import {getGlobalDataValue, getModuleContainer} from "../modules/globals/globalLibraryData";
import $ from 'jquery';
import {getStoreValue} from "../utils/storeValue";
import {isEmpty} from "../utils/generalUtils";
import {setDOMProperties} from "../modules/globals/setDOMProperties";


/**
 * Set the scroll when changing articles.  Currently, just sets to the top.
 * Pass the module and storageKey to get article container.
 *
 * Note: There are a number of DOM elements that might be scrolled,
 * scroll them all up to the top.
 *
 * @param params
 *     module: articleViewer
 *     storageKey: storage key from caller
 */
const setScrollToTop = (params) => {
	const containerData = getModuleContainer(params);
	const $articleContainer = $('#'+containerData.pane).find('.'+containerData.container);
	const $layoutContainer = $articleContainer.parents('div.layout-block');
	$("html, body").scrollTop(0);
	$articleContainer.animate({ scrollTop: 0 }, 100);
	$layoutContainer.animate({ scrollTop: 0 }, 100);
};


/**
 * If there is an anchor (hash value) set into the url, then call for the browser to jump
 * to that anchor.
 *
 * @param urlHash anchor hash value
 */
const jumpToAnchor = (urlHash) => {
	if (!isEmpty(urlHash)) {
		window.location.href = urlHash;
	}
};


/**
 * Scroll to an article section, as defined by
 * the h2 headers within the article content.
 *
 * @param params parameters for setSection trigger
 *     section: string to match in h2 section header
 */
const setSection = (params) => {
	params = Object.assign({
		section: ''
	}, params);
	// find matching string in `data-section`
	// scroll to that position
	const $h2 = $('h2[data-section="' + params.section + '"]');
	if ($h2.length > 0) {
		$h2[0].focus();
	}
};

/**
 * Set a data attribute based on template name in the App div
 * Pass the module and storageKey to get article pane.
 *
 * @param params
 *     module: articleViewer
 *     storageKey: storage key from caller
 *     article: pointer to the caller's article
 */
const setTemplateClass = (params) => {
	const $App = $('#App');
	if (params.action === 'reset') {
		$App.attr('data-article-template', '');
	} else {
		const pane = getModuleContainer(params).pane;
		const currentPane = getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''});
		if (currentPane === pane && params.article !== null) {
			const articleTemplate = params.article.customTemplate;
			$App.attr('data-article-template', articleTemplate);
		} else {
			$App.attr('data-article-template', '');
		}
	}
};
export {setTemplateClass};

/**
 * Respond to an action on an article that requires a browser back-end response
 * rather than one from within the module itself.
 *
 * First action is to respond to an article open (ie. change of article) and
 * scroll to the top of the article.  This requires scrolling the browser
 * to the top and also the container for the articleViewer.
 *
 * @param params parameters for article trigger
 *     action: 'viewed'
 *     module: module name
 *     storageKey: module instance storageKey
 *     article: pointer to article
 * @returns {string} success/failure
 */
const triggerOnArticle = (params) => {
	params = Object.assign({
		module: 'articleViewer',
		storageKey: '',
		article: {},
		triggerProperties: {},
		DOMProperties: {
		}
	}, params);
	const {module, storageKey, article} = params;
	const triggerProperties = Object.assign({
		module: module,
		storageKey: storageKey,
		article: article,
		action: '',
		section: '',
		urlHash: ''
	}, params.triggerProperties);
	const DOMProperties = Object.assign({
		module: module,
		storageKey: storageKey,
		moduleDOMElement: null,
		useModuleFocus: false,
		useModuleTitle: false,
		setDOMFocus: false
//		titleValue: undefined  // we check undefined by default, so don't want to define
	},params.DOMProperties);


	if (isEmpty(triggerProperties.section)) {
		DOMProperties.setDOMFocus = true;
		setDOMProperties(DOMProperties);
	} else {
		DOMProperties.setDOMFocus = false;
		setDOMProperties(DOMProperties);
	}
	if (!isEmpty(module) && !isEmpty(storageKey)) {
		if (!isEmpty(triggerProperties.section)) {
			setSection(triggerProperties);
		} else if (!isEmpty(triggerProperties.urlHash)) {
			jumpToAnchor(triggerProperties.urlHash);
		} else {
			setScrollToTop(triggerProperties);
		}
		setTemplateClass(params);
	} else {
		setTemplateClass({action: 'reset'});
	}
	return 'success';
};
export {triggerOnArticle};
