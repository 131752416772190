
import {getStoreValue} from "./storeValue";
import {GLOBALS, PURCHASE} from "../modules/_MODULE_GLOBALS/constants";
import {setCookie} from "./standardizedCookies";
import {goBack} from "../modules/common/staticButton/buttonWidgets/backButton";
import {goHome} from "../modules/common/staticButton/buttonWidgets/homeButton";
import {goToSearch} from "../modules/library/search/filters/searchEntry";


/**
 * 
 * nativeAppInterface should be used to define methods that the library can trigger in the app. 
 * Use this space to call native app methods
 */

/**
 * Calls the method in the native app to set the subscriberId
 * @param {*} subscriberId 
 */

const setAppSubscriberId = (subscriberId) => {
// const setAppSubscriberId = () => {
//	const subscriberId = getStoreValue({attributeKey: 'subscriberId'});
	if (typeof subscriberId !== "undefined" && subscriberId !== null) {
		if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.setAppSubscriberId) {
			console.log("found webkit.messageHandlers.setAppSubscriberId, setting subscriberId " + subscriberId);
			window.webkit.messageHandlers.setAppSubscriberId.postMessage(subscriberId);
		} else if (window.NativeApp) {
			console.log("found native app, setting subscriberId " + subscriberId);
			window.NativeApp.setAppSubscriberId(subscriberId);
		}
	}
};

/**
 * Calls the method in the native app to openInAppBrowser
 * The App will look specifically at the following params:
 * If using this function to login to PSL site
 * 	{ type : "login", url:<login_url_value>, successUrl:<success_url_value> }
 * If only using to open a url in  the in-app browser
 *  {type: "open" , url:<the_url_to_open> }
 * @param {*} params 
 */
const openInAppBrowser = (params) => {
	if (typeof params.url === "undefined") {
		console.error("Cannot open in-app browser. No url to open.");
		return;
	}
	if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.openLink) {
		console.log("found webkit.messageHandlers.openLink, " + params.url);
		window.webkit.messageHandlers.openLink.postMessage(params);
	} else if (window.NativeApp) {
		console.log("found native app, type: " + params.type);
		let paramStr = JSON.stringify(params);
		//console.log("openLink", paramStr);
		window.NativeApp.openLink(paramStr);
	}
};

/**
 * Calls the method in the native app to openInAppBrowser
 * The App will look specifically at the following params:
 * If using this function to login to PSL site
 * 	{ type : "login", url:<login_url_value>, successUrl:<success_url_value> }
 * If only using to open a url in  the in-app browser
 *  {type: "open" , url:<the_url_to_open> }
 * @param {*} params 
 */
const appCaller = (params) => {
	console.log("appCaller: " + params.type);
	if (params.type === "purchase" && params.productId === "undefined") {
		console.error("No productId specified for the purchase");
		return;
	}
	//ios only - we don't have buy in android
	if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.openLink) {
		console.log("found webkit.messageHandlers.openLink, ", params);
		window.webkit.messageHandlers.contentHubListener.postMessage(params);
	} else if (window.NativeApp) { //android
		console.log("found native app, type: " + params.type);
		let paramStr = JSON.stringify(params);
		//console.log("openLink", paramStr);
		window.NativeApp.openLink(paramStr);
	} else {
		console.error("don't know how to call app", params);
	}
};

const contentHubAction = (paramstr) => {
	console.log("in contentHubAction", paramstr);
	let params = null;
	if (typeof paramstr === "string") {
		console.log("params passed: string");
		try {
			params = JSON.parse(paramstr);
		} catch (e) {
			//check if this is a base64encodedString
			try {
				params = window.atob(paramstr);
				params = JSON.parse(params);
			} catch (e) {
				//we don't know what to do
				console.error("we don't know what to do with this data");
				console.error(paramstr);
				console.error(JSON.stringify(e));
			}
		}
	} else {
		params = paramstr;
	} 
	const type = params.type;
	const success = "SUCCESS";
	const failure = "FAILURE";
	const response = params.response;
	const isSuccessful = (response.success? response.success === success: false);
	const platform = params.platform;
	 
	if (type === "undefined") {
		return;
	}
	if (type === "login") {
		if (response.success === success) {
			//console.log("response.success : " + response.success);
			//drop a cookie
			let expirationDays = getStoreValue({attributeKey: 'expirationDays'});
			let expiredDate = new Date();
			expiredDate.setDate(expiredDate.getDate() + expirationDays);
			let successUrl = getStoreValue({attributeKey: 'successUrl'});
			setCookie(null, 'rfa', JSON.stringify(successUrl), {expires: expiredDate, secure: true});
			//set isLoggedIn true
			const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
			updateData({isLoggedIn: true}, {type: GLOBALS });
		}
		if (typeof response.error !== "undefined") {
			console.log("response.error = "+response.error);
			const errorMsg = response.errorMsg;
			//set authenticated false or whatever Roger wants to do

		}
	} else if (type === "productlist") {
		const products = params.products;
		const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
		updateData({products: products}, {type: GLOBALS });
	} else if (type === "purchase" || type === "restore" || type === "cancel") {
		if (isSuccessful) {
			/*		"app",
		"platform",
		"store",
		"productId",
		"transactionId",
		"transactionDate",
		"phoneId",
		"cdfdi",
		"signature",
		"userId",
		"developerPayload",
		"orderId",
		"mode",
		"version"
		*/
			if (platform && platform === "ios") {
				const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
				console.log("calling updateData with PurchaseMetadata - showing all params" );
				console.log(JSON.stringify(params));
				updateData({"PurchaseMetadata": params.PurchaseMetadata}, {type: PURCHASE, storageKey: 'globals'});
				console.log("calling updateData with PurchaseMetadata" );
			} else {
				const fetchData = getStoreValue({attributeKey: 'genericFetchData'});
				//console.log("action:" + action.type);
				fetchData({type: PURCHASE, storageKey: 'globals', queryParams: params});
			}
	
		}
	} else if (type === "goback") {
		goBack();
	} else if (type === "gohome") {
		goHome();
	} else if (type === "dosearch") {
		goToSearch();
	} else if (type === "error") {
		const errorMsg = params.message;
		/*What we can get from the app so far
		PRODUCTS_NOT_SET, PRODUCTS_NOT_VALID, PURCHASE_FAILED
		*/
		console.log(JSON.stringify(params));
		const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
		updateData({"accessError": errorMsg}, {type: GLOBALS });
	}
};
	

export { setAppSubscriberId, openInAppBrowser, contentHubAction, appCaller};

