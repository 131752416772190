import React, {useState} from 'react';
import {updateEasterEggData} from "../index";
import {isEmpty, isTrue} from "../../../../utils/generalUtils";
import {Translate} from "../../../../locales/locales";
import {EASTEREGG_KEY} from "../../../_MODULE_GLOBALS/constants";
import getDeviceType from "../../../../utils/getDeviceType";
import {addClass} from "../../../../utils/generateClassName";


/**
 * Update easter egg data for the gtxinfo easter egg to close it.
 *
 * @param storageKey
 */
const closeDialog = (storageKey) => {
	storageKey = !isEmpty(storageKey) ? storageKey : EASTEREGG_KEY;
	updateEasterEggData({egg: 'gtxinfo', enable: false, storageKey: storageKey});
};

/**
 * Trigger a mailto through location.href.
 *
 * @param params
 *     emailAddress: from configuration
 *     subject: default: gtxInfo
 *     body: gathered user data
 */
const emailButtonClick = (params) => {
	params = Object.assign({
		emailAddress: 'support@gtxcel.com',
		subject: '',
		emailBody: ''
	}, params);
	window.location.href = `mailto:${params.emailAddress}?subject=${params.subject}&body=${params.emailBody}`;
};


/**
 * Widget to generate the gtxInfo information dialog.
 *
 * Only made visible when the "magic code" is entered in the search entry
 * input field.
 *
 * Note: When displaying in the app, there is a problem getting the
 * button actions to work correctly.
 * For now, add "is-app" class and handle hiding in styles.css.
 *
 * Sample format of data
 *    Publication Name : FPM
 *    App Version : 49.0
 *    Push Token : 286f532e ddf66c6d ab66b0f9 ba63a74d eb713649 61e6e993 8be4698f 6ca3edf0
 *    Device Id : 64A95376-06ED-4AD3-A5C7-DFCF591F2978
 *    Subscriber Id : B9SKq5sCylbF4
 *    Platform : iPhone
 *    Platform Info : iPhone8,4 - 15
 *
 * @param props from easterEgg module
 *     state: redux state to get base config information
 * @returns {JSX.Element}
 * @constructor
 */
export const GtxInfo = (props) => {
	const [onClipboard, setOnClipboard] = useState(false);

	const state = props.state;
	const eggAttributes = props.eggAttributes;

	const subscriberId = !isEmpty(state.subscriberId) ? state.subscriberId : '-';
	const phoneId = !isEmpty(state.phoneId) ? state.phoneId : '-';
	const platform = !isEmpty(state.platform) ? state.platform : '-';
	const version = !isEmpty(state.version) ? state.version : '-';
	const publicationName = !isEmpty(state.publicationName) ? state.publicationName : '-';
	const collectionTitle = !isEmpty(state.collectionTitle) ? state.collectionTitle : '-';
//	const pushTokenId = !isEmpty(state.pushTokenId) ? state.pushTokenId : '';

	const subject = "GtxInfo";
	const emailAddress = !isEmpty(eggAttributes.emailAddress) ? eggAttributes.emailAddress : 'support@gtxcel.com';

	const gtxInfo = [
		"Publication Name: " + publicationName,
		"Collection Title: " + collectionTitle,
		"App Version: " + version,
//		"Push Token: " + pushTokenId,
		"Device Id: " + phoneId,
		"Subscriber Id: " + subscriberId,
		"Platform: " + platform
	];
	const clipboardText = gtxInfo.join('\n');
	const emailBody = gtxInfo.join('%0D%0A');
	const gtxInfoClassName = addClass('easter-egg-gtx-info', 'structure-' + getDeviceType());

	// if displayed in the app, then we won't show buttons; for now, we'll use classes to hide as it makes it easier to configure to turn on
	const gtxInfoButtonsClass = isTrue(props.isApp, {defaultValue: false}) ? 'gtx-info-buttons isApp' : 'gtx-info-buttons';
	const clipboardButtonLabel = onClipboard ? Translate.Text({id:'easterEgg.gtxinfo.clipboardCopied'}) : Translate.Text({id:'easterEgg.gtxinfo.clipboardCopy'});
	const clipboardButtonClass = onClipboard ? 'copied-to-clipboard' : 'copy-to-clipboard';
	return (
		<div className={gtxInfoClassName}>
			<div className={"gtx-info-close"}>
				{props.displayCloseButton ?
					<button onClick={() => {
						closeDialog(props.storageKey);
					}}>X</button>
					: null
				}
			</div>
			<div className={"gtx-info-display"}>
				<ul>
					{
						gtxInfo.map(info => {
							const [keyValue] = info.split(':');
							return <li key={keyValue}>{info}</li>;
						})
					}
				</ul>
			</div>
			<div className={gtxInfoButtonsClass}>
				<div className={"gtx-info-clipboard"}>
					<button className={clipboardButtonClass} onClick={() => {
						navigator.clipboard.writeText(clipboardText);
						setOnClipboard(true);
					}}>{clipboardButtonLabel}</button>
				</div>
				<div className={"gtx-info-email"}>
					<button onClick={() => {
						emailButtonClick({emailAddress: emailAddress, subject: subject, emailBody: emailBody});
					}}>{Translate.Text({id:'easterEgg.gtxinfo.email'})}</button>
				</div>
			</div>
		</div>
	);
};
