/**
 * Return the query parameters as an object.
 *
 * @param url the url, defaults to window.location.href
 * @returns {*}
 */
const getUrlParamsObject = (url) => {
	if (typeof url === "undefined") {
		url = window.location.href;
	} 
	let urlParts = url.split('?');
	if (urlParts && urlParts.length > 1) {
		const queryParams = urlParts[1].split('&');
		let paramObject = {};
		queryParams.forEach((param) => {
			const splitParams = param.split("=");
			if (splitParams.length > 1) {
				paramObject[splitParams[0]] = splitParams[1];
			} 
		});
		return paramObject;
	} else {
		return {};
	}
};

export {getUrlParamsObject};

/**
 * Return the url parameter value given the url key.
 *
 * @param param parameter key
 * @param defaultValue if no key=value pair, return default value
 * @returns {*}
 */
const getUrlParam = (param, defaultValue) => {
	defaultValue = typeof defaultValue !== 'undefined' ? defaultValue : null;
	if (typeof window === 'undefined') {
		return defaultValue;
	}
	const queryParams = getUrlParamsObject();
	return (queryParams.hasOwnProperty(param)) ? queryParams[param] : defaultValue;
};
export {getUrlParam};

/**
 * Return the url by removing the query params passed by params.
 *
 * @param params an array of the query parameter names to exclude
 * @param url a url string to remove the params from
 * @returns {*}
 */
const removeParamsfromUrl = (params, url) => {
	let urlParts = url.split('?');
	if (urlParts.length <= 1) {
		return url;
	}
	let queryParams = getUrlParamsObject(url);
	let queryString = '';
	Object.keys(queryParams).forEach((queryParam) => {
		if (params.includes(queryParam)) {
			delete queryParams[queryParam];
		}
	});
	Object.keys(queryParams).forEach((queryParam, index) => {
		queryString += (index === 0) ? "?" : "&" ;
		queryString += queryParam + "=" + queryParams[queryParam] ;
	});
	url = urlParts[0] + queryString;
	return url;
};
export {removeParamsfromUrl};

/**
 * Return the url by adding the query params passed by params.
 *
 * @param params an object of the query parameters to add
 * @param url a url string to add the params to
 * @param replace a boolean: replace existing query params or not
 * @returns {*}
 */
const addParamsToUrl = (params, url, replace) => {
	let urlParts = url.split('?');
	let queryParams = Object.assign({},getUrlParamsObject(url));
	let queryString = '';
	Object.keys(params).forEach((param) => {
		if (replace || !queryParams.hasOwnProperty(param)) {
			queryParams[param] = params[param];
		}
	});
	Object.keys(queryParams).forEach((queryParam, index) => {
		queryString += (index === 0) ? "?" : "&" ;
		queryString += queryParam + "=" + queryParams[queryParam] ;
	});
	url = urlParts[0] + queryString;
	return url;
};
export {addParamsToUrl};

