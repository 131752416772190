import {isEmpty} from "../generalUtils";
import {getDocTypeAttribute} from "../getDocTypeAttribute";
import {ID_NOT_SET} from "../../modules/_MODULE_GLOBALS/constants";

// replicaList currently only supports a default template -
// we may add more in the future, in which case they would be added here
const availableCardTemplates = ['default'];
/**
 * Get the cardTemplate property based on docType from original props.
 * If the card template is one of the available templates, return it, otherwise,
 * return the first template in the list; usually "default"
 *
 * @params cardTemplate: from cardTemplate.card attribute
 * @returns {string}
 */
const getAvailableCardTemplate = (cardTemplate) => {
	if (availableCardTemplates.includes(cardTemplate)) {
		return cardTemplate;
	} else {
		return availableCardTemplates[0];
	}
};

/**
 * Given the module props from any replicaList-style module, generate the display params
 * to be used by that module to create individual summary cards for a replica page.
 *
 * Read the documentMap object with the list of all documents and find the matching
 * document for the current issueUrl.  Populate documentId, documentUrl, and title from
 * the matching document
 *
 * @param params module parameters
 *     originalProps: the "props" attribute from the module
 *     moduleProps: new, added props for the module (listProps from replicaList)
 *     state: redux state object
 *     storeState: state object for the replicaList
 *
 * @returns {*} update moduleProps values
 */
const generateReplicaDisplayParams = (params) => {
	params = Object.assign({
		originalProps: {},
		moduleProps: {},
		state: {},
		storeState: {},
	}, params);
	const {moduleProps, originalProps, state, storeState} = params;

	const documentList = !isEmpty(state.documentList) ? state.documentList : [];
	const document = documentList.find(document => document.issueUrl === moduleProps.issueUrl);
	const replicaDocument = !isEmpty(document) ? document : {};
	const cardTemplateAttributes = getDocTypeAttribute(moduleProps.docType, originalProps.cardTemplate);

	return Object.assign({}, params.moduleProps, {
		replicaDisplayParams: {
			cardTemplate: getAvailableCardTemplate(cardTemplateAttributes.card),
			cardTemplateAttributes: cardTemplateAttributes
		},
		title: !isEmpty(replicaDocument.name) ? replicaDocument.name : (!isEmpty(storeState.documentTitle) ? storeState.documentTitle : 'replicaList.base.title'),
		documentId: !isEmpty(replicaDocument.documentId) ? replicaDocument.documentId : (!isEmpty(storeState.documentId) ? storeState.documentId : ID_NOT_SET),
		documentUrl: !isEmpty(replicaDocument.documentUrl) ? replicaDocument.documentUrl : (!isEmpty(storeState.documentUrl) ? storeState.documentUrl : ''),
	});
};
export {generateReplicaDisplayParams};
