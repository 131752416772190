import React from "react";
import {Translate} from "../../../../locales/locales";
import * as Tracking from "../../../../utils/tracking";
import {isEmpty} from "../../../../utils/generalUtils";
import {addClass} from "../../../../utils/generateClassName";


/**
 * 	TODO: DEPRECATED - REMOVE
 *
 * 	This widget has been deprecated in favor of the Support Menu attributes
 * 	used with the ModalMenu module.
 *
 * 	See Configuration:  "moduleId": "librarySupportMenu"
 *
 */

/**
 * Called when the support button is clicked
 */
const onSupportButtonClick = (mailto) => {
	const trackingProperties = {
		"type": "support",
	};
	Tracking.libraryTrack("button clicked", trackingProperties);
	if (mailto !== '') {
		window.location.href = mailto;
	}
};

/**
 * Support button will fire a mailto: event when clicked
 * The button will not render unless a value for customerServiceContactEmail is present
 *
 * @param params
 *     props object from caller
 *         className: add to base class
 *         label: use to generate label of button (either text or locale key lookup)
 *         tag: tag for component; if not specified, then default is button
 * @returns {*} string React object
 * @constructor
 */
const SupportButton = (params) => {
	params = Object.assign({
		state: {},
		className: '',
		label: '',
		title: '',
		tag: 'button'
	}, params);

	const className = addClass('support-button', params.className);
	const label = !isEmpty(params.label) ? Translate.Text({id: params.label}) : Translate.Text({id: 'actionButton.supportButton.label'});
	const title = !isEmpty(params.title) ? Translate.Text({id: params.title}) : Translate.Text({id: 'actionButton.supportButton.title'});
	const GeneratedTag = !isEmpty(params.tag) ? `${params.tag}` : `button`;

	if (!isEmpty(params.state.customerServiceContactEmail)) {
		return (
			<GeneratedTag
				className={className}
				title={title}
				onClick={() => onSupportButtonClick(params.state.customerServiceContactEmail)}
			>
				{label}
			</GeneratedTag>
		);
	} else {
		return null;
	}



};
export {SupportButton};
