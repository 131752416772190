import $ from "jquery";
import { ADD_LAYOUT} from "../../modules/_MODULE_GLOBALS/constants";

const initialState = {
	layout: '',
	configuration: {},
};


function baseReducer(state = initialState, action) {
	if (action.type === ADD_LAYOUT) {
		return $.extend({}, state,
			{
				layout: action.payload
			});
	}
	return state;
}
export default baseReducer;
