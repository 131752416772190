import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import {
	EASTEREGG,
	EASTEREGG_KEY,
	SEARCH_FILTERS,
	SEARCH_RESULTS
} from "../../../_MODULE_GLOBALS/constants";
import { updateData } from '../../../../store/actions';
import { Translate } from "../../../../locales/locales";
import GenerateTitle from "../../../../widgets/generateTitle";
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../../utils/moduleSetup";
import {checkIfMyPane} from "../../../globals/globalLibraryData";
import {setDOMProperties} from "../../../globals/setDOMProperties";
import {isEmpty, isTrue} from "../../../../utils/generalUtils";
import {updateFilterOnChange} from "../searchUtils/filterUpdate";
import {getStoreValue} from "../../../../utils/storeValue";
import {getKeyByValue} from "../../../../utils/objectUtils";
import {useMountPostRender} from "../../../../hooks/useMount";
import {navigateToTarget} from "../../../globals/globalNavigator";


/**
 * Set the same value for all the different search entry input fields.
 * This is somewhat a violation of independence of module instances, but
 * all searchEntry module instances share the same entry value.
 *
 * @param value string value to use to set input fields
 */
const setAllSearchInputValues = (value) => {
	$('input[name="searchEntry"]').val(value);
};


/**
 * Specialized call to "just" navigate to the search screen with no attributes.
 *
 */
const goToSearch = () => {
	navigateToTarget({
		targetKey: "searchFilter",
		attributes: {}
	});
};
export {goToSearch};

/**
 * Call to update data with contents of the current search entry field and call
 * to navigate to search page.
 *
 * The search entry field is likely to appear in multiple places in the UI, so
 * we update all search entry fields with the new value from the current search field.
 *
 * @param params
 *     props: module props
 *     searchEntryValue: string value from search entry input
 *     trigger: what use action triggered the update call
 */
const updateOnChange = (params) => {
	params = Object.assign({
		props: {},
		searchEntryValue: '',
		trigger: ''
	}, params);
	const {props, trigger} = params;
	const searchEntryValue = params.searchEntryValue.trim();

	const easterEggKey = getKeyByValue(props.easterEggTriggers, searchEntryValue);
	// trap easter egg input
	if (!isEmpty(easterEggKey)) {
		const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
		updateData({egg: easterEggKey, enable: true}, {type: EASTEREGG, storageKey: EASTEREGG_KEY});
		// clear search entry so it doesn't accidentally trigger again
		setAllSearchInputValues("");
	} else {
		/// set all the different module instances
		setAllSearchInputValues(searchEntryValue);
		// update session storage for searchTerm for new value
		if (isEmpty(searchEntryValue)) {
			window.sessionStorage.removeItem("searchTerm");
		} else {
			window.sessionStorage.setItem("searchTerm", searchEntryValue);
		}

		// set navigationKey for search
		// 'searchFilter': new entry value is not empty OR search button pressed and old entry was empty
		// 'clearFilter': any other condition where entry value is empty
		const oldSearchEntryValue = (props.hasOwnProperty('searchFilters') && props.searchFilters.hasOwnProperty('entry')) ? props.searchFilters.entry : '';
		const isEmptyOldSearch = trigger === 'search' && isEmpty(oldSearchEntryValue);
		const navigationKey = (!isEmpty(searchEntryValue) || isEmptyOldSearch) ? 'searchFilter' : 'clearSearchFilter';

		updateFilterOnChange({
			props: props,
			navigationKey: navigationKey,
			searchValue: searchEntryValue,
			searchReducer: 'searchEntryFilter',
		});
	}
};

/**
 * Clear search entry field for all instances, then call to update store.
 * We make a generic call to clear as search entry is a module that is
 * likely to be placed in multiple places in the UI.
 *
 * @param props module props
 */
const clearSearchEntry = (props) => {
	updateOnChange({props: props, searchEntryValue: '', trigger: 'clear'});
};

/**
 * Called when search input field triggers onChange event.
 * Update <storageKey>/filters/entry in the store on enter key.
 *
 * @param event keypress event in search entry
 * @param props, module props
 * @param searchEntryValue string value from search entry input
 */
const handleKeyPress = (event, props, searchEntryValue) => {
	if (event.key === 'Enter') {
		updateOnChange({props: props, searchEntryValue: searchEntryValue, trigger: 'key'});
	}
};


export const SearchEntryModule = (props) => {
	const [currentSearchEntry, setCurrentSearchEntry] = useState(props.searchEntry);

	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: SEARCH_RESULTS,
		defaultClass: 'search-entry',
		title: 'search.entry.title',
		titleTag: 'span',
		configQueryParams: {}
	});

	useMountPostRender(() => {
		if (isEmpty(currentSearchEntry)) {
			const storedEntry = window.sessionStorage.getItem("searchTerm");
			if (!isEmpty(storedEntry) && currentSearchEntry !== storedEntry) {
				setCurrentSearchEntry(storedEntry);
			}
		}
	});


	// run if clearSearch props changes to reset search entry if necessary
	useEffect(() => {
		if (props.clearSearch !== false) {
			$('input[name="searchEntry"]').val('');
		}
	},[props.clearSearch]);

	
	// setup ref to the search entry input element for focus
	const moduleDOMElement = useRef(null);
	// call if the search entry value changes and isMyPane to set focus
	// note: we need to use the actual input value, rather than the filter prop
	const searchEntryValue = moduleDOMElement.current !== null ? moduleDOMElement.current.value : '';
	useEffect(() => {
		if (props.isMyPane && searchEntryValue === '' && moduleDOMElement.current !== null) {
			setDOMProperties({
				module: 'searchEntry',
				storageKey: props.storageKey,
				moduleDOMElement: moduleDOMElement.current,
				useModuleTitle: props.useModuleTitle,
				useModuleFocus: props.useModuleFocus,
				titleValue: 'Search'
			});
		}
	}, [props.storageKey, props.isMyPane, props.useModuleFocus, props.useModuleTitle, searchEntryValue]);


	// check if the search entry value is not an empty string; enable clear button with text
	const buttonClass = isEmpty(searchEntryValue) ? '' : 'hasEntry';
	/*
	* Generate jsx for search entry field if displayOnDevice
	* If the hideInput attribute is set to true, then it means we only want to render the
	* searchEntrySubmit button and not the search input field.  This will only display the
	* magnifying glass icon for button click.  This is likely only set for smaller device sizes
	*/
	if (displayOnDevice(props)) {
		if (isTrue(props.hideInput)) {  // only render search submit button (magnifying glass icon)
			return (
				<div className={mops.className} role={"search"}>
					<GenerateTitle titleParams={mops.titleParams} />
					<button
						type={"button"} name={"searchEntrySubmit"} className={buttonClass} aria-label={Translate.Text({id: "search.entry.submit"})}
						onClick={(evt) => {
							const searchTerm = moduleDOMElement.current !== null ? moduleDOMElement.current.value : '';
							updateOnChange({props: props, searchEntryValue: searchTerm, trigger: 'search'});
							setCurrentSearchEntry(searchTerm);
						}}
					>
						<span className={'fas fa-search'} title={Translate.Text({id: "search.entry.submit"})} />
					</button>
				</div>
			);
		} else {
			return (
				<div className={mops.className} role={"search"}>
					<GenerateTitle titleParams={mops.titleParams} />
					<input
						type={"text"} className={"search-entry-input"} name="searchEntry" defaultValue={currentSearchEntry} ref={moduleDOMElement}
						aria-label={Translate.Text({id: "search.entry.title"})} placeholder={Translate.Text({id: "search.entry.placeholder"})}
						onKeyDown={(evt) => {
							const searchTerm = moduleDOMElement.current !== null ? moduleDOMElement.current.value : '';
							handleKeyPress(evt, props, searchTerm);
							setCurrentSearchEntry(searchTerm);
						}}
						onChange={(evt) => {
							const searchTerm = moduleDOMElement.current !== null ? moduleDOMElement.current.value : '';
							setCurrentSearchEntry(searchTerm);
						}}
					/>
					<button
						type={"button"} name={"searchClear"} className={buttonClass}
						aria-label={Translate.Text({id: "search.entry.clear"})}
						onClick={(evt) => {
							clearSearchEntry(props);
							setCurrentSearchEntry('');
						}}
					>
						<span className={'fas fa-times-circle'} title={Translate.Text({id: "search.entry.clear"})} />
					</button>
					<button
						type={"button"} name={"searchEntrySubmit"} className={buttonClass} aria-label={Translate.Text({id: "search.entry.submit"})}
						onClick={(evt) => {
							const searchTerm = moduleDOMElement.current !== null ? moduleDOMElement.current.value : '';
							updateOnChange({props: props, searchEntryValue: searchTerm, trigger: 'search'});
							setCurrentSearchEntry(searchTerm);
						}}
					>
						<span className={'fas fa-search'} title={Translate.Text({id: "search.entry.submit"})} />
					</button>
				</div>
			);
		}
	} else {
		return null;
	}
};

/**
 * Map the store object you are using.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{searchEntry: (*|string), clearSearch: (boolean|*), isMyPane: boolean, useModuleTitle: boolean, active: (*|string), searchFilters: *, useModuleFocus: boolean, storageKey: (*|string)}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = props.storageKey ? props.storageKey : SEARCH_RESULTS;
	const storeState = state[storageKey] ? state[storageKey] : {};
	const globalState = state.globals ? state.globals : {};

	const searchFilters = (storeState && storeState.searchFilters) ? storeState.searchFilters : {};
	const searchEntry = searchFilters.hasOwnProperty('entry') ? searchFilters.entry : '';
	// clear the search? usually happens when Back button is clicked
	const clearSearch = (storeState && storeState.clearSearch) ? storeState.clearSearch : false;

	// capture some values for other attributes use
	const myPane = checkIfMyPane({moduleName: 'searchResults', instanceId: props.instanceId, storageKey: storageKey});

	return {
		storageKey: storageKey,
		active: getActiveAttributeState(props),
		isMyPane: myPane.isMyPane,
		myPane: myPane.myPane,
		searchFilters: searchFilters,
		searchEntry: searchEntry,
		clearSearch: clearSearch,
		useModuleFocus: isTrue(storeState.useModuleFocus),
		useModuleTitle: isTrue(storeState.useModuleTitle),
		hideInput: isTrue(props.hideInput, {default: false}),
		easterEggTriggers: !isEmpty(props.easterEggTriggers) ? props.easterEggTriggers : {},
	};
};

/**
 * Actions that can be called by this module.
 *
 * actions:
 *     updateData: call to update the store with new properties
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		updateData: (payload, params) => {
			params.type = SEARCH_FILTERS;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchEntryModule);
