import {
	DATA_LOADED,
	HIGHLIGHTS_KEY,
	HIGHLIGHTS,
	DATA_SAVED,
	DATA_DELETED,
	DATA_UPDATED,
	DATA_REQUESTED,
	
} from "../../_MODULE_GLOBALS/constants";
import deepmerge from "deepmerge";
import { storeInCache, retrieveFromCache} from "../../../utils/manageStoreCacheData";
import { isEmpty } from "../../../utils/generalUtils";
import { isArray } from "util";

const createInitialState = ((storageKey) => {
	return {
		[storageKey]: {
		}
	};
	/*
		return {
		[storageKey]: {
			"articleId": '',
			"highlights":[],
		}
	*/
});

function highlightsReducers(state, action) {
	// check module action call; if not present, set default to empty string so it doesn't unnecessarily trigger
	const moduleAction = (action.params && action.params.type) ? action.params.type : HIGHLIGHTS;
	//const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'highlights';
	//const storageKey = HIGHLIGHTS_KEY;
	const storageKey = "highlights";

	/* We get action result type as DATA_LOADED for both DATA_REQUESTED and DATA_SAVE_REQUESTED
	 type of actions
	 */

	if (moduleAction === HIGHLIGHTS && (action.type === DATA_LOADED )) {		
		console.log("highlight fetched");
		const returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
		//
		//	let isSuccessful = (action.payload.status && action.payload.status.status === "SUCCESS");
		const articleId = action.payload.articleId;
		const result = action.payload.highlights;
		storeInCache({dataKey: articleId, keyType: HIGHLIGHTS_KEY, data: result, overrideEmptyData: true, state: returnState});
		// update the new state
		// If we want to keep adding highlights for all the articles that the user goes to 
		// in the session use Object.assign
		// returnState[storageKey] = Object.assign(returnState[storageKey],result);
		// We want to change the content in the highlight store by article
		returnState[storageKey] = result;
	//	returnState[storageKey].highlights = result;
	//	returnState[storageKey].highlightsFetched = true;

		return returnState;
	}
	if (moduleAction === HIGHLIGHTS && action.type === DATA_UPDATED) {		
		console.log("highlight updated from cache");
		const returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
		//
		//	let isSuccessful = (action.payload.status && action.payload.status.status === "SUCCESS");
		const result = action.payload.highlights;
		returnState[storageKey] = result;
		return returnState;
	}
	/**
	 * DATA_SAVE_REQUESTED = Save(POST) or Update(PUT) highlights by articleId 
	 * The 'method' parameter of the saveData action uses POST or PUT as input 
	 * In both the cases:
	 * 1. Fetch the existing highlight data from the cache 
	 * 2. Append the saved highlight and add the entire array to the cache 
	**/
	if (moduleAction === HIGHLIGHTS && action.type === DATA_SAVED) {
		console.log("highlight updated/added");
		const returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
	//	let isSuccessful = (action.payload.status && action.payload.status.status === "SUCCESS");
		const articleId = action.payload.articleId;
		const result = action.payload.highlights;
		const highId = result.id;
		console.log(typeof result);
		console.log(JSON.stringify(result));
		let highlights = retrieveFromCache({dataKey:articleId, keyType:HIGHLIGHTS_KEY, emptyDataObject: [],state: returnState}).storedData;
		highlights = highlights.filter(highlight => highlight.id !== highId);
		
		if (isArray(result)) {
			result.forEach((newHighlight) => {
				const highId = newHighlight.id;
				highlights = highlights.filter(highlight => highlight.id !== highId);
				highlights.push(newHighlight);
				console.log("highlight updated/added:" + JSON.stringify(newHighlight));
			});	
		} else {
			const highId = result.id;
			highlights = highlights.filter(highlight => highlight.id !== highId);
			highlights.push(result);
		}
		storeInCache({dataKey: articleId, keyType: HIGHLIGHTS_KEY, data: highlights, overrideEmptyData: true, state: returnState});
		return returnState;
	}

	if (moduleAction === HIGHLIGHTS && action.type === DATA_DELETED) {
		console.log("highlight deleted");
		const returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
		const deletedId = (action.params && action.params.hasOwnProperty("deleteId")) ? action.params.deleteId : null;
		const articleId = (action.params && action.params.hasOwnProperty("articleId")) ? action.params.articleId : "";
		console.log("highlight deleted:deletedId,articleId " + JSON.stringify([deletedId,articleId]));

		if (isEmpty(deletedId) || isEmpty(articleId)) {
			console.error("deletedId or articleId is empty.");
		} else {
			let highlights = retrieveFromCache({dataKey:articleId, keyType:HIGHLIGHTS_KEY, emptyDataObject: [], state: returnState}).storedData;
			console.log("before delete in cache:highlight count:" + highlights.length);
			highlights = highlights.filter(highlight => highlight.id !== deletedId);
			console.log("before delete in cache:highlight count:" + highlights.length);
			
			storeInCache({dataKey:articleId, keyType:HIGHLIGHTS_KEY, data:highlights, overrideEmptyData: true, state: returnState});
		} 
		return returnState;
	}
	return state;
}
export default highlightsReducers;

