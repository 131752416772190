import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {NavigationButton} from "./buttonWidgets/navigationButton";
import {RESPONSIVE_BUTTON} from "../../_MODULE_GLOBALS/constants.js";
import {isEmpty} from "../../../utils/generalUtils";
import {updateData} from "../../../store/actions";
import {Translate} from "../../../locales/locales";
import {getObjectFromJSON} from "../../../utils/objectUtils";
import {navigateToTarget} from "../../globals/globalNavigator";
import {getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import {PageViewButton} from "./buttonWidgets/pageViewButton";
import {ReadingViewButton} from "./buttonWidgets/readingViewButton";
import {GoToPage} from "../viewerControls/goToPage";
import {PageSpread} from "../viewerControls/pageSpread";
import {PageZoom} from "../viewerControls/pageZoom";
import {ArticleZoom} from "../viewerControls/articleZoom";
import {TocButton} from "./buttonWidgets/tocButton";



const responsiveButtons = {
	'navigationButton': NavigationButton,
	'pageViewButton': PageViewButton,
	'readingViewButton': ReadingViewButton,
	'goToPage': GoToPage,
	'pageSpread': PageSpread,
	'pageZoom': PageZoom,
	'articleZoom': ArticleZoom,
	'tocButton': TocButton
};


/**
 * Provide a consistent way for widgets to call back to call manageUpdateAndNavigation
 *
 * @param params
 *     props: module props
 *     updateValue: value to update in store
 *     additionalAttributes: anything to add to base attributes
 */
const manageNavigationUpdates = (params) => {
	params = Object.assign({
		props: {},
		updateValue: {
			name: '',
			value: ''
		},
		additionalAttributes: {}
	}, params);
	const {props, updateValue, additionalAttributes} = params;
	const storeState = !isEmpty(props.storeState) ? props.storeState : {};
	const attributes = Object.assign ({
		issueUrl: !isEmpty(props.issueUrl) ? props.issueUrl : '',
		fetchQueryParams: !isEmpty(storeState.fetchQueryParams) ? storeState.fetchQueryParams : {},
	}, additionalAttributes);
	attributes[updateValue.name] = updateValue.value;

	navigateToTarget({
		targetKey: !isEmpty(props.navigationKey) ? props.navigationKey : '',
		navigationTargets: !isEmpty(props.navigationKeys) ? props.navigationKeys : {},
		replacementValues: !isEmpty(props.replacementValues) ? props.replacementValues : {},
		attributes: attributes
	});
};
export {manageNavigationUpdates};


export const ResponsiveButtonModule = (props) => {
	const ResponsiveButton = responsiveButtons[props.widget];
	/*
	* Generate jsx for action button if displayOnDevice
	* Use ResponsiveButton as tag
	*/
	if (!isEmpty(ResponsiveButton) && displayOnDevice(props)) {
		return <ResponsiveButton {...props} />;
	} else {
		return null;
	}
};


/**
 * Map the store object you are using.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{articleList: Array}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : RESPONSIVE_BUTTON;
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};

	// look for replacement values from any specified storage keys
	const replacementValues = {};
	if (!isEmpty(storeState.issueUrl)) {
		replacementValues.issueUrl = storeState.issueUrl;
	}
	const issueUrl = !isEmpty(storeState.issueUrl) ? storeState.issueUrl : '';

	// attributes for navigation
	const attributes = {
		issueUrl: storeState.issueUrl,
		replacementValues: replacementValues,
	};

	return {
		navigationKeys: getObjectFromJSON(props.navigationKeys, {}),
		active: getActiveAttributeState(props),
		GeneratedTag: !isEmpty(props.tag) ? `${props.tag}` : `button`,
		title: !isEmpty(props.title) ? Translate.Text({id: props.title}) : '',
		label: !isEmpty(props.label) ? Translate.Text({id: props.label}) : '',
		issueUrl: issueUrl,
		replacementValues: replacementValues,
		attributes: attributes,
		storeState: storeState
	};
};

/**
 * UIWidgetModule module (currently) makes to calls to fetch of update data.
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		updateData: (payload, params) => {
			params.type = !isEmpty(params.type) ? params.type : RESPONSIVE_BUTTON;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ResponsiveButtonModule);
