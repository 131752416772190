import {isTrue} from "../generalUtils";
import {getDocTypeAttribute} from "../getDocTypeAttribute";


const availableCardTemplates = ['default', 'large', 'landscape','stacked', 'horizontal', 'carousel', 'textbook'];
/**
 * Get the cardTemplate property based on docType from original props.
 * If the card template is one of the available templates, return it, otherwise,
 * return the first template in the list; usually "default"
 *
 * @params cardTemplate: from cardTemplate.card attribute
 * @returns {string}
 */
const getAvailableCardTemplate = (cardTemplate) => {
	if (availableCardTemplates.includes(cardTemplate)) {
		return cardTemplate;
	} else {
		return availableCardTemplates[0];
	}
};

/**
 * Given the module props from any articleList-style module, generate the display params
 * to be used by that module to create individual summary cards for an article.
 *
 * @param params module parameters
 *     originalProps: the "props" attribute from the module
 *     moduleProps: new, added props for the module (listProps from articleList)
 *
 * @returns {*} update moduleProps values
 */
const generateArticleDisplayParams = (params) => {
	params = Object.assign({
		originalProps: {},
		moduleProps: {}
	}, params);
	const moduleProps = params.moduleProps;
	const originalProps = params.originalProps;
	const cardTemplateAttributes = getDocTypeAttribute(moduleProps.docType, originalProps.cardTemplate);

	moduleProps.articleDisplayParams = {
		displayThumbnail: isTrue(cardTemplateAttributes.displayThumbnail),
		displayThumbnailOnLeft: isTrue(cardTemplateAttributes.displayThumbnailOnLeft),
		displayIssue: isTrue(cardTemplateAttributes.displayIssue),
		displaySummary: isTrue(cardTemplateAttributes.displaySummary),
		displayCategory: isTrue(cardTemplateAttributes.displayCategory),
		useSummaryEllipsis: isTrue(cardTemplateAttributes.useSummaryEllipsis),
		maxSummaryCharacters: typeof cardTemplateAttributes.maxSummaryCharacters !== 'undefined' ? cardTemplateAttributes.maxSummaryCharacters : 155,
		cardTemplate: getAvailableCardTemplate(cardTemplateAttributes.card),
		cardTemplateAttributes: cardTemplateAttributes,
		featureCategory: originalProps.hasOwnProperty('featureCategory') ? originalProps.featureCategory : '',
		listCategory: originalProps.hasOwnProperty('listCategory') ? originalProps.listCategory : ''
	};

	return moduleProps;
};
export {generateArticleDisplayParams};
