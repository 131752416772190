import React from 'react';
import {Translate} from "../../../../locales/locales";



/**
 * Widget for the "Access Pending" dialog; which simply prints out a message
 * that shows that full access is being configured.
 * This will give way to the loading spinner for article/replica/
 *
 * @param params - Nothing currently used from these params
 *     props: authentication props
 *     additionalProps: anything additional to pass in
 * @returns {JSX.Element}
 * @constructor
 */

const AccessPending = (params) => {
	return (
		<div className={"authentication"}>
			<div className="authenticate-screen" />
			<div className="authenticate access-pending">
				<div className={"pending"}>
					<h2 className="title">{Translate.Text({id:'authenticate.accessPending.title'})}</h2>
				</div>
			</div>
		</div>
	);
};
export {AccessPending};
