import {
	SEARCH_RESULTS,
	SEARCH_FILTERS,
	DATA_LOADED,
	DATA_UPDATED,
	DATA_REQUESTED,
	SEARCHRESULTS_STOREDRESULTS
} from "../../_MODULE_GLOBALS/constants";
import {isEmpty, isTrue} from "../../../utils/generalUtils";
import deepmerge from "deepmerge";
import {createNewStoreState} from "./generalReducerActions";
import {storeInCache} from "../../../utils/manageStoreCacheData";


/*
   This reducer fetches and updates a list of search results, which generally consist
   of an array of article or page objects, depending on the search keys.
 */

/**
 * Required store properties for a module that fetches lists of articles or objects
 * from search.
 *
 * This ensures that there is a placeholder in the store for
 * the values returned for the module.
 * Each action-type then initializes the state for it's store state.
 */
const createInitialState = (storageKey) => {
	return {
		[storageKey]: {
			"articles": [],
			"issues": null,
			"searchFilters": {
				"entry": "",
				"type": null,
				"sortBy": null,
				"issueUrl": null,
				"articleSearch": null
			},
			"forceFetch": false,
			"inProgress": false,
			"found": {
				"total": 0,
				"displayed": 0
			},
			"clearSearch": false
		}
	};
};

/**
 * List of attributes that should be re-initialized each time a reducer gets called.
 *
 * @type {function(*): {}}
 */
const resetStoreState = ((storageKey) => {
	return {
		[storageKey]: {
//			"replaceHistoryState": false,
//			"updateHistoryState": true,
//			"forceStoreUpdate": false,
		}
	};
});


/**
 * Determine whether or not search is in progress based on the value in the
 * articles attribute.
 * Note that we are using the dummy value of -99999 to indicate that we are
 * still waiting for data from an api call to the server.
 *
 * @param articles list of articles
 * @returns {boolean} true: search in progress; false: not in progress
 */
const searchInProgress = (articles) => {
	const articleIds = articles.map(article => article.articleId);
	return JSON.stringify(articleIds) === '[-99999]';
};

/**
 * Set individual search filters, depending on the update from search filter modules.
 * Use function call to set filters so that the articleViewer can also get these
 * updates in the article.js reducers.
 *
 * @param params
 *     returnState: new store state to return
 *     payload: settings from action
 *     storageKey: key to use to determine where to set values
 * @returns {*}
 */
const setSearchFilters = (params) => {
	params = Object.assign({
		returnState: {},
		state: {},
		payload: {},
		storageKey: ''
	}, params);
	const {returnState, state, payload, storageKey} = params;

	if (payload.hasOwnProperty('searchEntryFilter')) {
		returnState[storageKey].searchFilters.entry = payload.searchEntryFilter;
	}
	if (payload.hasOwnProperty('searchTypeFilter')) {
		returnState[storageKey].searchFilters.type = payload.searchTypeFilter;
	}
	if (payload.hasOwnProperty('searchSortFilter')) {
		returnState[storageKey].searchFilters.sortBy = payload.searchSortFilter;
	}
	if (payload.hasOwnProperty('searchIssuesFilter')) {
		returnState[storageKey].searchFilters.issueUrl = payload.searchIssuesFilter;
	}
	if (payload.hasOwnProperty('searchArticleSearchFilter')) {
		returnState[storageKey].searchFilters.articleSearch = payload.searchArticleSearchFilter;
	}

	return returnState;

};
export {setSearchFilters};


function searchReducers(state, action) {
	// check if module action call matches SEARCH_RESULTS; if not present, set default to SEARCH_RESULTS
	const moduleAction = (action.params && action.params.type) ? action.params.type : SEARCH_RESULTS;

	if (moduleAction === SEARCH_RESULTS && action.type === DATA_REQUESTED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'SearchResults';

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});

		returnState[storageKey].fetchInProgress = true;

		return returnState;
	}

	if (moduleAction === SEARCH_RESULTS && action.type === DATA_LOADED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'SearchResults';

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});

		const searchResults = Array.isArray(action.payload.SearchMetadata.results) ? action.payload.SearchMetadata.results : [];
		returnState[storageKey].articles = searchResults;
		returnState[storageKey].searchResults = searchResults;
		returnState[storageKey].found = {
			"total": searchResults.length,
			"displayed": searchResults.length,
		};

		// cache return replicaList with fetchQueryParams object as the key
		storeInCache({dataKey: returnState[storageKey].fetchQueryParams, keyType: 'searchResults', data: searchResults, overrideEmptyData: true, state: returnState});
		returnState[storageKey].fetchInProgress = false;

		return returnState;
	}

	if (moduleAction === SEARCHRESULTS_STOREDRESULTS && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'SearchResults';

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});

		returnState[storageKey].searchResults = Array.isArray(action.payload.searchResults) ? action.payload.searchResults : (!isEmpty(returnState[storageKey].searchResults) ? returnState[storageKey].searchResults : []);

		return returnState;
	}


	/**
	 * This section is used to update the value of one or more search filters in the store.
	 * Search filters are determined by one or more associated search (filter) modules.
	 * Store attribute: "searchFilters"
	 */
	if (moduleAction === SEARCH_FILTERS && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'SearchResults';
		let returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
		returnState = setSearchFilters({returnState: returnState, state: state, payload: action.payload, storageKey: storageKey});
		// always set this value
		returnState[storageKey].forceFetch = isTrue(action.payload.forceFetch);
		return returnState;
	}

	return state;
}
export default searchReducers;
