import React from "react";
import {populateArticleActions} from "../../../../utils/articleList/articleActions";
import GenerateCardHorizontal from "../../../../widgets/articleListCards/generateCardHorizontal";
import GenerateCardStacked from "../../../../widgets/articleListCards/generateCardStacked";



/**
 * Generate the Article List Grid page by category
 *
 * @param pageParams params from generatePages
 * @returns {*}
 * @constructor
 */
const GenerateGridListPage = (pageParams) => {
	pageParams = Object.assign({
		props: {},
		mops: {},
		entries: [],
		fullList: [],
		page: 0,
		entryPage: 0
	}, pageParams);
	const props = pageParams.props;
	const mops = pageParams.mops;
	const articleDisplayParams = props.hasOwnProperty('articleDisplayParams') ? props.articleDisplayParams : {};

	// full articleList passed in as fullList
	const articleList = pageParams.fullList;
	// categoriesList is passed in as the entries to loop over
	const categoriesList = pageParams.entries.length > 0 ? pageParams.entries : [];
	if (categoriesList.length < 1) {
		return '';
	}

	const articleActions = populateArticleActions({props: props, mops: pageParams.mops, articleList: articleList});
	const displayClassName = 'display-page' + (pageParams.page === pageParams.entryPage ? ' selected' : '');

	return (
		<div className={displayClassName} data-page={pageParams.page} data-columns={props.pageGridAttributes.columns}>
			{
				categoriesList.map((category) => {
					const filterCategories = props.articleDisplayParams.filterCategories.length > 0 ? props.articleDisplayParams.filterCategories : [];
					let articles = [];
					let renderResults = false;

					for (let i = 0; i < articleList.length; i++) {
						const article = articleList[i];
						// if article contains current category, include it
						if (article.categories.includes(category)) {
							articles.push(article);
							renderResults = true;
						}
					}

					// if there are categories to filter out of the display, and any
					// of the articles' categories are included in the array, don't render
					for (let i = 0; i < articles.length; i++) {
						const article = articles[i];
						for (let c = 0; c < article.categories.length; c++) {
							if (filterCategories.includes(article.categories[c]) === true) {
								renderResults = false;
							}
						}
					}

					if (renderResults) {
						/*
						* Select which template to use, default is 'stacked'
						*/
						if (articleDisplayParams.cardTemplate === 'horizontal') {
							return (
								<GenerateCardHorizontal key={category} entries={articles} actions={articleActions} displayParams={articleDisplayParams} category={category} />
							);
						} else {
							return (
								<GenerateCardStacked key={category} entries={articles} actions={articleActions} displayParams={articleDisplayParams} category={category} />
							);
						}
					} else {
						return '';
					}
				})
			}
		</div>
	);
};
export {GenerateGridListPage};
