/*
	Gather all reducers to add to the list in reduceReducers.  All reducers
	will then be passed to the store on store creation.

	import all module Reducers used in the product
	include each reducer in the list for reduceReducers

	Each reducer should be geared towards a specific data object.  Different reducers
	should be expected to make a different API call.  A module doesn't have to
	implement its own reducers if it uses an existing data object.
 */

import reduceReducers from 'reduce-reducers';

import base from "../../store/reducers/baseReducer";
import articleList from "../library/reducers/articleList";
import issues from "../library/reducers/issues";
import search from "../library/reducers/search";
import filterList from "../library/reducers/filterList";
import articles from "../library/reducers/articles";
import replica from "../library/reducers/replica";
import globals from "../library/reducers/globals";
import highlights from "../library/reducers/highlights";



const gatheredLibraryReducers = reduceReducers(
	base,
	articleList,
	issues,
	search,
	filterList,
	articles,
	replica,
	globals,
	highlights
);
export {gatheredLibraryReducers};
