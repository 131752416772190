import {getStoreValue} from "../utils/storeValue";
import {GLOBALS} from "../modules/_MODULE_GLOBALS/constants";

const useModal = () => {
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
	
	function toggle(storageKey, params) {
		const isShowing = getStoreValue({type: GLOBALS, storageKey: storageKey, attributeKey:"isShowing"});
		Object.keys(params).map((key,idx) => {
			let p = {};
			p[key] = params[key]; 
			updateData(p, {type: GLOBALS, storageKey: storageKey});
			return '';
		});
		updateData({"isShowing": !isShowing}, {type: GLOBALS, storageKey: storageKey});
	}

	return {
		toggle
	};
};

export {useModal};
