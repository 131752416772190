import {arrayRemoveDups, arrayFromStringTrim} from "../../utils/arrayUtils";
import {stripTags} from "../../utils/htmlUtils";
import {isEmpty} from "../../utils/generalUtils";


/**
 * Return the first category string from a list of categories that is NOT in the
 * hideCategories array.  If no category can be found, or it tests as empty,
 * just return empty string.
 *
 * @param hideCategories array of categories that should NOT be visible
 * @param categories string (or array) of categories to look in; not hidden
 * @returns {*|string}
 */
const getVisibleCategory = (hideCategories, categories) => {
	if (!Array.isArray(hideCategories)) {
		hideCategories = typeof hideCategories === 'string' ? arrayRemoveDups(arrayFromStringTrim(hideCategories, '~')) : [];
	}
	if (!Array.isArray(categories)) {
		categories = typeof categories === 'string' ? arrayRemoveDups(arrayFromStringTrim(categories, '~')) : [];
	}
	const category = categories.find(category => !hideCategories.includes(category));
	return isEmpty(category) ? '' : stripTags(category);
};
export {getVisibleCategory};

