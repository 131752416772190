import {getStoreValue} from "../../utils/storeValue";
import {GLOBALS, ID_NOT_SET} from "../_MODULE_GLOBALS/constants";
import {navigationKeyMatch} from "./globalNavigator";
import {getGlobalDataValue, getModuleInstanceAttributes} from "./globalLibraryData";
import {storeInCache} from "../../utils/manageStoreCacheData";
import {isTrue, isEmpty} from "../../utils/generalUtils";
import {mergeNoDups, getObjectFromJSON, clone} from "../../utils/objectUtils";
import {setDOMProperties} from "./setDOMProperties";
import {getReplicaPages} from "../../utils/replicaList/replicaActions";


/**
 * Set of functions to handle setting URL for
 *     address bar
 *     share buttons
 *
 * and set of functions to handle targeting from URL with share attributes.
 *
 */


/**
 * Given a set of parameters that can be used to generate a share url,
 * form and return the url.
 * Base url and query parameters are gathered into config on the server and
 * are fixed for the current content hub based on the initial url.  Note that
 * baseUrl is configured to end in a '/'.
 * Shareables is gathered from configuration properties.
 *
 * Current structure and current pane are dynamic and vary by current view.
 *
 * @param params share parameters
 * @returns {{shareHistoryAttributes: {moduleName: (*|string), shareParams: *}, isShareable: boolean, shareUrl: string}}
 */
const createShareUrl = (params) => {
	params = Object.assign({
		shareParams: {},
		moduleName: '',
		storageKey: '',
		urlAttribute: '',
		urlValues: []
	}, params);

	const shareParams = params.shareParams;
	const currentStructure = getStoreValue({attributeKey: 'currentStructure'});
	const pane = params.shareParams.pane;
	const shareables = getStoreValue({attributeKey: 'shareables'});
	const shareableModule = shareables.hasOwnProperty(currentStructure) && shareables[currentStructure].hasOwnProperty(pane) ? shareables[currentStructure][pane] : '';

	/*
	 *  Generate the queryString component of shareUrl
	 *  - queryParams: passed in via the url
	 *  - shareQueryParams: parameters passed by caller modules to be appended to the queryString
	 *    example: ?section={section_title}    used in articleList and articleViewer for `Textbook` docType
	 */
	const baseUrl = getStoreValue({attributeKey: 'baseUrl'});
	let queryString = '';
	const queryParams = getStoreValue({attributeKey: 'queryParams'});
	const shareQueryParams = shareParams.attributes.hasOwnProperty('shareQueryParams') ? shareParams.attributes.shareQueryParams : {};
	const queryStringParams = mergeNoDups(queryParams, shareQueryParams);
	Object.keys(queryStringParams).forEach((param) => {
		if (!isEmpty(queryStringParams[param])) {
			queryString += queryString.length > 0 ? '&' : '?';
			queryString += param + '=' + queryStringParams[param];
		}
	});

	const isShareable = (params.moduleName !== '' && params.moduleName === shareableModule);
	let urlShareParams = '';
	if (isShareable && !isEmpty(params.urlAttribute) && !isEmpty(params.urlValues)) {
		urlShareParams = createUrlShareParams(params);
	}

	// Update attributes for shareParams; remove articleList
	shareParams.attributes = shareParams.hasOwnProperty('attributes') ? shareParams.attributes : {};
	if (shareParams.attributes.hasOwnProperty('articleList')) {
		delete shareParams.attributes.articleList;
	}
	const urlHashString = !isEmpty(shareParams.attributes.urlHash) ? shareParams.attributes.urlHash : '';

	return {
		shareHistoryAttributes: {
			shareParams: shareParams,
			moduleName: shareableModule
		},
		shareUrl: baseUrl + urlShareParams + queryString + urlHashString,  // appears in the url/history
		href:  baseUrl + urlShareParams,  // used to generate share button(s) url
		isShareable: isShareable
	};
};
export {createShareUrl};


/**
 * Generate the values for the share url
 *
 * @param params
 * @returns {string}
 */
const createUrlShareParams = (params) => {
	params = Object.assign({
		urlAttribute: '',
		urlValues: []
	}, params);
	let urlShareParams = params.urlAttribute + '/';

	// if first value has been rejected, go to home
	if (params.urlValues.length > 0 && isEmpty(params.urlValues[0])) {
		return '';
	}
	params.urlValues.forEach(urlValue => {
		urlShareParams += !isEmpty(urlValue) ? encodeURIComponent(urlValue) + '/' : '';  // issue/category name can have characters that mess with url
	});

	return urlShareParams;
};

/**
 * Stores history data in hash storage and returns a generated history key for use in populating
 * the current history value.
 *
 * @param shareObject object that generates a share
 * @returns {*} string key to the hash storage object
 */
const storeShareForHistory = (shareObject) => {
	const shareParams = clone(shareObject.shareParams);
	delete(shareParams.attributes.articleList);
	delete(shareParams.attributes.replicaList);

	// store navigationKeys in hash storage and keep hash value for reference and store in history
	shareParams.moduleProps.navigationKeys = storeInCache({
		dataKey: shareParams.moduleProps.navigationKeys,
		data: shareParams.moduleProps.navigationKeys
	});

	const historyStore = {
		shareParams: shareParams,
		shareUrl: shareObject.shareUrl
	};

	// store shareParams in hash storage and get/return hashKey for pushing to history
	return storeInCache({dataKey: historyStore, keyType: 'history', data: historyStore});
};


/**
 * Call to update the address bar.  We check if a share url has been created.  Then stringify
 * the share parameters that we want to store to recreate the shared page on browser
 * back/forward button.
 *
 * Note: The full articleList object requires a lot of space.  If we store it in history each time
 * we navigate, we will be storing a lot of redundant data.  We store the article list by fetchQueryParams
 * as the key and remove it from the navigation attributes.  The article list is stored here and also
 * each time we do a fetch, which is a little redundant, but probably safer.
 *
 * @param params
 *     shareHistoryKey: string key to store in history
 *     shareUrl: the generated share url for display in the address bar
 *     replaceHistoryState: call replaceState rather thatn pushState; usually only at start of library
 */
const updateHistory = (params) => {
	params = Object.assign({
		shareHistoryKey: '',
		shareUrl: '',
		replaceHistoryState: false
	}, params);
	try {
		if (isTrue(params.replaceHistoryState)) {
			window.history.replaceState(params.shareHistoryKey,"", params.shareUrl);
		} else {
			window.history.pushState(params.shareHistoryKey,"", params.shareUrl);
		}
	} catch (err) {
		console.log(err);
	}
};

/**
 *
 */
const createTrackingParams = (shareParams) => {
	const trackingParams = {};
	if (shareParams.hasOwnProperty('attributes')) {
		if (shareParams.attributes.hasOwnProperty('articleId') && shareParams.attributes.hasOwnProperty('articleList')) {
			const articleId = shareParams.attributes.articleId;
			let article = {};
			shareParams.attributes.articleList.forEach((a) => {
				if (a.articleId === articleId) {
					article = a;
				}
			});
			trackingParams['article title'] = article.title;
			trackingParams.category = article.categories ? article.categories : [];
			trackingParams['document name'] = article.issueName;
		} else if (shareParams.attributes.hasOwnProperty('folio')) {
			const pagesData = getReplicaPages({folio: shareParams.attributes.folio, pageAttributes: shareParams.attributes});
			trackingParams['page folio'] = pagesData.pages.map(page => {
				trackingParams['document name'] = page.issueName;
				return page.folio;
			});
		}
	}
	return trackingParams;
};


const updateShareUrl = (params) => {
	params = Object.assign({
		navigationKey: '',
		moduleProps: {},
		attributes: {}

	}, params);
	const {navigationKey, moduleProps, attributes} = params;

	// get navigationKeys from params and convert to javascript object from configuration string, then find module keys for the specific key
	const navigationKeys = getObjectFromJSON(moduleProps.navigationKeys, {});
	const moduleKeys = navigationKeys.hasOwnProperty(navigationKey) && navigationKeys[navigationKey].hasOwnProperty('modules') ?
		navigationKeys[navigationKey].modules :
		[];
	const moduleKey = moduleKeys.length > 0 ? moduleKeys[0] : {};

	manageShareable({
		moduleKey: moduleKey,
		shareParams: {
			moduleProps: moduleProps,
			navigationKey: navigationKey,
			attributes: attributes
		}
	});
};
export {updateShareUrl};

/**
 * Provide a one-stop function call from manageUpdateAndNavigation call from modules to use to
 *     generate a shareable url if appropriate
 *     set the address bar (and history) from the generated url
 *     make the url available to the share widget/module
 *
 * Keep track of the current (last set) shareable url and use it to ensure that the
 * address bar (and history) is only set if there is a change in shareable.
 *
 * Note: The current share url will be saved to the redux store through updateData
 *     in the "globals" storageKey so that it is available to any module to respond to.
 *     This is also used to check for duplicate.
 *
 * @param params shareable parameters
 *     shareProps: props for module for navigation
 *         navigationKey
 *         navigationKeys
 *         attributes
 *     moduleKey: key for module navigation
 *     pane: pane for new module display if share
 */
const manageShareable = (params) => {
	params = Object.assign({
		moduleKey: {},
		pane: null,
		shareParams: {}
	}, params);

	const moduleKey = params.moduleKey;
	const moduleInstance = getModuleInstanceAttributes({module: moduleKey.module, storageKey: moduleKey.storageKey, attributes: ['moduleId','instanceId']});
	const shareParams = clone(params.shareParams);
	shareParams.pane = params.pane ? params.pane : getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''});

	shareParams.moduleProps = Object.assign(
		shareParams.moduleProps,
		{
			name: moduleKey.module,
			storageKey: moduleKey.storageKey,
			instanceId: moduleInstance.instanceId,
			moduleId: moduleInstance.moduleId
		});

	// generic updateData function
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});

	const navigationKey = shareParams.hasOwnProperty('navigationKey') ? shareParams.navigationKey : '';
	const attributes = shareParams.hasOwnProperty('attributes') ? shareParams.attributes : {};
	const storageKey = moduleKey.hasOwnProperty('storageKey') ? moduleKey.storageKey : '';
	const moduleName = moduleKey.hasOwnProperty('module') ? moduleKey.module : '';

	// used to generate the path of the share url
	const moduleMatchKey = navigationKeyMatch.hasOwnProperty(navigationKey) && navigationKeyMatch[navigationKey].hasOwnProperty(moduleName) ? navigationKeyMatch[navigationKey][moduleName] : navigationKeyMatch.noMatch;
	const urlAttribute = moduleMatchKey.url.attribute;
	const urlValues = [];
	// parts of the path that are required and must get a value
	moduleMatchKey.url.values.forEach(type => {
		if (attributes.hasOwnProperty(type)) {
			urlValues.push(attributes[type]);
		} else if (type === null) {
			urlValues.push(null);
		} else {
			urlValues.push('-');
		}
	});
	// parts of the path that are optional, and are only used if there is a non-empty value
	if (Array.isArray(moduleMatchKey.url.optional)) {
		moduleMatchKey.url.optional.forEach(type => {
			if (attributes.hasOwnProperty(type) && !isEmpty(attributes[type])) {
				urlValues.push(attributes[type]);
			}
		});
	}

	// special attributes that trigger different behavior
	const fromHistory = isTrue(attributes.fromHistory);
	const navigateToHome = isTrue(attributes.navigateToHome);
	const replaceHistoryState = isTrue(attributes.replaceHistoryState);

	const shareObjectParams = {
		shareParams: shareParams,
		moduleName: moduleName,
		storageKey: storageKey,
		urlAttribute: urlAttribute,
		urlValues: urlValues
	};
	const shareObject = createShareUrl(shareObjectParams);


	// TODO: find a better way to set this
	// special case issueUrl since there is no target module to set this value
	if (!isEmpty(shareObject.shareUrl)) {
		const issueUrl = !isEmpty(attributes.issueUrl) ? attributes.issueUrl : '';
		updateData({currentIssueUrl: issueUrl}, {type: GLOBALS, storageKey: 'globals'});
	}


	if (!isEmpty(shareObject.shareUrl) && !urlValues.includes(ID_NOT_SET)) {
		const shareHistoryKey = storeShareForHistory(shareObject.shareHistoryAttributes);
		const lastShareHistory = getStoreValue({attributeKey: 'currentShareable', storageKey: 'globals', default: {}});


		// our generated url ends with a '/' if there are no query params
		// TODO: Create a better method to decide if the share is the same as the last (ie. only use required properties for comparison)
		// for now, don't set share if url is he same; this might be a problem for proper back button
		if ((isTrue(shareObject.isShareable) || navigateToHome) && shareHistoryKey !== lastShareHistory.shareHistoryKey && shareObject.shareUrl !== lastShareHistory.shareUrl) {
			// only update browser history if not pulling from history
			if (!fromHistory) {
				updateHistory({
					shareHistoryKey: shareHistoryKey,
					shareUrl: shareObject.shareUrl,
					replaceHistoryState: replaceHistoryState
				});
			}
			// always update global share parameters, even if not updating browser history as the url will (usually) change
			updateData({currentShareable: {shareHistoryKey: shareHistoryKey, shareUrl: shareObject.shareUrl}, share: {url: shareObject.shareUrl, href: shareObject.href}}, {type: GLOBALS, storageKey: 'globals'});

			setDOMProperties(
				{
					module: moduleName,
					storageKey: storageKey,
					setDOMFocus: false
				}
			);
		}
	}
};
export {manageShareable};
