import {ID_NOT_SET} from "../../modules/_MODULE_GLOBALS/constants";
import {getIntValue, isEmpty, isTrue} from "../generalUtils";


/**
 * ArticleList needs to know which article to mark as current.
 *
 * Detect whether or not to set articleId to a value or to reset it
 * to ID_NOT_SET.
 * 
 * @param params module params
 *     moduleProps: module props
 * @returns {{}}
 */
const getCurrentArticleId = (params) => {
	params = Object.assign({
		moduleProps: {},
	}, params);
	const moduleProps = params.moduleProps;

	// not current pane
	if (!isTrue(moduleProps.isMyPane)) {
		moduleProps.articleId = ID_NOT_SET;
	} else {
		if (moduleProps.articleId !== ID_NOT_SET) {
			// articleId is set; no change to moduleProps
		} else if (!isEmpty(moduleProps.articleList)) {
			// try to set articldId from articleList
			moduleProps.articleId = getIntValue(moduleProps.articleList[0].articleId, ID_NOT_SET);
		} else {
			moduleProps.articleId = ID_NOT_SET;
		}
	}
	return moduleProps;
};
export {getCurrentArticleId};
