import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {SEARCH_RESULTS, ID_NOT_SET} from "../../_MODULE_GLOBALS/constants";
import { fetchData, updateData } from '../../../store/actions';
import {Translate} from "../../../locales/locales";
import GenerateTitle from "../../../widgets/generateTitle";
import {generateMops, getActiveAttributeState, displayOnDevice, devicePageGrid} from "../../../utils/moduleSetup";
import {isTrue, isEmpty} from "../../../utils/generalUtils";
import GeneratePages, {GenerateInProgressPage, manageDeviceResize} from "../../../widgets/generatePages";
import {GenerateReplicaSummary, GenerateSummary} from "../../../widgets/generateSummary";
import * as Tracking from "../../../utils/tracking";
import {getObjectFromJSON, clone} from "../../../utils/objectUtils";
import {getDestinations} from "../../../utils/getDestinations";
import {populateArticleActions} from "../../../utils/articleList/articleActions";
import {checkIfMyPane} from "../../globals/globalLibraryData";
import {setDOMProperties} from "../../globals/setDOMProperties";
import {DisplaySearchResultsFound} from "./searchDisplayWidget";
import {useMountPostRender} from "../../../hooks/useMount";
import {defineSearchResultFilters} from "./searchUtils/filterUpdate";
import {NavigationButton} from "../responsiveButton/buttonWidgets/navigationButton";
import {addClass} from "../../../utils/generateClassName";
import {populateReplicaActions} from "../../../utils/replicaList/replicaActions";
import {useFetchAttributesChange, useFetchComplete} from "../../../hooks/useFetchChange";
import {getSearchResults} from "../../../utils/searchResults/getSearchResults";



// list of possible query params sent with api call
// key is props name; value is fetch parameter
const configQueryParams = {
	'maxResults':'maxResults',  // note that this is different from upp calls which use pageSize
	'categories':'categories',
	'searchQuery':'searchQuery',
	'search_type':'search_type',
	'search_sort':'search_sort',
	'articleSearch':'articleSearch',
	'documentId':'documentId',  // internally we use issueUrl; external fetch, documentId
	'maxCharacters':'maxCharacters',
	'publicationIds':'publicationIds',
	'u1': 'u1',
};

// Note: trackingValues are only used to determine what gets sent to tracking for dropdown options
const allSearchFilters = {
	"entry": {default: null, queryParam: "searchQuery", storageTerm: 'searchTerm'},
	"issueUrl": {default: "", queryParam: "issueUrl", storageTerm: 'searchIssue'},
	"type": {default: "", queryParam: "search_type", storageTerm: 'searchType',	trackingValues: {"0": "any terms", "1": "all terms", "2": "exact match"}},
	"sortBy": {default: "", queryParam: "search_sort", storageTerm: 'searchSort', trackingValues: {"pub_date": "published date", "relevance": "relevance"}},
	"articleSearch": {default: "", queryParam: "articleSearch", storageTerm: 'searchTarget', trackingValues: {"article": "article", "replica": "page"}}
};


/**
 * Loop through filters, checking to see if they are a query parameter that
 * must be added directly to the props object.
 *
 * @param searchProps search props object being built to add to props
 * @returns {*} searchProps object
 */
const addQueryParamsFiltersToProps = (searchProps) => {
	Object.keys(allSearchFilters).forEach((filterName) => {
		const filter = allSearchFilters[filterName];
		if (filter.hasOwnProperty('queryParam')) {
			searchProps[filter.queryParam] = encodeURIComponent(searchProps.searchFilters[filterName]);
		}
	});
	// add special section for translation of issueUrl to documentId
	const selectedIssue = searchProps.issues.find(issue => issue.issueUrl === searchProps.searchFilters.issueUrl);
	searchProps.documentId = typeof selectedIssue !== 'undefined' ? selectedIssue.documentId : '0';

	return searchProps;
};


/**
 * On search complete, track search.
 *
 * NOTE: all filters are used directly here...
 * TODO: figure out if we can loop through allSearchFilters without invoking filter names
 *
 * @param searchFilters current filters
 * @param numArticles number of articles
 */
const trackSearched = (searchFilters, numArticles) => {
	if (searchFilters.entry && searchFilters.entry !== '' && searchFilters.entry !== null) {
		const target = searchFilters.articleSearch ? 'article' : 'replica';
		const trackingProperties = {
			"module": "search results",
			"search term": searchFilters.entry,
			"number of results": numArticles,
			"options": allSearchFilters.type.trackingValues[searchFilters.type],
			"sorted by": allSearchFilters.sortBy.trackingValues[searchFilters.sortBy],
			"target": allSearchFilters.articleSearch.trackingValues[target],
			"context": searchFilters.issueUrl === '' ? "All Issues" : searchFilters.issueUrl
		};
		Tracking.libraryTrack("searched", trackingProperties);
	}
};


// Note: articleClicked/issueClicked calls are handled the same as for articleList
/**
 * Generate a single page of search results for paging.
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GeneratePage = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		entries: [],
		entryPage: 0,
		page: -1
	}, params);
	const { props, mops, entries, page } = params;

	const articleSearch = isTrue(props.articleSearch, {defaultValue: true});
	const displayParams = {
		displayThumbnail: isTrue(props.displayThumbnail),
		displayThumbnailOnLeft: isTrue(props.displayThumbnailOnLeft),
		displayIssue: isTrue(props.displayIssue),
		displaySummary: isTrue(props.displaySummary),
		displayCategory: isTrue(props.displayCategory)
	};

	const entryAttributes = {
		className: articleSearch ? 'article-search-page' : 'replica-search-page',
		entryClass: articleSearch ? 'article-entry' : 'replica-entry',
		entryStyles: {
			width: (100 / props.pageGridAttributes.columns) + '%'
		}
	};

	if (articleSearch) {
		// search entries come with wds-articleId, need to switch to contentpool articleId
		entries.forEach(function (item) {
			item.id = item.cmsId;
			item.articleId = item.cmsId;
		});
		entryAttributes.entryActions = populateArticleActions({
			props: props,
			mops: mops,
			articleList: entries,
			updateHistoryState: false
		});
	} else {
		entryAttributes.entryActions = populateReplicaActions({
			props: props,
			mops: mops,
			replicaList: entries,
			updateHistoryState: false,
			forceStoreUpdate: true
		});
	}

	return (
		<div className={'display-page ' + (page === params.entryPage ? 'selected' : '')} data-page={page}>
			<ul className={entryAttributes.className}>
				{
					entries.map((entry, index) => {
						const keyValue = articleSearch
							? !isEmpty(entry.id) ? entry.id : index
							: !isEmpty(entry.pageNumber) ? entry.issueUrl + '_' + entry.pageNumber : index;
						return (
							<li key={keyValue} data-entryid={entry.id} className={entryAttributes.entryClass} style={entryAttributes.entryStyles}>
								{articleSearch
									? <GenerateSummary entry={entry} actions={entryAttributes.entryActions} displayParams={displayParams}/>
									: <GenerateReplicaSummary entry={entry} actions={entryAttributes.entryActions} displayParams={displayParams} template={'search'}/>
								}
							</li>
						);
					})
				}
			</ul>
		</div>
	);
};



/**
 * Generate a page that displays a message when there are no results found.
 *
 * @returns {*}
 * @constructor
 */
const GenerateNoResultsPage = () => {
	return (
		<div className="no-results">
			{Translate.Text({id: 'search.results.noResults'})}
		</div>
	);
};

/**
 * Generate a page that displays a message when there are no results found.
 *
 * @returns {*}
 * @constructor
 */
const GenerateClearFiltersPage = (params) => {
	params = Object.assign({
		props: {}
	}, params);
	const props = clone(params.props);
	const issueUrl = isEmpty(props.searchFilters.issueUrl) ? '' : props.searchFilters.issueUrl;
	const navigationParams = {
		className: 'navigate-toc-button',
		title: "search.results.clearFilters.button",
		tag: `button`,
		navigationKey: 'toc',
		navigationKeys: params.props.navigationKeys,
		replacementValues: {issueUrl: issueUrl},
		attributes: {
			issueUrl: issueUrl,
			articleId: ID_NOT_SET,
			fromHistory: false,
			replaceHistoryState: false
		}
	};

	return (
		<div className="cleared-filters">
			{Translate.Text({id: 'search.results.clearFilters.message'})}
			{props.displayClearedSearchHomeButton ?
				<NavigationButton {...navigationParams} /> : ''
			}
		</div>
	);
};


export const SearchResultsModule = (props) => {
	// updating value triggers re-render; for pagination
	const [paginationWidth, setPaginationWidth] = useState(window.innerWidth);
	const [searchResults, setSearchResults] = useState([]);
	const [fetchInProgress, setFetchInProgress] = useState(false);


	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: SEARCH_RESULTS,
		defaultClass: 'search-results',
		title: 'search.results.title',
		titleTag: 'h2',
		configQueryParams: configQueryParams
	});
	const moduleProps = clone(props);
	// title used by GeneratePages for aria-label
	moduleProps.title = Translate.Text({'id': 'search.results.title'});

	// run once at startup
	useMountPostRender(() => {
		manageDeviceResize(setPaginationWidth);
	});


	/**
	 * Called to manage whether the module fetches data.  If the hook calls the
	 * callback, then call getSearchResults which will determine whether to
	 * fetch a new set of results or get an existing set from stored data.
	 *
	 * Note: this uses mops, so that must come first.
	 * Note: props is used internally in the hook.
	 */
	useFetchAttributesChange(() => {
		// only call to get search results if search entry is not empty
		if (!isEmpty(mops.queryParams.searchQuery)) {
			setFetchInProgress(true);
			getSearchResults({storageKey: mops.storageKey, queryParams: mops.queryParams});
		}
	}, {type: SEARCH_RESULTS, props: moduleProps, queryParams: mops.queryParams});

	/**
	 * Call hook to check to see if the fetchInProgress has been set/changed in the reducer.
	 * fetchInProgress is set true when fetch is started (DATA_REQUESTED)
	 * and set to false when fetch is done (DATA_LOADED)
	 */
	useFetchComplete((isInProgress) => {
		isInProgress = isTrue(isInProgress, {defaultValue: false});
		setFetchInProgress((isInProgress));
		if (!isInProgress) {
			const storedData = getSearchResults({storageKey: mops.storageKey, queryParams: mops.queryParams, returnStoredDataOnly: true});
			setSearchResults(storedData.searchResults);
			trackSearched(props.searchFilters, searchResults.length);
		}
	}, {requestInProgress: props.fetchInProgress, fetchInProgress: fetchInProgress});


	// setup ref to the search entry input element for focus
	// note: filter value will be passed through updateData from searchEntry module
	const moduleDOMElement = useRef(null);
	// call if the entry field changes and isMyPane to set focus and title
	useEffect(() => {
		if (props.isMyPane && props.searchFilters.entry !== '' && moduleDOMElement.current !== null) {
			setDOMProperties({
				module: 'searchResults',
				storageKey: props.storageKey,
				moduleDOMElement: moduleDOMElement.current,
				useModuleTitle: props.useModuleTitle,
				useModuleFocus: props.useModuleFocus,
				titleValue: 'Search'
			});
		}
	}, [props.storageKey, props.isMyPane, props.useModuleFocus, props.useModuleTitle, props.searchFilters.entry]);


	const clearFilters = isEmpty(props.searchFilters.entry);
	// only noResults if there is actually an active entry filter
	const noResults = !clearFilters && searchResults.length === 0;
	/*
	 * Generate jsx for search results list if displayOnDevice
	 * Call to GeneratePages since, as a list,
	 * it could require multiple pages and pagination block.
	 *
	 * If props.searchInProgress is set and true, generate in-progress page
	 *
	 * start a page 0; update this if we can start at another page
	*/
	const searchPage = 0;
	const additionalSearchClasses = [
		fetchInProgress ? 'search-in-progress' : '',
		clearFilters ? 'cleared-filters' : '',
		noResults ? 'no-results' : ''
	];
	const className = addClass(mops.className, additionalSearchClasses);
	if (displayOnDevice(props)) {
		return (
			<div className={className} tabIndex="-1" ref={moduleDOMElement}>
				<GenerateTitle titleParams={mops.titleParams} />
				<DisplaySearchResultsFound entry={props.searchFilters.entry} results={searchResults} searchInProgress={fetchInProgress} clearFilters={clearFilters} />
				{fetchInProgress ?
					<GenerateInProgressPage displaySpinner={false} textId={"search.results.inProgress"} /> :
					clearFilters ?
						<GenerateClearFiltersPage props={props} /> :
						noResults ?
							<GenerateNoResultsPage /> :
							<GeneratePages props={moduleProps} fullList={searchResults} entries={searchResults} className={'search-pages'} cssClass={mops.cssClass} GeneratePage={GeneratePage} entryPage={searchPage} />
				}
			</div>
		);
	} else {
		return null;
	}
};


/**
 * Map state (store) data for the articleList module; added to module props.
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {{isMyPane: boolean, useModuleTitle: boolean, navigationKeys: (null|*), destinations: *, searchInProgress: boolean, active: (*|string), searchFilters: {}, storageKey: (*|string), clearSearch: boolean, pageGridAttributes: {}, grid: (*), isLoggedIn: boolean, searchFilters: string, useModuleFocus: boolean, articles: ([]|[{pageNumber: number, articleId: number}]|*|*[]), currentViewedDocumentId: (*|number)}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : SEARCH_RESULTS;
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};
	const globalState = !isEmpty(state.globals) ? state.globals : {};

	// capture some values for other attributes use
	const myPane = checkIfMyPane({moduleName: 'searchResults', instanceId: props.instanceId, storageKey: storageKey});

	const definedFilters = defineSearchResultFilters({
		storeState: storeState,
		state: state,
		props: props,
		allSearchFilters: allSearchFilters
	});

	const grid = getObjectFromJSON(props.grid, {});

	let searchProps = {
		storageKey: storageKey,
		navigationKeys: !isEmpty(props.navigationKeys) ? props.navigationKeys : {},
		active: getActiveAttributeState(props),
		destinations: getDestinations(props),
		searchFilters: definedFilters.searchFilters,
		// clear the search? usually happens when Back button is clicked
		clearSearch: isTrue(storeState.clearSearch, {defaultValue: false}),
		issues: !isEmpty(state.documentList) ? state.documentList : [],
		singleDocumentCollection: isTrue(state.singleDocumentCollection, {defaultValue: false}),
		isMyPane: myPane.isMyPane,
		myPane: myPane.myPane,
		isLoggedIn: isTrue(globalState.isLoggedIn, {defaultValue: false}),
		currentViewedDocumentId: !isEmpty(globalState.currentViewedDocumentId) ? globalState.currentViewedDocumentId : 0,
		grid: grid,
		pageGridAttributes: devicePageGrid(grid),
		useModuleFocus: isTrue(props.useModuleFocus, {defaultValue: false}),
		useModuleTitle: isTrue(props.useModuleTitle, {defaultValue: false}),
		displayClearedSearchHomeButton: isTrue(props.displayClearedSearchHomeButton, {defaultValue: false}),
		fetchInProgress: isTrue(storeState.fetchInProgress, {defaultValue: false}),
	};

	searchProps = addQueryParamsFiltersToProps(searchProps);

	return searchProps;
};



/**
 * Actions that can be called by this module.  Each action is added to props so
 * that it can be called in the module, but defined in a single place with
 * appropriate parameters for the action call by this module.
 *
 * actions:
 *     fetchData: call to get data from api call
 *     updateData: call to update the store with new data depending on type
 *
 * @param dispatch call action
 * @returns {{fetchData: fetchData, updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		fetchData: (params) => {
			params.type = params.hasOwnProperty('type') ? params.type : SEARCH_RESULTS;
			dispatch(fetchData(params));
		},
		updateData: (payload, params) => {
			params.type = params.hasOwnProperty('type') ? params.type : SEARCH_RESULTS;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchResultsModule);
