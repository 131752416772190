import $ from 'jquery';
import React, { useState, useEffect } from 'react';
import {SHARE_BUTTONS} from "../modules/_MODULE_GLOBALS/constants";
import embedJavascript from "../utils/embedJavascript";
import {addClass} from "../utils/generateClassName";
import * as Tracking from "../utils/tracking";
import {openInAppBrowser} from "../utils/nativeAppInterface";
import {isEmpty} from "../utils/generalUtils";
import getDeviceType from "../utils/getDeviceType";
import {getStoreValue} from "../utils/storeValue";


/**
 * Called from a MutationObserver linked to our elfSight wrapper (.elfsight-wrapper) element.
 * When it changes, usually because of a resetWidget call, we update the DOM for the
 * elfSight elements.
 * This is necessary, because the only way we know to make the buttons pay attention to
 * changes to the address bar, is to have the widget(s) reset.  This then generates a
 * new set of buttons.
 *
 * Note: We must disconnect the observer before making DOM changes, as we are making
 * changes to the DOM element that we are listening to.  If we don't disconnect first, we
 * end up in an endless loop.  We then reconnect (observe) once we make our changes.
 *
 * DOM changes include:
 *     find the elfSight share buttons and add tabindex=0 to each one
 *         elfSight share buttons are <a> tags, but don't have an href, so are not keyboard accessible
 *
 * @param params
 *     mutationsList: list of changes from the MutationObserver
 *     observer: pointer to the observer instance
 *     targetNode: the DOM node that we are listening to for changes
 *     config: config parameters we use for what to listen to
 */
const handleElfSightChange = (params) => {
	params = Object.assign({
		mutationsList: [],
		observer: null,
		targetNode: null,
		config: {}
	}, params);
	// disconnect first, make update, re-connect
	params.observer.disconnect();
	const shareButtonList = document.querySelectorAll('.elfsight-wrapper .eapps-social-share-buttons .eapps-social-share-buttons-item');
	if (shareButtonList.length > 0) {
		shareButtonList.forEach((element) => {
			if (element.nodeName.toLowerCase() === 'a') {
				element.setAttribute('role', 'button');
				if (isEmpty(element.href)) {
					element.setAttribute('href', '//share_to/' + element.title);
				}
			}
		});
	}
	params.observer.observe(params.targetNode, params.config);
};

/**
 * Show/Hide the elfSight buttons on `xs` and `sm` screens
 */
const toggleElfSightButtons = (displayShareButtons, storageKey) => {
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
	updateData({displayShareButtons:!displayShareButtons}, {type: SHARE_BUTTONS, storageKey: storageKey ? storageKey : 'articleViewer'});
};


/**
 * Generate ElfSight buttons: uses https://elfsight.com/
 *
 * @param params
 *     elfSightCode: an ElfSight embed code
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateElfSightShareButtons = (params) => {
	params = Object.assign({
		elfSightCode: '',
		clickAction: null,
		displayShareButtons: false,
		storageKey: ''
	}, params);
	// determine if touch is supported in the client and indicate in className
	// ElfSight is used for touch devices with screen sizes md, lg, xl, and xxl
	const supportsTouch = 'ontouchstart' in document.documentElement;
	let wrapperClassName = supportsTouch ? 'elfsight-wrapper has-touch' : 'elfsight-wrapper no-touch';
	// ElfSight is also used if navigator.share() is not supported on xs and sm device
	wrapperClassName = typeof navigator.share !== 'function' ? addClass(wrapperClassName, 'no-navigator') : wrapperClassName;
	// determine if device size is `xs` or `sm` in which case we display a single share icon that show/hides the elfsight buttons
	const device = getDeviceType();
	const displayClass = params.displayShareButtons ? 'toggle-show' : 'toggle-hide';
	wrapperClassName = device === 'xs' || device === 'sm' ? addClass(wrapperClassName, displayClass) : wrapperClassName;
	return params.elfSightCode !== '' ? (
		<div className={wrapperClassName}>
			{device === 'xs' || device === 'sm' ?
				<div className={'share-button-wrapper elfsight'}>
					<button
						className={'share-button'}
						onClick={(event) => toggleElfSightButtons(params.displayShareButtons, params.storageKey)}>
						<span className={'fas fa-share-alt'} />
						<span className={'screen-reader'}>Share</span>
					</button>
				</div> : ''}
			<div className={'elfsight-app-' + params.elfSightCode} onClick={params.clickAction} />
		</div>) : '';
};


/**
 * Generate a button to share via navigator.share() or App code
 *
 * @param params
 *     clickAction: function to run on button click
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateNativeShareButton = (params) => {
	params = Object.assign({
		clickAction: null
	}, params);
	return (
		<div className={'share-button-wrapper'}>
			<button
				className={'share-button'}
				onClick={params.clickAction}>
				<span className={'fas fa-share-alt'} />
				<span className={'screen-reader'}>Share</span>
			</button>
		</div>);
};


/**
 * Generate the share button(s) for an article based on touch capability, device size, app
 *
 * NOTE: Currently this is only enabled in the articleViewer by way of the page templates.
 *       Future uses might include issue list, category list, or other types of sharing
 *
 * @param params article params
 *     elfSightCode: the ElfSight code string, e.g. `e4e722e9-68c4-4b2c-a54a-1018b0ab644c`
 *     shareTitle: title of the shared article
 *     isApp: true/false the share is from the app
 *     platform: if app share, whether it's ios or android
 *     moduleName: name of module, e.g. `articleViewer`
 *     displayShareButtons: true/false toggle display of share buttons on `xs` and `sm` devices
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateShareButtons = (params) => {
	params = Object.assign({
		elfSightCode: '',
		shareTitle: '',
		isApp: false,
		platform: '',
		moduleName: '',
		displayShareButtons: false,
		storageKey: ''
	}, params);

	const [shareType, setShareType] = useState('ElfSight');
	const [shareUrl, setShareUrl] = useState('');

	/**
	 * Called whenever platform or shareType changes.
	 * In practice, this probably only runs once (twice?) at widget creation,
	 * as shareType is set from the platform/navigator value and we don't expect
	 * either of those to change once the widget is fully instantiated.
	 */
	useEffect(() => {
		// determine if touch is supported in the client
		const supportsTouch = 'ontouchstart' in document.documentElement;

		// use navigator.share(), App call, ElfSight (default)
		if (supportsTouch && typeof navigator.share === 'function') {
			setShareType('Navigator');
		} else if (params.isApp && (params.platform === 'ios' || params.platform === 'android')) {
			setShareType('App');
		}

		// embed the ElfSight JS library when initialized
		// add a MutationObserver to listen for changes to the elfSight wrapper and call to handle DOM changes
		if (shareType === 'ElfSight') {
			embedJavascript('//apps.elfsight.com/p/platform.js');

			const elfSightTargetNode = document.querySelector('.elfsight-wrapper');
			const elfSightObserverConfig = { attributes: true, childList: true, subtree: true };
			const elfSightObserver = new MutationObserver((mutationsList, observer) => {
				handleElfSightChange({
					mutationsList: mutationsList,
					observer: observer,
					targetNode: elfSightTargetNode,
					config: elfSightObserverConfig
				});
			});
			elfSightObserver.observe(elfSightTargetNode, elfSightObserverConfig);
		}
	},[params.isApp, params.platform, shareType]);

	/**
	 * Test for a change in the url.  If the url changes, we want to update our
	 * stored value, then call for the elfsight widget to refresh (resetWidget)
	 * in order to update the internal value elfSight uses for share.
	 */
	// check if currentShareUrl has changed
	const currentShareUrl = window.location.href.replace(window.location.search, '');
	if (currentShareUrl !== shareUrl) {
		setShareUrl(currentShareUrl);
	}
	// called if shareUrl changes
	// Note: elfSightCode will come from caller, shouldn't change from start
	useEffect(() => {
		if ('eapps' in window) {
			window.eapps.platform.resetWidget(params.elfSightCode);
		}
	}, [shareUrl, params.elfSightCode]);


	/**
	 * Handle click on share button.
	 *
	 * @param event click event
	 */
	function shareButtonAction (event) {

		// set shareUrl, strip any parameters
		setShareUrl(window.location.href.replace(window.location.search,''));

		// Prepare tracking properties for the share click
		const trackingProperties = {
			"label": "Share",
			"type": shareType,
			"module": params.moduleName,
			"share title": params.shareTitle,
			"destination type": "share",
			"location": "modular library"
		};

		// Handle share by type
		if (shareType === 'ElfSight') {
			// tracks the click on the ElfSight wrapper as a general share event
			Tracking.libraryTrack("button clicked", trackingProperties);
		} else if (shareType === 'Navigator') {
			const shareData = {
				title: params.shareTitle,
				url: shareUrl,
			};
			navigator.share(shareData)
				.then(() => {
					Tracking.libraryTrack("button clicked", trackingProperties);
				})
				.catch(console.error);
		} else if (shareType === 'App') {
			if (params.platform === 'ios') {
				// @TODO: handle iOS native app share if navigator.share is not supported
			} else if (params.platform === 'android') {
				openInAppBrowser({type: 'share', url: shareUrl});
			}
		}
	}

	if (shareType === 'ElfSight') {
		return (<GenerateElfSightShareButtons elfSightCode={params.elfSightCode} displayShareButtons={params.displayShareButtons} clickAction={shareButtonAction} storageKey={params.storageKey} />);
	} else if (shareType === 'Navigator') {
		return (<GenerateNativeShareButton clickAction={shareButtonAction} />);
	} else if (shareType === 'App') {
		return (<GenerateNativeShareButton clickAction={shareButtonAction} />);
	}
};

export default GenerateShareButtons;
