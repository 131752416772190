import React from "react";
import GenerateCardDefault from "./articleListCards/generateCardDefault";
import GenerateCardTocAd from "./articleListCards/generateCardTocAd";
import GenerateCardLarge from "./articleListCards/generateCardLarge";
import GenerateCardLandscape from "./articleListCards/generateCardLandscape";
import GenerateCardCarousel from "./articleListCards/generateCardCarousel";
import GenerateCardSections from "./articleListCards/generateCardSections";
import GenerateReplicaCardDefault from "./replicaListCards/generateReplicaCardDefault";
import GenerateDownloadPDFButton from "./generateDownloadPDFButton";
import {stripTags} from "../utils/htmlUtils";
import GenerateImage from "./generateImage";
import {clone} from "../utils/objectUtils";
import {isEmpty, isTrue} from "../utils/generalUtils";
import {populateArticleActions} from "../utils/articleList/articleActions";
import {Translate} from "../locales/locales";
import {populateReplicaActions} from "../utils/replicaList/replicaActions";
import {getStoreValue} from "../utils/storeValue";
import {addParamsToUrl} from "../utils/urlParamUtils";


/**
 * Generate scaffolding html for article summary cards.
 *
 * @param params passed in from calling module
 * @returns {*}
 * @constructor
 */
const GenerateSummary = (params) => {
	params = Object.assign({
		entry: {},
		category: '',
		actions: {},
		displayParams: {}
	}, params);

	params.actions = Object.assign({
		params: {},
		articleClicked: null,
		issueClicked: null,
		cardEvents: null
	}, params.actions);

	params.displayParams = Object.assign({
		displayThumbnail: false,
		displayThumbnailOnLeft: false,
		displayIssue: false,
		displayCategory: false,
		displaySummary: true,
		cardTemplateAttributes: {}
	}, params.displayParams);

	const createCardSections = isTrue(params.displayParams.cardTemplateAttributes.createSections);
	const cardTemplate = params.template ? params.template : 'default';
	if (createCardSections) {
		return <GenerateCardSections entry={params.entry} actions={params.actions} displayParams={params.displayParams} />;
	} else if (cardTemplate === 'large') {
		return <GenerateCardLarge entry={params.entry} actions={params.actions} displayParams={params.displayParams} />;
	} else if (cardTemplate === 'landscape') {
		return <GenerateCardLandscape entry={params.entry} actions={params.actions} displayParams={params.displayParams} />;
	} else if (cardTemplate === 'carousel') {
		return <GenerateCardCarousel entry={params.entry} actions={params.actions} displayParams={params.displayParams} />;
	} else {
		if (params.entry.title === "TOC_AD") {
			return <GenerateCardTocAd entry={params.entry} actions={params.actions} displayParams={params.displayParams} />;
		}
		return <GenerateCardDefault entry={params.entry} actions={params.actions} displayParams={params.displayParams} />;
	}
};
export {GenerateSummary};

/**
 * Generate scaffolding html for replica summary cards.
 *
 * @param params passed in from calling module
 * @returns {*}
 * @constructor
 */
const GenerateReplicaSummary = (params) => {
	params = Object.assign({
		moduleProps: {},
		entry: {},
		actions: {},
		template: 'default',
		displayParams: {}
	}, params);

	params.actions = Object.assign({
		params: {},
		pageClicked: null
	}, params.actions);

	params.displayParams = Object.assign({
		displayThumbnail: false,
		displayThumbnailOnLeft: false,
		displayIssue: false,
		displayCategory: false,
		displaySummary: true,
		cardTemplateAttributes: {}
	}, params.displayParams);

	const cardTemplate = params.template ? params.template : 'default';
//	if (params.template === 'card') {
//		return <GenerateCardDefault entry={params.entry} actions={params.actions} displayParams={params.displayParams} />;
//	} else {
	return <GenerateReplicaCardDefault entry={params.entry} actions={params.actions} moduleProps={params.moduleProps} displayParams={params.displayParams} template={cardTemplate} />;
//	}
};
export {GenerateReplicaSummary};

/**
 * Populate article return data with attributes to use in summary cards.
 *
 * @param entry article
 * @param actions article actions from module
 * @returns {{linkTitle: *, title, url: *, clickAction: (function(*=): *|null)}}
 */
const populateArticleData = (entry, actions) => {
	const articleParams = actions.params.hasOwnProperty('article') ? actions.params.article : {};
	const moduleProps = actions.hasOwnProperty('moduleProps') ? actions.moduleProps : {};
	const isCurrentArticle = isTrue(moduleProps.articleId && moduleProps.articleId === entry.articleId);
	const clickParams = {
		params: articleParams,
		moduleProps: moduleProps,
		mops: actions.hasOwnProperty('mops') ? actions.mops : {},
		articleList: actions.hasOwnProperty('articleList') ? actions.articleList : [],
		articleId: entry.articleId,
		issueUrl: entry.issueUrl,
		isCurrentArticle: isCurrentArticle,
		section: entry.hasOwnProperty('section') ? entry.section : '',
		updateHistoryState: isTrue(actions.updateHistoryState)
	};
	const articleTitle = stripTags(entry.title);
	const urlTitle = articleTitle.replace(/[\s:?#]/g,"_");
	const queryParams = getStoreValue({attributeKey: 'queryParams'});
	return {
		clickAction: actions.articleClicked ? evt=>actions.articleClicked(evt, clickParams) : null,
		articleId: entry.articleId,
		isCurrentArticle: isCurrentArticle,
		linkTitle: articleParams.hasOwnProperty('linkTitle') ? articleParams.linkTitle : "",
		title: articleTitle,
		// eslint-disable-next-line no-script-url
		url: (articleParams.hasOwnProperty('destination') && articleParams.destination === 'external') ? addParamsToUrl(queryParams,entry.itemLinkUrl,false) : Translate.Text({id: "hoverText.articleLink"}) + urlTitle
	};
};
export {populateArticleData};

/**
 * Populate issue return data with attributes to use in summary cards.
 *
 * @param entry issue
 * @param actions issue actions from module
 * @returns {{linkTitle: *, title, url: *, clickAction: (function(*=): *|null)}}
 */
const populateIssueData = (entry, actions) => {
	const issueParams = actions.params.hasOwnProperty('issue') ? actions.params.issue : {};
	const clickParams = {
		params: issueParams,
		moduleProps: actions.hasOwnProperty('moduleProps') ? actions.moduleProps : {},
		mops: actions.hasOwnProperty('mops') ? actions.mops : {},
		issueList: actions.hasOwnProperty('issueList') ? actions.issueList : []
	};
	const destinationType = issueParams.hasOwnProperty('destination') ? issueParams.destination : 'disable';
	const issueTitle = stripTags(entry.documentUrl);
	const urlTitle = issueTitle.replace(/[\s:?#]/g,"_");
	const queryParams = getStoreValue({attributeKey: 'queryParams'});
	return {
		issueName: entry.issueName,
		disabled: (destinationType === 'disable'),
		clickAction: actions.issueClicked ? evt=>actions.issueClicked(evt, clickParams) : null,
		linkTitle: issueParams.hasOwnProperty('linkTitle') ? issueParams.linkTitle : "",
		title: issueTitle,
		// eslint-disable-next-line no-script-url
		url: (issueParams.hasOwnProperty('destination') && issueParams.destination === 'external') ? addParamsToUrl(queryParams,entry.documentUrl,false) : Translate.Text({id: "hoverText.issueLink"}) + urlTitle
	};
};
export {populateIssueData};

/**
 * Populate replica list, click on thumbnail click action function params.  Return the
 * click function for caller to use on page thumbnail click
 *
 * @param params
 *     entry replica page object
 *     replicaActions replica actions from module
 *     trackingParams: params specifically for the tracking call
 * @returns {function(*): *|void}
 */
const populateReplicaListClick = (params) => {
	params = Object.assign({
		entry: {},
		replicaActions: {},
		trackingParams: {}
	}, params);
	const {entry, replicaActions, trackingParams} = params;

	const replicaParams = !isEmpty(replicaActions.params.replica) ? replicaActions.params.replica : {};
	const moduleProps = replicaActions.hasOwnProperty('moduleProps') ? replicaActions.moduleProps : {};
	const clickParams = {
		params: replicaParams,
		moduleProps: moduleProps,
		mops: replicaActions.hasOwnProperty('mops') ? replicaActions.mops : {},
		replicaList: replicaActions.hasOwnProperty('replicaList') ? replicaActions.replicaList : [],
		folio: entry.folio,
		pageNumber: entry.pageNumber,
		issueUrl: entry.issueUrl,
		updateHistoryState: isTrue(replicaActions.updateHistoryState),
		trackingParams: trackingParams,
		forceStoreUpdate: isTrue(replicaActions.forceStoreUpdate),
	};
	return !isEmpty(replicaActions.pageClicked)
		? evt=>replicaActions.pageClicked(evt, clickParams)
		: null;
};
export {populateReplicaListClick};

/**
 * Populate article return data with attributes to use in summary cards.
 *
 * @param entry article
 * @param actions article actions from module
 * @returns {{linkTitle: *, title, url: *, clickAction: (function(*=): *|null)}}
 */
const populateReplicaData = (entry, actions) => {
	const replicaParams = actions.params.hasOwnProperty('replica') ? actions.params.replica : {};
	const replicaTitle = stripTags(entry.title);
	const urlTitle = replicaTitle.replace(/[\s:?#]/g,"_");
	const queryParams = getStoreValue({attributeKey: 'queryParams'});
	const link = Translate.Text({ id: 'replicaList.open.page' }) + entry.folio;
	const trackingParams = {
		title: replicaTitle,
		link: link,
		domain: !isEmpty(entry.domain) ? entry.domain : '',
	};

	const destination = !isEmpty(actions.params) && !isEmpty(actions.params.replica) ? actions.params.replica.destination : 'external';
	const clickAction = (destination === 'external')
		? null
		: populateReplicaListClick({
			entry: entry,
			replicaActions: actions,
			trackingParams: trackingParams,
		});

	return {
		clickAction: clickAction,
		folio: entry.folio,
		pageNumber: entry.pageNumber,
		linkTitle: replicaParams.hasOwnProperty('linkTitle') ? replicaParams.linkTitle : "",
		title: replicaTitle,
		// eslint-disable-next-line no-script-url
		url: (replicaParams.hasOwnProperty('destination') && replicaParams.destination === 'external') ? addParamsToUrl(queryParams,entry.itemLinkUrl,false) : Translate.Text({id: "hoverText.articleLink"}) + urlTitle
	};
};
export {populateReplicaData};


/**
 * Generate the html for an issue embedded within an article summary.  If linking is disabled, then
 * just generate the text; otherwise, generate a link.
 *
 * NOTE: For summary cards, issue link may be embedded within the article summary.  If so, the issue link can
 * take the characteristics of the enclosing summary if destination is "disable".
 *
 * @param params parameters passed from the call
 *     issue: issue data
 * @returns {*} html
 * @constructor
 */
const GenerateSummaryIssue = (params) => {
	const issue = params.hasOwnProperty('issue') ? params.issue : {};
	if (issue.disabled) {
		return (
			<div className={'issue-name'}>&nbsp;{issue.issueName}</div>
		);
	} else {
// TODO: temporarily removed click functionality; add back when we support this
//		return (
//			<a href={issue.url} className={'issue-link'} title={issue.linkTitle} data-issuetitle={issue.title} onClick={issue.clickAction}>
//				<div className={'issue-name'}>&nbsp;{issue.issueName}</div>
//			</a>
//		);
		return (
			<div className={'issue-name'}>&nbsp;{issue.issueName}</div>
		);
	}
};
export {GenerateSummaryIssue};


/**
 * Remove cover article from the articleList if the configuration asks for it to be removed.
 * We may have included it in fetch because we need it for display of issue cover, but want
 * to remove it from the display list if the properties ask for it to be removed.
 * Note: this is most common for a standard articleList
 *
 * @param params article list params
 *     articleList: article list
 *     props: articleList props
 * @returns {*}
 */
const removeCoverArticle = (params) => {
	params = Object.assign({
		articleList: [],
		props: {}
	}, params);
	const displayList = clone(params.articleList);
	const coverArticleIndex = displayList.findIndex(article => article.customTemplate === 'cover');
	if (params.props.coverExcluded && coverArticleIndex !== -1) {
		displayList.splice(coverArticleIndex, 1);  // remove cover article element
	}
	return displayList;
};
export {removeCoverArticle};

/**
 * Remove the cover from the replicaList for display, similar to above but
 * is always done for replicaList; we assume the first page in the list is the cover
 *
 * @param params replica list params
 *     replicaList: replica list
 * @returns {*}
 */
const removeCoverPage = (params) => {
 	params = Object.assign({
 		replicaList: []
 	}, params);
 	const displayList = clone(params.replicaList);
	displayList.splice(0, 1);
	return displayList;
};
export {removeCoverPage};

/**
 * If this should be the special-cased issue articleList, check to see if it meets the criteria
 * and set properties for the issue cover image.
 *
 * In general, if the articleList is fetched with an issueUrl as the fetch parameter, we
 * consider it to be an issue articleList
 *
 * @param params
 *     props: articleList props
 *     mops: articleList mops
 *     fullList: current articleList
 *     issueName: issue name for articleList
 *
 * @returns {{coverImageProperties: {}, coverArticle: {}, pdfDownloadUrl: string, issueUrl, articleActions: {}, coverImageUrl: string, showCoverImage: boolean, hasCoverArticle: boolean}}
 */
const generateArticleIssueCoverParams = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		articleList: [],
		issueName: ''
	}, params);
	const {props, mops, articleList, issueName} = params;
	const issueUrl = !isEmpty(mops.queryParams.issueUrl) ? mops.queryParams.issueUrl : '';
	const issueCoverParams = {
		showCoverImage: false,
		hasCoverArticle: false,
		coverImageUrl: '',
		coverArticle: {},
		coverImageProperties: {},
		articleActions: {},
		issueUrl: issueUrl,
		pdfDownloadUrl: ''
	};

	// check for override of displayCoverImage
	let displayCoverImage = isTrue(props.canDisplayCoverImage);
	// if issueOverride exists, then override property
	if (props.issueOverrides.hasOwnProperty(issueUrl) && props.issueOverrides[issueUrl].hasOwnProperty('canDisplayCoverImage')) {
		displayCoverImage = isTrue(props.issueOverrides[issueUrl].canDisplayCoverImage);
	}

	// confirm that mops.queryParams.issueUrl exists and props.canDisplayCoverImage is true
	if (!isEmpty(issueUrl) && articleList.length > 0 && props.isMyPane && displayCoverImage) {
		// find article with the documentUrl property to construct the url path to `cover468w.gif`
		const documentUrlArticle = articleList.find(article => article.hasOwnProperty('documentUrl'));

		// assign the pdfDownloadUrl if it exists
		issueCoverParams.pdfDownloadUrl = documentUrlArticle.hasOwnProperty('pdfDownloadUrl') ? documentUrlArticle.pdfDownloadUrl : '';

		// only show the image if documentUrl path exists
		if (typeof documentUrlArticle !== 'undefined') {
			issueCoverParams.coverImageUrl = documentUrlArticle.documentUrl + '/cover468w.gif';
			issueCoverParams.showCoverImage = true;
			// check for cover article
			const coverArticleIndex = articleList.findIndex(article => article.customTemplate === 'cover');
			if (coverArticleIndex !== -1) {
				issueCoverParams.coverArticle = articleList[coverArticleIndex];
				issueCoverParams.hasCoverArticle = true;
				issueCoverParams.articleActions = populateArticleActions({props: props, mops: mops, articleList: articleList});
			}
		}
		issueCoverParams.coverImageProperties = {
			alt: issueName + ' cover image',
			src: issueCoverParams.coverImageUrl,
			link: false
		};
	}
	return issueCoverParams;
};

/**
 * Generate the html for the special issue cover image that appears at the top of an issue articleList.
 *
 * @param params generated params for the issue cover
 *     props: module props
 *     mops: module mops
 *     fullList: full article list
 *     userHasAccessToFirstArticle: true/false; from configuration
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateArticleIssueCover = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		fullList: [],
	}, params);
	const {props, mops, fullList} = params;

	const articleList = clone(fullList);
	// find article with issueName property
	let issueArticle = articleList.find(article => article.hasOwnProperty('issueName'));
	issueArticle = !isEmpty(issueArticle) ? issueArticle : {};
	const issueName = !isEmpty(issueArticle.issueName) ? issueArticle.issueName : '';
	const issueDate = !isEmpty(issueArticle.issueDate) ? issueArticle.issueDate : '';
	const useHasAccessToDocument = isTrue(issueArticle.userHasAccess, {defaultValue: false});

	const issueCoverParams = generateArticleIssueCoverParams({props: props, mops: mops, articleList: articleList, issueName: issueName});
	if (issueCoverParams.showCoverImage) {
		if (issueCoverParams.hasCoverArticle) {
			const articleData = populateArticleData(issueCoverParams.coverArticle, issueCoverParams.articleActions);
			const title = issueName + ' - ' + articleData.title;
			return (
				<div className={'article-list-image-wrapper'}>
					<div className={'article-list-image'}>
						<a href={articleData.url} className={'item-link'} title={title} data-articletitle={articleData.title} onClick={articleData.clickAction}>
							<GenerateImage imageProperties={issueCoverParams.coverImageProperties} />
						</a>
						{props.canDownloadPDF && issueCoverParams.pdfDownloadUrl && issueCoverParams.pdfDownloadUrl !== ''
							&& useHasAccessToDocument ? <GenerateDownloadPDFButton issueTitle={props.title} issueName={issueName} issueDate={issueDate}
								pdfDownloadUrl={issueCoverParams.pdfDownloadUrl} isApp={props.app} /> : ''}
					</div>
				</div>
			);
		} else {
			return (
				<div className={'article-list-image-wrapper'}>
					<div className={'article-list-image'}>
						<GenerateImage imageProperties={issueCoverParams.coverImageProperties} />
						{props.canDownloadPDF && issueCoverParams.pdfDownloadUrl && issueCoverParams.pdfDownloadUrl !== ''
							&& useHasAccessToDocument ? <GenerateDownloadPDFButton issueTitle={props.title} issueName={issueName} issueDate={issueDate}
								pdfDownloadUrl={issueCoverParams.pdfDownloadUrl} isApp={props.app} /> : ''}
					</div>
				</div>
			);
		}
	} else {
		return "";
	}
};
export {GenerateArticleIssueCover};


/**
 * Set properties for the issue cover image for a replicaList, including whether or not to display
 * the list and what to do when clicked.
 *
 * @param params
 *     props: replicaList props
 *     mops: replicaList mops
 *     fullList: current replicaList
 *     issueName: issue name for replicaList
 *
 * @returns {{coverImageProperties: {}, pdfDownloadUrl: string, showCoverImage: boolean, clickAction: null}}
 */
const generateReplicaIssueCoverParams = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		fullList: [],
	}, params);
	const {props, mops, fullList} = params;
	const replicaList = clone(fullList);
	const replicaTitle = props.title ? props.title : '';
	const useHasAccessToDocument = !isEmpty(fullList) ? isTrue(fullList[0].userHasAccess) : false;

	const issueCoverParams = {
		showCoverImage: false,
		canDownloadPdf: false,
		coverImageProperties: {},
		replicaTitle: replicaTitle,
		pdfDownloadUrl: '',
		clickAction: null,
		useHasAccessToDocument: useHasAccessToDocument,
	};

	// confirm that mops.queryParams.issueUrl exists and props.canDisplayCoverImage is true
	if (!isEmpty(props.issueUrl) && !isEmpty(replicaList) && isTrue(props.canDisplayCoverImage)) {
		const coverPage = replicaList[0];  // assume first page of the list is the cover
		// only show the image and generate cover properties if cover page exists
		if (typeof coverPage !== 'undefined') {
			issueCoverParams.showCoverImage = true;

			issueCoverParams.canDownloadPdf = props.canDownloadPDF && !isEmpty(props.pdfDownloadUrl) && useHasAccessToDocument;
			issueCoverParams.pdfDownloadUrl = issueCoverParams.canDownloadPdf ? props.pdfDownloadUrl : '';
			issueCoverParams.issueName = coverPage.issueName;
			issueCoverParams.issueDate = coverPage.issueDate;

			// generate the click action from the standard call
			const replicaActions = populateReplicaActions({
				props: props,
				mops: mops,
				replicaList: replicaList,
				updateHistoryState: true
			});
			const trackingParams = {
				title: replicaTitle,
				link: Translate.Text({ id: 'replicaList.open.cover' }),
				domain: !isEmpty(coverPage.domain) ? coverPage.domain : '',
			};
			issueCoverParams.clickAction = populateReplicaListClick({
				entry: coverPage,
				replicaActions: replicaActions,
				trackingParams: trackingParams,
			});

			issueCoverParams.coverImageProperties = {
				className: 'replica-cover-link',
				title: replicaTitle,
				alt: replicaTitle + ' cover image',
				src: coverPage.image,
				link: Translate.Text({ id: 'replicaList.open.cover' }),
			};
		}
	}
	return issueCoverParams;
};


/**
 * Generate the html for the cover image that appears at the top of a replicaList.
 * If there is no cover image or we don't want to show it (through Configuration),
 * then we just return an empty string.
 * Similar to the pdf for download.
 *
 * @param params generated params for the issue cover
 *     props: module props
 *     mops: module mops
 *     fullList: full replica list
 *     userHasAccessToFirstArticle: true/false; from configuration
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateReplicaIssueCover = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		fullList: [],
	}, params);

	const replicaCoverParams = generateReplicaIssueCoverParams(params);

	if (replicaCoverParams.showCoverImage) {
		return (
			<div className={'replica-list-image-wrapper'}>
				<div className={'replica-list-image'}>
					<GenerateImage onClick={replicaCoverParams.clickAction} imageProperties={replicaCoverParams.coverImageProperties} />
					{replicaCoverParams.canDownloadPdf ?
						<GenerateDownloadPDFButton issueTitle={replicaCoverParams.replicaTitle} issueName={replicaCoverParams.issueName} 
							issueDate={replicaCoverParams.issueDate} pdfDownloadUrl={replicaCoverParams.pdfDownloadUrl} isApp={params.props.app} /> :''
					}
				</div>
			</div>
		);
	} else {
		return '';
	}
};
export {GenerateReplicaIssueCover};
