import React from "react";
import {
	GenerateSummaryIssue,
	populateIssueData,
	populateReplicaData,
} from "../generateSummary";
import GenerateImage from "../generateImage";
import {isEmpty} from "../../utils/generalUtils";
import {Translate} from "../../locales/locales";
import GenerateTitle from "../generateTitle";
import GenerateExcerpt from "../generateExcerpt";


/**
 * Generate html for replica summary default card
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GenerateReplicaCardDefault = (params) => {
	params = Object.assign({
		moduleProps: {},
		entry: {},
		actions: {},
		displayParams: {},
		template: 'default',
	}, params);
	const {moduleProps, entry, actions} = params;

	// set replica properties for template
	const titleParams = {
		tag: 'h3',
		className: 'title',
		displayTitle: true,
	};
	const replicaData = populateReplicaData(entry, actions);
	const issue = populateIssueData(entry, actions);

	// replica pages do not have titles, must use generic alt text
	const imgAlt = "Page " + entry.folio;
	const imageProperties = {
		className: 'replica-page-link',
		title: !isEmpty(moduleProps.title) ? moduleProps.title + ' - ' + imgAlt : imgAlt,
		alt: imgAlt,
		src: entry.smallImage ? entry.smallImage : "https://placekitten.com/136/182",
		link: Translate.Text({ id: 'replicaList.open.page' }) + entry.folio
	};

	const template = !isEmpty(params.template) ? params.template : 'default';
	if (template === 'search') {
		return (
			<a href={replicaData.url} className={'item-link'} data-articletitle={replicaData.title} onClick={replicaData.clickAction}>
				<div className={'description'}>
					<div className={'title-issue-wrap'}>
						<GenerateTitle titleParams={titleParams}>{entry.title}</GenerateTitle>
						<GenerateSummaryIssue issue={issue} />
					</div>
					<GenerateExcerpt className={'summary'} summary={entry.summary} />
				</div>
				<div className={'thumbnail right'}>
					<img alt={imgAlt} className={'thumbnail-image'} src={entry.squareImage} />
				</div>
			</a>
		);
	} else {
		return (
			<>
				<GenerateImage onClick={replicaData.clickAction} imageProperties={imageProperties} />
				<p>{entry.folio}</p>
			</>
		);
	}
};
export default GenerateReplicaCardDefault;
