import React from 'react';
import {connect} from 'react-redux';
import {Translate} from "../../../../locales/locales";
import {SEARCH_FILTERS, SEARCH_PROGRESS, SEARCH_RESULTS} from "../../../_MODULE_GLOBALS/constants";
import { updateData } from '../../../../store/actions';
import GenerateTitle from "../../../../widgets/generateTitle";
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../../utils/moduleSetup";
import {defineSelectionFilter, updateFilterOnChange} from "../searchUtils/filterUpdate";
import {useMountPostRender} from "../../../../hooks/useMount";
import {isEmpty, isTrue} from "../../../../utils/generalUtils";


const dropdownValues = {
	'relevant': {'value': 'relevance', 'display': 'search.sort.relevant'},
	'recent': {'value':'pub_date', 'display': 'search.sort.recent'}
};
export {dropdownValues};  // use: import {dropdownValues as searchSortValues} from "../filters/searchSort";

const defaultDropdownOrder = {
	default: 'relevant',
	order: ['relevant', 'recent']
};


export const SearchSortModule = (props) => {
	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: SEARCH_RESULTS,
		defaultClass: 'search-sortby',
		title: 'search.sort.title',
		titleTag: 'span',
		configQueryParams: {}
	});

	// runs only once on init
	useMountPostRender(() => {
		// update filter value on startup
		props.updateData({searchSortFilter: props.searchSortBy}, {storageKey: props.storageKey});
	});


	/*
	* Generate jsx for sort dropdown if displayOnDevice
	*/
	if (displayOnDevice(props)) {
		return (
			<div className={mops.className}>
				<GenerateTitle titleParams={mops.titleParams} />
				<select
					aria-label={Translate.Text({id: 'search.sort.ariaLabel'})} defaultValue={props.defaultValue}
					onChange={(evt) => {
						const searchValue = evt.currentTarget.value;
						updateFilterOnChange({props: props, searchValue: searchValue, searchReducer: 'searchSortFilter'});
					}}>
					{
						props.dropdownOrder.map((selection) => {
							return (
								<option key={dropdownValues[selection].value} value={dropdownValues[selection].value}>
									{Translate.Text({id: dropdownValues[selection].display})}
								</option>
							);
						})
					}
				</select>
			</div>
		);
	} else {
		return null;
	}
};


/**
 * Map the store object you are using.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{dropdownOrder, defaultValue: (*|string), searchSortBy: (string|*|string), active: (*|string), storageKey: (*|string)}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = props.storageKey ? props.storageKey : SEARCH_RESULTS;
	const storageState = state.hasOwnProperty(storageKey) ? state[storageKey] : {};
	// filters set through reducer; module filter is null if not explicitly set
	const searchFilters = storageState.searchFilters ? storageState.searchFilters : {};
	const searchFiltersValue = !isEmpty(searchFilters.sortBy) ? searchFilters.sortBy : '';

	// set default values from configuration
	const defaultFilterValues = defineSelectionFilter({
		props: props,
		searchFiltersValue: searchFiltersValue,
		defaultDropdownOrder: defaultDropdownOrder,
		dropdownValues: dropdownValues,
		forceFetch: isTrue(storageState.forceFetch)
	});
	const dropdownOrder = defaultFilterValues.order;
	const defaultValue = defaultFilterValues.defaultValue;
	const searchSortBy = defaultFilterValues.filterValue;

	return {
		storageKey: storageKey,
		active: getActiveAttributeState(props),
		searchFilters: searchFilters,
		dropdownOrder: dropdownOrder,
		searchSortBy: searchSortBy,
		defaultValue: defaultValue,
	};
};

/**
 * Actions that can be called by this module.
 *
 * actions:
 *     updateData call to update the store with new properties
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		updateData: (payload, params) => {
			params.type = SEARCH_FILTERS;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchSortModule);
