import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import {isTrue} from "../../../utils/generalUtils";
import {generateClassFromProps} from "../../../utils/generateClassName";
import { Translate } from "../../../locales/locales";


export const TaggedTextModule = (props) => {
	const generatedClass = generateClassFromProps(props, {
		defaultClass: isTrue(props.displayAsBlock) ? 'tagged-text block-display' : 'tagged-text inline-display'
	});
	const className = generatedClass.className;


	/*
	* Generate jsx for tagged text if displayOnDevice
	* Use GeneratedTag as tag, with text in generated tag.
	*/
	const GeneratedTag = `${props.tag}`;
	if (displayOnDevice(props)) {
		return <GeneratedTag className={className}>{Translate.Text({'id': props.text})}</GeneratedTag>;
	} else {
		return null;
	}
};


/**
 * Map the store object you are using.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{displayAsBlock: boolean, active: (*|string), text: string, tag: (*|string)}}
 */
const mapStateToProps = (state, props) => {
	const moduleProps = {
		active: getActiveAttributeState(props),
		text: '',
		tag: props.hasOwnProperty('tag') ? props.tag : 'h2',
		displayAsBlock: isTrue(props.displayAsBlock)
	};
	// handle both definition as "text" or "title"; otherwise accept default
	if (props.hasOwnProperty('text')) {
		moduleProps.text = props.text;
	} else if (props.hasOwnProperty('title')) {
		moduleProps.text = props.title;
	}

	return moduleProps;
};


export default connect(
	mapStateToProps,
	null
)(TaggedTextModule);

