import {isPlainObject} from "./objectUtils";
import {generateArrayFromNodeList, generateHtmlFromNode} from "./htmlUtils";


/**
 * Set of general utility functions that don't apply to any specific Content Hub functionality,
 * but are generally useful and potentially make life easier
 */

/**
 * Check if window object exists and has attribute window.document.
 * If so, code is NOT running on the server.
 *
 * @type {boolean} true: running on node server; false: running on client
 */
const isServer = !(
	typeof window !== 'undefined' &&
	window.document &&
	window.document.createElement
);
export {isServer};



/**
 * Test if the value satisfies one of the following criteria:
 *    value is boolean: return value
 *    value is string: return true if it is equal to "true"; note that this is a stricter test than isFalse
 *    value is number: return true if it is not equal to 0 (all other numbers evaluate to true)
 *
 * If nothing is passed in for value (ie. undefined) or it is not a legitimate value to test for
 * boolean true/false (ie. boolean/string/number) then return false or a default value if set
 *
 * @param value true/false value to test
 * @param options
 *      defaultValue value to return if value tests as empty or not testable, but only if set
 * @returns {boolean} true/false
 */
const isTrue = (value, options) => {
	options = Object.assign({
		defaultValue: null
	}, options);
	if (isEmpty(value)) {
		return !isEmpty(options.defaultValue) ? options.defaultValue : false;
	}
	if (typeof value === 'boolean') {
		return value;
	} else if (typeof value === 'string') {
		return value.toLowerCase().trim() === 'true';
	} else if (typeof value === 'number') {
		const num = parseInt(value, 10);
		return (!isNaN(num) && num !== 0);
	} else {
		return !isEmpty(options.defaultValue) ? options.defaultValue : false;
	}
};
export {isTrue};


/**
 * Test if the value evaluates as "empty" based on following criteria:
 *    value is undefined or null
 *    value is string: return true if empty
 *    value is number: return true if NaN
 *    value is jQuery plainObject; return true if no keys
 *    value is array; return true if length is 0
 * Note that these are all on by default; they can be turned off by passing
 * false as an option for any type.
 * For example:
 *     you want to test if 'undefined' or null, but don't want empty string to be marked as empty
 *     the following will return true if undefined or null, but will return false if any kind of string
 *         isEmpty(var, {'string': false})
 * Additionally, string has another property that allows for a test against a true empty string
 * so that a string with carriage returns or spaces will not test as empty if "trimString" is
 * set to false.
 *
 * Note: Check for node and nodelist is only valid for DOM elements in the browser.
 *
 * Everything else returns false
 *
 * @param value value to test
 * @param options modify test of return value
 *     undefined, null, string, number, array, nodelist, object
 *         false: skip this test; usually means this return as empty if this type
 *         true (default): test this type
 *     trimString: trim before testing
 *     zeroEmpty: consider the number value 0 to be empty
 * @returns {boolean} true/false
 */
const isEmpty = (value, options) => {
	options = Object.assign({
		'undefined': true,
		'null': true,
		'string': true,
		"trimString": true,
		'number': true,
		"zeroEmpty": false,
		'array': true,
		'nodelist': true,
		'object': true
	}, options);
	if ((typeof value === 'undefined' && options.undefined) || (value === null && options.null)) {
		return true;
	} else if (typeof value === 'string' && options.string) {
		return options.trimString ? value.trim() === '' : value === '';
	} else if (typeof value === 'number' && options.number) {
		return (options.zeroEmpty && !isNaN(value) && value === 0) || isNaN(value);
	} else if (Array.isArray(value) && options.array) {
		return value.length <= 0;
	} else if (isPlainObject(value) && options.object) {
		const keys = Object.keys(value);
		return keys.length <= 0;
	} else if (!isServer) {
		// special handling for html/DOM elements
		if (value instanceof NodeList) {
			return value.length <= 0;
		} else if (value instanceof Node) {
			return false;
		} else {
			return false;
		}
	} else {
		return false;
	}
};
export {isEmpty};


/**
 * This is provided as a convenience function which incorporates both the
 * parseInt function and the test for isNaN so that callers don't have to
 * use let and 2 lines for the conversion.
 *
 * Convert value to int base 10.  If this value isNaN, then return the defaultValue
 * attribute.  if not, return the converted int value.
 *
 * If no default is set, use -1 as the default return value
 *
 * @param value original value to convert
 * @param defaultValue default value if number can't be converted
 * @param options addition options
 *     zeroEmpty: true: if zero value, considers this to be empty and returns default value
 * @returns {number|*|number}
 */
const getIntValue = (value, defaultValue, options) => {
	options = Object.assign({
		"zeroEmpty": false,
	}, options);
	const zeroEmpty = isTrue(options.zeroEmpty, {defaultValue: false});
	defaultValue = isEmpty(defaultValue) ? -1 : defaultValue;

	const returnValue = parseInt(value, 10);
	return isEmpty(returnValue, {zeroEmpty: zeroEmpty}) ? defaultValue : returnValue;
};
export {getIntValue};


/**
 * This is provided as a convenience function which incorporates both the
 * parseInt function and the test for isNaN so that callers don't have to
 * use let and 2 lines for the conversion.
 *
 * Convert value to int base 10.  If this value isNaN, then return the defaultValue
 * attribute.  if not, return the converted int value.
 *
 * If no default is set, use -1 as the default return value
 *
 * @param value
 * @param defaultValue
 * @returns {number|*|number}
 */
const getFloatValue = (value, defaultValue) => {
	defaultValue = isEmpty(defaultValue) ? -1 : defaultValue;
	let returnValue = defaultValue;
	if (isEmpty(value)) {
		return defaultValue;
	} else {
		returnValue = parseFloat(value);
		return isNaN(returnValue) ? defaultValue : returnValue;
	}
};
export {getFloatValue};


/**
 * Generate a JSON string from a value
 *
 * @param value value to convert to a JSON string
 * @returns {string}
 */
const generateStringifiedValue = (value) => {
	let valueToStringify = value;
	if (value instanceof Node) {
		valueToStringify = generateHtmlFromNode(value);
	} else if (value instanceof NodeList) {
		valueToStringify = generateArrayFromNodeList(value);
	}
	let stringifiedValue = '';
	try {
		stringifiedValue = JSON.stringify(valueToStringify);
	} catch {
		console.log("could not stringify value");
		stringifiedValue += valueToStringify.toString();
	}
	return stringifiedValue;
};
export {generateStringifiedValue};


/**
 * Check to see if two values are equal.  Either of the "values" can be a
 * string, so they are first converted to the number value, then the
 * comparison is done.
 *
 * By default, this is for int values, but can be overridden for float.
 *
 * @param val1 value
 * @param val2 value
 * @param type "int" or "float" - default "int"
 * @returns {boolean}
 */
const valuesEqual = (val1, val2, type) => {
	type = !isEmpty(type) ? type : 'int';
	if (type === 'float') {
		return getFloatValue(val1) === getFloatValue(val2);
	} else {
		return getIntValue(val1) === getIntValue(val2);
	}
};
export {valuesEqual};
