import React, { useState, useEffect } from 'react';
import {
	AUTHENTICATION,
	AUTHENTICATION_KEY,
	ECOMMERCE_INAPP_LOGIN,
	JWT_LOGIN,
	REFERRER_LOGIN
} from "../../../_MODULE_GLOBALS/constants";
import {Translate} from "../../../../locales/locales";
import * as Tracking from "../../../../utils/tracking";
import {isEmpty, isTrue} from "../../../../utils/generalUtils";
import {getStoreValue} from "../../../../utils/storeValue";
import {appCaller} from "../../../../utils/nativeAppInterface";
import {AuthenticationMsg} from "../index";
import {trackButtonClick} from "../index";


/**
 * Handle click on the Subscribe button, including tracking call.
 *
 * @param params
 */
const subscribeButton = (params) => {
	const trackingProperties = Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		action: "subscribe link",
	});
	trackButtonClick(trackingProperties);
	if (window) {
		window.location.href = params.collectionSubscribeUrl;
	}
};

/**
 * Handle click on the JWT Subscribe button, including tracking call.
 *
 * @param params
 */
const jwtSubscribeButton = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		action: "subscribe link",
	}));
	if (window) {
		// expecting collectionSubscribeUrl == 'https://app.hapara.com'
		window.location.href = params.collectionSubscribeUrl + "/resource/" + params.resourceId + "?redirect_url=" + encodeURIComponent(window.location.href);
	}
};


/**
 * Handle click on the App Subscribe Purchase button, including tracking call.
 *
 * @param params
 */
const appSubscriptionPurchaseButton = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		type: "purchase button",
		action: "subscription purchase",
	}));
	appCaller({type: 'purchase', productId: params.subscriptionProduct.productId, productType: 'subscription'});
};

/**
 * Handle click on the App Single Issue Purchase button, including tracking call.
 *
 * @param params
 */
const appSingleIssuePurchaseButton = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		type: "purchase button",
		action: "single issue purchase",
	}));
	appCaller({type: 'purchase', productId: params.singleIssueProduct.productId, productType: 'singleIssue'});
};


/**
 * Handle click on the Login button, including tracking call.
 *
 * @param params
 */
const loginLink = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		type: "toggle access",
		action: "subscribe link",
	}));
	if (params.authenticationType === REFERRER_LOGIN) {
		subscribeButton(params);
	} else if (params.authenticationType === JWT_LOGIN) {
		jwtSubscribeButton(params);
	} else if (params.authenticationType === ECOMMERCE_INAPP_LOGIN) {
		const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
		updateData({authView: 'login'}, {type: AUTHENTICATION, storageKey: AUTHENTICATION_KEY });
	}
};

/**
 * Widget for the "ecommerce" dialog
 *
 * @param params props + additionalProps passed in from the authentication parent module
 * @returns {JSX.Element}
 * @constructor
 */
const AuthenticateEcommerce = (params) => {
	const displayText = {
		subscriptionLabel: Translate.Text({id: "ecommerce.buySubscription"}),
		singleIssueLabel:  Translate.Text({id: "ecommerce.buySingleIssue"}),
		alreadySubscribedLabel:  Translate.Text({ id: 'ecommerce.alreadySubscribed' }),
		title: Translate.Text({id:'ecommerce.title'}),
		description: Translate.Text({id: "ecommerce.description"}),
		autorenew: Translate.Text({id: "ecommerce.autorenew"}),
		buySingleIssue: Translate.Text({id: "ecommerce.buySingleIssue"}),
	};

	const authParams = {
		authenticationType: params.authenticationType,
		singleIssueProduct: params.singleIssueProduct,
		subscriptionProduct: params.subscriptionProduct,
		collectionSubscribeUrl: params.collectionSubscribeUrl,
		resourceId: params.resourceId,
		// setup base attributes for tracking for this widget
		baseTrackingProperties: {
			"destination type": params.popupType,
			"issue name": "_" + params.issueName,
			"issue url": params.issueUrl,
		}
	};

	const signedInNoAccess = params.isLoggedIn && !params.userHasAccess;
	const messageParams = {
		"message": params.accessError,
		"clearErrorMsg": !signedInNoAccess && (params.clearErrorMsg || isEmpty(params.emailAddress)),
	};


	return (
		<div className={"authentication authenticate-ecommerce"}>
			<div className="authenticate-screen" />
			<div className="authenticate">
				<div className={params.popupType}>
					<h2 className="title">{displayText.title}</h2>
					{
						isTrue(params.isLatestIssue) && !isEmpty(params.subscriptionProduct) ? <>
							<p className="copy">{displayText.description}</p>
							<p className="copy">{displayText.autorenew}</p>
						</>:''
					}
					<AuthenticationMsg {...messageParams}/>
					{
						isTrue(params.isLatestIssue) && !isEmpty(params.subscriptionProduct) ?
							<button
								id={'subscription-button'}
								type={"button"}
								name={params.subscriptionProduct.productId}
								className={'button'}
								aria-label={displayText.subscriptionLabel}
								onClick={() => appSubscriptionPurchaseButton({...authParams, label: displayText.subscriptionLabel})}>
								<figure>
									<span className="subscription-term">{displayText.subscriptionLabel}</span>
									<span className="subscription-price">{params.subscriptionProduct.productPrice}</span>
								</figure>
							</button>
							: ''
					}
					{
						!isEmpty(params.singleIssueProduct) ?
							<button
								id={'single-issue-button'}
								type={"button"}
								name={params.singleIssueProduct.productId}
								className={'button'}
								aria-label={displayText.buySingleIssue}
								onClick={() => appSingleIssuePurchaseButton({...authParams, label: displayText.singleIssueLabel})}>
								<figure>
									<span className="single-issue-term">{displayText.singleIssueLabel}</span>
									<span className="single-issue-price">{params.singleIssueProduct.productPrice}</span>
								</figure>
							</button>
							: ''
					}
					<br />
					<button id={'login-link'} className={'button login-link'} onClick={() => loginLink({...authParams, label: displayText.alreadySubscribedLabel})}>{displayText.alreadySubscribedLabel} <i className="fal fa-long-arrow-right" /></button>
				</div>
			</div>
		</div>
	);

};
export {AuthenticateEcommerce};
