import React from "react";
import {Translate} from "../../../../locales/locales";
import {navigateToTarget} from "../../../globals/globalNavigator";
import {getObjectFromJSON} from "../../../../utils/objectUtils";
import {isEmpty} from "../../../../utils/generalUtils";
import deepmerge from "deepmerge";
import {addClass} from "../../../../utils/generateClassName";


/**
 * Display and handle the click on a "navigation" button type.  This button is designed
 * to go to go to a module another pane.
 *
 * @param params props passed from the action button
 * @returns {*} the generated (clickable) tag to display
 * @constructor
 */
const NavigationButton = (params) => {
	params = Object.assign({
		className: '',
		title: '',
		tag: 'button',
		navigationKey: 'navigation',
		navigationKeys: {},
		attributes: {},
		replacementValues: {}
	}, params);
	const className = addClass('navigation-button', params.className);
	const label = isEmpty(params.title) ? '' : Translate.Text({id: params.title});
	const GeneratedTag = !isEmpty(params.tag) ? `${params.tag}` : `button`;

	const navKey = typeof params.navigationKey === 'string' ? params.navigationKey : 'navigation';
	const navigationKeys = getObjectFromJSON(params.navigationKeys, {});
	const navigationKey = navigationKeys.hasOwnProperty(navKey) ? navigationKeys[navKey] : {};
	const navKeyAttributes = navigationKey.attributes ? navigationKey.attributes : {};
	const targetKey = navigationKey.navigationTarget;
	const targetParams = {
		targetKey: targetKey,
		attributes: deepmerge(navKeyAttributes, params.attributes),
		replacementValues: params.replacementValues
	};

	return <GeneratedTag className={className} onClick={(evt) => navigateToTarget(targetParams)}><span className={'button-text'}>{label}</span></GeneratedTag>;
};
export {NavigationButton};
