import store from '../store';
import {getLeafValue, clone} from "./objectUtils";
import {isEmpty} from "./generalUtils";

/**
 * Given a storageKey (optional) and a . separated hierarchy, return the
 * hierarchy value from the store.
 *
 * @param params
 *     storageKey: (optional) storageKey for property
 *     attributeKey: hierarchy key to value
 *     clone: (optional) true/false try to return a clone of value (not reference)
 * @returns {*} store value
 */
const getStoreValue = (params) => {
	params = Object.assign({
		storageKey: '',
		attributeKey: '',
		clone: false,
		default: null,
		state: null
	}, params);

	const state = !isEmpty(params.state) ? params.state : (!isEmpty(store) ? store.getState() : {});
	const storageState = state.hasOwnProperty(params.storageKey) ? state[params.storageKey] : state;
	let storeValue = getLeafValue(storageState, params.attributeKey);
	storeValue = (typeof storeValue !== 'undefined' && storeValue !== null) ? storeValue : params.default;
	if (params.clone === false) {
		return storeValue;
	} else {
		return clone(storeValue);
	}
};
export {getStoreValue};



/**
 *
 * @param params
 *     storageKey: (optional) storageKey for property
 *     attributeKey: hierarchy key to value
 * @param value value to set in redux state
 */
const setStoreValue = (params, value) => {
	params = Object.assign({
		storageKey: '',
		attributeKey: '',
		state: null
	}, params);
	value = typeof value !== 'undefined' ? value : '';

	const state = isEmpty(params.state) ? store.getState() : params.state;
	const storageState = state.hasOwnProperty(params.storageKey) ? state[params.storageKey] : state;
	storageState[params.attributeKey] = value;
};
export {setStoreValue};
