import React from 'react';
import {getPreviousNextIds, previousNextClicked} from "../utils/previousNextUtils";
import {ID_NOT_SET} from "../modules/_MODULE_GLOBALS/constants";
import {clone} from '../utils/objectUtils';


/**
 * Generate a Previous / Next button element
 *
 * @param params element params
 * @returns {JSX.Element} button element for display
 * @constructor
 */
const PreviousNextButton = (params) => {
 	params = Object.assign({
 		props: {},
 		mops: {},
 		direction: '',
 		articleId: ID_NOT_SET,
 		articleList: [],
 		issueName: '',
		clickAction: {},
		zoomLevel: ''
 	}, params);
 	const props = clone(params.props);

 	const previousNextIds = getPreviousNextIds(params.articleId, params.articleList);

 	let label;
 	let buttonArticleId;
 	let direction;
 	if (params.direction === 'previous') {
 		label = 'Go to Previous Article';
 		buttonArticleId = previousNextIds.previousId;
 		direction = 'Previous Page';
 	} else {
 		label = 'Go to Next Article';
 		buttonArticleId = previousNextIds.nextId;
 		direction = 'Next Page';
 	}
 	// reset replaceHistoryState attribute
 	props.replaceHistoryState = false;
 	const articleClickParams = {
 		articleId: buttonArticleId,
 		props: props,
 		mops: params.mops,
 		articleList: params.articleList,
 		eventType: 'click',
 		direction: direction,
		clickAction: params.clickAction,
		zoomLevel: params.zoomLevel
 	};

 	return (
 		<button
 			className={params.direction + '-button' + (buttonArticleId <= 0 ? ' inactive' : '')}
 			data-articleid={buttonArticleId <= 0 ? '' : buttonArticleId}
 			data-issuename={buttonArticleId <= 0 ? '' : params.issueName}
 			onClick={(evt) => previousNextClicked( {event: evt, articleClickParams: articleClickParams})}>
 			<div className={'direction-icon'} />
 			<span className={'screen-reader'}>{label}</span>
 		</button>
 	);
};

export {PreviousNextButton};
