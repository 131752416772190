import {getStoreValue, setStoreValue} from "./storeValue";


/**
 * Return the identified display size to determine structure to use.  Sizes
 * usually include (xl, lg, md, sm, xs).
 *
 * @returns {string} display size name
 */
const getDeviceType = () => {
	const displays = getStoreValue({attributeKey: 'displays'});
	if (displays !== null && typeof window !== 'undefined') {
		// convert to int, then sort, largest to smallest breakpoint
		const displaysArray = Object.keys(displays).map(key => {
			return {name: key, breakpoint: parseInt(displays[key], 10)};
		});
		displaysArray.sort((a, b) => {
			return b.breakpoint - a.breakpoint;
		});
		// find first case where window.innerwidth >= breakpoint size
		const displaySize = displaysArray.find((display) => window.innerWidth >= display.breakpoint);
		return (displaySize && displaySize.name) ? displaySize.name : 'xl';
	} else {
		return null;
	}
};
export default getDeviceType;
