import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {REPLICA_LIST, ID_NOT_SET} from "../../_MODULE_GLOBALS/constants";
import {fetchData, updateData} from '../../../store/actions';
import {
	generateMops,
	devicePageGrid,
	dataModuleAttributes,
	displayOnDevice,
	generateQueryParams
} from "../../../utils/moduleSetup";
import {GenerateInProgressPage, manageDeviceResize} from "../../../widgets/generatePages";
import {generateReplicaDisplayParams} from "../../../utils/replicaList/generateReplicaDisplayParams";
import {getObjectFromJSON, clone, cloneWithoutReplicaList} from "../../../utils/objectUtils";
import {useFetchAttributesChange, useFetchComplete} from "../../../hooks/useFetchChange";
import {getReplicaList} from "../../../utils/replicaList/getReplicaList";
import {isTrue, isEmpty, getIntValue} from "../../../utils/generalUtils";
import {setCurrentPage} from "../../../utils/replicaList/setCurrentPage";
import {addClass} from "../../../utils/generateClassName";
import {getDocTypeAttribute} from "../../../utils/getDocTypeAttribute";
import {useMountPostRender} from "../../../hooks/useMount";
import {GenerateThumbnailReplicaList} from "../../../utils/replicaList/generateThumbnailReplicaList";
import {GenerateListStyleReplicaList} from "../../../utils/replicaList/generateListStyleReplicaList";


// list of possible query params sent with api call
// key is props name; value is fetch parameter
const configQueryParams = {
//	'maxEntries':'maxEntries',  // maxEntries has been removed
	'pageSize':'pageSize',  // maxEntries was an alias for pageSize
	'issueUrl': 'issueUrl',
	'isLoggedIn': 'isLoggedIn',
	'userAccessHash': 'userAccessHash',
	'u1': 'u1',
};

/*
	list of potential configuration parameters; other than query params.  (docType) means that it has sub-attributes matched to docType
		className: (docType) optional class name to add to div
		displayTitle: {true/false} - display the title; default false if not set
		maxDisplay: {value} - number of entries to display for paging
		cardTemplate: (docType) attributes for styling an replica list "card" (summary block); plus sub-attributes linked to docType
		gridColumns: {object} - number of columns at different pure grid breakpoints
			sm: {number}
			md: {number}
			lg: {number}
 */


/**
 * Start of jsx code for module
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ReplicaListModule = (props) => {
	// updating value triggers re-render; for pagination
	const [paginationWidth, setPaginationWidth] = useState(window.innerWidth);
	// store serialized data to keep the last "valid" list
	const [replicaList, setReplicaList] = useState([]);
	const [pageSpread, setPageSpread] = useState(1);
	const [fetchInProgress, setFetchInProgress] = useState(false);

	// Callback only once when module is setup.
	useMountPostRender(() => {
		// setup to trigger change if the device size value changes
		manageDeviceResize(setPaginationWidth);
	});

	useEffect(() => {
		if (props.pageSpread !== ID_NOT_SET && pageSpread !== props.pageSpread) {
			setPageSpread(props.pageSpread);
		}
	}, [props.pageSpread, pageSpread]);


	// clone props so nothing is overwritten
	//const moduleProps = clone(props);
	const moduleProps = cloneWithoutReplicaList(props);

	/**
	 * Call to generate module-specific props, but that are generally common to all library
	 * modules.
	 * Generally, returns titleParams, className, storageKey, queryParams
	 *
	 * Note: this should be called very early so that subsequent functions can use these.
	 *
	 * @type {{queryParams: {}, titleParams: {}, className: string, storageKey: string}}
	 */
	const mops = generateMops(moduleProps, {
		defaultKey: REPLICA_LIST,
		defaultClass: addClass('replicaList', [moduleProps.className]),
		title: moduleProps.title,
		titleTag: 'h2',
		configQueryParams: configQueryParams
	});

	/**
	 * Called to manage whether or not the module fetches data.  If the hook calls the
	 * callback, then call getReplicaList which will determine whether or not to
	 * fetch a new list or get an existing list from stored data.
	 *
	 * Note: this use mops, so that must come first.
	 * Note: props is used internally in the hook.
	 */
	useFetchAttributesChange(() => {
		setFetchInProgress(true);
		getReplicaList({storageKey: mops.storageKey, queryParams: mops.queryParams});
	}, {type: REPLICA_LIST, props: moduleProps, queryParams: mops.queryParams});

	/**
	 * Call hook to check to see if the fetchInProgress has been set/changed in the reducer.
	 * fetchInProgress is set true when fetch is started (DATA_REQUESTED)
	 * and set to false when fetch is done (DATA_LOADED)
	 */
	useFetchComplete((isInProgress) => {
		isInProgress = isTrue(isInProgress, {defaultValue: false});
		setFetchInProgress((isInProgress));
		if (!isInProgress) {
			const storedListData = getReplicaList({storageKey: mops.storageKey, queryParams: mops.queryParams, returnStoredDataOnly: true});
			setReplicaList(storedListData);
		}
	}, {requestInProgress: props.fetchInProgress, fetchInProgress: fetchInProgress});


	if (displayOnDevice(moduleProps)) {
		if (fetchInProgress) {
			return moduleProps.displayInProgressPage ? <GenerateInProgressPage displaySpinner={true} /> : '';
		} else {
			if (moduleProps.useListStyleToc) {
				return <GenerateListStyleReplicaList moduleProps={moduleProps} mops={mops} replicaList={replicaList} pageSpread={pageSpread} />;
			} else {
				return <GenerateThumbnailReplicaList moduleProps={moduleProps} mops={mops} replicaList={replicaList} pageSpread={pageSpread} />;
			}
		}
	} else {
		return null;
	}
};


/**
 * Map state (store) data for the replicaList module; added to module props.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{key: Value}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : REPLICA_LIST;
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};
	const globalState = !isEmpty(state.globals) ? state.globals : {};

	// capture some values for other attributes use
	const grid = getObjectFromJSON(props.grid, {});
	// note: pageNumbers for replica pages start at 1
	let pageNumber = !isEmpty(storeState.pageNumber) ? parseInt(storeState.pageNumber, 10) : 1;
	pageNumber = !isNaN(pageNumber, 10) ? pageNumber : 1;

	let replicaListProps = {
		storageKey: storageKey,
		grid: grid,
		pageGridAttributes: devicePageGrid(grid),
		canDisplayCoverImage: isTrue(props.canDisplayCoverImage, {defaultValue: true}),
		canDownloadPDF: isTrue(props.canDownloadPDF, {defaultValue: true}),
		docType: !isEmpty(storeState.docType) ? storeState.docType : 'Default',
		pageNumber: pageNumber,
		folio: !isEmpty(storeState.folio) ? storeState.folio : '',
		isLoggedIn: isTrue(globalState.isLoggedIn, {defaultValue: false}),
		app: isTrue(state.app, {defaultValue: false}),
		pdfDownloadUrl: !isEmpty(storeState.pdfDownloadUrl) ? storeState.pdfDownloadUrl : '',
		displayActiveIndicator: isTrue(props.displayActiveIndicator, {defaultValue: true}),
		scrollToSelection: isTrue(props.scrollToSelection, {defaultValue: true}),
		fetchInProgress: isTrue(storeState.fetchInProgress, {defaultValue: false}),
		displayInProgressPage: isTrue(props.displayInProgressPage, {defaultValue: true}),
		pageSpread: !isEmpty(storeState.pageSpread) ? getIntValue(storeState.pageSpread) : '',
		zoomLevel: !isEmpty(storeState.zoomLevel) ? getIntValue(storeState.zoomLevel) : '',
		listStyleToc: !isEmpty(storeState.listStyleToc) ? storeState.listStyleToc : '',
		useListStyleToc: isTrue(props.useListStyleToc, {defaultValue: false}),
	};

	// add module properties that are common to data modules
	replicaListProps = dataModuleAttributes({
		moduleProps: replicaListProps,
		originalProps: props,
		state: state,
		storageKey: storageKey,
		navigationAttributeType: 'replica'
	});

	// call to get the last list that matches query params (returns empty list if none)
	const queryParams = generateQueryParams({configQueryParams: configQueryParams, moduleProps: replicaListProps});
	replicaListProps.replicaList = getReplicaList({storageKey: replicaListProps.storageKey, queryParams: queryParams, returnStoredDataOnly: true});

	// set folio to first page if empty and replicaList is set
	if (isEmpty(replicaListProps.folio) && !isEmpty(replicaListProps.replicaList) && replicaListProps.replicaList.length > 0) {
		replicaListProps.folio = replicaListProps.replicaList[0].folio;
	}

	// Set the page number for the selected page; replicaList needs to know which page to mark as current
	replicaListProps = setCurrentPage({
		storeState: storeState,
		moduleProps: replicaListProps,
	});

	// generate a className for the list based on docType
	replicaListProps.className = getDocTypeAttribute(replicaListProps.docType, props.className);

	// setup all the replicaDisplayParams for creating a list card; generated from cardTemplate configuration
	// also determine document (replica list) data from documentMap
	replicaListProps = generateReplicaDisplayParams({
		originalProps: props,
		moduleProps: replicaListProps,
		state: state,
		storeState: storeState
	});

	return replicaListProps;
};

/**
* Actions that can be called by this module.  Each action is added to props so
* that it can be called in the module, but defined in a single place with
* appropriate parameters for the action call by this module.
 *
 * @param dispatch
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		fetchData: (params) => {
			params.type = params.hasOwnProperty('type') ? params.type : REPLICA_LIST;
			dispatch(fetchData(params));
		},
		updateData: (payload, params) => {
			params.type = params.hasOwnProperty('type') ? params.type : REPLICA_LIST;
			dispatch(updateData(payload, params));
		}
	};
}


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReplicaListModule);
