/*
	Gather all modules in one place to use in populateStructure.jsx.

	import all Modules used in the product
	include each module in the allModules object with a sensible key

	NOTE: The key name assigned to each module in allModules (ie. articleList)
	is the same name that is used in the layout configuration to identify
	the module to place in a container.

 */
import Globals from "../globals/globals";

// modules in common directory
import AddTracker from '../common/addTracker';
import Authentication from '../common/authentication';
import Banner from '../common/banner';
import EasterEgg from "../common/easterEgg";
import EmbedJavascript from '../common/embedJavascript';
import Html from '../common/htmlFragment';
import HtmlFragment from '../common/htmlFragment';
import Logo from '../common/logo';
import ReactShareButtons from "../common/reactShareButtons";
import ShareButtons from "../common/shareButtons";
import SimpleImage from '../common/simpleImage';
import StaticAd from '../common/staticAd';
import GoogleAd from '../common/googleAd';
import StaticButton from "../common/staticButton";
import TaggedText from '../common/taggedText';
import Video from '../common/video';
import ModalMenu from '../common/modalMenu';

// modules in library directory
import ArticleList from '../library/articleList';
import ArticleListFilter from '../library/filterList';
import ArticleViewer from '../library/articleViewer';
import CoverStory from "../library/coverStory";
import IssueList from '../library/issueList';
import IssuesFilter from '../library/issueList/issuesFilter';
import LibraryTarget from "../library/libraryTarget";
import ReplicaList from "../library/replicaList";
import ReplicaViewer from '../library/replicaViewer';
import ResponsiveButton from "../library/responsiveButton";
import SearchEntry from '../library/search/filters/searchEntry';
import SearchIssues from '../library/search/filters/searchIssues';
import SearchResults from '../library/search';
import SearchSort from '../library/search/filters/searchSort';
import SearchType from '../library/search/filters/searchType';
import SearchTarget from '../library/search/filters/searchTarget';



const libraryModules = {
	globals: Globals,

	// modules in common
	addTracker: AddTracker,
	authentication: Authentication,
	banner: Banner,
	easterEgg: EasterEgg,
	embedJavascript: EmbedJavascript,
	html: Html,
	htmlFragment: HtmlFragment,
	logo: Logo,
	reactShareButtons: ReactShareButtons,
	shareButtons: ShareButtons,
	simpleImage: SimpleImage,
	staticAd: StaticAd,
	googleAd: GoogleAd,
	staticButton: StaticButton,
	taggedText: TaggedText,
	video: Video,
	modalMenu: ModalMenu,


	// modules in library
	articleList: ArticleList,
	articleListFilter: ArticleListFilter,
	articleViewer: ArticleViewer,
	coverStory: CoverStory,
	issueList: IssueList,
	issuesFilter: IssuesFilter,
	libraryTarget: LibraryTarget,
	replicaList: ReplicaList,
	replicaViewer: ReplicaViewer,
	responsiveButton: ResponsiveButton,
	searchEntry: SearchEntry,
	searchIssues: SearchIssues,
	searchResults: SearchResults,
	searchSort: SearchSort,
	searchType: SearchType,
	searchTarget: SearchTarget
};
const gatheredLibraryModules = () => {
	return libraryModules;
};
export {gatheredLibraryModules};
