import React, {useState} from 'react';
import { Translate } from "../../locales/locales";
import * as Tracking from "../../utils/tracking";
import ReactPlayer from "react-player";
import {isEmpty, isTrue} from "../../utils/generalUtils";
import {addClass} from "../../utils/generateClassName";
import getDeviceType from "../../utils/getDeviceType";
import {stringIncludes} from "../../utils/stringUtils";
import extractDomain from "extract-domain";
import {getModuleTrackingName, getPlatform, getPopupType} from "./mediaAttributes";


/**
 * Some properties are editable in Page Editor and can have leading/trailing spaces by mistake.
 * Since that can potentially cause problems, trim the listed strings.
 *
 * @param popup
 */
const trimAttributes = (popup) => {
	const trimmable = [
		'url',
		'title',
		'popupName',
		'linkAlt',
		'linkHref',
	];
	trimmable.forEach(attr => {
		popup[attr] = !isEmpty(popup[attr]) ? popup[attr].trim() : '';
	});
};


/**
 * Add "px" or "%" to size attributes depending on value of "scaleToPage"
 * This only applies to width and height as top and left properties are
 * always in percentage.
 *
 * Note: The attribute "anchorToPage" which used to control percentage for
 * positioning is now always true.
 *
 * @param popup popup object on page
 *     top: absolute top value in %
 *     left: absolute left value in %
 *     width: width of popup
 *     height; height of popup
 * @returns {{"data-player-name": *, style: {top: *, left: *, width: *, height: *}, id: *, key: *}}
 */
const createWrapperAttributes = (popup) => {
	const sizeUnit = isTrue(popup.scaleToPage, {default: false}) ? '%' : 'px';
	const positionUnit = '%';  // for now, always percentage
	return {
		"key": popup.popupcardId,
		"id": popup.popupcardId,
		"data-player-name": popup.popupName,
		"style": {
			"top": popup.top + positionUnit,
			"left": popup.left + positionUnit,
			"width": popup.width + sizeUnit,
			"height": popup.height + sizeUnit
		}
	};
};


/**
 * Generate popupType specific values where required.
 *
 * Facebook
 *     add values for data-width and data-height, depending on absolute size (px based)
 *     or responsive size (% based)
 *         absolute: specify both width/height in px
 *         percentage: specify a large value for data-width, none for data-height
 *             video will be constrained to the width of the container, but height
 *             will vary and may be shorter or taller than container height if the
 *             defined aspect ratio in the Page Editor doesn't match the video
 *
 * @param popup popup object on page
 * @param popupType popup type
 * @returns {{controls: boolean, width: string, preload: string, url: *, height: string}}
 */
const createPlayerAttributes = (popup, popupType) => {
	const playerAttributes = {
		"url": popup.url,
		"controls": true,
		"preload": "auto",
		"width": "100%",
		"height": "100%",
	};
	if (isTrue(popup.autoPlay)) {
		playerAttributes.playing = true;
	}

	// FACEBOOK attributes
	// If "scale with page" is set, use large size for data-width as maximum width
	// video will not get wider than container; although height may not fit container
	if (isTrue(popupType === 'facebook')) {
		const scale = isTrue(popup.scaleToPage, {default: true});
		playerAttributes.config = {
			facebook: {
				attributes: {
					"data-width": scale ? "1400px" : popup.width + 'px',
					"data-height": scale ? "" : popup.height + 'px',
				}
			}
		};
	}

	return playerAttributes;
};


/**
 * Call Content Viewed tracking when the popup appears on the page when it is viewed
 *
 * @param params all attributes passed in
 *     popup: popup object on page
 *     popupType: type of popup
 *     platform: Desktop (web), Mobile (mobileWeb), App (mobileApp)
 *     callerAttributes: attributes passed in from calling module
 *         replicaViewer: for internal page linking
 */
const trackViewed = (params) => {
	const {popup, popupType, platform, callerAttributes} = params;
	const trackingProperties = {
		"breakpoint": getDeviceType(),
		"module": getModuleTrackingName(callerAttributes.module),
		"page folio": popup.folioPage,
		"popup platform": platform.tracking,
		"popup name": popup.title,
		"popup type": popupType.tracking.popupType,
		"type": popupType.tracking.type,
	};
	if (!isEmpty(popupType.tracking.videoPlatform)) {
		trackingProperties["video platform"] = popupType.tracking.videoPlatform;
	} else if (!isEmpty(popupType.tracking.audioPlatform)) {
		trackingProperties["audio platform"] = popupType.tracking.audioPlatform;
	}
//	console.log("Content Viewed tracking", trackingProperties);
	Tracking.libraryTrack("content viewed", trackingProperties);
};


/**
 * Call Link Clicked tracking when a link on the popup is clicked.
 *
 * Note: This will only be called for external links, as internal links
 * will be tracked as page viewed on landing page.
 *
 * @param params
 *     popup: popup object on page
 *     popupType: type of popup
 *     platform: Desktop (web), Mobile (mobileWeb), App (mobileApp)
 *     callerAttributes: attributes passed in from calling module
 *         replicaViewer: for internal page linking
 */
const trackPopupLinkClicked = (params) => {
	const {popup, popupType, platform, callerAttributes} = params;

	const trackingProperties = {
		"breakpoint": getDeviceType(),
		"module": getModuleTrackingName(callerAttributes.module),
		"page folio": popup.folioPage,
		"popup platform": platform.tracking,
		"popup name": popup.title,
		"type": "popup",
		"popup type": popupType.tracking.popupType,
		"url": popup.linkHref,
		"domain": extractDomain(popup.linkHref, { tld: true })
	};
//	console.log("Link Click tracking", trackingProperties);
	Tracking.libraryTrack("link clicked", trackingProperties);
};


/**
 * Close button widget
 *
 * @param popup popup object on page
 * @param setClosed function to call to set "closed" state in caller
 * @returns {JSX.Element|null}
 * @constructor
 */
const CloseButton = ({popup, setClosed}) => {
	if (isTrue(popup.closeButton)) {
		return (
			<button
				className={'media-close-button'}
				type={'button'}
				title={Translate.Text({id: 'mediaPlayer.close'})}
				aria-label={Translate.Text({id: 'mediaPlayer.close'})}
				tabIndex={0}
				onClick={() => setClosed(true)}  // will trigger re-render to hide popup
			>
				<span className={Translate.Text({id: 'mediaPlayer.closeButton'})}></span>
			</button>
		);
	} else {
		return null;
	}
};


/**
 * Html <a> widget in case a link is defined as a clickable link for the popup.
 * Generates an element that covers the popup so that clicking on the popup
 * takes you to the link address, as configured in the Page Editor.
 * This may be an external link or to another internal replica page.
 *
 * This will usually only be configurable for "img" or "video" popups,
 * but, if the Page Editor allows linking, then we will create the link cover.
 *
 * Size of the cover <a> tag is defined in styles.scss.
 * Note that "img" cover size is handled differently from "video" cover size
 *     "img": base class "media-link-cover"
 *         full coverage of the img with the link
 *     "video:: base class "media-link-cover video-cover-link"
 *         video-cover-link reduces the height by 65px to accommodate player controls
 *
 * @param params all attributes passed in
 *     popup: popup object on page
 *     additionalClassName: class name to add to element
 *     callerAttributes: attributes passed in from calling module
 *         replicaViewer: for internal page linking
 * @returns {JSX.Element|null}
 * @constructor
 */
const LinkCover = (params) => {
	const {popup, additionalClassName, callerAttributes} = params;
	if (!isEmpty(popup.linkHref)) {
		const isInternalReplicaLink = isTrue(stringIncludes(popup.linkHref, 'internalLinkToPage'), {default: false});
		// internal page linking
		if (isInternalReplicaLink) {
			const folioMatch = popup.linkHref.match(/\((.*?)\)/);
			const folio = Array.isArray(folioMatch) && !isEmpty(folioMatch[1]) ? folioMatch[1].toString() : '';
			const replicaAttributes = callerAttributes.module === 'replicaViewer' ? callerAttributes.attributes : {};
			if (!isEmpty(folio) && !isEmpty(replicaAttributes)) {
				const className = addClass('media-link-cover internal-link', additionalClassName);
				return (
					<a
						href={"//goto_page/"+folio}
						className={className}
						title={popup.linkAlt}
						onClick={(evt) => {
							// tracking call made on target internal page
							replicaAttributes.gotoInternalPage({
								evt: evt,
								props: replicaAttributes.props,
								replicaList: replicaAttributes.replicaList,
								folio: folio,
							});
						}}
					>
					</a>
				);
			} else {
				return null;
			}
		} else {
			const className = addClass('media-link-cover', additionalClassName);
			return (
				<a
					href={popup.linkHref}
					target={"_blank"}
					className={className}
					rel={"noreferrer"}
					title={popup.linkAlt}
					onClick={(evt) => {
//						console.log("Call Tracking: Link Clicked to EXTERNAL page");
						trackPopupLinkClicked(params);
					}}
				>
				</a>
			);
		}
	} else {
		return null;
	}
};


/**
 * Generate the link on the page that will display the popup.  For Reverse Popups,
 * the popup is initially hidden (marked "closed") and clicking on the link
 * will mark the popup as not closed, so that it will be rendered and displayed.
 *
 * Note: classes "generated-link link-normal" so it behaves the same as regular links
 *    for hover highlights and transition effects
 *
 * @param popup popup object on page
 * @param setClosed function to call to set "closed" state in caller
 * @returns {JSX.Element|null}
 * @constructor
 */
const ReverseLink = ({popup, setClosed}) => {
	if (isTrue(popup.reversePopup) && !isEmpty(popup.reverseLink)) {
		const classes = [
			'media-open-popup-button',
			'generated-link',
			'link-normal',
			isTrue(popup.reverseLink.underline) ? 'underline' : 'box'
		].join(' ');
		const linkStyles = {
			left: popup.reverseLink.left + '%',
			top: popup.reverseLink.top + '%',
			width: popup.reverseLink.width + '%',
			height: popup.reverseLink.height + '%',
		};
		// override underline color if set
		if (isTrue(popup.reverseLink.underline) && !isEmpty(popup.reverseLink.underlineColor)) {
			linkStyles.borderBottomColor = '#'+popup.reverseLink.underlineColor;
		}

		return (
			<button
				className={classes}
				type={'button'}
				title={popup.reverseLink.alt}
				aria-label={popup.reverseLink.alt}
				style={linkStyles}
				tabIndex={0}
				onClick={() => setClosed(false)}  // will trigger re-render to display popup
			>
			</button>
		);
	} else {
		return null;
	}

};

/**
 * Use the react-player package to display a popup on the page.
 * The plugin can generate a <video> element. <audio> element,
 * or create an iframe around a youtube video, as detected by the plugin.
 *
 * <video> elements get special handling if set to autoplay as some
 * browsers will not let the video autoplay is volume is not muted.
 *
 * @param params all attributes passed in
 *     popup: popup object on page
 *     popupType: type of popup
 *         "video": from one of several file types as defined above
 *         "audio:: from one of several file types as defined above
 *         "youtube": set by the Page Editor
 * @returns {JSX.Element|null}
 * @constructor
 */
const GenerateReactPlayer = (params) => {
	const {popup, popupType} = params;
	// if closed state for a popup changes, re-render popup
	const [closed, setClosed] = useState(isTrue(popup.reversePopup));
	const [tracked, setTracked] = useState(false);

	if (!isTrue(closed)) {
		const playerAttributes = createPlayerAttributes(popup, popupType);
		const wrapperAttributes = createWrapperAttributes(popup);
		const wrapperClassName = addClass('media-player video-viewer', popupType+'-video');
		const linkClassName = popupType === 'video' ? 'video-cover-link' : '';  // only setting for video

		if (!tracked) {
//			console.log("Call Tracking: React Player");
			trackViewed(params);
			setTracked(true);
		}

		return (
			<div className={wrapperClassName} {...wrapperAttributes}>
				<ReactPlayer {...playerAttributes} />
				<LinkCover {...params} />
				<CloseButton popup={popup} setClosed={setClosed} />
			</div>
		);
	} else if (isTrue(popup.reversePopup)) {
		return (
			<ReverseLink popup={popup} setClosed={setClosed} />
		);
	} else {
		return null;
	}
};


/**
 * Generate an <img> tag for the popup.
 *
 * This can either be set in the Page Editor as popupType: "img" or
 * detected from the file extension for web-supported image types.
 *
 * @param params all attributes passed in
 *     popup: popup object on page
 * @returns {JSX.Element|null}
 * @constructor
 */
const GenerateImage = (params) => {
	const {popup} = params;
	// if closed state for a popup changes, re-render popup
	const [closed, setClosed] = useState(isTrue(popup.reversePopup));
	const [tracked, setTracked] = useState(false);

	if (!isTrue(closed)) {
		const imageAttributes = {
			"src": popup.url,
			"style": {"width": "100%", "height": "100%"}
		};
		const wrapperAttributes = createWrapperAttributes(popup);
		const wrapperClassName = 'media-player image-viewer';

		if (!tracked) {
//			console.log("Call Tracking: image");
			trackViewed(params);
			setTracked(true);
		}

		return (
			<div className={wrapperClassName} {...wrapperAttributes}>
				<img alt={popup.title} {...imageAttributes} />
				<LinkCover {...params} />
				<CloseButton popup={popup} setClosed={setClosed} />
			</div>
		);
	} else if (isTrue(popup.reversePopup)) {
		return (
			<ReverseLink popup={popup} setClosed={setClosed} />
		);
	} else {
		return null;
	}
};


/**
 * Generate an iframe for the popup and use the source url as the source
 * for the iframe.
 *
 * This popup type is set in the Page Editor as iframe and does not have
 * a supported file type as the url for the popup.  This usually means
 * that the url is some kind of included html.
 *
 * @param params all attributes passed in
 *     popup: popup object on page
 * @returns {JSX.Element|null}
 * @constructor
 */
const GenerateIframe = (params) => {
	const {popup} = params;
	// if closed state for a popup changes, re-render popup
	const [closed, setClosed] = useState(isTrue(popup.reversePopup));
	const [tracked, setTracked] = useState(false);

	if (!isTrue(closed)) {
		const iframeAttributes = {
			"id": popup.popupcardId,
			"width": "100%",
			"height": "100%",
			"src": popup.url
		};
		const wrapperAttributes = createWrapperAttributes(popup);
		const wrapperClassName = 'media-player iframe-viewer';

		if (!tracked) {
//			console.log("Call Tracking: iframe");
			trackViewed(params);
			setTracked(true);
		}

		return (
			<div className={wrapperClassName} {...wrapperAttributes}>
				<iframe title={popup.title} {...iframeAttributes}></iframe>
				<CloseButton popup={popup} setClosed={setClosed} />
			</div>
		);
	} else if (isTrue(popup.reversePopup)) {
		return (
			<ReverseLink popup={popup} setClosed={setClosed} />
		);
	} else {
		return null;
	}
};


/**
 * Base media player widget that will then call to include a widget that
 * matches the specific kind of popup to be displayed.
 *
 * If the user does not have full access to the issue, we display truncated
 * content, either replica thumbnails or article part.  In that case, we
 * don't want to generate popups.
 *
 * Supported popup types
 *     "iframe": place an iframe on the page and use source for iframe source
 *     "img": "img" from Page Editor or detected file type
 *     other: any other popup type uses react-player to display
 *
 * Note: If the popup is not displayable on a particular platform, don't
 * call to create.  Values in the popup are true/false for each PLATFORM_MATCH
 * platform type.
 *
 * @param popups popups for a particular page
 * @param issueHasAccess user has full access to the issue
 * @param callerAttributes optional attributes passed in from the calling module
 *     replicaViewer: popup added from replicaViewer
 * @returns {JSX.Element|null}
 * @constructor
 */
const MediaPlayer = ({popups, issueHasAccess, callerAttributes}) => {
	if (!isEmpty(popups) && isTrue(issueHasAccess)) {
		const platform = getPlatform();
		return (
			<>
				{popups.map((popup) => {
					if (isTrue(popup[platform.configured], {default: true})) {
						trimAttributes(popup);
						const popupAttributes = {
							popup: popup,
							popupType: getPopupType(popup),
							callerAttributes: callerAttributes,
							platform: platform,
							key: popup.popupcardId  // React-required key for elements generated in a loop
						};

						if (popupAttributes.popupType.element === 'iframe') {
							return <GenerateIframe {...popupAttributes} />;
						} else if (popupAttributes.popupType.element === 'img') {
							return <GenerateImage {...popupAttributes} />;
						} else {
							return <GenerateReactPlayer {...popupAttributes} />;
						}
					} else {
						return null;
					}
				})}
			</>
		);
	} else {
		return null;
	}
};
export {MediaPlayer};
