import React from "react";
import GenerateCardSingleArticle from "../../../../widgets/articleListCards/generateCardSingleArticle";
import GenerateTitle from "../../../../widgets/generateTitle";
import GenerateCardArticleLinks from "../../../../widgets/articleListCards/generateCardArticleLinks";
import {populateArticleActions} from "../../../../utils/articleList/articleActions";


/**
 * Generate a Single Article card element from an array of elements
 * filtered by category.
 *
 * @param params
 *     entries: filtered article list
 *     actions: article click actions
 *     displayParams: how to display article element
 * @returns {JSX.Element|string}
 * @constructor
 */
const GenerateComboListSingleArticle = (params) => {
	params = Object.assign({
		entries: [],
		actions: {},
		displayParams: {}
	}, params);

	const entries = params.entries;
	const actions = params.actions;
	const displayParams = params.displayParams;

	let entry = {};

	for (let i = 0; i < entries.length; i++) {
		entry = entries[i];
		// use the first article with configured featureCategory
		if (entry.categories.includes(displayParams.featureCategory)) {
			break;
		}
	}

	if (Object.keys(entry).length !== 0) {
		return (
			<div className={'single-article'}>
				<GenerateCardSingleArticle entry={entry} actions={actions} displayParams={displayParams} />
			</div>
		);
	} else {
		return '';
	}
};


/**
 * Generate jsx for article title and links from an array of articles filtered by category
 *
 * @param params
 * @returns {JSX.Element}
 *     entries: filtered article list
 *     actions: article click actions
 *     displayParams: how to display article element
 * @constructor
 */
const GenerateComboListArticleLinks = (params) => {
	params = Object.assign({
		entries: [],
		actions: {},
		displayParams: {}
	}, params);

	const entries = params.entries;
	const actions = params.actions;
	const displayParams = params.displayParams;

	const titleParams = {
		tag: 'h3',
		className: 'title',
		displayTitle: true,
	};

	return (
		<div className={'combo-list-articles'}>
			<GenerateTitle titleParams={titleParams}>{displayParams.listCategory}</GenerateTitle>
			<GenerateCardArticleLinks entries={entries} actions={actions} displayParams={displayParams} />
		</div>
	);
};


/**
 * Generate the jsx for each article list card in the Combo List
 *
 * @param pageParams
 *     props: module props
 *     mops: module mops
 *     entries: article list
 *     page: page number to display (always 0)
 *     entryPage: entry page number (always 0)
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateComboList = (pageParams) => {
	pageParams = Object.assign({
		props: {},
		mops: {},
		entries: [],
		page: 0,
		entryPage: 0
	}, pageParams);
	const props = pageParams.props;
	const mops = pageParams.mops;
	const articleDisplayParams = props.hasOwnProperty('articleDisplayParams') ? props.articleDisplayParams : {};
	const articleList = pageParams.entries;

	const articleActions = populateArticleActions({props: props, mops: pageParams.mops, articleList: articleList});
	// there is only 1 page, so should always be selected
	const displayClassName = 'display-page' + (pageParams.page === pageParams.entryPage ? ' selected' : '');

	return (
		<div className={displayClassName} data-page={pageParams.page}>
			<h2>
				<i className={'icon-star'}><span>Star Icon</span>
					<svg viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 0L15.9187 8.98278H25.3637L17.7225 14.5344L20.6412 23.5172L13 17.9656L5.35879 23.5172L8.27747 14.5344L0.636266 8.98278H10.0813L13 0Z" /></svg>
				</i>
				{props.title}
			</h2>
			<div className={'combo-list-row'}>
				<GenerateComboListSingleArticle entries={articleList} actions={articleActions} displayParams={articleDisplayParams} />
				<GenerateComboListArticleLinks entries={articleList} actions={articleActions} displayParams={articleDisplayParams} />
			</div>
		</div>
	);
};
export {GenerateComboList};
