import React from "react";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import {clone} from "../utils/objectUtils";
import {isTrue, isEmpty} from "../utils/generalUtils";
import {Translate} from "../locales/locales";
import CssSpinner from "./cssSpinner/cssSpinner";



/**
 * Add window resize check to and callback the passed-in function.  This is generally
 * used by the caller to re-do the UI for pagination block.  On window resize,
 * callback to caller with new window width.
 * Wait for 200 milliseconds to allow window resizing to settle down
 * so re-draw doesn't happen too often; probably not a problem with modern browsers.
 *
 * @param callback
 */
const manageDeviceResize = (callback) => {
	let pageResizeTimer = null;
	// Note: typeof check is for node compilation...
	if (typeof window !== 'undefined') {
		$(window).resize(function () {
			window.clearTimeout(pageResizeTimer);
			pageResizeTimer = window.setTimeout(function () {
				callback(window.innerWidth);
			}, 200);
		});
	}
};
export {manageDeviceResize};

/**
 * Pagination values to use for configuration parameters for react-paginate
 *     marginPagesDisplayed, pageRangeDisplayed
 * depending on browser width.
 *
 * @returns {*} set of sizes dependent on browser size.
 */
const getDevicePaginationValues = () => {
	const displaySizes = {0: 'phone', 600: 'smallTablet', 800: 'desktopTablet'};
	const paginationConstants = {
		desktopTablet:  {25: [1,1], 33: [2,1], 50: [4,1], 66: [5,3]}, // >800
		smallTablet: {50: [2,1], 100: [4,1]}, // >600
		phone: {100: [2,1]} // >0
	};
	let pageSize = 0;
	Object.keys(displaySizes).forEach((size) => {
		if (window.innerWidth >= size) {
			pageSize = size;
		}
	});
	return paginationConstants[displaySizes[pageSize]];
};

/**
 * For a given browser size and paged container size, return a set of values for
 * marginPagesDisplayed and pageRangeDisplayed
 *
 * @param $pagedContainer container being paged
 * @returns {{marginPagesDisplayed: number, pageRangeDisplayed: number}}
 */
const calcPaginationMarginRange = ($pagedContainer) => {
	// default to smallest size, just in case
	let marginPagesDisplayed = 1;
	let pageRangeDisplayed = 1;

	if ($pagedContainer.length > 0) {
		// show to get size in case hidden
		$pagedContainer.css({'display': 'block'});
		const pagedContainerWidth = $pagedContainer.outerWidth();
		$pagedContainer.css({'display': ''});

		const paginationValues = getDevicePaginationValues();
		const containerPercentage = Math.floor(((pagedContainerWidth / window.innerWidth) * 100));
		// find closest match to percentage
		const marginRangeSize = Object.keys(paginationValues).reduce((prev, curr) => {
			return (Math.abs(curr - containerPercentage) < Math.abs(prev - containerPercentage)) ? curr : prev;
		});
		marginPagesDisplayed = paginationValues[marginRangeSize][0];
		pageRangeDisplayed = paginationValues[marginRangeSize][1];
	}
	return {marginPagesDisplayed: marginPagesDisplayed, pageRangeDisplayed: pageRangeDisplayed};

/*
	// keep for now...
	// responsive calculation if we hook pagination values into browser resize event
	if ($pagedContainer.length > 0) {
		// show to get size in case hidden
		$pagedContainer.css({'display': 'block'});
		const pagedContainerWidth = $pagedContainer.outerWidth();
		$pagedContainer.css({'display': ''});
		let totalElements = Math.floor(pagedContainerWidth / 37);
		totalElements = totalElements <= 15 ? totalElements : 15;
		marginPagesDisplayed = Math.ceil((totalElements - 5) / 2);
		pageRangeDisplayed = (totalElements - 6) - marginPagesDisplayed;

		console.log('percentage: ' + Math.floor(((pagedContainerWidth / window.innerWidth) * 100)));
	}
	return {marginPagesDisplayed: marginPagesDisplayed, pageRangeDisplayed: pageRangeDisplayed};
	*/
};

/**
 * TODO: Fix implementation to use i18n strings and provide sensible labels.
 *
 * Unused for now.  We should activate this in the future to provide i18n support for
 * the pagination page aria labels.
 *
 * To implement, add the following to the list of defines for ReactPaginate
 *     ariaLabelBuilder={ariaLabelBuilder}
 *
 * @param page the pagination page number
 * @param selected true/false for selected pagination page
 * @returns {string}
 */
const ariaLabelBuilder = (page, selected) => {
	selected = typeof selected === 'boolean' ? selected : false;
	return page + '-' + selected;
};

/**
 * Generate the pagination block.
 *
 * ReactPaginate parameters definitions
 *     containerClassname: class name for the pagination container itself
 *     activeLinkClassName: class name for active pagination button
 *     onPageChange: callback function for when user clicks a pagination button
 *
 *     marginPagesDisplayed: the number of page numbers displayed on left and right when pages require two sets of ellipses
 *     pageRangeDisplayed: the number of page numbers displayed in the middle when pages require two sets of ellipses
 *         note: even if you enter 0, at least 1 value will be generated when the selected page is in the middle
 *         note2: this value will always default to the next higher ODD number
 *
 *     example:
 *         marginPagesDisplayed={2}
 *         pageRangeDisplayed={1}
 *
 *           pageRangeDisplayed
 *                   |
 *     <  1  2  ...  5  ...  9  10  >
 *         |                   |
 *         marginPagesDisplayed
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const Pagination = (params) => {
	const pageCount = params.hasOwnProperty('pageCount') ? params.pageCount : 0;
	const initialPage = params.hasOwnProperty('initialPage') ? params.initialPage : 0;
	const forcePage = params.hasOwnProperty('forcePage') ? params.forcePage : 0;
	const moduleTitle = params.hasOwnProperty('moduleTitle') ? params.moduleTitle : '';

	if (pageCount <= 1) {
		return '';
	} else {
		const callback = params.hasOwnProperty('callback') ? params.callback : null;
		const $pagedContainer = params.hasOwnProperty('pagedContainer') ? $(params.pagedContainer) : [];
		const marginRange = calcPaginationMarginRange($pagedContainer);
		return (
			<div className={'pagination-block'} aria-label={'Pagination for ' + moduleTitle} role={'navigation'}>
				<ReactPaginate
					containerClassName={'pagination-list'}
					pageCount={pageCount}
					marginPagesDisplayed={marginRange.marginPagesDisplayed}
					pageRangeDisplayed={marginRange.pageRangeDisplayed}
					onPageChange={callback}
					activeLinkClassName={'active'}
					previousLabel={'<'}
					previousClassName={'pagination-previous'}
					nextLabel={'>'}
					nextClassName={'pagination-next'}
					initialPage={initialPage}
					forcePage={forcePage}
				/>
			</div>
		);
	}
};

/**
 * Change page on click on paging button.
 * This also handle updating 'src' attribute of image from 'data-src' attribute
 *
 * @param pagingData data for responding to paging button
 */
const handlePaging = (pagingData) => {
	const index = pagingData.hasOwnProperty('selected') ? pagingData.selected : 0;
	const $displayPages = $(pagingData.parent).find('.display-pages');
	const $pageToDisplay = $displayPages.find('[data-page='+index+']');

	// if image has 'data-src' attribute, then populate 'src' attr from 'data-src'
	$pageToDisplay.find('img[data-src]').each(function(index, image) {
		const $image = $(image);
		$image.attr('src', $image.attr('data-src')).removeAttr('data-src');
	});

	$displayPages.find('.display-page').removeClass('selected');
	$pageToDisplay.addClass('selected');
};

/**
 * Generate pages for a list with pagination, given a set of entries.  Callback to the
 * calling function to generate the specific page of data for display.
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GeneratePages = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		entries: [],
		fullList: [],
		pageCount: -1,
		className: 'entries-pages',
		cssClass: '.entries-pages',
		GeneratePage: null,  // callback function
		entryPage: 0,
		initialPage: -1,
		updateHistoryState: false,
		articleListIdentifier: '',
		callOnUIChange: null,
		moduleDOMElement: null,
		moduleSpecific: {}
	}, params);
	const moduleProps = clone(params.props);
	const GeneratePage = params.GeneratePage;
	const numEntries = params.entries.length;
	const pageGridAttributes = moduleProps.pageGridAttributes ? moduleProps.pageGridAttributes : {};
	const maxDisplay = (pageGridAttributes.maxDisplay && !isNaN(parseInt(pageGridAttributes.maxDisplay, 10))) ? parseInt(pageGridAttributes.maxDisplay, 10) : 200;
	const entriesPerPage = (pageGridAttributes.entriesPerPage && !isNaN(parseInt(pageGridAttributes.entriesPerPage, 10))) ? parseInt(pageGridAttributes.entriesPerPage, 10) : 10;
	const pageCount = params.pageCount !== -1 ? params.pageCount : ((maxDisplay <= numEntries) ? Math.ceil(maxDisplay / entriesPerPage) : Math.ceil(numEntries / entriesPerPage));
	const initialPage = params.initialPage > 0 ? params.initialPage : params.entryPage;

	const pageParams = {
		fullList: params.fullList,
		entryPage: params.entryPage,
		props: moduleProps,
		mops: params.mops,
		updateHistoryState: isTrue(params.updateHistoryState),
		articleListIdentifier: params.articleListIdentifier,
		callOnUIChange: params.callOnUIChange,
		moduleDOMElement: params.moduleDOMElement,
		moduleSpecific: params.moduleSpecific
	};

	return (
		<div className={params.className}>
			<div className={'display-pages'}>
				{Array(pageCount).fill(null).map((value, index) => {
					const start = index * entriesPerPage;
					const end = start + entriesPerPage;
					const subset = params.entries.slice(start, end);
					const keyValue = moduleProps.storageKey + index.toString();
					return (
						<GeneratePage key={keyValue} entries={subset} page={index} {...pageParams} />
					);
				})}
			</div>
			<Pagination
				callback={function(pagingData) {
					pagingData = $.extend({
						parent: params.cssClass
					}, pagingData);
					handlePaging(pagingData);
				}}
				pageCount={pageCount}
				pagedContainer={params.cssClass}
				initialPage={initialPage}
				forcePage={params.entryPage}
				moduleTitle={moduleProps.title}
			/>
		</div>
	);
};
export default GeneratePages;



/**
 * Generate a page that displays the appropriate in-progress page for a module.
 *
 * @param params
 *     className: class name to attach to the div block
 *     displaySpinner: true/false (default: false) to display spinner vs text
 *     textId: string id into locale file; used if displaySpinner if false
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateInProgressPage = (params) => {
	params = Object.assign({
		className: 'in-progress',
		displaySpinner: false,
		textId: 'moduleDisplay.inProgress'
	}, params);
	const className = !isEmpty(params.className) ? params.className : 'in-progress';
	const textId = !isEmpty(params.textId) ? params.textId : 'moduleDisplay.inProgress';
	const displaySpinner = isTrue(params.displaySpinner);

	return (
		<div className={className}>
			{displaySpinner ?
				<CssSpinner/> :
				Translate.Text({id: textId})
			}
		</div>
	);
};
export {GenerateInProgressPage};



/* ***** NOT CURRENTLY USED ***** */

/*
// Note: typeof check is for node compilation...
let pageResizeTimer = null;
if (typeof window !== 'undefined') {
	$(window).resize(function() {
		window.clearTimeout(pageResizeTimer);
		pageResizeTimer = window.setTimeout(function() {
//			console.log('calling handle resize');
			handlePageResize();
		}, 100);
	});
}
*/
/**
 * On window resize, handle pagination element sizing.
 * Wait for 100 milliseconds to allow window resizing to settle down
 * so that fixDisplayPageSizes function doesn't get called too often.
 *
 * @param pgnPage pagination page to add/remove
 * @param status 'add' add the page to list; else remove from list
 */
/*
const paginationPages = [];
const handlePageResize = (pgnPage, status) => {
	if (typeof pgnPage !== 'undefined') {
		paginationPages.filter(item => item !== pgnPage);
		if (status === 'add') {
			paginationPages.push(pgnPage);
		}
	}
	paginationPages.forEach((page) => {
		fixDisplayPageSizes({pages: page});
	});
};
*/
/**
 * Given a paged-display container, make sure the container height does not
 * vary on every page display.  Make all the pages height the height of the
 * tallest page.
 *
 * Note that it can take some time for page images to populate so that the
 * final height can be determined.  For that, we loop through the pages
 * until the heights settle down to the final heights.
 *
 * @param params
 */
/*
const fixDisplayPageSizes = (params) => {
	const originalHeight = params.hasOwnProperty('originalHeight') ? params.originalHeight : -999;
	const pageHeightTimers = {};
	const $pages = $(params.pages).find('.display-page');
	const $paginationBlock = $(params.pages).find('.pagination-block');
	// if pages passed in, find height of tallest page and set all pages height the same
	// timeout is to allow browser render to complete
	if ($pages.length > 1) {
		$paginationBlock.hide();
		pageHeightTimers[params.pages] = window.setInterval(function() {
			let pageHeight = -1;
			const pagesHeight = $pages.outerHeight();
			$pages.each(function(index, page) {
				const $page = $(page);
				// show to get size in case hidden; reset height to get browser-based height
				$page.css({"display": "block", "height": ""});
				pageHeight = pageHeight <= $page.height() ? $page.height() : pageHeight;
				$page.css({"display": ""});
			});
			$pages.outerHeight(pageHeight);
			// reported/set values may not be decimal exact; within 2px is fine
			if (Math.abs(pageHeight - pagesHeight) < 2) {
				window.clearInterval(pageHeightTimers[params.pages]);
				if (Math.abs(pageHeight - originalHeight) > 2) {
					window.setTimeout(function() {
						params.originalHeight = pageHeight;
						fixDisplayPageSizes(params);
					}, 1000);
				} else {
					$paginationBlock.show();
				}
			}
		}, 100);
	}
};
export { fixDisplayPageSizes };
*/

/**
 * Custom pagination component; in progress...
 *
 * @param params
 * @returns {*}
 * @constructor
 */
/*
const Paginator = (params) => {
	params = $.extend({
		buttonHeight: 32,
		buttonWidth: 32,
		container: '.entries-pages',
		pageCount: 1,
		onPageChange: null,
		activeLinkClassName: 'active',
		previousLabel: '<',
		nextLabel: '>'
	}, params);

	const maxWidth = $(params.container).find('.display-pages').width();
	const maxButtons = Math.floor(maxWidth / params.buttonWidth);

	console.log(params.container);
	console.log('maxWidth: ' + maxWidth);
	console.log('maxButtons: '+ maxButtons);
	console.log('params.pageCount: '+ params.pageCount);

	return (
		<div className={'pagination-block'}>
		</div>
	);
};
*/

/*
<Paginator
	callback={function(pagingData) {
		pagingData = $.extend({
			parent: cssClass
		}, pagingData);
		handlePaging(pagingData);
	}}
	container={cssClass}
	pageCount={pageCount}
/>
*/
