import getDeviceType from "../getDeviceType";
import {getObjectFromJSON} from "../objectUtils";
import * as Tracking from "../tracking";
import {
	DEFAULT_REPLICA_PAGE_SPREAD,
	DEFAULT_REPLICA_ZOOM_LEVEL,
	ID_NOT_SET, MAX_REPLICA_ZOOM_LEVEL, MIN_REPLICA_ZOOM_LEVEL, REPLICA_ZOOM_STEP_LEVEL
} from "../../modules/_MODULE_GLOBALS/constants";
import {populateFetchDataAttributes} from "../populateFetchDataAttributes";
import {manageUpdateAndNavigation} from "../../modules/globals/globalNavigator";
import {isTrue, isEmpty} from "../generalUtils";
import {triggerOnReplicaListClick} from "../../triggers/triggerOnReplicaList";
import {addClass} from "../generateClassName";
import {stringsEqual} from "../stringUtils";



/**
 * Open an page in the Replica Viewer when an page thumbnail target is clicked.
 *
 * @param event click event
 * @param params parameters passed back from summary click on page thumbnail
 */
const openPage = (event, params) => {
	params = Object.assign({
		mops: {},
		moduleProps: {},
		replicaList: [],
		folio: '',
		pageNumber: 0,
		issueUrl: '',
	}, params);
	const mops = params.mops;
	const replicaList = params.replicaList;

	// get the current page and parse any dynamic fetch attributes; add issueUrl attribute to page
	const currentPage = replicaList.find(entry => entry.folio === params.folio);
	const moduleProps = populateFetchDataAttributes({
		props: params.moduleProps,
		data: !isEmpty(currentPage) ? currentPage : {},
	});

	const navigationKeys = getObjectFromJSON(moduleProps.navigationKeys, {});
	const fetchNavigationKey = moduleProps.fetchNavigationKey !== null && navigationKeys.hasOwnProperty(moduleProps.fetchNavigationKey) ? moduleProps.fetchNavigationKey : 'replica';

	const updateHistoryStateOverride = fetchNavigationKey !== 'replica';
	const updateHistoryState = isTrue(params.updateHistoryState) || updateHistoryStateOverride;

	const attributes = {
		folio: params.folio,
		pageNumber: params.pageNumber,
		issueUrl: params.issueUrl,
		replicaList: replicaList,
		fetchQueryParams: mops.queryParams,
		updateHistoryState: updateHistoryState,
		forceStoreUpdate: isTrue(params.forceStoreUpdate, {defaultValue: false}),
	};

	triggerOnReplicaListClick({
		storageKey: moduleProps.storageKey,
		listType: moduleProps.listType,
		scrollToLastPosition: moduleProps.scrollToLastPosition
	});

	manageUpdateAndNavigation({
		navigationKey: fetchNavigationKey,
		moduleProps: moduleProps,
		attributes: attributes
	});
};

/**
 * Handle replica page thumbnail link clicked event.
 * Track event
 *
 * @param event click event
 * @param params parameters passed through the click action setup
 *     can be directly or from setup in populateReplicaListClick
 */
const pageClicked = (event, params) => {
	event.stopPropagation();
	event.preventDefault();

	params = typeof params !== 'undefined' ? params : {};
	const trackingParams = !isEmpty(params.trackingParams) ? params.trackingParams: {};
	const trackingProperties = Object.assign({
		"type": "replica page link",
		"module": "replica list",
		"destination type": "replica",
	}, trackingParams);
	Tracking.libraryTrack("button clicked", trackingProperties);

	openPage(event, params);
};
export {pageClicked};


/**
 * Check navigationKeys for links to other modules.  Initialize then
 * populate and return replicaActions with properties from navigationKeys.
 */
const populateReplicaActions = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		replicaList: [],
		updateHistoryState: false,
		forceStoreUpdate: false
	}, params);
	const props = params.props;
	const device = getDeviceType();
	const destination = !isEmpty(props.destinations) && props.destinations.hasOwnProperty(device) ? props.destinations[device] : {};
	// initialize with defaults as not configured
	const replicaActions = {
		params: {},
		pageClicked: pageClicked,
		moduleProps: props,
		mops: params.mops,
		replicaList: params.replicaList,
		updateHistoryState: isTrue(params.updateHistoryState),
		forceStoreUpdate: isTrue(params.forceStoreUpdate, {defaultValue: false}),
	};
	const navigationKeys = getObjectFromJSON(props.navigationKeys, {});
	if (navigationKeys.hasOwnProperty('replica')) {
		const pageParams = navigationKeys.replica;
		replicaActions.params.replica = {
			destination: destination.hasOwnProperty('replica') ? destination.replica : 'internal',
			linkTitle: pageParams.hasOwnProperty('elementTitle') ? pageParams.elementTitle : ""
		};
	}
	return replicaActions;
};
export {populateReplicaActions};


/**
 * Given a replicaList array and either a folio or pageNumber value; find the
 * replica page object from the list and return it.  If not found, return empty
 * object.
 *
 * Note: find for a replica page based on folio is case-insensitive
 *
 * NOTE: an empty object indicates not found
 *
 * @param params
 *     replicaList: list of replica objects
 *     folio: folio string
 *     pageNumber: page number in replica
 * @returns {*}
 */
const findReplicaPage = (params) => {
	params = Object.assign({
		replicaList: [],
		folio: '',
		pageNumber: ID_NOT_SET
	}, params);
	const replicaList = !isEmpty(params.replicaList) && Array.isArray(params.replicaList) ? params.replicaList : [];
	let folioPage = {};  // default not found
	if (!isEmpty(params.folio)) {
		folioPage = replicaList.find(page => stringsEqual(page.folio, params.folio, {exact: false, trim: true}));
	} else if (params.pageNumber !== ID_NOT_SET) {
		// should already be int value, but make sure
		const pageNumber = isNaN(parseInt(params.pageNumber, 10)) ? -1 : parseInt(params.pageNumber, 10);
		folioPage = replicaList.find(page => page.pageNumber === pageNumber);
	}

	return isEmpty(folioPage) ? {} : folioPage;
};
export {findReplicaPage};

/**
 * Given a replicaList object and either a folio or pageNumber value; find the
 * index value of the replica page in the list and return it.
 * If not found, return -1.
 *
 * Note: find for a replica page based on folio is case-insensitive
 *
 * @param params entry and page attributes
 *     replicaList: list of entries
 *     folio: the page object folio (string)
 *     pageNumber: the page object pageNumber (number greater than zero)
 * @returns {number} value or 0 if not found, since that will always be the default page index
 */
const findReplicaPageIndex = (params) => {
	params = Object.assign({
		replicaList: [],
		folio: '',
		pageNumber: ID_NOT_SET
	}, params);
	const replicaList = !isEmpty(params.replicaList) && Array.isArray(params.replicaList) ? params.replicaList : [];
	const pageNumber = Number(params.pageNumber);

	if (!isEmpty(params.folio)) {
		return replicaList.findIndex(page => stringsEqual(page.folio, params.folio, {exact: false, trim: true}));
	} else if (!isNaN(pageNumber) && pageNumber > 0) {
		return replicaList.findIndex(entry => entry.pageNumber === pageNumber);
	} else {
		return -1;
	}
};
export {findReplicaPageIndex};


/**
 * Given a replicaList array and either a `folio` or `pageNumber` value, along
 * with the number of entries per page in a single page, return the pagination page
 * index that entry would be found on.  Return 0 if not found.
 *
 * Note: find for a replica page based on folio is case-insensitive
 * Note2: this is NOT the index in the replicaList, but the index for the
 * replica list pagination pages.
 *
 * @param params entry and page attributes
 *     replicaList: list of entries
 *     folio: the page object folio (string)
 *     pageNumber: the page object pageNumber (number greater than zero)
 *     entriesPerPage: the number of entries per single page
 * @returns {number} value or 0 if not found, since that will always be the default page index
 */
const generateReplicaListPaginationIndex = (params) => {
	params = Object.assign({
		replicaList: [],
		folio: '',
		pageNumber: ID_NOT_SET,
		entriesPerPage: 1
	}, params);
	const replicaList = !isEmpty(params.replicaList) && Array.isArray(params.replicaList) ? params.replicaList : [];
	let entryIndex = -1;
	const pageNumber = Number(params.pageNumber);
	if (!isEmpty(params.folio)) {
		entryIndex = replicaList.findIndex(page => stringsEqual(page.folio, params.folio, {exact: false, trim: true}));
	} else if (!isNaN(pageNumber) && pageNumber > 0) {
		entryIndex = replicaList.findIndex(entry => entry.pageNumber === pageNumber);
	}

	if (entryIndex === -1) {
		return 0;
	} else {
		const pageIndex = Math.ceil(entryIndex / params.entriesPerPage) - 1;
		return pageIndex < 0 ? 0 : pageIndex;
	}
};
export {generateReplicaListPaginationIndex};


/**
 * Find page or pages in the replicaList based on folio.
 * If the pageSpread value is 1, then just return that page
 * If the pageSpread value is not 1, then loop through and find the replica pages
 * matching the spread value in the requested replica page.
 *
 * Return
 *     pages: array of replica pages matching spread for requested page
 *     pageDetails: relating to requested page
 *         hasPage: boolean true/false if page found
 *         moduleClassName: className + 'empty' if no page image for requested page
 *
 * @param params
 *     folio: folio for current page (preferred)
 *     pageNumber: page number for current page (secondary)
 *     className: class name for generated page module
 *     pageAttributes: properties of the requested page
 * @returns {{pageDetails: {hasPage: boolean, moduleClassName: string}, pages: *[]}}
 */
const getReplicaPages = (params) => {
	params = Object.assign({
		folio: '',
		pageNumber: ID_NOT_SET,
		className: '',
		pageAttributes: {}
	}, params);
	const {className, pageAttributes} = params;

	const folio = isEmpty(params.folio) ? '' : params.folio;
	const pageNumber = isEmpty(params.pageNumber) ? ID_NOT_SET : params.pageNumber;

	const replicaList = isEmpty(pageAttributes.replicaList) ? [] : pageAttributes.replicaList;
	const pageSpread = isEmpty(pageAttributes.pageSpread) ? 1 : pageAttributes.pageSpread;

	const pages = [];
	let pageDetails = {
		hasPage: false,
	};

	if ((!isEmpty(folio) || pageNumber !== ID_NOT_SET) && replicaList.length > 0) {
		const currentPage = findReplicaPage({replicaList: replicaList, folio: folio, pageNumber: pageNumber});
		if (!isEmpty(currentPage)) {
			const page = currentPage;
			if (pageSpread === 1) {
				pages.push(page);
			} else {
				page.spread.forEach((pageNumber) => {
					const replicaPage = findReplicaPage({replicaList: replicaList, pageNumber: pageNumber});
					if (!isEmpty(replicaPage)) {
						pages.push(replicaPage);
					}
				});
			}
			pageDetails = {
				hasPage: true,
				currentPage: page,
				moduleClassName: page.hasOwnProperty('image') ? className : addClass(className, 'empty')
			};
		}
	}
	return {pageDetails: pageDetails, pages: pages};
};
export {getReplicaPages};


/**
 * Read the Configuration property for replicaLayout which is configured based on device size.
 * Populate a return object with pageSpread/zoomLevel dependent on device size.
 *
 * @param replicaLayout value of Configuration property
 * @returns {{}}
 */
const deviceReplicaLayout = (replicaLayout) => {
	const defaultValue = {
		pageSpread: DEFAULT_REPLICA_PAGE_SPREAD,
		zoomLevel: DEFAULT_REPLICA_ZOOM_LEVEL,
		zoomMin: MIN_REPLICA_ZOOM_LEVEL,
		zoomMax: MAX_REPLICA_ZOOM_LEVEL,
		zoomStep: REPLICA_ZOOM_STEP_LEVEL
	};
	const device = getDeviceType();
	const globalsDevice = replicaLayout.hasOwnProperty(device) ? replicaLayout[device] : {};
	const globalsDefault = replicaLayout.hasOwnProperty('default') ? replicaLayout.default : {};

	const deviceReplicaLayout = {
		pageSpread: globalsDevice.hasOwnProperty('pageSpread') ? parseInt(globalsDevice.pageSpread, 10) : (globalsDefault.hasOwnProperty('pageSpread') ? parseInt(globalsDefault.pageSpread, 10) : defaultValue.pageSpread),
		zoomLevel: globalsDevice.hasOwnProperty('zoomLevel') ? parseFloat(globalsDevice.zoomLevel) : (globalsDefault.hasOwnProperty('zoomLevel') ? parseFloat(globalsDefault.zoomLevel) : defaultValue.zoomLevel),
		zoomMin: globalsDevice.hasOwnProperty('zoomMin') ? parseFloat(globalsDevice.zoomMin) : (globalsDefault.hasOwnProperty('zoomMin') ? parseFloat(globalsDefault.zoomMin) : defaultValue.zoomMin),
		zoomMax: globalsDevice.hasOwnProperty('zoomMax') ? parseFloat(globalsDevice.zoomMax) : (globalsDefault.hasOwnProperty('zoomMax') ? parseFloat(globalsDefault.zoomMax) : defaultValue.zoomMax),
		zoomStep: globalsDevice.hasOwnProperty('zoomStep') ? parseFloat(globalsDevice.zoomStep) : (globalsDefault.hasOwnProperty('zoomStep') ? parseFloat(globalsDefault.zoomStep) : defaultValue.zoomStep),
	};
	deviceReplicaLayout.pageSpread = !isNaN(deviceReplicaLayout.pageSpread) ? deviceReplicaLayout.pageSpread : defaultValue.pageSpread;
	deviceReplicaLayout.zoomLevel = !isNaN(deviceReplicaLayout.zoomLevel) ? deviceReplicaLayout.zoomLevel : defaultValue.zoomLevel;
	deviceReplicaLayout.zoomMin = !isNaN(deviceReplicaLayout.zoomMin) ? deviceReplicaLayout.zoomMin : defaultValue.zoomMin;
	deviceReplicaLayout.zoomMax = !isNaN(deviceReplicaLayout.zoomMax) ? deviceReplicaLayout.zoomMax : defaultValue.zoomMax;
	deviceReplicaLayout.zoomStep = !isNaN(deviceReplicaLayout.zoomStep) ? deviceReplicaLayout.zoomStep : defaultValue.zoomStep;

	return deviceReplicaLayout;
};
export {deviceReplicaLayout};
