import React, { useState, useEffect } from 'react';
import {connect} from "react-redux";
import {
	AUTHENTICATION,
	AUTHENTICATION_KEY,
	ECOMMERCE_INAPP_LOGIN,
	JWT_LOGIN,
	REFERRER_LOGIN,
	SUBSCRIBER_LOGIN
} from "../../_MODULE_GLOBALS/constants";
import {getStoreValue} from "../../../utils/storeValue";
import {displayOnDevice} from "../../../utils/moduleSetup";
import * as Tracking from "../../../utils/tracking";
import {getActiveAttributeState} from "../../../utils/moduleSetup";
import {isEmpty, isTrue} from "../../../utils/generalUtils";
import {AuthenticatePrompt} from "./widgets/prompt";
import {AuthenticateLogin} from "./widgets/login";
import {useFetchAttributesChange, useFetchComplete} from "../../../hooks/useFetchChange";
import {AuthenticateApp} from "./widgets/app";
import {AuthenticateEcommerce} from "./widgets/ecommerce";
import {useAttributesChanged} from "../../../hooks/useAttributesChanged";
import {checkIfMyPane} from "../../globals/globalLibraryData";
import {setCookie} from "../../../utils/standardizedCookies";
import {AccessPending} from "./widgets/accessPending";



/**
 * Returns a div with message (usually error) or empty, when no message to display.
 * Called from an Authentication widget, but can be seperated/exported
 * and called from anywhere if we have more areas where we'd need
 * to display messages.
 *
 * @param params
 *     message: the message to display
 *     className: to apply to the message div
 *         usually "error" or "information"
 *     clearErrorMsg: true: clear current message; false: use existing
 * @returns {JSX.Element|string}
 * @constructor
 */
const AuthenticationMsg = (params) => {
	params = Object.assign({
		message: '',
		className: 'error',
		clearErrorMsg: false,
	}, params);

	const authenticationMsg = isTrue(params.clearErrorMsg, {defaultValue: false}) ? '' : (!isEmpty(params.message) ? params.message : '');
	const className = !isEmpty(params.className) ? params.className : 'error';

	if (!isEmpty(authenticationMsg)) {
		return (
			<p className={className}>{authenticationMsg}</p>
		);
	} else {
		return '';
	}
};
export {AuthenticationMsg};

/**
 * Call tracking on buton clicck passing widget-specific tracking properties.
 *
 * @param trackingProperties properties to pass to tracking
 */
const trackButtonClick = (trackingProperties) => {
	Tracking.libraryTrack("button clicked", trackingProperties);
};
export {trackButtonClick};


/**
 *
 * @param props - Props needed for the Authentication module
 * @returns {JSX.Element|string}
 * @constructor
 */
const AuthenticationModule = (props) => {
	const [authenticationInProgress, setAuthenticationInProgress] = useState(false);
	const [userAccess, setUserAccess] = useState(props.userAccess);
	const [authenticationDialogShown, setAuthenticationDialogShown] = useState(false);

	const isEcommerce = props.authenticationType === ECOMMERCE_INAPP_LOGIN;
	const isJWT = props.authenticationType === JWT_LOGIN;
	const isSubmanaged = (!props.app && props.authenticationType === SUBSCRIBER_LOGIN);
	/**
	 * For ecommerce apps, once a user goes through the subscription or single-issue purchase 
	 * the userAccess object in this module doesn't get updated immediatelly which would cause the popup to remain in place.
	 * To work around this, we are comparing the purchaseProductId to productId of the products displayed in this popup.
	 * If one of them matches and we have a successful purchase we hide the popup.
	 */
	const isCurrentIssuePurchaseSuccessful = props.isPurchaseSuccessful && 
		(props.singleIssueProduct.productId === props.purchaseProductId ||
		props.subscriptionProduct.productId === props.purchaseProductId);

	const isCurrentArticleOpen = props.isCurrentArticleOpen;
	const isOpen = (props.authenticationType !== REFERRER_LOGIN && props.authenticationType !== SUBSCRIBER_LOGIN && !isEcommerce && !isJWT);
	const isLoggedInAsSubscriber = isTrue(props.isLoggedIn) && !isEmpty(props.subscriberId);
	const userHasAccess = isTrue(userAccess.access[props.issueUrl], {defaultValue: true});
	const popupType = isEcommerce && props.authView !== 'login' ? 'ecommerce' : !isEcommerce && props.app === true ? 'app' : props.authView;
	const dontShowAuthentication = !displayOnDevice(props) || userHasAccess || isOpen || isCurrentIssuePurchaseSuccessful;

	const baseTrackingProperties = {
		"module": "authentication",
		"issue name": "_" + props.issueName,
		"issue url": props.issueUrl,
		"subscriber status": props.isLoggedIn ? "subscriber" : (props.failedLoginAttempt ? "login failed" : "lookinside"),
	};


	/**
	 * Called to manage whether or not the module fetches data.  If the hook calls the
	 * callback, then call getReplicaList which will determine whether or not to
	 * fetch a new list or get an existing list from stored data.
	 *
	 */
	useFetchAttributesChange((params) => {
		const fetchData = getStoreValue({attributeKey: 'genericFetchData'});
		setAuthenticationInProgress(true);
		fetchData({type: AUTHENTICATION, storageKey: props.storageKey});
	}, {props: props, additionalTriggers: {isLoggedIn: props.isLoggedIn}});

	/**
	 * Call hook to check to see if the authenticationInProgress has been set/changed in the reducer.
	 * authenticationInProgress is set true when fetch is started (DATA_REQUESTED)
	 * and set to false when fetch is done (DATA_LOADED)
	 */
	useFetchComplete((isInProgress) => {
		setAuthenticationInProgress(isTrue(isInProgress, {defaultValue: false}));
		const userAccess = !isEmpty(props.userAccess) ? props.userAccess : props.defaultUserAccess;
		setUserAccess(userAccess);
		if (!isInProgress && userAccess.access[props.issueUrl]) {
			const trackingProperties = {
				"isSubmanaged": isSubmanaged ,
				"userHasAccess": userHasAccess,
				"isLoggedInAsSubscriber": isTrue(props.isLoggedIn) && !isEmpty(props.subscriberId),
				"popupType": popupType,
				"type": "login",
				"subscriber status": props.isLoggedIn ? "subscriber" : "lookinside",
				"action": "logged in"
			};
			Tracking.libraryTrack("login popup action", trackingProperties);
		}
	}, {requestInProgress: props.authenticationInProgress, fetchInProgress: authenticationInProgress});

	/**
	 * Check if successful login response from server call.
	 * If true, set the subscriber_id cookie
	 * if false,
	 */
	useAttributesChanged((params) => {
		// setup to trigger/update login failed/succeeded
		if (props.isMyPane) {
			if (!isEmpty(props.subscriberId)) {  // successful login
//				console.log('Successful email login attempt');
				setCookie(null, 'subscriber_id', props.subscriberId, {path: "/" + props.collectionUrl, secure: props.secureCookie});
			} else if (!isEmpty(props.emailAddress) && isTrue(props.failedLoginAttempt)) {  // login attempted by invalid email
//				console.log("Failed email login attempt");
				const event = new CustomEvent("gtxlogin", {detail: {category: "login popup action", "subscriber status": "login failed"}});
				document.dispatchEvent(event);
			} else {
//-				console.log("Called check login - NO CHANGE");
			}
		}
	}, [props.emailAddress, props.subscriberId, props.failedLoginAttempt]);


	const additionalProps = {
		"isSubmanaged": isSubmanaged ,
		"userHasAccess": userHasAccess,
		"isLoggedInAsSubscriber": isTrue(props.isLoggedIn) && !isEmpty(props.subscriberId),
		"popupType": popupType,
	};


	if (dontShowAuthentication) {
		// if user has access to this issue (open or logged in), don't show auth popup
		return null;
	} else if (authenticationInProgress && props.isLoggedIn) {
		// if logged in, there will be a short time before useAccess is set
		return <AccessPending {...props} {...additionalProps} />;
	} else {
		// otherwise, continue normal display of authentication dialog
		if (!isTrue(authenticationDialogShown)) {
			// state to trigger tracking of authentication dialog shown only once
			setAuthenticationDialogShown(true);
		}
		if (popupType === 'app') {
			return <AuthenticateApp {...props} {...additionalProps} />;
		} else if (popupType === 'ecommerce') {
			return <AuthenticateEcommerce {...props} {...additionalProps} />;
		} else if (popupType === 'prompt' && !isLoggedInAsSubscriber) {
			return <AuthenticatePrompt {...props} {...additionalProps} />;
		} else {  // "login"
			return <AuthenticateLogin {...props} {...additionalProps} />;
		}
	}
};



/**
 * Map state (store) data.
 *
 * storageKey provides a way to map a specific store object to a particular
 * instance of the module.  Add storageKey when configuring the module instance
 * if it needs to listen for a store change.
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : AUTHENTICATION_KEY;
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};
	const globalState = !isEmpty(state.globals) ? state.globals : {};

	const issueUrl = !isEmpty(globalState.currentIssueUrl) ? globalState.currentIssueUrl : '';

	const documentList = !isEmpty(state.documentList) ? state.documentList : [];
	let document = documentList.find(document => document.issueUrl === issueUrl);
	document = !isEmpty(document) ? document : {};
	const documentUrl = !isEmpty(document.documentUrl) ? document.documentUrl : '';
	const documentId = !isEmpty(document.documentId) ? document.documentId : '';

	const myPane = checkIfMyPane({moduleName: 'authentication', instanceId: props.instanceId, storageKey: storageKey});

	const products = !isEmpty(globalState.products) ? globalState.products : [];
	const subscriptionProduct = products.find(product => product.productType === "subscription");
	const singleIssueProduct = products.find(product => product.productType === "single_issue" && documentUrl.includes(product.documentUrl));


	const authenticationProps = {
		// Only used to generate other properties
//		"documentList": documentList,
//		"documentUrl": documentUrl,
//		"documentId": documentId,
//		"platform": !isEmpty(state.platform) ? state.platform : "web",
//		"products": products,
//		"isHapara": !!(state.collectionSubscribeUrl && state.collectionSubscribeUrl.toLowerCase().includes("hapara")),

		// Only used by articleViewer and replicaViewer
//		"purchaseProductId": !isEmpty(globalState.purchaseProductId) ? globalState.purchaseProductId : "",


		// GENERIC MODULE PROPS
		"active": getActiveAttributeState(props),
		"module": "authentication",
		"isMyPane": myPane.isMyPane,
		"myPane": myPane.myPane,
		"authenticationInProgress": isTrue(storeState.authenticationInProgress, {defaultValue: false}),
		"subscriberInProgress": isTrue(globalState.subscriberInProgress, {defaultValue: false}),

		// AUTHENTICATION MODULE ONLY
		"defaultUserAccess": !isEmpty(state.userAccess) ? state.userAccess : {
			isLoggedIn: false,
			access: {}
		},
		"userAccess": !isEmpty(storeState.userAccess) ?
			storeState.userAccess :
			(!isEmpty(state.userAccess) ?
				state.userAccess :
				{
					isLoggedIn: false,
					access: {}
				}),
		"subscriberId": !isEmpty(globalState.subscriberId) ? globalState.subscriberId : '',
		"failedLoginAttempt": isTrue(globalState.failedLoginAttempt, {defaultValue: false}),
		"secureCookie": !isTrue(globalState.secureCookie, {defaultValue: false}),
		"collectionUrl":  !isEmpty(state.collectionUrl) ? state.collectionUrl : '',
		"machineLimitReached": isTrue(globalState.machineLimitReached, {defaultValue: false}),

		// ALL WIDGETS
		"authView": !isEmpty(storeState.authView) ? storeState.authView : 'prompt',
		"issueUrl": issueUrl,
		"issueName": !isEmpty(documentList[issueUrl]) ? "_" + documentList[issueUrl] : "_" + issueUrl,
		"authenticationType": !isEmpty(state.authenticationType) ? state.authenticationType : '',
		"collectionSubscribeUrl": !isEmpty(state.collectionSubscribeUrl) ? state.collectionSubscribeUrl : '',
		"subscribeUrl": !isEmpty(state.subscribeUrl) ? state.subscribeUrl : '',

		// LOGIN/PROMPT WIDGET
		"isCurrentArticleOpen": isTrue(globalState.currentArticleIsOpen, {defaultValue: false}),
		"isPurchaseSuccessful": isTrue(globalState.isPurchaseSuccessful, {defaultValue: false}),
		"purchaseProductId": !isEmpty(globalState.purchaseProductId) ? globalState.purchaseProductId : "",

		// ECOMMERCE WIDGET
		"isLatestIssue" : !isEmpty(state.latestIssue) ? state.latestIssue.issueUrl === issueUrl : false,
		"subscriptionProduct": !isEmpty(subscriptionProduct) ? subscriptionProduct : {},
		"singleIssueProduct": !isEmpty(singleIssueProduct) ? singleIssueProduct : {},

		// AUTHENTICATION/LOGIN/ECOMMERCE
		"isLoggedIn": isTrue(globalState.isLoggedIn, {defaultValue: false}),
		"accessError": !isEmpty(globalState.accessError) ? globalState.accessError : '',
		"clearErrorMsg": isTrue(storeState.clearErrorMsg, {defaultValue: false}),
		"emailAddress": !isEmpty(storeState.emailAddress) ? storeState.emailAddress : '',
		"malformedEmail": isTrue(storeState.malformedEmail, {defaultValue: false}),

		// APP/ECOMMERCE
		"resourceId": !isEmpty(documentList.resourceId) ? documentList.resourceId : '',
		"successUrl": !isEmpty(state.successUrl) ? state.successUrl : '',
		"expirationDays": !isEmpty(state.expirationDays) ? state.expirationDays : '',

		// APP/LOGIN
		"app": isTrue(state.app, {defaultValue: false}),

	};

	return authenticationProps;
};

/**
 * simpleImage module (currently) makes to calls to fetch of update data.
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AuthenticationModule);

