import React from "react";
import {Translate} from "../../../../locales/locales";
import {navigateToTarget} from "../../../globals/globalNavigator";
import {getObjectFromJSON} from "../../../../utils/objectUtils";
import {isEmpty} from "../../../../utils/generalUtils";
import {addClass} from "../../../../utils/generateClassName";
import {ID_NOT_SET} from "../../../_MODULE_GLOBALS/constants";


/**
 * Display and handle the click on a "navigation" button type.  This button is designed
 * to go to go to a module another pane.
 *
 * @param params props passed from the action button
 * @returns {*} the generated (clickable) tag to display
 * @constructor
 */
const TocButton = (params) => {
	params = Object.assign({
		className: '',
		title: '',
		label: '',
		tag: 'button',
		navigationKey: 'navigation',
		navigationKeys: {},
		attributes: {},
		storeState: {},
		replacementValues: {}
	}, params);

	const className = addClass('navigation-button', params.className);
	const label = !isEmpty(params.label) ? Translate.Text({id: params.label}) : Translate.Text({id: 'actionButton.tocButton.label'});
	const title = !isEmpty(params.title) ? Translate.Text({id: params.title}) : Translate.Text({id: 'actionButton.tocButton.title'});
	const GeneratedTag = !isEmpty(params.tag) ? `${params.tag}` : `button`;

	const navKey = typeof params.navigationKey === 'string' ? params.navigationKey : 'issue';
	const navigationKeys = getObjectFromJSON(params.navigationKeys, {});
	const navigationKey = navigationKeys.hasOwnProperty(navKey) ? navigationKeys[navKey] : {};
	const navKeyAttributes = navigationKey.attributes ? navigationKey.attributes : {};
	const targetKey = navigationKey.navigationTarget;

	const attributes = Object.assign(navKeyAttributes, params.attributes, {
		articleId: !isEmpty(params.storeState.articleId) ? params.storeState.articleId : ID_NOT_SET,
		articleList: !isEmpty(params.storeState.articleList) ? params.storeState.articleList : [],
		updateHistoryState: true,
		replaceHistoryState: true,
		forceFetch: true
	});


	const targetParams = {
		targetKey: targetKey,
		attributes: attributes,
		replacementValues: params.replacementValues
	};

	return <GeneratedTag className={className} title={title} onClick={(evt) => navigateToTarget(targetParams)}><span className={'button-text'}>{label}</span></GeneratedTag>;
};
export {TocButton};
