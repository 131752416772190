import $ from 'jquery';
import {Translate} from "../../../../locales/locales";
import React from "react";
import deepmerge from "deepmerge";
import {getElementAttributes} from "../../../../utils/htmlUtils";
import GenerateThumbnails from "../../../../widgets/generateThumbnails";
import GenerateCategoryButtons from "../../../../widgets/generateCategoryButtons";
import {getStoreValue} from "../../../../utils/storeValue";
import {isEmpty} from "../../../../utils/generalUtils";


/**
 * Find and return an element from a given html.  The element is either the
 * class name, id, or tag to find within the html.
 * Optionally, remove the element from the html before returning it to the caller.
 *
 * Convert the element to html text from a jQuery object before returning.
 *
 * @param params
 *     article: article object
 *     content: html content (note: we pass in content to modify, as we want to preserver the article attribute)
 *     attributes:
 *         element: tag or class name or id to find within html
 *             element starting with letter : taken as a tag
 *             element starting with '.' : taken as class name
 *             element starting with '#' : taken as id
 *         duplicate: (optional) false: make a copy instead of removing element
 *         position: (optional) value (ie. 0)
 *         count: (optional) number of copies of the element to return; default 1
 *         class: (optional) class name to add to element
 *         id: (optional) id to use for element
 *         prefix: (optional) text to add to front of return value
 *         postfix: (optional) text to add to end of return value
 *
 * This returns
 *     the html string (value) from a jQuery object, that may contain 0 to many objects, depending
 *     on how many matches are made, plus the modified html source, which is usually modified to
 *     remove the element unless "duplicate" is true
 *
 * @returns {{value: string, content: (*|jQuery)}}
 */
const findElement = (params) => {
	params = deepmerge({
		article: null,
		content: '',
		attributes: {
			element: '',
			duplicate: false,
			position: -1,
			count: 1,
			class: null,
			id: null,
			prefix: null,
			postfix: null
		}
	}, params);

	const $wrapper = $('<div />');
	$wrapper.append(params.content);

	let $found = $wrapper.find(params.attributes.element);
	if (params.attributes.position >= 0 && ($found.length - 1) >= params.attributes.position) {
		$found = $($found[params.attributes.position]);
	}

	let value = '';  // return value
	if ($found.length > 0) {
		// only work with count # elements
		const count = params.attributes.count === 'all' ? $found.length : params.attributes.count;
		for (let i = 0; i < count; i++) {
			const element = $found[i];
			const $element = $(element);

			if (params.attributes.class) {
				$element.addClass(params.attributes.class);
			}
			if (params.attributes.id) {
				$element.attr({'id': params.attributes.id});
			}
			// add prefix and postfix
			if (params.attributes.prefix) {
				const prefix = params.attributes.prefix.hasOwnProperty('i18n')
					? Translate.Text({id: params.attributes.prefix.i18n})
					: (params.attributes.prefix.hasOwnProperty('text') ? params.attributes.prefix.text : params.attributes.prefix);
				$element.prepend(prefix + ' ');
			}
			if (params.attributes.postfix) {
				const postfix = params.attributes.postfix.hasOwnProperty('i18n')
					? Translate.Text({id: params.attributes.postfix.i18n})
					: (params.attributes.postfix.hasOwnProperty('text') ? params.attributes.postfix.text : params.attributes.postfix);
				$element.append(' ' + postfix);
			}

			// add html to return value
			value += element.outerHTML;

			// remove elements from html if not duplicate
			if (!params.attributes.duplicate) {
				$element.remove();
			}
		}
	}

	return {value: value, content: $wrapper.html()};
};
export {findElement};


/**
 * Given a set of elements and attributes identifying those elements and additional attributes,
 * extract them from the article content ("content") and return an object with the element name
 * as the key and the React html of the content.
 *
 * Additionaly, return the article content ("articleMarkup") as that is usually modified in the
 * process of extracting the elements.
 *
 * @param params
 *     article: the article object
 *     elementsAttributes: list of elements to find (extract) and attributes to apply to them
 *
 * @returns {{articleMarkup, elements: {}}}
 */
const getExtractedElements = (params) => {
	params = Object.assign({
		article: {},
		elementsAttributes: []
	}, params);

	let articleMarkup = params.article.content;
	if (articleMarkup === "") {
		console.log("no articleMarkup found", params.article.articleId, params.article.title);
	}
	const extractedElements = {};

	params.elementsAttributes.forEach((elementAttributes) => {
		const foundElement = findElement({article: params.article, content: articleMarkup, attributes: elementAttributes});
		extractedElements[elementAttributes.name] = foundElement.value;
		articleMarkup = foundElement.content;
	});

	return {elements: extractedElements, articleMarkup: articleMarkup};
};
export {getExtractedElements};


/**
 * Given article markup to search within, and parameters related to the search,
 * return a targeted element in the markup.
 *
 * Note 1: This will also look for the special element with class="insert-article-widget" to
 *     manually set the target element for the (named) widget in the article html.
 *
 * Note 2: This will check to see if there is any elements before/after the htmlBody element
 *     and move them inside the htmlBody.
 *
 * @param $articleMarkup jQuery from article html
 * @param targetParams params to use for targeting
 *     element: the html element to find
 *     eq: which numbered element
 *     widgetName: the widget name for lookup if needed
 * @returns {{$targetElement: {}, $childElements: *, eq: number|acorn.TokenType|*, beforeElement: boolean}}
 */
const getTargetData = ($articleMarkup, targetParams) => {
	const targetData = {
		$articleMarkup: $articleMarkup,
		$childElements: $articleMarkup.children(),
		$targetElement: {},
		eq: targetParams.eq,
		beforeElement: false,
	};
	if (targetParams.eq < 0) {
		targetData.beforeElement = true;
		targetData.eq = 0;
	}

	// if $articleMarkup only has the single div, use it for markup
	// else try to find elements before/after htmlBody and move them inside htmlBody
	if ($articleMarkup.length === 1) {
		targetData.$articleMarkup = $articleMarkup;
	} else {
		targetData.$articleMarkup = $articleMarkup.filter('.htmlBody');
		let $contentBefore = targetData.$articleMarkup.prevAll();
		targetData.$articleMarkup.remove($contentBefore);
		let $contentAfter = targetData.$articleMarkup.nextAll();
		targetData.$articleMarkup.remove($contentAfter);
		if ($contentBefore.length > 0) {
			targetData.$articleMarkup = targetData.$articleMarkup.prepend($('<div class="content-added-before">').append($contentBefore.get().reverse()));
		}
		if ($contentAfter.length > 0) {
			targetData.$articleMarkup = targetData.$articleMarkup.append($('<div class="content-added-after">').append($contentAfter));
		}
	}
	// re-get children, as we may have added before/after content
	targetData.$childElements = targetData.$articleMarkup.children();

	// default target element
	targetData.$targetElement = targetData.$childElements.filter(targetParams.element).eq(targetData.eq);

	// check for manual insert
	const $insertElement = $articleMarkup.find('.insert-article-widget');
	if ($insertElement.length > 0) {
		const dataWidgetName = $insertElement.attr('data-widget-name');
		if (dataWidgetName === targetParams.widgetName) {
			targetData.$targetElement = $insertElement;
			targetData.eq = 0;  // only one element
			$insertElement.hide();  // don't want it to interfere with layout, but need it for placement
		}
	}

	return targetData;
};
export {getTargetData};

/**
 * Given an article content value (markup), get all the children of the top-level element of the
 * markup, search through the children for a match to the passed in element and element #, then
 * split the markup up to and including the element and return the two parts.
 *
 * Note 1: jQuery find is used to find the element, with element number specified with the "eq" property.
 *     By default, the split will occur after the specified element.
 *     It is also possible to specify splitting before the element by specifying eq: -1
 *
 * Also return both the top-level id and class, if they exist, so that the top-level element
 * can be reproduced in the caller to wrap the returned content.
 *
 * Note 2: Most article content is wrapped in a single <div class="htmlBody article_div"> element.
 *     We are explicitly checking for class="htmlBody".  where this is not true, we will just return
 *     the the original article markup as the first part, with an empty string as the second part.
 *
 * Note 3: Older or mal-formed articles can have two wrappers, ie.
 *     <div class="htmlBody article_div">
 *         <div class="htmlBody article_div">
 *             ...
 *         </div>
 *     </div>
 *     This case is handled before we get here by removing one of the duplicates with the function
 *     removeDuplicateArticleWrapper in the file cleanupArticleContent.js.
 *
 * Note 4: This can also handle manual marking and placement of a widget at a specified location within
 *     the article markup, by placing a special marker div at the desited spot, using the article editor.
 *     The format of this div to place the generateThumbnails widget:
 *         <div class="insert-article-widget" data-widget-name="generateThumbnails"></div>
 *
 * @param params
 *     articleMarkup: article content value
 *     element: tag/class to search for in top-level child elements
 *     eq: 0-based number of the element (ie: 0 === first, 1 === second, ...)
 *         -1: special case to place before content
 * @returns {{markupClass: (*|string), articleMarkupPart1: (*|Window.jQuery), articleMarkupPart2: (*|Window.jQuery), markupId: (*|string)}}
 */
const splitArticleAtElement = (params) => {
	params = Object.assign({
		articleMarkup: '',
		element: 'p',
		eq: 0,
		widgetName: ''
	}, params);

	const articleSplitParts = {
		articleMarkupPart1: params.articleMarkup,
		articleMarkupPart2: '',
		topLevelAttributes: {}
	};

	const $articleMarkup = $(params.articleMarkup);
	if ($articleMarkup.hasClass('htmlBody')) {
		const targetData = getTargetData($articleMarkup, params);
		articleSplitParts.topLevelAttributes = getElementAttributes(targetData.$articleMarkup[0]);

		let $part1 = '';
		let $part2 = '';
		if (targetData.$targetElement.length > 0) {
			if (targetData.$targetElement.is(':first-child')) {
				$part1 = targetData.beforeElement ? '' : targetData.$targetElement;
			} else {
				$part1 = targetData.beforeElement ? targetData.$childElements.first().nextUntil(targetData.$targetElement).addBack() : targetData.$childElements.first().nextUntil(targetData.$targetElement).addBack().add(targetData.$targetElement);
			}
			$part2 = targetData.beforeElement ? targetData.$targetElement.add(targetData.$targetElement.nextAll()) : targetData.$targetElement.nextAll();
		} else {
			$part1 = targetData.$childElements;
			$part2 = '';
		}
		// we want to return the elements as html; jQuery weirdness...
		articleSplitParts.articleMarkupPart1 = $('<div />').append($part1).html();
		articleSplitParts.articleMarkupPart2 = $('<div />').append($part2).html();
	}

	return articleSplitParts;
};
export {splitArticleAtElement};


const articleWidgets = {
	"generateThumbnails": GenerateThumbnails,
	"generateCategoryButtons": GenerateCategoryButtons
};

/**
 * Parse a templates configuration looking for the configuration for a specific
 * article template and return the list of widget definitions within that template configuration.
 * Check if there are templateOverrides defined for the base template definition and use that
 * if it exists.  Otherwise, use the base template definition or empty if there are no matches.
 *
 * @param params
 *     template name of template (ex. default)
 *     templateOverrides: template overrides object from configuration
 * @returns {*} array of template widget configurations
 */
const getWidgetDefinitions = (params) => {
	params = Object.assign({
		template: '',
		templateOverrides: {}
	}, params);
	const templates = getStoreValue({attributeKey: 'templates'});
	const widgetTemplate = !isEmpty(params.templateOverrides[params.template]) ? params.templateOverrides[params.template] : params.template;
	const templateDefinitions = !isEmpty(templates[widgetTemplate]) ? templates[widgetTemplate] : (!isEmpty(templates[params.template]) ? templates[params.template] : {});
	return templateDefinitions.hasOwnProperty('widgets') ? templateDefinitions.widgets : [];
};
export {getWidgetDefinitions};


/**
 * Find and return the split content using splitArticleAtElement
 *
 * @param articleMarkup article html
 * @param contentWidget widget to check for insertion
 * @returns {{widget: null, markup: {}, hasSplit: boolean}}
 */
const getSplitContent = (articleMarkup, contentWidget) => {
	const splitContent = {
		hasSplit: false,
		markup: {},
		widget: null
	};
	const element = contentWidget.location.positionAfter;
	const eq = contentWidget.location.eq;
	splitContent.markup = splitArticleAtElement({articleMarkup: articleMarkup, element: element, eq: eq, widgetName: contentWidget.name});
	splitContent.hasSplit = true;
	splitContent.widget = articleWidgets[contentWidget.name];

	return splitContent;
};
export {getSplitContent};


/**
 * Parse the article content and find external links that are buried in the article content
 * body that need to be applied elsewhere than where they are located.
 *
 * Currently only implemented/required for fullPageAd links
 *
 * @param params
 *     articleMarkup: the article markup from article.content
 *     linkElementTag: element tag where link will be found
 *     linkAttribute: link type attribute to extract and return
 *     attributeType: generally one of "data", "class", "id"
 *
 * @returns {{extractedLinks: []}}
 */
const getArticleExternalLinks = (params) => {
	params = Object.assign({
		articleMarkup: '',
		linkElementTag: 'div',
		linkAttribute: '',
		attributeType: 'data'
	}, params);
	const linkAttribute = typeof params.linkAttribute === 'string' ? params.linkAttribute : '';

	const extractedLinks = [];

	const $wrapper = $('<div />');
	$wrapper.append(params.articleMarkup);
	if (params.attributeType === 'data') {
		const $dataLinkElements = $wrapper.find(params.linkElementTag+'['+linkAttribute+']');
		$dataLinkElements.each( function(index, linkElement) {
			const externalLink = linkElement.getAttribute(linkAttribute);
			if (typeof externalLink === 'string') {
				extractedLinks.push(externalLink);
			}
		});
	}

	return extractedLinks;

};
export {getArticleExternalLinks};