import React from "react";
import {populateArticleData} from "../generateSummary";
import {getVisibleCategory} from "./cardUtils";


/**
 * Generate html for list of simple article links filtered by category
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GenerateCardArticleLinks = (params) => {
	const entries = params.entries || [];
	const actions = params.actions ? params.actions : {};
	const displayParams = params.displayParams ? params.displayParams : {};

	return (
		<div>
			{
				entries.map((entry) => {
					if (entry.categories < 1) {
						return '';
					}
					// set article properties
					const articleData = populateArticleData(entry, actions);

					if (entry.categories.includes(displayParams.listCategory)) {
						// determine which category to display; find the first one that is not hidden or use empty string
						const category = getVisibleCategory(displayParams.hideCategories, entry.categories);
						return (
							<a href={articleData.url} key={articleData.articleId} className={'item-link'} title={articleData.linkTitle} data-articletitle={articleData.title} data-category={category} data-articleid={entry.id} data-issueid={entry.issueId} onClick={articleData.clickAction}>
								<strong>{category}</strong><br />{articleData.title}
							</a>
						);
					} else {
						return '';
					}
				})
			}
		</div>
	);
};
export default GenerateCardArticleLinks;
