/**
 * Check (mostly correct) for a hi-res (retina) display.
 * Be careful where you attempt to use this function
 *
 * Note: windowMultiplier is an attempt to correct for hi-dpi detection
 * while simultaneously doing browser zoom.
 *
 * @returns {boolean}
 *     true: match media query satisfied for screen resolution (with multiplier)
 *     false: not true
 */
const isHiResDisplay = () => {
	var isEdgeIE = document.documentMode || /Edge/.test(navigator.userAgent);
	var windowMultiplier = isEdgeIE ? window.devicePixelRatio : Math.round(window.outerWidth / window.innerWidth);
	var query = 'only screen and (min-resolution: ' + (124 * windowMultiplier) + 'dpi)';
	return matchMedia(query).matches;
};

/**
 * Checks to see user has used browser zoom.
 *
 * Check for hi-res display
 *     This depends on reliable check for hi-res displays, which,
 *     unfortunately, is somewhat unreliable.
 *     Hopefully, this works ;-)
 *
 * NOTE: Safari on desktop always seems to return a value of 1 for devicePixelRatio,
 *     use a different calculation after detecting safari browser...
 *
 * @returns {{zoomLevel: number, isZoomed: boolean}}
 */
const isBrowserZoomed = () => {
	var adjustment = isHiResDisplay() ? 2.0 : 1.0;
	var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	var baseValue = isSafari ? window.outerWidth / window.innerWidth : window.devicePixelRatio;
	return (baseValue / adjustment) > 1.0;
};
export {isBrowserZoomed};
