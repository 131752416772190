/**
 * Truncate string to desired length by word, append ellipsis if set to true
 *
 * Return altered string
 *
 * @param str string to truncate
 * @param len length to truncate to
 * @param ell true/false append ellipsis
 * @returns {string} altered string
 */
import {isPlainObject} from "./objectUtils";
import {isEmpty, isTrue} from "./generalUtils";
import {arraysConcatNoDup} from "./arrayUtils";



const truncateString = (str, len, ell) => {
	str = typeof str === 'string' ? str : '';
	// default len: 155 characters
	len = typeof len === 'number' ? len : 155;
	ell = typeof ell === 'boolean' ? ell : false;
	if (str.length <= len) {
		return str;
	}
	const truncate = (str, len) => {
		const regex = new RegExp("^(.{0," + len + "}(?!\\w))", "sm");
		const newStr = str.match(regex);
		return Array.isArray(newStr) ? newStr[0].trim() : str.trim();
	};
	return ell ? truncate(str,len) + '...' : truncate(str,len);
};
export {truncateString};


/**
 * Given a string with a replaceable attribute, find the replaceable attribute (in braces)
 * and replace it with the actual value.  This should be able to handle both a string
 * and an object that contains a replaceable.
 *
 * It is expected that the replaceable attribute will be of the form:
 *     {replaceable}
 * but this will also accept a replaceable attribute without the braces.
 *
 * @param params
 *     original: the original string or object
 *     replaceKey: replaceable key; usually formatted as "{replaceable}"
 *     replacement: value to use to replace
 * @returns {*|(() => Source)|string|string}
 */
const updateReplaceable = (params) => {
	params = Object.assign({
		original: '',
		replaceKey: '',
		replacement: ''
	}, params);
	// first remove braces, then add back, so both with/without can be used
	const replaceKey = params.replaceKey.trim().replace(/^{(.*?)}$/, "$1");
	const replaceableRegex = new RegExp('{'+replaceKey+'}', "g");
	let fixedString = params.original;
	try {
		fixedString = typeof original !== 'string' ? JSON.stringify(params.original) : fixedString;
		fixedString = fixedString.replace(replaceableRegex, params.replacement);
		fixedString = typeof original !== 'string' ? JSON.parse(fixedString) : fixedString;
	} catch (e) {
		console.error("replacement failed; returning original");
	}
	return fixedString;
};
export {updateReplaceable};


/**
 * Replace multiple string values.  For each set of replacement strings and replaceKeys,
 * loop through each set of replacementValues and call updateReplaceable to replace the
 * string keys in the original string
 *
 * @param params
 *     original: the original string or object
 *     replacementValues: object of replaceable attributes, each one consisting of
 *         replaceKey: replaceable key; usually formatted as "{replaceable}"
 *         replacement: value to use to replace
 * @returns {*|(() => Source)|string|string}
 */
const updateReplaceableMultiple = (params) => {
	params = Object.assign({
		original: '',
		replacementValues: {}
	}, params);
	const replacementValues = isPlainObject(params.replacementValues) ? params.replacementValues : {};
	let fixedString = params.original;
	Object.keys(replacementValues).forEach(replaceKey => {
		fixedString = updateReplaceable({original: fixedString, replaceKey: replaceKey, replacement: replacementValues[replaceKey]});
	});
	return fixedString;
};
export {updateReplaceableMultiple};


/**
 * Simple comparison that attempts to convert each of the values to a string and
 * compare them.
 *
 * Options to trim and convert to lowercase
 *
 * @param str1 (potential) string 1
 * @param str2 (potential) string 2
 * @param options
 *     trim: trim spaces around string
 *     exact: don't convert to lowercase before comparison
 * @returns {boolean}
 */
const stringsEqual = (str1, str2, options) => {
	options = Object.assign({
		trim: false,
		exact: true
	}, options);

	let string1 = !isEmpty(str1) ? String(str1) : '';
	string1 = isTrue(options.trim, false) ? string1.trim() : string1;
	string1 = isTrue(options.exact, true) ? string1 : string1.toLowerCase();

	let string2 = !isEmpty(str2) ? String(str2) : '';
	string2 = isTrue(options.trim, false) ? string2.trim() : string2;
	string2 = isTrue(options.exact, true) ? string2 : string2.toLowerCase();

	return string1 === string2;
};
export {stringsEqual};


/**
 * Check if str1 includes as a substring str2.
 * This is a convenience function that will perform trim and case-insensitive
 * comparison by default.
 *
 * @param str1 string
 * @param str2 substring
 * @param options
 *     trim: trim whitespace from both strings; mostly important for substring (str2)
 *     exact: case-sensitive for strings check
 * @returns {boolean}
 */
const stringIncludes = (str1, str2, options) => {
	options = Object.assign({
		trim: true,
		exact: false
	}, options);

	let string1 = !isEmpty(str1) ? String(str1) : '';
	string1 = isTrue(options.trim, true) ? string1.trim() : string1;
	string1 = isTrue(options.exact, false) ? string1 : string1.toLowerCase();

	let string2 = !isEmpty(str2) ? String(str2) : '';
	string2 = isTrue(options.trim, true) ? string2.trim() : string2;
	string2 = isTrue(options.exact, false) ? string2 : string2.toLowerCase();

	return string1.includes(string2);
};
export {stringIncludes};


/**
 * Convert a string in snake case to camel case.  We need this to convert styles that
 * are written in snake-case to camelCase for javascript processing.
 *
 * Example:
 *     background-color -> backgroundColor
 *
 * @param value string in snake-case format
 * @returns {*} string in camelCase format
 */
const snakeToCamelCase = (value) => {
	const camelCaseAttribute = value.trim().split('-').map(word => word[0].toUpperCase() + word.slice(1).toLowerCase()).join('');
	return camelCaseAttribute[0].toLowerCase() + camelCaseAttribute.slice(1);
};
export {snakeToCamelCase};


/**
 * Merge two strings with a separator character that indicates different
 * substrings, making sure that each substring is unique in the combined
 * string.  By default, the separator character is a space.
 *
 * @param str1 first string
 * @param str2 second string
 * @param separator character separating substrings
 * @returns {*}
 */
const mergeStringsNoDup = (str1, str2, separator) => {
	separator = !isEmpty(separator) ? separator : ' ';
	const noDuplicates = arraysConcatNoDup(str1.split(separator), str2.split(separator));
	return noDuplicates.join(' ');
};
export {mergeStringsNoDup};