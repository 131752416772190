import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import GenerateTitle from "../../../widgets/generateTitle";
import {GenerateHtmlMarkup} from "../../../widgets/generateHtmlMarkup";
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import {VIDEO} from "../../_MODULE_GLOBALS/constants";


/**
 * Video Module
 *
 * This module is designed to allow for the display of an image given an image src.
 *
 * All parameters are passed in through configuration options, so it will not have
 * any interaction with the store; so not fetch or update store data.
 *
 */

/*
	list of potential configuration parameters; other than query params
		className: optional class name to add to div
		displayTitle: {true/false} - display the title; default false if not set
		i18nId: {string} - key to string for title in strings (locale) file
		embed: {string} - embed from content provider
 */

export const VideoModule = (props) => {
	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: VIDEO,
		defaultClass: 'video',
		title: 'video.title',
		titleTag: 'h2',
		configQueryParams: {}
	});


	// TODO: update this section to fix up video embed, depending on the type of video and options provided
	const fixVideoEmbed = (embed) => {
		const embedHtml = props.embed;
		return { __html: embedHtml };
	};


	/*
	* Generate jsx for video if displayOnDevice
	*/
	if (displayOnDevice(props)) {
		return (
			<div className={mops.className}>
				<GenerateTitle titleParams={mops.titleParams} />
				<GenerateHtmlMarkup tag={'div'} className={`video-embed`} htmlMarkup={fixVideoEmbed()} />
			</div>
		);
	} else {
		return null;
	}
};


/**
 * Map state (store) data.
 *
 * storageKey provides a way to map a specific store object to a particular
 * instance of the module.  Add storageKey when configuring the module instance
 * if it needs to listen for a store change.
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {{filters: Array}}
 */
const mapStateToProps = (state, props) => {
	return {
		active: getActiveAttributeState(props)
	};
};

/**
 * simpleImage module (currently) makes to calls to fetch of update data.
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VideoModule);
