import $ from "jquery";
import {getStoreValue} from "../../../utils/storeValue";
import {executeFunction} from "../../../utils/embedJavascript";
import {isEmpty} from "../../../utils/generalUtils";


/**
 * Extract date string from issue name.
 * Currently only used for Olytics tracking
 *
 * Conversion example and expected input:
 *     "_March 2022" => MAR-2022
 *
 * @param str
 * @returns {string|*}
 */
const getMMM_YYYY = (str) => {
	if (isEmpty(str)) {
		return str;
	}
	if (str.startsWith("_")) {
		str = str.substring(1);
	}
	const res = str.split(" ");
	if (res < 1 || res[0].length < 3) {
		return str;
	}
	return res[0].substring(0, 3).toUpperCase()+ "-" + res[res.length - 1];
};


/**
 * Adobe Dynamic Tag Manager
 *
 * Call to update the configured DOM element for the adobedtm tracker each time a
 * Content Hub tracking event happens.  Then throw a "gtxdatalayer" event to
 * trigger adobedtm tracking.
 *
 * Configured attributes:
 *     className: class name of the target DOM element
 *
 * Currently, our implementation of adobeDTM does not require a function
 * call each time tracking is called.
 *
 * @param params
 *    category: the tracking event category
 *    trackingProperties: tracking properties as collected in tracking.js
 *    target: DOM element targetName - use jQuery to find
 *    trackFunction: function to call each time a tracking call is made
 *    trackParams: function to call each time a tracking call is made
 */
const updateForAdobeDTM = (params) => {
	params = Object.assign({
		category: '',
		trackingProperties: {},
		target: '',
		trackFunction: '',
		trackParams: {}
	}, params);

	if (params.category === "login popup action" && params.trackingProperties.popupType === "login" && params.trackingProperties.action === "logged in") {
		window.location.reload();
	}

	const $target = $(params.target);
	const callToTrackContentViewed = $target.length > 0 && (params.category === "content viewed" && (params.trackingProperties.type === "page view" || params.trackingProperties.type === "article view"));
	const callToTrackForGTXLogin = params.category === "login popup appeared" || params.category === "login popup action";

	if (callToTrackContentViewed) {
		const eventType = "gtxdatalayer";
		const dataObject = {
			category: params.category,
			args: params.trackingProperties
		};
		// console.log(dataObject);
		$target.text(JSON.stringify(dataObject));
		const event = new CustomEvent(eventType, {detail: dataObject});
		console.log("triggered adobe datalayer call");
		document.dispatchEvent(event);
	} else if (callToTrackForGTXLogin) {
		const eventType = "gtxlogin";
		const dataObject = {
			"category": params.category,
			"type": params.trackingProperties.type,
			"popupType": params.trackingProperties.popupType,
			"subscriber status": !isEmpty(params.trackingProperties["subscriber status"]) ? params.trackingProperties["subscriber status"] : "",
			"action": !isEmpty(params.trackingProperties.action) ? params.trackingProperties.action : "",
		};
		const event = new CustomEvent(eventType, {detail: dataObject, bubbles: true});
		console.log("gtxlogin: firing event", event);
		document.dispatchEvent(event);
	}

};


/**
 * Omeda Olytics tracking.
 *
 * Call to trigger an olytics function call each time a Content Hub tracking
 * event happens.
 *
 * Configured attributes:
 *    trackFunction: function to call each time a tracking call is made
 *    trackParams: function to call each time a tracking call is made
 *
 * @param params
 *    category: the tracking event category
 *    trackingProperties: tracking properties as collected in tracking.js
 *    target: DOM element targetName - use jQuery to find
 *    trackFunction: function to call each time a tracking call is made
 *    trackParams: function to call each time a tracking call is made
 */
const updateOlytics = (params) => {
	params = Object.assign({
		category: '',
		trackingProperties: {},
		target: '',
		trackFunction: '',
		trackParams: {}
	}, params);
//	console.log("updateOlytics");
};


/**
 * Omeda Olytics tracking - Allured customized
 *
 * Call to trigger an olytics function call each time a Content Hub tracking
 * event happens.
 *
 * Configured attributes:
 *    trackFunction: function to call each time a tracking call is made
 *    trackParams: tracking attributes to pass to tracking function call
 *
 * @param params
 *    category: the tracking event category
 *    trackingProperties: tracking properties as collected in tracking.js
 *    target: DOM element targetName - use jQuery to find
 *    trackFunction: function to call each time a tracking call is made
 *    trackParams: function to call each time a tracking call is made
 */
const updateOlyticsAllured = (params) => {
	params = Object.assign({
		category: '',
		trackingProperties: {},
		target: '',
		trackFunction: '',
		trackParams: {}
	}, params);
//	console.log("updateOlyticsAllured");
	if (isEmpty(params.trackFunction) || isEmpty(params.trackParams)) {
		console.log("updateOlyticsAllured configuration error, need trackFunctiopn and trackParams", params.trackFunction, params.trackParams);
		return;
	}

	const behaviorId = !isEmpty(params.trackParams.behaviorId) ? params.trackParams.behaviorId : '';
	const brandId = !isEmpty(params.trackParams.brandId) ? params.trackParams.brandId : '';

	if (params.category === "content viewed") {
		const options = params.trackingProperties;
		if (options.type === "page view") {
/*
			const pgs = options["page folio"];
			if ( typeof pgs === 'string' ) {
				const olyticsParams = {
					"behaviorId": behaviorId,
					"category": brandId + " - Digital Edition Page View - " + this.getMMM_YYYY(options["issue"]) + " - " + pgs, "issueDate":brandId + " DE " + this.getMMM_YYYY(options["issue"]),
					"title": options["issue name"]
				};
				const pageNumber = pgs;
				console.log(pageNumber + " " +JSON.stringify(options) + " => " + JSON.stringify(olyticsParams));
				executeFunction(params.trackFunction, olyticsParams);
			} else {
				let i;
				for (i = 0; i < pgs.length; i++) {
					const olyticsParams = { "behaviorId": behaviorId, "category": brandId + " - Digital Edition Page View - " + this.getMMM_YYYY(options["issue"]) + " - " + pgs[i], "issueDate":brandId + " DE " + this.getMMM_YYYY(options["issue"]),"title":DocumentProperties.getTitle()};
					const pageNumber = pgs[i];
					console.log(pageNumber + " " +JSON.stringify(options) + " => " + JSON.stringify(olyticsParams));
					executeFunction(params.trackFunction, olyticsParams);
				}
			}
*/
		} else if (options.type === "article view") {
			const article = options["article title"];
			const mmm_yyyy = getMMM_YYYY(options["issue name"]);
			const olyticsParams = {
				"behaviorId": behaviorId,
				"category": brandId + " - Digital Edition Article View - " + mmm_yyyy + " - " + article,
				"issueDate": brandId + " DE " + mmm_yyyy,
				"title": options["issue name"]
			};
			console.log(article + " " + JSON.stringify(options) + " => " + JSON.stringify(olyticsParams));
			console.log("executeFunction", params.trackerTarget, olyticsParams);
			executeFunction(params.trackFunction, olyticsParams);
		}
	}

};


export {updateForAdobeDTM, updateOlytics, updateOlyticsAllured};



/**
 * List of supported trackers
 *
 * @type {{adobeTracker: updateForAdobeDTM}}
 */
const customTrackers = {
	adobeTracker: updateForAdobeDTM,
	olyticsTracker: updateOlytics,
	olyticsAlluredTracker: updateOlyticsAllured
};

/**
 * Calls all the custom trackers that have been configured for a particular collection
 * and collected in server.js with the function gatherGlobalModulesAndAttributes and
 * into the customTrackers object.
 *
 * Only trackers that have been created and listed above will be called.
 *
 * @param params
 *    category: the tracking event category
 *    trackingProperties: tracking properties as collected in tracking.js
 */
const callCustomTrackers = (params) => {
	params = Object.assign({
		category: '',
		trackingProperties: {}
	}, params);

	const configuredTrackers = getStoreValue({attributeKey: 'customTrackers'});
	configuredTrackers.forEach(configuredTracker => {
		params.target = !isEmpty(configuredTracker.target) ? configuredTracker.target : '';
		params.trackFunction = !isEmpty(configuredTracker.trackFunction) ? configuredTracker.trackFunction : '';
		params.trackParams = !isEmpty(configuredTracker.trackParams) ? configuredTracker.trackParams : {};

		const trackerName = configuredTracker.tracker;
		// get and call configured custom tracker functions; also call trigger function if defined in configuration
		if (customTrackers.hasOwnProperty(trackerName)) {
			customTrackers[trackerName](params);
		}
	});
};
export {callCustomTrackers};
