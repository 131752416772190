import React from "react";
import GenerateStringFromList from "../generateStringFromlist";
import GenerateExcerpt from "../generateExcerpt";
import GenerateTitle from "../generateTitle";
import {populateArticleData, populateIssueData, GenerateSummaryIssue} from "../generateSummary";
import {truncateString} from "../../utils/stringUtils";

/**
 * Generate html for article summary (and replica summary??)
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GenerateCardDefault = (params) => {
	params = Object.assign({
		entry: {},
		actions: {},
		displayParams: {}
	}, params);

	const entry = params.entry;
	const displayParams = Object.assign({},{
		displayThumbnail: false,
		displayThumbnailOnLeft: false,
		displayIssue: false,
		displayCategory: false,
		displaySummary: true,
	}, params.displayParams);

	const titleParams = {
		tag: 'h3',
		className: 'title',
		displayTitle: true,
	};

	// set article and issue properties for template
	const articleData = populateArticleData(entry, params.actions);
	const issue = populateIssueData(entry, params.actions);
	// truncate summary if needed
	const summary = typeof entry.summary === 'string' && entry.summary !== '' ? truncateString(entry.summary, displayParams.maxSummaryCharacters, displayParams.useSummaryEllipsis) : '';

	// fallback to title for ALT tag if not available
	const imgAlt = typeof entry.squareImageAlt === 'string' && entry.squareImageAlt !== '' ? entry.squareImageAlt : entry.title + ", article thumbnail";

	/**
	 * Generate an a-tag element with different parts depending on whether an associated variable is set
	 *     displayParams.displayThumbnail && displayParams.displayThumbnailOnLeft && entry.squareImage all: generate a thumbnail image on left
	 *     displayParams.displayThumbnail && !displayParams.displayThumbnailOnLeft && entry.squareImage: generate a thumbnail image on right
	 *     displayParams.displayIssue: generate an issue summary
	 *     displayParams.displayCategory: generate a category list
	 *     displayParams.displaySummary: generate a summary excerpt
	 *
	 * Note: a description element and title is always generated, so the a-tag will not be empty
	 */
	return (
		<a href={articleData.url} className={'item-link'} data-articletitle={articleData.title} onClick={articleData.clickAction}>
			{
				displayParams.displayThumbnail && displayParams.displayThumbnailOnLeft && entry.squareImage &&
				<div className={'thumbnail left'}>
					<img alt={imgAlt} className={'thumbnail-image'} src={entry.squareImage} />
				</div>
			}
			<div className={'description'}>
				<div className={'title-issue-wrap'}>
					<GenerateTitle titleParams={titleParams}>{entry.title}</GenerateTitle>
					{
						displayParams.displayIssue &&
						<GenerateSummaryIssue issue={issue} />
					}
					{
						displayParams.displayCategory &&
						<div className={'article-categories'}>
							<GenerateStringFromList list={entry.categories} separator={', '} tag={'span'} />
						</div>
					}
				</div>
				{
					displayParams.displaySummary &&
					<GenerateExcerpt className={'summary'} summary={summary} />
				}
			</div>
			{
				displayParams.displayThumbnail && !displayParams.displayThumbnailOnLeft && entry.squareImage &&
				<div className={'thumbnail right'}>
					<img alt={imgAlt} className={'thumbnail-image'} src={entry.squareImage} />
				</div>
			}
		</a>
	);
};
export default GenerateCardDefault;
