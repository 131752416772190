import {getModuleContainer} from "../modules/globals/globalLibraryData";
import $ from 'jquery';
import {getStoreValue, setStoreValue} from "../utils/storeValue";
import {retrieveFromCache} from "../utils/manageStoreCacheData";
import getDeviceType from "../utils/getDeviceType";
import {getStoredScrollData, storeGlobalModuleData, getStoredGlobalModuleData} from "../modules/globals/globalLibraryData";
import {isTrue} from "../utils/generalUtils";


/**
 * Check to see if the thumbnail element is within view in the replica list.
 *
 * If the element is visible, set the position to "middle", meaning that it is visible and doesn't need to trigger a scroll.
 * If the thumbnail is off the top, set to "above" and set a value for scroll.
 * If the thumbnail is off the bottom, set to "below" and set a value for scroll.
 *
 * @param params elements to check for on-screen and to set a scroll value
 *     $listFolioElement: thumbnail element in list
 *     $layoutContainer: scrollable container for replica
 *     useWindow: if we want to scroll the whole window (mobile devices)
 *     scrollToLastPosition: if we want to use stored scroll position
 *
 * @returns {{layoutContainerAttributes: {top: *} & {width: number, scrollHeight: number, scrollTop: number, height: number}, folioElementAttributes: {top: number, height: number}, scrollTo: number}}
 */
const checkIfOffScreen = (params) => {
	params = Object.assign({
		$listFolioElement: $(),
		$layoutContainer: $(),
		useWindow: false,
		scrollToLastPosition: false,
		module: 'replicaList',
		storageKey: ''
	}, params);
	const $listFolioElement = params.$listFolioElement;
	const $layoutContainer = params.$layoutContainer;
	const useWindow = params.useWindow;
	const scrollToLastPosition = params.scrollToLastPosition;

	let layoutContainerAttributes = {
		top: $layoutContainer.position().top
	};
	const folioElementAttributes = {
		height: parseInt($listFolioElement.height(), 10),
		top: parseInt($listFolioElement.position().top, 10)
	};
	let scrollTo = 0;

	if (scrollToLastPosition) {
		// note: this is only currently valid for mobile where the whole window scrolls
		const moduleData = getStoredGlobalModuleData({
			module: params.module,
			storageKey: params.storageKey
		});
		// scrollAdjust is required because scrollHeight is different from original scrollHeight when function is first called
		const documentScrollHeight = document.documentElement.scrollHeight;
		const scrollAdjust = moduleData.hasOwnProperty('scroll') ? moduleData.scroll.scrollHeight - documentScrollHeight : 0;
		scrollTo = moduleData.hasOwnProperty('scroll') ? moduleData.scroll.scrollTop - scrollAdjust : 0;
		layoutContainerAttributes = Object.assign(layoutContainerAttributes, {
			height: parseInt(window.innerHeight, 10),
			width: parseInt(window.innerWidth, 10),
			scrollHeight: documentScrollHeight,
			scrollTop: 0
		});
	} else {
		if (useWindow) {
			layoutContainerAttributes = Object.assign(layoutContainerAttributes, {
				height: parseInt(window.innerHeight, 10),
				width: parseInt(window.innerWidth, 10),
				scrollHeight: parseInt($(document).height(), 10),
				scrollTop: parseInt(window.scrollY, 10)
			});
		} else {
			layoutContainerAttributes = Object.assign(layoutContainerAttributes, {
				height: parseInt($layoutContainer.height(), 10),
				width: parseInt($layoutContainer.width(), 10),
				scrollHeight: parseInt($layoutContainer.prop('scrollHeight'), 10),
				scrollTop: parseInt($layoutContainer.scrollTop(), 10)
			});
		}
		const middle = parseInt((folioElementAttributes.top + (folioElementAttributes.height / 2)), 10);
		scrollTo = parseInt((middle - (layoutContainerAttributes.height / 2)), 10);
	}

	return {layoutContainerAttributes: layoutContainerAttributes, folioElementAttributes: folioElementAttributes, scrollTo: scrollTo};
};

/**
 * Set the scroll position in a vertical replica list when changing pages.
 *
 * @param params
 *     $listFolioElement: thumbnail element in list
 *     $layoutContainer: scrollable container for pages
 */
const verticalscrollToFolio = (params) => {
	params = Object.assign({
		$listContainer: [],
		$listElement: [],
		$layoutContainer: [],
		$listFolioElement: [],
		$listEntryPage: [],
		triggerKeyData: {}
	}, params);

	const useWindow = params.$layoutContainer.parents('.article-independent-scrolling').length === 0;
	let scrollCheck = checkIfOffScreen({
		$listFolioElement: params.$listFolioElement,
		$layoutContainer: params.$layoutContainer,
		useWindow: useWindow,
		scrollToLastPosition: params.triggerKeyData.scrollToLastPosition,
		module: params.triggerKeyData.module,
		storageKey: params.triggerKeyData.storageKey
	});

	const containerScrollHeight = scrollCheck.layoutContainerAttributes.scrollHeight;
	const containerWidth = scrollCheck.layoutContainerAttributes.width;
	// get and set store value for latest trigger by device, module, storageKey; so we only scroll once
	const triggerKey = getDeviceType() + '_' + params.triggerKeyData.module + '_' + params.triggerKeyData.storageKey;
	const triggerStorage = getStoreValue({attributeKey: 'replicaListUITriggers', default: {}});
	const storedData = triggerStorage.hasOwnProperty(triggerKey) ?
		triggerStorage[triggerKey] :
		{
			scrollTo: -1,
			containerScrollHeight: 0,
			containerWidth: 0
		};

	const doScroll = storedData.scrollTo !== scrollCheck.scrollTo || storedData.containerScrollHeight !== containerScrollHeight || storedData.containerWidth !== containerWidth;
	if (doScroll) {
		if (useWindow) {
			window.scrollTo(0, scrollCheck.scrollTo);
		} else {
			params.$layoutContainer.scrollTop(scrollCheck.scrollTo);
		}
	}
	triggerStorage[triggerKey] = {scrollTo: scrollCheck.scrollTo, containerScrollHeight: containerScrollHeight, containerWidth: containerWidth};
	setStoreValue({attributeKey: 'replicaListUITriggers'}, triggerStorage);
};


/**
 * Respond to an action on an replicaList that requires a browser back-end response
 * rather than one from within the module itself.
 *
 * Note: Not currently called
 *
 * @param params parameters for page trigger
 *     action: potential, respondable action
 *     module: module name
 *     storageKey: module instance storageKey
 *     replicaList: pointer to the list
 * @returns {string} success/failure
 */
// const triggerOnReplicaList = (params) => {
// 	params = Object.assign({
// 		action: '',
// 		module: null,
// 		storageKey: null,
// 		replicaList: null
// 	}, params);
//
// 	return 'success';
// };
// export {triggerOnReplicaList};



const mobileDevices = ['md', 'sm', 'xs'];
/**
 * Trigger function that is activated when a click is registered in the replicaList.
 * Currently, this is only called when an thumbnail is clicked.
 *
 * TODO: Generalize this for future use
 *     We are only triggering this if scrollToLastPosition is true and if mobile
 *
 * @param params
 *     storageKey: module storage key
 *     scrollToLastPosition: configuration parameter to trigger scroll on mobile
 */
const triggerOnReplicaListClick = (params) => {
	params = Object.assign({
		storageKey: '',
		scrollToLastPosition: false
	}, params);

	const device = getDeviceType();
	const mobileDevice = mobileDevices.includes(device);
	if (isTrue(params.scrollToLastPosition)) {
		if (mobileDevice) {
			const documentScrollData = getStoredScrollData({component: document, componentName: 'browser'});
			storeGlobalModuleData({
				module: 'replicaList',
				storageKey: params.storageKey,
				attributeKey: 'scroll',
				attributeValue: documentScrollData
			});
		} else {
			// for now, only mobile
		}
	}
};
export {triggerOnReplicaListClick};


/**
 * Respond to an action on replicaList when the list UI is re-rendered.
 * This will trigger a response to the module containers, so can't be handled
 * from within the module itself.
 *
 * The triggered action (setting scroll position) works best when the UI for the finish rendering,
 * which is why it encloses the action (verticalScrollToFolio) within a
 * window.setTimeout.  This happens relatively quickly, so the timeout value
 * is relatively short.
 *
 * Note: This is kind of clunky, but resizeObserver didn't work here.  This just loops around until
 * the scroll height of the layout container settles down to a constant value as the contents are
 * finally populated.  This might still not work on a first load on a slow connection, but it will
 * be fine for prev/next button, since the replica list will usually be fully populated at that point.
 *
 * @param params
 *     parameters for thumbnail trigger passed in by calling module
 *         module: 'replicaList' required to find the module in the UI
 *         storageKey: module storage key
 *         scrollToSelection: module property; true: try to trigger scroll; false: no scroll
 *         page: explicitly pass in page index for trigger action
 *         replicaListKey: hash storage key to get list of replica thumbnails
 * @returns {string} success/failure
 */
const triggerOnReplicaListUI = (params) => {
	params = Object.assign({
		module: 'replicaList',
		storageKey: '',
		scrollToSelection: false,
		scrollToLastPosition: false,
		folio: '',
		replicaListKey: ''
	}, params);

	const replicaList = retrieveFromCache({dataKey: params.replicaListKey, keyType: 'replicaPages', emptyDataObject: []}).storedData;
	const scrollToSelection = isTrue(params.scrollToSelection);
	const scrollToLastPosition = isTrue(params.scrollToLastPosition);

	// check if the folio is in the list (ads are usually not fetched in the list)
	const pageInList = replicaList.some((entry) => entry.folio === params.folio);
	if (pageInList && params.folio !== '' && (scrollToSelection || scrollToLastPosition)) {
		// find the module's defined container and find it in the UI and start from there
		const moduleContainer = getModuleContainer(params);

		const $listContainer = $('#' + moduleContainer.pane).find('.' + moduleContainer.container);
		const $listElement = $listContainer.find('div[data-storagekey="' + params.storageKey + '"]');
		const $listFolioElement = $listElement.find('li[data-folio="'+params.folio+'"]').filter('.folio-current');
		const $layoutContainer = $listElement.parents('div.layout-block');
		const $listEntryPage = $listFolioElement.parents('ul.replica-page');
		const $listGridPages = $listFolioElement.parents('.display-pages');

		const triggerKeyData = {
			module: params.module,
			storageKey: params.storageKey,
			scrollToSelection: scrollToSelection,
			scrollToLastPosition: scrollToLastPosition
		};
		const scrollParams = {
			$listContainer: $listContainer,
			$listElement: $listElement,
			$layoutContainer: $layoutContainer,
			$listFolioElement: $listFolioElement,
			$listEntryPage: $listEntryPage,
			$listGridPages: $listGridPages,
			triggerKeyData: triggerKeyData
		};

		if ($listElement.length > 0 && $listFolioElement.length > 0) {
			verticalscrollToFolio(scrollParams);
		}
	}
	return 'success';
};
export {triggerOnReplicaListUI};
