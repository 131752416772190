import React from "react";
import {sanitizeInline} from "../utils/htmlUtils";
import {GenerateHtmlMarkup} from "./generateHtmlMarkup";

/**
 * When a summary is passed it will display the inline sanitized content in a paragraph within a classed div.
 *
 * @param {Object} params - Settings for displaying summary/excerpt.
 * @param {string} [params.className=summary] - Class name to use for the wrapping <div>.
 * @param {string} [params.summary=] - The string content of summary, may contain HTML.
 * @returns {*} string
 * @constructor
 */
const GenerateExcerpt = (params) => {
	params = Object.assign({
		className: 'summary',
		summary: null
	}, params);

	if (! params.summary) {
		return '';
	} else {
		return <div className={params.className}>
			<GenerateHtmlMarkup tag={'p'} htmlMarkup={sanitizeInline(params.summary)} />
		</div>;
	}
};
export default GenerateExcerpt;
