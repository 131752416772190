import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-responsive-dt";
import "datatables.net-rowgroup-dt";
import getDeviceType from "../../../../utils/getDeviceType";

/**
 *
 * @param articleMarkup html markup for an article
 *
 * @returns {*} the modified html markup
 */
const updateArticleDataTables = (articleContainerElement, deviceTypeSupport) => {
	const $tables = $(articleContainerElement).find('.gtxcelDataTable>table');
	if ($tables.length === 0) {
		return;          
	}

	/**
	 * Data tables don't work well at the moment for smaller device sizes like xs and sm.
	 * So our default configuration has data tables turned off for these sizes. 
	 * This can be adjusted per publication.
	 */
	const deviceType = getDeviceType();
	if (typeof deviceType !== "undefined" && typeof deviceTypeSupport !== "undefined" && 
		typeof deviceTypeSupport[deviceType] !== "undefined" && !deviceTypeSupport[deviceType]) {
		//console.log("data tables not supported for device type: " + deviceType);
		return;
	}
	
	$tables.each(function(idx, table) {     
		const $table = $(table);
		if ($.fn.dataTable.isDataTable($table)) {
			$table.DataTable(); 
			return true; 
		}

		try {          
			$table.DataTable({     
				paging: false,
				searching: false,
				info: false,
				responsive: true,
				order: [],
			});             
		} catch (err) {
			console.log(err);  
		}
	});
	
	return;
};

export { updateArticleDataTables };
