import {debounce} from "debounce";
import {isEmpty, isTrue} from "./generalUtils";
import $ from "jquery";

/**
 * "debounce" makes sure that an event cannot execute a called function too many times.
 * It is designed to execute the passed function after a certain amount of time has
 * passed to ensure that the event calls have died down.
 *
 * Typical use is to apply debounce to the resize event as it is called many times
 * while the user is in the process of resizing the browser.  We only want to call
 * the passed function when resizing is complete.
 *
 * @param params to pass in
 *     eventName event to add debounce listener
 *     target element to attach listener
 *     timeout timeout time to wait before execution
 *     fn function to execute
 * @returns {function(...[*]=)}
 */
const addDebounceListener = (params) => {
	params = Object.assign({
		eventName: "resize",
		target: window,
		timeout: 500,
		fn: null,
		// javascript addEventListener only
		capture: false,
		// jQuery .on only
		useJQuery: false,
		namespace: '',
		removeOld: true
	}, params);
	if (isTrue(params.useJQuery, {defaultValue: false})) {
		const $target = $(params.target);
		const eventName = !isEmpty(params.namespace) ? params.eventName + '.' + params.namespace : params.eventName;
		if (isTrue(params.removeOld, {defaultValue: true})) {
			$target.off(eventName);
		}
		$target.on(
			eventName,
			debounce(params.fn, params.timeout)
		);
	} else {
		const useCapture = {capture: params.capture};
		params.target.addEventListener(
			params.eventName,
			debounce(params.fn, params.timeout),
			useCapture
		);
	}
};
export default addDebounceListener;



/*
// NOT USED: simple debounce function; using npm package "debounce"
const debounce = (event, fn, timeout) => {
	let debounceTimeout = null;
	return function() {
		const functionCall = () => fn.apply(this, arguments);
		clearTimeout(debounceTimeout);
		debounceTimeout = setTimeout(functionCall, timeout);
	};
};
export default addDebounceListener;
*/
