import sanitizeHtml from 'sanitize-html';
import cheerio from "cheerio";
import {isEmpty, isTrue} from "./generalUtils";
import {isPlainObject} from "./objectUtils";
import {snakeToCamelCase} from "./stringUtils";
import {arraysEqual} from "./arrayUtils";


/**
 * Remove white space from an html string before adding to the DOM to
 * minimize unintentional space problems for layout.
 *
 * @param htmlString string to cleanup
 * @returns {string} "cleaned" string
 */
const cleanupHtmlString = (htmlString) => {
	return htmlString
		.replace(/\t|\r?\n|\r/g, '')
		.replace(/>\s</g, '><');
};
export {cleanupHtmlString};

/**
 * Strips all HTML tags from the string passed.
 *
 * @param string string that may contain HTML tags.
 * @returns {*}
 */
const stripTags = (string) => {
	return sanitizeHtml(string, {
		allowedTags: []
	});
};
export {stripTags};

/**
 * Strips all but the most basic inline HTML tags.
 *
 * @param string string that may contain HTML tags.
 * @returns {*}
 */
const sanitizeInline = (string) => {
	return sanitizeHtml(string, {
		allowedTags: ['b','i','strong','em','sup','sub','br'],
		allowedAttributes: {
			'em': ['class']
		}
	});
};
export {sanitizeInline};


/**
 * Fix attributes before applying them in React format to the top-level div
 * for our article content.
 *
 * Attributes fixed:
 *    style: convert to object of camelCased style definitions
 *
 * @param styleAttribute style attribute to convert
 * @returns {*} camelCased style attribute
 */
const fixStyleAttribute = (styleAttribute) => {
	const fixedStyleAttribute = {};
	if (!isEmpty(styleAttribute)) {
		const styles = styleAttribute.split(";");
		styles.forEach(style => {
			if (!isEmpty(style)) {
				const splitStyle = style.split(':');
				const styleKey = snakeToCamelCase(splitStyle[0]).trim();
				fixedStyleAttribute[styleKey] = splitStyle[1].trim();
			}
		});
	}
	return fixedStyleAttribute;
};

/**
 * For now, return the same class attributes as passed in to allow for a standard
 * function call from calling functions.
 *
 * @param classAttribute class attributes
 * @returns {*}
 */
const fixClassAttribute = (classAttribute) => {
	return classAttribute;
};


/**
 * Given a DOM element, get the attributes attached to that element, loop through the
 * attributes and create an object with attribute.name: attribute.value.
 *
 * Note: React uses a slightly different format for some attributes, which requires some conversion.
 * If a particular attributes is listed in the "conversions" parameter as true, we will convert
 * the attribute to the React-friendly format.
 *
 * Note: React specifies that attribute names be lowercase, so convert name.
 *
 * @param node DOM element
 * @param conversions list of attributes to convert format
 *     class: convert to "className"
 *     style: convert to javascript camelCase
 * @returns {{}} object of attributes that can be used with spread operator
 */
const getElementAttributes = (node, conversions) => {
	conversions = Object.assign({
		class: ["className", fixClassAttribute],
		style: ["style", fixStyleAttribute]
	}, conversions);

	const elementAttributes = {};
	if (node.hasAttributes()) {
		const attributeNames = node.getAttributeNames();
		attributeNames.forEach(name => {
			const nodeAttribute = node.getAttribute(name);
			if (!isEmpty(conversions[name])) {
				const attributeName = conversions[name][0];
				const attributeConversion = conversions[name][1];
				elementAttributes[attributeName] = attributeConversion(nodeAttribute);
			} else {
				elementAttributes[name.toLowerCase()] = nodeAttribute;
			}
		});
	}
	return elementAttributes;
};
export {getElementAttributes};


/**
 * Generate an html string from three elements as described below.
 *
 * Note: This is mostly to support finding the outer html from DOM nodes.
 *
 * @param params
 *     tag: html tag
 *     attributes: attributes for the tag
 *     contents: contents of the html element
 * @returns {string}
 */
const generateHtmlFromNodeElements = (params) => {
	params = Object.assign({
		tag: 'div',
		attributes: {},
		contents: ''
	}, params);
	const attributes = isPlainObject(params.attributes) ? params.attributes : {};
	let htmlString = '';
	htmlString += '<' + params.tag;
	Object.keys(attributes).forEach(key => {
		htmlString += ' ' + key + '="' + attributes[key] + '"';
	});
	htmlString += '>' + params.contents + '</' + params.tag + '>';
	return htmlString;
};
export {generateHtmlFromNodeElements};


/**
 * Given an DOM node, try to return the text value in the node.
 *
 * If a nodeName is specified, use that node name (tag name) if found, otherwise just
 * use the top level dom element.
 *
 * If the text is empty, then return the default value.
 *
 * @param domNode DOM node
 * @param options
 *     nodeName: check if a specified node name is required
 *     defaultValue: return if text is empty string
 * @returns {string|*}
 */
const getNodeTextValue = (domNode, options) => {
	options = Object.assign({
		nodeName: '',
		defaultValue: ''
	}, options);

	const $ = cheerio.load(domNode, null, false);
	const $namedNode = $(options.nodeName).length > 0 ? $(options.nodeName) : $;
	const text = $namedNode.text();
	return !isEmpty(text) ? text : options.defaultValue;
};
export {getNodeTextValue};


/**
 * given an html DOM node, return the inner html of the dom node.
 *
 * If the html is empty, then return the default value.
 *
 * @param domNode DOM node
 * @param options
 *     defaultValue: return if html is empty string
 *     outerHtml:
 *         false(default): return the innerHtm of the node element
 *         true: return the outerHtml, including the node element
 * @returns {string|*}
 */
const getNodeHtml = (domNode, options) => {
	options = Object.assign({
		defaultValue: '',
		outerHtml: false
	}, options);

	const $ = cheerio.load(domNode, null, false);
	const html = $('*').html();

	if (isTrue(options.outerHtml, {defaultValue: false})) {
		return generateHtmlFromNodeElements({tag: domNode.name, attributes: domNode.attribs, contents: html});
	} else {
		return !isEmpty(html) ? html : options.defaultValue;
	}
};
export {getNodeHtml};


/**
 * Given a tag/id/class selector and a parent DOM element, find the first child node
 * represented by the selector.
 *
 * @param parentElement parent DOM element
 * @param selector tag/id/class selector
 * @returns {null|any} child element
 */
const getNodeFromClass = (parentElement, selector) => {
	if (isEmpty(parentElement) || isEmpty(selector)) {
		return null;
	} else {
		return parentElement.querySelector(selector);
	}
};
export {getNodeFromClass};


/**
 * Return all the child nodes in a parent DOM element based on a tag/id/class
 * selector.
 *
 * Note: A nodelist is NOT an array, but has some array properties, like length
 * or the ability to interate over the list with forEach; so return empty array if no parent.
 *
 * @param parentElement parent DOM ellement
 * @param selector tag/id/class selector
 * @returns {*[]|*} nodelist of child nodes
 */
const getChildNodes = (parentElement, selector) => {
	if (isEmpty(parentElement) || isEmpty(selector)) {
		return [];
	} else {
		return parentElement.querySelectorAll(selector);
	}
};
export {getChildNodes};


/**
 * Given a nodelist DOM object, generate an array from the nodelist
 * and get the outer html from each object as the element for each of
 * the elements in the new array.
 *
 * @param nodeList DOM nodelist object
 * @returns {*[]} array of elements consisting of the outer html of each nodelist element
 */
const generateArrayFromNodeList = (nodeList) => {
	return Array.from(nodeList).map(node => {
		return node.outerHTML;
	});
};
export {generateArrayFromNodeList};


/**
 *
 * @param node DOM node object/element
 * @returns {string|*} outer html of the DOM node element
 */
const generateHtmlFromNode = (node) => {
	if (!isEmpty(node)) {
		return node.outerHTML;
	} else {
		return '';
	}
};
export {generateHtmlFromNode};


/**
 * Compare two nodelist objects to see if they are equal.
 * Convert each to an array first, then call arraysEqual to
 * do the equality test
 *
 * @param nodelist1 DOM nodelist 1
 * @param nodelist2 DOM nodelist 2
 * @returns {boolean} true: nodelists considered equal; false: not equal
 */
const nodeListsEqual = (nodelist1, nodelist2) => {
	if (nodelist1.length !== nodelist2.length) {
		return false;
	} else if (nodelist1.length > 0) {
		const array1 = Array.from(nodelist1);
		const array2 = Array.from(nodelist2);
		return arraysEqual(array1, array2);
	} else {
		return true;
	}
};
export {nodeListsEqual};


/**
 * Return the value of a particular style attribute for the given node.
 * For DOM node computed style, the style format is the same as for css, rather
 * than the javascript camelCase style; for example:
 *     'padding-top'
 *     'vertical-align'
 *
 * @param node DOM element
 * @param style style attribute
 * @param options additional, optional modifiers
 *     pseudoElt: (default: null) pseudo element; null passed in if none
 * @returns {string} css value for the style attribute
 */
const getNodeStyleValue = (node, style, options) => {
	options = Object.assign({
		pseudoElt: null
	}, options);

	return window.getComputedStyle(node, options.pseudoElt).getPropertyValue(style);
};
export {getNodeStyleValue};