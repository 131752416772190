import {retrieveFromCache} from "../manageStoreCacheData";
import {REPLICA_LIST, REPLICALIST_STOREDLIST} from "../../modules/_MODULE_GLOBALS/constants";
import {getStoreValue} from "../storeValue";
import {isEmpty} from "../generalUtils";


/**
 * Called by one of the following modules that use an replica list as their source for replica
 * data:
 *     replicaList
 *     replicaViewer
 *
 * This function will attempt to get a stored replica list from hash storage.  If it finds
 * a list with a key that matches the query parameters, it will just call updateData to
 * return the list without fetch.  If not, it will call fetch to get a new list.
 *
 * Note: This will use the stored, generic updateData and fetchData and just pass the
 * appropriate parameters.  This will trigger the appropriate reducer in the replica.js
 * reducer file, which the appropriate module (identified by storageKey) will use to
 * determine if the list is for its use.
 *
 * @param params module parameters
 *     mops: mops from module
 */
const getReplicaList = (params) => {
	params = Object.assign({
		storageKey: '',
		queryParams: {},
		returnStoredDataOnly: false
	}, params);
	const storageKey = !isEmpty(params.storageKey) ? params.storageKey : '';
	const queryParams = !isEmpty(params.queryParams) ? params.queryParams : {};

	const storedListData = retrieveFromCache({dataKey: queryParams, keyType: 'replicaPages', emptyDataObject: []});
	const storedListStyleToc = retrieveFromCache({dataKey: queryParams, keyType: 'listStyleToc', emptyDataObject: ''});

	if (params.returnStoredDataOnly) {
		return storedListData.storedData;
	} else {
		if (storedListData.hasStoredData) {
			//console.log('No FETCH');
			const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
			updateData({replicaList: storedListData.storedData, listStyleToc: storedListStyleToc.storedData}, 
				{storageKey: storageKey, fetchQueryParams: queryParams, type: REPLICALIST_STOREDLIST});
		} else {
			//console.log('FETCHING NEW LIST');
			const fetchData = getStoreValue({attributeKey: 'genericFetchData'});
			fetchData({storageKey: storageKey, fetchQueryParams: queryParams, queryParams: queryParams, type: REPLICA_LIST});
		}
	}
};
export {getReplicaList};
