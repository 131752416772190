import React, { useState, useEffect } from 'react';
import {jsonObjectsEqual} from "../utils/objectUtils";
import {useMountPostRender} from "./useMount";
import {generateStringifiedValue, isEmpty, isTrue} from "../utils/generalUtils";
import deepmerge from "deepmerge";
import {useAttributesChanged} from "./useAttributesChanged";
import {checkRequiredParams} from "../utils/getApiUrl";



/**
 * This hook will keep state for the calling module's fetch attributes.
 * Although it is somewhat generic, it is built to keep track of attributes
 * that are used for fetch and callback to fetch new data for the calling
 * module if the fetch attributes change
 *
 * If a fetch attribute value changes, it will call the callback, otherwise it will do
 * nothing
 *
 * Attribute "fetchOnInit" is a special property that should be passed in separately.
 * If this attribute is passed in and is false, the fetchData callback function
 * will NOT be called the first time the module is instantiated, no matter what
 * the other fetch attributes are.
 *
 * Attribute "forceFetch" is similar, but will force a fetch call even if nothing
 * changes.
 *
 * Note: This works together with initialModuleRender which keeps track of whether
 * this is the first time instantiation of this hook from an initial
 * module render.  This value is immediately set to false once it is used.
 *
 * There is no return value; the callback is called if the attributes
 * change from what has been previously stored and if the loggedIn state
 * is the same between the global isLoggedIn parameter and the isLoggedIn
 * attribute stored in the userAccess object.
 *
 * @param callback function to call back if state changes
 * @param params parameters for hook
 *     props: module props
 *        fetchOnInit: override queryParams change and force fetch on init of module
 *         forceFetch: override queryParams change and force fetch
 *     queryParams: queryParams used for fetch
 *     optional
 *         additionialTriggers: if there are anything that would trigger change but isn't a query parameter
 */
const useFetchAttributesChange = (callback, params) => {
	params = Object.assign({
		type: '',
		props: {},
		queryParams: {},
		additionalTriggers: {}
	}, params);
	const fetchOnInit = params.props.hasOwnProperty('fetchOnInit') ? isTrue(params.props.fetchOnInit) : true;
	const forceFetch = params.props.hasOwnProperty('forceFetch') ? isTrue(params.props.forceFetch) : false;

	const [attributes, setAttributes] = useState({});
	// keep track if this is/isn't the first time called from calling module; for fetchOnInit processing
	const [initialModuleRender, setInitialModuleRender] = useState(true);

	useMountPostRender(() => {
		setInitialModuleRender(false);  // after first render, set value to false
	});

	// merge additionalTriggers and queryParams then convert to string to avoid useEffect side effects
	const triggerAttributes = JSON.stringify(deepmerge(params.additionalTriggers, params.queryParams));
	const attributesChanged = !jsonObjectsEqual(triggerAttributes, attributes);
	const skipFetch = initialModuleRender && !fetchOnInit && !forceFetch;
	const validQuery = checkRequiredParams({type: params.type, queryParams: params.queryParams});
	// check if isLoggedIn state from globals matches the isLoggedIn state in userAccess object
	const isLoggedInStatus = isTrue(params.queryParams.isLoggedIn, {default: false});
	const userAccessIsLoggedInStatus = !isEmpty(params.props.userAccess) ? isTrue(params.props.userAccess.isLoggedIn, {default: false}) : false;
	const isLoggedInMatch = isLoggedInStatus === userAccessIsLoggedInStatus;

	// useEffect prevents clashes with React setState
	useEffect(() => {
		if (attributesChanged && isLoggedInMatch) {
			setAttributes(triggerAttributes);
			if (!isTrue(skipFetch) && isTrue(validQuery)) {  // call callback if attributes changed and not skipFetch
				callback({initialRender: initialModuleRender});
			}
		}
	}, [skipFetch, attributesChanged, callback, triggerAttributes, initialModuleRender, validQuery, isLoggedInMatch]);

};
export {useFetchAttributesChange};


/**
 * Keep track of the fetchInProgress flag that is set/unset in the list reducers.
 *
 * Check to see if the fetchInProgress flag changes.  We really care mostly that
 * fetchInProgress changes from true to false, which indicates that fetch is complete,
 * but this just looks for a change in the inProgress flag.  If the inProgress flag
 * changes, call the callback with the inProgress flag.
 *
 * Note: It is up to the calling module to decide how it handles the change.
 *
 * @param callback function callback if fetch progress changes
 *     progressFlag: fetch in progress true/falls
 * @param params
 *     requestInProgress: fetchInProgress from module props
 *     fetchInprogress: fetchInProgress from module state value
 */
const useFetchComplete = (callback, params) => {
	params = Object.assign({
		requestInProgress: false,
		fetchInProgress: false,
	}, params);

	const requestInProgress = isTrue(params.requestInProgress);  // can be true/false/undefined/null
	const fetchInProgress = isTrue(params.fetchInProgress);
	useAttributesChanged(() => {
		if (requestInProgress !== fetchInProgress) {
			callback(requestInProgress);
		}
	}, [requestInProgress, fetchInProgress]);

};
export {useFetchComplete};
