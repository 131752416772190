import { getStoreValue } from "../utils/storeValue";
import {storeInCache, retrieveFromCache} from "../utils/manageStoreCacheData";
import {Translate} from "../locales/locales";
import $ from 'jquery';
import { HIGHLIGHTS, HIGHLIGHTS_KEY } from "../modules/_MODULE_GLOBALS/constants";
import { isEmpty } from "./generalUtils";
import * as Tracking from "./tracking";

let Highlighter;
let highlighter;

let style;
let inRemove = false;
const location = {
	collectionUrl: '',
	issueUrl: '',
	articleId: '',
	articleTitle: ''
};
/*
button clicked
label - create highlight
color - red, yellow, blue 
module - articleViewer
type - highlight
location - article
url - url of article
article title -article title
*/
const trackHighlights= (params) => {
	params = Object.assign({
		"article title":""
	}, params);
	const label = params.label;
	const color = params.color;
	const trackingProperties = {
		label: label,
		module: 'articleViewer',
		type: "highlight",
		"article title": location.articleTitle,
		url: window.location.href,
		location: "article",
	};
	if (!isEmpty(color)) {
		trackingProperties.color = color;
	}
	Tracking.libraryTrack("button clicked", trackingProperties);

};

const saveHighlights = ({sources}) => {
	const {collectionUrl,issueUrl,articleId} = location;
	const highlights = [];
	sources.forEach((source) => {
		const highlight = {articleId: articleId, issueUrl: issueUrl, source: source};
		if (typeof style !== 'undefined') {
			highlight.style = JSON.stringify(style);
		}
		highlights.push(highlight);
	});
	if (highlights.length > 0) {
		const saveData = getStoreValue({attributeKey: 'genericSaveData'});

		let params = { 
			"method" : "POST",
			"type" : HIGHLIGHTS } ;
		saveData(highlights, params);
	}
};

const updateHighlight = ({sourceId,style}) => {
	const {collectionUrl,issueUrl,articleId} = location;
	let highlights = retrieveFromCache({dataKey:articleId, keyType:HIGHLIGHTS_KEY}).storedData;
	if (Array.isArray(highlights)) {
		highlights.forEach((highlight) => {
			if (highlight.sourceId === sourceId) {
				// update in client store
				highlight.style = JSON.stringify(style);
				const saveData = getStoreValue({attributeKey: 'genericSaveData'});
				let params = { 
					"method" : "PUT",
					"type" : HIGHLIGHTS } ;
				saveData(highlight, params);
			}
		});
	}
};

const removeHighlight = ({id}) => {
	const {collectionUrl,issueUrl,articleId} = location;
	//console.log("calling removeHighlight with id, articleId:" + id + "," + articleId);

	let highlights = retrieveFromCache({dataKey:articleId, keyType:HIGHLIGHTS_KEY}).storedData;

	let highlightsCopy = [...highlights];
	if (!Array.isArray(highlights)) {
		highlightsCopy = Object.values(highlights);
	}
	const deleteData = getStoreValue({attributeKey: 'genericDeleteData'});
	let deleteIdFound = false;
	highlightsCopy.forEach((highlight,index) => {
		if (id === highlight.sourceId) {
		//	console.log("delete id found");
			deleteIdFound = true;
			deleteData(highlight.id, {type : HIGHLIGHTS, "articleId" : articleId});
		}
	});
	if (!deleteIdFound) {
		//console.log(" we still did not find deleteid, perhaps deleting too soon");
		//we'll have to delete by source id which we don't have an api for yet
		//deleteData(id, {type : HIGHLIGHTS,  "articleId" : articleId});
	}
};

const displayHighlights = ({highlights, root})=>{

	if (!Array.isArray(highlights)) {
		highlights = Object.values(highlights);
	}
	highlights.forEach((h) => {
		const source = JSON.parse(h.source);
		if (h.style) {
			const style = JSON.parse(h.style);
			highlighter.setOption({style: style});
		}
		highlighter.fromStore(source.startMeta,source.endMeta,source.text,source.id);
	});
};

const retrieveHighlights = (articleId) => {
	const highlights = retrieveFromCache({dataKey:articleId, keyType:HIGHLIGHTS_KEY}).storedData;
	if (Array.isArray(highlights)) {
		const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
		updateData({"articleId":articleId,"highlights":highlights, "highlightsFetched": true}, {type: HIGHLIGHTS, storageKey: HIGHLIGHTS_KEY});
	} else {
		// if not in client store, get highlights from api
		let params = {"type": HIGHLIGHTS, 
			"queryParams": {"articleId": articleId},
			"articleId": articleId
		 };
		const fetchData = getStoreValue({attributeKey: 'genericFetchData'});
		fetchData(params);
	}
};
const getMenu = (menuType) => {
	const menu = document.createElement('ul');
	menu.className = 'menu-text-selection';
	//role="menu" aria-label="Study Tools Menu" tabindex="0"
	menu.setAttribute("role","menu");
	menu.setAttribute("aria-label", "Study Tools Menu");
	menu.setAttribute("tabindex", "0");
	const selectArialabel = "Highlight Selected Text";
	const updateArialabel = "Change Highlight Color";
	let ariaLabel = selectArialabel;
	if (menuType === "UPDATE") {
		ariaLabel = updateArialabel;
		menu.className += " menu-update-highlight";
	}
	let highlightHtml = '<li aria-label="' + ariaLabel + '"><div class="highlight-text">' +
	Translate.Text({id:'highlighter.menu.highlighter'}) +
	' ' +
	'<button class="highlight-color yellow" data-color="yellow"><span class="screen-reader">' +
	Translate.Text({id:'highlighter.menu.yellow'}) +
	'</span></button>' +
	'<button class="highlight-color blue" data-color="blue"><span class="screen-reader">' +
	Translate.Text({id:'highlighter.menu.blue'}) +
	'</span></button>' +
	'<button class="highlight-color red" data-color="red"><span class="screen-reader">' +
	Translate.Text({id:'highlighter.menu.red'}) +
	'</span></button>' + 
	'</div></li>'; 
	if (menuType === "UPDATE") {
		const removeHtml = 	'<li aria-label="Remove Highlight"><button class="highlight-text-remove">' +
		Translate.Text({id:'highlighter.menu.remove'}) +
		'</button></li>';
		highlightHtml += removeHtml;
	}
	menu.innerHTML = highlightHtml;
	return menu;
};

const setMenuPosition = (root, menu, rect) => {
	const parentWidth = root.clientWidth;
	const menuRect = menu.getBoundingClientRect();
	const borderOffset = 10;
	const menuWidth = menuRect.right - menuRect.left;
	const menuTop = rect.bottom - menuRect.top;
	let menuLeft = ((rect.left + rect.right) / 2 - 90) - menuRect.left;
	if (menuLeft + menuWidth + borderOffset > parentWidth) {
		menuLeft = parentWidth - menuWidth - borderOffset;
	}
	if (menuLeft < borderOffset) {
		menuLeft = borderOffset;
	}
	menu.style.top = menuTop + 'px';
	menu.style.left = menuLeft + 'px';
};

const openSelectionMenu = ({selection,root}) => {
	//console.log("opening selection menu");
	//close any other open menu
	closeMenu(root, true);
	//override the select
	let range = (selection ? selection.getRangeAt(0) : undefined);

	if (range === undefined || typeof range === "undefined") {
		return;
	}
	const rect = range.getBoundingClientRect();
	const menu = getMenu();
	root.appendChild(menu);

	// prevent menu reopening shenanigans
	$(menu).on('mouseup mousedown click touchstart touchend', (e) => {
		e.stopPropagation();
	});

	// position the menu under the selection
	//const myViewportWidth = window.innerWidth;
	setMenuPosition(root, menu, rect);

	// set click handlers on color swatches
	Array.from(menu.getElementsByClassName("highlight-color")).forEach((swatch) => {
		$(swatch).on('click', (e) => {
			//we are reading it again, as on mobile the user can drag 
			//and create a new selection
			const thisSelection = window.getSelection();
			const thisRange = (selection ? selection.getRangeAt(0) : undefined);
			if (thisSelection && !thisSelection.isCollapsed ) {
				selection = thisSelection;
				range = thisRange;
			}

			const colorClass = e.currentTarget.getAttribute("data-color");
			style = { className: colorClass };
			highlighter.setOption({style: style});
			if (range) {
				const newH = highlighter.fromRange(range);	
			} else {
				console.log("No range");
			}

			const trackingParams = { color: colorClass,
				label: "create highlight"
			};
			trackHighlights(trackingParams);
			// close menu
			closeMenu(root);

		});
		
	});

};

const openHighlightMenu = ({root,id}) => {
	//close any other open menu
	closeMenu(root, true);
	const {collectionUrl,issueUrl,articleId} = location;
	const dom = (id ? highlighter.getDoms(id).pop() : undefined);
	const menu = getMenu("UPDATE");
	root.appendChild(menu);

	// prevent menu reopening shenanigans
	$(menu).on('mouseup mousedown click touchstart touchend', (e) => {
		e.stopPropagation();
	});

	// position the menu centered under the highlight
	const rect = dom.getBoundingClientRect();
	setMenuPosition(root, menu, rect);
	
	// set click handlers on color swatches
	Array.from(menu.getElementsByClassName("highlight-color")).forEach((swatch) => {
		$(swatch).on('click', (e) => {
			const colorClass = e.currentTarget.getAttribute("data-color");
			style = { className: colorClass };
			highlighter.setOption({style: style});

			if (id) {
				highlighter.removeClass('yellow',id);
				highlighter.removeClass('blue',id);
				highlighter.removeClass('red',id);
				highlighter.addClass(colorClass,id);

				// update UPP and store
				updateHighlight({collectionUrl:collectionUrl,articleId:articleId,issueUrl:issueUrl,style:style,sourceId:id});
				const trackingParams = { color: colorClass,
					label: "update highlight"
				};
				trackHighlights(trackingParams);
			} else {
				console.log("No id");
			}

			// close menu
			closeMenu(root, true);

		});
		
	});

	Array.from(menu.getElementsByClassName("highlight-text-remove")).forEach((button) => {
		$(button).on('click', (e) => {
			highlighter.remove(id);

			// update UPP and store
			removeHighlight({collectionUrl:collectionUrl,articleId:articleId,issueUrl:issueUrl,id: id});
			const trackingParams = { 
				label: "remove highlight"
			};
			trackHighlights(trackingParams);
			// close menu
			closeMenu(root, true);

		});
	});

};

const closeMenu = (root, force) => {
	try {
		//when calling via timeout root is null
		//console.log("closemenu called with force:" + force);
		const menus = document.getElementsByClassName("menu-text-selection");
		Array.from(menus).forEach((menu) => {
			if (menu.classList.contains("menu-update-highlight") && !force) {
				return;
			}
			const menuParent = menu.parentElement;
			if (menuParent) {
				menuParent.removeChild(menu);
			}
		});
		/*
			Array.from(root.getElementsByClassName("menu-text-selection")).forEach((menu) => {
				root.removeChild(menu); 
			});
		*/
	} catch (e) {
		// dom has been invalidated, never mind
		console.log("oops");
	}
};

const initSelect = ({root}) => {
	$(root).off('mouseup.webHighlighter touchend.webHighlighter').on('mouseup.webHighlighter touchend.webHighlighter', (e) => {
		//close any menu(mobile is causing so much scattering of closemenu)
		//This closemenu is to satisfy the case where you tapped on a highlight
		//to open the highlight menu, but tapping anywhere else does not close it
		//There probably is a much better way to manage this
		if ( e.target instanceof HTMLSpanElement && e.target.hasAttribute("data-highlight-id")) {
			return;
		}
		setTimeout(function() {
			closeMenu(root, true);
			const selection = window.getSelection();
			if (selection && !selection.isCollapsed) {
				// don't open menu inside DataTable
				if ($(selection.anchorNode.parentElement).parents(".gtxcelDataTable").length === 0 && $(selection.focusNode.parentElement).parents(".gtxcelDataTable").length === 0) {
					// for devices that fire both touch and mouse events
					e.stopPropagation();
					// open menu
					openSelectionMenu({selection:selection,root:root});
				}
			} 
			//To close the menu, when the user clicks on the selected area, and 
			//the selection goes away, but the menu remains
			//called in a timeout so we can check if the selection
			//is empty
			if (!selection || selection.isCollapsed) {	
				closeMenu(root);
			}
		}, 100);
	});
	$(root).off('mousedown.webHighlighter touchstart.webHighlighter').on('mousedown.webHighlighter touchstart.webHighlighter', (e) => {
		if ( e.target instanceof HTMLSpanElement && e.target.hasAttribute("data-highlight-id")) {
			return;
		}	
		closeMenu(root);
	});
};


const newArticleHighlighter = ({root}) => {
	if (typeof highlighter !== 'undefined') {
		highlighter.dispose();
	}
	const highlighterOpts = { $root: root,
		wrapTag:'span',
		//exceptSelectors:['.blue','.red','.yellow']
	};
	highlighter = new Highlighter(highlighterOpts);
	highlighter.on('selection:create', ({sources,type}) => {
		if (type === 'from-input') {
			saveHighlights({sources:sources});
		}
	}).on('selection:click', ({id},h, e) => {
		if (id) {
			openHighlightMenu({root:root,id:id});
		}
	});
	initSelect({root:root});
	return highlighter;
};

const webHighlighter = ({articleId, articleTitle, issueUrl, root}) => {
	const fullCollectionUrl = getStoreValue({'attributeKey': 'fullCollectionUrl'});
	const urlParts = fullCollectionUrl.split("/");
	const collectionUrl = urlParts[urlParts.length - 1];

	// set location
	location.collectionUrl = collectionUrl;
	location.issueUrl = issueUrl;
	location.articleId = articleId;
	location.articleTitle = articleTitle;

	let articleHighlighter = null;
	// only import Highlighter on client side
	if (typeof Highlighter === 'undefined') {
		import('web-highlighter')
       			.then((module) => {
				Highlighter = module.default;
				articleHighlighter = newArticleHighlighter({root: root});
       			});
	} else {
		articleHighlighter = newArticleHighlighter({root: root});
	}
	if (articleHighlighter !== null ) {
		return articleHighlighter;
	}
};

export { webHighlighter , displayHighlights, retrieveHighlights };


