import React from 'react';
import {connect} from 'react-redux';
import {Translate} from "../../../locales/locales";
import $ from 'jquery';
import {ARTICLE_LIST_FILTER} from "../../_MODULE_GLOBALS/constants";
import { updateData } from '../../../store/actions';
import {getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import {isEmpty} from "../../../utils/generalUtils";


/**
 * Loop through the checkboxes in the filter list UI and capture the new state
 * of the checkboxes to pass to filters.
 *
 * @param event click event
 * @param props module props
 */
const handleCheckClicked = (event, props) => {
	const newFilters = {};
	$(event.currentTarget)
		.parents('.filter-checkboxes').find('input[type="checkbox"]')
		.each((index, checkbox) => {
			newFilters[checkbox.getAttribute('name')] = checkbox.checked;
		});
	props.updateData({issueNameFilters: newFilters}, {storageKey: props.storageKey});
};


export const ArticleListFilterModule = (props) => {
	const className = 'article-list-filter ' + props.active + ' ' + (props.className || '');

	const { issueNameFilters, issueNameFilterKeys } = props;
	/*
	* Generate jsx for filter list if displayOnDevice
	*/
	if (displayOnDevice(props)) {
		return (
			<div className={className}>
				<h2>{Translate.Text({id: "articleList.filter.title"})}</h2>
				<div>
					<ul className={"filter-checkboxes"}>
						{
							issueNameFilterKeys.map((selection, index) => {
								const keyValue = props.storeState + index.toString();
								return (
									<li key={keyValue}>
										<input type={"checkbox"} name={selection} value={selection} checked={issueNameFilters[selection]} onChange={(evt) => handleCheckClicked(evt, props)} />{selection}
									</li>
								);
							})
						}
					</ul>
				</div>
			</div>
		);
	} else {
		return null;
	}
};


/**
 * state returns the store state.
 * Map the store object you are using.
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {{filters: Array}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = props.storageKey ? props.storageKey : 'ArticleListFilter';
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};

	const filters = !isEmpty(storeState.filters) ? storeState.filters : {};
	const issueNameFilters = !isEmpty(filters.issueName) ? filters.issueName : {};
	const issueNameFilterKeys = Object.keys(issueNameFilters).sort();

	return {
		active: getActiveAttributeState(props),
		filters: filters,
		issueNameFilters: issueNameFilters,
		issueNameFilterKeys: issueNameFilterKeys
	};
};

/**
 * Actions that can be called by this module.  Each action is added to props so
 * that it can be called in the module, but defined in a single place with
 * appropriate parameters for the action call by this module.
 *
 * actions:
 *     updateData will simply add the data in the payload to the store
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		updateData: (payload, params) => {
			params.type = ARTICLE_LIST_FILTER;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
//	{ fetchData }
)(ArticleListFilterModule);

