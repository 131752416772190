import React from "react";
import {openInAppBrowser} from "../utils/nativeAppInterface";
import * as Tracking from "../utils/tracking";


/**
 * Generate the Download PDF button for an issue.
 * Handle the click on the button to open the PDF in a new window.
 *
 * @param params
 *     issueTitle: title of the issue
 *     pdfDownloadUrl: url location of the issue PDF
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateDownloadPDFButton = (params) => {
	params = Object.assign({
		issueDate: '',
		issueName: '',
		issueTitle: '',
		pdfDownloadUrl: '',
		isApp: false
	}, params);

	/**
	 * Handle click on PDF button.
	 *
	 * @param event click event
	 */
	const pdfButtonAction = (event) => {
		const trackingProperties = {
			"issue date": params.issueDate,
			"issue name": "_" + params.issueName,
			"label": params.issueTitle,
			"type": "download PDF button",
			"module": "article list",
			"destination type": "issue",
			"location": "modular library"
		};
		Tracking.libraryTrack("pdf downloaded", trackingProperties);
		if (params.isApp) {
			openInAppBrowser({type: 'open', url: params.pdfDownloadUrl + "&app=true&refurl=" + window.location.href});
		} else {
			window.open(params.pdfDownloadUrl);
		}
	};

	return (
		<div className={'download-pdf-wrapper'}>
			<button className={'download-pdf'}
				title={'Download ' + params.issueTitle + ' PDF'}
				onClick={pdfButtonAction}>
				<span className={'fas fa-file-pdf'}></span>
				<span className={'screen-reader'}>Download PDF</span>
			</button>
		</div>);
};

export default GenerateDownloadPDFButton;
