import React from "react";
import {manageUpdateAndNavigation} from "../modules/globals/globalNavigator";
import {populateFetchDataAttributes} from "../utils/populateFetchDataAttributes";
import {Translate} from "../locales/locales";
import * as Tracking from "../utils/tracking";
import {clone} from "../utils/objectUtils";


/**
 * Generate the issue button for an article.  Since an article is expected
 * to be contained in a single issue, this is expected to be a singleton.
 *
 * Handle the click on the button to open an issue articleList.
 *
 * @param params article params
 *     moduleProps: props from module
 *     articleId: selected article id
 *     articleTitle: title of selected article
 *     issueName: issue name from article
 *     issueUrl: issueUrl from article
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateIssueButton = (params) => {
	params = Object.assign({
		moduleProps: {},
		articleId: 0,
		articleTitle: '',
		issueName: '',
		issueUrl: ''
	}, params);


	/**
	 * Handle click on issue button.  Call navigation to go to correct module.
	 *
	 * @param event click event
	 */
	const issueButtonAction = (event) => {
		let issueProps = clone(params.moduleProps);
		// check the category clicked and reset the fetchQueryParams if present
		if (issueProps.hasOwnProperty('fetchQueryParams')) {
			issueProps.fetchQueryParams.issueUrl = params.issueUrl;
		} else {
			issueProps.fetchQueryParams = {};
		}
		issueProps.issueUrl = params.issueUrl;
		issueProps = populateFetchDataAttributes({
			props: issueProps,
			data: {issueUrl: params.issueUrl}  // issueUrl used by fetch/navigation
		});

		const trackingProperties = {
			"label": "_" + params.issueName,  // use the actual issue name for tracking
			"type": "issue button",
			"module": "article viewer",
			"article title": params.articleTitle,
			"destination type": "issue",
			"location": "modular library"
		};
		Tracking.libraryTrack("button clicked", trackingProperties);

		// NOTE: Set the articleList but NOT the articleId as we are sending the user to a document toc
		// and expect to scroll to the top of the destination toc issue articleList
		manageUpdateAndNavigation({
			navigationKey: 'issue',
			moduleProps: issueProps,
			attributes: {
				issueUrl: params.issueUrl,  // issueUrl used by fetch/navigation
				fetchQueryParams: issueProps.fetchQueryParams,
				articleList: issueProps.hasOwnProperty('articleList') ? issueProps.articleList : [],
				updateHistoryState: true
			}
		});
	};

	let fetchNavigationValue = '';
	if (params.moduleProps.hasOwnProperty('fetchNavigationKey') && (params.moduleProps.fetchNavigationKey === 'issue' || params.moduleProps.fetchNavigationKey === 'article')) {
		fetchNavigationValue = params.moduleProps.hasOwnProperty('fetchQueryParams') && params.moduleProps.fetchQueryParams.hasOwnProperty('issueUrl') ? params.moduleProps.fetchQueryParams.issueUrl : '';
	}
	const buttonClassName = params.issueUrl === fetchNavigationValue ? 'issue-button fetched-issue' : 'issue-button';
	const buttonTitle = params.moduleProps.overrideIssueButtonTitle ? Translate.Text({id: "article.issueButton.title"}) : params.issueName;

	return (
		<div className={'issue-button-wrapper'}>
			<button
				className={buttonClassName}
				data-issueurl={params.issueUrl}
				onClick={issueButtonAction}
			>
				{buttonTitle}
			</button>
		</div>);
};

export default GenerateIssueButton;
