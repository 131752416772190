import React from "react";
import {GenerateHtmlMarkup} from "../../../widgets/generateHtmlMarkup";
import getArticleAttribute from "../../../modules/library/articleViewer/manage_attributes/getArticleAttribute";
import {getExtractedElements} from "../../../modules/library/articleViewer/content_updaters/findElement";
import getSpecialAttribute from "../../../modules/library/articleViewer/manage_attributes/getSpecialAttribute";
import {clone} from "../../../utils/objectUtils";


/**
 * Attributes to extract from the article content and moved to a new position.
 * created as array, as order matters
 *
 * @type {({name: string, id: string, duplicate: boolean, element: string}|{prefix: {i18n: string}, name: string, class: string, element: string}|{name: string, element: string}|{name: string, element: string})[]}
 */
const elementsAttributes = [
	{name: 'figure', element: "figure", count: "all"}
];

/**
 * React code to generate html for "cover" template
 *
 * Note: figure/img definition in article body includes a "ref" attribute.  This is a bit of a
 * problem for React, so be careful if trying to modify the markup generation for the figure.
 *
 * @param params
 *     article article object
 *
 * @returns {JSX.Element}
 * @constructor
 */

const GenerateCoverArticle = (params) => {
	params = Object.assign({
		article: {},
		template: 'cover',
		moduleProps: {}
	}, params);
	// make a clone of article so we don't accidentally change anything for the caller
	const article = clone(params.article);
	const title = getArticleAttribute({article: article, attribute: "title"}).value;
	const issueName = getArticleAttribute({article: article, attribute: "issueName"}).value;
	const categories = getSpecialAttribute({article: article, attribute: "categories"}).value;
	const className = 'cover scrollContainer ' + categories;
	const titleIssue = title + ' - ' + issueName;

	// gets object of extracted elements and modified article content
	const extractedElements = getExtractedElements({article: article, elementsAttributes: elementsAttributes});

	return (
		<div data-templatename="Cover" className={className}>
			{/* v1 images become background images; v2 images added to coverImageContainer */}
			<GenerateHtmlMarkup tag={'div'} id={'coverImageContainer'} htmlMarkup={extractedElements.elements.figure} />
			<div className="articleBody screen-reader">
				<h1 className="cover-title">
					{titleIssue}
				 </h1>
				<GenerateHtmlMarkup tag={'article'} htmlMarkup={extractedElements.articleMarkup} />
			</div>
		</div>
	);

};
export default GenerateCoverArticle;
