import React from "react";
import {getStoreValue, setStoreValue} from "../utils/storeValue";


/**
 * Check to see if img src url starts with any of the following:
 *     http
 *     https
 *     //
 *
 * If so, then just return the src.  Else, add collectionUrl to start of relative
 * img src url.
 *
 * @param src original src
 * @returns {string} "fixed" src
 */
const updateImgPath = (src) => {
	src = src.trim();
	const matchHttp = src.match(/^(http:|https:|\/\/)/);
	if (matchHttp === null) {
		const collectionUrl = getStoreValue({'attributeKey': 'fullCollectionUrl'});
		src = collectionUrl.replace(/\/$/, '') + '/' + src.replace(/^\//, '');
	}
	return src;
};

/**
 * Generate html for an image given a set of image parameters.
 *
 * @param params
 *     imageProperties image properties to use for generating image html
 *     onClick function to call on click on image
 * @returns {*} DOM object
 */
const GenerateImage = (params) => {
	params = Object.assign({
		imageProperties: {},
		onClick: null
	}, params);
	const imageProperties = params.imageProperties ? params.imageProperties : {};
	const onClick = params.onClick ? params.onClick : null;
	const imgSrc = updateImgPath(imageProperties.src);
	const alt = imageProperties.hasOwnProperty('alt') ? imageProperties.alt : '';

	const optionalLinkAttributes = {};
	optionalLinkAttributes.target = imageProperties.hasOwnProperty('target') ? imageProperties.target : '_blank';
	if (imageProperties.hasOwnProperty('className')) {
		optionalLinkAttributes.className = imageProperties.className;
	}
	if (imageProperties.hasOwnProperty('title')) {
		optionalLinkAttributes.title = imageProperties.title;
	}
	const optionalImageAttributes = {};
	if (imageProperties.hasOwnProperty('styles')) {
		optionalImageAttributes.style = optionalImageAttributes.styles;
	}
	if (imageProperties.hasOwnProperty('data-folio')) {
		optionalImageAttributes["data-folio"] = imageProperties["data-folio"];
	}
	if (imageProperties.hasOwnProperty('data-folios')) {
		optionalImageAttributes["data-folios"] = imageProperties["data-folios"];
	}

	if (imageProperties.link !== false) {
		return (
			<a href={imageProperties.link} onClick={onClick} {...optionalLinkAttributes}>
				<img src={imgSrc} alt={alt} {...optionalImageAttributes} />
			</a>
		);
	} else {
		return (
			<img src={imgSrc} alt={alt} {...optionalImageAttributes} />
		);
	}
};
export default GenerateImage;

