import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {updateData} from '../../../store/actions';
import { Translate } from "../../../locales/locales";
import GenerateImage from "../../../widgets/generateImage";
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import {checkIfMyPane} from "../../globals/globalLibraryData";
import {STATIC_AD} from "../../_MODULE_GLOBALS/constants";
import $ from "jquery";
import * as Tracking from "../../../utils/tracking";
import {isEmpty} from "../../../utils/generalUtils";


/**
 * Static Ad Module
 *
 * This module is designed to allow for the display of an image given an image src,
 * which will be used to display and ad.
 * The ad can be a clickable link.
 * Tracking will be done for
 *     advertisement viewed - when the ad is updated on start or browser resize
 *     advertisement clicked
 *
 * All parameters are passed in through configuration options, so it will not have
 * any interaction with the store; so no fetch or update of store data.
 *
 */

/*
	list of potential configuration parameters; other than query params
		name: name of ad
		className: optional class name to add to div
		src: ad img src url
		alt: ad img alt text
		link: href link for ad
		imgHeight: inline style; height of image (better to set className)
		imgWidth: inline style; width of image (better to set className)
		target: optional <a> target (default: _blank)
 */

/**
 * Send a tracking call when the ad is viewed.
 *
 * @param adAttributes applicable attributes for ad; setup in module
 */
const adViewed = (adAttributes) => {
	const trackingProperties = {
		"module": "static ad",
		"ad name": adAttributes.adName,
		"location": adAttributes.location,
		"breakpoint": adAttributes.device,
		"height": adAttributes.imgHeight,
		"width": adAttributes.imgWidth
	};
	Tracking.libraryTrack("advertisement viewed", trackingProperties);
};

/**
 * Handle ad link clicked event.
 * Track event
 *
 * @param event click event
 * @param adAttributes applicable attributes for ad; setup in module
 */
const adClicked = (event, adAttributes) => {
	const $target = $(event.target);
	const $link = $target.closest('a');

	const trackingProperties = {
		"module": "static ad",
		"ad name": adAttributes.adName,
		"url": $link.attr('href'),
		"location": adAttributes.location,
		"breakpoint": adAttributes.device,
		"height": adAttributes.imgHeight,
		"width": adAttributes.imgWidth
	};
	Tracking.libraryTrack("advertisement clicked", trackingProperties);
};

/**
 * This will close the ad and regenerate the html without an ad being built.
 */
/**
 *
 * @param event click on close button
 * @param adAttributes applicable attributes for ad; setup in module
 */
const adClosed = (event, adAttributes) => {
	/* track close button click */
	const trackingProperties = {
		"type": adAttributes.trackingType,
		"module": "static ad",
		"ad name": adAttributes.adName,
		"location": adAttributes.location,
		"breakpoint": adAttributes.device,
		"label": "Close"
	};
	Tracking.libraryTrack("button clicked", trackingProperties);
};


export const StaticAdModule = (props) => {
	const [bannerClosed, setBannerClosed] = useState(false);
	const [adTrackedForShareable, setAdTrackedForShareable] = useState("");

	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: STATIC_AD,
		defaultClass: 'static-ad',
		configQueryParams: {}
	});


	// check and set image properties
	const imageProperties = {
		alt: props.alt ? props.alt : '',
		src: props.src ? props.src : '#',
		link: props.link ? props.link : false,
		target: props.target ? props.target : '_blank',
		styles: {
			height: props.adAttributes.imgHeight,
			width: props.adAttributes.imgWidth
		}
	};


	useEffect(() => {
		// special check since we don't want to track ad view if not displayed in pane
		if (props.isDisplayable && !bannerClosed) {
			if (adTrackedForShareable !== props.currentShareable) {
				//console.log("TRACKING", props.instanceId);
				adViewed(props.adAttributes);
				setAdTrackedForShareable(props.currentShareable);
			}
		}
		// Add functions that run every time the module is re-rendered
	}, [props.adAttributes, bannerClosed, adTrackedForShareable, props.isDisplayable, props.currentShareable]);

	const close = Translate.Text({id:'staticAd.close'});
	/*
	* Generate jsx for static ad if displayOnDevice
	* Call GenerateImage function to generate image for ad
	*/
	if (displayOnDevice(props) && !bannerClosed) {
		return (
			<div className={mops.className}>
				<div className={'banner-image'}>
					<GenerateImage imageProperties={imageProperties} onClick={(evt) => adClicked(evt, props.adAttributes)}/>
					{props.displayCloseButton ?
						<button
							className={'close-banner-ad'}
							onClick={(evt) => {
								adClosed(evt, props.adAttributes);
								setBannerClosed(true);
							}}
						>
							<icon><i className="fas fa-times-circle" /><span className={'sr-only'}>{close}</span></icon>
						</button> :
						''
					}
				</div>
			</div>
		);
	} else {
		return null;
	}
};


/**
 * Map state (store) data.
 *
 * storageKey provides a way to map a specific store object to a particular
 * instance of the module.  Add storageKey when configuring the module instance
 * if it needs to listen for a store change.
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
	const storageKey = props.storageKey ? props.storageKey : STATIC_AD;
	const instanceId = !isEmpty(props.instanceId) ? props.instanceId : '';
	const myPane = checkIfMyPane({moduleName: 'staticAd', instanceId: instanceId, storageKey: storageKey});
	const isDisplayable = myPane.isMyPane && displayOnDevice(props);
	const lastShareHistory = state.globals.currentShareable;
	return {
		adAttributes: {
			trackingType: props.trackingType ? props.trackingType : 'close ad',
			adName: props.adName ? props.adName : '',
			location: !isEmpty(props.location) ? props.location : "modular library",
			device: props.device ? props.device : '',
			imgHeight: props.imgHeight ? props.imgHeight : '',
			imgWidth: props.imgWidth ? props.imgWidth : ''
		},
		active: getActiveAttributeState(props),
		isDisplayable: isDisplayable,
		currentShareable: !isEmpty(lastShareHistory) ? lastShareHistory.shareHistoryKey : '',
		isMyPane: myPane.isMyPane,
		myPane: myPane,
	};
};

/**
 * simpleImage module (currently) makes to calls to fetch of update data.
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StaticAdModule);
