import {sha3_256} from "js-sha3";
import {generateStringifiedValue, isTrue} from "./generalUtils";
import moment from "moment/moment";

/**
 * Genearate a 32-bit hash from a string.  If not a string, convert to string
 * with JSON.stringify.
 *
 * @param string string to hash
 * @returns {number} hash value
 */
const generateHash = (string) => {
	string = typeof string === 'string' ? string : JSON.stringify(string);
	let hash = 0;
	for (let i = 0, len = string.length; i < len; i++) {
		let character = string.charCodeAt(i);
		hash = ((hash << 5) - hash) + character;
		hash = hash & hash; // Convert to 32bit integer
	}
	return hash;
};
export {generateHash};


/**
 * Generate a key value for stored data from the key value data passed in,
 * using SHA 256 hashing algorithm.
 *
 * Call generateStringifiedValue Value to handle different kinds of values,
 * including DOM nodes/elements.
 *
 * @param params
 *     keyValue: string or object to hash
 *     keyType: (optional) prefix for key generation for name-spacing; usually omitted
 * @returns {string}
 */
const generateHashKey = (params) => {
	params = Object.assign({
		keyValue: '',
		keyType: ''
	}, params);
	let keyValue = params.keyValue;
	if (typeof keyValue === 'undefined' || keyValue === null) {
		keyValue = '';
	} else if (typeof keyValue !== 'string') {
		keyValue = generateStringifiedValue(keyValue);
	}
	// if keyValue is already a HashKey, just return it
	if (keyValue.indexOf('HashKey_') === 0) {
		return keyValue;
	} else {
		const keyTypeKey = params.keyType !== '' ? params.keyType + '_' : '';
		return 'HashKey_' + keyTypeKey + sha3_256(keyValue);
	}
};
export {generateHashKey};


/**
 * Generate a simple string value that can be used as a (more or less) unique id.
 * Just use a simple character set plus the current time.
 *
 * @param params
 *     characters: character set to use; default - just letters
 *     maxLength: constrain the length of the identifier
 *     addedLength: if useTimestamp, then how many characters to add
 *     useTimestamp: add the timestamp at the end
 *     prefix: string to add to beginning
 * @returns {string}
 */
const generateSimpleId = (params) => {
	params = Object.assign({
		characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
		maxLength: -1,
		addedLength: 10,
		useTimestamp: true,
		prefix: ''
	}, params);
	let result = '';
	if (isTrue(params.useTimestamp)) {
		result = '_' + moment.now();
	}
	const maxLength = Number.isInteger(params.maxLength) && params.maxLength > 0 ? params.maxLength : -1;
	const addedLength = Number.isInteger(params.addedLength) ? params.addedLength : 10;
	const fullLength = params.prefix.length + addedLength;
	const charactersLength = params.characters.length;
	const addedCharactersLength = maxLength > fullLength ? maxLength - fullLength : addedLength;
	for (let i = 0; i < addedCharactersLength; i++ ) {
		result = params.characters.charAt(Math.floor(Math.random() * charactersLength)) + result;
	}
	console.log(params.prefix + result);
	return params.prefix + result;
};
export {generateSimpleId};
