import React from 'react';
import {connect} from 'react-redux';
import {SEARCH_FILTERS, SEARCH_ISSUES, SEARCH_PROGRESS, SEARCH_RESULTS} from "../../../_MODULE_GLOBALS/constants";
import {fetchData, updateData} from '../../../../store/actions';
import {Translate} from "../../../../locales/locales";
import GenerateTitle from "../../../../widgets/generateTitle";
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../../utils/moduleSetup";
import {getDefaultFilterValue, updateFilterOnChange} from "../searchUtils/filterUpdate";
import {useMountPostRender} from "../../../../hooks/useMount";
import {isEmpty, isTrue} from "../../../../utils/generalUtils";

const defaultSearchValue = "";


export const SearchIssuesModule = (props) => {
	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: SEARCH_RESULTS,
		defaultClass: 'search-issues',
		title: 'search.issues.title',
		titleTag: 'span',
		configQueryParams: {}
	});

	// runs only once on init
	useMountPostRender(() => {
		// list of issues is fetched in search/index.js in case this module is not instantiated
		// update filter value on startup
		props.updateData({searchIssuesFilter: props.searchIssueUrl}, {storageKey: props.storageKey});
	}, [props]);


	/*
	* Generate jsx for issues dropdown if displayOnDevice
	* Check to see if the issues array has been populated (it could be empty), which will happen
	* when the list of issues is fetched.  Before that, issues is set to null.
	* If null, then display message that list is being fetched.
	*/
	const {issues} = props;
	const keyValue0 = props.storeKey + "_0";
	if (displayOnDevice(props)) {
		return (issues === null) ?
			(
				<div className={mops.className}>
					<GenerateTitle titleParams={mops.titleParams} /><div className={"search-fetching-issues"}>{Translate.Text({id: 'search.issues.fetchingIssues'})}</div>
				</div>
			) :
			(
				<div className={mops.className}>
					<GenerateTitle titleParams={mops.titleParams} />
					<select
						aria-label={Translate.Text({id: 'search.issues.ariaLabel'})} defaultValue={props.defaultValue}
						onChange={(evt) => {
							const searchValue = evt.currentTarget.value.trim();
							updateFilterOnChange({props: props, searchValue: searchValue, searchReducer: 'searchIssuesFilter'});
						}}>
						>
						<option key={keyValue0} value={""}>{Translate.Text({id: 'search.issues.allIssues'})}</option>
						{
							issues.map((issue) => {
								return (
									<option key={issue.issueUrl} value={issue.issueUrl}>
										{issue.name}
									</option>
								);
							})
						}
					</select>
				</div>
			);
	} else {
		return null;
	}

};

/**
 * Map the store object you are using.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{defaultValue: (*|string), active: (*|string), storageKey: (*|string), issues: ([]|*), searchIssues: ([]|*|string)}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = props.storageKey ? props.storageKey : SEARCH_RESULTS;
	const storageState = state.hasOwnProperty(storageKey) ? state[storageKey] : {};
	const issues = state.hasOwnProperty('documentList') ? state.documentList : [];
	const searchFilters = storageState.searchFilters ? storageState.searchFilters : {};
	const searchFiltersValue = !isEmpty(searchFilters.issueUrl) ? searchFilters.issueUrl : '';

	// check if issueUrl is in the issues list
	const document = issues.find(issue => searchFiltersValue === issue.issueUrl);
	const searchIssueUrl = typeof document !== 'undefined' ? searchFiltersValue : defaultSearchValue;

	// get defaultValue for initial choice in generated dropdown
	const defaultValue = getDefaultFilterValue({
		props: props,
		defaultSearchValue: defaultSearchValue,
		searchFiltersValue: searchIssueUrl,
		forceFetch: isTrue(storageState.forceFetch)
	});

	return {
		storageKey: storageKey,
		active: getActiveAttributeState(props),
		searchFilters: searchFilters,
		searchIssueUrl: searchIssueUrl,
		defaultValue: defaultValue,
		issues: issues,
	};
};

/**
 * Actions that can be called by this module.
 *
 * actions:
 *     fetchData: call to get data from api call
 *     updateData: call to update the store with new properties
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		fetchData: (params) => {
			params.type = SEARCH_ISSUES;
			dispatch(fetchData(params));
		},
		updateData: (payload, params) => {
			params.type = SEARCH_FILTERS;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchIssuesModule);
