import {createStore} from 'redux';
import {gatheredLibraryReducers} from '../modules/_MODULE_GLOBALS/gatheredReducers';

import {applyMiddleware, compose} from 'redux';
import createSagaMiddleware from "redux-saga";
import baseSagas from './sagas/base';
import {isServer} from "../utils/generalUtils";
const isBrowser = typeof window !== 'undefined';
const storeEnhancers = (process.env.NODE_ENV !== 'production' && isBrowser && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;


/**
 * Build the store.
 *
 * Note that we are using reduce-reducers (from getheredLibraryReducers),
 * instead of combineReducers, which allows us to build a flat store and
 * access all part of the store from different reducers.
 * The base reducer builds the store, then passes the store state to subsequent
 * reducers included through reduce-reducers.
 * This ensures that every reducer gets the same store state as the base reducer.
 *
 * Currently, we have the capability to apply saga middleware, but don't actually
 * have any.  We are keeping the middleware functionality in case we want to
 * provide any; most likely some error catching for returned data.
 *
 */

let initialStoreState = (isBrowser && window.__PRELOADED_STATE__)?window.__PRELOADED_STATE__ : {};

if (isBrowser) {
	delete window.__PRELOADED_STATE__;
	// pure js construction that supports all browsers (including IE11)
	const stateElement = document.getElementById("store-preloaded-state");
	stateElement.parentNode.removeChild(stateElement);
}

function createNewStore (defaultState = {}) {
	const initializeSagaMiddleware = createSagaMiddleware();
	const store = createStore(
		gatheredLibraryReducers,
		defaultState,
		storeEnhancers(
			applyMiddleware(initializeSagaMiddleware)
		)
	);
	initializeSagaMiddleware.run(baseSagas);
	return store;
}
const store = createNewStore(initialStoreState);

export default store;
export {createNewStore as createStore};
