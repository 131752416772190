import getDeviceType from "../utils/getDeviceType";
import {getStoreValue, setStoreValue} from "../utils/storeValue";


/*
	All structures as strings - in anticipation of serving from server. Current structures:
		simple: default
		tabs
		leaderboard
		leaderboard-tabs

	NOTE: Each of the structures is defined as a multi-line string.
	If you add another structure, don't forget the backtick (`) character
	around your new structure string.

	IMPORTANT: Each container has a "class" property, with a name starting with "container",
	although this name is really just a convention to help you identify containers for module placement.
	The code WILL check to see if the div is a valid container before placing the defined
	module when generating the UI.

	NOTE: Only some nodes (ie. those named "container") can be targeted for module inclusion.

	Styling of the structures is done in the structures.scss file.  We map pure.css classes to our
	classes in that file.
	In addition to the classes in this file, device size classes (xl, lg, md, sm, xs) will be added
	to the structure top-level class (ie. "structure-leaderboard") and can be targeted with styles.
	For example:
		.structure-leaderboard.xl
		.structure-leaderboard.lg
		.structure-leaderboard.md
		.structure-leaderboard.sm
		.structure-leaderboard.xs

	Note: device size classes will also be added to the layout top-level
*/
const getStructure = (structureName) => {
	const structures = {
		"leaderboard": (`
			<div class="structure-leaderboard">
				<div class="container container-banner-top empty" role="complementary" aria-label="Top Advertisement"></div>
				<div class="container container-top-header empty"></div>
				<div class="structure-component-panes"></div>
				<div class="container container-footer empty" role="contentinfo" aria-label="Footer"></div>
				<div class="container container-banner-bottom empty" role="complementary" aria-label="Bottom Advertisement"></div>
			</div>
		`),
		"tabs": (`
			<div class="structure-tabs">
				<div class="container container-banner-top empty" role="complementary" aria-label="Top Advertisement"></div>
				<div class="container container-top-header empty"></div>
				<div class="structure-component-tabs"></div>
				<div class="structure-component-panes"></div>
				<div class="container container-footer empty" role="contentinfo" aria-label="Footer"></div>
				<div class="container container-banner-bottom empty" role="complementary" aria-label="Bottom Advertisement"></div>
			</div>
		`)
	};
	return (typeof structures !== 'undefined' && structures.hasOwnProperty(structureName)) ? structures[structureName] : structures.leaderboard;
};

export {getStructure};


/**
 * Return the current structure id (structureName) from the structures object based on the browser
 * device size.
 * Note: the structures object is generated on the server.
 *
 * @param device browser device size
 * @returns {string|*} structure id
 */
const getCurrentStructureId = (device) => {
	device = typeof device !== 'undefined' ? device : getDeviceType();
	const structures = getStoreValue({attributeKey: 'structures'});
	if (structures !== null && structures.hasOwnProperty(device)) {
		return structures[device].structureName;
	} else {
		return '';
	}
};
export {getCurrentStructureId};
