import {arrayRemoveDups, arraysConcatNoDup, arraysEqual} from "./arrayUtils";
import $ from "jquery";
import {isEmpty, isServer, isTrue} from "./generalUtils";
import cloneLib from 'clone';
import deepmerge from "deepmerge";
import {mergeStringsNoDup, stringsEqual} from "./stringUtils";


/**
 * Run a series of tests on an object to see if we can determine if it
 * is a plain object.
 * This is defined as an object created with {}
 *
 * Note: This is a pretty simple test, so won't catch everything, just to
 * allow us to do some basic checking.
 * Note2: On the client, we will simply use $.isPlainObject(obj) instead
 * of this function.
 *
 * @param obj object to check to see if it is just a plain object
 *     return false if:
 *         null or undefined
 *         not an object
 *         an array
 *
 * @returns {boolean} true: plain object; false: not matched as object
 */
const isPlainObject = (obj) => {
	let isPlainObject = false;
	if (typeof obj === 'undefined' || obj === null) {
		isPlainObject = false;
	} else if (isServer) {
		if (typeof obj === 'object') {
			// test only for array object; if not array, then set to true (is plain object)
			isPlainObject = Array.isArray(obj) === false;
		}
	} else {
		isPlainObject = $.isPlainObject(obj);
	}
	return isPlainObject;
};
export {isPlainObject};


/**
 * Compare the attributes from params with the stored attributes.
 * If all the keys and values are the same, then return true,
 * else return false.
 *
 * @param obj1 first object
 * @param obj2 second object
 * @returns {boolean} true: objects are the same
 */
const objectsEqual = (obj1, obj2) => {
	const obj1Keys = Object.keys(obj1);
	const obj2Keys = Object.keys(obj2);
	let objsEqual = true;
	if (!arraysEqual(obj1Keys, obj2Keys)) {
		objsEqual = false;
	} else {
		objsEqual = obj1Keys.every((key) => {
			const value1 = typeof obj1[key] === 'string' ? obj1[key].trim() : JSON.stringify(obj1[key]);
			const value2 = typeof obj2[key] === 'string' ? obj2[key].trim() : JSON.stringify(obj2[key]);
			return value1 === value2;
		});
	}
	return objsEqual;
};
export {objectsEqual};


/**
 * Given a string of JSON data, run JSON.parse to convert it to it's
 * javascript object.
 *
 * defaultObject: return value if defined otherwise set to {}
 *
 * Returns when jsonString is
 *     undefined: return defaultObject
 *     string: attempt to parse; return defaultObject on error
 *     object (but not null): return object
 *     null (else): return defaultObject
 *
 * @param jsonString string of json to convert
 * @param defaultObject optional return if not parse-able
 * @returns {object} object from parse
 */
const getObjectFromJSON = (jsonString, defaultObject) => {
	defaultObject = typeof defaultObject !== 'undefined' ? defaultObject : {};

	if (typeof jsonString === 'undefined') {
		return defaultObject;
	} else if (typeof jsonString === 'string') {
		// if nothing to parse, just return default
		if (jsonString === '') {
			return defaultObject;
		} else {
			try {
				return JSON.parse(jsonString);
			} catch (e) {
				console.error('could not parse the input JSON string: ' + jsonString);
				return defaultObject;
			}
		}
	} else if (typeof jsonString === 'object' && jsonString !== null) {
		return clone(jsonString);
	} else {
		return defaultObject;
	}
};
export {getObjectFromJSON};



/**
 * Loop through an object or array and try to generate a consistent order
 * of attributes to generate a string key.
 *
 * @param object
 * @returns {string}
 */
const generateStringKeyFromObject = (object) => {
	object = clone(object);
	let stringKey = '';
	if (typeof object === 'string') {
		stringKey += object;
	} else if (isPlainObject(object)) {
		const keys = Object.keys(object).sort();
		keys.forEach(key => {
			if (isPlainObject(object[key])) {
				stringKey += key.toString() + ':{' + generateStringKeyFromObject(object[key]) + '}';
			} else if (Array.isArray(object[key])) {
				stringKey += '[' + generateStringKeyFromObject(object[key]) + ']';
			} else {
				stringKey += (key.toString() + ':' + object[key].toString() + ' ');
			}
		});
	} else if (Array.isArray(object)) {
		object = object.sort();
		object.forEach(value => {
			if (isPlainObject(value)) {
				stringKey += value.toString() + ':{' + generateStringKeyFromObject(value) + '}';
			} else if (Array.isArray(value)) {
				stringKey += '[' + generateStringKeyFromObject(value) + ']';
			} else {
				stringKey += value.toString() + ' ';
			}
		});
	}
	return stringKey;
};
export {generateStringKeyFromObject};


/**
 * Compare the attributes for two json-stringified objects by comparing each of the
 * attributes in the object to each other.  If all the keys and values are the same,
 * then return true, else return false.
 *
 * @param jsonObj1 json string for object 1
 * @param jsonObj2 json string for object 2
 * @returns {boolean} true: objects are the same
 */
const jsonObjectsEqual = (jsonObj1, jsonObj2) => {
	const object1 = getObjectFromJSON(jsonObj1);
	const object2 = getObjectFromJSON(jsonObj2);
	return objectsEqual(object1, object2);
};
export {jsonObjectsEqual};


/**
 * Given a javascript object and an array of potential attributes in a hierarchy,
 * determine if there is an attribute with that name at each appropriate level.
 *
 * @param obj object to check; recursively call each level in obj
 * @param hierarchy array of attributes; remove each from front on recursive call
 * @param hasLeaf if all levels, up to and including leaf exist
 * @returns {*|boolean|boolean}
 */
const objectHasLeaf = (obj, hierarchy, hasLeaf) => {
	hasLeaf = typeof hasLeaf === 'boolean' ? hasLeaf : true;
	if (isPlainObject(obj) && Array.isArray(hierarchy)) {
		const topLevel = hierarchy.shift();
		hasLeaf = obj.hasOwnProperty(topLevel) && hasLeaf;
		if (hierarchy.length > 0 && hasLeaf) {
			return objectHasLeaf(obj[topLevel], hierarchy, hasLeaf);
		} else {
			return hasLeaf;
		}
	} else {
		return false;
	}
};
export {objectHasLeaf};


/**
 * Given an initialValue, that may or may not be a leaf value,
 * use the attributeMap to dive down the object hierarchy and
 * return the leaf value.
 *
 * @param initialValue object or leaf value
 * @param attributeMap hierarchy attribute list
 *     format: branch1.branch2.branch3.leaf
 * @returns {*} value or null if not found
 */
const getLeafValue = (initialValue, attributeMap) => {
	let leafValue = initialValue;
	const attributes = attributeMap.split('.');
	attributes.find((key) => {
		if (leafValue.hasOwnProperty(key)) {
			leafValue = leafValue[key];
			return false;
		} else {
			leafValue = null;
			return true;
		}
	});
	return leafValue;
};
export {getLeafValue};


/**
 * Given an object and a list of attributes that we want to remove,
 * remove them, then return the new object.
 * 
 * For example, given an object of query params and a list ["subscriberId", "sub_id"]
 * remove the attributes and return the new object.
 * 
 * @param obj object to remove attributes from
 * @param removeList list of attributes to remove
 * @returns {*} scrubbed object
 */
const removeObjectAttributes = (obj, removeList) => {
	if (isPlainObject(obj) && removeList.length > 0) {
		const cleanedObject = clone(obj);
		removeList.forEach(attr => {
			if (cleanedObject.hasOwnProperty(attr)) {
				delete cleanedObject[attr];
			}
		});
		return cleanedObject;
	} else {
		return obj;
	}
};
export {removeObjectAttributes};

/**
 * Given an object and a list of attributes that we want to keep,
 * keep those and remove everything else,then return the new object.
 *
 * For example, given an object of query params and a list ["subscriberId", "sub_id"]
 * remove the attributes and return the new object.
 *
 * @param obj object to remove attributes from
 * @param keepList list of attributes to keep
 * @returns {*} scrubbed object
 */
const keepObjectAttributes = (obj, keepList) => {
	if (isPlainObject(obj) && keepList.length > 0) {
		const cleanedObject = {};
		keepList.forEach(attr => {
			if (obj.hasOwnProperty(attr)) {
				cleanedObject[attr] = obj[attr];
			}
		});
	} else {
		return obj;
	}
};
export {keepObjectAttributes};


/**
 * Compare attributes with storedAttributes by key.
 * If all the keys and values are the same, then return true,
 * else return false.
 *
 * @param attributes from params
 * @param storedAttributes from the stored attributes
 * @returns {boolean} true: objects are the same
 */
const attributesEqual = (attributes, storedAttributes) => {
	const attributesObject = getObjectFromJSON(attributes);
	const storedAttributesObject = getObjectFromJSON(storedAttributes);
	return objectsEqual(attributesObject, storedAttributesObject);
};
export {attributesEqual};


/**
 * Given an object, find the first attribute pair in the object that matches
 * the *value* (not the key) and returns the key for the attribute.
 *
 * @param object js object with key/value pairs
 * @param value the value to match
 * @param options optional modifiers
 *     exact: use exact string match; default: usually set to false, ignore case
 *     trim: trim whitespace; default: trim whitespace from value
 * @returns {T} key matching the attribute
 */
function getKeyByValue(object, value, options) {
	options = Object.assign({
		exact: false,
		trim: true,
	}, options);
	const exactMatch = isTrue(options.ignoreCase, {defaultValue: false}); // default value false; ignore case
	const trim = isTrue(options.trim, {defaultValue: true});
	return Object.keys(object).find(key => stringsEqual(object[key], value, {trim: trim, exact: exactMatch}));
}
export {getKeyByValue};


/**
 * Marge two js objects of the same type, with no duplicates.  Merge cases
 *     both undefined: return null
 *     one defined: return the one that is defined
 *     types different: return the first
 *     string: merge, remove dups
 *     number: add together
 *     array: merge, remove dups
 *     object: deepmerge
 *
 * @param obj1 first js object, one of any of the types above
 * @param obj2 second js object, one of any of the types above
 * @returns {string|null|unknown} merged js objects of same type, no dups
 */
const mergeNoDups = (obj1, obj2) => {
	let obj1Type = typeof obj1;
	if (obj1Type !== 'undefined') {
		if (Array.isArray(obj1)) {
			obj1Type = 'array';
		} else if (isPlainObject(obj1)) {
			obj1Type = 'object';
		}
	}
	let obj2Type = typeof obj2;
	if (obj2Type !== 'undefined') {
		if (Array.isArray(obj2)) {
			obj2Type = 'array';
		} else if (isPlainObject(obj2)) {
			obj2Type = 'object';
		}
	}

	if (obj1Type !== 'undefined' && obj2Type === 'undefined') {
		return obj1;
	} else if (obj1Type === 'undefined' && obj2Type !== 'undefined') {
		return obj2;
	} else if (obj1Type !== obj2Type) {
		return obj1;
	} else if (obj1Type === 'string') {
		const obj1Array = obj1.trim().split(' ');
		const obj2Array = obj2.trim().split(' ');
		const merged = arrayRemoveDups(obj1Array.concat(obj2Array));
		return merged.join(' ').trim();
	} else if (obj1Type === 'number') {
		return obj1 + obj2;
	} else if (obj1Type === 'array') {
		return arraysConcatNoDup(obj1, obj2);
	} else if (obj1Type === 'object') {
		return deepmerge(obj1, obj2);
	} else {
		return null;
	}
};
export {mergeNoDups};


/**
 * Given a potentially partial key into an object, return the first value with
 * a key that matches the partial key.
 *
 * @param params
 *     obj: javascript object to search
 *     partialKey: partial key value
 *     default: default return value if object value not found
 * @returns {*}
 */
const getValueByPartialKey = (params) => {
	params = Object.assign({
		obj: {},
		partialKey: '',
		default: null
	}, params);
	if (isEmpty(params.obj) || isEmpty(params.partialKey)) {
		return params.default;
	}
	const fullKey = Object.keys(params.obj).find(key => key.includes(params.partialKey));
	return params.obj.hasOwnProperty(fullKey) ? params.obj[fullKey] : params.default;

};
export {getValueByPartialKey};


/**
 * Merge two objects where the values are strings and the strings are considered
 * to be a set of substrings that need to be preserved, and where the set of
 * substrings in each generated value for the key is unique (no dups).
 *
 * @param obj1 strings object 1
 * @param obj2 strings object 2
 * @returns {{}}
 */
const mergeStringsObject = (obj1, obj2) => {
	const mergedStringsObject = {};
	const obj1Keys = Object.keys(obj1);
	const obj2Keys = Object.keys(obj2);
	const allKeys = mergeNoDups(Object.keys(obj1), Object.keys(obj2));
	allKeys.forEach(key => {
		const str1 = !isEmpty(obj1[key]) ? obj1[key] : '';
		const str2 = !isEmpty(obj2[key]) ? obj2[key] : '';
		mergedStringsObject[key] = mergeStringsNoDup(str1, str2);
	});
	return mergedStringsObject;
};
export {mergeStringsObject};


const clone = (obj1) => {
	//const cloneMethod = cloneLib;// foolproof deep clone but not very fast
	const cloneMethod = myClone;// deep clone, twice as fast as cloneLib
	//const cloneMethod =jsonClone;// doesn't work for all objects
	//const cloneMethod = standardClone;// not a true clone as it copies references to objects, very fast

	const startTime = Date.now();
	const obj2 = cloneMethod(obj1);
	//const size = objectSize(obj1);
	const timeElapsed = Date.now() - startTime;
	if (timeElapsed > 10) {
		console.log("clone", timeElapsed);//, size);
	}
	return obj2;
};
export {clone};

/**
 * https://dev.to/shadid12/the-most-efficient-ways-to-clone-objects-in-javascript-1phe
 */
const myClone = (obj) => {
	if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj) {
		return obj;
	}

	let temp;
	if (obj instanceof Date) {
		temp = new obj.constructor(); 
	} else {
		temp = obj.constructor();
	}

	for (let key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			obj.isActiveClone = null;
			temp[key] = myClone(obj[key]);
			delete obj.isActiveClone;
		}
	}
	return temp;
};

const jsonClone = (obj) => {
	return JSON.parse(JSON.stringify(obj));
};

/**
 * A note on spread operator (...) and Object.assign():
 * If the source value is a reference to an object, it only copies the reference value.
 * 
 * const oldObj = {a: {b: 10}, c: 8};
 * const newObj = {...oldObj};
 * or
 * const newObj = Object.assign({},oldObj);
 * oldObj.a.b = 2;
 * oldObj.c =4;
 * oldObj  //{a: {b: 2}, c: 4}
 * newObj  //{a: {b: 2}, c: 8}
 */
const standardClone = (obj) => {
	if (Array.isArray(obj)) {
		return Object.assign([],obj);
	} else {
		return Object.assign({},obj);
	}
};

const cloneWithoutReplicaList = (props) => {
	const {replicaList, ...propsWithoutReplicaList} = props;
	const clonedProps = clone(propsWithoutReplicaList);
	clonedProps.replicaList=replicaList;
	return clonedProps;
};
export {cloneWithoutReplicaList};

const objectSize = (obj) => {
	return String(JSON.stringify(obj).length).replace(/(.)(?=(\d{3})+$)/g,'$1,');
};
export {objectSize};
