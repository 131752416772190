import {
	DATA_LOADED,
	DATA_REQUESTED,
	DATA_UPDATED,
	ID_NOT_SET,
	REPLICA_LIST, REPLICALIST_REPLICA,
	REPLICALIST_REPLICALIST,
	REPLICALIST_STOREDLIST,
} from "../../_MODULE_GLOBALS/constants";
import {storeInCache} from "../../../utils/manageStoreCacheData";
import {createNewStoreState} from "./generalReducerActions";
import {isEmpty} from "../../../utils/generalUtils";

/*
   This reducer fetches and updates replica and replica list attributes.
   Note: replica list is returned as an array of replica page objects as "pages"
 */

/**
 * List of potential replica and replicaList store properties for a module that
 * implements replica page and replica list display.
 *
 * This attempts to ensure that there is a placeholder in the store for
 * the values returned for the module.
 */
const createInitialState = ((storageKey) => {
	return {
		[storageKey]: {
			"page": '',
			"pageNumber": '',
			"folio": '',
			"replicaList": [],
			"listStyleToc": '',
			"issueUrl": '',

			"pageSpread": '',
			"zoomLevel": '',

			"documentId": '',
			"documentTitle": '',
			"documentUrl": '',
			"pdfDownloadUrl": '',

			"fetchListParams": {},
			"fetchQueryParams": null,
			"navigationAttributes": {},
			"fetchInProgress": false,

			"forceFetch": false,
			"replaceHistoryState": false,
			"updateHistoryState": true,

			"articleList": [],
			"articleId": ID_NOT_SET
		}
	};
});

/**
 * List of attributes that should be re-initialized each time a reducer gets called.
 *
 * @type {function(*): {}}
 */
const resetStoreState = ((storageKey) => {
	return {
		[storageKey]: {
			"replaceHistoryState": false,
			"updateHistoryState": true,
			"forceStoreUpdate": false,
		}
	};
});



function replicaReducers(state, action) {
	// check if module action call matches REPLICA_LIST; if not present, set default to REPLICA_LIST
	const moduleAction = (action.params && action.params.type) ? action.params.type : REPLICA_LIST;

	if (moduleAction === REPLICA_LIST && action.type === DATA_REQUESTED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'ReplicaList';

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});

		returnState[storageKey].fetchInProgress = true;

		return returnState;
	}

	if (moduleAction === REPLICA_LIST && action.type === DATA_LOADED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'ReplicaList';

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});

		// handle replicaList separately, as the return value is a list of pages
		const replicaList = Array.isArray(action.payload.pages) ? action.payload.pages : (!isEmpty(returnState[storageKey].replicaList) ? returnState[storageKey].replicaList : []);
		returnState[storageKey].replicaList = replicaList;
		// remove pages to keep size of returnState down
		delete(returnState[storageKey].pages);

		// cache return replicaList with fetchQueryParams object as the key
		storeInCache({dataKey: returnState[storageKey].fetchQueryParams, keyType: 'replicaPages', data: replicaList, overrideEmptyData: true, state: returnState});
		
		const listStyleToc = action.payload.listStyleToc;
		returnState[storageKey].listStyleToc = listStyleToc;
		storeInCache({dataKey: returnState[storageKey].fetchQueryParams, keyType: 'listStyleToc', data: listStyleToc, overrideEmptyData: true, state: returnState});
		
		returnState[storageKey].fetchInProgress = false;

		return returnState;
	}

	if (moduleAction === REPLICALIST_STOREDLIST && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'ReplicaList';

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});

		returnState[storageKey].replicaList = Array.isArray(action.payload.pages) ? action.payload.pages : (!isEmpty(returnState[storageKey].replicaList) ? returnState[storageKey].replicaList : []);
		// remove pages to keep size of returnState down
		delete(returnState[storageKey].pages);

		return returnState;
	}

	// Action called through clicking on replica page from replica list
	if (moduleAction === REPLICALIST_REPLICALIST && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'ReplicaList';

		// create new store state and update only changed attributes
		return createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});
	}

	if (moduleAction === REPLICALIST_REPLICA && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'replicaViewer';

		// create new store state and update only changed attributes
		return createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});
	}

	// if no matches, just return original state
	return state;
}
export default replicaReducers;
