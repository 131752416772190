import React, { useState } from 'react';
import {connect} from 'react-redux';
import {Translate} from "../../../locales/locales";
import $ from 'jquery';
import {TAGS_LOOKUP, ISSUE_FILTERS, ID_NOT_SET} from "../../_MODULE_GLOBALS/constants";
import { fetchData, updateData } from '../../../store/actions';
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import * as Tracking from "../../../utils/tracking";
import {getObjectFromJSON, clone} from "../../../utils/objectUtils";
import GenerateTitle from "../../../widgets/generateTitle";
import {manageUpdateAndNavigation} from "../../globals/globalNavigator";
import {useMountPostRender} from "../../../hooks/useMount";
import {isEmpty, getIntValue} from "../../../utils/generalUtils";
import {checkIfMyPane} from "../../globals/globalLibraryData";


// list of possible query params passed in through the configuration
const configQueryParams = {
	'publicationIds':'publicationIds',
};


/**
 * Toggle show/hide "Show All" button
 * @param maxButtons maximum number of buttons from configuration
 */
const toggleShowAll = (maxButtons) => {
	$('.radio-toolbar div').each(function(index) {
		// ignore elements excluded by maxButtons param
		if (index > (maxButtons - 1)) {
			$(this).delay(20*index).slideToggle(200);
		}
	});
};

/**
 * Call to update data from issue buttons and call to navigate to issue list page.
 * Pass in the selectedTagId to use to identify the issues to display and the
 * tagName for tracking.
 *
 * Note: This can be triggered on startup, so only trigger if isMyPane.
 *
 * @param params
 *     props module props
 *     selectedTagId tagId from click on tag choice
 *     tagName: name of tag
 */
const updateOnTagSelected = (params) => {
	params = Object.assign({
		props: {},
		selectedTagId: '',
		pageSize: 0,
		tagName: 'default',
		tagLabel: ''
	}, params);
	const {props} = params;
	const tagName = !isEmpty(params.tagName) ? params.tagName : 'default';
	const tagLabel = !isEmpty(params.tagLabel) ? params.tagLabel : '';
	if (props.isMyPane) {
		Tracking.libraryTrack("button clicked", {type: "issue group filter", tag: tagName});

		manageUpdateAndNavigation({
			navigationKey: 'filter',
			action: ISSUE_FILTERS,
			moduleProps: props,
			attributes: {
				selectedTag: {
					tagId: params.selectedTagId,
					tagName: tagName,
					tagLabel: tagLabel,
					pageSize: params.pageSize,
				},
			}
		});
	} else {
		props.updateSelectedTag(
			{
				selectedTag: {
					tagId: params.selectedTagId,
					tagName: tagName,
					tagLabel: tagLabel,
					pageSize: params.pageSize,
				}
			},
			{type: ISSUE_FILTERS, storageKey: props.storageKey});
	}
};


export const IssuesFilterModule = (props) => {
	const [isToggleOn, setIsToggleOn] = useState(false);
	const [currentTagId, setCurrentTagId] = useState(ID_NOT_SET);
	const [displayTags, setDisplayTags] = useState([]);

	const className = 'issues-filter ' + props.active + ' ' + (props.className || '');


	const mops = generateMops(props, {
		defaultKey: "issueListName",
		defaultClass: 'issue-tags',
		title: 'issuesFilter.title',
		titleTag: 'h2',
		configQueryParams: configQueryParams
	});

	useMountPostRender(() => {
		const validTags = [];
		if (!isEmpty(props.issueGroups) && !isEmpty(props.buttons)) {
			props.buttons.forEach(button => {
				if (button.group === '*') {
					validTags.push({
						tagId: -1,
						tagLabel: !isEmpty(button.label) ? button.label : 'issuesFilter.allTag',
						group: button.group,
						tagName: button.group,
						pageSize: getIntValue(button.pageSize, 0),
					});
				} else {
					const issueGroup = props.issueGroups.find(issueGroup => issueGroup.name === button.group);
					if (!isEmpty(issueGroup)) {
						validTags.push(
							Object.assign(issueGroup, {
								tagLabel: !isEmpty(button.label) ? button.label : button.group,
								tagName: issueGroup.name,
								group: button.group,
								pageSize: getIntValue(button.pageSize, 0),
							})
						);
					}
				}
			});
		}
		setDisplayTags(validTags);
		if (!isEmpty(validTags)) {
			const initialTag = validTags[0];
			if (initialTag.tagId !== currentTagId) {
				setCurrentTagId(initialTag.tagId);
				updateOnTagSelected({
					props: props,
					selectedTagId: initialTag.tagId,
					tagName: Translate.Text({id: initialTag.tagName}),
					tagLabel: Translate.Text({id: initialTag.tagLabel}),
					pageSize: getIntValue(initialTag.pageSize, 0),
				});
			}
		}
	});

	// show toggle only if props.maxButtons has a value
	// and the number of filter buttons exceeds that value
	const toggleClass = props.maxButtons && (displayTags.length > props.maxButtons) ? 'radio-toolbar-toggle' : 'radio-toolbar-toggle-hidden';
	const selectedTagId = getIntValue(props.selectedTag.tagId, ID_NOT_SET);
	/*
	* Generate jsx for filter Issues List if displayOnDevice
	* Generate the filters UI from the list of tags
	*/
	return !displayOnDevice(props) ? '' :
		(
			<div className={className}>
				<GenerateTitle titleParams={mops.titleParams} />
				<div className="radio-toolbar">
					{
						displayTags.map((tag, index) => {
							const tagLabel = Translate.Text({id: tag.tagLabel});
							const radioClass = props.maxButtons && (index >= props.maxButtons) ? "radio-hidden" : "";
							return (
								<div className={radioClass} key={tag.tagId}>
									<input
										id={tag.tagId}
										type="radio"
										name="tagId"
										value={tag.tagId}
										checked={tag.tagId === selectedTagId}
										onChange={(evt) => {
											const selectedTagId = parseInt(evt.currentTarget.value, 10);
											if (selectedTagId !== currentTagId) {
												updateOnTagSelected({
													props: props,
													selectedTagId: selectedTagId,
													tagName: tag.tagName,
													tagLabel: tagLabel,
													pageSize: getIntValue(tag.pageSize, 0),
												});
												setCurrentTagId(selectedTagId);
											}
										}}
									/>
									<label htmlFor={tag.tagId}>{tagLabel}</label>
								</div>
							);
						})
					}
				</div>
				<div className={toggleClass}>
					<button onClick={(evt) => {
						toggleShowAll(props.maxButtons);
						setIsToggleOn(!isToggleOn);
					}}>
						<span
							className={'filter-list-toggle'}>
							{isToggleOn ? Translate.Text({id: 'issuesFilter.close'}) : Translate.Text({id: 'issuesFilter.showAll'})}
						</span>
						<span
							className={isToggleOn ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
							title={isToggleOn ? Translate.Text({id: 'issuesFilter.upArrow'}) : Translate.Text({id: 'issuesFilter.downArrow'})} />
					</button>
				</div>
			</div>
		);
};


/**
 * state returns the store state.
 * Map the store object you are using.
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {{maxButtons: (number|boolean), active: (*|string), filters: *, selectedTag: (*|number), storageKey: (*|string), tags: (string|[]|string[]|[{description: string, title: string}]|{headTags: HtmlWebpackPlugin.HtmlTagObject[], bodyTags: HtmlWebpackPlugin.HtmlTagObject[]}|*|*[])}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = props.storageKey ? props.storageKey : "issueListName";
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};

	const myPane = checkIfMyPane({moduleName: 'issueList', instanceId: props.instanceId, storageKey: storageKey});

	return {
		storageKey: storageKey,
		active: getActiveAttributeState(props),
		buttons: getObjectFromJSON(props.buttons, []),
		issueGroups: !isEmpty(state.publicationIssueGroups) ? clone(state.publicationIssueGroups) : [],
		selectedTag: !isEmpty(storeState.selectedTag) ? storeState.selectedTag : {},
		maxButtons: getIntValue(props.maxButtons),
		isMyPane: myPane.isMyPane,
		myPane: myPane.myPane,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		fetchData: (params) => {
			params.type = TAGS_LOOKUP;
			dispatch(fetchData(params));
		},
		updateSelectedTag: (payload, params) => {
			params.type = ISSUE_FILTERS;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IssuesFilterModule);
