/*
	GLOBAL DEFINES

	Add properties that will be used by the library to get data.

 */
// TODO: Get rid of this import, API_URL,WEBSERVICES_SERVICE,libraryConfiguration.WEBSERVICES

import properties from './properties';
import React from "react";

const API_URL = properties.API_URL;
const SERVER_API_URL = '/gtxapi';
const WEBSERVICES_SERVICE = {
	CONTENT: '/content/v1.7',
	FILESTORE: '/filestore/v1.1',
	PUBLISHER: '/publisher/v1.4',
	VIDEO: '/video/v1.5',
	SETTINGS: '/settings/v1.3',
	EVENTS: '/events/v1.1',
	WORDPRESS: '/wp-json/wp/v2',
	WP_SYNDICATION: '/wp-json/wp/v2'
};
//Leaving the webservices in there for now.
//Add more action constants under server
const libraryConfiguration = {
	SERVER: {
		ARTICLE_LIST: SERVER_API_URL + '/articlelist',
		ISSUE_LIST: SERVER_API_URL + '/issuelist',
		ISSUE_NAMES: SERVER_API_URL + '/issuenamelist',
		SEARCH: SERVER_API_URL + '/search',
		ISSUE_TAGS : SERVER_API_URL + '/issuetags',
		IN_APP_PRODUCTS: SERVER_API_URL + '/products',
		PUBLICATION : SERVER_API_URL + '/publication',
		SUBSCRIBER : SERVER_API_URL + '/subscriber',
		PURCHASE : SERVER_API_URL + '/purchase',
		REPLICA_LIST : SERVER_API_URL + '/replicalist',
		ACCESS : SERVER_API_URL + '/access',
		HIGHLIGHTS :  SERVER_API_URL + '/user/highlightlist'
	},
	WEBSERVICES: {
		CONTENT: {
			ARTICLES: API_URL + WEBSERVICES_SERVICE.CONTENT + '/articles',
			ARTICLE_DETAILS: API_URL + WEBSERVICES_SERVICE.CONTENT + '/articleDetails',
			GROUPS: API_URL + WEBSERVICES_SERVICE.CONTENT + '/groups',
			ARTICLE_GROUPS: API_URL + WEBSERVICES_SERVICE.CONTENT + '/articleGroups',
			ARTICLE_CATEGORY_ATTRIBUTE_VALUES: API_URL + WEBSERVICES_SERVICE.CONTENT + '/articleCategoryAttributeValues',
			ARTICLE_CATEGORY_ATTRIBUTES: API_URL + WEBSERVICES_SERVICE.CONTENT + '/articleCategoryAttributes',
			ARTICLE_CATEGORIES: API_URL + WEBSERVICES_SERVICE.CONTENT + '/articleCategories',
			GROUP_CATEGORIES: API_URL + WEBSERVICES_SERVICE.CONTENT + '/groupCategories',
			GROUP_CATEGORY_ATTRIBUTES: API_URL + WEBSERVICES_SERVICE.CONTENT + '/groupCategoryAttributes',
			GROUP_CATEGORY_ATTRIBUTE_VALUES: API_URL + WEBSERVICES_SERVICE.CONTENT + '/groupCategoryAttributeValues',
			FEED_DATA: API_URL + WEBSERVICES_SERVICE.CONTENT + '/feedData',
			FEED_DETAILS: API_URL + WEBSERVICES_SERVICE.CONTENT + '/feedDetails',
			FEEDS: API_URL + WEBSERVICES_SERVICE.CONTENT + '/feeds',
			TAGS: API_URL + WEBSERVICES_SERVICE.CONTENT + '/tags'
		},
		FILESTORE: {
			FILES: API_URL + WEBSERVICES_SERVICE.FILESTORE + '/files',
			DIRECTORIES: API_URL + WEBSERVICES_SERVICE.FILESTORE + '/directories',
			VOLUMES: API_URL + WEBSERVICES_SERVICE.FILESTORE + '/volumes'
		},
		PUBLISHER: {
			PUBLISHERS: API_URL + WEBSERVICES_SERVICE.PUBLISHER + '/publishers',
			PUBLICATIONS: API_URL + WEBSERVICES_SERVICE.PUBLISHER + '/publications',
			PUBLICATIONS_ADMIN: API_URL + WEBSERVICES_SERVICE.PUBLISHER + '/publications/admin'
		},
		VIDEO: {
			VIDEOS: API_URL + WEBSERVICES_SERVICE.VIDEO + '/videos',
			TIERS: API_URL + WEBSERVICES_SERVICE.VIDEO + '/tiers',
			ACCOUNTS: API_URL + WEBSERVICES_SERVICE.VIDEO + '/accounts',
			PLAYERS: API_URL + WEBSERVICES_SERVICE.VIDEO + '/players'
		},
		NOTIFICATIONS: {
			PUSHNOTIFICATION: API_URL + WEBSERVICES_SERVICE.NOTIFICATIONS + '/pushNotifications',
			PUSHAPPLICATIONS: API_URL + WEBSERVICES_SERVICE.NOTIFICATIONS + '/pushApplications',
			TWCEVENT: API_URL + WEBSERVICES_SERVICE.NOTIFICATIONS + '/twcevent',
			TWCPUBLICATIONEVENT: API_URL + WEBSERVICES_SERVICE.NOTIFICATIONS + '/twcPublicationEvent'
		},
		SETTINGS: {
			SETTINGS: API_URL + WEBSERVICES_SERVICE.SETTINGS + '/settings',
			CONTENT: API_URL + WEBSERVICES_SERVICE.SETTINGS + '/appContents',
			APPCONTENTATTRS: API_URL + WEBSERVICES_SERVICE.SETTINGS + '/appContentAttributes',
			SETTINGS_DETAILS: API_URL + WEBSERVICES_SERVICE.SETTINGS + '/settingsDetails'
		}
	},

	DEFAULTS: {
		DEVICESIZEBREAKPOINTS: {xs: 0, sm: 568, md: 768, lg: 1024, xl: 1280, xxl: 1400},
		TARGETPRIORITY: ['articleViewer','replicaViewer','articleList','replicaList','libraryTarget','searchResults']
	}


};
export default libraryConfiguration;


const TestConfig = {
	DUMMY_DATA_ARTICLE_LIST_TESTING: 'https://jsonplaceholder.typicode.com/posts',
	DUMMY_DATA_ARTICLE_LIST_QA8: libraryConfiguration.WEBSERVICES.CONTENT.ARTICLE_DETAILS + '?publicationId=43&apikey=VgjkuRYcUGPHQ7Gihfy5sAKSmEkK7wVusKYM9XsdZlO7RMbT2s',
};
export {TestConfig};


const ExternalUrl = {
	PLACEHOLDER: {
		GTXCEL_LIBRARY: 'https://via.placeholder.com/172/0054AD/FFFFFF?text=GTxcel Library',
		LANDSCAPE_THUMBNAIL_SMALL: 'https://via.placeholder.com/444x300/?text=Upload%20a%20Landscape%20Thumbnail%20image',
		LANDSCAPE_THUMBNAIL_MEDIUM: 'https://via.placeholder.com/630x300/?text=Upload%20a%20Landscape%20Thumbnail%20image',
		PORTRAIT_LARGE: 'https://via.placeholder.com/375x510/?text=Upload%20an%20Extra%20Large%20Portrait%20image',
		PORTRAIT_EXTRA_LARGE: 'https://via.placeholder.com/1440x500/?text=Upload%20an%20Extra%20Large%20Landscape%20image',
	}
};
export {ExternalUrl};
