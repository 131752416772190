/*
	Locale javascript.
	Takes multiple language files for each supported language and returns
	in a single object for locales.

	For use by eo-locale
	https://www.npmjs.com/package/eo-locale

	Each language file can be constructed in a hierarchical format to make it
	more readable and easier to construct.  This file flattens the hierarchy
	to produce a dotted key for use as an id when specifying string.

	Custom Language strings can be created and stored in settings through the
	Library Configuration dashboard.  These are passed to the LocaleStrings
	function from the caller (populateLayout.jsx).

	Example:

	ORIGINAL
	"first": "this is the first string"
	"levelA": {
		"second": "this is the second string"
		"levelB": {
			"levelC": {
				"third": "this is the third string"
			}
		}
	}

	FLATTENED
	"first
	"levelA.second": "this is the second string",
	"levelA.levelB.levelC.third": "this is the third string"

	USE EACH
	id="first"
	id="levelA.second"
	id="levelA.levelB.levelC.third"


	In the UI, a normal call to get a string and populate in the UI would call Translate.Text,
	passing in the id for the string
		Translate.Text({id: "search.display.inProgress"})

	If this is a special call and Translate should return html, add that as a parameter
		Translate.Text({id: "search.display.title", html: true})

	If there are replacement parameters, pass those as an object of replacement values
		Translate.Text({id: "search.display.title", html: true, replacements: {total: 100, display: 20}})
		note: replacements in the string are encoded with {} around the replacement string
			"title": "<span class=\"search-display-total\">{total}</span><span class=\"search-display-searching\">--</span> Results Found for <span class=\"search-display-entry\">{entry}</span>",

 */

import React from "react";
import {getStoreValue} from "../utils/storeValue";
import {clone} from '../utils/objectUtils';
import {isEmpty} from "../utils/generalUtils";


/**
 * Check whether the i18n strings contains the desired string key.
 *
 * @param key string key
 * @returns {boolean}
 *     true: has the key
 *     false; does not have property with key
 */
const stringsHasKey = (key) => {
	const i18nStrings = getStoreValue({'attributeKey':'i18nStrings'});
	return isEmpty(i18nStrings) ? false : i18nStrings.hasOwnProperty(key);
};
export {stringsHasKey};


/**
 * Check to see if the alternative language strings file contains the desired string key.
 *
 * @param key
 * @returns {boolean|boolean}
 *     true: has the key
 *     false; does not have property with key
 */
const stringsHasKeyFallback = (key) => {
	const i18nStringsFallback = getStoreValue({'attributeKey':'i18nStringsFallback'});
	return isEmpty(i18nStringsFallback) ? false : i18nStringsFallback.hasOwnProperty(key);
};
export {stringsHasKeyFallback};


/**
 * Given a set of translation parameters, find the string in the full
 * i18nStrings object, given a key into the i18nStrings.
 *
 * If optional replacements are passed in, then replace the corresponding
 * sections in the string with the replacement value passed in.
 *
 * This returns a string and can't be used to generate html.  For that,
 * call TranslatedText with html=true.
 *
 * @param params
 *     id: key for string in strings file
 *     replacements: (optional) replacement fragments, of format:
 *         {key: value}
 * @returns {*}
 */
const translatedString = (params) => {
	const key = params.id;
	const replacements = typeof params.replacements !== 'undefined' ? params.replacements : {'collectionTitle':getStoreValue({'attributeKey':'collectionTitle'})};
	let i18nStrings = getStoreValue({'attributeKey':'i18nStrings'});
	i18nStrings = i18nStrings === null ? {} : i18nStrings;
	let i18nStringsFallback = getStoreValue({'attributeKey':'i18nStringsFallback'});
	i18nStringsFallback = i18nStringsFallback === null ? {} : i18nStringsFallback;
	if (stringsHasKey(key)) {
		let translation = i18nStrings[key];
		Object.keys(replacements).forEach(replaceKey => {
			const replacement = new RegExp('{'+replaceKey+'}');
			translation = translation.replace(replacement, replacements[replaceKey]);
		});
		return translation;
	} else if (stringsHasKeyFallback(key)) {
		let translation = i18nStringsFallback[key];
		Object.keys(replacements).forEach(replaceKey => {
			const replacement = new RegExp('{'+replaceKey+'}');
			translation = translation.replace(replacement, replacements[replaceKey]);
		});
		return translation;
	} else {
		return key;
	}
};
export {translatedString};


/**
 * Given a set of translation parameters, call translatedString with the
 * params.
 *
 * if one of the parameters is html=true, then generate the string as html
 * and pass back.
 *
 * @param params translation parameters
 * @returns {*}
 * @constructor
 */
const TranslatedText = (params) => {
	const textParams = clone(params);
	const returnHtml = (textParams.hasOwnProperty('html') && (textParams.html === true || textParams.html === 'true'));
	const tag = textParams.hasOwnProperty('wrapper') ? textParams.wrapper : 'span';
	const tagAttributes = {};
	if (textParams.hasOwnProperty('className')) {
		tagAttributes.className = textParams.className;
	}

	delete textParams.html;
	delete textParams.wrapper;
	delete textParams.className;

	let translation = translatedString(textParams);

	if (returnHtml) {
		const GeneratedTag = `${tag}`;
		const GenerateMarkup = (translation) => {
			return { __html: translation };
		};
		return <GeneratedTag {...tagAttributes} dangerouslySetInnerHTML={GenerateMarkup(translation)} />;
	} else {
		return translation;
	}
};
export {TranslatedText};


// Return Translate object with two functions
export const Translate = {
	string: translatedString,
	Text: TranslatedText,
	hasKey: stringsHasKey
};
