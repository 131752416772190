import React from "react";
import parseHtmlToReact from 'html-react-parser';
import {clone} from "../utils/objectUtils";
import {isEmpty} from "../utils/generalUtils";
import {useModal} from '../hooks/useModal';
import { getNodeHtml} from "../utils/htmlUtils";
import $ from 'jquery';
import {updateFootnoteReference} from "../modules/library/articleViewer/content_updaters/updateFootnoteReference";



/**
 * Generate html from html text, including optional class
 * If no html markup is passed in, simply return empty string
 * This is a jsx call, so it must be capitalized to add the DOM
 * object into the caller.
 *
 * WARNING: Do NOT use this for user input content or anything
 *     that might be suspicious.  There is a reason it is called
 *     dangerouslySetInnerHTML...
 *
 * WARNING: This function allows you to specify the html to set without
 *     specifying a wrapper, id, or class
 *     but... USE WITH CAUTION
 *     This will bypass the usual React dangerouslySetInnerHTML mechanism
 *
 * @param params
 *     htmlMarkup html markup to generate
 *     className optional class name to apply
 * @returns {*} DOM object
 */
const GenerateHtmlMarkup = (params) => {
	params = Object.assign({
		htmlMarkup: ''
	}, params);
	const htmlMarkup = typeof params.htmlMarkup === 'string' ? params.htmlMarkup : '';
	// remove markup string so it doesn't get added as an attribute to generated tag
	const props = clone(params);

	if (props.htmlMarkup) {
		delete props.htmlMarkup;
	}
	// check if a tag has been passed in; if so, use it
	// if not, check if any other attribute has been set; if so, use 'div'; otherwise use ''
	const tag = props.hasOwnProperty('tag') ? props.tag :
		(Object.keys(props).length === 0) ? '' : 'div';
	delete props.tag;

	const GeneratedTag = `${tag}`;
	const GenerateMarkup = () => {
		return { __html: htmlMarkup };
	};

	if (htmlMarkup === '') {
		return '';
	} else if (isEmpty(tag)) {
		// This will just return React markup without a wrapper; see warning above
		return parseHtmlToReact(htmlMarkup);
	} else {
		return <GeneratedTag {...props} dangerouslySetInnerHTML={GenerateMarkup()} />;
	}
};
export {GenerateHtmlMarkup};


/**
 * Generate the article body content for an article, allowing for special processing
 * of the article content.
 * If there is no special processing to be done, just calls the generic GenerateHtmlMarkup function.
 *
 * Currently implemented special processing
 *    If footnoteReference is defined in the markup, add an appropriate onClick handler to the <a> tag
 *
 * @param params
 *     htmlMarkup: html markup to generate
 *     optional parameters
 * @returns {JSX.Element|ReturnType<typeof domToReact>}
 * @constructor
 */
const GenerateArticleHtmlMarkup = (params) => {
	params = Object.assign({
		htmlMarkup: ''
	}, params);
	const {toggle} = useModal();  // modal dialog hook

	if (typeof params.htmlMarkup === 'string' && params.htmlMarkup.includes('footnoteReference')) {
		const htmlMarkup = updateFootnoteReference({articleMarkup: clone(params.htmlMarkup)});
		// This will return React markup without a wrapper; see warning in GenerateHtmlMarkup
		return parseHtmlToReact(htmlMarkup, {
			replace: domNode => {
				// just using jquery for parsing; find "footnoteReference" in parents
				const isFootnoteReferenceAddress =
					!isEmpty(domNode) &&
					domNode.name === 'a' &&
					$(domNode).parents().filter((index, el) => el.name === "sup" && el.attribs.class === "footnoteReference").length > 0;

				if (isFootnoteReferenceAddress) {
					const innerHtml = getNodeHtml(domNode, {defaultValue: '#'});
					const href = domNode.attribs.href;
					const dataHref = domNode.attribs['data-href'];
					delete domNode.attribs.onclick;
					delete domNode.attribs.href;
					delete domNode.attribs['data-href'];
					return (
						<a {...domNode.attribs}
						   onClick={(e) => {
							   e.preventDefault();
							   e.stopPropagation();
							   e.nativeEvent.stopImmediatePropagation();
							   if (typeof dataHref === "undefined") {
								   toggle("FOOTNOTE", {footnoteHref: href});
							   } else {
								   toggle("FOOTNOTE", {footnoteHref: dataHref});
							   }
						   }}
						>
							{parseHtmlToReact(innerHtml)}
						</a>
					);
				}
			}
		});
	} else {
		return <GenerateHtmlMarkup {...params} />;
	}
};
export {GenerateArticleHtmlMarkup};
