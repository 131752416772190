import {retrieveFromCache} from "../manageStoreCacheData";
import { SEARCH_RESULTS, SEARCHRESULTS_STOREDRESULTS } from "../../modules/_MODULE_GLOBALS/constants";
import {getStoreValue} from "../storeValue";
import {isEmpty} from "../generalUtils";


/**
 * Called by the search module to get search results
 *
 * This function will attempt to get a stored set of search results from hash storage.
 * If it finds results with a key that matches the query parameters, it will just call
 * updateData to return the results without fetch.  If not, it will call fetch to get
 * a new set of search results.
 *
 * Note: This will use the stored, generic updateData and fetchData and just pass the
 * appropriate parameters.  This will trigger the appropriate reducer in the search.js
 * reducer file, which the appropriate module (identified by storageKey) will use to
 * determine if the results are for its use.
 *
 * @param params module parameters
 *     storageKey: instance key
 *     queryParams: query parameters for module
 *     returnStoredDataOnly: don't perform fetch, even if no stored data found
 */
const getSearchResults = (params) => {
	params = Object.assign({
		storageKey: '',
		queryParams: {},
		returnStoredDataOnly: false
	}, params);
	const storageKey = !isEmpty(params.storageKey) ? params.storageKey : '';
	const queryParams = !isEmpty(params.queryParams) ? params.queryParams : {};

	const storedData = retrieveFromCache({dataKey: queryParams, keyType: 'searchResults', emptyDataObject: []});

	if (params.returnStoredDataOnly) {
		return {searchResults: storedData.storedData, fetchInProgress: false};
	} else {
		if (storedData.hasStoredData) {
//		console.log('No FETCH');
			const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
			updateData({searchResults: storedData.storedData, forceProgressDone: true}, {
				storageKey: storageKey,
				fetchQueryParams: queryParams,
				type: SEARCHRESULTS_STOREDRESULTS
			});
			return {searchResults: storedData.storedData, fetchInProgress: false};

		} else {
//		console.log('FETCHING NEW SET OF SEARCH METADATA');
			const fetchData = getStoreValue({attributeKey: 'genericFetchData'});
			fetchData({
				storageKey: storageKey,
				fetchQueryParams: queryParams,
				queryParams: queryParams,
				type: SEARCH_RESULTS
			});
			return {searchResults: [], fetchInProgress: true};

		}
	}
};
export {getSearchResults};
