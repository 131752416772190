import $ from 'jquery';

/**
 * Generates strings from the text content of an h2 element
 *
 * @param $h2 jQuery wrapper of article section header
 * @returns {{}} object containing generated strings
 */
const generateArticleSectionStrings = ($h2) => {

	// clone the element so this process is non-destructive
	const $header = $h2.clone();

	// create an identifier by escaping unwanted characters from the header content string
	let sectionTitle = $header.text().trim();
	const section = sectionTitle.replace(/[\s:?#]/g,"_");

	// separate the section title from the section number `span` element if it exists
	let sectionNumber = '';
	const $sectionNumber = $header.find('.section-number');

	if ($sectionNumber.length > 0) {
		sectionNumber = $sectionNumber.text();
		$header.find('.section-number').remove();
		sectionTitle = $header.text().trim();
	}

	const results = {
		section: section,
		sectionNumber: sectionNumber,
		sectionTitle: sectionTitle
	};

	return results;
};
export {generateArticleSectionStrings};
