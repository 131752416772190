import $ from "jquery";
import {getArticleExternalLinks} from "./findElement";

/**
 * Given a full-page ad article, find the data-link attribute for the article.
 * Then, find the add image (figure) and wrap the image in an a tag with the
 * data link as the href.
 *
 * @param articleMarkup html markup for an article
 * @returns {*} the modified html markup
 */

const updateArticleFullPageAdLink = (articleMarkup) => {
	const $wrapper = $('<div />').append(articleMarkup);
	const externalLinks = getArticleExternalLinks({articleMarkup: articleMarkup, linkElementTag: 'div', linkAttribute: 'data-link', attributeType: 'data'});
	const imageLinks = getArticleExternalLinks({articleMarkup: articleMarkup, linkElementTag: 'figure a', linkAttribute: 'href', attributeType: 'data'});

	// link on ad image should override data-link
	if (imageLinks.length > 0) {
		const $adImg = $wrapper.find('.fullPageAd .fullPageAdImages figure a img');
		$adImg.unwrap();
		externalLinks.unshift(imageLinks[0]);
		
	}

	// we are calling a generic routine, just use the first one for full page ad
	if (externalLinks.length > 0) {
		const externalLink = externalLinks[0];
		const $adFigure = $wrapper.find('.fullPageAd .fullPageAdImages figure');
		$adFigure.wrap('<a class="full-page-ad-link" data-link="' + externalLink + '" target="_blank" href="' + externalLink + '" />');
	}

	// return the article content html as a string
	return $wrapper.html();
};

export {updateArticleFullPageAdLink};
