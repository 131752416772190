import {getModuleContainer} from "../modules/globals/globalLibraryData";
import $ from 'jquery';
import {getStoreValue, setStoreValue} from "../utils/storeValue";
import {ABOVE, BELOW, MIDDLE, ID_NOT_SET} from "../modules/_MODULE_GLOBALS/constants";
import {retrieveFromCache} from "../utils/manageStoreCacheData";
import getDeviceType from "../utils/getDeviceType";
import {getStoredScrollData, storeGlobalModuleData, getStoredGlobalModuleData} from "../modules/globals/globalLibraryData";
import {isEmpty, isTrue} from "../utils/generalUtils";


/**
 * Check where the element is in relation to the visible area of the parent scrollable
 * element.  If the bottom top of the element is below
 *
 * If the element bottom is off the top, set to "above"
 * if the element top is below the bottom, set to "below:
 * else assume that the element is (mostly) visible in the viewport and set to "middle"
 *
 * @param params
 *     layoutContainerAttributes: scroll attributes of scrollable container
 *     articleElementAttributes: scroll attributes of element
 * @returns {string}
 */
const getElementPosition = (params) => {
	params = Object.assign({
		layoutContainerAttributes: {},
		articleElementAttributes: {}
	}, params);
	const elementTop = params.articleElementAttributes.top;
	const elementBottom = params.articleElementAttributes.top + params.articleElementAttributes.height;
	const containerTop = params.layoutContainerAttributes.scrollTop;
	const containerBottom = params.layoutContainerAttributes.scrollTop + params.layoutContainerAttributes.height;
	if (elementBottom <= containerTop) {
		return ABOVE;
	} else if (elementTop >= containerBottom) {
		return BELOW;
	} else {
		return MIDDLE;
	}
};


/**
 * Calculate the actual values for the layout container (scrollable container) and
 * the position values for the element within the container.
 * Calculate the scroll mid-point of the element and return as point to scroll to
 * if required.
 *
 * Call to find where the element is in relation to the viewport of the scrollable container
 * with the call to getElementPosition.
 *
 * @param params
 *     $listArticleElement: article element in list
 *     $layoutContainer: scrollable container for article
 *     useWindow: if we want to scroll the whole window (mobile devices)
 *     scrollToLastPosition: if we want to use stored scroll position (usually false)
 *     module: module as the target
 *     storageKey: storage key for the module
 *
 * @returns {{layoutContainerAttributes: {top: *} & {width: number, scrollHeight: number, scrollTop: number, height: number}, articleElementAttributes: {top: number, height: number}, scrollTo: number}}
 */
const checkIfOffScreen = (params) => {
	params = Object.assign({
		$listArticleElement: $(),
		$layoutContainer: $(),
		useWindow: false,
		scrollToLastPosition: false,
		module: 'articleList',
		storageKey: ''
	}, params);
	const $listArticleElement = params.$listArticleElement;
	const $layoutContainer = params.$layoutContainer;
	const useWindow = params.useWindow;
	const scrollToLastPosition = params.scrollToLastPosition;

	let layoutContainerAttributes = {
		top: $layoutContainer.position().top
	};
	const articleElementAttributes = {
		height: parseInt($listArticleElement.height(), 10),
		top: parseInt($listArticleElement.position().top, 10)
	};
	articleElementAttributes.middle = parseInt((articleElementAttributes.top + (articleElementAttributes.height / 2)), 10);

	let scrollTo = 0;

	if (scrollToLastPosition) {
		// note: this is only currently valid for mobile where the whole window scrolls
		const moduleData = getStoredGlobalModuleData({
			module: params.module,
			storageKey: params.storageKey
		});
		// scrollAdjust is required because scrollHeight is different from original scrollHeight when function is first called
		const documentScrollHeight = document.documentElement.scrollHeight;
		const scrollAdjust = moduleData.hasOwnProperty('scroll') ? moduleData.scroll.scrollHeight - documentScrollHeight : 0;
		scrollTo = moduleData.hasOwnProperty('scroll') ? moduleData.scroll.scrollTop - scrollAdjust : 0;
		layoutContainerAttributes = Object.assign(layoutContainerAttributes, {
			height: parseInt(window.innerHeight, 10),
			width: parseInt(window.innerWidth, 10),
			scrollHeight: documentScrollHeight,
			scrollTop: 0
		});
	} else {
		if (useWindow) {
			layoutContainerAttributes = Object.assign(layoutContainerAttributes, {
				height: parseInt(window.innerHeight, 10),
				width: parseInt(window.innerWidth, 10),
				scrollHeight: parseInt($(document).height(), 10),
				scrollTop: parseInt(window.scrollY, 10)
			});
		} else {
			layoutContainerAttributes = Object.assign(layoutContainerAttributes, {
				height: parseInt($layoutContainer.height(), 10),
				width: parseInt($layoutContainer.width(), 10),
				scrollHeight: parseInt($layoutContainer.prop('scrollHeight'), 10),
				scrollTop: parseInt($layoutContainer.scrollTop(), 10)
			});
		}
		articleElementAttributes.middle = parseInt((articleElementAttributes.top + (articleElementAttributes.height / 2)), 10);
		scrollTo = parseInt((articleElementAttributes.middle - (layoutContainerAttributes.height / 2)), 10);
	}
	articleElementAttributes.position = getElementPosition({
		layoutContainerAttributes: layoutContainerAttributes,
		articleElementAttributes: articleElementAttributes
	});

	return {layoutContainerAttributes: layoutContainerAttributes, articleElementAttributes: articleElementAttributes, scrollTo: scrollTo};
};

/**
 * Set the scroll position in a vertical article list when changing articles.
 *
 * If an articleId is passed in, then check to see if the articleId is in the article list
 * and that the DOM element matching the article is in the list of articles in the DOM
 * articleList.  If it found, then calculate the vertical position of the article in the
 * DOM list and scroll to make the article visible.
 *
 * If the articleId is not found in the list or the article DOM element does not exist,
 * then just scroll to the top of the list.
 *
 * @param params
 *     $listContainer: overall list container
 *     $layoutContainer: container for articles
 *     $listArticleElement: article element
 *     $listArticlePage: page element container articles
 *     $listGridPages: grid parent; used for scrolling
 *     triggerKeyData: data passed from trigger *
 */
const verticalScrollToArticle = (params) => {
	params = Object.assign({
		$listContainer: [],
		$layoutContainer: [],
		$listArticleElement: [],
		$listArticlePage: [],
		triggerKeyData: {}
	}, params);

	/*
	 * Note sure what the purpose of the useWindow switch was supposed to be but it caused this issue CH-1269.
	 * The only structure that has the article-independent-scrolling class is Structure-LARGE which means that 
	 * other structures end up doing a window.scrollTo instead of scrolling a div. This trigger function gets called
	 * multiple times when open/closing footnotes. On desktop nothing gets scrolled because the divs it's trying
	 * to scroll are already at the top and the actual article-div never gets scrolled. But with useWindow=true
	 * for mobile layout all calls result in a window.scrollTo call and the article ends up being scolled up when 
	 * clicking a footnote.
	*/
	const useWindow = false;//params.$layoutContainer.parents('.article-independent-scrolling').length === 0;
	const articleInList = params.triggerKeyData.articleInList;
	const articleElementExists = !isEmpty(params.$listArticleElement) && params.$listArticleElement.length > 0;
	if (articleInList && articleElementExists) {
		let scrollCheck = checkIfOffScreen({
			$listArticleElement: params.$listArticleElement,
			$layoutContainer: params.$layoutContainer,
			useWindow: useWindow,
			scrollToLastPosition: params.triggerKeyData.scrollToLastPosition,
			module: params.triggerKeyData.module,
			storageKey: params.triggerKeyData.storageKey
		});

		const containerScrollHeight = scrollCheck.layoutContainerAttributes.scrollHeight;
		const containerWidth = scrollCheck.layoutContainerAttributes.width;
		// get and set store value for latest trigger by device, module, storageKey; so we only scroll once
		const triggerKey = getDeviceType() + '_' + params.triggerKeyData.module + '_' + params.triggerKeyData.storageKey;
		const triggerStorage = getStoreValue({attributeKey: 'articleListUITriggers', default: {}});
		const storedData = triggerStorage.hasOwnProperty(triggerKey) ?
			triggerStorage[triggerKey] :
			{
				scrollTo: -1,
				containerScrollHeight: 0,
				containerWidth: 0
			};

		const doScroll = scrollCheck.articleElementAttributes.position !== MIDDLE ||
			storedData.scrollTo !== scrollCheck.scrollTo ||
			storedData.containerScrollHeight !== containerScrollHeight ||
			storedData.containerWidth !== containerWidth;
		if (doScroll) {
			if (useWindow) {
				window.scrollTo(0, scrollCheck.scrollTo);
			} else {
				params.$layoutContainer.scrollTop(scrollCheck.scrollTo);
			}
		}
		triggerStorage[triggerKey] = {scrollTo: scrollCheck.scrollTo, containerScrollHeight: containerScrollHeight, containerWidth: containerWidth};
		setStoreValue({attributeKey: 'articleListUITriggers'}, triggerStorage);
	} else {
		if (useWindow) {
			window.scrollTo(0, 0);
		} else {
			params.$layoutContainer.scrollTop(0);
		}
	}
};

/**
 * Set the scroll position in the carousel horizontal article list, when changing an article.
 * Try to position in the center of the viewable area, but make sure it is visible.
 *
 * Note: The carousel articleList is built inside a grid element, so the actual scroll
 * container element is the grid page parent.
 *
 * @param params
 *     $listContainer: overall list container
 *     $layoutContainer: container for articles
 *     $listArticleElement: article element
 *     $listArticlePage: page element container articles
 *     $listGridPages: grid parent; used for scrolling
 *     triggerKeyData: data passed from trigger
 */
const carouselScrollToArticle = (params) => {
	params = Object.assign({
		$listContainer: [],
		$layoutContainer: [],
		$listArticleElement: [],
		$listArticlePage: [],
		$listGridPages: [],
		triggerKeyData: {}
	}, params);

	const articleInList = params.triggerKeyData.articleInList;
	if (articleInList) {
		const halfContainerWidth = params.$listGridPages.width() / 2;
		// actual article element width plus one for 1px left border
		const articleElementWidth = params.$listArticleElement.width() + 1;
		// scrollLeft equals the index of the article element in the list
		// multiplied by the width of the article element, subtracting
		// half the container width minus half the article element width
		const $listItems = params.$listArticlePage.children();
		const currentArticleIndex = $listItems.index(params.$listArticleElement);
		const scrollLeft = (currentArticleIndex * articleElementWidth) - (halfContainerWidth - (articleElementWidth / 2));
		// set the scrollLeft of the list page container
		params.$listGridPages.scrollLeft(scrollLeft);
	} else {
		params.$listGridPages.scrollLeft(0);
	}
};

/**
 * Respond to an action on an articleList that requires a browser back-end response
 * rather than one from within the module itself.
 *
 * Note: Not currently called
 *
 * @param params parameters for article trigger
 *     action: potential, respondable action
 *     module: module name
 *     storageKey: module instance storageKey
 *     articleList: pointer to the list
 * @returns {string} success/failure
 */
const triggerOnArticleList = (params) => {
	params = Object.assign({
		action: '',
		module: null,
		storageKey: null,
		articleList: null
	}, params);

	return 'success';
};
export {triggerOnArticleList};



const mobileDevices = ['md', 'sm', 'xs'];
/**
 * Trigger function that is activated when a click is registered in the articleList.
 * Currently, this is only called when an article is clicked.
 *
 * TODO: Generalize this for future use
 *     We are only triggering this if scrollToLastPosition is true and if mobile
 *
 * @param params
 *     storageKey: module storage key
 *     listType: type of article list
 *     scrollToLastPosition: configuration parameter to trigger scroll on mobile
 */
const triggerOnArticleListClick = (params) => {
	params = Object.assign({
		storageKey: '',
		listType: 'default',
		scrollToLastPosition: false
	}, params);

	const device = getDeviceType();
	const mobileDevice = mobileDevices.includes(device);
	if (isTrue(params.scrollToLastPosition)) {
		if (mobileDevice) {
			const documentScrollData = getStoredScrollData({component: document, componentName: 'browser'});
			storeGlobalModuleData({
				module: 'articleList',
				storageKey: params.storageKey,
				attributeKey: 'scroll',
				attributeValue: documentScrollData
			});
		} else {
			// for now, only mobile
		}
	}
};
export {triggerOnArticleListClick};


/**
 * Respond to an action on articleList when the article list UI is re-rendered.
 * There are several kinds of scroll actions, and this handles those.
 *
 * If an articleId is passed in, this will check if the articleId is in the
 * rendered articleList.  If so, the eventual scroll will scroll it into view
 * if scrollToSelection is set.  Scrolling in the list will differ depending
 * on whether the list is vertical or hozizontal.
 *
 * If none of the scroll actions is set, then do nothing.
 *
 * @param params
 *     parameters for article trigger passed in by calling module
 *         module: 'articleList' required to find the module in the UI
 *         storageKey: module storage key
 *         listType: 'default' or 'carousel' for vertical vs horizontal scroll effects
 *         scrollToSelection: module property; true: try to trigger scroll; false: no scroll
 *         articleId: explicitly pass in articleId for trigger action
 *         articleListKey: hash storage key to get list of articles
 * @returns {string} success/failure
 */
const triggerOnArticleListUI = (params) => {
	params = Object.assign({
		module: 'articleList',
		storageKey: '',
		listType: 'default',
		scrollToSelection: false,
		scrollToLastPosition: false,
		section: '',
		articleId: ID_NOT_SET,
		articleListKey: ''
	}, params);

	const scrollToSelection = isTrue(params.scrollToSelection);
	const scrollToLastPosition = isTrue(params.scrollToLastPosition);
	const section = params.section;

	// check if the articleId is in the list (ads are usually not fetched in the list)
	// if not in the list, set to the first article for setting scroll position
	const articleListData = retrieveFromCache({dataKey: params.articleListKey, keyType: 'articles', emptyDataObject: []});
	const articleList = articleListData.storedData;
	const articleInList = articleList.some((article) => article.articleId === params.articleId);

	// only trigger scroll if one of the scroll options is set
	if (scrollToSelection || scrollToLastPosition || section) {
		// find the module's defined container and find it in the UI and start from there
		const moduleContainer = getModuleContainer(params);

		let $listContainer;
		if (isEmpty(moduleContainer.pane) || isEmpty(moduleContainer.container)) {
			$listContainer = $();
		} else {
			$listContainer = $('#' + moduleContainer.pane).find('.' + moduleContainer.container);
		}
		const $listElement = $listContainer.find('div[data-storagekey="' + params.storageKey + '"]');

		// only proceed if the list element has been created in the UI
		if (!isEmpty($listElement && $listElement.length > 0)) {
			const $listArticleElement = $listElement.find('li[data-articleid="'+params.articleId+'"]').filter('.article-current');
			const $layoutContainer = $listElement.parents('div.layout-block');
			const $listArticlePage = $listArticleElement.parents('ul.article-page');
			const $listGridPages = $listArticleElement.parents('.display-pages');

			const triggerKeyData = {
				module: params.module,
				storageKey: params.storageKey,
				listType: params.listType,
				scrollToSelection: scrollToSelection,
				scrollToLastPosition: scrollToLastPosition,
				articleInList: isTrue(articleInList, {default: false})
			};
			const scrollParams = {
				$listContainer: $listContainer,
				$layoutContainer: $layoutContainer,
				$listArticleElement: $listArticleElement,
				$listArticlePage: $listArticlePage,
				$listGridPages: $listGridPages,
				triggerKeyData: triggerKeyData
			};

			// if "section" has a value, add a "selected" class to the associated link
			if (!isEmpty(section)) {
				$('a[href="//scroll_to/' + section + '"]').addClass('selected');
			}

			const scrollFunction = params.listType === 'carousel' ? carouselScrollToArticle: verticalScrollToArticle;
			scrollFunction(scrollParams);
		}
	}
	return 'success';
};
export {triggerOnArticleListUI};
