import React from "react";

/**
 * Generate a css spinner.
 * This must be used together with appropriate css styling.
 *
 * Original open source for spinner
 *     https://loading.io/css/
 *
 * NOTE: Modified from original htnl
 *     Changed from  'class="lds-roller"'  to  'className={className}'
 *
 * @param params
 *     className optional class name to apply for type of spinner
 *         default: 'lds-roller'  (dotted-spinner)
 *         NOTE: if a different spinner is needed, add spinner styls to generateSpinner.css
 * @returns {*} DOM object
 */
const CssSpinner = (params) => {
	const className = params.className ? params.className : 'lds-roller';
	return (
		<div className={className}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};
export default CssSpinner;


//	return <div className={className}><div /><div /><div /><div /><div /><div /><div /><div /></div>;