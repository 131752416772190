import React from "react";
import GenerateCategoryButtons from "../../../widgets/generateCategoryButtons";
import {GenerateHtmlMarkup} from "../../../widgets/generateHtmlMarkup";
import GenerateIssueButton from "../../../widgets/generateIssueButton";
import GenerateShareButtons from "../../../widgets/generateShareButtons";
// import GenerateThumbnails from "../../../widgets/generateThumbnails";
import getArticleAttribute from "../../../modules/library/articleViewer/manage_attributes/getArticleAttribute";
import getSpecialAttribute from "../../../modules/library/articleViewer/manage_attributes/getSpecialAttribute";
import {getExtractedElements} from "../../../modules/library/articleViewer/content_updaters/findElement";
import {GenerateArticleBodyContent} from "../../../modules/library/articleViewer/content_updaters/generateArticleBodyContent";
import {updateArticleImages} from "../../../modules/library/articleViewer/content_updaters/updateArticleImages";
import {clone} from "../../../utils/objectUtils";


/**
 * Attributes to extract from the article content and moved to a new position.
 * created as array, as order matters
 *
 * @type {({name: string, id: string, duplicate: boolean, element: string}|{prefix: {i18n: string}, name: string, class: string, element: string}|{name: string, element: string}|{name: string, element: string})[]}
 */
const elementsAttributes = [
	{name: 'roofHeader', element: ".roofHeader", id: "roof-header-first", duplicate: true},
	{name: 'roofHeaderScreenReader', element: ".roofHeader", class: "screen-reader", prefix: {i18n: "article.roofHeader.prefix"}},
	{name: 'subtitle', element: ".subtitle"},
	{name: 'byline', element: ".byline"},
	{name: 'figure', element: "figure", count: 1},
	{name: 'sectionHeader', element: ".sectionHeader"},
];

/**
 * React code to generate html for "default" template
 *
 * @param params
 *     article article object
 *     moduleProps props from calling module
 *
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateHeroImageArticle = (params) => {
	params = Object.assign({
		article: {},
		template: 'heroimage',
		moduleProps: {}
	}, params);
	// make a clone of article so we don't accidentally change anything for the caller
	const article = clone(params.article);
	const title = getArticleAttribute({article: article, attribute: "title"}).value;
	const categories = getSpecialAttribute({article: article, attribute: "categories"}).value;
	const className = 'heroimage scrollContainer ' + categories;
	article.content = updateArticleImages({articleMarkup: article.content, template: params.template});


	const shareAttributes = {
		elfSightCode: params.moduleProps.hasOwnProperty('elfSightCode') ? params.moduleProps.elfSightCode : '',
		shareTitle: article.title,
		isApp: params.moduleProps.app,
		platform: params.moduleProps.platform,
		moduleName: params.moduleProps.name,
		displayShareButtons: params.moduleProps.displayShareButtons,
		storageKey: params.moduleProps.storageKey
	};

	// gets object of extracted elements and modified article content
	const extractedElements = getExtractedElements({article: article, elementsAttributes: elementsAttributes});
	const articleMarkup = extractedElements.articleMarkup;

	return (
		<div data-templatename="Hero Image" className={className}>
			<div className="hero">
				<GenerateHtmlMarkup htmlMarkup={extractedElements.elements.roofHeader} />
				<GenerateHtmlMarkup htmlMarkup={extractedElements.elements.figure} />
			</div>
			<div className="contentContainer gtxcelDefaultTemplate">
				<GenerateHtmlMarkup htmlMarkup={extractedElements.elements.sectionHeader} />
				<div className="articleBodyContainer">
					<header className="header">
						<GenerateIssueButton issueUrl={article.issueUrl} issueName={article.issueName} articleId={article.articleId} articleTitle={article.title} moduleProps={params.moduleProps}/>
						{params.moduleProps.canDisplayShareButtons ? <GenerateShareButtons {...shareAttributes} /> : ''}
						<h1 className="mainTitle" tabIndex="-1">
							<GenerateHtmlMarkup htmlMarkup={title} />
							<GenerateHtmlMarkup htmlMarkup={extractedElements.elements.roofHeaderScreenReader} />
						</h1>
						<GenerateHtmlMarkup htmlMarkup={extractedElements.elements.subtitle} />
						<GenerateHtmlMarkup htmlMarkup={extractedElements.elements.byline} />
					</header>
					<GenerateCategoryButtons categories={article.categories} articleId={article.articleId} articleTitle={article.title} moduleProps={params.moduleProps} />
					<div className=" articleBody">
						<article>
							<GenerateArticleBodyContent templateName={params.template} articleMarkup={articleMarkup} article={article} moduleProps={params.moduleProps} />
						</article>
					</div>
				</div>
			</div>
		</div>
	);

};
export default GenerateHeroImageArticle;
