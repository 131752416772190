import {arrayFromStringTrim, arrayRemoveDups, arraysConcatNoDup} from "../arrayUtils";


/**
 * Remove extra space around values within lists or string lists.
 * Create actual lists from string lists.
 *
 * Note: Some properties are set in both the module's new props and
 * the original module props.  In that case, check the new props values
 * first.
 *
 * @param params module parameters
 *     storeState: store state based on storageKey
 *     originalProps: the "props" attribute from the module
 *     moduleProps: new, added props for the module
 *
 * @returns {*} update moduleProps values
 */
const finalCleanupArticleList = (params) => {
	params = Object.assign({
		storeState: {},
		originalProps: {},
		moduleProps: {}
	}, params);

	const originalProps = params.originalProps;
	const moduleProps = params.moduleProps;

	// remove spaces between/around multiple-defined values
	if (moduleProps.hasOwnProperty('authorNames')) {
		moduleProps.authorNamesList = moduleProps.authorNames.split('|').map(name => name.trim());
		moduleProps.authorNames = moduleProps.authorNamesList.join('|');
	} else if (originalProps.hasOwnProperty('authorNames')) {
		moduleProps.authorNamesList = originalProps.authorNames.split('|').map(name => name.trim());
		moduleProps.authorNames = moduleProps.authorNamesList.join('|');
	} else {
		moduleProps.authorNamesList = [];
		moduleProps.authorNames = '';
	}
	if (moduleProps.hasOwnProperty('categories')) {
		moduleProps.categoriesList = moduleProps.categories.split('~').map(name => name.trim());
		moduleProps.categories = moduleProps.categoriesList.join('~');
	} else if (originalProps.hasOwnProperty('categories')) {
		moduleProps.categoriesList = originalProps.categories.split('~').map(name => name.trim());
		moduleProps.categories = moduleProps.categoriesList.join('~');
	} else {
		moduleProps.categoriesList = [];
		moduleProps.categories = '';
	}

	// add filter and hide to articleDisplayParams
	moduleProps.articleDisplayParams.filterCategories = originalProps.hasOwnProperty('filterCategories') ? originalProps.filterCategories.split('~').map(name => name.trim()) : [];
	moduleProps.articleDisplayParams.hideCategories = originalProps.hasOwnProperty('hideCategories') && typeof originalProps.hideCategories === 'string' ? arrayRemoveDups(arrayFromStringTrim(originalProps.hideCategories, '~')) : [];
	// concatenate categories on to hideCategories if any,
	// or just assign the category list to hideCategories
	if (moduleProps.articleDisplayParams.hideCategories.length > 0) {
		moduleProps.articleDisplayParams.hideCategories.push(...moduleProps.categoriesList);
	} else {
		moduleProps.articleDisplayParams.hideCategories = moduleProps.categoriesList;
	}
	// generate hideCategories as an array and concatenate categories, featureCategory and listCategory
	if (moduleProps.categories !== '') {
		moduleProps.articleDisplayParams.hideCategories = arraysConcatNoDup(moduleProps.articleDisplayParams.hideCategories, (arrayFromStringTrim(originalProps.categories, '~')));
	}
	if (typeof originalProps.featureCategory === 'string') {
		moduleProps.articleDisplayParams.hideCategories = arraysConcatNoDup(moduleProps.articleDisplayParams.hideCategories, (arrayFromStringTrim(originalProps.featureCategory, '~')));
	}
	if (typeof originalProps.listCategory === 'string') {
		moduleProps.articleDisplayParams.hideCategories = arraysConcatNoDup(moduleProps.articleDisplayParams.hideCategories, (arrayFromStringTrim(originalProps.listCategory, '~')));
	}

	return moduleProps;
};
export {finalCleanupArticleList};
