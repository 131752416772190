import React from 'react';
import {connect} from 'react-redux';
import {getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import {isEmpty, isTrue} from "../../../utils/generalUtils";
import {GtxInfo} from "./widgets/gtxinfo";
import {EASTEREGG, EASTEREGG_KEY} from "../../_MODULE_GLOBALS/constants";
import {getStoreValue} from "../../../utils/storeValue";


// Current set of supported easter eggs
const easterEggWidgets = {
	'gtxinfo': GtxInfo,
};


/**
 * Called by widget to update their easter egg data.
 * Most likely called by the close button or someing similar for the easter egg.
 *
 * @param params easter egg data to update
 *     egg: easter egg widget
 *     enable: (optional) default: false
 *     storageKey: (optional) default: EASTEREGG_KEY
 */
const updateEasterEggData = (params) => {
	params = Object.assign({
		egg: '',
		enable: false,
		storageKey: EASTEREGG_KEY,
	}, params);
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
	updateData(params, {type: EASTEREGG, storageKey: params.storageKey});
};
export {updateEasterEggData};



/*
* Generate jsx for Easter Egg if displayOnDevice
*/
/**
 * Generate the Easter Egg.
 * Module configuration comes in two parts
 *     easterEgg module
 *         specify the easter egg widget to display
 *         specify any attributes for the easter egg
 *     searchEntry module
 *         specify the trigger search term for a particular easter egg
 *
 * @param props module props
 * @returns {JSX.Element|null}
 * @constructor
 */
export const EasterEggModule = (props) => {
	if (displayOnDevice(props) && props.eggTriggered && easterEggWidgets.hasOwnProperty(props.egg)) {
		const EasterEgg = easterEggWidgets[props.egg];
		return <EasterEgg {...props} />;
	} else {
		return null;
	}
};


/**
 * Map the store object you are using.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{articleList: Array}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : EASTEREGG_KEY;
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};
	const egg = !isEmpty(props.egg) ? props.egg : '';

	return {
		state: state,
		active: getActiveAttributeState(props),
		storageKey: storageKey,
		isApp: isTrue(state.app, {defaultValue: false}),
		egg: egg,
		eggAttributes: !isEmpty(props.eggAttributes) ? props.eggAttributes : {},
		eggTriggered: !isEmpty(storeState.egg) && isTrue(storeState.enable, {defaultValue: true}) ? egg === storeState.egg : false,
		displayCloseButton: isTrue(props.displayCloseButton, {defaultValue: true}),
	};
};


export default connect(
	mapStateToProps,
	null
)(EasterEggModule);
