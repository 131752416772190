import {
	ISSUE_LIST,
	TAGS_LOOKUP,
	TAG_LIST,
	DATA_LOADED,
	DATA_UPDATED,
	ISSUE_FILTERS,
	DATA_REQUESTED
} from "../../_MODULE_GLOBALS/constants";
import deepmerge from "deepmerge";
import {createNewStoreState} from "./generalReducerActions";
import {storeInCache} from "../../../utils/manageStoreCacheData";

/*
   This reducer fetches and updates issue list objects, which generally consist
   of an array of issue objects.
 */


/**
 * Required store properties for a module that fetches lists of issues.
 *
 * This ensures that there is a placeholder in the store for
 * the values returned for the module.
 * Each action-type then initializes the state for it's store state.
 */
const createInitialState = ((storageKey) => {
	return {
		[storageKey]: {
			"collection": "",
			"collectionUrl": "",
			"issues": [],
			"tags": []
		}
	};
});
/**
 * List of attributes that should be re-initialized each time a reducer gets called.
 *
 * @type {function(*): {}}
 */
const resetStoreState = ((storageKey) => {
	return {
		[storageKey]: {}
	};
});

/**
 * Required store properties for a module that fetches lists of issues.
 *
 * This ensures that there is a placeholder in the store for
 * the values returned for the module.
 * Each action-type then initializes the state for it's store state.
 */
const createFiltersInitialState = ((storageKey) => {
	return {
		[storageKey]: {
			"selectedTag": {
				"tagId": '',
				"tagName": '',
				"tagLabel": '',
				"pageSize": 0,

			}
		}
	};
});
/**
 * List of attributes that should be re-initialized each time a reducer gets called.
 *
 * @type {function(*): {}}
 */
const resetFiltersStoreState = ((storageKey) => {
	return {
		[storageKey]: {}
	};
});


function issueListReducers(state, action) {
	// check if module type matches ISSUE_LIST; if not present, set to true to handle anyway
	const moduleAction = (action.params && action.params.type) ? action.params.type : ISSUE_LIST;
	if (moduleAction === ISSUE_LIST && action.type === DATA_REQUESTED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : "issueListName";

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});
		returnState[storageKey].fetchInProgress = true;

		return returnState;
	}
	if (moduleAction === ISSUE_LIST && action.type === DATA_LOADED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : "issueListName";

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});

		const fetchQueryParams = action.params.fetchQueryParams ? action.params.fetchQueryParams : null;
		const issueList = Array.isArray(action.payload.groupDetails) ? action.payload.groupDetails : [];

		returnState[storageKey].collection = action.payload.collection ? action.payload.collection : '';
		returnState[storageKey].collectionUrl = action.payload.collectionUrl ? action.payload.collectionUrl : '';
		returnState[storageKey].issueList = issueList;
		returnState[storageKey].fetchQueryParams = fetchQueryParams;

		// cache return issues with query params as key
		storeInCache({dataKey: fetchQueryParams, keyType: "issues", data: issueList, overrideEmptyData: true, state: returnState});
		returnState[storageKey].fetchInProgress = false;
		return returnState;
	}
	if (moduleAction === ISSUE_LIST && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : "issueListName";
		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createFiltersInitialState(storageKey),
			resetStorageStateValues: resetFiltersStoreState(storageKey)
		});

		return returnState;
	}

	// NO LONGER USED???  REMOVE IN THE FUTURE
	if (moduleAction === TAGS_LOOKUP && action.type === DATA_LOADED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : TAG_LIST;
		const returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
		
		returnState[storageKey].tags = Array.isArray(action.payload) ? action.payload : [];
		return returnState;
	}

	if (moduleAction === ISSUE_FILTERS && action.type === DATA_UPDATED) {
//		console.log("updating selected tag");
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : "issueListName";
		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createFiltersInitialState(storageKey),
			resetStorageStateValues: resetFiltersStoreState(storageKey)
		});

		return returnState;
	} 
	return state;
}
export default issueListReducers;

