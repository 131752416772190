import React from "react";
import {Translate} from "../locales/locales";
import {navigateToTarget} from "../modules/globals/globalNavigator";
import {isEmpty, isTrue} from "../utils/generalUtils";
import * as Tracking from "../utils/tracking";
import $ from 'jquery';



/**
 * Using the pageThumbnails string in the article object, split into a set of thumbnail links,
 * then show the thumbnails in the thumbnails widget with a link to the replica page represented
 * in the thumbnail image.
 *
 * @param params
 *     article: article object
 *     moduleProps: module props from the calling module
 * @returns {string|string|JSX.Element}
 * @constructor
 */
const GenerateThumbnails = (params) => {
	params = Object.assign({
		article: {},
		moduleProps: {}
	}, params);
	const {article, moduleProps} = params;
	// do not show thumbnails if user has no access
	if (!article.userHasAccess) {
		return '';
	}

	const thumbnails = typeof article.pageThumbnails === 'string' && article.pageThumbnails.length > 0 ? article.pageThumbnails.split(',') : [];
	const folios = Array.isArray(article.folios) ? article.folios : [];
	const pages = typeof article.pages === 'string' && article.pages.length > 0 ? article.pages.split(',') : [];

	// navigation attributes
	const navigationKey = 'replica';
	const navKey = moduleProps.navigationKeys.hasOwnProperty(navigationKey) ? moduleProps.navigationKeys[navigationKey] : {};
	const navKeyAttributes = navKey.attributes ? navKey.attributes : {};
	const targetKey = isEmpty(navKey.navigationTarget) ? 'replica' : navKey.navigationTarget;
	const replacementValues = {
		issueUrl: article.issueUrl
	};
	const fixedAttributes = Object.assign({}, navKeyAttributes,
		{
			updateHistoryState: true,
			replaceHistoryState: true
		});


	return isEmpty(thumbnails) ? '' : (
		<div className="page-thumbnails-wrapper">
			<div className="page-thumbnails-title">{Translate.Text({id:'article.pageThumbnails.title'})}</div>
			<ul className={"page-thumbnails"} aria-label={"Pages"}>
				{
					thumbnails.map((thumbnail, index) => {
						const pageNumber = parseInt(pages[index]);
						const folio = article.folios.length > index ? article.folios[index] : '';
						const pageAttributes = {
							pageNumber: isNaN(pageNumber) ? 0 : pageNumber,
							// get actual folio value when supported in article object
							folio: folio,
							issueUrl: article.issueUrl
						};
						const targetParams = {
							targetKey: targetKey,
							attributes: Object.assign({}, fixedAttributes, pageAttributes),
							replacementValues: replacementValues
						};
						const imgAlt = Translate.string({id: "article.lightboxImage.thumbnail"}) + " " + pageNumber;

						// handle articleViewer with/without replicaViewer
						return (
							isTrue(moduleProps.pageThumbnailOpensReplicaViewer) ?
								<li key={pageNumber + '-' + index} className={"page-thumbnail"}>
									<a href={"//open_page"}
									   className={"replicaThumbnailWrap"}
									   onClick={(evt) => {
										   evt.stopPropagation();
										   evt.preventDefault();

											const img = $(evt.currentTarget).find('img');
											const source = $(img).attr('src').replace('mobile_tn2','mobile3').replace('.png','.jpg');
											const folio = $(img).data('folio') || '';
											const $articleContainer = $(img).parents(".articleContainer");
											const matchDomain = new RegExp('(https*://|//|/|.*?)(.*?)(/|$)');
											const domain = source.match(matchDomain)[2];
											const trackingProperties = {
												label: "Open page in Replica Viewer",
												module: 'articleViewer',
												type: "page thumbnail",
												"image type": "page",
												domain: domain,
												"article title": $articleContainer.find('.mainTitle').text().trim(),
												location: "modular library",
												"page folio": [ folio ],
											};
											Tracking.libraryTrack("link clicked", trackingProperties);
										   navigateToTarget(targetParams);
									   }}
									>
										<figure className="page-thumbnail-figure">
											<img src={article.documentThumbnailUrl + "/data/imgpages/mobile_tn2/" + thumbnail + '.png'} alt={imgAlt} data-folio={folio} />
											<span className={"screen-reader"}>{Translate.string({id: "article.lightboxImage.thumbnail"})}</span>
										</figure>
									</a>
								</li>
								:
								<li key={pageNumber + '-' + index} className={"page-thumbnail"}>
									<a className="replicaThumbnailWrap lightboxPageWrap" href="//openLightbox">
										<figure className="page-thumbnail-figure">
											<img src={article.documentThumbnailUrl + "/data/imgpages/mobile_tn2/" + thumbnail + '.png'} alt={imgAlt} data-folio={folio} />
											<span className={"screen-reader"}>{Translate.string({id: "article.lightboxImage.thumbnail"})}</span>
										</figure>
									</a>
								</li>
						);
					})
				}
			</ul>
		</div>
	);

};
export default GenerateThumbnails;

// removing figcaption from <figure> for now
// <figcaption className="page-thumbnail-page-number">{pageNumber}</figcaption>
