import {getSplitContent, getWidgetDefinitions} from "./findElement";
import {GenerateArticleHtmlMarkup} from "../../../../widgets/generateHtmlMarkup";
import React from "react";


/**
 * Split article markup at an element, removing the top-level wrapper and returns top-level class and id
 * (if present)
 *     articleMarkupPart1: up to and including element
 *     articleMarkupPart2: rest after element
 *
 * Note 1: because only the children are returned, we use the class/id to recreate the top-level wrapper
   Note 2: eq is 0-based, so "0", for example, would be the first of that element/tag

 * @param params
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateArticleBodyContent = (params) => {
	params = Object.assign({
		article: {},
		moduleProps: {},
		articleMarkup: '',
		templateName: 'default'
	}, params);

	const widgetDefinitions = getWidgetDefinitions({template: params.templateName, templateOverrides: params.moduleProps.templateOverrides});
	const contentWidget = widgetDefinitions.find((def) => {
		return def.display === true && (def.hasOwnProperty('location') && def.location.within === 'content');
	});
	if (typeof contentWidget !== 'undefined') {
		const splitArticleContent = getSplitContent(params.articleMarkup, contentWidget);
		if (splitArticleContent.hasSplit) {
			const SplitWidget = splitArticleContent.widget;
			const topLevelAttributes = splitArticleContent.markup.topLevelAttributes;
			return (
				<div {...topLevelAttributes}>
					<GenerateArticleHtmlMarkup htmlMarkup={splitArticleContent.markup.articleMarkupPart1}/>
					<SplitWidget article={params.article} moduleProps={params.moduleProps} />
					<GenerateArticleHtmlMarkup htmlMarkup={splitArticleContent.markup.articleMarkupPart2}/>
				</div>
			);
		} else {
			return <GenerateArticleHtmlMarkup htmlMarkup={params.articleMarkup} />;
		}
	} else {
		return <GenerateArticleHtmlMarkup htmlMarkup={params.articleMarkup} />;
	}
};
export {GenerateArticleBodyContent};
