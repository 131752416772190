import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { Translate } from "../../../locales/locales";
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import {checkIfMyPane} from "../../globals/globalLibraryData";
import {GOOGLE_AD, ID_NOT_SET} from "../../_MODULE_GLOBALS/constants";
import {isEmpty, isTrue} from "../../../utils/generalUtils";
import getDeviceType from "../../../utils/getDeviceType";
import {useAttributesChanged} from "../../../hooks/useAttributesChanged";



/**
 * Based on standard device size breakpoints from DEVICESIZEBREAKPOINTS constant and
 * returned by function getDeviceSize()
 *     {xs: 0, sm: 568, md: 768, lg: 1024, xl: 1280, xxl: 1400},
 *
 * @type {{xl: string, md: string, sm: string, xs: string, lg: string, xxl: string}}
 */
const DEVICE_SIZE_MATCH = {
	"xs": "phone",
	"sm": "phone",
	"md": "tablet",
	"lg": "desktop",
	"xl": "desktop",
	"xxl": "desktop"
};



/**
 * GoogleAd Module.
 *
 * Use Google Publisher Tag (GPT) to manage display and selection os an
 * ad created in the google ad interface.
 *
 * GPT functionality
 *     googletag: base call to GPT
 *     googletag.cmd: wrapper that allows for asynchronous calls to GPT
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export const GoogleAdModule = (props) => {
	const [bannerClosed, setBannerClosed] = useState(false);
	const [displayAd, setDisplayAd] = useState(false);
	const [googleTagReady, setGoogleTagReady] = useState(false);
	const [slot, setSlot] = useState(null);
	const [hasCreatives, setHasCreatives] = useState(true);

	// reference to the googletag functions; they should be loaded before module init
	const googletag = window.googletag || {};
	googletag.cmd = googletag.cmd || [];


	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: GOOGLE_AD,
		defaultClass: 'google-ad',
		configQueryParams: {}
	});

	const deviceType = DEVICE_SIZE_MATCH[getDeviceType()];  // desktop/tablet/phone
	const selectedAdSize = props.adDeviceSizes[deviceType];
	const adId = 'dfp-ad_' + props.instanceId + '_' + deviceType;  // generate from instanceId; html id for ad div
	const slotIdentifier = !isEmpty(slot) ? slot.getSlotElementId() + '_' + slot.getSlotId().getId() : '';  // elementId: html div id; slotId: based on adUnit and networkCode


	/**
	 * Check that GPT function googletag is available and ready for calls.
	 * Update state to trigger hook that creates new slot for ad.
	 */
	useAttributesChanged(() => {
		if (googleTagReady) {
			//console.log("googleTagReady is already set to", googleTagReady);
		} else if (window.googletag && googletag.apiReady) {
			//console.log("googletag api is ready", googletag.apiReady);
			setGoogleTagReady(true);
		} else {
			console.log("googletag api is not ready, sleeping for a moment");
			setTimeout(() => {
				if (window.googletag) {
					//even though the api says it's not ready we assume that it will be
					//console.log("found googletag api, apiReady:", googletag.apiReady);
					setGoogleTagReady(true);
				} else {
					console.log("googletag not found", window.googletag);
				}
			},1000);
		}
	}, [(window.googletag && googletag.apiReady)]);


	/**
	 * Update the google ad slot on changes to
	 *     browser size: when crossing a device type boundary
	 *     path: derived from network code and adUnit
	 *     slot: when a slot has been created/deleted
	 *     googleTagReady: state change trigger for googletag ready for calls
	 */
	useAttributesChanged(() => {
		const configuredSlots = !isEmpty(googletag.pubads) && !isEmpty(googletag.pubads().getSlots()) ? googletag.pubads().getSlots() : [];

		if (googleTagReady) {
			if (isEmpty(slot)) {
				// check if slot is already stored in google pubads service; else create new
				const instanceSlot = configuredSlots.find(adSlot => {
					return adSlot.getSlotId().getDomId() === adId;
				});
				if (isEmpty(instanceSlot)) {
					googletag.cmd.push(function() {
						const currentSlot = googletag.defineSlot(props.path, selectedAdSize, adId);
						currentSlot.addService(googletag.pubads());
						//console.log("create Slot", currentSlot.getSlotElementId());
						setSlot(currentSlot);
					});
				} else {
					setSlot(instanceSlot);
					//console.log("restore slot", instanceSlot.getSlotElementId());
				}
			} else {
				googletag.cmd.push(function() {
					//console.log("push", props.path, adId);
					googletag.pubads().addEventListener('slotRenderEnded', function(event) {
						// check that we are looking at the slot for this module instance
						if (slot === event.slot) {
							if (event.creativeId === null) {
								console.warn("slotRenderEnded: no creativeId found for this slot", event.slot.getSlotElementId(), event.slot.getSlotId().getId());
								if (isTrue(hasCreatives)) {
									setHasCreatives(false);
								}
							} else {
								if (!isTrue(hasCreatives)) {
									setHasCreatives(true);
								}
							}
						}
					});
					googletag.pubads().disableInitialLoad();  // refresh will run automatically to load ad
					googletag.enableServices();
				});
			}
		}
	}, [deviceType, props.path, slotIdentifier, googleTagReady]);


	/**
	 * After slot creation, detect a change in the saved slot object and call to allow
	 * GPT to render ads to the slot
	 */
	useAttributesChanged(() => {
		if (!isEmpty(slot) && googleTagReady) {
			googletag.cmd.push(function() {
				googletag.display(adId);
			});
		}
	}, [slotIdentifier]);


	/**
	 * Call to refresh the ad when one of the refresh properties changes or after slot is created.
	 * Before calling refresh, set the targeting properties for the slot ad delivery
	 *     note: targeting value must be a string, so convert with general toString call
	 */
	const refreshValuesList = Object.values(props.adRefreshProperties).concat([slotIdentifier, hasCreatives]);
	useAttributesChanged(() => {
		if (!isEmpty(slot) && googleTagReady && props.isDisplayable && !bannerClosed && hasCreatives) {
			//console.log("refresh");
			googletag.cmd.push(function () {
				slot.clearTargeting();
				Object.keys(props.adTargetingProperties).forEach((key) => {
					//console.log("setTargeting", key, props.adTargetingProperties[key]);
					if (!isEmpty(props.adTargetingProperties[key]) && props.adTargetingProperties[key] !== ID_NOT_SET) {
						slot.setTargeting(key, props.adTargetingProperties[key].toString());
					}
				});
				googletag.pubads().refresh([slot]);
				setDisplayAd(true);
			});
		} else {
			setDisplayAd(false);
		}
	}, refreshValuesList);



	const adClassName = props.isDisplayable && displayAd && !bannerClosed && hasCreatives ? mops.className + " show-ad" : mops.className + " hide-ad";
	if (displayOnDevice(props) && !isEmpty(adId)) {
		return (
			<div className={adClassName}>
				<div id={adId} />
				{props.displayCloseButton ?
					<button
						className={'close-banner-ad'}
						onClick={(evt) => {
							setDisplayAd(false);
							setBannerClosed(true);
						}}>
						<icon>
							<i className="fas fa-times-circle" />
							<span className={'sr-only'}>
								{Translate.Text({id:'googleAd.close'})}
							</span>
						</icon>
					</button>
					: null
				}
			</div>
		);
	} else {
		return null;
	}

};


const mapStateToProps = (state, props) => {
	const storageKey = props.storageKey ? props.storageKey : GOOGLE_AD;
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};
	const globalState = !isEmpty(state.globals) ? state.globals : {};

	const myPane = checkIfMyPane({moduleName: 'googleAd', instanceId: props.instanceId, storageKey: storageKey});
	const isDisplayable = myPane.isMyPane && displayOnDevice(props);
	const networkCode = !isEmpty(props.properties.networkCode) ? props.properties.networkCode : '';
	const adUnit = !isEmpty(props.properties.adUnit) ? props.properties.adUnit : '';

	const moduleProps = {
		active: getActiveAttributeState(props),
		isDisplayable: isDisplayable,
		path: '/' + networkCode + '/' + adUnit,
	};

	moduleProps.adDeviceSizes = {
		desktop: [],
		tablet: [],
		phone: []
	};
	if (!isEmpty(props.properties) && !isEmpty(props.properties.sizes)) {
		moduleProps.adDeviceSizes.desktop = !isEmpty(props.properties.sizes.desktop) && !isEmpty(props.properties.sizes.desktop.ads) ? props.properties.sizes.desktop.ads : [];
		moduleProps.adDeviceSizes.tablet = !isEmpty(props.properties.sizes.tablet) && !isEmpty(props.properties.sizes.tablet.ads) ? props.properties.sizes.tablet.ads : [];
		moduleProps.adDeviceSizes.phone = !isEmpty(props.properties.sizes.phone) && !isEmpty(props.properties.sizes.phone.ads) ? props.properties.sizes.phone.ads : [];
	}

	// set to default values for each targeting property
	moduleProps.adTargetingProperties = {
		'issue': !isEmpty(globalState.currentIssueUrl) ? globalState.currentIssueUrl : '',
		'articleId': !isEmpty(globalState.currentArticleId) ? globalState.currentArticleId : ID_NOT_SET,
		'articleIdentifier':  !isEmpty(globalState.currentArticleId) && globalState.currentArticleId !== ID_NOT_SET ? 'ARTICLE-' + globalState.currentArticleId : '',
		'category': !isEmpty(globalState.currentArticleCategory) ? globalState.currentArticleCategory : '',
		'folio': !isEmpty(globalState.currentFolio) ? globalState.currentFolio : '',
	};
	// values for refresh are similar to targeting; swap pane for issueUrl
	moduleProps.adRefreshProperties = {
		articleId: !isEmpty(globalState.currentArticleId) ? globalState.currentArticleId : ID_NOT_SET,
		category: !isEmpty(globalState.currentArticleCategory) ? globalState.currentArticleCategory : '',
		folio: !isEmpty(globalState.currentFolio) ? globalState.currentFolio : '',
		pane: !isEmpty(globalState.currentPane) ? globalState.currentPane : '',
	};

	return moduleProps;
};


function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GoogleAdModule);
