import $ from 'jquery';
import {getStoreValue, setStoreValue} from "../../utils/storeValue";
import {getCurrentStructureId} from "../../structures/structures";
import getDeviceType from "../../utils/getDeviceType";
import {getObjectFromJSON, clone} from "../../utils/objectUtils";
import * as Tracking from '../../utils/tracking';
import {
	updateOnNavigationChange,
	getModuleContainer,
	getGlobalDataValue,
	setGlobalDataValue,
	getModuleAttributesByLocation
} from "./globalLibraryData";
import {SEARCH_RESULTS, ID_NOT_SET} from "../_MODULE_GLOBALS/constants";
import {manageShareable} from "./shareUrl";
import {isEmpty, isTrue} from "../../utils/generalUtils";
import {resetTemplateClass} from "../../utils/resetTemplateClass";
import {updateReplaceableMultiple} from "../../utils/stringUtils";
import {retrieveFromCache} from "../../utils/manageStoreCacheData";
import {goHome} from "../common/staticButton/buttonWidgets/homeButton";


/**
 * Match up the navigationKey/navigationTarget attributes to the attributes used to
 * populate the url and to the actual fetch attributes used by modules for a given key
 * and module name.
 *
 * @type object of matches
 */
const navigationKeyMatch = {
	article: {
		"articleViewer": {url: {attribute: "item", values: ["issueUrl", "articleId"]}, fetch: "issueUrl"}
	},
	articleList: {
		"articleList": {url: {attribute: "document", values: ["issueUrl"]}, fetch: "issueUrl"},
	},
	issue: {
		"articleList": {url: {attribute: "document", values: ["issueUrl"]}, fetch: "issueUrl"},
		"replicaList": {url: {attribute: "document", values: ["issueUrl"]}, fetch: "issueUrl"},
		"articleViewer": {url: {attribute: "item", values: ["issueUrl", "articleId"]}, fetch: "issueUrl"}
	},
	category: {
		"articleList": {url: {attribute: "category", values: ["categories"]}, fetch: "categories"},
		"articleViewer": {url: {attribute: "item", values: ["issueUrl", "articleId"]}, fetch: "issueUrl"}
	},
	searchFilter: {
		// null generates a url for "search" with no path parameters
		"searchResults": {url: {attribute: "search", values: [null]}}
	},
	clearSearchFilter: {
		// null generates a url for "search" with no path parameters
		"searchResults": {url: {attribute: "search", values: [null]}}
	},
	replica: {
		"replicaList": {url: {attribute: "document", values: ["issueUrl"]}, fetch: "issueUrl"},
		"replicaViewer": {url: {attribute: "page", values: ["issueUrl", "folio"]}, fetch: "issueUrl"}
	},
	replicaList: {
		"replicaList": {url: {attribute: "document", values: ["issueUrl"]}, fetch: "issueUrl"},
	},
	noMatch: {url: {attribute: "", values: [""]}, fetch: ""}
};
export {navigationKeyMatch};

/**
 * NOTE: CALLED ONCE AT INITIALIZATION
 *
 * Make a call to go to the first defined pane in the current structure.
 * Set the current structure name into the store.
 *
 * For Tracking, check to see if there is a url attribute (target) that indicates that
 * the final destination is other than the home pane.  If the destination target is
 * empty, then the final destination is the home pane and let tracking happen.
 *
 * Note: This will always start at the first(home) pane.  Another function (navigateFromTarget)
 * will re-direct to a final destination if the url has a target destination.
 */
const setInitialPaneNavigation = () => {
	const structures = getStoreValue({attributeKey: 'structures'});
	if (structures !== null) {
		const device = getDeviceType();
		const currentStructure = structures[device];
		const firstPane = currentStructure.paneDisplay[0].id;
		setStoreValue({attributeKey: 'currentStructure'}, currentStructure.structureName);
		const target = getStoreValue({attributeKey: 'urlNavigationTarget', default: {}});

		// goToPane will store initial values for currentPane and previousPanes
		goToPane(firstPane, [], {trackPane: isEmpty(target)});
	}
};
export {setInitialPaneNavigation};


/**
 * Given a currentPane, generate tracking properties for the landing pane that includes
 *     pane label
 *     structure name
 *     module data for the pane
 *
 * @param currentPane
 * @returns {{name, label: (*|number|string), type: string, structure: *, modules: {}}}
 */
const generatePaneTrackingProperties = (currentPane) => {
	const currentStructure = getStoreValue({attributeKey: 'currentStructure', default: ''});

	const structures = getStoreValue({attributeKey: 'structures', default: ''});
	const currentStructureAttributes = !isEmpty(structures[currentStructure]) ? structures[currentStructure] : {};
	const structurePaneDisplay = !isEmpty(currentStructureAttributes.paneDisplay) ? currentStructureAttributes.paneDisplay : [];
	let paneAttributes = structurePaneDisplay.find(pane => pane.id === currentPane);
	paneAttributes = !isEmpty(paneAttributes) ? paneAttributes : {};

	const moduleInstances = getStoreValue({attributeKey: 'moduleInstances', default: {}});
	const structureModules = !isEmpty(moduleInstances[currentStructure]) ? moduleInstances[currentStructure] : {};
	const paneContainers = !isEmpty(structureModules[currentPane]) ? structureModules[currentPane] : {};

	const trackingProperties = {
		"type": "library view",
		"label": currentPane,
		"name": !isEmpty(paneAttributes.label) ? paneAttributes.label : (!isEmpty(paneAttributes.heading) ? paneAttributes.heading : ''),
		"structure": currentStructure,
		"device size": getDeviceType(),
		"modules": {}
	};

	Object.keys(paneContainers).forEach(container => {
		trackingProperties.modules[container] = !isEmpty(trackingProperties.modules[container]) ? trackingProperties.modules[container] : [];
		paneContainers[container].forEach(module => {
			const moduleData = {
				moduleName: module.name,
				moduleId: module.moduleId,
				moduleInstance: module.instanceId
			};
			trackingProperties.modules[container].push(moduleData);
		});
	});

	return trackingProperties;
};

/**
 * Find the current pane in the DOM; hide all panes, then show the current pane.
 * Store the current pane and previous panes array in the store, but only if there has
 * been a change in the current pane which signals that you have gone to another pane.
 *
 * Note: Everything passes through this function, so it is a good place for the call
 * that captures changes to structures and panes.
 *
 * @param currentPane current pane to show
 * @param previousPanes previous panes array
 * @param options additional options
 *     trackPane: true: send tracking call for pane display
 */
const goToPane = (currentPane, previousPanes, options) => {
	options = Object.assign({
		trackPane: true
	}, options);
	// note: an empty string will throw an error when trying to do $('#'+currentPane) later
	// $('#") is an error in jQuery, but $('#'+null) is just fine...
	const previousCurrentPane = getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''});
	currentPane = (typeof currentPane !== 'undefined' && currentPane !== '') ? currentPane : null;
	previousPanes = typeof previousPanes !== 'undefined' ? previousPanes : [];
	const $panes = $('.pane');
	const $currentPane = currentPane !== '' ? $('#'+currentPane) : [];
	if ($currentPane.length > 0) {
		$panes.hide();
		$currentPane.show();
		// check to see if currentPane has changed; if so reset store values
		if (currentPane !== previousCurrentPane) {
			if (isTrue(options.trackPane, {defaultValue: true})) {
				const trackingProperties = generatePaneTrackingProperties(currentPane);
				Tracking.libraryTrack("content viewed", trackingProperties);
			}
			// get updateData function; call updateData for globals (currentPane)
			setGlobalDataValue({attributeKey: 'currentPane'}, currentPane, {callUpdateData: true});
			setGlobalDataValue({attributeKey: 'previousPanes'}, previousPanes, {callUpdateData: false, shallow: true});
		}
	}
	// global reaction to updates and navigation changes
	updateOnNavigationChange({
		currentPane: currentPane,
		previousCurrentPane: previousCurrentPane,
	});
};


/**
 * When a device boundary is crossed (ie. 'lg' to 'md'), check to see if the
 * structure type has changed (ex. LARGE to SMALL).  If so, get the new structure.
 * Get the currentStructure from the store and the newStructure based on the
 * device type.
 *
 * Then, match up the related panes for each structure and update the currentPane
 * and previousPanes array (generate newCurrentPane and newPreviousPanes) array,
 * based on the related parameter in the structures' definitions.
 *
 * Store the newStructure in the store as currentStructure
 *
 * Call goToPane with the newCurrentPane and newPreviousPanes, which will go to
 * the new, related pane and store the pane objects in the store.
 *
 * @param device browser device size
 */
const resetPaneNavigation = (device) => {
	device = typeof device !== 'undefined' ? device : getDeviceType();
	const structures = getStoreValue({attributeKey: 'structures'}) || {};
	const currentStructure = getStoreValue({attributeKey: 'currentStructure'});
	const newStructure = getCurrentStructureId(device);
	const currentPane = getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''});
	const previousPanes = getGlobalDataValue({attr: 'previousPanes', simple: false, defaultValue: [], clone: true});

	let newCurrentPane = currentPane !== null ? currentPane : structures[newStructure].paneDisplay[0].id;
	let newPreviousPanes = !isEmpty(previousPanes) ? previousPanes : [];
	let matchedPane = {};
	let related = {};
	if (newStructure !== currentStructure) {
		const currentStructurePanes = structures[currentStructure].structurePanes;
		matchedPane = currentStructurePanes.find((pane) => {
			return pane.paneName === currentPane;
		});
		related = typeof matchedPane !== 'undefined' ? matchedPane.related : {};
		newCurrentPane = related.hasOwnProperty(newStructure) ? related[newStructure] : newStructure.paneDisplay[0].id;

		previousPanes.forEach(previousPane => {
			matchedPane = currentStructurePanes.find((pane) => {
				return pane.paneName === previousPane;
			});
			related = typeof matchedPane !== 'undefined' ? matchedPane.related : {};
			if (related.hasOwnProperty(newStructure)) {
				newPreviousPanes.push(related[newStructure]);
			} else {
				newPreviousPanes.push(newStructure.paneDisplay[0].id);
			}
		});
	}
	// note: goToPane will store current/previous panes
	setStoreValue({attributeKey: 'currentStructure'}, newStructure);
	goToPane(newCurrentPane, newPreviousPanes);
};
export {resetPaneNavigation};


/**
 * Find and return the pane that a module is located in, based on the device size
 *
 * @param params
 *     module: module name
 *     storageKey: module storageKey
 * @returns {*}
 */
const getCurrentPane = (params) => {
	params = Object.assign({
		module: '',
		storageKey: ''
	}, params);
	const $panes = $('.pane');
	const paneLocation = getModuleContainer({module: params.module, storageKey: params.storageKey});
	let currentPane = paneLocation.hasOwnProperty('pane') ? paneLocation.pane : '';
	if (currentPane === '') {
		console.error("Pane location for navigation has not been configured.");
		currentPane = $panes[0].id;
	} else {
		currentPane = $('#'+currentPane).length > 0 ? currentPane : $panes[0].id;
	}

	return currentPane;
};

/**
 * Manage navigation between panes.  This involves showing/hiding panes and setting
 * current/previous pane values in the redux store.
 *
 * Generate a specific navigation locationKey from configured navigationKeys for the
 * specific modules from/to clicked modules.  This key is then used to lookup the
 * pane on which the called module is located from a previously generated set of
 * paneLocations from all configured modules.
 *
 * If we can't find the pane to go to, just stay on the current pane.
 *
 * Usually only called internally.
 *
 * Note: If there are no panes, don't bother trying to do anything.
 *
 * @param params
 *     moduleKey: attributes for the first module from configured navigationKeys for specific navigationKey
 *     shareParams: properties to store for shareable pages, for later use by manageUpdateAndNavigation
 *         navigationKey: key for navigation
 *         navigationKeys: set of navigation keys for the module
 *         attributes: module attributes
 *     additionalParams: any additional params to add for share
 */
const navigateToPane = (params) => {
	params = Object.assign({
		moduleKey: {},
		shareParams: {}
	}, params);
	const moduleKey = params.moduleKey;
	const shareParams = Object.assign({
		navigationKey: '',
		attributes: {},
		moduleProps: {},
	}, params.shareParams);

	const $panes = $('.pane');
	if ($panes.length > 0) {
		const currentPane = getCurrentPane({module: moduleKey.module, storageKey: moduleKey.storageKey});
		const previousPane = getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''});
		const previousPanes = getGlobalDataValue({attr: 'previousPanes', simple: false, defaultValue: [], clone: true});
		previousPanes.push(previousPane);

		// set browser history (url) for share through navigate to module; default is true
		// should be set to false if target module will set share itself
		const setShareHistory = isTrue(shareParams.attributes.setShareHistory, {defaultValue: true});

		/**
		 * Call to update url, if possible; function will check if browser history needs updating
		 *     notShareable (optional) set by calling module
		 *        true: browser history (share) may be set through navigateToPane
		 *     setShareHistory (optional) set by calling module
		 *         true: browser history (share) should be set through navigateToPane and not directly
		 */
		if (!isTrue(shareParams.attributes.notShareable) && isTrue(setShareHistory)) {
			manageShareable({
				moduleKey: moduleKey,
				pane: currentPane,
				shareParams: shareParams,
			});
		}

		// call, on navigation, to (potentially) go to a new pane
		// "NOTARGET" module or "noTarget" attribute signals no navigation; ie. stay on the same pane
		const noShare = isTrue(moduleKey.module === 'NOTARGET') || isTrue(shareParams.attributes.noNavigation);
		if (!noShare) {
			goToPane(currentPane, previousPanes);
		}
	} else {
		// just set to empty string; nothing to do
		setGlobalDataValue({attributeKey: 'currentPane'}, '', {callUpdateData: true});
		setGlobalDataValue({attributeKey: 'previousPanes'}, [], {callUpdateData: false, shallow: true});
	}
};
export {navigateToPane};


/**
 * Call to perform a simple update store data for all storage keys belonging to
 * the modules defined in a navigationTarget.  This simply allows the caller to
 * use already defined lists to perform multiple updates.
 *
 * @param params
 *     navigationKey: key to determine specific navigation target
 *     navigationTargets: list of targets; if not defined, get from store
 *     attributes: updates to use to update storage data
 */
const updateNavigationTargets = (params) => {
	params = Object.assign({
		navigationKey: '',
		navigationTargets: {},
		attributes: {}
	}, params);

	const navigationTargets = isEmpty(params.navigationTargets) ? getStoreValue({attributeKey: 'navigationTargets'}) : params.navigationTargets;
	// if no matching navigationTargets defined, just exit without doing anything
	if (isEmpty(navigationTargets) || !navigationTargets.hasOwnProperty(params.navigationKey)) {
		return;
	}
	const moduleKeys = navigationTargets.hasOwnProperty(params.navigationKey) && navigationTargets[params.navigationKey].hasOwnProperty('modules') ?
		navigationTargets[params.navigationKey].modules :
		[];
	// get updateData function; call updateData for each configured module
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
	const moduleKeyAttributes = clone(params.attributes);
	moduleKeys.forEach((moduleKey) => {
		updateData(moduleKeyAttributes, {storageKey: moduleKey.storageKey});
	});
};
export {updateNavigationTargets};

/**
 * Manage the call from a module that is designed to trigger the display of
 * another module.
 *
 * If there are navigationKeys for the trigger, then call updateData to
 * update the redux store with the appropriate attributes.
 *
 * Call navigateToPane to handle navigation to another pane if warranted.
 *
 * @param params
 *     navigationKey: the type of navigation being called; for lookup in configured navigationKeys
 *     moduleProps: props from calling module
 *     attributes: for updateData
 */
const manageUpdateAndNavigation = (params) => {
	params = Object.assign({
		navigationKey: '',
		moduleProps: {},
		attributes: {}
	}, params);
	const navigationKey = params.navigationKey;
	const attributes = clone(params.attributes);
	const moduleId = params.moduleProps.moduleId ? params.moduleProps.moduleId : '';
	const instanceId = params.moduleProps.instanceId ? params.moduleProps.instanceId : '';
	const fromHistory = isTrue(attributes.fromHistory);
	const generalNavigationAttributes = {
		navigationKey: navigationKey,
		fromModule: moduleId,
		fromInstance: instanceId,
		fromHistory: fromHistory
	};
	// if fetchQueryParams not already set; check if module props has attribute; otherwise, leave as is
	if (!attributes.hasOwnProperty('fetchQueryParams') && params.moduleProps.hasOwnProperty('fetchQueryParams')) {
		attributes.fetchQueryParams = params.moduleProps.fetchQueryParams;
	}

	// get navigationKeys from params and convert to javascript object from configuration string, then find module keys for the specific key
	const navigationKeys = getObjectFromJSON(params.moduleProps.navigationKeys, {});
	const moduleKeys = navigationKeys.hasOwnProperty(navigationKey) && navigationKeys[navigationKey].hasOwnProperty('modules') ?
		navigationKeys[navigationKey].modules :
		[];

	const triggerModule = params.moduleProps.name ? params.moduleProps.name : '';

	// get updateData function; call updateData for each configured module
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
	moduleKeys.forEach((moduleKey) => {
		const moduleKeyAttributes = clone(attributes);
		// optional navigation attributes; attach to module via storageKey
		const moduleNavigationAttributes = moduleKey.hasOwnProperty('attributes') ? moduleKey.attributes : {};
		// assign to empty object each time to reset attributes
		moduleKeyAttributes.navigationAttributes = Object.assign({}, generalNavigationAttributes, moduleNavigationAttributes);

		const actionType = (moduleKey.hasOwnProperty('reducerAction') && moduleKey.reducerAction !== null) ? moduleKey.reducerAction.toUpperCase() : triggerModule.toUpperCase();
		delete(moduleKeyAttributes.replicaList);
		updateData(moduleKeyAttributes, {type: actionType, storageKey: moduleKey.storageKey});
	});

	const moduleKey = moduleKeys.length > 0 ? moduleKeys[0] : {};

	// call navigateToPane to trigger showing/hiding panes if eventTrigger passed in
	// populate navigationKeys into moduleProps subset to pass along to share; this attribute
	// will be further populated in manageShareable for history store
	const shareParams = {
		navigationKey: navigationKey,
		attributes: attributes,
		moduleProps: {
			navigationKeys: navigationKeys
		}
	};
	navigateToPane({moduleKey: moduleKey, shareParams: shareParams});
};
export {manageUpdateAndNavigation};


/**
 * Manage the call from a module that is designed to trigger updates for multiple modules.
 *
 * This call uses the configured navigationKeys for the trigger, and calls updateData to
 * update the redux store with the appropriate attributes for each targeted module.
 *
 * Note: This uses navigationKeys and navigationTargets to ge the list of module store states
 * to update, even though it doesn't trigger navigation.  The list of listener modules is the
 * same as for manageUpdateAndNavigation.
 *
 * @param params
 *     navigationKey: the type of navigation being called; for lookup in configured navigationKeys
 *     navigationKeys: the configured navigation attributes
 *     attributes: for updateData
 */
const manageUpdate = (params) => {
	params = Object.assign({
		navigationKey: '',
		navigationKeys: {},
		attributes: {}
	}, params);
	const navigationKey = params.navigationKey;
	const attributes = clone(params.attributes);

	// get navigationKeys from params and convert to javascript object from configuration string, then find module keys for the specific key
	const navigationKeys = getObjectFromJSON(params.navigationKeys, {});
	const moduleKeys = navigationKeys.hasOwnProperty(navigationKey) && navigationKeys[navigationKey].hasOwnProperty('modules') ?
		navigationKeys[navigationKey].modules :
		[];

	// get updateData function; call updateData for each configured module
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
	moduleKeys.forEach((moduleKey) => {
		const moduleKeyAttributes = clone(attributes);
		const actionType = (moduleKey.hasOwnProperty('reducerAction') && moduleKey.reducerAction !== null) ? moduleKey.reducerAction.toUpperCase() : '';
		updateData(moduleKeyAttributes, {type: actionType, storageKey: moduleKey.storageKey});
	});
};
export {manageUpdate};


/**
 * Handle navigation to a page when given a target attribute.  Usually, this is called, dependent on
 * target parameters in the url, but can also be called from elsewhere.
 * Current targets
 *     article: url: item/{articleId}
 *     issue: url: document/{issueUrl}
 *     category: url: category/{category}
 *
 * @param params
 *     targetKey: target key value
 *     replacementValues: replacement parameters for Navigation Targets configuration options
 *     attributes: initial attributes object to pass to manageUpdateAndNavigation
 */
const navigateToTarget = (params) => {
	params = Object.assign({
		targetKey: '',
		navigationTargets: {},
		replacementValues: {},
		attributes: {},
		additionalProps: {}
	}, params);
	const navigationTargets = isEmpty(params.navigationTargets) ? getStoreValue({attributeKey: 'navigationTargets'}) : params.navigationTargets;
	// if no matching navigationTargets defined, just exit without doing anything
	if (isEmpty(navigationTargets) || !navigationTargets.hasOwnProperty(params.targetKey)) {
		return;
	}

	const navigationTarget = navigationTargets[params.targetKey];
	const targetModules = updateReplaceableMultiple({original: navigationTarget.modules, replacementValues: params.replacementValues});

	// usually don't need to override defaults
	const attributes = Object.assign({
		forceFetch: false,
		replaceHistoryState: false,
		fromHistory: false
	}, params.attributes);

	const moduleProps = Object.assign({
		navigationKeys: {
			[params.targetKey]: {
				modules: targetModules
			}
		}
	}, params.additionalProps);

	manageUpdateAndNavigation({
		attributes: attributes,
		navigationKey: params.targetKey,
		moduleProps: moduleProps

	});

};
export {navigateToTarget};


/**
 * Called from populateStructure as the first thing when the Content Hub starts.
 * If there is a recognized parameter in the URL, this will restructure the
 * attributes from configuration in a format that manageUpdateAndNavigation
 * recognizes and calls that to force a navigation to the correct page and
 * module(s).
 *
 * Since each target type has different requirements, we just go through
 * and see if there is a match for any one, in the following order.
 * Current recognized URL parameters in order:
 *     articleId
 *     issueName
 *     category
 *
 * All of the navigationTarget definitions have replaceable parameters.  If they occur
 * in the modules definition, then we replace them before calling manageUpdateAndNavigation.
 * These are part of the fetchAttributes property of navigationTargets.
 *
 * replaceable parameters
 *     "articleId": sets articleId directly and replaces {issueUrl} property
 *     "issue":  sets articleId to ID_NOT_SET and replaces {issueUrl} property
 *     "category":  sets articleId to ID_NOT_SET and replaces {categories} property
 *
 */
const navigateFromTarget = (params) => {
	params = Object.assign({
		target: null
	}, params);
	const target = params.target !== null ? params.target : getStoreValue({attributeKey: 'urlNavigationTarget'});

	if (target.hasOwnProperty('articleId') && target.hasOwnProperty('issueUrl')) {
		navigateToTarget({
			targetKey: 'article',
			replacementValues: {
				issueUrl: target.hasOwnProperty('issueUrl') ? target.issueUrl : ''
			},
			attributes: {
				articleId: target.articleId,
				issueUrl: target.issueUrl,
				section: target.section,
				urlHash: !isEmpty(window.location.hash) ? window.location.hash : '',  // currently only valid for article
				forceFetch: true,
				replaceHistoryState: true,
				shareQueryParams: !isEmpty(target.section) ? {section: target.section} : {}
			}
		});
	} else if (target.hasOwnProperty('folio') && target.hasOwnProperty('issueUrl')) {
		navigateToTarget({
			targetKey: 'replica',
			replacementValues: {
				issueUrl: target.hasOwnProperty('issueUrl') ? target.issueUrl : ''
			},
			attributes: {
				folio: target.folio,
				issueUrl: target.issueUrl,
				forceFetch: true,
				replaceHistoryState: true
			}
		});
	} else if (target.hasOwnProperty('searchFilters')) {
		const searchFilters = target.searchFilters;
		navigateToTarget({
			targetKey: 'searchFilter',
			replacementValues: {},
			additionalProps: {
				defaultOverride: true
			},
			attributes: {
				forceFetch: true,
				replaceHistoryState: true,
				searchEntryFilter: searchFilters.entry,
				searchIssuesFilter: isEmpty(searchFilters.issueUrl) ? '' : searchFilters.issueUrl,
				searchTypeFilter: searchFilters.type,
				searchSortFilter: searchFilters.sortBy,
				searchArticleSearchFilter: searchFilters.articleSearch,
				shareQueryParams: searchFilters
			}
		});
	} else if (target.hasOwnProperty('issueUrl')) {
		// going to document (issue) is treated as a special case of issueList click
		// get navigation configuration values for issueList and use that for navigation
		// defaults
		// if issueList not found/configured or no target, just use "internal" as destination
		// and "issue" as the targetKey
		let destination = 'internal';
		let targetKey = 'issue';
		let externalLink = '';
		const documentList = getStoreValue({attributeKey: 'documentList', default: {}});
		const document = documentList.find(doc => doc.issueUrl === target.issueUrl);
		if (!isEmpty(document)) {
			externalLink = !isEmpty(document.documentLink) ? document.documentLink : '';
			// we are looking for attributes of a configured issueList module and not all collections have this
			// properties of issueList configuration take precedence to determine navigation
			const issueListConfiguration = getModuleAttributesByLocation({module: 'issueList', attributes: ['navigationKey', 'navigationKeys', 'replicaOnlyNavigationKey'], default: {}});
			if (!isEmpty(issueListConfiguration)) {
				// if no articles, check replicaOnlyKey else just use navigationKey
				if (!isTrue(document.hasArticles, {default: true})) {
					targetKey = !isEmpty(issueListConfiguration.replicaOnlyNavigationKey) ? issueListConfiguration.replicaOnlyNavigationKey : 'replica';
				} else {
					targetKey = issueListConfiguration.navigationKey;
				}
				if (!isEmpty(issueListConfiguration.navigationKeys) && !isEmpty(issueListConfiguration.navigationKeys[targetKey])) {
					if (!isEmpty(issueListConfiguration.navigationKeys[targetKey].destination)) {
						const device = getDeviceType();
						if (issueListConfiguration.navigationKeys[targetKey].destination.hasOwnProperty(device)) {
							destination = issueListConfiguration.navigationKeys[targetKey].destination[device];
						} else {
							destination = issueListConfiguration.navigationKeys[targetKey].destination.default;
						}
					}
				}
			}
		}

		if (destination === 'external' && !isEmpty(externalLink)) {
			// navigate to external link
			$('#root').empty();  // clear DOM to prevent library framework from showing
			window.location.href = externalLink;
		} else {
			navigateToTarget({
				targetKey: targetKey,
				replacementValues: {issueUrl: target.issueUrl},
				attributes: {
					issueUrl: target.issueUrl,
					articleId: ID_NOT_SET,
					forceFetch: true,
					replaceHistoryState: true,
				}
			});
		}
	} else if (target.hasOwnProperty('category')) {
		navigateToTarget({
			targetKey: 'category',
			// "{categories}" is the replacement attribute, because multiple categories can be used for fetch
			replacementValues: {categories: target.category},
			attributes: {
				category: target.category,
				articleId: ID_NOT_SET,
				forceFetch: true,
				replaceHistoryState: true,
			}
		});
	} else if (isEmpty(target)) {
		// empty for now; maybe fill in later if we add url parameters at the start
		// back button will handle an empty history by making a goHome() call
		goHome({
			replaceHistoryState: true,
			forceFetch: false,
			targetKey: 'home'
		});
	}
};
export {navigateFromTarget};


/**
 * Handle the browser back/forward button.
 *
 * window.history.state values are stored in the history in a function in the shareUrl.js file,
 * where the share parameters are setup.
 * Large data objects which can be repeated are stored in the redux store in hashkey storage and only
 * the hash key for the stored oabject is stored in the history.
 *
 * Once the data is retrieved from history, the objects in hashkey storage are populated into the navigationParams
 * which are then used in a call to the standard manageUpdateAndNavigation function, which handles navigation
 * to the correct module and page.
 *
 * If nothing is retrieved from the history on the back button action (for example, start page),
 * then call to go to the home page.  This may not be correct many cases, we can update when/if we
 * find other cases.
 *
 * @param event browser back or forward button pressed
 */
const handleBrowserBack = (event) => {
	const navigationParamsHash = window.history.state;
	const historyStore = retrieveFromCache({dataKey: navigationParamsHash, keyType: 'history', emptyDataObject: {}});
	if (historyStore.hasStoredData) {
		const historyStoreData = historyStore.storedData;
		const navigationParams = historyStoreData.shareParams;
		// get articleList from hashkey storage and add to attributes; replace navigationKeys (hash) with navigationKeys from hashkey storage
		navigationParams.attributes.articleList = retrieveFromCache({dataKey: navigationParams.attributes.fetchQueryParams, emptyDataObject: []}).storedData;
		navigationParams.moduleProps.navigationKeys = retrieveFromCache({dataKey: navigationParams.moduleProps.navigationKeys, emptyDataObject: {}}).storedData;
		navigationParams.attributes.fromHistory = true;
		manageUpdateAndNavigation(navigationParams);
	} else {
		goHome({
			fromHistory: true
		});
	}
};
export {handleBrowserBack};
