import React, {useRef, useState, useEffect} from 'react';
import {addClass} from "../../../utils/generateClassName";
import {findReplicaPage, getReplicaPages} from "../../../utils/replicaList/replicaActions";
import {isEmpty, isTrue} from "../../../utils/generalUtils";
import {navigateToTarget} from "../../globals/globalNavigator";
import {Translate} from "../../../locales/locales";
import * as Tracking from "../../../utils/tracking";
import {GLOBALS} from "../../_MODULE_GLOBALS/constants";
import {getStoreValue} from "../../../utils/storeValue";

/**
 * Handle click from Go to Page.
 *
 * Note: the folio value is an input field entry, so can be entered with the
 *     wrong case or have spaces.  We trim the spaces and handle case, when returning
 *     a matching replica page.
 *     Then, we get the actual folio value from the replicaPage object and use that
 *     for further function calls.
 *
 * @param params
 *     evt: click or keyboard event
 *     props: props for widget
 *     folioValue: folio string entered in input field
 *     DOMElements: react DOM elements for widget
 */
const goToReplicaPage = (params) => {
	params = Object.assign({
		evt: null,
		props: {},
		folioValue: '',
		DOMElements: {}
	}, params);
	const {props, DOMElements} = params;
	const storeState = !isEmpty(props.storeState) ? props.storeState : {};
	const sourceFolio = !isEmpty(storeState) ? storeState.folio : '';
	const replicaList = storeState.replicaList;
	const pageSpread = storeState.pageSpread;
	const pageAttributes = {
		replicaList: replicaList,
		pageSpread: pageSpread,
	};
	const pagesData = getReplicaPages({folio: sourceFolio, pageAttributes: pageAttributes});
	const pagesFolios = [];
	pagesData.pages.forEach(page => {
		pagesFolios.push(page.folio);
	});

	const folioValue = !isEmpty(params.folioValue) ? params.folioValue.trim() : '';
	const replicaPage = findReplicaPage({replicaList: replicaList, folio: folioValue});

	if (isEmpty(replicaPage)) {
		displayErrorMessage({evt: params.evt, DOMElements: DOMElements, folioValue: folioValue, resetFocus: true});
	} else {
		const folio = replicaPage.folio;  // get actual, case-sensitive folio from replica page object
		const trackingProperties = {
			"destination folio": folio,
			"folio": pagesFolios,
			"label": "Go to Page #",
			"location": "replica viewer",
			"module": "go to page",
			"type": "click",
			"url": (window ? window.location.href : ''),
		};
		Tracking.libraryTrack("button clicked", trackingProperties);

		clearErrorMessage({evt: params.evt, DOMElements: DOMElements, resetFocus: false});
		navigateToTarget({
			targetKey: !isEmpty(props.navigationKey) ? props.navigationKey : '',
			navigationTargets: !isEmpty(props.navigationKeys) ? props.navigationKeys : {},
			replacementValues: !isEmpty(props.replacementValues) ? props.replacementValues : {},
			attributes: {
				folio: folio,
				issueUrl: !isEmpty(props.issueUrl) ? props.issueUrl : '',
				replicaList: replicaList,
				fetchQueryParams: !isEmpty(storeState.fetchQueryParams) ? storeState.fetchQueryParams : {}
			}
		});
	}
};

/**
 * Handle the display of the "page not found" error message.
 * "errorTimeoutID" is used to manage clearing the timeout if the
 * user manually clears the error message dialog.
 *
 * @param params
 *     evt: click or keyboard event
 *     DOMElements: react DOM elements for widget
 *     folioValue: folio string entered in input field
 */
let errorTimeoutID;
const displayErrorMessage = (params) => {
	params = Object.assign({
		evt: null,
		DOMElements: {},
		folioValue: ''
	}, params);
	const errorElement = params.DOMElements.error.current !== null ? params.DOMElements.error.current : null;
	const errorElementWrapper = params.DOMElements.errorWrapper.current !== null ? params.DOMElements.errorWrapper.current : null;
	errorElementWrapper.classList.add("display-error");
	errorElement.innerHTML = Translate.Text({id: 'actionButton.gotoPageButton.noPageError'});
	errorElement.focus();
	errorTimeoutID = setTimeout(() => {
		clearErrorMessage(params);
	},5000);
};

/**
 * When the error message loses focus or is clicked on, hide the error message and clear the input
 * element.  If the error element was clicked on, set focus back to the input element.
 *
 * @param params
 *     evt: blur or click event
 *     DOMElements: react DOM elements for widget
 *     resetFocus: true: set focus to input field; false: leave focus where it is
 */
const clearErrorMessage = (params) => {
	params = Object.assign({
		evt: null,
		DOMElements: {},
		resetFocus: false
	}, params);

	clearTimeout(errorTimeoutID);
	const errorElementWrapper = params.DOMElements.errorWrapper.current !== null ? params.DOMElements.errorWrapper.current : null;
	const inputElement = params.DOMElements.input.current !== null ? params.DOMElements.input.current : null;
	errorElementWrapper.classList.remove('display-error');
	inputElement.value = "";
	if (isTrue(params.resetFocus)) {
		inputElement.focus();
	}
};

/**
 * Handle Enter key press in input field.  If Enter, then pass all params to goToReplicaPage.
 *
 * @param params
 */
const handleKeyPress = (params) => {
	if (params.evt.key === 'Enter') {
		goToReplicaPage(params);
	}
};


/**
 * Start of jsx code for module
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const GoToPage = (props) => {
	props = Object.assign({
		GeneratedTag: `button`,
		className: '',
		title: '',
		label: '',
		replacementValues: {},
		navigationKey: '',
		navigationKeys: {},
		storeState: {},
		issueUrl: ''
	}, props);

	const [inputValue, setInputValue] = useState("");

	const className = addClass('replica-viewer-control go-to-replica-page', props.className);
	const buttonClassName = 'go-to-replica-page-button';
	const label = !isEmpty(props.label) ? Translate.Text({id: props.label}) : Translate.Text({id: 'actionButton.gotoPageButton.label'});
	const title = !isEmpty(props.title) ? Translate.Text({id: props.title}) : Translate.Text({id: 'actionButton.gotoPageButton.title'});
	const errorTitle = Translate.Text({id: 'actionButton.gotoPageButton.errorTitle'});

	const DOMElements = {
		input: useRef(null),
		error: useRef(null),
		errorWrapper: useRef(null)
	};

	const folios = getStoreValue({type: GLOBALS, storageKey: "", attributeKey:"globals.currentPageFolios"});

	useEffect(() => {
		setInputValue(folios);
	}, [folios]);

	return (
		<div className={className}>
			<label>{label}</label>
			<input
				type={"text"} name="gotoReplicaPage" className={"page-number-selector"} ref={DOMElements.input}
				aria-label={title} value={inputValue}
				onChange={e => setInputValue(e.target.value)}
				onKeyDown={(evt) => {
					const folioValue = DOMElements.input.current !== null ? DOMElements.input.current.value : '';
					setInputValue(folioValue);
					handleKeyPress({evt: evt, props: props, folioValue: folioValue, DOMElements: DOMElements});
				}}
			/>
			<button
				type={"button"} name={"gotoReplicaPageSubmit"} className={buttonClassName}
				title={title} aria-label={title}
				onClick={(evt) => {
					const folioValue = DOMElements.input.current !== null ? DOMElements.input.current.value : '';
					setInputValue(folioValue);
					goToReplicaPage({evt: evt, props: props, folioValue: folioValue, DOMElements: DOMElements});
				}}
			>
				<span className={'fas fa-arrow-square-right'} />
			</button>
			<div className={"go-to-replica-page-error-wrapper"} ref={DOMElements.errorWrapper}>
				<div className={"go-to-replica-page-error"} ref={DOMElements.error}
					 title={errorTitle} aria-label={errorTitle} role={"dialog"}
					 tabIndex={-1}
					 onBlur={(evt) => {
						 clearErrorMessage({evt: evt, DOMElements: DOMElements, resetFocus: false});
					 }}
					 onClick={(evt) => {
						 clearErrorMessage({evt: evt, DOMElements: DOMElements, resetFocus: true});
					 }}
				/>
			</div>
		</div>
	);
};
export {GoToPage};
