import React from 'react';
import GenerateDefaultArticle from "../../../../templates/article/default";
import GenerateCoverArticle from "../../../../templates/article/cover";
import GenerateFloatedImagesArticle from "../../../../templates/article/floatedImages";
import GenerateFullPageAdArticle from "../../../../templates/article/fullPageAd";
import GenerateFullWidthHtmlAdArticle from "../../../../templates/article/fullWidthHtmlAd";
import GenerateHeroImageArticle from "../../../../templates/article/heroImage";
import {generateSearchMarkers} from "./generateArticleSearchMarkers";
import {setArticleSectionHeaders} from "../content_updaters/setArticleSectionHeaders";


/**
 * Supported templates with their associated functions to generate their content.
 *
 * @type {{cover: (function(*=): JSX.Element), default: (function(*=): JSX.Element), fullPageAd: (function(*=): JSX.Element), floatedImages: (function(*=): JSX.Element), heroImage: (function(*=): JSX.Element), fullWidthHtmlAd: (function(*=): JSX.Element)}}
 */
const articleTemplates = {
	'default': GenerateDefaultArticle,
	'cover': GenerateCoverArticle,
	'floated-images':  GenerateFloatedImagesArticle,
	'full-page-advertisement':  GenerateFullPageAdArticle,
	'toc-advertorial': GenerateFullPageAdArticle,  // this is a full-page ad that shows in the toc
	'full-width-html-ad':  GenerateFullWidthHtmlAdArticle,
	'heroimage':  GenerateHeroImageArticle
};

/**
 * Return the React html for an article, for one of the types above, after it has been processed.
 *
 * @param params
 * @returns {JSX.Element|string}
 * @constructor
 */
const GeneratedArticleContent = (params) => {
	params = Object.assign({
		articleData: {},
		moduleProps: {},
		searchFilters: {}
	}, params);
	const template = params.articleData.hasOwnProperty('template') ? params.articleData.template : 'default';
	const articleTemplate = articleTemplates.hasOwnProperty(template) ? articleTemplates[template] : articleTemplates.default;

	// do a pre-check on article content
	let article = generateSearchMarkers({article: params.articleData.article, attributes: ['content', 'title'], searchFilters: params.searchFilters});

	if (!article.hasOwnProperty('content')) {
		return '';
	} else {
		// generate `data-section` attributes on article <h2> elements
		article = setArticleSectionHeaders(article);
		return articleTemplate({article: article, template: template, moduleProps: params.moduleProps});
	}
};
export {GeneratedArticleContent};
