import {
	DATA_LOADED,
	DATA_UPDATED,
	DISPLAY_ARTICLE_DATA,
	ARTICLE_VIEWER_PROGRESS,
	ARTICLELIST_ARTICLE,
	SEARCH_FILTERS_ARTICLE,
	SHARE_BUTTONS,
	ARTICLE_ARTICLEVIEW
} from "../../_MODULE_GLOBALS/constants";
import {setSearchFilters} from "./search";
import deepmerge from "deepmerge";
import {createNewStoreState} from "./generalReducerActions";

/*
   This reducer fetches and updates an article list or a single article for display
   in the viewer.  It will usually use an array of article objects that have been
   fetched by an articleList, and display one of the articles in that array
 */

/**
 * Required store properties for a module that fetches an article object or
 * lists of articles.
 *
 * This ensures that there is a placeholder in the store for
 * the values returned for the module.
 * Each action-type then initializes the state for it's store state.
 */
const createInitialState = (storageKey) => {
	return {
		[storageKey]: {
			"articleId": '',
			"articleList": [],
			"issueId": '',
			"issueUrl": '',
			"wdsArticleId": '',
			"dataSource": {},
			"fetchListParams": {},
			"fetchQueryParams": null,
			"navigationAttributes": {},
			"section": '',

			"folio": '',
			"pageNumber": 0,
			"replicaList": [],
			"zoomLevel": '',

			"searchFilters": {
				"entry": "",
				"type": null,
				"sortBy": null,
				"issueUrl": null,
				"articleSearch": null
			},

			"inProgress": false,
			"forceFetch": false,
			"replaceHistoryState": false,
			"updateHistoryState": true,
			"urlHash": '',
		}
	};
};

/**
 * List of attributes to reset on each call to reducer.
 *
 * @type {function(*): {}}
 */
const resetStoreState = ((storageKey) => {
	return {
		[storageKey]: {
			"replaceHistoryState": false,
			"updateHistoryState": true,
			"forceStoreUpdate": false,
			"urlHash": '',
		}
	};
});


/**
 * Article View state variables for when the articleViewer is updated with
 * a new list or id.
 * 
 * @param storageKey storage key for article viewer
 * @returns {{}}
 */
const createInitialArticleViewState = (storageKey) => {
	return {
		[storageKey]: {
			"articleViewData": {
				"articleList": [],
				"articleId": '',
			}
		}
	};
};



function articlesReducers(state, action) {
	// check module action call; if not present, set default to ADD_ARTICLE
	const moduleAction = (action.params && action.params.type) ? action.params.type : DISPLAY_ARTICLE_DATA;

	// Action called any time updating article: click article in article list; click zoom level button
	if (moduleAction === ARTICLELIST_ARTICLE && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'articleViewer';

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});

		return returnState;
	}

	if (moduleAction === ARTICLE_VIEWER_PROGRESS && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'articleViewer';
		const returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
		if (action.payload.hasOwnProperty('articleProgress')) {
			returnState[storageKey].inProgress = action.payload.articleProgress;
		}
		return returnState;
	}

	// get notified if search filters change and set using function in search.js
	if (moduleAction === SEARCH_FILTERS_ARTICLE && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'articleViewer';
		const returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
		return setSearchFilters({returnState: returnState, state: state, payload: action.payload, storageKey: storageKey});
	}
/*
	if (moduleAction === SUBSCRIBER && action.type === DATA_LOADED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'globals';
		const returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
		//The SUBSCRIBER action uses "globals" storageKey, so update the global State
		if (storageKey === "globals") {
			returnState[storageKey] = Object.assign(returnState[storageKey], action.payload);
		}
		return returnState;
	}
*/
/*
	if (moduleAction === PURCHASE && ( action.type === DATA_LOADED || action.type === DATA_UPDATED)) {
		//  && ( action.type === DATA_LOADED || action.type === DATA_UPDATED)
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'globals';
		const returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
		//The SUBSCRIBER action uses "globals" storageKey, so update the global State
		if (storageKey === "globals") {
			console.log(action.payload);
//			{ status: 'success',
//  				PurchaseMetadata: '{"status":"success","subscriberId":"CSOUdQRvlOJET","foundExistingSubscriber":false,
//				"verifiedPurchases":[{"purchaseState":"PURCHASED","notificationId":"88",
//				"productId":"com.texterity.hybridecommerce_qa7.20201201124815","orderId":"GPA.3395-5301-7208-39321",
//				"purchaseTime":1616433839460,"purchaseToken":"GPA.3395-5301-7208-39321","developerPayload":"GPA.3395-5301-7208-39321"}]}' }
			//const purchaseMetadata = JSON.parse(action.payload.PurchaseMetadata);
			const purchaseMetadata = action.payload.PurchaseMetadata || {};
			const verifiedPurchases = purchaseMetadata.verifiedPurchases;
			console.log(verifiedPurchases);
			if (verifiedPurchases && verifiedPurchases.length > 0) {
				console.log("successful purchase of " + verifiedPurchases[0].productId);
				returnState[storageKey].purchaseProductId = verifiedPurchases[0].productId;
				returnState[storageKey].isPurchaseSuccessful = true;
				returnState[storageKey].subscriberId = purchaseMetadata.subscriberId;
			}
  			returnState[storageKey] = Object.assign(returnState[storageKey], action.payload);
		}
		console.log(returnState);
		return returnState;
	}
*/
	if (moduleAction === SHARE_BUTTONS && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'articleViewer';
		const returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
		// share buttons attributes
		returnState[storageKey].displayShareButtons = action.payload.displayShareButtons ? action.payload.displayShareButtons : false;
		return returnState;
	}

	// triggered when the article or article list in articleViewer changes
	if (moduleAction === ARTICLE_ARTICLEVIEW && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'articleViewer';

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialArticleViewState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});

		return returnState;
	}

	/*
		if (moduleAction === SEARCH_RESULTS && action.type === DATA_UPDATED) {
			const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'SearchResults';
			const returnState = $.extend(true, {},
				createInitialState(storageKey),
				state
			);
			if (action.payload.hasOwnProperty('searchResults')) {
				returnState[storageKey].articles = action.payload.searchResults;
			}
			return returnState;
		}


		if (moduleAction === ADD_ARTICLE && action.type === DATA_LOADED) {
			const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'ArticleList';
			const returnState = $.extend({},
				createInitialState(storageKey),
				state
			);

			returnState[storageKey].articles = Array.isArray(action.payload.articles) ? action.payload.articles : [];
			returnState[storageKey].collectionUrl = action.payload.collectionUrl ? action.payload.collectionUrl : '';
			return returnState;
		}
	*/

	return state;
}
export default articlesReducers;
