import {findReplicaPage} from "../../../../utils/replicaList/replicaActions";
import $ from "jquery";
import {isEmpty} from "../../../../utils/generalUtils";
import {trackLinkClick} from "../tracking/trackLinkClick";
import {Translate} from "../../../../locales/locales";



/**
 * We must apply a class for sizing to allow the <figure> element to fit closely around
 * each of the replica page images.  This is necessary in order to set
 * the page links correctly into the figure, on top of the image.
 *
 * The class to be applied depends on the aspect ratio of the page image and the replica viewer,
 * in combination with the pageSpread property.
 *
 * @param params
 *     replicaElement: replica viewer (images) container element
 *     imgElement: page folio element
 *     pageSpread: 1/2 page spread
 */
const applyAspectRatioClass = (params) => {
	params = Object.assign({
		replicaElement: {},
		imgElement: null,
		pageSpread: 1
	}, params);
	const {replicaElement, imgElement, pageSpread} = params;

	const aspectRatioImage = !isEmpty(params.imgElement) ? params.imgElement.naturalWidth / params.imgElement.naturalHeight : 1;
	const aspectRationReplicaViewer = !isEmpty(replicaElement) ? (replicaElement.clientWidth / params.pageSpread) / replicaElement.clientHeight : 1;
	if (aspectRatioImage < aspectRationReplicaViewer) {
		$(replicaElement).removeClass('width-bound').addClass('height-bound');
	} else {
		$(replicaElement).removeClass('height-bound').addClass('width-bound');
	}
};


/**
 * Generate DOM elements (<a> tag) representing configured folio page links and add to the
 * enclosing <figure> for the folio page image.
 *
 * Note: For internal linking, we need the "folio" value rather than the "pageNumber" value
 * from the replica page object.  Since links "destPageNumber" corresponds to the pageNumber,
 * we must find the folio value that corresponds to the pageNumber.
 *
 * Note: We must also ensure that the <figure> element "fits" tightly around the img element,
 * so always call applyAspectRatioClass to ensure that happens.+
 *
 * Note: Use jQuery to make it easy to add class and inline styles.
 *
 * @param params
 *     props: module props to pass to gotoPage
 *     replicaList: replica list
 *     figureElement: <figure> DOM element
 *     folio: folio page value
 *     replicaElement: replica container element
 *     imgElement: current img element for page links
 *     pageSpread: page spread attribute
 *     gotoInternalPage: function to call for link that goes to an internal page
 */
const generatePageLinks = (params) => {
	params = Object.assign({
		props: {},
		replicaList: {},
		figureElement: '',
		folio: '',
		replicaElement: '',
		imgElement: '',
		pageSpread: 1,
		gotoInternalPage: null
	}, params);
	const {props, replicaList, figureElement, folio} = params;

	// Call to apply class to allow <figure> to fit correctly on image
	applyAspectRatioClass({replicaElement: params.replicaElement, imgElement: params.imgElement, pageSpread: params.pageSpread});


	const $folio = $(figureElement);
	// for 1/2 page switch, check if the figure element has been instantiated; if not, just return
	if ($folio.length === 0) {
		return;
	}

	// clear out any old added link elements first
	$folio.find("a").remove(".generated-link");

	const externalWebLinkText = Translate.Text({id:'replicaViewer.externalWebLink'});

	const currentPage = findReplicaPage({replicaList: replicaList, folio: folio});
	const issueName = !isEmpty(currentPage.issueName) ? currentPage.issueName : '';
	const issueDate = new Date(currentPage.issueDate).toISOString().slice(0, 10);
	const links = !isEmpty(currentPage.links) ? currentPage.links : [];
	links.forEach(link => {
		const styleAttr = {
			left: link.left + '%',
			top: link.top + '%',
			width: link.width + '%',
			height: link.height + '%',
		};
		const linkStyle = !isEmpty(link.style) ? link.style : "box";
		const $generatedLink = $('<a />').css(styleAttr).attr({"title": link.alt}).addClass(['generated-link', linkStyle]);
		if (!isEmpty(link.link)) {
			const linkTitle = link.link.includes('mailto:') ? link.alt : link.alt + externalWebLinkText;
			$generatedLink.attr({"href": link.link, "target": "_blank", "title": linkTitle}).addClass("external-link");
			$generatedLink.on('click', (evt) => {
				trackLinkClick({
					link: link,
					pageFolio: params.folio,
					issueName: issueName,
					issueDate: issueDate,
					type: "external"
				});
			});
		} else {
			// find replica page corresponding to pageNumber, otherwise default to first page
			const replicaPage = replicaList.find(page => page.pageNumber === link.destPageNumber);
			const folio = !isEmpty(replicaPage) ? replicaPage.folio : (!isEmpty(replicaList) ? replicaList[0].folio : '');
			$generatedLink.attr({"href": "//goto_page/"+folio, "title": link.alt}).addClass("internal-link");
			$generatedLink.on('click', (evt) => {
				params.gotoInternalPage({
					evt: evt,
					props: props,
					replicaList: replicaList,
					folio: folio,
				});
			});
		}
		$generatedLink.appendTo($folio);
	});

	/**
	 * This is to allow link color transition at population of links on page.
	 * Each of these classes is defined and described in styles.scss.  This allows
	 * for each override of colors and timing through custom styles.
	 * Note: We read in the cssVar set in styles.scss for the transition time to allow
	 *     all values to be overridden in custom styles
	 *
	 * Note: Using jQuery fadeIn to let it manage timing and transition effects, as well as
	 *     making it easier to add/remove classes on start and ent
	 */
	let fadeInTime = parseInt(getComputedStyle($folio[0]).getPropertyValue('--link-transition-in'), 10);
	fadeInTime = !isNaN(fadeInTime) ? fadeInTime : 2000;  // 2 seconds default if not set

	$folio.find("a").addClass('link-highlight').css({'display': 'none'}).fadeIn(fadeInTime, function() {
		const $link = $(this);
		$link.addClass('link-transition link-transition-end');
		$link.on('transitionend', function() {
			$link.removeClass('link-highlight link-transition link-transition-end').addClass('link-normal');
		});
	});
	// VERY HACKY: apply the same fade-in/fade-out transition to reverse popup buttons which are built elsewhere
	$folio.parent().find(".media-open-popup-button").addClass('link-highlight').css({'display': 'none'}).fadeIn(fadeInTime, function() {
		const $link = $(this);
		$link.addClass('link-transition link-transition-end');
		$link.on('transitionend', function() {
			$link.removeClass('link-highlight link-transition link-transition-end');
		});
	});

};
export {generatePageLinks};