import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import { Translate } from "../../../locales/locales";
import {GenerateHtmlMarkup} from "../../../widgets/generateHtmlMarkup";
import $ from 'jquery';
import * as Tracking from "../../../utils/tracking";


/**
 * This will close the banner and regenerate the html without a banner being built.
 * Note: If you don't set a localStorageStatus value, the banner will re-appear
 * when the browser is refreshed, as useState is only for the session.
 *
 * @param evt click event
 * @param props module props
 */
const closeBanner = (evt, props) => {
	if (props.localStorageStatus !== null) {
		localStorage.setItem(props.localStorageStatus,'true');
	}

	/* track click of close button */
	const trackingProperties = {
		"type": props.trackingType,
		"module": "banner",
		"label": "Close"
	};
	Tracking.libraryTrack("button clicked", trackingProperties);
};


/**
 * Fires when the banner <div> wrapper is clicked.
 * If the event target is either a link (<a> tag) or the
 * Powered by GTxcel logo image, track the click.
 *
 * @param event click event
 */
const trackBannerLink = (event) => {
	const tagName = event.target.tagName.toLowerCase();
	if (tagName === 'a') {

		const trackingProperties = {
			"type": "banner link",
			"module": "banner",
			"url": $(event.target).attr('href'),
			"label": $(event.target).text()
		};
		Tracking.libraryTrack("link clicked", trackingProperties);

	} else if (tagName === 'img' && event.target.classList.contains('powered-by-gtxcel')) {

		const trackingProperties = {
			"type": "banner link",
			"module": "banner",
			"url": $(event.target).closest('a').attr('href'),
			"label": 'Powered by GTxcel'
		};
		Tracking.libraryTrack("link clicked", trackingProperties);
	}
};


export const BannerModule = (props) => {
	const [bannerClosed, setBannerClosed] = useState(false);

	// returns className
	// NOTE: This module does NOT display title
	const mops = generateMops(props, {
		defaultClass: ''
	});

	useEffect(() => {
		if (props.localStorageStatus !== null) {
			setBannerClosed(localStorage.getItem(props.localStorageStatus) === 'true');
		}
	}, [props.localStorageStatus]);


	// this will read the 'html' tag, which should be a string in a locale file.
	// the locale string can have replacement values as indicated in the links configuration property
	const html = props.hasOwnProperty('html') ? Translate.Text({id:props.html, replacements: props.links}): '';
	const closeVisibleText = Translate.Text({id:'banner.closeVisible'});
	const closeInvisibleText = Translate.Text({id:'banner.closeInvisible'});
	// append configured classes to parent class
	const bannerClass = mops.className !== ''? 'banner ' + mops.className : 'banner';

	/*
	* Generate jsx for tagged text if displayOnDevice or if the banner is not closed
	* Use GeneratedTag as tag, with text in generated tag.
	*/
	if (displayOnDevice(props) && !bannerClosed) {
		return (
			<div className={bannerClass} onClick={trackBannerLink}>
				<GenerateHtmlMarkup htmlMarkup={html} />
				{props.displayCloseButton ?
					<button
						className={'close-banner icon-button'}
						onClick={(evt) => {
							closeBanner(evt, props);
							setBannerClosed(true);
						}}
					>
						{closeVisibleText}<span className="screen-reader">{closeInvisibleText}</span>
					</button> :
					''
				}
			</div>
		);
	} else {
		return null;
	}
};


/**
 * Map the store object you are using.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{articleList: Array}}
 */
const mapStateToProps = (state, props) => {
	const links = props.hasOwnProperty('links') ?
		(typeof props.links === 'string' ? JSON.parse(props.links) : props.links) :
		{};
	return {
		links: links,
		localStorageStatus: props.localStorageStatus ? props.localStorageStatus : null,
		displayCloseButton: props.hasOwnProperty('displayCloseButton') ? props.displayCloseButton === "true" : true,
		active: getActiveAttributeState(props),
		trackingType: props.hasOwnProperty('trackingType') ? props.trackingType : 'banner close button'
	};
};

export default connect(
	mapStateToProps,
	null
)(BannerModule);
