/**
 * Actions that can potentially be used by any module.  These set of
 * actions use parameters to decide what call to make and return
 * a generic set of data that the appropriate reducer can use to
 * populate the module data in the store.
 *
 */

import { DATA_REQUESTED, 
	DATA_UPDATED, 
	DATA_SAVE_REQUESTED,
	DATA_DELETE_REQUESTED } from "../../modules/_MODULE_GLOBALS/constants";
import getApiUrl from '../../utils/getApiUrl';


/**
 * Action call to fetch data.
 * Call to getApiUrl to get the correct api call for url.
 *
 * @param params parameters for fetchData and to pass back
 * @returns structure for call to to pass to DATA_REQUESTED saga
 *     DATA_REQUESTED: generic api call, populate store with all returned data
 */
export function fetchData(params) {
	params = Object.assign({
		queryParams: {},
		type: ''
	}, params);
	params.url = getApiUrl(params.type, params.queryParams);
	return {
		type: DATA_REQUESTED,
		params
	};
}

/**
 * Action call to return data to a reducer to place into the store.
 * This call does not require a fetch from the server, so we do not
 * need to pass it onto a saga for asynchronous processing
 *
 * @param payload data to pass back to reducer
 * @param params additional params
 * @returns {{payload: *, type: string, params: *}}
 */
export function updateData(payload, params) {
	return {
		type: DATA_UPDATED,
		payload,
		params
	};
}


/**
 * Action call to save data.
 * Call to getApiUrl to get the correct api call for url.
 *
 * @param params parameters for saveData and to pass back
 * @returns structure for call to to pass to DATA_SAVE_REQUESTED saga
 *     DATA_SAVE_REQUESTED: generic api call, populate store with all returned data
 */
export function saveData(payload, params) {
	params = Object.assign({
		queryParams: {},
		type: '',
		body : '',
		method: 'POST'
	}, params);
	params.url = getApiUrl(params.type, params.queryParams);
	params.body = JSON.stringify(payload);
	return {
		type: DATA_SAVE_REQUESTED,
		params
	};
}
//	//params.url = "http://localhost:3000/faithtoday/gtxapi/highlightlist";

/**
 * Action call to delete/remove data.
 * Call to getApiUrl to get the correct api call for url.
 *
 * @param params parameters for deleteData 
 * @returns structure for call to to pass to removedata saga
 *     DATA_REMOVE_REQUESTED: generic api call, remove from store if exists
 */
export function deleteData(id, params) {
	params = Object.assign({
		queryParams: {},
		type: ''
	}, params);
	 const url = getApiUrl(params.type, params.queryParams);
	 params.url = url.split("?")[0] + "/" + id;
	 params.deleteId = id;
	return {
		type: DATA_DELETE_REQUESTED,
		params
	};
}

