import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {GLOBALS} from "../../_MODULE_GLOBALS/constants";
import {fetchData, updateData} from '../../../store/actions';


/**
 * Setup globals that can only be added through the React system
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const GlobalsModule = (props) => {

	/*
	 * Generate jsx for Global Navigator module.
	 * Even though no html is required for it to respond, we generate a base scaffolding
	 * to show that it is in the UI.
	 */
	return (
		<div id={'globals'} />
	);
};


/**
 * Map state (store) data for the navigator module; added to module props.
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {{articleList: Array}}
 */
const mapStateToProps = (state, props) => {
	return {};
};

/**
 * Actions that can be called by this module.  Each action is added to props so
 * that it can be called in the module, but defined in a single place with
 * appropriate parameters for the action call by this module.
 *
 * Potential query parameters (set in configuration)
 *
 * actions:
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		fetchData: (params) => {
			params.type = GLOBALS;
			dispatch(fetchData(params));
		},
		updateData: (payload, params) => {
			params.type = params.hasOwnProperty('type') ? params.type : GLOBALS;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GlobalsModule);

