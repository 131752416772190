import {getStoreValue, setStoreValue} from "./storeValue";
import {isEmpty, isTrue} from "./generalUtils";
import {generateHashKey} from "./generateHash";



/**
 * Save the data into the redux store cache object based on the generated hash key
 * 
 * @param params
 *     dataKey: string or object to generate key
 *     data: data object to store
 *     keyType: (optional) prefix for key generation for name-spacing; usually omitted
 *     isGeneratedKey: (optional) dataKey is the final key and shouldn't be further hashed,
 *     overrideEmptyData: (optional) store even if the data is empty
 *     state: (optional) store state, if not passed in then getStoreValue and setStoreValue
 *         will use store.getState(). But if you are calling this function from a reducer,
 *         pass the state.
 *
 * @returns {string|null}
 */
const storeInCache = (params) => {
	params = Object.assign({
		dataKey: '',
		data: null,
		keyType: '',
		isGeneratedKey: false,
		overrideEmptyData: false,
		state: null
	}, params);

	if (!isEmpty(params.data) || isTrue(params.overrideEmptyData)) {
		const generatedKey = isTrue(params.isGeneratedKey) ? params.dataKey : generateHashKey({keyValue: params.dataKey, keyType: params.keyType});
		//get all the hashed data
		const storedDataByHashKey = getStoreValue({attributeKey: 'storedDataByHashKey', default: {}, state: params.state});
		//store the data for your specific key
		storedDataByHashKey[generatedKey] = params.data;
		//put the updated hashed data back into the store
		setStoreValue({attributeKey: 'storedDataByHashKey', state: params.state}, storedDataByHashKey);
		return generatedKey;
	} else {
		return null;
	}
};
export {storeInCache};



/**
 * Get a stored data object from the redux store based on the generated key
 *
 * @param params
 *     dataKey: string or object to use to generate key
 *     keyType: prefix for key generation
 *         note: if the keyType was used to store the data, then it must be specified
 *     emptyDataObject: (optional) empty data object to return if not found in storage
 *     isGeneratedKey: (optional) dataKey is the final key and shouldn't be further hashed
 * @returns {*|*[]}
 */
const retrieveFromCache = (params) => {
	params = Object.assign({
		dataKey: '',
		keyType: '',
		emptyDataObject: null,
		isGeneratedKey: false,
		state: null
	}, params);

	const generatedKey = isTrue(params.isGeneratedKey) ? params.dataKey : generateHashKey({keyValue: params.dataKey, keyType: params.keyType});
	const storedDataByHashKey = (params.state === null)? getStoreValue({attributeKey: 'storedDataByHashKey', default: {}, state: params.state}) : params.state.storedDataByHashKey;
	//const storedDataByHashKey = getStoreValue({attributeKey: 'storedDataByHashKey', default: {}, state: params.state}) ;
	const hasStoredData = storedDataByHashKey.hasOwnProperty(generatedKey);
	const storedData = storedDataByHashKey.hasOwnProperty(generatedKey) ? storedDataByHashKey[generatedKey] : params.emptyDataObject;
	return {storedData: storedData, generatedKey: generatedKey, hasStoredData: hasStoredData};
};
export {retrieveFromCache};

