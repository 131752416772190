import {isEmpty, isTrue} from "../../utils/generalUtils";
import parsePath from "parse-path";
import extractDomain from "extract-domain";
import {getStoreValue} from "../../utils/storeValue";
import getDeviceType from "../../utils/getDeviceType";


/**
 * Modules that call the mediaPlayer to display video.
 * These values are mostly used for tracking purposes.
 *
 * @type {{replicaViewer: string, articleViewer: string}}
 */
const supportedModules = {
	"replicaViewer": "replica viewer",
	"articleViewer": "article viewer"
};

/**
 * Based on standard device size breakpoints from DEVICESIZEBREAKPOINTS constant and
 * returned by function getDeviceSize()
 *     {xs: 0, sm: 568, md: 768, lg: 1024, xl: 1280, xxl: 1400}
 *
 * Popup attributes for Platform are true/false and populated as
 *     Desktop Web Replica:  web
 *     Mobile Web Replica:  mobileWeb
 *     Mobile App Replica:  mobileApp
 *
 * @type {{app: {configured: string, tracking: string}, xl: {configured: string, tracking: string}, md: {configured: string, tracking: string}, sm: {configured: string, tracking: string}, xs: {configured: string, tracking: string}, lg: {configured: string, tracking: string}, xxl: {configured: string, tracking: string}}}
 */
const PLATFORM_MATCH = {
	"xs": {
		"configured": "mobileWeb",
		"tracking": "mobileWeb",
	},
	"sm": {
		"configured": "mobileWeb",
		"tracking": "mobileWeb",
	},
	"md": {
		"configured": "web",
		"tracking": "desktopWeb",
	},
	"lg": {
		"configured": "web",
		"tracking": "desktopWeb",
	},
	"xl": {
		"configured": "web",
		"tracking": "desktopWeb",
	},
	"xxl": {
		"configured": "web",
		"tracking": "desktopWeb",
	},
	"app": {
		"configured": "mobileApp",
		"tracking": "mobileApp",
	},
};

/**
 * Media type extensions to detect if looking at file type in url
 *
 * @type {{mp4: {type: string, element: string}, jpg: {type: string, element: string}, mp3: {type: string, element: string}, gif: {type: string, element: string}, png: {type: string, element: string}, jpeg: {type: string, element: string}, m3u8: {type: string, element: string}, m4a: {type: string, element: string}, ogg: {type: string, element: string}, webm: {type: string, element: string}, ogv: {type: string, element: string}}}
 */
const mediaExtensions = {
	// video extensions
	"mp4": {
		"element": "reactPlayer",
		"type": "video",
		"tracking": {
			"popupType": "h264",
			"type": "video view",
			"videoPlatform": "video",
		},
	},
	"ogg": {
		"element": "reactPlayer",
		"type": "video",
		"tracking": {
			"popupType": "h264",
			"type": "video view",
			"videoPlatform": "video",
		},
	},
	"ogv": {
		"element": "reactPlayer",
		"type": "video",
		"tracking": {
			"popupType": "h264",
			"type": "video view",
			"videoPlatform": "video",
		},
	},
	"webm": {
		"element": "reactPlayer",
		"type": "video",
		"tracking": {
			"popupType": "h264",
			"type": "video view",
			"videoPlatform": "video",
		},
	},
	"m3u8": {
		"element": "reactPlayer",
		"type": "video",
		"tracking": {
			"popupType": "h264",
			"type": "video view",
			"videoPlatform": "video",
		},
	},
	// audio extensions
	"mp3": {
		"element": "reactPlayer",
		"type": "audio",
		"tracking": {
			"popupType": "audio",
			"type": "popup view",
			"audioPlatform": "audio",
		},
	},
	"m4a": {
		"element": "reactPlayer",
		"type": "audio",
		"tracking": {
			"popupType": "audio",
			"type": "popup view",
			"audioPlatform": "audio",
		},
	},
	"wav": {
		"element": "reactPlayer",
		"type": "audio",
		"tracking": {
			"popupType": "audio",
			"type": "popup view",
			"audioPlatform": "audio",
		},
	},

	// (image) img extensions; no "platform" type
	"jpg": {
		"element": "img",
		"type": "img",
		"tracking": {
			"popupType": "img",
			"type": "popup view",
		},
	},
	"jpeg": {
		"element": "img",
		"type": "img",
		"tracking": {
			"popupType": "img",
			"type": "popup view",
		},
	},
	"gif": {
		"element": "img",
		"type": "img",
		"tracking": {
			"popupType": "img",
			"type": "popup view",
		},
	},
	"png": {
		"element": "img",
		"type": "img",
		"tracking": {
			"popupType": "img",
			"type": "popup view",
		},
	},
};

/**
 * Attempt to match the video type from the url, by matching the hostName
 * in the media url to one supported by react-player.
 *
 * @type {{"soundcloud.com": string, "wistia.com": string, "facebook.com": string, "mixcloud.com": string, "twitch.tv": string, "vimeo.com": string, "youtube.com": string}}
 */
const playerMedia = {
	"jwplayer.com": {
		"element": "iframe",
		"type": "jwplayer",
		"tracking": {
			"popupType": "iframe",
			"type": "video view",
			"videoPlatform": "JW Player",
		},
	},
	"youtube.com": {
		"element": "reactPlayer",
		"type": "youtube",
		"tracking": {
			"popupType": "youtube",
			"type": "video view",
			"videoPlatform": "YouTube",
		},
	},
	"youtu.be": {
		"element": "reactPlayer",
		"type": "youtube",
		"tracking": {
			"popupType": "youtube",
			"type": "video view",
			"videoPlatform": "YouTube",
		},
	},
	"facebook.com": {
		"element": "reactPlayer",
		"type": "facebook",
		"tracking": {
			"popupType": "iframe",
			"type": "video view",
			"videoPlatform": "Facebook",
		},
	},
	"vimeo.com": {
		"element": "reactPlayer",
		"type": "vimeo",
		"tracking": {
			"popupType": "iframe",
			"type": "video view",
			"videoPlatform": "Vimeo",
		},
	},
	"twitch.tv": {
		"element": "reactPlayer",
		"type": "twitch",
		"tracking": {
			"popupType": "iframe",
			"type": "video view",
			"videoPlatform": "Twitch",
		},
	},
	"wistia.com": {
		"element": "reactPlayer",
		"type": "wistia",
		"tracking": {
			"popupType": "iframe",
			"type": "video view",
			"videoPlatform": "Wistia",
		},
	},
	"brightcove.net": {
		"element": "iframe",
		"type": "brightcove",
		"tracking": {
			"popupType": "iframe",
			"type": "video view",
			"videoPlatform": "Brightcove",
		},
	},
	"soundcloud.com": {
		"element": "reactPlayer",
		"type": "soundcloud",
		"tracking": {
			"popupType": "iframe",
			"type": "popup view",
			"audioPlatform": "SoundCloud",
		},
	},
	"mixcloud.com": {
		"element": "reactPlayer",
		"type": "mixcloud",
		"tracking": {
			"popupType": "iframe",
			"type": "popup view",
			"audioPlatform": "MixCloud",
		},
	},
	"spotify.com": {
		"element": "iframe",
		"type": "spotify",
		"tracking": {
			"popupType": "iframe",
			"type": "popup view",
			"audioPlatform": "Spotify",
		},
	},
};

/**
 * Popup types as set by the Page Editor or other back-end editor
 *
 * Note: "iframe" is not included here, as it has its own detectors for various platforms
 *
 * @type {{youtube: {type: string, element: string}, img: {type: string, element: string}, h264: {type: string, element: string}}}
 */
const configuredPopupTypes = {
	"img": {
		"element": "img",
		"type": "img",
		"tracking": {
			"popupType": "img",
			"type": "popup view",
		},
	},
	"h264": {
		"element": "reactPlayer",
		"type": "h264",
		"tracking": {
			"popupType": "h264",
			"type": "video view",
			"videoPlatform": "video",
		},
	},
	"youtube": {
		"element": "reactPlayer",
		"type": "youtube",
		"tracking": {
			"popupType": "youtube",
			"type": "video view",
			"videoPlatform": "YouTube",
		},
	},
	// not currently a type set by Page Editor
//	"audio": {
//		"element": "reactPlayer",
//		"type": "audio"
//		"audioPlatform": "audio",
//	},
};



/**
 * Match react-player supported media type, based on the domain in the
 * url from an iframe entry.
 * If match, return the type from reactPlayerMedia
 *
 * @param domain domain from url
 * @returns {*|string}
 */
const playerType = (domain) => {
	const playerHosts = Object.keys(playerMedia);
	const supportedHost = playerHosts.filter(playerHost => {
		return domain.includes(playerHost);
	});
	return !isEmpty(playerMedia[supportedHost]) ? playerMedia[supportedHost] : {"element": "iframe", "type": ""};
};

/**
 * Determine the popup type from either the type set in the Page Editor
 * or from the file extension of the included url.
 *
 * @param popup popup object on page
 *     popupType: type of popup; set in Page Editor
 * @returns {*|string|{type: string, element: string}}
 *     type: specific detected popup type
 *     element: kind of popup that will be generated
 *         img: image file match
 *         reactPlayer: file type or content provider handled by react-player
 *         iframe: iframe match from Page Editor not handled by react-player
 */
const getPopupType = (popup) => {
	const type = !isEmpty(popup.popupType) ? popup.popupType : 'reactPlayer';

	if (!isEmpty(configuredPopupTypes[type])) {
		return configuredPopupTypes[type];
	} else if (type === 'iframe') {
		const ext = parsePath(popup.url).pathname.includes('.') ? parsePath(popup.url).pathname.split('.').pop().toLowerCase() : '';
		const domain = extractDomain(popup.url, {tld: true});
		return !isEmpty(mediaExtensions[ext]) ? mediaExtensions[ext] : playerType(domain);
	} else {
		return {
			"element": "reactPlayer",
			"type": "video",
			"tracking": {
				"popupType": "iframe",
				"type": "video view",
				"videoPlatform": "video",
			},
		};
	}
};
export {getPopupType};


/**
 * Return the "platform" match based on device size and attributes from Page Editor
 *
 * @returns {{configured: string, tracking: string}|*}
 */
const getPlatform = () => {
	const isApp = isTrue(getStoreValue({attributeKey: 'app'}), {defaultValue: false});
	const device = getDeviceType();
	return isApp ? PLATFORM_MATCH.app : PLATFORM_MATCH[device];
};
export {getPlatform};


/**
 * Based on the actual module name, return the module name we use for tracking
 *
 * @param moduleName actual module name
 * @returns {*|string} tracking name
 */
const getModuleTrackingName = (moduleName) => {
	return !isEmpty(supportedModules[moduleName]) ? supportedModules[moduleName] : '';
};
export {getModuleTrackingName};