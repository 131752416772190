import React, {useState, useEffect, useCallback} from 'react';
import {isEmpty, isTrue} from "../utils/generalUtils";
import $ from 'jquery';
import addDebounceListener from "../utils/addDebounceListener";
import {useAttributesChanged} from "./useAttributesChanged";
import {getNodeStyleValue, nodeListsEqual} from "../utils/htmlUtils";
import {generateHashKey} from "../utils/generateHash";



/**
 * Check if the DOM element is visible in the viewable area of the scroll container.
 * View detection is constrained by the "percentInView" which calculates how much
 * of the DOM element must be showing in the view before it is deemed "viewed".
 *
 * For example: if the percentInView value is 50, then 50% of the element must
 * be showing before it is deemed in view.
 * Note: This is overridden for elements that are very tall and overlap the
 * top and bottom of the viewable area.  In that case, the element is deemed
 * to be in view if the top and bottom are off the view, but the body is in view.
 *
 * @param params
 *     scrollContainer: DOM element that triggers scroll event
 *     element: a single DOM element to check if in view
 *     percentInView: (default: 0) integer value for percent; convert if not int
 * @returns {boolean} true: element in view; false; element not in view
 */
const checkIfInView = (params) => {
	params = Object.assign({
		scrollContainer: null,
		element: null,
		percentInView: 0
	}, params);
	const {scrollContainer, element} = params;
	let pctView = Number.isInteger(params.percentInView) ? params.percentInView / 100 : parseInt(params.percentInView, 10);
	pctView = isNaN(pctView) ? 0 : pctView;

	// need to add a "correction" for padding top, as that is added to the scroll
	let paddingTopValue = parseInt(getNodeStyleValue(scrollContainer, 'padding-top'), 10);
	paddingTopValue = !isNaN(paddingTopValue) ? paddingTopValue : 0;

	const viewTop = scrollContainer.scrollTop - paddingTopValue;
	const viewHeight = scrollContainer.clientHeight;
	const viewableArea = {
		top: viewTop,
		height: viewHeight,
		bottom: viewTop + viewHeight,
	};

	const elementTop = element.offsetTop;
	const elementHeight = element.clientHeight;
	const elementBottom = elementTop + elementHeight;
	const elementView = {
		top: elementTop,
		height: elementHeight,
		bottom: elementBottom,
		topPct: elementTop + (elementHeight * pctView),
		bottomPct: elementBottom - (elementHeight * pctView),
	};

	// element top is in view and more than pct of element is showing
	if (elementView.top > viewableArea.top && elementView.topPct < viewableArea.bottom) {
		return true;
	// element bottom is in view and more than pct of element is showing
	} else if (elementView.bottom < viewableArea.bottom && elementView.bottomPct > viewableArea.top) {
		return true;
	// element top is above the view and element bottom is below the view (overlapping)
	} else if (elementView.top < viewableArea.top && elementView.bottom > viewableArea.bottom) {
		return true;
	} else {
		return false;
	}
};


/**
 * Listen for the scroll event on a parent element (scrollContainer).
 * If a scroll event is triggered, check to see if any child element (elements)
 * is in the viewport, taking into account the percentInView value.
 * If so, then trigger a callback.
 *
 * @param callback function call back
 * @param params
 *     scrollContainer: a "parent" container that is the container that actually scrolls
 *     elements: child elements within the parent DOM element to check for in view
 *     percentInView: the percent of the child element (vertical) that needs to be visible to trigger callback
 *     triggerDelay: debounce value so that scroll event is not triggered too often
 *     triggerOn: always, once, perParentView
 *         always: (default) every time the element comes into view
 *         once: only once per browser session
 *         oncePerParentView: reset when parent changes (ie. go to new article; come back to article)
 *             note: this wouldn't make sense if triggering always
 *     collectionId: id value to gather element data for keeping track of triggerOnce
 *
 * Call addDebounceListener to provide the delay processing.
 *
 * NOTE: This function uses jQuery to listen for events, even though it requires the DOM element
 * to be passed in.  This is to be able to use the jQuery event namespace to identify a listener
 * to remove if required.
 */
const useScrolledIntoView = (callback, params) => {
	params = Object.assign({
		scrollContainer: null,
		namespace: '',
		elements: {},
		percentInView: 0,
		triggerDelay: 0,
		triggerOn: "always",
		collectionId: '',
	}, params);

	const [scrollContainer, setScrollContainer] = useState(null);
	const [elements, setElements] = useState([]);  // nodelist; array, only for initialization
	const [elementsSeen, setElementsSeen] = useState([]);
	const [collectionId, setCollectionId] = useState('');

	const triggerOn = ["always", "once", "resetOnCollection"].includes (params.triggerOn) ? params.triggerOn : "always";

	/**
	 * Call to check each of the elements to see if they are in the scrollable view.
	 * Include the function within the widget so that it can set state values.
	 *
	 * Then, check to see if we are keeping track of the element to check if the user
	 * has see the DOM element previously.
	 * Note: This is only if the "triggerOn" attribute is "once" or "resetOnCollection".
	 *     "once": keep track over browser session
	 *     "resetOnCollection": keep track for viewed collection (parent)
	 *         ex: view article, keep track; go to next article, reset seen status
	 *
	 * Return the list of elements and the seen/not-seen status of each.
	 * Note: We return a list, since multiple elements can be in view at once.
	 * Note: We return seen/not-seen in addition to triggerInView (which will
	 * be more commonly used to trigger a response) in case the caller has a need
	 * to differentiate between seen and trigger seen.
	 *
	 * @param options
	 *     callback: callback function
	 *     params: params passed to hook
	 *         scrollContainer: container that actually scrolls
	 *         elements: list of elements to check (may be nodelist)
	 *         percentInView: percentage of element that must be visible to trigger callback
	 *         triggerOn: parameter to decide if should trigger once (keep track), resetOnCollection (reset when changing view), or always
	 * @type {(function(*): void)|*}
	 */
	const checkViewedAndDoCallback = useCallback((options) => {
		const {callback, params} = options;
		if (!isEmpty(params.elements)) {
			let elementInView = false;
			const checkedElements = Array.from(params.elements).map(element => {
				const isInView = checkIfInView({
					scrollContainer: params.scrollContainer,
					element: element,
					percentInView: params.percentInView
				});

				// check to see if we should trigger viewed state based on triggerOn type and stored elements seen
				let triggerInView;
				if (isInView) {
					elementInView = true;
					// for "resetOnCollection", collection reset handled in useAttributesChanged hook below
					if (triggerOn === "resetOnCollection" || triggerOn === "once") {
						const elementKey = generateHashKey({keyValue: element});
						if (elementsSeen.includes(elementKey)) {
							triggerInView = false;
						} else {
							elementsSeen.push(elementKey);
							setElementsSeen(elementsSeen);
							triggerInView = true;
						}
					} else {  // "always"
						triggerInView = true;
					}
				} else {
					triggerInView = false;
				}
				return {
					element: element,
					isInView: isInView,
					triggerInView: triggerInView
				};
			});

			// only call callback if something comes into view
			if (elementInView) {
				callback(checkedElements);
			}
		}
	}, [elementsSeen, triggerOn]);


	// keep track of scrollContainer and elements we are looking at
	// Note: debounce uses setTimeout, so we must re-create the fn callback from
	//    debounce each time the list of elements changes
	useAttributesChanged(() => {
		if (triggerOn === "resetOnCollection" && params.collectionId !== collectionId && !isEmpty(params.collectionId)) {
			// keep track of collectionId and reset elementsSeen if changed
			setCollectionId(params.collectionId);
			setElementsSeen([]);
		}
		if (!nodeListsEqual(params.elements, elements)) {
			setElements(params.elements);
		}
		if (params.scrollContainer !== scrollContainer) {
			setScrollContainer(params.scrollContainer);
		}
		if (!isEmpty(params.scrollContainer && !isEmpty(params.elements))) {
			addDebounceListener({
				eventName: 'scroll',
				target: params.scrollContainer,
				useJQuery: true,
				namespace: params.namespace,
				timeout: params.triggerDelay,
				fn: () => {
					checkViewedAndDoCallback({callback: callback, params: params});
				}
			});
		}
	}, [params.elements, params.collectionId]);


	// NOTE: use jQuery to unset namespaced scroll event
	if (params.scrollContainer !== scrollContainer) {
		const $scrollContainer = !isEmpty(scrollContainer) ? $(scrollContainer) : [];
		if ($scrollContainer.length > 0) {
			const scrollEvent = !isEmpty(params.nameSpace) ? 'scroll.' + params.nameSpace : 'scroll';
			$(scrollContainer).off(scrollEvent);
		}
	}

};
export {useScrolledIntoView};
