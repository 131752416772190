import React, {useState} from 'react';
import {connect} from "react-redux";
import {ARTICLE_VIEWER} from "../../_MODULE_GLOBALS/constants";
import {isTrue, isEmpty} from "../../../utils/generalUtils";
import GenerateShareButtons from "../../../widgets/generateShareButtons";
import {findArticle} from "../../../utils/articleList/articleActions";
import {displayOnDevice} from "../../../utils/moduleSetup";

/**
 * Start of jsx code for module
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ShareButtonsModule = (props) => {
	const currentArticle = findArticle({articleList: props.articleList, articleId: props.articleId});
	const shareAttributes = {
		elfSightCode: props.elfSightCode,
		shareTitle: !isEmpty(currentArticle) ? currentArticle.title : '',
		isApp: props.isApp,
		platform: props.platform,
		moduleName: props.moduleName,
		displayShareButtons: props.displayShareButtons,
		storageKey: props.storageKey
	};
	if (displayOnDevice(props) && props.canDisplayShareButtons && props.moduleName !== '' && props.elfSightCode !== '' && !isEmpty(currentArticle)) {
		return <GenerateShareButtons {...shareAttributes} />;
	} else {
		return null;
	}
};


/**
 * Map state (store) data for the shareButtons module; added to module props.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{key: Value}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = props.storageKey ? props.storageKey : ARTICLE_VIEWER;
	const shareButtonsState = state[storageKey] ? state[storageKey] : {};
	const shareButtonsProps = {
		storageKey: storageKey,
		articleId: shareButtonsState.articleId ? shareButtonsState.articleId : '',
		articleList: shareButtonsState.articleList ? shareButtonsState.articleList : [],
		canDisplayShareButtons: isTrue(props.canDisplayShareButtons),
		elfSightCode: props.hasOwnProperty('elfSightCode') ? props.elfSightCode : '',
		isApp: isTrue(state.app, {default: false}),
		platform: state.platform ? state.platform : "web",
		moduleName: props.hasOwnProperty('moduleName') ? props.moduleName : '',
		displayShareButtons: shareButtonsState.displayShareButtons ? shareButtonsState.displayShareButtons : false
	};
	return shareButtonsProps;
};

export default connect(
	mapStateToProps,
	null
)(ShareButtonsModule);
