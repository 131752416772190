import {clone, cloneWithoutReplicaList} from "./objectUtils";
import {navigationKeyMatch} from "../modules/globals/globalNavigator";


/**
 * Looks for the fetchDataAttributes object that may be configured
 * within a module and converts parameters dynamic field names then
 * returns the new props as moduleProps.
 *
 * It will also check to see if fetch parameters have been updated in
 * fetchQueryParams and update any parameters through merge.
 *
 * Note: This function makes a clone of props so that the actual module's
 * props are not modified.
 *
 * @param params
 *     props: object containing module propeties
 *     data: object containing corresponding replacement values
 *
 * @returns {*}
 */
const populateFetchDataAttributes = (params) => {
	params = Object.assign({
		props: {},
		data: {}
	}, params);
	//const moduleProps = clone(params.props);
	const moduleProps = cloneWithoutReplicaList(params.props);
	const data = moduleProps.hasOwnProperty('fetchQueryParams') ? Object.assign({}, params.data, moduleProps.fetchQueryParams) : params.data;

	if (moduleProps.hasOwnProperty('navigationKeys')) {
		// get navigationKeys from moduleProps and convert to javascript object from configuration string
		Object.keys(moduleProps.navigationKeys).forEach(key => {
			// get any configured modules
			const modules = moduleProps.navigationKeys[key].hasOwnProperty('modules') ? moduleProps.navigationKeys[key].modules : {};
			Object.keys(modules).forEach(index => {
				// if fetchDataAttributes are found to contain an array of parameters, iterate through, replaceDynamicParameters() and re-assign
				if (modules[index].hasOwnProperty('attributes')) {
					const fetchDataAttributes = modules[index].attributes.hasOwnProperty('fetchDataAttributes') ? modules[index].attributes.fetchDataAttributes : {};
					Object.keys(fetchDataAttributes).forEach(attr => {
						if (fetchDataAttributes[attr].hasOwnProperty('parameters')) {
							fetchDataAttributes[attr].parameters = replaceDynamicParameters({fetchParameters: fetchDataAttributes[attr].parameters, data: data, props: moduleProps});
						}
					});
				}
			});
		});
	} else {
		moduleProps.navigationKeys = {};
	}

	return moduleProps;
};
export {populateFetchDataAttributes};



/**
 * Replaces dynamic field names wrapped in braces with corresponding values
 * Example:
 *    "parameters": {
 *       "issueUrl": "{issueUrl}",		-- dynamic value
 *       "maxEntries": 100				-- fixed value
 *    }
 *
 * Note: fetchNavigationKey is not the same as the fetch property we need, so we
 * translate to the actual fetch attribute.
 *
 * @param params parameters
 *     fetchParameters: object containing values that represent dynamic fields
 *     data: object containing corresponding replacement values.
 *     props: module props
 * @returns {*}
 */
const replaceDynamicParameters = (params) => {
	Object.assign({
		fetchParameters: {},
		data: {},
		props: {}
	}, params);
	const fetchParameters = params.fetchParameters;
	const data = params.data;
	const props = params.props;
	// get actual fetch attribute name for navigation key
	const matchKey = props.hasOwnProperty('fetchNavigationKey') && props.fetchNavigationKey !== null && navigationKeyMatch.hasOwnProperty(props.fetchNavigationKey) ? navigationKeyMatch[props.fetchNavigationKey] : {};
	const fetchKey = matchKey.hasOwnProperty(props.name) ? matchKey[props.name].fetch : '';

	Object.keys(fetchParameters).forEach((key) => {
		// match strings wrapped in braces
		const replaceRegex = new RegExp('{.*}');
		const replaceMatch = fetchParameters[key].toString().match(replaceRegex);
		// replaceMatch results in Array with first match if found, null if no matches
		if (Array.isArray(replaceMatch)) {
			const paramKey = fetchParameters[key].toString().trim().toString().replace(/[{}]/gi, '');
			if (fetchKey === paramKey && props.hasOwnProperty(paramKey)) {
				fetchParameters[key] = props[paramKey];
			} else if (data.hasOwnProperty(paramKey)) {  // else if key exist in the data object, use to re-assign parameter value
				if (typeof data[paramKey] === 'string') {
					fetchParameters[key] = data[paramKey];
				} else if (Array.isArray(data[paramKey])) {
					// if multiple categories values are passed in an array, they must be joined with a "~"; otherwise ","
					if (paramKey === 'categories') {
						// get current category from props, if available
						const value = props.hasOwnProperty(paramKey) ? props[paramKey] : data[paramKey].join('~');
						fetchParameters[key] = value;
					} else {
						fetchParameters[key] = data[paramKey].join(',');
					}
				}
			}
		}
	});
	return fetchParameters;
};
export {replaceDynamicParameters};

