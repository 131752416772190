import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import {ARTICLE_LIST, DISPLAY_ARTICLE_DATA, ID_NOT_SET} from "../../_MODULE_GLOBALS/constants";
import {fetchData, updateData} from '../../../store/actions';
import {isTrue, isEmpty} from "../../../utils/generalUtils";
import {generateMops, detectListFetchComplete, displayOnDevice, getActiveAttributeState, devicePageGrid} from "../../../utils/moduleSetup";
import * as Tracking from "../../../utils/tracking";
import {manageDeviceResize} from "../../../widgets/generatePages";
import {manageUpdateAndNavigation} from "../../globals/globalNavigator";
import {getObjectFromJSON, clone} from "../../../utils/objectUtils";
import getDeviceType from "../../../utils/getDeviceType";
import {getDestinations} from "../../../utils/getDestinations";
import { populateFetchDataAttributes } from "../../../utils/populateFetchDataAttributes";
import {useFetchAttributesChange, useFetchComplete} from "../../../hooks/useFetchChange";
import articleList from "../reducers/articleList";
import deepmerge from "deepmerge";
import {getModuleContainer} from "../../globals/globalLibraryData";
import GenerateCardCoverStory from "../../../widgets/articleListCards/generateCardCoverStory";
import {getArticleList} from "../../../utils/articleList/getArticleList";



// list of possible query params sent with api call
// key is props name; value is fetch parameter
const configQueryParams = {
//	'maxEntries':'maxEntries',  // maxEntries has been removed
	'pageSize':'pageSize',  // maxEntries was an alias for pageSize
	'categories':'categories',
	'excludeContentTypes':'excludeContentTypes',
	'issueUrl':'issueUrl',
	'authorNames':'authorNames',
	'sortBy':'sortBy',
	'publicationIds':'publicationIds',
	'recentIssues':'recentIssues',
	'pageNumber':'pageNumber',
	'isLoggedIn': 'isLoggedIn',
	'userAccessHash': 'userAccessHash',
	'withContent': {attribute: "withContent", default: false},
	'u1': 'u1',
};

/*
	list of potential configuration parameters; other than query params
		className: optional class name to add to div
		displayTitle: {true/false} - display the title; default false if not set
		i18nId: {string} - key to string for title in strings (locale) file
		displayThumbnail: {true/false} - show/hide article thumbnail
		displayIssue: {true/false} - show/hide issue name
		displaySummary: {true/false} - show/hide article summary
		displayCategory: {true/false} - show/hide category name
		maxDisplay: {value} - number of entries to display for paging
		maxSummaryCharacters: {value} - number of characters to allow in the summary
		useSummaryEllipsis: {true/false} - add ellipsis to end of the summary, or not

		gridColumns: {object} - number of columns at different pure grid breakpoints
			sm: {number}
			md: {number}
			lg: {number}
 */


/**
 * Open an article in the Content Hub when an article target is clicked.
 *
 * @param event click event
 * @param params parameters passed back from summary click on article
 */
const openArticle = (event, params) => {
	params = Object.assign({
		mops: {},
		moduleProps: {},
		articleList: []
	}, params);
	const mops = params.mops;
	const props = params.moduleProps;
	const articleList = params.articleList;
	const currentArticle = params.articleList;

	// parse any dynamic fetch attributes
	const moduleProps = populateFetchDataAttributes({
		props: props,
		data: currentArticle
	});

	manageUpdateAndNavigation({
		navigationKey: 'article',
		moduleProps: moduleProps,
		attributes: {
			articleId: currentArticle.articleId,
			issueUrl: currentArticle.issueUrl,
			articleList: articleList,
			fetchQueryParams: mops.queryParams,
		}
	});
};


/**
 * Handle article link clicked event.
 * Track event
 *
 * @param params parameters passed back from summary click on article
 * @param event event
 */
const articleClicked = (event, params) => {
	params = typeof params !== 'undefined' ? params : {};
	const articleParams = params.hasOwnProperty('params') ? params.params : {};
	const $target = $(event.target);
	const $link = $target.closest('a');
	const trackingProperties = {
		"type": "article link",
		"module": "article list",
		"url": $link.attr('href'),
		"domain": $link.prop('hostname'),
		"label": $link.data('articletitle'),
		"destination type": "article"
	};
	if (articleParams.hasOwnProperty('destination')) {
		event.stopPropagation();
		Tracking.libraryTrack("button clicked", trackingProperties);
		if (articleParams.destination === 'internal') {
			event.preventDefault();
			openArticle(event, params);
		}
	}
};


/**
 * Check navigationKeys for links to other modules.  Initialize then
 * populate and return articleActions with properties from navigationKeys.
 */
const populateArticleActions = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		articleList: []
	}, params);
	const props = params.props;
	const device = getDeviceType();
	const destination = props.destinations.hasOwnProperty(device) ? props.destinations[device] : {};
	// initialize with defaults as not configured
	const articleActions = {
		params: {},
		articleClicked: null,
		moduleProps: props,
		mops: params.mops,
		articleList: params.articleList
	};
	const navigationKeys = getObjectFromJSON(props.navigationKeys, {});
	if (navigationKeys.hasOwnProperty('article')) {
		const articleParams = navigationKeys.article;
		articleActions.params.article = {
			destination: destination.hasOwnProperty('article') ? destination.article : 'internal',
			linkTitle: articleParams.hasOwnProperty('elementTitle') ? articleParams.elementTitle : ""
		};
		articleActions.articleClicked = articleClicked;
	}
	if (navigationKeys.hasOwnProperty('issue')) {
		const issueParams = navigationKeys.issue;
		articleActions.params.issue = {
			destination: destination.hasOwnProperty('issue') ? destination.issue : 'internal',
			linkTitle: issueParams.hasOwnProperty('elementTitle') ? issueParams.elementTitle : ""
		};
	}
	return articleActions;
};


/**
 * Generate the Cover Story elements
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GenerateCoverStory = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		entry: []
	}, params);
	const props = params.props;
	const mops = params.mops;
	let article = params.entry;

	if (article.length < 1) {
		return '';
	} else {
		// expects an array length of one
		article = article[0];
	}

	const articleActions = populateArticleActions({props: props, mops: params.mops, articleList: article});

	return (
		<div key={article.id} data-articleid={article.id} data-issueid={article.issueId} className={mops.className}>
			<GenerateCardCoverStory props={props} entry={article} actions={articleActions} />
		</div>
	);
};


export const CoverStoryModule = (origProps) => {
	let props = clone(origProps);
	// updating value triggers re-render; for pagination
	const [paginationWidth, setPaginationWidth] = useState(window.innerWidth);
	// store serialized data to keep the last "valid" list
	const [articleList, setArticleList] = useState([]);
	const [fetchInProgress, setFetchInProgress] = useState(false);

	// Callback only once when module is setup.
	useEffect(() => {
		// setup to trigger change if the device size value changes
		manageDeviceResize(setPaginationWidth);
	}, []);


	const moduleProps = clone(props);
	// returns className, storageKey, queryParams
	const mops = generateMops(moduleProps, {
		defaultKey: ARTICLE_LIST,
		defaultClass: 'cover-story',
		configQueryParams: configQueryParams
	});

	/**
	 * Called to manage whether or not the module fetches data.  If the hook calls the
	 * callback, then call getArticleList which will determine whether or not to
	 * fetch a new list or get an existing list from stored data.
	 *
	 * Note: this use mops, so that must come first.
	 * Note: moduleProps is used internally in the hook.
	 */
	useFetchAttributesChange(() => {
		const storedListData = getArticleList({storageKey: mops.storageKey, queryParams: mops.queryParams});
		if (!isEmpty(storedListData.articleList)) {
			setArticleList(storedListData.articleList);
		}
		setFetchInProgress(isTrue(storedListData.fetchInProgress));
	}, {type: ARTICLE_LIST, props: moduleProps, queryParams: mops.queryParams});

	/**
	 * Call hook to check to see if the fetchInProgress has been set/changed in the reducer.
	 * fetchInProgress is set true when fetch is started (DATA_REQUESTED)
	 * and set to false when fetch is done (DATA_LOADED)
	 */
	useFetchComplete((isInProgress) => {
		isInProgress = isTrue(isInProgress, {defaultValue: false});
		setFetchInProgress((isInProgress));
		if (!isInProgress) {
			const storedListData = getArticleList({storageKey: mops.storageKey, queryParams: mops.queryParams, returnStoredDataOnly: true});
			setArticleList(storedListData.articleList);
		}
	}, {requestInProgress: props.fetchInProgress, fetchInProgress: fetchInProgress});

	/*
	* Generate jsx for html if displayOnDevice
	*/
	if (displayOnDevice(props)) {
		return <GenerateCoverStory props={props} mops={mops} entry={articleList} />;
	} else {
		return null;
	}
};


/**
 * Map state (store) data for the articleList module; added to module props.
 *
 * articleList: array of articles objects where each object can contain:
 *     id (article id in UPP) - not currently available
 *     articleId (article id in WDS)
 *     publicationId
 *     publication (publication name)
 *     issueName (Short title?)
 *     issueId
 *     articleUrl (The webreader link to the article)
 *     title
 *     subtitle
 *     byline (Comma separated list of authors)
 *     thumbnail (article image thumbnail src)
 *     sortOrder (The order in which the article are displayed in the issue. Articles should be returned in sort order.
 *     summary (This is a short summary of the article)
 *     categories - comma separated string of categories
 *     authorNames - array of author names
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {{articleList: Array}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : ARTICLE_LIST;
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};
	const globalState = !isEmpty(state.globals) ? state.globals : {};

	// if the currentPane attribute is set in state globals, capture the value
	const currentPane = globalState.hasOwnProperty('currentPane') ? globalState.currentPane : 'none';
	const isMyPane = currentPane === getModuleContainer({module: 'coverStory', storageKey: storageKey}).pane;

	let articleProps = {
		storageKey: storageKey,
		active: getActiveAttributeState(props),
		filters: storeState.filters ? storeState.filters : {},
		grid: getObjectFromJSON(props.grid, {}),
		destinations: getDestinations(props),
		articleId: storeState.articleId ? parseInt(storeState.articleId,10) : 0,
		navigationAttributes: storeState.navigationAttributes ? storeState.navigationAttributes : {},
		isMyPane: isMyPane,
		fetchInProgress: isTrue(storeState.fetchInProgress, {defaultValue: false}),
		fetchOnInit: isTrue(props.fetchOnInit, {defaultValue: true}),
		forceFetch: isTrue(storeState.forceFetch, {defaultValue: false}),
		shareable: isTrue(props.shareable, {defaultValue: false})
	};

	/**
	 *  The `navigationAttributes.fetchDataAttributes` object specifies
	 *  data that should take precedence over module-defined data.
	 *  If it exists, check if we should fetch a new list of articles
	 *  and add any found parameters to articleProps
	 */
	articleProps.fetchListParams = storeState.fetchListParams ? storeState.fetchListParams : {};
	if (storeState.hasOwnProperty('navigationAttributes')) {
		const fetchDataAttributes = getObjectFromJSON(storeState.navigationAttributes.fetchDataAttributes, {});
		if (fetchDataAttributes.hasOwnProperty('articles')) {
			articleProps.fetchListParams = deepmerge(getObjectFromJSON(fetchDataAttributes.articles.parameters, {}), articleProps.fetchListParams);
		}
	}
	Object.keys(articleProps.fetchListParams).forEach(key => {
		articleProps[key] = articleProps.fetchListParams[key];
	});

	/**
	 * Check to see if the query parameters used to fetch the articleList are the same as the query parameters
	 * defined for this module instance.  Depending on the differences, set some properties on the props object
	 *     fetchParamsChanged: the fetch parameters have changed
	 *     fetchParamsSet: fetch complete and fetch parameters set
	 *     fetchQueryParams: fetch complete; capture the fetch parameters
	 */
	articleProps = detectListFetchComplete({
		originalProps: props,
		storeState: storeState,
		moduleProps: articleProps,
		configQueryParams: configQueryParams,
		listType: 'articleList'
	});

	articleProps.pageGridAttributes = devicePageGrid(articleProps.grid);
	articleProps.isLoggedIn = isTrue(globalState.isLoggedIn, {defaultValue: false});

	articleProps.articleDisplayParams = {
		maxSummaryCharacters: typeof props.maxSummaryCharacters !== 'undefined' ? props.maxSummaryCharacters : 155,
		useSummaryEllipsis: isTrue(props.useSummaryEllipsis, {defaultValue: false})
	};

	return articleProps;
};

/**
 * Actions that can be called by this module.  Each action is added to props so
 * that it can be called in the module, but defined in a single place with
 * appropriate parameters for the action call by this module.
 *
 * Potential query parameters (set in configuration)
 *     maxEntries (alias: pageSize): (default: 200) maximum number of entries to return
 *         property: number
 *     categories: (default: all categories) comma separated list of categories to filter
 *         categories are set in article editor for article
 *         properties: comma separated list of category names
 *     excludeContentTypes: (default: none, ie. show all) comma separated list of article content types to filter out
 *         matching articles.  These types are (generally) generated based on the article template.  Article content
 *         types include:
 *             cover - generated based on cover template
 *             advertisement - generated based on template with advertisement in the name (full-page-advertisement)
 *             article - all other articles
 *     issueUrl: (default: all issues) Issue URL value as set in Specs
 *         the issue specified is the Issue URL value not the id
 *         ex. "issueURL": "April_2000"
 *     recentIssues: (default: all issues) number of most recent issues to return
 *         property: number
 *         ex. "recentIssues": 5
 *     authorNames: (default: all authors) pipe ("|") separated list of authors to filter
 *         name must be specified in the same format it is entered in the author-name span in the article
 *         Note: name is NOT case-sensitive; "LASTNAME" will be handles the same as "lastname"
 *         ex. "authorNames": "LastName1, FirstName1|FirstName2 LastName2"
 *         Note: this is an OR query; returns articles containing any of the author names
 *     pageNumber: (default: 0) starting page number of full results
 *     sortBy: (default: "publishedDate-desc") sort the results
 *         properties: "sortOrder"|"issueName"|"publishedDate"|"category" + "-" + "asc"|"desc"
 *         includes sort order
 *         comma separated list - whatever is first is the top level sort, the second one is within that sort by the next
 *         default sort orders
 *             "sortOrder-asc"
 *             "issueName-asc"
 *             "publishedDate-desc"
 *             "category-asc"
 *     'publicationIds': (default: current publication for collection url) for multiple collections in future
 *         properties: comma separated list of publication ids
 *             specify in the order you want the publications to appear
 *
 * actions:
 *     fetchData will make a call to the server to get data
 *     updateData will store data in the redux store
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		fetchData: (params) => {
			params.type = ARTICLE_LIST;
			dispatch(fetchData(params));
		},
		updateData: (payload, params) => {
			params.type = params.hasOwnProperty('type') ? params.type : DISPLAY_ARTICLE_DATA;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CoverStoryModule);
