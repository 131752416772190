import { ARTICLE_LIST_FILTER, DATA_UPDATED } from "../../_MODULE_GLOBALS/constants";
import deepmerge from "deepmerge";

/*
   This reducer updates article list objects for an article list from a given
    set of filters.
 */

/**
 * Required store properties for a module that updates lists of articles.
 *
 * This ensures that there is a placeholder in the store for
 * the values returned for the module.
 * Each action-type then initializes the state for it's store state.
 */
const createInitialState = ((storageKey) => {
	return {
		[storageKey]: {
			"articles": [],
			"filters": {
				"issueName": {},
			}
		}
	};
});


function articleListFilterReducers(state, action) {
	// check if module type matches ARTICLE_LIST_FILTER; if not present, set to true to handle anyway
	const moduleAction = (action.params && action.params.type) ? (action.params.type === ARTICLE_LIST_FILTER) : true;
	if (moduleAction && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'ArticleListFilter';
		const returnState = deepmerge(
			createInitialState(storageKey),
			state
		);
		returnState[storageKey].filters.issueName = action.payload.issueNameFilters ? action.payload.issueNameFilters : {};
		return returnState;
	}
	return state;
}
export default articleListFilterReducers;

