import React from "react";
import {Translate} from "../locales/locales";
import {isTrue} from "../utils/generalUtils";
import {sanitizeInline} from "../utils/htmlUtils";

/**
 * Title can return one of various states:
 *     string from strings file matching i18nId
 *         with tag or without tag
 *     element child content html sanitized
 *         with or without tag
 *     empty string - no i18nId, child elements or displayTitle is false
 *
 * @param params
 *     titleParams: Collection of settings title display titles.
 *         i18nId: Internationalization settings
 *         displayTitle: Whether to display the title, undefined defaults to false
 *         titlePrefix: Additional string to place before the title; only valid for text, not html
 *         tag: Wrapper tag to output, pass falsey value for no wrapping tag
 *         className: Class to apply to wrapper tag
 *     html: type of string is html content; to be converted to React html
 *     replacememnts: object of replacement key:value pairs in generated string
 *     wrapper: element to use to wrap returned string if html
 *
 *     // deliberately left out; test for defined to use
 *     children: The string content of summary, may contain HTML. ex. <GenrateTitle>children</GenerateTitle>
 * @returns {JSX.Element|*|string}
 * @constructor
 */
const GenerateTitle = (params) => {
	params = Object.assign({
		titleParams: {},
		html: false,
		replacements: {},
		wrapper: 'span'
	}, params);
	const titleParams = params.titleParams;
	const i18nId = titleParams.hasOwnProperty('i18nId') && titleParams.i18nId !== '' ? titleParams.i18nId : false;
	const className = titleParams.hasOwnProperty('className') ? titleParams.className : 'module-title';
	const displayTitle = isTrue(titleParams.displayTitle);
	const titlePrefix = titleParams.hasOwnProperty('titlePrefix') ? Translate.Text({id: titleParams.titlePrefix}) : '';
	const isHtml = isTrue(params.html);

	const GeneratedTag = (titleParams.hasOwnProperty('tag'))
		? (titleParams.tag !== false && titleParams.tag !== '') ? `${titleParams.tag}` : false
		: 'h2';

	// check no title first; then i18nId; default return ''
	if (displayTitle === false) {
		return '';
		//	if i18nId passed in, use it; if not translatable, just return i18nId
	} else if (i18nId !== false) {
		const localeParams = {
			'id': i18nId,
			'html': isHtml,
			'replacements': params.replacements,
			'wrapper': params.wrapper
		};
		// prefix text is only valid for text strings, not React html
		const translatedText = Translate.Text(localeParams);
		const titleStr = isHtml ? translatedText : titlePrefix + translatedText;

		// TODO: Support html for normal titles, as we do for the next section
		return GeneratedTag ? <GeneratedTag className={className}>{titleStr}</GeneratedTag> : titleStr;
		// if the tag contains child content, return sanitized output
	} else if (params.hasOwnProperty('children')) {
		return GeneratedTag ? <GeneratedTag className={className} dangerouslySetInnerHTML={{ __html: sanitizeInline(params.children) }} /> : sanitizeInline(params.children);
	} else {
		return '';
	}
};
export default GenerateTitle;
