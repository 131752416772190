import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import store from '../store';
import {Provider, connect} from 'react-redux';
import {getLayout} from '../layouts/layouts.js';
import {getStructure} from './structures.js';
import getDeviceType from "../utils/getDeviceType";
import { gatheredLibraryModules } from '../modules/_MODULE_GLOBALS/gatheredModules';
import {GenerateHtmlMarkup} from "../widgets/generateHtmlMarkup";
import {cleanupHtmlString} from '../utils/htmlUtils';
import * as nativeApp from "../utils/nativeAppInterface.js" ;
import {handleBrowserBack, resetPaneNavigation, setInitialPaneNavigation, navigateFromTarget} from "../modules/globals/globalNavigator";
import {setInitialGlobalData} from "../modules/globals/globalLibraryData";
import addDebounceListener from "../utils/addDebounceListener";
import {getStoreValue, setStoreValue} from "../utils/storeValue";
import {getObjectFromJSON} from "../utils/objectUtils";
import {GLOBALS} from "../modules/_MODULE_GLOBALS/constants";
import {fetchData, updateData, saveData, deleteData} from "../store/actions";
import {isEmpty, isServer} from "../utils/generalUtils";
import {isBrowserZoomed} from "../utils/isBrowserZoomed";
import {Translate} from "../locales/locales";
import {addParamsToUrl} from "../utils/urlParamUtils";


/**
 * Loop through structures definition and compare to supported displays from
 * environment.  Only keep defined displays if they have been defined in the
 * structures configuration.
 *
 * If the display device doesn't appear in the structures definition, remove
 * it from the displays object (by not keeping it).
 *
 * @returns {{}} supported displays object
 */
const filterStructureDisplays = () => {
	const supportedDisplays = {};
	const structures = getStoreValue({attributeKey: 'structures'});
	const displays = getStoreValue({attributeKey: 'displays'});
	if (displays !== null) {
		if (typeof structures !== 'undefined') {
			Object.keys(structures).forEach(structure => {
				if (displays.hasOwnProperty(structure)) {
					supportedDisplays[structure] = displays[structure];
				}
			});
		}
		setStoreValue({attributeKey: 'displays'}, supportedDisplays);
	}
	return supportedDisplays;
};

/**
 * Generate a strings object from the customStrings string.
 *
 * @returns {{}}
 */
const generateCustomStringsObject = () => {
	let customStringsObject = {};
	const customStrings = getStoreValue({attributeKey: 'customStrings'});

	if (typeof customStrings !== 'undefined' && customStrings !== null && customStrings !== '') {
		customStringsObject = getObjectFromJSON(customStrings, {});
	} else {
		customStringsObject.customStrings = {};
	}
	return customStringsObject;
};

/**
 * See if there is a defaultConfiguration object.  If so, extract the configuration object from it,
 * otherwise, just return an empty object.
 *
 * @returns {{}|*}
 */
const getConfigurationObject = () => {
	const defaultConfiguration = getStoreValue({attributeKey: 'defaultConfiguration'});
	if (typeof defaultConfiguration !== 'undefined' && defaultConfiguration !== null && defaultConfiguration.hasOwnProperty('configuration')) {
		return defaultConfiguration.configuration;
	} else {
		return {};
	}
};

/**
 * These are all "true" globals and never change.
 * Rather than continually calling a function to get the values, just setup an object with
 * these values when the file is read.
 *
 * It calls the three functions above which is why it is place here.
 *
 * @type {{moduleOverrideInstances: *, structures: *, customStylesheet: *, moduleInstances: *, customStrings: *, customVariables: *}}
 */
const stateGlobals = {
	structures: getStoreValue({attributeKey: 'structures', default: {}}),
	moduleInstances: getStoreValue({attributeKey: 'moduleInstances', default: {}}),
	moduleOverrideInstances: getStoreValue({attributeKey: 'moduleOverrideInstances', default: {}}),
	customVariables: getStoreValue({attributeKey: 'customVariables', default: ''}),
	customStylesheet: getStoreValue({attributeKey: 'customStylesheet', default: ''}),
	customStrings: getStoreValue({attributeKey: 'customStrings', default: ''}),
	queryParamsOriginal: getStoreValue({attributeKey: 'queryParamsOriginal', default: ''}),
	customStringsObject: generateCustomStringsObject(),
	displays: filterStructureDisplays(),
	configuration: getConfigurationObject()
};

/**
 * If a custom container is defined, add it to the specified pane and location.
 *
 * @param $pane pane element
 * @param customContainers array of custom container definitions
 *     "name": name/className of the new container
 *     "layoutArea": layout area within a layout definition for where to add
 *     "appendAfter": container name in layout area to add new custom container after; if "" then add as first element
 */
const addCustomContainers = ($pane, customContainers) => {
	if (Array.isArray(customContainers)) {
		customContainers.forEach(customContainerDef => {
			const customContainer = typeof customContainerDef.name === 'string' ?
				'<div class="container empty ' + customContainerDef.name.trim() + '" tabindex="-1"></div>' : '';
			const layoutArea = typeof customContainerDef.layoutArea === 'string' ?
				('.'+customContainerDef.layoutArea.trim()).replace(/^\.\./,'.') : '';
			const appendAfter = typeof customContainerDef.appendAfter === 'string' && customContainerDef.appendAfter.trim() !== '' ?
				('.'+customContainerDef.appendAfter.trim()).replace(/^\.\./, '.') : '';
			const $layoutArea = $pane.find(layoutArea);
			if ($layoutArea.length > 0 && customContainer !== '') {
				if (appendAfter === '') {
					$layoutArea.prepend(customContainer);
				} else {
					const $appendAfter = $layoutArea.find(appendAfter);
					if ($appendAfter.length > 0) {
						$appendAfter.after(customContainer);
					}
				}
			}
		});
	}
};

/**
 * Add panes and pane layout to the structure string.
 *
 * In order to avoid problems with excess spaces between elements, we call cleanupHtmlString to
 * remove carriage returns and spaces between elements.
 *
 * We use jQuery to convert to a DOM element as it handles extra spaces,
 * different DOM syntax, etc.  We then convert back to a string and return the
 * html string for React to populate the DOM.
 *
 * Note: We also add <h1> headings as the first element in a pane definition, as defined in the strings
 * file under the attribute paneHeadings.  Since these are added when we generate the panes, we can only
 * do replacement parameters with attributes that are available when we create the panes.
 *
 * @param params
 *     structure: structure string
 *     deviceStructure: contains pane definitions
 *     device: currently displayed browser device size
 *     headingReplacements: pass as potential replacements for <h1> strings
 * @returns {string} new structure, including panes
 */
const addPanes = (params) => {
	params = Object.assign({
		structure: '',
		deviceStructure: {},
		device: '',
		headingReplacements: {}
	}, params);

	// convert to object with pane id as key
	const panesObj = {};
	params.deviceStructure.structurePanes.forEach(pane => {
		panesObj[pane.paneName] = pane;
	});

	const $structure = $(params.structure);
	const $panes = $structure.find('.structure-component-panes');

	// paneDisplay lists panes by id, in order of appearance
	params.deviceStructure.paneDisplay.forEach(paneDisplayPane => {
		const paneDef = panesObj[paneDisplayPane.id];
		const className = 'pane pane-' + params.device + (paneDef.hasOwnProperty('className') ? ' ' + paneDef.className : '');
		const paneLabel = paneDisplayPane.hasOwnProperty('label') ? paneDisplayPane.label : '';
		const headingClassName = 'pane-heading screen-reader';
		// make sure every pane has an <h1> and programmatic tabindex
		let heading = (paneDisplayPane.hasOwnProperty('heading') && Translate.hasKey(paneDisplayPane.heading)) ? Translate.string({id: paneDisplayPane.heading, replacements: params.headingReplacements}) : Translate.string({id: 'paneHeading.none'});
		const paneStr = cleanupHtmlString(`<div id="${paneDef.paneName}" class="${className}" data-pane-label="${paneLabel}" tabindex="-1"><h1 class="${headingClassName}">${heading}</h1>${getLayout(paneDef.layout)}</div>`);

		const $pane = $(paneStr);
		if ($pane.length > 0) {
			const $paneLayout = $pane.find('.layout');
			$paneLayout.addClass('layout-' + params.device);
			if (paneDef.hasOwnProperty('class')) {
				$paneLayout.addClass(paneDef.class);
			}
			$panes.append($pane);
		}
		if (paneDisplayPane.hasOwnProperty('customContainers')) {
			addCustomContainers($pane, paneDisplayPane.customContainers);
		}
	});
	return $structure[0].outerHTML;
};


/**
 * Find the current structure, then the specified container in the structure.  If the
 * container doesn't exist in the DOM, then don't do anything else.
 *
 * Loop through the specified modules, from the defined module instances passed in,
 * then find the matching abstract module definition and merge the instance definition
 * into the abstract definition to create the final module instance.
 *
 * Get the stored React module and add the module instance properties to pass as
 * props to the module.
 *
 * Add the module to a list, which is then used to populate the specified container.
 *
 * @param paneId current pane
 * @param container current container in pane
 * @param modules module definitions to use to populate pane
 */
const renderContainer = (paneId, container, modules) => {
	if (typeof paneId === 'undefined' || paneId === '') {
		paneId = null;
		console.error("PaneId is not defined in configuration.");
	}
	if (typeof container === 'undefined' || container === '') {
		container = null;
		console.error("Container is not defined in configuration.");
	}
	let $container = [];
	const $structure = $('.structure');
	// any of these count for a pane that doesn't exist
	if (paneId === 'none' || paneId === 'structure' || paneId === null || paneId === 'null') {
		$container = $structure.find('.' + container);
	} else if (paneId === 'global') {
		$container = $('#' + container);
	} else {
		const $pane = $('.structure-component-panes').find('#'+paneId);
		$container = $pane.find('.' + container);
	}
	if ($container.length > 0) {
		const containerDiv = $container[0];
		const allModules = gatheredLibraryModules();

		let keyNum = 0;  // key for each module; incremented by 1 for each module
		const components = [];
		const moduleStorageKeys = [];
		modules.forEach((module) => {
			if (module.name && (module.name in allModules)) {
				module.key = ++keyNum;
				const Module = allModules[module.name];
				components.push(<Module {...module} />);
				if (module.hasOwnProperty('storageKey')) {
					moduleStorageKeys.push(module.storageKey);
				}
			}
		});
		// add the container's module storage keys as a data attribute and remove "empty" class since it now has content
		$container.attr('data-module-keys', moduleStorageKeys.join(', '));
		$container.removeClass('empty');

		// render array of components into layout block
		// also pass in locale strings for appropriate language
		ReactDOM.render(
			<Provider store={store}>
				{components}
			</Provider>,
			containerDiv
		);
	}
};


/**
 * Given a configuration object for the modules in a structure, loop through the object
 * and populate the configured modules, for a specified device size, by:
 *     structure
 *     pane
 *     container
 */
const renderModules = (device) => {
	device = !isEmpty(device) ? device : getDeviceType();
	if (device !== null) {
		const structureId = stateGlobals.structures[device].structureName;
		if (stateGlobals.moduleInstances.hasOwnProperty(structureId)) {
			const structureModules = stateGlobals.moduleInstances[structureId];
			// loop through structure
			Object.keys(structureModules).forEach((paneId) => {
				Object.keys(structureModules[paneId]).forEach((container) => {
					renderContainer(paneId, container, structureModules[paneId][container]);
				});
			});
		}
	} else {
		return '';
	}
};


/**
 * If browser zoom is change, update class on App
 *
 * @param event resize event
 */
const updateBrowserZoomedStatus = (event) => {
	if (isBrowserZoomed()) {
		$('#App').addClass('browserZoomed');
	} else {
		$('#App').removeClass('browserZoomed');
	}
};


/**
 * START OF JSX DEFINITION
 *
 * Given a configuration and a set of layouts, populate the appropriate layout
 * with configured modules.
 *
 * Note: This runs a listener for browser resize to determine the device type for
 * appropriate structure and layout.
 *
 * @param props passed in props
 * @returns {*}
 * @constructor
 */
const PopulateStructure = (props) => {
	const [deviceType, setDeviceType] = useState(getDeviceType());

	const subscriberId = props.subscriberId;
	useEffect(() => {

		// TODO: Determine if subscriberId is a "true" global and, if so, when it is set
		if (typeof subscriberId !== "undefined") {
	//		console.log("setting app subscriberId to " + subscriberId);
			nativeApp.setAppSubscriberId(subscriberId);
		}

		return () => {
//			console.log("remove event listener");
		};
	});

	/**
	 * Check to see if a major breakpoint is crossed where the new structure is different from
	 * the old structure.  If so, trigger a reload of the page by assigning a new url to the
	 * window.location by adding in the original query parameters that were stripped off
	 * on the server.
	 *
	 * @type {(function(): void)|*}
	 */
	const handleResize = useCallback(() => {
		const newDeviceType = getDeviceType();
		const deviceStructure = stateGlobals.structures[newDeviceType];
		const newStructure = !isEmpty(deviceStructure) ? deviceStructure.structureName : '';
		const oldStructure = getStoreValue({attributeKey: 'currentStructure', default: ''});
		//console.log(deviceType, newDeviceType, newStructure,oldStructure);
		if (deviceType !== newDeviceType) {
			setDeviceType(newDeviceType);
		}
		if (newStructure !== oldStructure || deviceType !== newDeviceType) {
			const originalQueryParams = !isEmpty(stateGlobals.queryParamsOriginal) ? stateGlobals.queryParamsOriginal : {};
			const fullLocation = addParamsToUrl(originalQueryParams, window.location.href);
			//console.log("xxxxxxxxxxx triggering reload to ",fullLocation);
			window.location.assign(fullLocation);
		}
	}, [deviceType]);

	/**
	 * Call once at initialization.
	 *
	 * Note renderModules is also added here as it must be called before navigateFromTarget,
	 * which should only be called once when the Library is started.
	 */
	useEffect(() => {
		setInitialPaneNavigation();
		setInitialGlobalData();
		// add event (debounce) listener for window resize
		addDebounceListener({eventName: "resize", target: window, timeout: 250, fn: () => handleResize()});
		// update browser zoom on resize event
		addDebounceListener({eventName: "resize", target: window, timeout: 250, fn: (event) => updateBrowserZoomedStatus(event)});
		// popstate triggered when the browser back of forward button is pressed
		// popstate also triggered when clicking on an internal anchor tag
		addDebounceListener({
			eventName: "popstate",
			target: window,
			timeout: 0,
			fn: (event) => {
				// if popstate is the result of an anchor click, just return
				if (!isEmpty(document.location.hash)) {
					return;
				}
				handleBrowserBack(event);
			}
		});

		renderModules();
		navigateFromTarget();
		return () => {
//			console.log("remove event listener");
		};
	},[handleResize]);

	/**
	 * Call whenever deviceType changes
	 */
	useEffect(() => {
		renderModules(deviceType);
		resetPaneNavigation(deviceType);
		return () => {
//			console.log("remove event listener");
		};
	},[deviceType]);


	/**
	 * Generate the markup for the base html layout and return a React jsx function.
	 *
	 * Generate the base structure for the DOM every time the device size changes.  This includes
	 * structure, panes, and layout.
	 *
	 * getStructure returns the html as a string for the structure, which determines the base
	 * DOM elements for the structure.  We then call addPanes to add individual panes to the
	 * panes area of the structure.
	 *
	 * In order to avoid problems with excess spaces between elements, we call cleanupHtmlString to
	 * remove carriage returns and spaces between elements.
	 *
	 * We then use the React method: dangerouslySetInnerHTML to create the markup.
	 *
	 * Populating the layout with modules is done after this is complete.
	 *
	 * props: Since this method is internal to the function, it gets props automatically.
	 * @type {function(): *}
	 */
	const GenerateBrowserStructure = (() => {
		const structureDeviceType = getDeviceType();
		if (!isServer && structureDeviceType !== null) {
			const deviceStructure = stateGlobals.structures[structureDeviceType];
			const structureType = deviceStructure ? deviceStructure.structureType : '';
			let structure = cleanupHtmlString(getStructure(structureType));
			structure = addPanes(
				{
					structure: structure,
					deviceStructure: deviceStructure,
					device: structureDeviceType,
					headingReplacements: props.headingReplacements
				}
			);
			return (
				<div className={deviceStructure.className}>
					<GenerateHtmlMarkup htmlMarkup={structure} id={deviceStructure.structureName} className={`structure structure-${structureDeviceType}`} />
				</div>
			);
		} else {
			return '';
		}
	});
	return GenerateBrowserStructure();

};


/**
 * Get store state, add appropriate property to props to pass to component.
 *
 * @param state store state
 * @param props module props
 * @returns {{moduleOverrideInstances: []|*, defaultConfig: *, structures: []|*, subscriberId: *, customStrings: {}, moduleInstances: any, displays: {}, pubConfig: *}}
 */

const mapStateToProps = (state, props) => {
	return {
		// TODO: Determine if subscriberId is a "true" global and, if so, when it is set
		subscriberId: state.globals.subscriberId,
		headingReplacements: {
			collectionTitle: state.collectionTitle
		}
	};
};

/**
 * Actions that can be called by this module.  Each action is added to props so
 * that it can be called in the module, but defined in a single place with
 * appropriate parameters for the action call by this module.
 *
 * NOTE: These to generic redux actions are actually for global use and
 * should NOT be changed or medddled with.  They are used by global
 * navigation.
 *
 * @param dispatch call action
 * @returns {{fetchData: function(*=): void, updateData: function(*=, *=): void}}
 */
function mapDispatchToProps(dispatch) {
	const globalDispatch = {
		fetchData: (params) => {
			params.type = params.hasOwnProperty('type') ? params.type : GLOBALS;
			dispatch(fetchData(params));
		},
		updateData: (payload, params) => {
			params.type = params.hasOwnProperty('type') ? params.type : GLOBALS;
			dispatch(updateData(payload, params));
		},
		saveData: (payload, params) => {
			params.type = params.hasOwnProperty('type') ? params.type : GLOBALS;
			dispatch(saveData(payload, params));
		},
		deleteData: (id, params) => {
			params.type = params.hasOwnProperty('type') ? params.type : GLOBALS;
			dispatch(deleteData(id, params));
		}
	};
	// save these away in the store for generic use
	setStoreValue({attributeKey: 'genericUpdateData'}, globalDispatch.updateData);
	setStoreValue({attributeKey: 'genericFetchData'}, globalDispatch.fetchData);
	setStoreValue({attributeKey: 'genericSaveData'}, globalDispatch.saveData);
	setStoreValue({attributeKey: 'genericDeleteData'}, globalDispatch.deleteData);

	return globalDispatch;
}


/**
 * Provide connection to store
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PopulateStructure);
