import {isEmpty, isTrue} from "../generalUtils";


/**
 * Generate either an i18n string or text for the articleList title.
 *
 * The following replacement scenarios apply
 *     useTitleAttribute: true - this forces the use of the configuration title attribute if it exists
 *     list type === recent or fetch based on issueUrl: use issueName from first article with issueName attribute
 *     fetch based on categoriesList: use defined categoriesList and pick first one
 *
 * @param params
 *     moduleProps articleList props
 *     articleList: article list from state
 * @returns {*}
 */
const generateTitleAttribute = (params) => {
//const generateTitleAttribute = (moduleProps) => {
	params = Object.assign({
		moduleProps: {},
		articleList: []
	}, params);
	const moduleProps = params.moduleProps;
	const articleList = !isEmpty(params.articleList) ? params.articleList : moduleProps.articleList;
	if (isTrue(moduleProps.useTitleAttribute) && !isEmpty(moduleProps.title)) {
		return moduleProps.title;
	} else if (moduleProps.type === 'recent' || moduleProps.hasOwnProperty('issueUrl')) {
		if (!isEmpty(articleList)) {
			const article = articleList.find(article => article.hasOwnProperty('issueName'));
			if (typeof article !== 'undefined') {
				moduleProps.title = article.issueName;
			}
		}
	} else if (moduleProps.hasOwnProperty('categoriesList') && moduleProps.categoriesList.length > 0) {
		moduleProps.title = moduleProps.categoriesList[0];
	}
	return moduleProps.title;
};
export {generateTitleAttribute};
