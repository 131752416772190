import {arrayRemoveDups} from "./arrayUtils";
import {isEmpty} from "./generalUtils";



/*
	Set of routines to generate a "cleaned/legal" class string.  These are somewhat
	more restrictive than the class of all legal class strings, but will make sure
	that no illegal characters are added to a className.

	Our "Clean" ClassName
	    SHALL begin with a letter
	    SHALL contain only alphanumeric, _, or -
 */

/**
 * Given a className string with potential prefix and postfix, generate
 * a "cleaned" class name.
 *
 * @param params className, prefix, postfix
 * @returns {*} decoded string
 */
const generateCleanClassName = (params) => {
	const options = Object.assign({
		"className": '',
		"prefix": '',
		"postfix": ''
	}, params);
	let cleanName = '';
	if (typeof options.className === 'string' && options.className.trim() !== '') {
		cleanName = options.prefix + options.className.trim() + options.postfix;
		cleanName = cleanName.replace(/^[^a-z]|[^a-z0-9_-]/gi,'_');
	}
	return cleanName;
};
export {generateCleanClassName};

/**
 * Given an array of strings, form a full class name by "cleaning" each
 * string, then joining them with a space.
 *
 * @param params
 *     classArray: array of strings for each class name
 *     prefix: (optional) to add before each class name
 *     postfix: (optional) to add after each class name
 * @returns {string} full class name string
 */
const generateClassFromArray = (params) => {
	const options = Object.assign({
		classArray: [],
		prefix: '',
		postfix: ''
	}, params);
	let fullClassName = '';
	options.classArray = options.classArray.join(' ').split(' ');
	options.classArray = arrayRemoveDups(options.classArray);
	options.classArray.forEach((className) => {
		options.className = className.trim();
		fullClassName += !isEmpty(options.className) ? generateCleanClassName(options) + ' ' : '';
	});
	return fullClassName.trim();
};
export {generateClassFromArray};

/**
 * Generate a class name given a set of props with className set in props from module, where
 * the classNames are separated by space plus a set of optional params, generate a full
 * class name and a string suitable for testing as a css class
 *
 * @param props
 *     className; string
 *     active: (optional) string for active class
 * @param params
 *     defaultClass: string
 *     prefix: (optional) to add before each class name
 *     postfix: (optional) to add after each class name
 * @returns {string}
 */
const generateClassFromProps = (props, params) => {
	const options = Object.assign({
		defaultClass: 'default-class',
		prefix: '',
		postfix: ''
	}, params);
	let className = props.hasOwnProperty('className') ? (options.defaultClass + ' ' + props.className) : options.defaultClass;
	options.classArray = className.trim().split(' ');
	className = generateClassFromArray(options);
	const cssClass = '.' + className.split(' ').join('.').replace(/\.$/, "");
	className += props.active ? (' ' + props.active) : '';
	return {className: className, cssClass: cssClass};
};
export {generateClassFromProps};

/**
 * Given a comma-separated string of class names, clean each name then join
 * together to form a full class name
 *
 * @param params
 *     classCSV: comma-separated string, list of class names
 *     prefix: (optional) to add before each class name
 *     postfix: (optional) to add after each class name
 * @returns {string}
 */
const generateClassFromCSV = (params) => {
	const options = Object.assign({
		classCSV: '',
		prefix: '',
		postfix: ''
	}, params);
	options.classArray = typeof options.classCSV === 'string' ? options.classCSV.split(',') : [];
	return generateClassFromArray(options);
};
export {generateClassFromCSV};


/**
 * Add one or more new class names to an existing class name.
 * Different inputs for the added class name
 *     string: if no spaces, it will be taken as a single class name
 *     string: if spaces, it will be taken as multiple class names
 *     array: multiple class names
 *
 * @param className original class name
 * @param addClassName new class name(s) to add to the original; string or array
 * @returns {string} final class name to return
 */
const addClass = (className, addClassName) => {
	className = isEmpty(className) ? '' : className;
	addClassName = isEmpty(addClassName) ? [] : addClassName;
	addClassName = typeof addClassName === 'string' ? addClassName.split(' ') : (Array.isArray(addClassName) ? addClassName : []);

	const classArray = addClassName.concat(className.trim().split(' '));

	return generateClassFromArray({classArray: classArray});
};
export {addClass};


/**
 * Add one or more new class names to an existing class name.
 * Different inputs for the added class name
 *     string: if no spaces, it will be taken as a single class name
 *     string: if spaces, it will be taken as multiple class names
 *     array: multiple class names
 *
 * @param className original class name
 * @param removeClassName class name(s) to remove from the original; string or array
 * @returns {string} final class name to return
 */
const removeClass = (className, removeClassName) => {
	className = isEmpty(className) ? '' : className;
	removeClassName = isEmpty(removeClassName) ? '' : removeClassName;
	let classArray = className.trim().split(' ');
	if (typeof removeClassName === 'string') {
		removeClassName = removeClassName.split(' ');
		removeClassName.forEach(className => {
			classArray = classArray.filter(item => item !== className);
		});
	} else if (Array.isArray(removeClassName)) {
		removeClassName.forEach(className => {
			classArray = classArray.filter(item => item !== className);
		});
	}
	return generateClassFromArray({classArray: classArray});
};
export {removeClass};
