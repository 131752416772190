import {
	ARTICLE_LIST,
	ARTICLELIST_ARTICLELIST,
	ARTICLELIST_STOREDLIST,
	DATA_REQUESTED,
	DATA_LOADED,
	DATA_UPDATED, ID_NOT_SET,
	GLOBAL_ARTICLELIST_KEY
} from "../../_MODULE_GLOBALS/constants";
import deepmerge from "deepmerge";
import {storeInCache} from "../../../utils/manageStoreCacheData";
import {getIntValue, isEmpty} from "../../../utils/generalUtils";
import {findArticle} from "../../../utils/articleList/articleActions";
import {createNewStoreState} from "./generalReducerActions";

/*
   This reducer fetches and updates article list objects, which generally consist
   of an array of article objects.
 */

/**
 * Required store properties for a module that fetches lists of articles.
 *
 * This ensures that there is a placeholder in the store for
 * the values returned for the module.
 * Each action-type then initializes the state for it's store state.
 */
const createInitialState = ((storageKey) => {
	return {
		[storageKey]: {
			"collectionUrl": '',
			"articles": [],
			"articleId": '',
			"wdsArticleId": '',
			"articleList": [],
			"issueId": '',
			"issueUrl": '',
			"coverImageUrl": '',
			"section": '',
			"zoomLevel": '',

			"fetchListParams": {},
			"fetchQueryParams": null,
			"navigationAttributes": {},
			"fetchInProgress": false,

			"forceFetch": false,
			"replaceHistoryState": false,
			"updateHistoryState": true,

		}
	};
});
const createInitialShareState = ((storageKey, globalStoreKey) => {
	return {
		[storageKey]: {
			"collectionUrl": '',
			"fetchQueryParams": {},
			"articleList": [],
			"fetchInProgress": null
		},
		[globalStoreKey]: {
			"fetchQueryParams": {},
			"articleList": []
		}
	};
});
/**
 * List of attributes that should be re-initialized each time a reducer gets called.
 *
 * @type {function(*): {}}
 */
const resetStoreState = ((storageKey) => {
	return {
		[storageKey]: {
			"replaceHistoryState": false,
			"updateHistoryState": true,
			"forceStoreUpdate": false,
		}
	};
});



/**
 * Process returned article list from fetch and update the article that matches the
 * articlePreview object from the Article Editor.
 *
 * Use sessionStorage to keep content across reload in case of a browser major resize.
 *
 * Note: Just save preview to sessionStorage if articlePreview is not empty since
 * this won't happen very often.
 *
 * @param articleList article list from fetch
 * @param state store state
 * @returns {*}
 */
const updateArticleForEditorPreview = (articleList, state) => {
	const sessionPreview = window.sessionStorage.getItem("articleEditorPreview");
	const articlePreview = !isEmpty(state.articlePreview)
		? state.articlePreview
		: !isEmpty(sessionPreview) ? JSON.parse(sessionPreview) : {};
	if (!isEmpty(articlePreview)) {
		const articleId = getIntValue(articlePreview.articleId, ID_NOT_SET);
		const article = findArticle({articleList: articleList, articleId: articleId});
		if (!isEmpty(article)) {
			const articleIndex = articleList.findIndex(art => art.articleId === articleId);
			articleList[articleIndex] = Object.assign(article, articlePreview);
		}
		window.sessionStorage.setItem("articleEditorPreview", JSON.stringify(articlePreview));
	}
	return articleList;
};


function articleListReducers(state, action) {
	// check if module action call matches ARTICLE_LIST; if not present, set default to ARTICLE_LIST
	const moduleAction = (action.params && action.params.type) ? action.params.type : ARTICLE_LIST;

	if (moduleAction === ARTICLE_LIST && action.type === DATA_REQUESTED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'ArticleList';
		const returnState = deepmerge(
			createInitialShareState(storageKey, GLOBAL_ARTICLELIST_KEY),
			state
		);
		returnState[storageKey].fetchInProgress = true;

		return returnState;
	}

	if (moduleAction === ARTICLE_LIST && action.type === DATA_LOADED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'ArticleList';
		const returnState = deepmerge(
			createInitialShareState(storageKey, GLOBAL_ARTICLELIST_KEY),
			state
		);

		returnState[storageKey].collectionUrl = action.payload.collectionUrl ? action.payload.collectionUrl : '';
		const articleList = Array.isArray(action.payload.articles) ? updateArticleForEditorPreview(action.payload.articles, returnState) : [];
		const fetchQueryParams = action.params.fetchQueryParams ? action.params.fetchQueryParams : null;
		returnState[storageKey].articleList = articleList;
		returnState[storageKey].fetchQueryParams = fetchQueryParams;
		returnState[storageKey].docType = action.payload.docType ? action.payload.docType : '';

		// we need to store article lists for use by share (and maybe by multiple modules)
		// this property is read in PopulateGlobalModules and stored in the redux store
		returnState[GLOBAL_ARTICLELIST_KEY].articleList = articleList;
		returnState[GLOBAL_ARTICLELIST_KEY].fetchQueryParams = fetchQueryParams;

		// cache return articleList with query params as key
		returnState[storageKey].generatedCacheKey = storeInCache({dataKey: fetchQueryParams, keyType: "articles", data: articleList, overrideEmptyData: true, state: returnState});

		returnState[storageKey].fetchInProgress = false;

		return returnState;
	}

	if (moduleAction === ARTICLELIST_STOREDLIST && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'ArticleList';
		const returnState = deepmerge(
			createInitialShareState(storageKey, GLOBAL_ARTICLELIST_KEY),
			state
		);

		const articleList = Array.isArray(action.payload.articleList) ? action.payload.articleList : [];
		const fetchQueryParams = action.params.fetchQueryParams ? action.params.fetchQueryParams : null;
		returnState[storageKey].articleList = articleList;
		returnState[storageKey].fetchQueryParams = fetchQueryParams;

		returnState[storageKey].fetchInProgress = false;

		return returnState;
	}

	// Action called through clicking on article from article list
	if (moduleAction === ARTICLELIST_ARTICLELIST && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : 'ArticleList';

		// create new store state and update only changed attributes
		return createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialState(storageKey),
			resetStorageStateValues: resetStoreState(storageKey)
		});
	}


	return state;
}
export default articleListReducers;
