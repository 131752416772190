import React, {useState, useEffect, useRef} from 'react';
import {
	AUTHENTICATION,
	AUTHENTICATION_KEY,
	ECOMMERCE_INAPP_LOGIN,
	GLOBALS_KEY,
	SUBSCRIBER,
	SUBSCRIBER_LOGIN
} from "../../../_MODULE_GLOBALS/constants";
import {AuthenticationMsg, trackButtonClick} from "../index";
import {Translate} from "../../../../locales/locales";
import {appCaller} from "../../../../utils/nativeAppInterface";
import {getStoreValue} from "../../../../utils/storeValue";
import {isEmailValid} from "../../../../utils/validationUtils";
import * as Tracking from "../../../../utils/tracking";
import {isEmpty, isTrue} from "../../../../utils/generalUtils";



/**
 * Handle click on the Subscribe button, including tracking call.
 *
 * @param params
 */
const subscribeButton = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		action: "subscribe link",
	}));
	if (window) {
		window.location.href = params.collectionSubscribeUrl;
	}
};

/**
 * Handle click on the app Restore button, including tracking call.
 *
 * @param params
 */
const appRestoreButton = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		type: "restore button",
		action: "restore",
	}));
	appCaller({type: 'restore'});
};

/**
 * Handle click on the Subscribe Screen Link button, including tracking call.
 *
 * @param params
 */
const subscribeScreenLink = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		type: "toggle access",
		action: "subscribe screen",
	}));
	// go to prompt/ecommerce dialog
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
	updateData({authView: params.authView}, {type: AUTHENTICATION, storageKey: AUTHENTICATION_KEY });
};


/**
 * Handle login submit of email address for validation.  Also call tracking for
 * button click.
 *
 * If valid email, call to backend to check email, then call updateData to
 * update the current emailAddress; this also triggers in-progress validation
 * message.
 * If not valid email, call updateData to trigger display of error message for
 * invalid email.
 *
 *
 * WHY CALL UPDATEDATA?
 *
 * The updateData call will set redux store values that will be handled by the
 * authentication module.  This will then trigger a re-render in the authentication
 * module which will then call the appropriate widget to display the correct
 * dialog.  In this case, the re-render will call this widget (login) to re-render
 * the widget and display the proper messages.
 *
 * This re-render of the widget will then trigger a library tracking call
 * which will also trigger a call to external trackers, and, specifically, a
 * call to the adobeDTM tracker.
 * This seems kind of arbitrary and circular, but the adobeDTM tracker, when used
 * with the React render mechanism, requires a context-switch; ie. it requires
 * that we enter a module/widget to trigger adobeDTM tracking.  In this case,
 * we exit the login widget, then re-enter the login widget to trigger tracking.
 *
 *
 * @param params tracking parameters
 */
const loginSubmitAction = (params) => {
	const trackingProperties = Object.assign({}, params.baseTrackingProperties, {
		"label": params.label,
		"type": "login",
		"popupType": "login",
		"subscriber status": params.isLoggedIn ? "subscriber" : "lookinside",
		"action": "submit",
		"category": "login popup action",
	});
	trackButtonClick(trackingProperties);

	const emailAddress = params.emailInput.current !== null ? params.emailInput.current.value : '';
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
	if (isEmailValid(emailAddress)) {
		const fetchData = getStoreValue({attributeKey: 'genericFetchData'});
		const queryParams = { "emailAddress": emailAddress };
		updateData({clearErrorMsg: false, emailAddress: emailAddress, malformedEmail: false}, {type: AUTHENTICATION, storageKey: AUTHENTICATION_KEY});
		// call to check if email is a valid subscriber
		fetchData({type: SUBSCRIBER, storageKey: GLOBALS_KEY, queryParams: queryParams});
	} else {
		updateData({clearErrorMsg: false, emailAddress: emailAddress, malformedEmail: true}, {type: AUTHENTICATION, storageKey: AUTHENTICATION_KEY});
	}
};

/**
 * Handle key press in email input field; detect Enter key to trigger login check.
 *
 * @param key key press value
 * @param params
 */
const handleKeypress = (key, params) => {
	if (key === 'Enter') {
		loginSubmitAction(params);
	}
};

/**
 * Widget for submanaged access UI.
 *
 * @param params
 * @returns {JSX.Element}
 * @constructor
 */
const SubmanagedAccess = (params) => {
	const promptLabel = Translate.Text({id:'authenticate.prompt.button'});
	const loginLabel = Translate.Text({ id: 'authenticate.prompt.loginLink' });
	const emailInput = useRef(null);

	if (params.isSubmanaged && params.isLoggedIn && !params.userHasAccess) {
		return (
			<>
				<div className={"signedInNoAccess"} />
				<p className="copy">{Translate.Text({id:'authenticate.prompt.copy'})}</p>
				<AuthenticationMsg {...params.messageParams} />
				<button id={'subscribe-button'} className={'button'} onClick={() => subscribeButton({label: promptLabel, collectionSubscribeUrl: params.collectionSubscribeUrl})}>{promptLabel}</button>
			</>
		);
	} else {
		const loginParams = Object.assign({}, params, {
			label: loginLabel,
			isLoggedIn: params.isLoggedIn,
			emailInput: emailInput
		});
		return (
			<>
				<p className="copy">{Translate.Text({id:'authenticate.login.copy'})}</p>
				<AuthenticationMsg {...params.messageParams} />
				<div className="login-prompt">
					<input autoFocus type={"text"} name="subscriberEmail" aria-label={Translate.Text({id: "authenticate.login.emailLabel"})}
						   ref={emailInput}
						   placeholder={Translate.Text({id: "authenticate.login.emailPlaceholder"})}
						   onKeyDown={(evt) => {
							   const key = evt.key;
							   evt.stopPropagation();
							   handleKeypress(key, loginParams);
						   }}/>
					<button id={'login-button'} className={'button'} onClick={(evt) => {
						evt.stopPropagation();
						loginSubmitAction(loginParams);
					}}>{loginLabel}</button>
				</div>
			</>
		);
	}
};


/**
 * Widget for the "login" dialog
 *
 * @param params props + additionalProps passed in from the authentication parent module
 * @returns {JSX.Element}
 * @constructor
 */
const AuthenticateLogin = (params) => {
	const [loginPopupTracked, setLoginPopupTracked] = useState(false);
	const [subscriberInProgress, setSubscriberInProgress] = useState(false);
	const [loginEmailAddress, setLoginEmailAddress] = useState('');

	const isEcommerce = params.authenticationType === ECOMMERCE_INAPP_LOGIN;
	const signedInNoAccess = params.isSubmanaged && params.isLoggedIn && !params.userHasAccess;

	const messageParams = {
		"clearErrorMsg": !signedInNoAccess && (params.clearErrorMsg || isEmpty(params.emailAddress)),
		"className": "error",
	};
	if ( params.malformedEmail) {
		messageParams.message = Translate.Text({id: 'authenticate.login.emailError'});
	} else if (isTrue(params.subscriberInProgress, {defaultValue: false})) {
		messageParams.message = Translate.Text({id: 'authenticate.login.loginCheck'});
		messageParams.className = "information";
	} else if (signedInNoAccess) {
		messageParams.message = Translate.Text({id:'authenticate.login.signedInNoAccess'});
	} else if (params.machineLimitReached) {
		messageParams.message = Translate.Text({id:'authenticate.login.machineLimitReached'});
	} else {
		messageParams.message = Translate.Text({id:'authenticate.login.loginError'});
	}

	const accessProps = {
		"isSubmanaged" : !params.app && params.authenticationType === SUBSCRIBER_LOGIN,
		"isLoggedIn" : params.isLoggedIn ,
		"isPurchaseSuccessful" : params.isPurchaseSuccessful,
		"userHasAccess": params.userHasAccess,
		"authenticationStorageKey": params.storageKey,
	};

	const displayText = {
		purchaseScreenLabel: Translate.Text({id: 'ecommerce.purchaseScreenLink'}),
		subscribeScreenLabel: Translate.Text({id: 'authenticate.login.subscribeLink'}),
		restorePurchasesLabel: Translate.Text({id: "ecommerce.restorePurchasesLink"}),
	};

	const authParams = {
		authenticationType: params.authenticationType,
		collectionSubscribeUrl: params.collectionSubscribeUrl,
		// setup base attributes for tracking for this widget
		baseTrackingProperties: {
			"destination type": params.popupType,
			"issue name": "_" + params.issueName,
			"issue url": params.issueUrl,
		},
		messageParams: messageParams
	};

	// call to check for tracking if in the current pane
	if (isTrue(params.isMyPane)) {
		const trackingProperties = {
			"popupType": "login",
			"type": "login",
		};
		const firstLoginPopup = !isTrue(loginPopupTracked);
		const emailUpdated = !isEmpty(params.emailAddress) && loginEmailAddress !== params.emailAddress;
		const loginFailed = !isTrue(params.subscriberInProgress) && subscriberInProgress !== params.subscriberInProgress && !isTrue(params.isLoggedIn, {defaultValue: false});

		if (emailUpdated) {
			// email address changed; indicates login verification in progress
			setLoginEmailAddress(params.emailAddress);
			setSubscriberInProgress(isTrue(params.subscriberInProgress));
			trackingProperties.category = "login popup action";
			trackingProperties["subscriber status"] = "login in-progress";
			trackingProperties.action = "submit";
		} else if (loginFailed) {
			// in-progress done; indicates login failed
			setSubscriberInProgress(isTrue(params.subscriberInProgress));
			trackingProperties.category = "login popup appeared";
			trackingProperties["subscriber status"] = "login failed";
		} else if (firstLoginPopup) {
			// triggered on first-time entry
			trackingProperties.category = "login popup appeared";
			setLoginPopupTracked(true);
		} else {
			// don't call tracking
			trackingProperties.type = "";
		}
		if (!isEmpty(trackingProperties.type)) {
			Tracking.libraryTrack(trackingProperties.category, trackingProperties);
		}
	}


	return (
		<div className={"authentication authenticate-login"}>
			<div className="authenticate-screen" />
			<div className="authenticate">
				<div className={params.popupType}>
					<h2 className="title">{Translate.Text({id:'authenticate.login.title'})}</h2>
					<SubmanagedAccess {...accessProps} {...authParams} />
					{isEcommerce ?
						<div>
							<button id={'ecommerce-link'} className={'button ecommerce-link'} onClick={() => subscribeScreenLink({...authParams, authView: "ecommerce", label: displayText.purchaseScreenLabel})}>
								<i className="fal fa-long-arrow-left" /> {displayText.purchaseScreenLabel}
							</button>
							<button className={'restore-purchases-link'} onClick={() => appRestoreButton({...authParams, authView: "restore", label: displayText.subscribeScreenLabel})}>
								<i className="fal fa-history" /> {displayText.restorePurchasesLabel}
							</button>
						</div>
						: !signedInNoAccess ?
							<div>
								<button id={'ecommerce-link'} className={'button ecommerce-link'} onClick={() => subscribeScreenLink({...authParams, authView: "prompt", label: displayText.subscribeScreenLabel})}>
									<i className="fal fa-long-arrow-left" /> {displayText.subscribeScreenLabel}
								</button>
							</div>
							:
							<div />
					}
				</div>
			</div>
		</div>
	);

};
export {AuthenticateLogin};
