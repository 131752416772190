import {getModuleContainer} from "../modules/globals/globalLibraryData";
import $ from 'jquery';
import {isEmpty} from "../utils/generalUtils";
import {setDOMProperties} from "../modules/globals/setDOMProperties";


/**
 * Set the scroll when changing replica pages.  Currently, just sets to the top.
 * Pass the module and storageKey to get replica container.
 *
 * Note: There are a number of DOM elements that might be scrolled,
 * scroll them all up to the top.
 *
 * @param params
 *     module: replicaViewer
 *     storageKey: storage key from caller
 */
const setScrollToTop = (params) => {
	const containerData = getModuleContainer(params);
	const $replicaContainer = $('#'+containerData.pane).find('.'+containerData.container);
	const $layoutContainer = $replicaContainer.parents('div.layout-block');
	$("html, body").scrollTop(0);
	$replicaContainer.animate({ scrollTop: 0 }, 100);
	$layoutContainer.animate({ scrollTop: 0 }, 100);
};

/**
 * Respond to an action on a replica page that requires a browser back-end response
 * rather than one from within the module itself.
 *
 * First action is to respond to an replica page open (ie. change of replica page) and
 * scroll to the top of the page.  This requires scrolling the browser
 * to the top and also the container for the replicaViewer.
 *
 * @param params parameters for replica trigger
 *     action: 'viewed'
 *     module: module name
 *     storageKey: module instance storageKey
 *     page: pointer to replica page
 * @returns {string} success/failure
 */
const triggerOnReplica = (params) => {
	params = Object.assign({
		module: 'replicaViewer',
		storageKey: '',
		triggerProperties: {},
		DOMProperties: {
		}
	}, params);
	const {module, storageKey} = params;
	const triggerProperties = Object.assign({
		module: module,
		storageKey: storageKey,
		action: ''
	}, params.triggerProperties);
	const DOMProperties = Object.assign({
		module: module,
		storageKey: storageKey,
		moduleDOMElement: null,
		useModuleFocus: false,
		useModuleTitle: false,
		setDOMFocus: false
	},params.DOMProperties);

	setDOMProperties(DOMProperties);

	if (!isEmpty(module) && !isEmpty(storageKey)) {
		setScrollToTop(triggerProperties);
	}
	return 'success';
};
export {triggerOnReplica};
