/**
 * Return an article attribute if it exists.
 *
 * @param params attribute
 * @returns {{value: string}} return as object for caller
 */
const getArticleAttribute = (params) => {
	const attribute = params.hasOwnProperty('attribute') ? params.attribute : '';
	const value = params.article.hasOwnProperty(attribute) ? params.article[attribute] : '';
	return {value: value};
};
export default getArticleAttribute;