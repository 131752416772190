import React, {useEffect, useState} from 'react';
import {
	DEFAULT_REPLICA_ZOOM_LEVEL,
	MAX_REPLICA_ZOOM_LEVEL,
	MIN_REPLICA_ZOOM_LEVEL,
	REPLICA_ZOOM_STEP_LEVEL
} from "../../_MODULE_GLOBALS/constants";
import {getFloatValue, isEmpty} from "../../../utils/generalUtils";
import {manageUpdate} from "../../globals/globalNavigator";
import {addClass} from "../../../utils/generateClassName";
import {Translate} from "../../../locales/locales";
import {getReplicaPages} from "../../../utils/replicaList/replicaActions";
import * as Tracking from "../../../utils/tracking";



/**
 * Handle click from Zoom
 */
const updateZoom = (params) => {
	params = Object.assign({
		evt: null,
		zoomSize: 1,
		moduleProps: {}
	}, params);

	const updateParams = {
		navigationKey: !isEmpty(params.moduleProps.navigationKey) ? params.moduleProps.navigationKey : 'replica',
		navigationKeys: !isEmpty(params.moduleProps.navigationKeys) ? params.moduleProps.navigationKeys : {},
		attributes: {
			zoomLevel: params.zoomSize
		},
	};

	const storeState = !isEmpty(params.moduleProps.storeState) ? params.moduleProps.storeState : {};
	const folio = storeState.folio;
	const replicaList = storeState.replicaList;
	const pageSpread = storeState.pageSpread;
	const pageAttributes = {
		replicaList: replicaList,
		pageSpread: pageSpread,
	};
	const pagesData = getReplicaPages({folio: folio, pageAttributes: pageAttributes});
	const pagesFolios = [];
	pagesData.pages.forEach(page => {
		pagesFolios.push(page.folio);
	});

	const trackingProperties = {
		folio: pagesFolios,
		label: params.moduleProps.trackingLabel,
		location: "replica viewer",
		module: "page zoom",
		type: "click",
		"url": (window ? window.location.href : ''),
	};
	Tracking.libraryTrack("button clicked",trackingProperties);

	manageUpdate(updateParams);

};

/**
 * Start of jsx code for module
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PageZoom = (props) => {
	const moduleProps = Object.assign({
		GeneratedTag: `button`,
		className: '',
		title: '',
		label: '',
		navigationKey: '',
		navigationKeys: {},
		storeState: {},
		storageKey: 'replicaViewer'
	}, props);

	const storeState = !isEmpty(moduleProps.storeState) ? moduleProps.storeState : {};
	// keep track of page zoom level (1 (default); up to 2)
	const [zoomSize, setZoomSize] = useState(DEFAULT_REPLICA_ZOOM_LEVEL);

	const className = addClass('replica-viewer-control page-zoom', moduleProps.className);
	const zoomLevel = getFloatValue(storeState.zoomLevel, zoomSize);

	/**
	 * If zoomLevel size changes, update the state value.
	 * This can happen through click on the button or from initialization
	 * data update from replicaViewer
	 */
	useEffect(() => {
		if (zoomSize !== zoomLevel) {
			setZoomSize(zoomLevel);
		}
	}, [zoomLevel, zoomSize]);

	const zoomConstants = {
		zoomMax: !isEmpty(storeState.zoomMax) ? storeState.zoomMax : MAX_REPLICA_ZOOM_LEVEL,
		zoomMin: !isEmpty(storeState.zoomMin) ? storeState.zoomMin : MIN_REPLICA_ZOOM_LEVEL,
		zoomStep: !isEmpty(storeState.zoomStep) ? storeState.zoomStep : REPLICA_ZOOM_STEP_LEVEL,
		label: Translate.Text({'id': 'actionButton.zoomButton.label'}),
		zoomInTitle: Translate.Text({'id': 'actionButton.zoomButton.zoomIn'}),
		zoomOutTitle: Translate.Text({'id': 'actionButton.zoomButton.zoomOut'}),
	};
	const zoomClass = {
		zoomIn: 'zoom-plus',
		zoomOutTitle: zoomConstants.zoomOutTitle,
		zoomOut: 'zoom-minus',
		zoomInTitle: zoomConstants.zoomInTitle
	};
	if (zoomSize <= zoomConstants.zoomMin) {
		zoomClass.zoomOut = 'zoom-minus disabled';
		zoomClass.zoomOutTitle = '';
	}
	if (zoomSize >= zoomConstants.zoomMax) {
		zoomClass.zoomIn = 'zoom-plus disabled';
		zoomClass.zoomInTitle = '';
	}

	return (
		<div className={className}>
			<span>{zoomConstants.label}</span>
			<button
				className={zoomClass.zoomOut}
				title={zoomClass.zoomOutTitle} aria-label={zoomClass.zoomOutTitle}
				onClick={(evt) => {
					const newZoomSize = zoomSize - zoomConstants.zoomStep;
					if (newZoomSize >= zoomConstants.zoomMin) {
						moduleProps.trackingLabel = 'Zoom Out';
						setZoomSize(newZoomSize);
						updateZoom({evt: evt, zoomSize: newZoomSize, moduleProps: moduleProps});
					}
				}}
			>
				<span className={'fal fa-minus-circle'} />
			</button>
			<button
				className={zoomClass.zoomIn}
				title={zoomClass.zoomInTitle} aria-label={zoomClass.zoomInTitle}
				onClick={(evt) => {
					const newZoomSize = zoomSize + zoomConstants.zoomStep;
					if (newZoomSize <= zoomConstants.zoomMax) {
						moduleProps.trackingLabel = 'Zoom In';
						setZoomSize(newZoomSize);
						updateZoom({evt: evt, zoomSize: newZoomSize, moduleProps: moduleProps});
					}
				}}
			>
				<span className={'fal fa-plus-circle'} />
			</button>
		</div>
	);
};
export {PageZoom};

