import getDeviceType from "../../../../utils/getDeviceType";
import {isEmpty} from "../../../../utils/generalUtils";
import * as Tracking from "../../../../utils/tracking";


/**
 * Capture click on a replica page <a> tag link and make a tracking call.
 *
 * Note: Links are setup for both internal (gotoPage call) and external
 * web/mailto calls, but we only want to track the external calls.
 *
 * It is possible that we might want to track internal link clicks
 * in the future, at which point, this function should probably call
 * either make an external function call for external links or an internal
 * function call for internal links.
 *
 * @param params
 *     link: link properties attached to the external link tag
 *     pageFolio: folio page on which the link appears
 *     type: internal/external; type of link clicked; not currently used
 */
const trackLinkClick = (params) => {
	params = Object.assign({
		link: {},
		pageFolio: '',
		type: '',
		issueName: '',
		issueDate: '',
	}, params);
	const {link, pageFolio, issueName, issueDate} = params;

	const matchWebDomain = new RegExp('(https*://|//|/|.*?)(.*?)(/|$)');
	const domain = link.link.includes('mailto:') ? link.link.split('@')[1] : link.link.match(matchWebDomain)[2];

	const linkTrackingProperties = {
		"breakpoint": getDeviceType(),
		"module": "replica viewer",
		"issue name": "_" + issueName,
		"issue date": issueDate,
		"label": link.alt,
		"page folio": pageFolio,
		"url": link.link,
		"domain": !isEmpty(domain) ? domain : '',
	};
	Tracking.libraryTrack("link clicked", linkTrackingProperties);
};
export {trackLinkClick};
