import React, {useEffect, useState, useRef} from 'react';
import {connect} from "react-redux";
import {
	REPLICA_VIEWER,
	REPLICA_LIST,
	ID_NOT_SET,
	GLOBALS,
	DEFAULT_REPLICA_PAGE_SPREAD,
	DEFAULT_REPLICA_ZOOM_LEVEL,
	NO_LOGIN
} from "../../_MODULE_GLOBALS/constants";
import {fetchData, updateData} from '../../../store/actions';
import {isTrue, isEmpty, getIntValue, getFloatValue} from "../../../utils/generalUtils";
import {useFetchAttributesChange, useFetchComplete} from "../../../hooks/useFetchChange";
import {generateMops, generateQueryParams,	displayOnDevice, dataModuleAttributes} from "../../../utils/moduleSetup";
import {manageUpdate, manageUpdateAndNavigation} from "../../globals/globalNavigator";
import {addClass} from "../../../utils/generateClassName";
import {reorderNavigationKeysModules} from "../../../utils/reorderNavigationKeysModules";
import {populateFetchDataAttributes} from "../../../utils/populateFetchDataAttributes";
import {getReplicaList} from "../../../utils/replicaList/getReplicaList";
import {getStoreValue} from "../../../utils/storeValue";
import {getObjectFromJSON, clone} from "../../../utils/objectUtils";
import GenerateImage from "../../../widgets/generateImage";
import {PreviousNextReplicaButton} from "../../../widgets/generatePreviousNextReplicaButton";
import {useKeyEvents, enableDisableKeyEvent} from "../../../hooks/useKeyEvents";
import {getPreviousNextPages, previousNextPageClicked} from "../../../utils/previousNextUtils";
import {triggerOnReplica} from "../../../triggers/triggerOnReplica";
import {Translate} from "../../../locales/locales";
import {deviceReplicaLayout, findReplicaPage, getReplicaPages} from "../../../utils/replicaList/replicaActions";
import {useMountPostRender} from "../../../hooks/useMount";
import {GenerateInProgressPage} from "../../../widgets/generatePages";
import {useAttributesChanged} from "../../../hooks/useAttributesChanged";
import { useVisibilityChange } from "../../../hooks/useVisibilityChange";
import * as Tracking from "../../../utils/tracking";
import getDeviceType from "../../../utils/getDeviceType";
import { useImageLoadedMultiple} from "../../../hooks/useImageLoaded";
import {generatePageLinks} from "./post_render/pageLinks";
import {MediaPlayer} from "../../../widgets/mediaPlayer";



// Maximum tracking duration
const MAX_DURATION = 30 * 60; // 30 minutes (in seconds)

// list of possible query params sent with api call
// key is props name; value is fetch parameter
const configQueryParams = {
//	'maxEntries':'maxEntries',  // maxEntries has been removed
	'pageSize':'pageSize',  // maxEntries was an alias for pageSize
	'issueUrl': 'issueUrl',
	'isLoggedIn': 'isLoggedIn',
	'isPurchaseSuccessful': 'isPurchaseSuccessful',
	'purchaseProductId' : 'purchaseProductId',
	'userAccessHash': 'userAccessHash',
	'u1': 'u1',
};

/*
	list of potential configuration parameters; other than query params
		className: optional class name to add to div
		displayTitle: {true/false} - display the title; default false if not set
		i18nId: {string} - key to string for title in strings (locale) file
 */


/**
 * Call tracking with page and time, as long as the stored folio is not ID_NOT_SET
 *
 * @param params tracking parameters
 */
const trackPageView = (params) => {
	params = Object.assign({
		trackPage: {},
		trackFolios: [],
		documentTitle: '',
		fullPageAd: false,
		deviceSize: '',
		trackingStartTime: 0,
		trackingEndTime: 0,
		trackingEndType: ''
	}, params);
	if (isEmpty(params.trackPage)) {
		return;
	}
	const trackPage = Object.assign({
		folio: ID_NOT_SET,
	}, params.trackPage);

	// make sure that stored folio not ID_NOT_SET which indicates first-time in replicaViewer
	if (trackPage.folio === ID_NOT_SET) {
		return;
	}

	// duration (in seconds), but no more than MAX_DURATION
	let duration = (params.trackingEndTime - params.trackingStartTime)/1000;
	if (duration > MAX_DURATION) {
		duration = MAX_DURATION;
	}
	const trackingProperties = {
		"type": "page view",
		"$duration": duration,
		"module": "replica viewer",
		"category": "content viewed",
		"document title": (document ? document.title : ''),
		"url": (window ? window.location.href : ''),
		"issue name": "_" + params.documentTitle,
		"page folio": params.trackFolios,
		"page folio - issue name": params.trackFolios.join(',') + ' - ' + params.documentTitle,
		"split": trackPage.edition ? trackPage.edition : "none",
	};
	if (params.hasOwnProperty("authenticationType") && params.authenticationType !== NO_LOGIN) {
		trackingProperties["subscriber status"] = isTrue(params.isLoggedIn) ? "subscriber" : "lookinside";
	}

	Tracking.libraryTrack("content viewed", trackingProperties);

	// track full-page ads
	if (params.fullPageAd) {
		const adProperties = {
			breakpoint: params.deviceSize,
			name: (document ? document.title : ''),
			module: "replica viewer",
			location: "full-page-ad",
		};
		Tracking.libraryTrack("advertisement viewed", adProperties);
	}
};

/**
 * Go to a replica page based on a page number.  This triggers manageUpdateAndNavigation which manages
 * the display of the page and whether or not the url is built through share.  This is called
 * by both prev/next buttons and through refresh of the underlying replica list for the viewer.
 *
 * It can also be called through the history back/next button, so we must pass the fromHistory
 * state to ensure that the history is not updated and the url populated when we are calling
 * from the browser back/next button.
 *
 * @param params
 *     props: module props
 *     mops: module mops
 *     replicaList: replica list for viewer
 *     page: index of page to display from replicaList
 */
const gotoPage = (params) => {
	params = Object.assign({
		props: {},
		queryParams: {},
		replicaList: [],
		pageNumber: ID_NOT_SET,
		folio: '',
		pageSpread: '',
		zoomLevel: '',
		replaceHistoryState: false
	}, params);
	const replicaList = !isEmpty(params.replicaList) ? params.replicaList : (!isEmpty(params.props.replicaList) ? params.props.replicaList : {});
	const destinationPage = findReplicaPage({replicaList: replicaList, folio: params.folio, pageNumber: params.pageNumber});
	const issueUrl = !isEmpty(destinationPage) ? destinationPage.issueUrl : '';
	const moduleProps = populateFetchDataAttributes({
		props: params.props,
		data: destinationPage
	});
	const navigationKeys = clone(moduleProps.navigationKeys);
	const fetchNavigationKey = !isEmpty(moduleProps.fetchNavigationKey) && navigationKeys.hasOwnProperty(moduleProps.fetchNavigationKey) ? moduleProps.fetchNavigationKey : 'replica';
	if (navigationKeys.hasOwnProperty(fetchNavigationKey) && navigationKeys[fetchNavigationKey].hasOwnProperty('modules')) {
		navigationKeys[fetchNavigationKey].modules = reorderNavigationKeysModules({modules: navigationKeys[fetchNavigationKey].modules, key: 'replica'});
	}

	manageUpdateAndNavigation({
		navigationKey: fetchNavigationKey,
		moduleProps: moduleProps,
		attributes: {
			fetchQueryParams: getObjectFromJSON(params.queryParams, {}),
			pageNumber: params.pageNumber,
			folio: params.folio,
			issueUrl: issueUrl,
			replicaList: params.replicaList,
			pageSpread: !isEmpty(params.pageSpread) ? params.pageSpread : (!isEmpty(moduleProps.pageSpread) ? moduleProps.pageSpread : 1),
			zoomLevel: !isEmpty(params.zoomLevel) ? params.zoomLevel : (!isEmpty(moduleProps.zoomLevel) ? moduleProps.zoomLevel : 1),
			documentTitle: !isEmpty(moduleProps.documentTitle) ? moduleProps.documentTitle : '',
			fromHistory: !isEmpty(moduleProps.navigationAttributes) ? isTrue(moduleProps.navigationAttributes.fromHistory, {defaultValue: false}) : false,
			replaceHistoryState: isTrue(params.replaceHistoryState, {defaultValue: false}) ? isTrue(params.replaceHistoryState) : isTrue(moduleProps.replaceHistoryState)
		}
	});
};

/**
 * Go to an internal replica page on click of a link.
 * Since it comes from a link, call to stop propagation first, then
 * call navigation through the gotoPage function.
 *
 * @param evt click event
 * @param props module props
 * @param replicaList replica list
 * @param folio folio page to go to
 */
const gotoInternalPage = ({evt, props, replicaList, folio}) => {
	evt.stopPropagation();
	evt.preventDefault();
	// call to trackLinkClick would go here if we decide to track click to internal page
	gotoPage({
		props: props,
		replicaList: replicaList,
		folio: folio,
	});
};

/**
 * Generate the page jsx for display.
 *
 * @param params
 *     props: module props
 *     mops: module mops
 *     pageAttributes: attribute values for the specific page
 * @returns {string|*} react object for page for display
 * @constructor
 */
const GeneratePage = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		pageAttributes: {}
	}, params);
	const {props, mops, pageAttributes} = params;
	const moduleDOMElement = pageAttributes.DOMElements.module;

	const pagesData = getReplicaPages({folio: props.folio, className: mops.className, pageAttributes: pageAttributes});
	//Return empty string if no page
	if (!pagesData.pageDetails.hasPage) {
		if (!isEmpty(pageAttributes.replicaList) && isTrue(props.isMyPane)) {
			gotoPage({
				props: props,
				replicaList: pageAttributes.replicaList,
				pageNumber: 1,
				pageSpread: pageAttributes.pageSpread,
			});
		}
		return '';
	}
	// update cssVar for zoomLevel css calculation of replicaPage height/width
	const dataAttributes = {
		'data-storagekey': props.storageKey,
		'data-issue-group': props.issueGroup,
		'style': {
			"--zoomLevel": pageAttributes.zoomLevel
		}
	};

	// get page details for each page in the spread
	// store folios and determine if any page is not fully accessible (ie. not logged in)
	const currentPage = pagesData.pageDetails.currentPage;
	const pagesFolios = [];
	let noAccess = "";
	pagesData.pages.forEach(page => {
		pagesFolios.push(page.folio);
		noAccess = isTrue(page.userHasAccess, {defaultValue: true}) ? noAccess : "no-access";
	});

	const prevNextAttributes = {
		props: props,
		queryParams: mops.queryParams,
		replicaList: pageAttributes.replicaList,
		pageNumber: currentPage.pageNumber,
		folio: currentPage.folio,
		pagesFolios: pagesFolios,
		documentTitle: props.documentTitle,
		pageSpread: pageAttributes.pageSpread,
		zoomLevel: pageAttributes.zoomLevel,
		clickAction: gotoPage
	};

	const trackImageClick = () => {
		// @TODO: track click on image
	};


	// page spread value can be 1/2; 3 is historically possible, but probably won't happen now
	const pageSpread = !isEmpty(pageAttributes.pageSpread) ? pageAttributes.pageSpread : 0;
	const pageSpreadClasses = ['', 'one-page-spread', 'two-page-spread', 'three-page-spread'];
	const replicaClasses = {
		module: pagesData.pageDetails.moduleClassName,
		container: addClass('replica-viewer-container', pageSpreadClasses[pageSpread]),
		images: addClass('replica-viewer-images', noAccess),
		page: addClass('replica-viewer-page'),
	};

	const pageIdentifier = 'FOLIO-' + currentPage.folio;
	return (
		<div className={replicaClasses.module} {...dataAttributes}>
			<PreviousNextReplicaButton direction={'previous'} {...prevNextAttributes} />
			<div className={replicaClasses.container} data-page-identifier={pageIdentifier} tabIndex={-1} ref={moduleDOMElement}>
				<div className={replicaClasses.images}>
					{pagesData.pages.map((page, index) => {
						// check and set image properties
						const imageProperties = {
							alt: 'page ' + page.folio,
							src: page.hasOwnProperty('image') ? page.image : '#',
							link: false,
							"data-folio": page.folio,
							"data-folios": pagesFolios.join(","),
						};
						const folioIdentifier = 'FOLIO-' + page.folio;
						const folioDOMElement = index === 0 ? pageAttributes.DOMElements.folio1 : pageAttributes.DOMElements.folio2;
						const optionalMediaPlayerAttributes = {
							module: 'replicaViewer',
							attributes: {
								gotoInternalPage: gotoInternalPage,
								props: props,
								replicaList: pageAttributes.replicaList
							}
						};

						return (
							<div key={page.folio} className={replicaClasses.page}>
								<figure data-image-folio={page.folio} data-image-folio-identifier={folioIdentifier} tabIndex={-1} ref={folioDOMElement}>
									<GenerateImage imageProperties={imageProperties} onClick={trackImageClick} />
								</figure>
								<MediaPlayer
									popups={page.popups}
									issueHasAccess={props.issueHasAccess}
									callerAttributes={optionalMediaPlayerAttributes}
								/>
							</div>
						);
					})}
				</div>
			</div>
			<PreviousNextReplicaButton direction={'next'} {...prevNextAttributes} />
		</div>
	);
};


const pageViewed = (params) => {
	params = Object.assign({
		props: {},
		pagesData: {},
		replicaList: [],
		currentViewedFolio: ID_NOT_SET,
		trackingStartTime: 0
	}, params);
	const replicaList = typeof params.replicaList !== 'undefined' ? params.replicaList : [];
	const currentViewedFolio = typeof params.currentViewedFolio !== 'undefined' ? params.currentViewedFolio : ID_NOT_SET;

	// get page details for each page in the spread
	// store folios and determine if any page is not fully accessible (ie. not logged in)
	const currentPage = params.pagesData.pageDetails.currentPage;
	const pagesFolios = [];
	let noAccess = "";
	let fullPageAd = false;
	params.pagesData.pages.forEach(page => {
		pagesFolios.push(page.folio);
		noAccess = isTrue(page.userHasAccess, {defaultValue: true}) ? noAccess : "no-access";
		fullPageAd = fullPageAd || page.hasOwnProperty("pageLink");
	});
	const documentTitle = params.props.documentTitle;

	const trackPage = currentViewedFolio !== ID_NOT_SET ? findReplicaPage({replicaList: replicaList, folio: currentViewedFolio}) : undefined;

	// Return values for setting new viewed article and start time
	let trackingEndTime = null;
	let newCurrentFolio = null;

	if (params.props.isMyPane) {
		if (!isEmpty(currentPage)) {
			const pageChanged = currentPage.folio !== params.currentViewedFolio;
			if (pageChanged) {
				trackingEndTime = new Date().getTime();
				newCurrentFolio = currentPage.folio;
				// call tracking since the page has changed
				// note: the tracking function will check to make sure the stored folio is not ID_NOT_SET
				if (typeof trackPage !== "undefined") {
					trackPageView({trackPage: trackPage, 
						trackFolios: pagesFolios,
						documentTitle: documentTitle,
						fullPageAd: fullPageAd,
						deviceSize: getDeviceType(),
						trackingEndTime: trackingEndTime, 
						trackingStartTime: params.trackingStartTime,
						authenticationType: params.props.authenticationType,
						isLoggedIn: params.props.isLoggedIn
					});
				}
			}
		}
		// call tracking if we have changed out of the replica pane but still have the folio stored away
	} else if (typeof trackPage !== "undefined") {
		trackingEndTime = new Date().getTime();
		newCurrentFolio = ID_NOT_SET;
		trackPageView({trackPage: trackPage, 
			trackFolios: pagesFolios, 
			documentTitle: documentTitle,
			trackingEndTime: trackingEndTime, 
			trackingStartTime: params.trackingStartTime,
			authenticationType: params.props.authenticationType,
			isLoggedIn: params.props.isLoggedIn
		});
	}

	return {newCurrentFolio: newCurrentFolio, newStartTime: trackingEndTime};
};

/**
 * Handle keystroke events for previous/next navigation.  As part of the function call,
 * the actual keystroke event will get passed into the function and checked to see
 * if it is applicable to navigation.
 *
 * Once the direction is setup, then call the generic previousNextPageClicked function
 * to handle going to previous/next replica page.
 *
 * @param event keystroke event
 * @param params
 *     replicaList: replicaList used by the module
 *     props: module props
 *     mops: module mops
 */
const handleArrowKeyEvent = (event, params) => {
	params = Object.assign({
		supportedKeyCodes: [37,39],
		replicaList: [],
		props: {},
		mops: {}
	}, params);

	const props = clone(params.props);
	const mops = clone(params.mops);
	const keyCode = event.keyCode;

	// only run if this is my pane and replicaList contains pages and it is a supported keyCode
	if (props.isMyPane && params.replicaList.length > 0 && params.supportedKeyCodes.includes(keyCode)) {
		const folios = getStoreValue({type: GLOBALS, storageKey: "", attributeKey:"globals.currentPageFolios"});
		const previousNextPages = getPreviousNextPages({pagesFolios: folios, replicaList: params.replicaList});

		let keyEventPageIndex;
		let direction;
		if (keyCode === 37) {
			keyEventPageIndex = previousNextPages.previousPageIndex;
			direction = 'Previous Page';
		} else {
			keyEventPageIndex = previousNextPages.nextPageIndex;
			direction = 'Next Page';
		}

		const destinationPage = keyEventPageIndex > -1 && params.replicaList.length > 0 ? params.replicaList[keyEventPageIndex] : {};
		const keyEventFolio = !isEmpty(destinationPage) ? params.replicaList[keyEventPageIndex].folio : '';
		const keyEventPageNumber = !isEmpty(destinationPage) ? params.replicaList[keyEventPageIndex].pageNumber : '';

		const pageClickParams = {
			pageNumber: keyEventPageNumber,
			pageSpread: props.pageSpread,
			folio: keyEventFolio,
			props: props,
			queryParams: mops.queryParams,
			replicaList: params.replicaList,
			eventType: 'key',
			direction: direction,
			documentTitle: props.documentTitle,
			clickAction: gotoPage
		};
		// fire the navigation only if page and keyEventPage are valid
		if (keyEventPageNumber !== ID_NOT_SET && keyEventPageIndex > -1) {
			previousNextPageClicked( {event: params.event, pageClickParams: pageClickParams} );
		}
	}
};

/*
const handleDisableEvent = (event, params) => {
	params = Object.assign({
		supportedKeyCodes: [9],
		replicaList: [],
		props: {},
		mops: {}
	}, params);
	const props = clone(params.props);
	const keyCode = event.keyCode;
	if (props.isMyPane && params.supportedKeyCodes.includes(keyCode)) {
		if (params.supportedKeyCodes.includes(keyCode)) {
			event.stopPropagation();
			event.preventDefault();
		}
	}
};
*/

/**
 * Start of jsx code for module
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ReplicaViewerModule = (props) => {
	// store serialized data to keep the last "valid" data
	const [replicaList, setReplicaList] = useState([]);
	const [pageSpread, setPageSpread] = useState(DEFAULT_REPLICA_PAGE_SPREAD);
	const [zoomLevel, setZoomLevel] = useState(DEFAULT_REPLICA_ZOOM_LEVEL);
	const [fetchInProgress, setFetchInProgress] = useState(false);
	const [lastViewedIssueFolio, setLastViewedIssueFolio] = useState('');

	// keep track of the (last) current page and time it was viewed for tracking only
	const [currentViewedFolio, setCurrentViewedFolio] = useState(ID_NOT_SET);
	const [trackingStartTime, setTrackingStartTime] = useState(new Date().getTime());

	// generate unique identifier; combine folio and issue
	const issueFolio = !isEmpty(props.folio) ? props.issueUrl + "_" + props.folio : props.issueUrl + "_none";


	// runs only once on init
	useMountPostRender(() => {
		// set and update store replica data values on startup; as defined in Configuration
		// capture some values for other attributes use
		const configurationReplicaLayout = getObjectFromJSON(props.replicaLayout, {});
		const initialReplicaLayout = deviceReplicaLayout(configurationReplicaLayout);
		const updateParams = {
			navigationKey: !isEmpty(props.navigationKey) ? props.navigationKey : 'replica',
			navigationKeys: !isEmpty(props.navigationKeys) ? props.navigationKeys : {},
			attributes: initialReplicaLayout
		};
		manageUpdate(updateParams);

		setPageSpread(initialReplicaLayout.pageSpread);
		setZoomLevel(initialReplicaLayout.zoomLevel);
	});


	useEffect(() => {
		if (!isEmpty(props.pageSpread) && pageSpread !== props.pageSpread) {
			setPageSpread(props.pageSpread);
		}
		if (!isEmpty(props.zoomLevel) && zoomLevel !== props.zoomLevel) {
			setZoomLevel(props.zoomLevel);
		}
	}, [props.pageSpread, props.zoomLevel, pageSpread, zoomLevel]);


	/**
	 * Update global value when folio changes.
	 */
	useAttributesChanged(() => {
		const genericUpdateData = getStoreValue({attributeKey: 'genericUpdateData'});
		genericUpdateData({currentFolio: currentViewedFolio},{type: GLOBALS});
	}, [currentViewedFolio]);


	/**
	 * Call to generate module-specific props, but that are generally common to all library
	 * modules.
	 * Generally, returns titleParams, className, storageKey, queryParams
	 *
	 * Note: this should be called very early so that subsequent functions can use these.
	 *
	 * @type {{queryParams: {}, titleParams: {}, className: string, storageKey: string}}
	 */
	const mops = generateMops(props, {
		defaultKey: REPLICA_VIEWER,
		defaultClass: addClass('replica-viewer', [props.className]),
		title: 'replicaViewer.title',
		titleTag: 'h2',
		displayTitle: false,  // for replica pages, we usually don't want to display a title
		configQueryParams: configQueryParams
	});


	/**
	 * If folio is generated from pageNumber or from replicaList, then call to update all navigationKeys for
	 * the replicaList module and storageKey.
	 *
	 * Note: We keep track of "issueFolio", a combination of issueUel and folio as folio value
	 * is not unique across issues.
	 *
	 * TODO: Replace the gotoPage call with the manageUpdate call when setting the url changes
	 */
	useAttributesChanged(() => {
		const replicaList = getReplicaList({storageKey: mops.storageKey, queryParams: mops.queryParams, returnStoredDataOnly: true});
		if (!isEmpty(replicaList) && props.generatedFolio && lastViewedIssueFolio !== issueFolio && props.isMyPane) {
			setLastViewedIssueFolio(issueFolio);
			gotoPage({
				props: props,
				queryParams: mops.queryParams,
				replicaList: replicaList,
				folio: props.folio,
				pageSpread: pageSpread,
				zoomLevel: zoomLevel,
				replaceHistoryState: true
			});
//				const updateParams = {
//					navigationKey: !isEmpty(props.navigationKey) ? props.navigationKey : 'replica',
//					navigationKeys: !isEmpty(props.navigationKeys) ? props.navigationKeys : {},
//					attributes: {folio: props.folio}
//				};
//				manageUpdate(updateParams);
		}
	},[mops.queryParams, issueFolio, props.generatedFolio]);


	// When folio changes, update the share "summary" attribute from the folio value
	useEffect(() => {
		if (!isEmpty(replicaList) && !isEmpty(props.folio)) {
			const genericUpdateData = getStoreValue({attributeKey: 'genericUpdateData'});
			const currentPage = findReplicaPage({replicaList: replicaList, folio: props.folio});
			const pageAttributes = {
				replicaList: replicaList,
				pageSpread: props.pageSpread,
			};

			const pagesData = getReplicaPages({folio: props.folio, pageAttributes: pageAttributes});
			const pagesFolios = [];
			pagesData.pages.forEach(page => {
				pagesFolios.push(page.folio);
			});

			if (!isEmpty(currentPage)) {
				genericUpdateData({
					share: {
						summary: !isEmpty(currentPage.summary) ? currentPage.summary : '',
						folio: pagesFolios,
						issueName: !isEmpty(currentPage.issueName) ? currentPage.issueName : '',
					}
				},{type: GLOBALS, assign: "merge"});
				genericUpdateData({
					currentPageFolios: pagesFolios
				},{type: GLOBALS, assign: "merge"});
			}
		}
	}, [replicaList, issueFolio, props.pageSpread, props.folio]);


	/**
	 * Setup ref to the replica parent element and for image elements for each folio page.
	 * We use these to check for the page/images loaded for when we change pages and
	 * we need to perform some action after the DOM elements have been created.
	 * Once ref is created, create attributes for the different elements based on the DOM
	 * elements being referenced.
	 */
	const DOMElements = {
		module: useRef(null),
		folio1: useRef(null),
		folio2: useRef(null),
	};
	const elementAttributes = {
		module: DOMElements.module.current !== null ? {
			identifier: DOMElements.module.current.getAttribute('data-page-identifier'),
		} : {},
		folio1: DOMElements.folio1.current !== null ? {
			identifier: DOMElements.folio1.current.getAttribute('data-image-folio-identifier'),
			page: DOMElements.folio1.current.getAttribute('data-image-folio'),
			img: DOMElements.folio1.current.querySelector('img'),
		} : {},
		folio2: DOMElements.folio2.current !== null ? {
			identifier: DOMElements.folio2.current.getAttribute('data-image-folio-identifier'),
			page: DOMElements.folio2.current.getAttribute('data-image-folio'),
			img: DOMElements.folio2.current.querySelector('img'),
		} : {},
	};
	

	// Call function to set DOM properties if the DOM element exists and to trigger
	// call if the replica identifier changes and isMyPane to set focus
	useEffect(() => {
		if (props.isMyPane && props.folio !== '' && props.documentTitle !== '' && elementAttributes.module.identifier !== '' && DOMElements.module.current !== null) {
			const currentPage = findReplicaPage({replicaList: replicaList, folio: props.folio});
			const pageTerm = Translate.Text({id: 'replicaViewer.pageTerm'});
			const titleValue = isNaN(parseInt(props.folio,10)) ? props.documentTitle + ' : ' + props.folio : props.documentTitle + ' : ' + pageTerm + ' ' + props.folio;
			triggerOnReplica({
				module: 'replicaViewer',
				storageKey: props.storageKey,
				triggerProperties: {
					action: 'viewed'
				},
				DOMProperties: {
					moduleDOMElement: DOMElements.module.current,
					useModuleTitle: props.useModuleTitle,
					useModuleFocus: props.useModuleFocus,
					titleValue: !isEmpty(currentPage) ? titleValue : ''
				}
			});
		}
	}, [DOMElements.module, elementAttributes.module.identifier, props.documentTitle, issueFolio, props.isMyPane, props.storageKey, props.useModuleFocus, props.useModuleTitle, replicaList, props.folio]);


	const imagesToLoad = [
		{  // folio1 on the left if two-page spread; single image for one-page spread
			imgElement: elementAttributes.folio1.img,
			imgIdentifier: elementAttributes.folio1.identifier,
			additionalTestAttributes: [pageSpread],
			returnAttributes: {DOMElement: DOMElements.folio1, folioAttributes: elementAttributes.folio1}
		},
		{  // folio2 on the right if two-page spread; undefined for one-page spread
			imgElement: elementAttributes.folio2.img,
			imgIdentifier: elementAttributes.folio2.identifier,
			additionalTestAttributes: [pageSpread],
			returnAttributes: {DOMElement: DOMElements.folio2, folioAttributes: elementAttributes.folio2}
		}
	];
	useImageLoadedMultiple((evt, params) => {
		const imgElement = params.imgElement;
		const {DOMElement, folioAttributes} = params.returnAttributes;
		if (isTrue(props.isMyPane, {defaultValue: false}) && !isEmpty(DOMElement)) {
			generatePageLinks({
				props: props,
				replicaList: replicaList,
				figureElement: DOMElement.current,
				folio: folioAttributes.page,
				replicaElement: DOMElements.module.current,
				imgElement: imgElement,
				pageSpread: pageSpread,
				gotoInternalPage: gotoInternalPage
			});
		}
	}, imagesToLoad);


	/**
	 * Called to manage whether or not the module fetches data.  If the hook calls the
	 * callback, then call getReplicaList which will determine whether or not to
	 * fetch a new list or get an existing list from stored data.
	 *
	 */
	useFetchAttributesChange(() => {
		setFetchInProgress(true);
		getReplicaList({storageKey: mops.storageKey, queryParams: mops.queryParams});
	}, {type: REPLICA_LIST, props: props, mops: mops, queryParams: mops.queryParams});

	/**
	 * Call hook to check to see if the fetchInProgress has been set/changed in the reducer.
	 * fetchInProgress is set true when fetch is started (DATA_REQUESTED)
	 * and set to false when fetch is done (DATA_LOADED)
	 */
	useFetchComplete((isInProgress) => {
		isInProgress = isTrue(isInProgress, {defaultValue: false});
		setFetchInProgress((isInProgress));
		if (!isInProgress) {
			const storedListData = getReplicaList({storageKey: mops.storageKey, queryParams: mops.queryParams, returnStoredDataOnly: true});
			setReplicaList(storedListData);
		}
	}, {requestInProgress: props.fetchInProgress, fetchInProgress: fetchInProgress});

	useAttributesChanged(() => {
		if (props.forceStoreUpdate) {
			const storedListData = getReplicaList({storageKey: mops.storageKey, queryParams: mops.queryParams, returnStoredDataOnly: true});
			manageUpdate({
				navigationKey: props.fetchNavigationKey,
				navigationKeys: props.navigationKeys,
				attributes: {
					queryParams: mops.queryParams,
					replicaList: storedListData,
					folio: props.folio,
				}
			});
		}
	}, [issueFolio, props.forceStoreUpdate]);

	/**
	 * Setup hook to register and callback if the replicaViewer goes out of view.
	 * Note: we don't currently use the triggerStatus return
	 *
	 * Callback passes three pieces of data to the callback function
	 *     time: end time for tracking
	 *     message: tracking message
	 *     status: status of check
	 *
	 * @type {string}
	 */
	const triggerStatus = useVisibilityChange((params) => {
		params = Object.assign({
			time: 0,
			message: '',
			status: '',
			props: {}
		}, params);
		if (!isEmpty(currentViewedFolio)) {
			const trackPage = currentViewedFolio !== ID_NOT_SET ? findReplicaPage({replicaList: replicaList, folio: currentViewedFolio}) : undefined;
			const pageAttributes = {
				replicaList: replicaList,
				pageSpread: params.props.pageSpread,
			};

			const pagesData = getReplicaPages({folio: currentViewedFolio, pageAttributes: pageAttributes});
			const pagesFolios = [];
			pagesData.pages.forEach(page => {
				pagesFolios.push(page.folio);
			});
			trackPageView({trackPage: trackPage, 
				trackFolios: pagesFolios, 
				documentTitle: params.props.documentTitle,
				trackingEndTime: params.time, 
				trackingEndType: params.message, 
				trackingStartTime: trackingStartTime,
				authenticationType: params.props.authenticationType,
				isLoggedIn: params.props.isLoggedIn
			});
			setTrackingStartTime(params.time);
		}
	}, setTrackingStartTime, props);


	/**
	 * Setup hook that handles keystroke events.  This hook just keeps track of key detection/handling
	 * attributes and calls back to enable/disable keystroke detection and to set the function that
	 * handles what happens on a key stroke.
	 *
	 * keyEventParams
	 *     enableDisableHandler: function to enable/disable keystroke detection; usually use default
	 *     keyEventHandler: function to handle keystroke; must define in module
	 *     enableEventParams: object of key detection attributes
	 *         keyEvent: key event to listen for
	 *         supportedKeyCodes: which key codes to listen for
	 *         nameSpace: for event enable/disable
	 *         eventElementId: element to which to attach the listener; should be the module's pane
	 *         enable: true: enable listener; false: disable listener
	 *     handleEventParams: object of attributes used by the event handler
	 *         replicaList: pass current list to handler
	 *         props: mobule props
	 *         mops: module mops
	 */
	const keyEventParams = {
		enableDisableHandler: enableDisableKeyEvent,
		keyEventHandler: handleArrowKeyEvent,
		enableEventParams: {
			supportedKeyEvents: ['keyup','keydown','keypress'],
			keyEvent: 'keydown',
			nameSpace: 'keydown.' + props.storageKey,
			eventElementId: props.myPane,
			enable: props.isMyPane
		},
		handleEventParams: {
			supportedKeyCodes: [37,39],
			replicaList: replicaList,
			props: props,
			mops: mops,
		}
	};
	useKeyEvents((enableDisableHandler, params) => {
		enableDisableHandler(params);
	}, keyEventParams);


	/*
	 * Generate jsx for replica image and wrappers if displayOnDevice
	 * Call GeneratePage function to generate html
	 */
	if (displayOnDevice(props)) {

		const pageAttributes = {
			replicaList: replicaList,
			DOMElements: DOMElements,
			pageSpread: pageSpread,
			zoomLevel: zoomLevel
		};

		const pagesData = getReplicaPages({folio: props.folio, className: mops.className, pageAttributes: pageAttributes});

		const newTrackingAttr = pageViewed({props: props, pagesData: pagesData, currentViewedFolio: currentViewedFolio, replicaList: replicaList, trackingStartTime: trackingStartTime});
		// reset stored articleId and start time
		if (newTrackingAttr.newStartTime !== null) {
			setCurrentViewedFolio(newTrackingAttr.newCurrentFolio);
			setTrackingStartTime(newTrackingAttr.newStartTime);
		}

		// note: this uses the state value for replicaList rather than the one set in props
		return (
			fetchInProgress ?
				props.displayInProgressPage ? <GenerateInProgressPage displaySpinner={true} /> : ''
				:
				<GeneratePage props={props} mops={mops} pageAttributes={pageAttributes} />
		);
	} else {
		return null;
	}
};


/**
 * Map state (store) data for the replicaViewer module; added to module props.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{key: Value}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : REPLICA_VIEWER;
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};
	const globalState = !isEmpty(state.globals) ? state.globals : {};

	// note: pageNumbers for replica pages start at 1
	let pageNumber = !isEmpty(storeState.pageNumber) ? parseInt(storeState.pageNumber, 10) : 1;
	pageNumber = !isNaN(pageNumber, 10) ? pageNumber : 1;

	let replicaProps = {
		storageKey: storageKey,
		documentId: !isEmpty(storeState.documentId) ? storeState.documentId : ID_NOT_SET,
		documentUrl: !isEmpty(storeState.documentUrl) ? storeState.documentUrl : '',
		documentTitle: !isEmpty(storeState.documentTitle) ? storeState.documentTitle : '',
		initialPage: !isEmpty(storeState.initialPage) ? storeState.initialPage : 1,
		pageNumber: pageNumber,
		folio: !isEmpty(storeState.folio) ? storeState.folio : '',
		docType: !isEmpty(storeState.docType) ? storeState.docType : 'Default',
		fetchInProgress: isTrue(storeState.fetchInProgress, {defaultValue: false}),
		displayInProgressPage: isTrue(props.displayInProgressPage, {defaultValue: true}),
		pageSpread: !isEmpty(storeState.pageSpread) ? getIntValue(storeState.pageSpread) : '',
		zoomLevel: !isEmpty(storeState.zoomLevel) ? getFloatValue(storeState.zoomLevel) : '',
		generatedFolio: false,  // keep track of whether or not we generate folio later
		isLoggedIn: isTrue(globalState.isLoggedIn, {defaultValue: false}),
		authenticationType: state.authenticationType ? state.authenticationType : '',
		// if true, force manageUpdate after render to update related modules
		forceStoreUpdate: isTrue(storeState.forceStoreUpdate, {defaultValue: false}),
	};

	// add module properties that are common to data modules
	replicaProps = dataModuleAttributes({
		moduleProps: replicaProps,
		originalProps: props,
		state: state,
		storageKey: storageKey,
		navigationAttributeType: 'replica'
	});

	// call to get the last list that matches query params (returns empty list if none)
	const queryParams = generateQueryParams({configQueryParams: configQueryParams, moduleProps: replicaProps});
	replicaProps.replicaList = getReplicaList({storageKey: replicaProps.storageKey, queryParams: queryParams, returnStoredDataOnly: true});

	// if folio is empty, see if there is a replica page matching "pageNumber" value
	// if replicaPage is found, use it to find folio, else set folio to first page replicaList is set
	if (isEmpty(replicaProps.folio) && Array.isArray(replicaProps.replicaList) && replicaProps.replicaList.length > 0) {
		const replicaPage = findReplicaPage({replicaList: replicaProps.replicaList, pageNumber: replicaProps.pageNumber});
		replicaProps.folio = isEmpty(replicaPage) ? replicaProps.replicaList[0].folio : replicaPage.folio;
		replicaProps.generatedFolio = true;
	}

	return replicaProps;
};

/**
 * Actions that can be called by this module.  Each action is added to props so
 * that it can be called in the module, but defined in a single place with
 * appropriate parameters for the action call by this module.
 *
 * @param dispatch
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		fetchData: (params) => {
			params.type = params.hasOwnProperty('type') ? params.type : REPLICA_LIST;
			dispatch(fetchData(params));
		},
		updateData: (payload, params) => {
			params.type = params.hasOwnProperty('type') ? params.type : REPLICA_VIEWER;
			dispatch(updateData(payload, params));
		}
	};
}


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReplicaViewerModule);
