import React from "react";
import {GenerateHtmlMarkup} from "../../../widgets/generateHtmlMarkup";
import getArticleAttribute from "../../../modules/library/articleViewer/manage_attributes/getArticleAttribute";
import getSpecialAttribute from "../../../modules/library/articleViewer/manage_attributes/getSpecialAttribute";
import {getExtractedElements} from "../../../modules/library/articleViewer/content_updaters/findElement";
import {updateArticleImages} from "../../../modules/library/articleViewer/content_updaters/updateArticleImages";
import {clone} from "../../../utils/objectUtils";

/**
 * Attributes to extract from the article content and moved to a new position.
 * created as array, as order matters
 *
 * @type {({name: string, id: string, duplicate: boolean, element: string}|{prefix: {i18n: string}, name: string, class: string, element: string}|{name: string, element: string}|{name: string, element: string})[]}
 */
const elementsAttributes = [
	{name: 'roofHeader', element: ".roofHeader", id: "roof-header-first", duplicate: true},
	{name: 'roofHeaderScreenReader', element: ".roofHeader", class: "screen-reader", prefix: {i18n: "article.roofHeader.prefix"}},
	{name: 'subtitle', element: ".subtitle"},
	{name: 'byline', element: ".byline"},
	{name: 'htmlAd', element: ".html-ad", count: 1},
	{name: 'sectionHeader', element: ".sectionHeader"}
];

/**
 * React code to generate html for "default" template
 *
 * @param params
 *     article article object
 *
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateFullWidthHtmlAdArticle = (params) => {
	params = Object.assign({
		article: {},
		template: 'fullWidthHtmlAd',
		moduleProps: {}
	}, params);
	// make a clone of article so we don't accidentally change anything for the caller
	const article = clone(params.article);
	const title = getArticleAttribute({article: article, attribute: "title"}).value;
	const categories = getSpecialAttribute({article: article, attribute: "categories"}).value;
	const className = 'full-width-html-ad scrollContainer ' + categories;
	article.content = updateArticleImages({articleMarkup: article.content, template: params.template});

	// gets object of extracted elements and modified article content
	const extractedElements = getExtractedElements({article: article, elementsAttributes: elementsAttributes});

	return (
		<div data-templatename="Full Width Html Ad" className={className}>
			<div className="contentContainer gtxcelDefaultTemplate">
				<div className="full-width-ad">
					<GenerateHtmlMarkup htmlMarkup={extractedElements.elements.htmlAd} />
				</div>
				<GenerateHtmlMarkup htmlMarkup={extractedElements.elements.sectionHeader} />
				<div className="articleBodyContainer">
					<header className="header">
						<h1 className="mainTitle screen-reader" tabIndex="-1">
							<GenerateHtmlMarkup htmlMarkup={title} />
							<GenerateHtmlMarkup htmlMarkup={extractedElements.elements.roofHeaderScreenReader} />
						</h1>
						<GenerateHtmlMarkup htmlMarkup={extractedElements.elements.subtitle} />
						<GenerateHtmlMarkup htmlMarkup={extractedElements.elements.byline} />
					</header>
					<div className=" articleBody">
						<GenerateHtmlMarkup tag={'article'} htmlMarkup={extractedElements.articleMarkup} />
					</div>
				</div>
			</div>
		</div>
	);

};
export default GenerateFullWidthHtmlAdArticle;
