import React from "react";
import {generateReplicaListPaginationIndex} from "./replicaActions";
import {addClass} from "../generateClassName";
import {clone} from "../objectUtils";
import {GenerateReplicaPage} from "../../modules/library/replicaList/generateReplicaPage";
import {GenerateReplicaIssueCover, removeCoverPage} from "../../widgets/generateSummary";
import GenerateTitle from "../../widgets/generateTitle";
import GeneratePages from "../../widgets/generatePages";


/*
* Generate jsx for replica list
* Call to GeneratePages since, as a list,
* it could require multiple pages and pagination block.
 *
 * @param params
 * @constructor
 */
const GenerateThumbnailReplicaList = (params) => {
	params = Object.assign({
		moduleProps: {},
		mops: {},
		replicaList: {},
		pageSpread: 1
	}, params);
	const {mops, replicaList, pageSpread} = params;
	const props = clone(params.moduleProps);

	const replicaPage = generateReplicaListPaginationIndex({
		replicaList: replicaList,
		folio: props.folio,
		entriesPerPage: props.pageGridAttributes.entriesPerPage
	});

	// generate full className for module; default is to display active indicator
	let moduleClassName = replicaList.length > 0 ? mops.className : addClass(mops.className, 'empty');
	moduleClassName = (props.navigationAttributes.fromModule === props.moduleId && props.navigationAttributes.fromInstance === props.instanceId) ? addClass(moduleClassName, 'called-from-self') : moduleClassName;
	moduleClassName = props.displayActiveIndicator ? addClass(moduleClassName, 'display-active') : moduleClassName;

	const dataAttributes = {
		'data-columns': props.pageGridAttributes.columns,
		'data-called-from-module': props.navigationAttributes.hasOwnProperty('fromModule') ? props.navigationAttributes.fromModule : null,
		'data-called-from-instance': props.navigationAttributes.hasOwnProperty('fromInstance') ? props.navigationAttributes.fromInstance : null,
		'data-storagekey': props.storageKey,
		'data-issue-group': props.issueGroup,
	};

	// callback for single page generation
	const GenerateReplicaListPage = (pageParams) => GenerateReplicaPage(pageParams);
	// we need the cover for display at top, but want to remove it for the list
	const displayList = removeCoverPage({replicaList: replicaList});

	const replicaAttributes = {
		props: props,
		mops: mops,
		fullList: replicaList,
		updateHistoryState: props.hasOwnProperty('navigationKey'),
		moduleSpecific: {
			pageSpread: pageSpread
		},
	};
	return (
		<div className={'replicaListContainer'} tabIndex={'-1'} {...dataAttributes}>
			<GenerateReplicaIssueCover {...replicaAttributes} />
			<div className={moduleClassName}>
				<GenerateTitle titleParams={mops.titleParams}/>
				<GeneratePages {...replicaAttributes} entries={displayList} className={'replica-pages'}
							   cssClass={mops.cssClass} entryPage={replicaPage}
							   GeneratePage={GenerateReplicaListPage}/>
			</div>
		</div>
	);
};
export {GenerateThumbnailReplicaList};
