import React from "react";
import {stripTags, sanitizeInline} from "../../utils/htmlUtils";
import {populateArticleData} from "../generateSummary";
import {truncateString} from "../../utils/stringUtils";
import GenerateTitle from "../generateTitle";
import GenerateImage from "../generateImage";
import {GenerateHtmlMarkup} from "../generateHtmlMarkup";
import {getVisibleCategory} from "./cardUtils";
import {ExternalUrl} from "../../environment";


/**
 * Generate html for single article with image
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GenerateCardSingleArticle = (params) => {
	const entry = params.entry ? params.entry : {};
	const actions = params.actions ? params.actions : {};
	const displayParams = params.displayParams ? params.displayParams : {};

	// set article properties
	const articleData = populateArticleData(entry, actions);

	const title = stripTags(articleData.title);
	let summary = '';

	if (entry.summary !== '') {
		summary = typeof entry.summary === 'string' && entry.summary !== '' ? truncateString(sanitizeInline(entry.summary), displayParams.maxSummaryCharacters, displayParams.useSummaryEllipsis) : '';
	}

	const titleParams = {
		tag: 'h3',
		className: 'title',
		displayTitle: true,
	};

	const titleParamsCategory = {
		tag: 'h4',
		className: 'title-category',
		displayTitle: true,
	};

	const singleImageSrc = entry.bigImage !== '' ? entry.bigImage : ExternalUrl.PLACEHOLDER.LANDSCAPE_THUMBNAIL_SMALL;
	// fallback to article title for ALT tag if not available
	const singleImageAlt = typeof entry.bigImageAlt === 'string' && entry.bigImageAlt !== '' ? entry.bigImageAlt : title;

	// check and set image properties
	const imageProperties = {
		alt: singleImageAlt,
		src: singleImageSrc,
		link: false,
	};

	// determine which category to display; find the first one that is not hidden or use empty string
	const category = getVisibleCategory(displayParams.hideCategories, entry.categories);

	return (
		<div className={'single-article-container'}>
			<a href={articleData.url} className={'item-link'} data-articletitle={articleData.title} data-category={category} data-articleid={entry.id} data-issueid={entry.issueId} onClick={articleData.clickAction}>
				<div className={'single-article-image'}>
					<GenerateImage imageProperties={imageProperties} />
				</div>
				<div className={'single-article-title'}>
					<div>
						<GenerateTitle titleParams={titleParamsCategory}>{category}</GenerateTitle>
						<GenerateTitle titleParams={titleParams}>{title}</GenerateTitle>
						<GenerateHtmlMarkup tag={'p'} htmlMarkup={summary} />
					</div>
				</div>
			</a>
		</div>
	);
};
export default GenerateCardSingleArticle;
