import React from 'react';
import GoogleAdModule from "../../modules/common/googleAd/index";
import StaticAdModule from "../../modules/common/staticAd/index";
import {getStoreValue} from "../../utils/storeValue";
import GenerateImage from "../../widgets/generateImage";
import * as Tracking from "../../utils/tracking";

/**
 * Generate html for toc ad
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GenerateCardTocAd = (params) => {
	params = Object.assign({
		entry: {},
		actions: {},
		displayParams: {}
	}, params);

	const metadata = JSON.parse(params.entry.content);
	const currentStructure = getStoreValue({attributeKey: 'currentStructure'});
	
	if (metadata.adType === "static") {
		const imageProperties = {
			alt: '',
			src: params.entry.image ? params.entry.image :'',
			link: metadata.link ? metadata.link :'',
			target: '_blank',
			styles: {
				height: 50,
				width: 320
			}
		};

		const trackingProperties = {
			"module": "article list card",
			"ad name": metadata.tracking ? metadata.tracking :'tocAd',
			"url": metadata.link ? metadata.link :'',
			"location": "toc",
			"height":50,
			"width": 320
		};

		params.actions.callOnUIChange(Tracking.libraryTrack, ["advertisement viewed", trackingProperties]);

		const adClicked = (event) => {
			Tracking.libraryTrack("advertisement clicked", trackingProperties);
		};

		return (
			<div className={'toc-ad'}>
				<div className={'banner-image'}>
					<GenerateImage imageProperties={imageProperties} onClick={(evt) => adClicked(evt)}/>
				</div>
			</div>
		);
	} else {
		const properties = {
			instanceId: "tocAd" + params.entry.sortOrder,
			structureId: currentStructure,
			properties: {
				networkCode: metadata.networkCode ? metadata.networkCode : '',
				adUnit: metadata.adUnit ? metadata.adUnit : '',
				sizes: {
					"desktop": {
						"ads": [[320,50]]
					},
					"tablet": {
						"ads": [[320, 50]]
					},
					"phone": {
						"ads": [[320,50]]
					}
				}
			}
		};
		return (
			<GoogleAdModule {...properties} />
		);
	}

};
export default GenerateCardTocAd;
