import React, {useState, useEffect} from 'react';
import {DEFAULT_REPLICA_PAGE_SPREAD} from "../../_MODULE_GLOBALS/constants";
import {addClass} from "../../../utils/generateClassName";
import {getIntValue, isEmpty} from "../../../utils/generalUtils";
import {manageUpdate} from "../../globals/globalNavigator";
import {Translate} from "../../../locales/locales";
import {getReplicaPages} from "../../../utils/replicaList/replicaActions";
import * as Tracking from "../../../utils/tracking";



/**
 * Handle click from page spread buttons; either 1-page (spreadSize: 1)
 * or 2-page (spreadSize: 2) button.
 *
 * Call to updateData for all configured navigationKeys modules.
 *
 * @param params
 *     evt: click event
 *     pageSpreadValue: new page spread size value
 *     spreadSize: existing page spread size
 *     moduleProps: props for module
 */
const updatePageSpread = (params) => {
	params = Object.assign({
		evt: null,
		pageSpreadValue: null,
		spreadSize: 1,
		moduleProps: {}
	}, params);

	const moduleProps = !isEmpty(params.moduleProps) ? params.moduleProps : {};
	const storeState = !isEmpty(moduleProps.storeState) ? moduleProps.storeState : {};
	const updateParams = {
		navigationKey: !isEmpty(moduleProps.navigationKey) ? moduleProps.navigationKey : 'replica',
		navigationKeys: !isEmpty(moduleProps.navigationKeys) ? moduleProps.navigationKeys : {},
		attributes: {
			pageSpread: getIntValue(params.pageSpreadValue, params.spreadSize)
		},
	};

	const trackingProperties = {
		folio: storeState.hasOwnProperty('folio') ? [ storeState.folio ] : [],
		label: params.pageSpreadValue === 1 ? "One Page Spread" : "Two Page Spread",
		location: "replica viewer",
		module: "page spread",
		type: "click",
		"url": (window ? window.location.href : ''),
	};
	Tracking.libraryTrack("button clicked",trackingProperties);
	manageUpdate(updateParams);
};


/**
 * Start of jsx code for module
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PageSpread = (props) => {
	const moduleProps = Object.assign({
		GeneratedTag: `button`,
		className: '',
		title: '',
		label: '',
		navigationKey: '',
		navigationKeys: {},
		storeState: {},
		storageKey: 'replicaViewer'
	}, props);

	// keep track of page spread value (1 [default] or 2)
	const [spreadSize, setSpreadSize] = useState(DEFAULT_REPLICA_PAGE_SPREAD);

	const className = addClass('replica-viewer-control page-spread', moduleProps.className);
	const pageSpread = !isEmpty(moduleProps.storeState.pageSpread) ? getIntValue(moduleProps.storeState.pageSpread) : spreadSize;

	/*
	 * If pageSpread size changes, update the state value.
	 * This can happen through click on the button or from initialization
	 * data update from replicaViewer
	 */
	useEffect(() => {
		if (pageSpread !== spreadSize) {
			setSpreadSize(pageSpread);
		}
	}, [pageSpread, spreadSize]);


	const buttonData = {
		onePage: {
			className: spreadSize === 1 ? 'select-one-page-spread selected' : 'select-one-page-spread',
			title: Translate.Text({'id': 'actionButton.pageSpread.onePage.title'})
		},
		twoPage: {
			className: spreadSize === 2 ? 'select-two-page-spread selected' : 'select-two-page-spread',
			title: Translate.Text({'id': 'actionButton.pageSpread.twoPage.title'})
		}
	};

	return (
		<div className={className}>
			<button
				className={buttonData.onePage.className}
				title={buttonData.onePage.title} aria-label={buttonData.onePage}
				onClick={(evt) => {
					if (spreadSize !== 1) {
						setSpreadSize(1);
						updatePageSpread({evt: evt, pageSpreadValue: 1, spreadSize: spreadSize, moduleProps: moduleProps});
					}
				}}
			>
				<span className={'fal fa-file'} />
			</button>
			<button
				className={buttonData.twoPage.className}
				title={buttonData.twoPage.title} aria-label={buttonData.twoPage}
				onClick={(evt) => {
					if (spreadSize !== 2) {
						setSpreadSize(2);
						updatePageSpread({evt: evt, pageSpreadValue: 2, spreadSize: spreadSize, moduleProps: moduleProps});
					}
				}}
			>
				<span className={'fal fa-file left'} /> <span className={'fal fa-file right'} />
			</button>
		</div>
	);
};
export {PageSpread};
