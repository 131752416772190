import * as Tracking from "./tracking";
import {isEmpty} from "./generalUtils";
import {clone} from './objectUtils';
import {findArticle} from "./articleList/articleActions";
import {findReplicaPage, findReplicaPageIndex, getReplicaPages} from "./replicaList/replicaActions";


/**
 * Return the previous and next article ids in an article list
 *
 * @param id id of article from list
 * @param list list of articles
 * @returns {{previousNextIds: object}}
 */
const getPreviousNextIds = (id, list) => {
	list = typeof list !== 'undefined' ? list : [];
	const i = list.findIndex(x => x.id === parseInt(id, 10));
	return {
		previousId: typeof list[i-1] !== 'undefined' ? list[i-1].id : -1,
		nextId: typeof list[i+1] !== 'undefined' ? list[i+1].id : -1
	};
};
export {getPreviousNextIds};

/**
 * Return the previous and next page indexes in a replica list from a list of
 * replica pages representing the page spread.
 *
 * Return -1 for page index if the replica page is not found.
 *
 * @param params
 *     pageFolios: array of folios from page spread
 *     replicaList: list of replica pages
 * @returns {{nextPageIndex: (number|*), previousPageIndex: (number|number)}}
 */
const getPreviousNextPages = (params) => {
	params = Object.assign({
		pagesFolios: [],
		replicaList:[]
	}, params);
	const {pagesFolios, replicaList} = params;
	if (isEmpty(pagesFolios) || isEmpty(replicaList)) {
		return {
			previousPageIndex: -1,
			nextPageIndex: -1
		};
	} else {
		const firstPage = pagesFolios[0];
		const lastPage = pagesFolios[pagesFolios.length - 1];
		const firstPageIndex = findReplicaPageIndex({replicaList: replicaList, folio: firstPage});
		const lastPageIndex = findReplicaPageIndex({replicaList: replicaList, folio: lastPage});

		return {
			previousPageIndex: isEmpty(replicaList[firstPageIndex - 1]) ? -1 : firstPageIndex - 1,
			nextPageIndex: isEmpty(replicaList[lastPageIndex + 1]) ? -1 : lastPageIndex + 1
		};
	}
};
export {getPreviousNextPages};

/**
 * Handle previous/next button clicked event.
 * Pass the current navigationKey ("fetchNavigationKey") along to manageUpdateAndNavigation
 * to keep the same fetch type as we are currently using.
 *
 * @param params parameters passed in from click event
 */
const previousNextClicked = (params) => {
	params = Object.assign({
		event: null,
		articleClickParams: {}
	}, params);
	const articleClickParams = clone(params.articleClickParams);
	const articleList = Array.isArray(articleClickParams.articleList) ? articleClickParams.articleList : [];
	articleClickParams.section = '';

	// attributes for tracking
	const eventType = articleClickParams.eventType;
	const direction = articleClickParams.direction;
	// get the current article for tracking the title
	const currentArticle = findArticle({articleList: articleList, articleId: articleClickParams.props.articleId});
	// get the destination article and parse any dynamic fetch attributes
	const destinationArticle = findArticle({articleList: articleList, articleId: articleClickParams.articleId});
	const trackingProperties = {
		"label": direction,
		"type": eventType,
		"module": "article viewer",
		"article title": currentArticle.title,
		"destination article title": destinationArticle.title,
		"destination type": "article",
		"location": "modular library"
	};
	Tracking.libraryTrack("button clicked", trackingProperties);

	// call the clickAction passed by params
	articleClickParams.clickAction(articleClickParams);
};
export {previousNextClicked};

/**
 * Handle previous/next button clicked event for replicaViewer.
 * Pass the current navigationKey ("fetchNavigationKey") along to manageUpdateAndNavigation
 * to keep the same fetch type as we are currently using.
 *
 * @param params parameters passed in from click event
 */
const previousNextPageClicked = (params) => {
	params = Object.assign({
		event: null,
		pageClickParams: {}
	}, params);
	const pageClickParams = clone(params.pageClickParams);
	const replicaList = pageClickParams.replicaList;
	const pageSpread = pageClickParams.pageSpread;
	const currentFolio = pageClickParams.props.folio;
	const folio = pageClickParams.folio;

	const pageAttributes = {
		replicaList: replicaList,
		pageSpread: pageSpread,
	};

	const pagesData = getReplicaPages({folio: folio, pageAttributes: pageAttributes});
	const currentPagesData = getReplicaPages({folio: currentFolio, pageAttributes: pageAttributes});
	const pagesFolios = [];
	pagesData.pages.forEach(page => {
		pagesFolios.push(page.folio);
	});
	const currentFolios = [];
	currentPagesData.pages.forEach(page => {
		currentFolios.push(page.folio);
	});

	// attributes for tracking
	const eventType = pageClickParams.eventType;
	const direction = pageClickParams.direction;
	// get the current page for tracking the page number
	// const currentPage = findReplicaPage({replicaList: replicaList, folio: currentFolio});
	// get the destination page and parse any dynamic fetch attributes
	// const destinationPage = findReplicaPage({replicaList: replicaList, folio: folio});
	const trackingProperties = {
		"destination folio": pagesFolios,
		"destination type": "replica",
		"document title": (document ? document.title : ''),
		"folio": currentFolios,
		"label": direction,
		"location": "replica page",
		"module": "replica viewer",
		"type": eventType,
	};
	Tracking.libraryTrack("button clicked", trackingProperties);

	// call the clickAction passed by params
	pageClickParams.clickAction(pageClickParams);
};
export {previousNextPageClicked};
