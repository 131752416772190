import React from 'react';
import {connect} from 'react-redux';
import {SEARCH_FILTERS, SEARCH_PROGRESS, SEARCH_RESULTS} from "../../../_MODULE_GLOBALS/constants";
import {updateData} from '../../../../store/actions';
import {Translate} from "../../../../locales/locales";
import GenerateTitle from "../../../../widgets/generateTitle";
import {displayOnDevice, generateMops, getActiveAttributeState} from "../../../../utils/moduleSetup";
import {useMountPostRender} from "../../../../hooks/useMount";
import {defineSelectionFilter, updateFilterOnChange} from "../searchUtils/filterUpdate";
import {isEmpty, isTrue} from "../../../../utils/generalUtils";


const dropdownValues = {
	'article': {'value':'article', 'display': 'search.target.article'},
	'replica': {'value': 'replica', 'display': 'search.target.replica'}
};
export {dropdownValues};  // use: import {dropdownValues as searchTargetValues} from "../filters/searchTarget";

const defaultDropdownOrder = {
	default: 'article',
	order: ['article', 'replica']
};

export const SearchTargetModule = (props) => {
	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: SEARCH_RESULTS,
		defaultClass: 'search-target',
		title: 'search.target.title',
		titleTag: 'span',
		configQueryParams: {}
	});


	// runs only once on init
	useMountPostRender(() => {
		// update filter value on startup
		props.updateData({searchArticleSearchFilter: props.isArticleSearch}, {storageKey: props.storageKey});
	});


	/*
	* Generate jsx for target dropdown if displayOnDevice
	*
	*/
	if (displayOnDevice(props)) {
		return (
			<div className={mops.className}>
				<GenerateTitle titleParams={mops.titleParams} />
				<select
					aria-label={Translate.Text({id: 'search.target.ariaLabel'})} defaultValue={props.defaultValue}
					onChange={(evt) => {
						const searchValue = evt.currentTarget.value === 'article';
						updateFilterOnChange({props: props, searchValue: searchValue, searchReducer: 'searchArticleSearchFilter'});
					}}>
					{
						props.dropdownOrder.map((selection) => {
							const dropdownValue = dropdownValues[selection].value;
							return (
								<option key={dropdownValue} value={dropdownValue}>
									{Translate.Text({id: dropdownValues[selection].display})}
								</option>
							);
						})
					}
				</select>
			</div>
		);
	} else {
		return null;
	}
};


/**
 * Map the store object you are using.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{dropdownOrder, defaultValue: (*|string), active: (*|string), storageKey: (*|string), isArticleSearch: (boolean|string|*|boolean)}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = props.storageKey ? props.storageKey : SEARCH_RESULTS;
	const storageState = state.hasOwnProperty(storageKey) ? state[storageKey] : {};
	// filters set through reducer; module filter is null if not explicitly set
	const searchFilters = storageState.hasOwnProperty('searchFilters') ? storageState.searchFilters : {};
	const searchFiltersValue = !isEmpty(searchFilters.articleSearch) ? searchFilters.articleSearch : '';

	// set default values from configuration
	const defaultFilterValues = defineSelectionFilter({
		props: props,
		searchFiltersValue: searchFiltersValue,
		defaultDropdownOrder: defaultDropdownOrder,
		dropdownValues: dropdownValues,
		forceFetch: isTrue(storageState.forceFetch)
	});
	const dropdownOrder = defaultFilterValues.order;
	const defaultValue = defaultFilterValues.defaultValue;
	const isArticleSearch = defaultFilterValues.filterValue === 'article' || isTrue(defaultFilterValues.filterValue);

	return {
		storageKey: storageKey,
		active: getActiveAttributeState(props),
		searchFilters: searchFilters,
		dropdownOrder: dropdownOrder,
		defaultValue: defaultValue,
		isArticleSearch: isArticleSearch,
	};
};

/**
 * Actions that can be called by this module.
 *
 * actions:
 *     updateData call to update the store with new properties
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		updateData: (payload, params) => {
			params.type = SEARCH_FILTERS;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchTargetModule);
