import React from "react";
import {Translate} from "../../../../locales/locales";
import getDeviceType from "../../../../utils/getDeviceType";
import {navigateToTarget} from "../../../globals/globalNavigator";
import {getObjectFromJSON} from "../../../../utils/objectUtils";
import * as Tracking from "../../../../utils/tracking";
import {isEmpty, isTrue} from "../../../../utils/generalUtils";
import {getStoreValue} from "../../../../utils/storeValue";
import {addClass} from "../../../../utils/generateClassName";



/**
 * Navigate to the navigation key specified for 'navigation' for the home button.
 * The navigationButton specification only operates for a single destination, which
 * must be specified as 'navigation' in the configuration for navigationKeys.
 *
 * Note: When calling from a library module, attributes may come from the module rather
 * than the configuration, but, since this is specifically for the home button, specifications
 * should be specific for navigating to home.
 *
 * Note: The goHome function can be called from the home button, or through a history
 * action that doesn't have any other defined target.  It is assumed that if no specified
 * url target exists in history (ie. only ../library), that the target is the home pane.
 *
 * Note: It is possible to specify "issue" as the target of the Home operation, but the
 * value of issueUrl is not taken from a dynamic state value, but only from the
 * startup value.  Given that, it is only useful to specify this for a single document
 * collection.
 *
 * @param params passing in props from staticButton or direct call
 */
// default navigationKeys definition for "home" if not passed in params
// copied from Navigation Targets for "home" target
const defaultHomeNavigationKeys = {
	"home": {
		"destination": {
			"default": "internal"
		},
		"modules": [
			{
				"module": "libraryTarget",
				"storageKey": "homeTarget",
				"reducerAction": "LIBRARY_TARGET"
			}
		],
		"navigationTarget": "home"
	},
	"empty": {
		"destination": {
			"default": "internal"
		},
		"modules": [
			{
				"module": "libraryTarget",
				"storageKey": "emptyTarget",
				"reducerAction": "LIBRARY_TARGET"
			}
		],
		"navigationTarget": "empty"
	}
};
const goHome = (params) => {
	params = Object.assign({
		targetKey: 'home',
		navigationKeys: null,
		fromHistory: false,
		replaceHistoryState: false
	}, params);
	const navKey = typeof params.targetKey === 'string' ? params.targetKey : 'home';
	const navigationKeys = params.navigationKeys === null ? defaultHomeNavigationKeys : getObjectFromJSON(params.navigationKeys, {});
	const navigationKey = navigationKeys[navKey] ? navigationKeys[navKey] : {};
	const navigationTarget = navigationKey.hasOwnProperty('navigationTarget') ? navigationKey.navigationTarget : '';
	const fromHistory = isTrue(params.fromHistory);

	const attributes = navigationKey.attributes ? navigationKey.attributes : {};
	attributes.navigateToHome = true;
	attributes.fromHistory = fromHistory;
	attributes.replaceHistoryState = isTrue(params.replaceHistoryState);

	if (!fromHistory) {
		const trackingProperties = {
			"type": "home"
		};
		Tracking.libraryTrack("button clicked", trackingProperties);
	}

	const replacementValues = {};
	const startupNavigationTarget = getStoreValue({attributeKey: 'urlNavigationTarget'});
	if (startupNavigationTarget.hasOwnProperty('issueUrl')) {
		replacementValues.issueUrl = startupNavigationTarget.issueUrl;
	}
	if (startupNavigationTarget.hasOwnProperty('articleId')) {
		replacementValues.articleId = startupNavigationTarget.articleId;
	}

	// we are only handling issue for now; otherwise standard home navigation
	if (navigationTarget === 'issue' && startupNavigationTarget.hasOwnProperty('issueUrl')) {
		attributes.issueUrl = startupNavigationTarget.issueUrl;
		navigateToTarget({
			targetKey: 'issue',
			forceFetch: false,
			replaceHistoryState: false,
			replacementValues: replacementValues,
			attributes: attributes
		});
	} else {
		navigateToTarget({
			targetKey: navKey,
			forceFetch: false,
			replaceHistoryState: false,
			replacementValues: replacementValues,
			attributes: attributes
		});
	}
};
export {goHome};


/**
 * Display and handle the click on a "home" button type.  This button is designed
 * to go to go to a module another pane.
 *
 * @param params passed from the action button
 * @returns {*} the generated (clickable) tag to display
 * @constructor
 */
const HomeButton = (params) => {
	params = Object.assign({
		tag: 'button',
		label: '',
		title: '',
		className: ''
	}, params);
	const className = addClass('home-button', params.className);
	const label = !isEmpty(params.label) ? Translate.Text({id: params.label}) : Translate.Text({id: 'actionButton.homeButton.label'});
	const title = !isEmpty(params.title) ? Translate.Text({id: params.title}) : Translate.Text({id: 'actionButton.homeButton.title'});
	const GeneratedTag = !isEmpty(params.tag) ? `${params.tag}` : `button`;
	// add screen-reader class to label on xs and sm device sizes
	const device = getDeviceType();
	const labelClassName = (device === 'xs' || device === 'sm') ? 'button-text screen-reader' : 'button-text';

	return (
		<GeneratedTag
			className={className}
			title={title}
			onClick={(event) => goHome(params)}
		>
			<span className={'fal fa-home-alt'} />
			<span className={labelClassName}>
				{label}
			</span>
		</GeneratedTag>
	);
};
export {HomeButton};
