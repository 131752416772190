import React from "react";
import {Translate} from "../../../../locales/locales";
import getDeviceType from "../../../../utils/getDeviceType";
import * as Tracking from "../../../../utils/tracking";
import {isEmpty} from "../../../../utils/generalUtils";
import {addClass} from "../../../../utils/generateClassName";

/**
 * Called when the back button is clicked; this just calls the history back.
 * Go back to a previous pane from the previousPanes array.
 * Usually called from back button but can be triggered externally.
 */
const goBack = () => {
	const trackingProperties = {
		"type": "back",
	};
	Tracking.libraryTrack("button clicked", trackingProperties);
	window.history.back();
};
export {goBack};

/**
 * Back button will call the "go back to" function in global navigator.
 *
 * @param params
 *     props object from caller
 *         className: add to base class
 *         i18nID: use to generate name/icon? on button (either text or locale key lookup)
 *         tag: tag for component; if not specified, then default is button
 * @returns {*} string React object
 * @constructor
 */
const BackButton = (params) => {
	params = Object.assign({
		tag: 'button',
		label: '',
		title: '',
		className: ''
	}, params);
	const className = addClass('back-button', params.className);
	const label = !isEmpty(params.label) ? Translate.Text({id: params.label}) : Translate.Text({id: 'actionButton.backButton.label'});
	const title = !isEmpty(params.title) ? Translate.Text({id: params.title}) : Translate.Text({id: 'actionButton.backButton.title'});
	const GeneratedTag = !isEmpty(params.tag) ? `${params.tag}` : `button`;
	// add screen-reader class to label on xs and sm device sizes
	const device = getDeviceType();
	const labelClassName = (device === 'xs' || device === 'sm') ? 'button-text screen-reader' : 'button-text';

	return <GeneratedTag
		className={className}
		title={title}
		onClick={goBack}
	>
		<span className={'far fa-angle-left'} />
		<span className={labelClassName}>
			{label}
		</span>
	</GeneratedTag>;

};
export {BackButton};
