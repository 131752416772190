import React from "react";
import GenerateTitle from "../generateTitle";
import GenerateImage from "../generateImage";
import {GenerateHtmlMarkup} from "../generateHtmlMarkup";
import {stripTags, sanitizeInline} from "../../utils/htmlUtils";
import {populateArticleData} from "../generateSummary";
import getDeviceType from "../../utils/getDeviceType";
import {truncateString} from "../../utils/stringUtils";
import {ExternalUrl} from "../../environment";


/**
 * Generate the Cover Story headline
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GenerateHeadline = (params) => {
	const article = params.entry || {};
	const props = params.props;
	const category = stripTags(props.categories);
	const title = stripTags(article.title);
	const summary = typeof article.summary === 'string' && article.summary !== '' ? truncateString(sanitizeInline(article.summary), props.maxSummaryCharacters, props.useSummaryEllipsis) : '';


	const titleParams = {
		tag: 'h3',
		className: 'title',
		displayTitle: true,
	};

	const titleParamsCategory = {
		tag: 'h4',
		className: 'title-category',
		displayTitle: true,
	};

	return (
		<div className={'cover-story-headline'}>
			<GenerateTitle titleParams={titleParamsCategory}>{category}</GenerateTitle>
			<GenerateTitle titleParams={titleParams}>{title}</GenerateTitle>
			<GenerateHtmlMarkup tag={'p'} htmlMarkup={summary} />
		</div>
	);
};


const GenerateCoverImage = (params) => {
	const article = params.entry || {};
	const props = params.props;
	const device = getDeviceType();
	let coverImageSrc = '';
	let coverImageAlt = article.title; // fallback to title for ALT tag

	if (device === 'xs') {
		coverImageSrc = article.xlPortraitImage !== '' ? article.xlPortraitImage : ExternalUrl.PLACEHOLDER.PORTRAIT_LARGE;
		coverImageAlt = typeof article.xlPortraitImageAlt === 'string' && article.xlPortraitImageAlt !== '' ? article.xlPortraitImageAlt : coverImageAlt;
	} else {
		coverImageSrc = article.xlLandscapeImage !== '' ? article.xlLandscapeImage : ExternalUrl.PLACEHOLDER.PORTRAIT_EXTRA_LARGE;
		coverImageAlt = typeof article.xlLandscapeImageAlt === 'string' && article.xlLandscapeImageAlt !== '' ? article.xlLandscapeImageAlt : coverImageAlt;
	}

	// check and set image properties
	const imageProperties = {
		alt: props.alt ? props.alt : coverImageAlt,
		src: props.src ? props.src : coverImageSrc,
		link: props.link ? props.link : false,
	};

	return (
		<GenerateImage imageProperties={imageProperties} />
	);
};


/**
 * Generate html for Cover Story article
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GenerateCardCoverStory = (params) => {
	const entry = params.entry ? params.entry : {};
	const actions = params.actions ? params.actions : {};
	const props = params.props;

	// set article properties
	const articleData = populateArticleData(entry, actions);

	return (
		<a href={articleData.url} className={'item-link'} data-articletitle={articleData.title} onClick={articleData.clickAction}>
			<div className={'cover-story-card'}>
				<GenerateHeadline props={props} entry={entry} />
				<GenerateCoverImage props={props} entry={entry} />
			</div>
		</a>
	);
};
export default GenerateCardCoverStory;
