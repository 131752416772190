/*
	All layouts as strings - in anticipation of serving from server. Current layouts:
		oneColumn: default
		twoColumn
		threeColumn
		fourColumn
		fiveColumn

	NOTE: Each of the layouts is defined as a multi-line string.
	If you add another layout, don't forget the backtick (`) character
	around your new layout string.

	NOTE: Only leaf-nodes (ie. those named "container") can be targeted for module inclusion.

	IMPORTANT: Each container has a "class" property, with both "container" and a name starting
	with "container".  This name is important to help you identify containers for module placement
	and to determine what to do with the container for pane show/hide.
	The code WILL check to see if the div is a valid container before placing the defined
	module when generating the UI.

	NOTE: A container class is also defined with the "empty" class name.  This will be removed
	when modules are instantiated and the container is the target of any module instantiation.
	It is easier to remove the "empty" class than to try to keep track of modules which don't
	have content.

	CRITICAL: Each container name MUST be unique within the layout.  Modules will be added to the
	DOM using the container name within the pane/layout.

	NOTE: Custom containers can be added to any layout block in the Structures tab in the
	Configurator.  These containers will be added and instantiated in populateStructure.jsx when
	all containers and modules are instantiated and pouplated.  Containers added in this way must
	follow all the rules of regular containers.

	Styling of the layouts is done in the layouts.scss file.  We map pure.css classes to our
	classes in that file.
	In addition to the classes in this file, device size classes (xl, lg, md, sm, xs) will be added
	to the layout top-level class (ie. ".layout-two-column") and can be targeted with styles.
	For example:
		.layout-two-column.layout-xl
		.layout-two-column.layout-md
		.layout-two-column.layout-lg
		.layout-two-column.layout-sm
		.layout-two-column.layout-xs

	Note: device size classes will also be added to the structure top-level and to the pane
	Note2: every container is potentially programmatically focusable, so is given tabindex="-1"
*/


import {isTrue} from "../utils/generalUtils";


/**
 * Generate and object with the "reverse" order for the entries in the layout block
 * where the container name is the key and the layout block name is the value.
 *
 * @param singleLayout one of the layouts from the "containers" definition below
 * @returns {{}}
 */
const reverseOrder = (singleLayout) => {
	const reversedLayout = {};
	const layoutBlocks = singleLayout["layout-blocks"];
	Object.keys(layoutBlocks).forEach(layoutBlockName => {
		layoutBlocks[layoutBlockName].forEach(containerName => {
			reversedLayout[containerName] = layoutBlockName;
		});
	});
	return reversedLayout;
};


/**
 * WARNING: THIS MUST BE UPDATED IF "getLayouts" IS UPDATED
 *
 * Define and return the container structure as a JSON object.
 *
 * Normally, a specific layout name is passed in and the structure for that layout is
 * returned.  This can be overridden
 *     order: (default normal)
 *         normal: return the JSON structure as defined
 *         reverse: use the value of the container as the key and the block name as the value (faster lookup)
 *     returnAll: (default false)
 *         false: return a single layout block
 *         true: return the full containers definition
 *
 * @param layoutName name of a layout
 * @param params
 *     order: (default normal) normal/reverse
 *     returnAll: (default true) true/false
 * @returns {{}|*|{"layout-blocks": {"layout-header": string[], "layout-column-first": string[]}, name: string}|{fiveColumn: {"layout-blocks": {"layout-column-second": string[], "layout-header": string[], "layout-column-fifth": string[], "layout-column-first": string[], "layout-column-third": string[], "layout-column-fourth": string[]}, name: string}, threeColumn: {"layout-blocks": {"layout-column-second": string[], "layout-header": string[], "layout-column-first": string[], "layout-column-third": string[]}, name: string}, twoColumn: {"layout-blocks": {"layout-column-second": string[], "layout-header": string[], "layout-column-first": string[]}, name: string}, oneColumn: {"layout-blocks": {"layout-header": string[], "layout-column-first": string[]}, name: string}, fourColumn: {"layout-blocks": {"layout-column-second": string[], "layout-header": string[], "layout-column-first": string[], "layout-column-third": string[], "layout-column-fourth": string[]}, name: string}}}
 */
const getContainers = (layoutName, params) => {
	params = Object.assign({
		"order": "normal",
		"returnAll": false
	}, params);

	const containers = {
		"oneColumn": {
			"name": "layout-one-column",
			"layout-blocks": {
				"layout-header": [
					"container-header",
					"container-below-header"
				],
				"layout-column-first": [
					"container-viewer-controls",
					"container-first-top",
					"container-first-bottom"
				]
			}
		},
		"twoColumn": {
			"name": "layout-two-column",
			"layout-blocks": {
				"layout-header": [
					"container-header",
					"container-below-header"
				],
				"layout-column-first": [
					"container-first-top",
					"container-first-bottom",
					"container-banner-toc"
				],
				"layout-column-second": [
					"container-viewer-controls",
					"container-second-top",
					"container-second-bottom"
				]
			},
		},
		"threeColumn": {
			"name": "layout-three-column",
			"layout-blocks": {
				"layout-header": [
					"container-header",
					"container-below-header"
				],
				"layout-column-first": [
					"container-first-top",
					"container-first-bottom",
					"container-banner-toc"
				],
				"layout-column-second": [
					"container-viewer-controls",
					"container-second-top",
					"container-second-bottom"
				],
				"layout-column-third": [
					"container-third-top",
					"container-third-bottom"
				]
			},
		},
		"fourColumn": {
			"name": "layout-four-column",
			"layout-blocks": {
				"layout-header": [
					"container-header",
					"container-below-header"
				],
				"layout-column-first": [
					"container-first-top",
					"container-first-bottom",
					"container-banner-toc"
				],
				"layout-column-second": [
					"container-viewer-controls",
					"container-second-top",
					"container-second-bottom"
				],
				"layout-column-third": [
					"container-third-top",
					"container-third-bottom"
				],
				"layout-column-fourth": [
					"container-fourth-top",
					"container-fourth-bottom"
				]
			},
		},
		"fiveColumn": {
			"name": "layout-five-column",
			"layout-blocks": {
				"layout-header": [
					"container-header",
					"container-below-header"
				],
				"layout-column-first": [
					"container-first-top",
					"container-first-bottom",
					"container-banner-toc"
				],
				"layout-column-second": [
					"container-viewer-controls",
					"container-second-top",
					"container-second-bottom"
				],
				"layout-column-third": [
					"container-third-top",
					"container-third-bottom"
				],
				"layout-column-fourth": [
					"container-fourth-top",
					"container-fourth-bottom"
				],
				"layout-column-fifth": [
					"container-fifth-top",
					"container-fifth-bottom"
				]
			}
		}
	};

	if (isTrue(params.returnAll, {defaultValue: false})) {
		return containers;
	} else {
		const singleLayout = containers.hasOwnProperty(layoutName) ? containers[layoutName] : containers.oneColumn;
		if (params.order === "reverse") {
			return reverseOrder(singleLayout);
		} else {
			return singleLayout;
		}
	}
};


/**
 * WARNING: THIS MUST BE UPDATED IF "getContainers" IS UPDATED
 *
 * Define and return the DOM structure for a particular layout.
 * The call to getLayout will return the layout for a single type
 *
 * @param layoutName the name of layout to return
 * @returns {*|string} the layout definition
 */
const getLayout = (layoutName) => {
	const layouts = {
		"oneColumn":
			(`
				<div class="layout layout-one-column">
					<div class="layout-block layout-header">
						<div class="container container-header empty" role="banner" aria-label="Header" tabindex="-1"></div>
						<div class="container container-below-header empty" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-first" role="main">
						<div class="container container-viewer-controls empty" tabindex="-1"></div>
						<div class="container container-first-top empty" tabindex="-1"></div>
						<div class="container container-first-bottom empty" tabindex="-1"></div>
					</div>
				</div>
			`),
		"twoColumn":
			(`
				<div class="layout layout-two-column">
					<div class="layout-block layout-header">
						<div class="container container-header empty" role="banner" aria-label="Header" tabindex="-1"></div>
						<div class="container container-below-header empty" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-first" role="navigation">
						<div class="container container-first-top empty" tabindex="-1"></div>
						<div class="container container-first-bottom empty" tabindex="-1"></div>
						<div class="container container-banner-toc empty" role="complementary" aria-label="Sidebar Advertisement" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-second" role="main">
						<div class="container container-viewer-controls empty" tabindex="-1"></div>
						<div class="container container-second-top empty" tabindex="-1"></div>
						<div class="container container-second-bottom empty" tabindex="-1"></div>
					</div>
				</div>
			`),
		"threeColumn":
			(`
				<div class="layout layout-three-column">
					<div class="layout-block layout-header">
						<div class="container container-header empty" role="banner" aria-label="Header" tabindex="-1"></div>
						<div class="container container-below-header empty" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-first" role="navigation">
						<div class="container container-first-top empty" tabindex="-1"></div>
						<div class="container container-first-bottom empty" tabindex="-1"></div>
						<div class="container container-banner-toc empty" role="complementary" aria-label="Sidebar Advertisement" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-second" role="main">
						<div class="container container-viewer-controls empty" tabindex="-1"></div>
						<div class="container container-second-top empty" tabindex="-1"></div>
						<div class="container container-second-bottom empty" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-third" role="navigation">
						<div class="container container-third-top empty" tabindex="-1"></div>
						<div class="container container-third-bottom empty" tabindex="-1"></div>
					</div>
				</div>
			`),
		"fourColumn":
			(`
				<div class="layout layout-four-column">
					<div class="layout-block layout-header">
						<div class="container container-header empty" role="banner" aria-label="Header" tabindex="-1"></div>
						<div class="container container-below-header empty" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-first" role="navigation">
						<div class="container container-first-top empty" tabindex="-1"></div>
						<div class="container container-first-bottom empty" tabindex="-1"></div>
						<div class="container container-banner-toc empty" role="complementary" aria-label="Sidebar Advertisement" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-second" role="main">
						<div class="container container-viewer-controls empty" tabindex="-1"></div>
						<div class="container container-second-top empty" tabindex="-1"></div>
						<div class="container container-second-bottom empty" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-third">
						<div class="container container-third-top empty" tabindex="-1"></div>
						<div class="container container-third-bottom empty" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-fourth">
						<div class="container container-fourth-top empty" tabindex="-1"></div>
						<div class="container container-fourth-bottom empty" tabindex="-1"></div>
					</div>
				</div>
			`),
		"fiveColumn":
			(`
				<div class="layout layout-five-column">
					<div class="layout-block layout-header">
						<div class="container container-header empty" role="banner" aria-label="Header" tabindex="-1"></div>
						<div class="container container-below-header empty" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-first" role="navigation">
						<div class="container container-first-top empty" tabindex="-1"></div>
						<div class="container container-first-bottom empty" tabindex="-1"></div>
						<div class="container container-banner-toc empty" role="complementary" aria-label="Sidebar Advertisement" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-second" role="main">
						<div class="container container-viewer-controls empty" tabindex="-1"></div>
						<div class="container container-second-top empty" tabindex="-1"></div>
						<div class="container container-second-bottom empty" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-third">
						<div class="container container-third-top empty" tabindex="-1"></div>
						<div class="container container-third-bottom empty" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-fourth">
						<div class="container container-fourth-top empty" tabindex="-1"></div>
						<div class="container container-fourth-bottom empty" tabindex="-1"></div>
					</div>
					<div class="layout-block layout-column-fifth">
						<div class="container container-fifth-top empty" tabindex="-1"</div>
						<div class="container container-fifth-bottom empty" tabindex="-1"></div>
					</div>
				</div>
			`),
	};

	return (layouts.hasOwnProperty(layoutName)) ? layouts[layoutName] : layouts.oneColumn;
};

export {getLayout, getContainers};
