import getDeviceType from "../getDeviceType";
import {getObjectFromJSON} from "../objectUtils";
import $ from "jquery";
import * as Tracking from "../tracking";
import {
	ARTICLE_ZOOM_STEP_LEVEL,
	DEFAULT_ARTICLE_ZOOM_LEVEL,
	ID_NOT_SET,
	MAX_ARTICLE_ZOOM_LEVEL,
	MIN_ARTICLE_ZOOM_LEVEL
} from "../../modules/_MODULE_GLOBALS/constants";
import {populateFetchDataAttributes} from "../populateFetchDataAttributes";
import {manageUpdateAndNavigation} from "../../modules/globals/globalNavigator";
import {isEmpty, isTrue} from "../generalUtils";
import {triggerOnArticleListClick} from "../../triggers/triggerOnArticleList";
import {addClass} from "../generateClassName";



/**
 * Open an article in the Content Hub when an article target is clicked.
 *
 * @param event click event
 * @param params parameters passed back from summary click on article
 */
const openArticle = (event, params) => {
	params = Object.assign({
		mops: {},
		moduleProps: {},
		articleList: [],
		articleId: ID_NOT_SET,
		issueUrl: '',
		isCurrentArticle: false,
		zoomLevel: '',
		setShareHistory: true,
		section: ''
	}, params);
	const mops = params.mops;
	const articleList = params.articleList;
	const section = !isEmpty(params.section) ? params.section : '';

	// get the current article and parse any dynamic fetch attributes; add issueUrl attribute to article
	const currentArticle = articleList.find(entry => entry.articleId === params.articleId);
	const moduleProps = populateFetchDataAttributes({
		props: params.moduleProps,
		data: currentArticle
	});

	const navigationKeys = getObjectFromJSON(moduleProps.navigationKeys, {});
	const fetchNavigationKey = moduleProps.fetchNavigationKey !== null && navigationKeys.hasOwnProperty(moduleProps.fetchNavigationKey) ? moduleProps.fetchNavigationKey : 'article';

	/**
	 * TODO: Redo the way we decide when/what to store in history in manageShareable so this section can be eliminated.
	 * TODO: Note: There is still a bug in mobile that populates history twice in some instances.
	 *
	 * 	"updateHistoryState": true will force the articleViewer module to do a manageUpdateAndNavigation call which
	 * 	will force an entry in history.
	 * 	This will be set to true in the caller, if a navigationKey is defined.
	 *
	 * 	It can also be triggered if there is no navigationKey and we need to use the fetchNavigationKey
	 * 	to figure out what to do.  We only need to override and set this value if the fetchNavigationKey
	 * 	is issue or category (ie. not article)
	 *
	 * 	Note: a navigationKey will automatically set history in the articleViewer, so we don't want to
	 * 	also create an override here.
	 */
	const updateHistoryStateOverride = fetchNavigationKey !== 'article';
	const updateHistoryState = isTrue(params.updateHistoryState) || updateHistoryStateOverride;
	const shareQueryParams = {};
	if (!isEmpty(section)) {
		shareQueryParams.section = section;
	}

	const attributes = {
		articleId: params.articleId,
		issueUrl: params.issueUrl,
		articleList: articleList,
		fetchQueryParams: mops.queryParams,
		updateHistoryState: updateHistoryState,
		replaceHistoryState: isTrue(params.replaceHistoryState, {defaultValue: false}),
		setShareHistory: isTrue(params.setShareHistory, {defaultValue: true}),
		isCurrentArticle: params.isCurrentArticle,
		zoomLevel: !isEmpty(params.zoomLevel) ? params.zoomLevel : (!isEmpty(moduleProps.zoomLevel) ? moduleProps.zoomLevel : 1),
		section: section,
		shareQueryParams: shareQueryParams
	};
	if (moduleProps.hasOwnProperty('navigationKey')) {
		if (moduleProps.navigationKey === 'issue') {
			attributes.issueUrl = moduleProps.issueUrl;
		} else if (moduleProps.navigationKey === 'category') {
			attributes.category = moduleProps.categories;
		}
	}
	triggerOnArticleListClick({
		storageKey: moduleProps.storageKey,
		listType: moduleProps.listType,
		scrollToLastPosition: moduleProps.scrollToLastPosition
	});

	manageUpdateAndNavigation({
		navigationKey: fetchNavigationKey,
		moduleProps: moduleProps,
		attributes: attributes
	});
};
export {openArticle};

/**
 * Open an issue article in the Content Hub when an issue target is clicked.
 *
 * @param event click event
 * @param params parameters passed back from summary click on issue
 */
const openIssue = (event, params) => {
	console.log('clicked issue');
};


/**
 * Handle article link clicked event.
 * Track event
 *
 * @param params parameters passed back from summary click on article
 * @param event event
 */
const articleClicked = (event, params) => {
	params = typeof params !== 'undefined' ? params : {};
	const articleParams = params.hasOwnProperty('params') ? params.params : {};
	const $target = $(event.target);
	const $link = $target.closest('a');
	const trackingProperties = {
		"type": "article link",
		"module": "article list",
		"url": $link.attr('href'),
		"domain": $link.prop('hostname'),
		"label": $link.data('articletitle'),
		"destination type": "article"
	};
	if (articleParams.hasOwnProperty('destination')) {
		event.stopPropagation();
		Tracking.libraryTrack("button clicked", trackingProperties);
		if (articleParams.destination === 'internal') {
			event.preventDefault();
			params.setShareHistory = false;
			openArticle(event, params);
		}
	}
};

/**
 * Handle article issue link clicked event.
 * Track event
 *
 * @param params parameters passed back from summary click on issue
 * @param event event
 */
const issueClicked = (event, params) => {
	params = typeof params !== 'undefined' ? params : {};
	const issueParams = params.hasOwnProperty('params') ? params.params : {};
	const $target = $(event.target);
	const $link = $target.closest('a');
	const trackingProperties = {
		"type": "issue link",
		"module": "article list",
		"url": $link.attr('href'),
		"domain": $link.prop('hostname'),
		"label": $link.data('issuetitle'),
		"destination type": "issue"
	};
	if (issueParams.hasOwnProperty('destination')) {
		event.stopPropagation();
		Tracking.libraryTrack("button clicked", trackingProperties);
		if (issueParams.destination === 'internal') {
			event.preventDefault();
			openIssue(event, params);
		}
	}
};


/**
 * Check navigationKeys for links to other modules.  Initialize then
 * populate and return articleActions with properties from navigationKeys.
 */
const populateArticleActions = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		articleList: [],
		callOnUIChange: null,
		updateHistoryState: false
	}, params);
	const props = params.props;
	const device = getDeviceType();
	const destination = props.destinations.hasOwnProperty(device) ? props.destinations[device] : {};
	// initialize with defaults as not configured
	const articleActions = {
		params: {},
		articleClicked: articleClicked,
		issueClicked: issueClicked,
		callOnUIChange: params.callOnUIChange,
		moduleProps: props,
		mops: params.mops,
		articleList: params.articleList,
		updateHistoryState: isTrue(params.updateHistoryState, {defaultValue: false})
	};
	const navigationKeys = getObjectFromJSON(props.navigationKeys, {});
	if (navigationKeys.hasOwnProperty('article')) {
		const articleParams = navigationKeys.article;
		articleActions.params.article = {
			destination: destination.hasOwnProperty('article') ? destination.article : 'internal',
			linkTitle: articleParams.hasOwnProperty('elementTitle') ? articleParams.elementTitle : ""
		};
	}
	if (navigationKeys.hasOwnProperty('issue')) {
		const issueParams = navigationKeys.issue;
		articleActions.params.issue = {
			destination: destination.hasOwnProperty('issue') ? destination.issue : 'internal',
			linkTitle: issueParams.hasOwnProperty('elementTitle') ? issueParams.elementTitle : ""
		};
	}
	if (navigationKeys.hasOwnProperty('category')) {
		const categoryParams = navigationKeys.category;
		articleActions.params.category = {
			destination: destination.hasOwnProperty('issue') ? destination.category : 'internal',
			linkTitle: categoryParams.hasOwnProperty('elementTitle') ? categoryParams.elementTitle : ""
		};
	}
	return articleActions;
};
export {populateArticleActions};


/**
 * Given an articleList object and an articleId value; find the article object
 * from the list and return it.  If not found, return empty object.
 * Optionally, try to find the article from either the associated folio or
 * pages values (from pageNumber)
 *
 * @param params
 *     articleList: list of replica objects
 *     articleId: article id
 *     folio: folio string
 *     pageNumber: page number in replica
 * @returns {*}
 */
const findArticle = (params) => {
	params = Object.assign({
		articleList: [],
		articleId: ID_NOT_SET,
		folio: '',
		pageNumber: ID_NOT_SET
	}, params);
	let article = {};
	if (params.articleId !== ID_NOT_SET) {
		// should already be int value, but make sure; if isNaN, then comparison will be false
		const articleId = parseInt(params.articleId, 10);
		article = params.articleList.find(entry => entry.articleId === articleId);
	} else if (!isEmpty(params.folio)) {
		article = params.articleList.find(entry => !isEmpty(entry.folios) && entry.folios.includes(params.folio));
	} else if (!isEmpty(params.pageNumber)) {
		// should already be int value, but make sure; if isNaN, then comparison will be false
		const pageNumber = parseInt(params.pageNumber, 10);
		article = params.articleList.find(entry => entry.pageNumber === pageNumber);
	}
	return typeof article !== 'undefined' ? article : {};
};
export {findArticle};



/**
 * Find article in the list of articles based on articleId.
 *
 * If the articleId is -99999 (ID_NOT_SET) return the first article in the article list.
 *
 * Return
 *     hasArticle: boolean true/false if article found
 *     article: matching article
 *     content: the article content
 *     moduleClassName: className + 'empty' if no article content
 *     template: template name used to generate article
 *
 * @param params input parameters
 *     props: props from jsx
 *     mops: generated mops
 * @returns {{hasArticle: boolean, template: string, moduleClassName: string, article: {}, content: string}}
 */
const getArticleDetails = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		articleList: []
	}, params);
	const className = params.mops.className;
	const articleList = typeof params.articleList !== 'undefined' ? params.articleList : [];

	let articleDetails = {
		hasArticle: false,
		article: {},
		subscriptionProduct: {},
		singleIssueProduct: {},
		content: '',
		moduleClassName: addClass(className, 'empty'),
		template: ''
	};
	if (!isEmpty(articleList) && articleList[0].articleId > 0) {
		const articleId = params.props.articleId === ID_NOT_SET ? articleList[0].articleId : params.props.articleId;
		const currentArticle = findArticle({articleList: articleList, articleId: articleId});
		if (!isEmpty(currentArticle)) {
			const articleContent = currentArticle.hasOwnProperty('content') ? currentArticle.content : '';
			const moduleClassName = articleContent !== '' ? className : addClass(className, 'empty');
			const template = (currentArticle.hasOwnProperty('customTemplate') && currentArticle.customTemplate !== 'default') ? currentArticle.customTemplate : params.props.docTypeDocumentTemplate.template;

			articleDetails = {
				hasArticle: true,
				article: currentArticle,
				articleId: articleId,
				subscriptionProduct: params.props.products.find((e) => e.productType === "subscription"),
				singleIssueProduct: params.props.products.find(e => e.productType === "single_issue" &&
					currentArticle.documentUrl.includes(e.documentUrl)),
				content: articleContent,
				moduleClassName: moduleClassName,
				template: template
			};
		}
	}
	return articleDetails;
};
export {getArticleDetails};


/**
 * Read the Configuration property for articleLayout which is configured based on device size.
 * Populate a return object with zoomLevel dependent on device size.
 *
 * @param articleLayout value of Configuration property
 * @returns {{}}
 */
const deviceArticleLayout = (articleLayout) => {
	const defaultValue = {
		zoomLevel:DEFAULT_ARTICLE_ZOOM_LEVEL,
		zoomMin: MIN_ARTICLE_ZOOM_LEVEL,
		zoomMax: MAX_ARTICLE_ZOOM_LEVEL,
		zoomStep: ARTICLE_ZOOM_STEP_LEVEL
	};
	const device = getDeviceType();
	const globalsDevice = articleLayout.hasOwnProperty(device) ? articleLayout[device] : {};
	const globalsDefault = articleLayout.hasOwnProperty('default') ? articleLayout.default : {};

	const deviceLayout = {
		zoomLevel: globalsDevice.hasOwnProperty('zoomLevel') ? parseFloat(globalsDevice.zoomLevel) : (globalsDefault.hasOwnProperty('zoomLevel') ? parseFloat(globalsDefault.zoomLevel) : defaultValue.zoomLevel),
		zoomMin: globalsDevice.hasOwnProperty('zoomMin') ? parseFloat(globalsDevice.zoomMin) : (globalsDefault.hasOwnProperty('zoomMin') ? parseFloat(globalsDefault.zoomMin) : defaultValue.zoomMin),
		zoomMax: globalsDevice.hasOwnProperty('zoomMax') ? parseFloat(globalsDevice.zoomMax) : (globalsDefault.hasOwnProperty('zoomMax') ? parseFloat(globalsDefault.zoomMax) : defaultValue.zoomMax),
		zoomStep: globalsDevice.hasOwnProperty('zoomStep') ? parseFloat(globalsDevice.zoomStep) : (globalsDefault.hasOwnProperty('zoomStep') ? parseFloat(globalsDefault.zoomStep) : defaultValue.zoomStep),
	};
	deviceLayout.pageSpread = !isNaN(deviceLayout.pageSpread) ? deviceLayout.pageSpread : defaultValue.pageSpread;
	deviceLayout.zoomLevel = !isNaN(deviceLayout.zoomLevel) ? deviceLayout.zoomLevel : defaultValue.zoomLevel;
	deviceLayout.zoomMin = !isNaN(deviceLayout.zoomMin) ? deviceLayout.zoomMin : defaultValue.zoomMin;
	deviceLayout.zoomMax = !isNaN(deviceLayout.zoomMax) ? deviceLayout.zoomMax : defaultValue.zoomMax;
	deviceLayout.zoomStep = !isNaN(deviceLayout.zoomStep) ? deviceLayout.zoomStep : defaultValue.zoomStep;

	return deviceLayout;
};
export {deviceArticleLayout};


