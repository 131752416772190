
/**
 * replicaList needs to know which page(s) to mark as current.
 * Detect whether or not to set a page array value or to reset it
 * to an empty array.
 *
 * @param params module params
 *     storeState: module store state
 *     moduleProps: module props
 * @returns {{}}
 */
const setCurrentPage = (params) => {
	params = Object.assign({
		storeState: {},
		moduleProps: {},
	}, params);
	const moduleProps = params.moduleProps;
	const storeState = params.storeState;

	if (!moduleProps.isMyPane) {
		moduleProps.folio = '';
	} else if (storeState.hasOwnProperty('folio') && storeState.folio !== '') {
		moduleProps.folio = storeState.folio;
	} else if (moduleProps.hasOwnProperty('replicaList') && moduleProps.replicaList.length > 0) {
		moduleProps.folio = moduleProps.replicaList[0].folio;
	} else {
		moduleProps.folio = '';
	}

	return moduleProps;
};
export {setCurrentPage};
