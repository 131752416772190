import React, {useRef} from 'react';
import {connect} from 'react-redux';
import {updateData} from '../../../store/actions';
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import { MODAL_MENU} from "../../_MODULE_GLOBALS/constants";
import {isEmpty, isTrue} from "../../../utils/generalUtils";
import {addClass} from "../../../utils/generateClassName";
import {Translate} from "../../../locales/locales";
import {navigateToTarget} from "../../globals/globalNavigator";
import {clone} from "../../../utils/objectUtils";



/**
 * ModalMenuModule
 *
 * This module is designed to display a modal box with a list of links as a simple
 * menu; typically displayed in as an html unordered list;
 *
 * Most parameters are passed in through configuration options, but we allow the link
 * value to be passed in through the store.  These values are determined through
 * server parameters, so there is no fetch or update.
 *
 */

/*
	list of potential configuration parameters;
		className: optional class name to add to module
		icon: open and close icons; currently only font-awesome value
		links: ordered array of link objects to display
		    label: string to display for link
		    linkType: string/state/global/storageKey
		    link: url ("string") or state variable name ("state", "global", "storageKey")
 */

/**
 * Replace the link.url attribute with the final url for the generated link.
 * link url values can be one of a number of types; a string to use as is,
 * or a redux state variable that matches a redux state attribute.
 *
 * @param params
 *     state: redux state object
 *     storageKey: module storageKey
 *     links: array of links from configuration
 * @returns {*|*[]} final links array of objects
 */
const addressLinkTypes = new Set(['string', 'state', 'global', 'storageKey']);
const updateLinksUrl = (params) => {
	params = Object.assign({
		state: {},
		storageKey: '',
		links: [],
	}, params);
	const {state, storageKey} = params;
	const links = !isEmpty(params.links) ? clone(params.links) : [];
	links.forEach(link => {
		// generate url string, for "address" links
		const linkProperties = !isEmpty(link.properties) ? link.properties : {};
		if (linkProperties.type === 'address') {
			const urlLocation = addressLinkTypes.has(linkProperties.urlLocation) ? linkProperties.urlLocation : 'string';
			const urlValue = !isEmpty(linkProperties.url) ? linkProperties.url : "#";
			if (urlLocation === 'string') {
				linkProperties.url = urlValue;
			} else if (urlLocation === 'state') {
				linkProperties.url = !isEmpty(state[urlValue]) ? state[urlValue] : "#";
			} else if (urlLocation === 'global') {
				const globalState = !isEmpty(state.globals) ? state.globals : {};
				linkProperties.url = !isEmpty(globalState[urlValue]) ? globalState[urlValue] : "#";
			} else if (urlLocation === 'storageKey') {
				const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};
				linkProperties.url = !isEmpty(storeState[urlValue]) ? storeState[urlValue] : "#";
			}
			// convert link url to mailto if mail type
			if (linkProperties.linkType === 'mailto') {
				linkProperties.url = linkProperties.url.startsWith('mailto:') ? linkProperties.url : 'mailto:' + linkProperties.url;
			}
		}
	});
	return links;
};

const openCloseModalMenu = (params) => {
	params = Object.assign({
		moduleDOMElement: null,
	}, params);
	const moduleElement = !isEmpty(params.moduleDOMElement.current) ? params.moduleDOMElement.current : null;
	if (isEmpty(moduleElement)) {
		return;
	}
	if (moduleElement.classList.contains('modal-open')) {
		moduleElement.classList.remove('modal-open');
		moduleElement.classList.add('modal-closed');
	} else {
		moduleElement.classList.remove('modal-closed');
		moduleElement.classList.add('modal-open');
	}

};

/**
 * Handle click on navigation link, for internal navigation.
 * We could just call navigateToTarget directly, but this allows for more checking
 * and control when link is clicked.
 * If the navigationTarget value is not set, then do nothing.
 * Since we are navigating, close the modal menu.
 *
 * @param params
 *     navigationTarget: string target value from Navigation Targets configuration
 *     attributes: any attributes to pass to the call
 *     moduleDOMElement: module DOM element
 */
const goToNavigationLink = (params) => {
	params = Object.assign({
		navigationTarget: '',
		attributes: {},
		moduleDOMElement: null
	}, params);
	const navigationTarget = typeof params.navigationTarget === 'string' ? params.navigationTarget : '';

	if (!isEmpty(navigationTarget)) {
		openCloseModalMenu({moduleDOMElement: params.moduleDOMElement});
		navigateToTarget({
			targetKey: navigationTarget,
			attributes: params.attributes
		});
	}
};


export const ModalMenuModule = (props) => {
	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: MODAL_MENU,
		defaultClass: addClass('modal-menu-wrapper', [props.classes.module]),
	});

	const moduleDOMElement = useRef(null);

	/*
	* Generate jsx for logo if displayOnDevice
	* Create the modal with the list of links.  Start off closed.
	*/
	if (displayOnDevice(props)) {
		const wrapperClassName = addClass(mops.className, 'modal-closed');
		const menuClassName = addClass(props.classes.menu, 'modal-menu');
		const buttonClassName = addClass(props.classes.button, 'modal-menu-selector');
		return (
			<div className={wrapperClassName} ref={moduleDOMElement}>
				<button
					className={buttonClassName}
					name={props.labels.button.name}
					title={props.labels.button.title}
					onClick={() => openCloseModalMenu({moduleDOMElement: moduleDOMElement})}
				>
					<span className="screen-reader">{props.labels.button.screenReader}</span>
				</button>
				<div className={menuClassName} role={'navigation'}>
					<menu role={'menu'} aria-label={props.labels.menu.name}>
						{
							props.links.map((link, index) => {
								const keyValue = link.label + index.toString();
								const labelType = !isEmpty(link.labelType) ? link.labelType : "text";
								const label = !isEmpty(link.label) ? (labelType === 'html'? Translate.Text ({id: link.label, html: true}) : Translate.Text({id: link.label})) : link.url;
								const linkClass = !isEmpty(link.className) ? link.className : '';
								const renderLink = displayOnDevice({displayDevices: link.displayDevices, structureId: props.structureId});

								if (renderLink) {
									const linkProperties = !isEmpty(link.properties) ? link.properties : {};
									const type = !isEmpty(linkProperties.type) ? (linkProperties.type === 'navigation' ? 'navigation' : 'address') : 'address';
									const urlAttributes = !isEmpty(link.urlAttributes) ? link.urlAttributes : {};
									if (type === 'navigation') {
										if (!isEmpty(linkProperties.navigationTarget)) {
											const attributes = !isEmpty(linkProperties.attributes) ? linkProperties.attributes : {};
											return (
												<li key={keyValue} className={linkClass}>
													<button
														{...urlAttributes}
														onClick={() => goToNavigationLink({
															navigationTarget: linkProperties.navigationTarget,
															attributes: attributes,
															moduleDOMElement: moduleDOMElement
														})}
													>
														{label}
													</button>
												</li>
											);
										} else {
											return null;
										}
									} else {
										const target = !isEmpty(linkProperties.target) ? linkProperties.target : '_blank';
										return (
											<li key={keyValue} className={linkClass}>
												<a href={linkProperties.url} target={target}>
													{label}
												</a>
											</li>
										);
									}
								} else {
									return null;
								}
							})
						}
					</menu>
				</div>
			</div>
		);
	} else {
		return null;
	}
};


/**
 * Map state (store) data.
 *
 * storageKey provides a way to map a specific store object to a particular
 * instance of the module.  Add storageKey when configuring the module instance
 * if it needs to listen for a store change.
 *
 * Note: logo image path is hard-coded and added to collectionUrl
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : MODAL_MENU;
//	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};
//	const globalState = !isEmpty(state.globals) ? state.globals : {};

	const modalProps = {
		active: getActiveAttributeState(props),
		openCloseIcons: !isEmpty(props.icon) ? props.icon : {},
	};
	const menu = !isEmpty(props.menu) ? props.menu : {};
	const button = !isEmpty(props.button) ? props.button : {};
	modalProps.classes = {
		module: !isEmpty(props.className) ? props.className : 'modal-menu-wrapper',
		menu: !isEmpty(menu.className) ? menu.className : 'modal-menu',
		button: !isEmpty(button.className) ? button.className : 'modal-menu-selector',
	};
	modalProps.labels = {
		button: {
			name: !isEmpty(button.name) && !isEmpty(button.name) ? Translate.Text({id: button.name}) : 'modalButton',
			screenReader: !isEmpty(button.screenreader) && !isEmpty(button.screenreader) ? Translate.Text({id: button.screenreader}) : '',
		},
		menu: {
			name: !isEmpty(menu.name) && !isEmpty(menu.name) ? Translate.Text({id: menu.name}) : 'Modal Menu'
		},
	};

	const links = !isEmpty(menu.links) ? menu.links : [];
	modalProps.links = updateLinksUrl({
		state: state,
		storageKey: storageKey,
		links: links,
	});

	return modalProps;
};

/**
 * simpleImage module (currently) makes to calls to fetch of update data.
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ModalMenuModule);

