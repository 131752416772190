/**
 * validation functions, so far we don't have many
 */



const isEmailValid = (email) => {
	// (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)){
	// /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
	// Keeping a simple one for now, doesn't consider spaces, 
    //if we want to consider spaces use one of the more complex ones above
	if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
		return (true);
	}
	return (false);
};

export { isEmailValid };