import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {executeFunction} from "../../../utils/embedJavascript";
import {useMountPostRender} from "../../../hooks/useMount";
import {isEmpty} from "../../../utils/generalUtils";

/**
 * The addTracker module provides a way to trigger custom tracking using
 * customer-supplied external javascript scripts.
 * If the head property is configured, then gather and add these scripts to
 * the <head> of the application.
 * If the init attribute is configured, trigger an initial call based on the configured javascript.
 *
 * Configuration Properties
 *     tracker: name of tracker to call (added to configuration on server)
 *     head: gathered and added to the <head> tag for the index html on the server
 *     init: enable trigger for an included javascript script
 *     initParams: object of parameters to pass to init
 *     className: class(es) to add to created UI
 *     target: target class/id to add to created UI
 *
 * Configuration Properties gathered on the server and used by configured trackers
 *     trackFunction: function to call each time tracking is triggered
 *     trackParams: object of parameters to pass to trackFunction
 *
 */
export const AddTrackerModule = (props) => {
	useMountPostRender(() => {
		if (!isEmpty(props.init)) {
			if (!isEmpty(props.initParams)) {
				executeFunction(props.init, props.initParams);
			} else {
				executeFunction(props.init);
			}
		}
	}, [props.init]);

	// if either className or target is defined, create div
	// if target is defined; check if either id or class
	if (!isEmpty(props.className) || !isEmpty(props.target)) {
		const hasTarget = !isEmpty(props.target);
		const htmlAttributes = {
			id: '',
			className: !isEmpty(props.className) ? props.className : '',
			useId: hasTarget ? props.target.charAt(0) === '#' : false,
			classIdString: hasTarget ? props.target.slice(1) : ''
		};

		if (htmlAttributes.useId) {
			return <div id={htmlAttributes.classIdString} className={htmlAttributes.className} />;
		} else {
			return <div className={htmlAttributes.className + ' ' + htmlAttributes.classIdString} />;
		}
	} else {
		return null;
	}

};

/**
 * Pass Configured properties (props) directly to the module.
 *
 * NOTE: Javascript added to the "head" configuration is embedded from the node server
 * in a call to "gatherGlobalModulesAndAttributes" in the "generateAdditionalConfigObjects.js" file.
 *
 * @param state
 * @param props
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
	return props;
};

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddTrackerModule);
