// import React from 'react';
import $ from 'jquery';
import mixpanel from 'mixpanel-browser';
import store from "../store";
import {getUrlParam} from './urlParamUtils';
import {getObjectFromJSON} from "./objectUtils";
import {isEmpty} from "./generalUtils";
import Cookies from 'universal-cookie';
import {callCustomTrackers} from "../modules/common/addTracker/externalTrackers";

const CONSTANTS = {
	TIMED_EVENTS: ['content viewed'],
	TIMED_EVENTS_EXCLUDE_TYPES: ["popup view", "video view"],
	// for storing events when offline
	OFFLINE_EVENT_KEY: 'GTX_OFFLINE_EVENTS',
	SESSION_TIMEOUT: 30,
	SESSION_DATA_KEY: 'sessionData',
	FIRST_TIME_USER_KEY: 'GTX_FIRST_TIMER',
};

const events = [
	"content viewed",
	"butto clicked",
	"page viewed",
	"article viewed",
	"issue clicked",
	"search",
	"link clicked",
	"button clicked"
];
/**
 * For contenthub we store everything in localStorage
 * Cookies are only used to pass the sessionId between webreader and contenthub
*/
const cookies = new Cookies();

/**
 * Attempt to determine if the browser supports touch events.  Not really a check
 * for device, but, determining a touch device is not currently possible.
 *
 * @returns {boolean}
 */
const isTouchDevice = () => {
	return (('ontouchstart' in window) || (document.documentElement && 'ontouchstart' in document.documentElement) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
};

/**
 * Generate a new session id, given a timestamp value.
 * If no timestamp, generate a new one.
 *
 * @param timestamp time in milliseconds
 * @returns {string}
 */
const generateSessionId = (timestamp) => {
	const sessionDataCookie = cookies.get(CONSTANTS.SESSION_DATA_KEY);

	if (sessionDataCookie && sessionDataCookie.id) {
//		console.log("using sessionId from cookie");
		return sessionDataCookie.id;
	}
	timestamp = typeof timestamp !== 'undefined' ? timestamp : new Date().getTime();
	const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let sessionId = timestamp + "-";
	for (let i=0; i<8; i++) {
		sessionId += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return sessionId;
};

/**
 * Save sessionData object as a string under SESSION_DATA_KEY
 *
 * @param sessionData session data object
 */
const saveSessionData = (sessionData) => {
	if (sessionData && sessionData.id && (typeof sessionData.id !== "undefined")) {
		window.sessionStorage.setItem(CONSTANTS.SESSION_DATA_KEY, JSON.stringify(sessionData));
		cookies.set(CONSTANTS.SESSION_DATA_KEY,
			JSON.stringify(sessionData), {
				expires: (new Date(sessionData.timeout)),
				path: '/',
				sameSite: 'none',
				secure: true
			});
	}
};

/**
 * Return sessionData property stored in session storage.
 * Convert to javascript object before returning.
 * If extend is false or sessionData doesn't exist or timed out,
 * generate a new session.
 *
 * @param extend true extend timeout; false new timeout
 * @returns {any}
 */
const getSessionData = (extend) => {
	extend = typeof extend === 'boolean' ? extend : true;
	const currentTime = new Date().getTime();
	const newTimeout = (currentTime + (CONSTANTS.SESSION_TIMEOUT * 60 * 1000));
	let sessionData = window.sessionStorage.getItem(CONSTANTS.SESSION_DATA_KEY);
	sessionData = getObjectFromJSON(sessionData, null);

	if (extend === false || sessionData === null || (sessionData.timeout && sessionData.timeout < currentTime)) {
		sessionData = {
			id: generateSessionId(currentTime),
			timeout: newTimeout,
			count: 0
		};
	} else {
		sessionData.timeout = newTimeout;
		sessionData.count++;
	}
	saveSessionData(sessionData);
	return sessionData;
};

/**
 * Return current session id in sessionData
 * Pass through extend property
 *
 * @param extend true extend timeout; false new timeout
 * @returns {*}
 */
function getSessionId(extend) {
	// TODO: check for session on the url (passed in from the app)
	extend = typeof extend === 'boolean' ? extend : false;
	const sessionData = extend === false ? getSessionData(false) : getSessionData(true);
	return sessionData.id;
}

/**
 * Generic Library track call.  Sets up general properties, then calls trackEvent to
 * actually make the tracking call to mixpanel.
 *
 * @param event tracking event name
 * @param params tracking properties
 */
const libraryTrack = (event, params) => {
	event = typeof event !== 'undefined' ? event : 'content viewed';
	const storeState = store.getState();
	const subscriberId = storeState.globals.subscriberId;
	const readerId = storeState.globals.readerId;
	const trackingProperties = $.extend({
		"type": "",
		"location": "modular library",
		"stack": storeState.stack,
		"publisher name": storeState.publisher_name,
		"publication name": storeState.collectionTitle,
		"platform status": storeState.platformStatus,
		"industry": storeState.industry,
		"app access type": storeState.appAccessType,
		"sub management type": storeState.subManagementType,
		"publication type": storeState.publicationType,
		"platform": (storeState.app) ? "app" : "web",
		"touch device": isTouchDevice(),
		"privacy banner" : $("#privacy-policy-alert").is(":visible"),
		"extendSession": true,
		"viewport width": window.innerWidth,
		"viewport height": window.innerHeight
	}, params);
	trackingProperties.session = getSessionId(trackingProperties.extendSession);
	if (!isEmpty(readerId)) {
		trackingProperties["reader id"] = readerId;
	} else if (!isEmpty(subscriberId)) {
		trackingProperties["reader id"] = subscriberId;
	} else if (window) {
		trackingProperties["reader id"] = window.distinct_id;
	}
	trackEvent(event, trackingProperties);
	callCustomTrackers({category: event, trackingProperties: trackingProperties});
};
export {libraryTrack};

/**
 * Call to mixpanel to track event.
 * Include mpdebug=true in url for mixpanel debug data in console
 *
 * @param event GTXcel mixpanel event string
 * @param trackingProperties GTXcel mixpanel properties for event
 */
const trackEvent = (event, trackingProperties) => {
	const debug = getUrlParam('debug', false);
	if (debug) {
		console.log("trackEvent", event, trackingProperties);
	}
	const mpdebug = getUrlParam('mpdebug', false);
	const storeState = store.getState();
	const subscriberId = storeState.globals.subscriberId;
	if (storeState.hasOwnProperty('mixpanelToken') && storeState.mixpanelToken !== null) {
		mixpanel.init(storeState.mixpanelToken, {
			loaded: function() {
				window.distinct_id = mixpanel.get_distinct_id();
			},
			debug: mpdebug}
		);
		if (subscriberId) {
			mixpanel.identify(subscriberId);
		}
		mixpanel.track(event, trackingProperties);
		if (storeState.hasOwnProperty('publisher_mixpanel_token') && storeState.publisher_mixpanel_token && storeState.publisher_mixpanel_token !== 'false') {
			mixpanel.init(storeState.publisher_mixpanel_token, {
				loaded: function() {
					window.distinct_id = mixpanel.get_distinct_id();
				},
				debug: mpdebug
			},"publisher");
			mixpanel.publisher.track(event, trackingProperties);
		}
		if (storeState.hasOwnProperty('gtxcelMixpanelToken') && storeState.gtxcelMixpanelToken !== null) {
			mixpanel.init(storeState.gtxcelMixpanelToken, {
				loaded: function() {
					window.distinct_id = mixpanel.get_distinct_id();
				},
				debug: mpdebug
			},"gtxcel");
			mixpanel.gtxcel.track(event, trackingProperties);
		}
	} else {
		console.error('No Mixpanel Token');
	}
};
export {trackEvent};
