import React, {useRef, useState} from 'react';
import {connect} from "react-redux";
import {ARTICLE_VIEWER} from "../../_MODULE_GLOBALS/constants";
import {isTrue, isEmpty} from "../../../utils/generalUtils";
import {GenerateReactShareButton} from "./shareButtonWidget";
import {GenerateNativeShareButton} from "./shareButtonWidget";
import {displayOnDevice} from "../../../utils/moduleSetup";
import {addClass} from "../../../utils/generateClassName";
import getDeviceType from "../../../utils/getDeviceType";
import {clone} from "../../../utils/objectUtils";
import {Translate, translatedString} from "../../../locales/locales";


/**
 * Social Media Share Button support provided by two different package
 *     https://www.npmjs.com/package/react-share
 *
 * The "react-share" package comes out of Europe, so some of the 20+ social media
 * sites supported are a little odd.
 *
 * For CH, we will support the following from "react-share"
 *
 *     Major Social-media platforms
 *         Facebook
 *         Twitter
 *         Whatsapp
 *         LinkedIn
 *         Pinterest
 *         Tumblr
 *         Reddit
 *
 *     Secondary Social-media platforms
 *         Telegram
 *         Facebook Messenger
 *         Viber
 *         Weibo
 *
 *     Additional Button support
 *         Email
 *         Print
 *
 *     Not currently supported; maybe with enough interest...
 *         Discord
 *         Slack
 *         Instagram
 *         Google
 *
 */


/**
 * Type of share is dependent on whether we are in the app or a mobile platform.
 * Default is "web" if none of those.
 *
 * @param params
 *     isApp: app=true set in url
 *     platform: platform set in url
 * @returns {{isApp: boolean, type: string, platform: (*|string)}}
 */
const getShareType = (params) => {
	params = Object.assign({
		isApp: false,
		platform: 'web'
	}, params);
	const isApp = isTrue(params.isApp, {defaultValue: false});
	const platform = !isEmpty(params.platform) ? params.platform : "web";

	let shareType = 'web';  // default
	if ('ontouchstart' in document.documentElement && typeof navigator.share === 'function') {
		shareType = 'Navigator';
	} else if (isApp && (platform === 'ios' || platform === 'android')) {
		shareType = 'App';
	}
	return {type: shareType, isApp: isApp, platform: platform};
};

/**
 * Toggle the display/visibility of the Share buttons block; dependent on the current state
 *
 * @param shareElement share buttons block DOM element
 */
const toggleShareButtons = (shareElement) => {
	if (isEmpty(shareElement)) {
		return;
	}
	if (shareElement.classList.contains('hidden')) {
		shareElement.classList.remove('hidden');
	} else {
		shareElement.classList.add('hidden');
	}
};

/**
 * Depending on configuration (usually for smaller devices), generate the single-button Share
 * button.  This button will toggle the visibility of the share buttons block.
 *
 * @param params
 *     shareButtonsElement DOM element for the share buttons blocl
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateSingleButton = (params) => {
	params = Object.assign({
		shareButtonsElement: {}
	}, params);
	const shareElement = !isEmpty(params.shareButtonsElement.current) ? params.shareButtonsElement.current : null;

	const shareI18nId = "share.mobileShare.label";
	let label = translatedString({id: shareI18nId});
	label = label !== shareI18nId ? label : "share";
	const titleI18nId = "share.mobileShare.buttonTitle";
	let title = translatedString({id: titleI18nId});
	title = title !== titleI18nId ? title : "share";

	return (
		<div className={'single-share-button'}>
			<button aria-label={label} className={"share-button"} onClick={(evt) => toggleShareButtons(shareElement)}>
				<span title={title} className={"font-awesome-icons mobile-share-button-icon"} aria-hidden={"true"} />
			</button>
		</div>
	);
};

/**
 * Generate the share buttons.
 *
 * If web, generate a block of share buttons.  In addition, if configured for small devices,
 * generate a single Share button to show/hide the share buttons block.
 *
 * If not web, then call to generate navigator/mobile browser-specific buttons
 *
 * @param params
 *     props: module props
 * @returns {JSX.Element|null}
 * @constructor
 */
const GenerateShare = (params) => {
	params = Object.assign({
		props: {}
	}, params);
	const props = clone(params.props);

	const shareButtons = !isEmpty(props.shareButtons) ? props.shareButtons : [];
	const currentDevice = getDeviceType();
	const createSingleButton = props.singleButtonDevices.includes(currentDevice);
	const shareType = getShareType({isApp: props.isApp, platform: props.platform});
	const descriptionPrefix = Translate.Text({id:'share.descriptionPrefix.title'});

	const buttonParams = {
		shareUrl: props.shareUrl,
		description: !isEmpty(props.summary) ? props.summary : Translate.Text({id:'share.descriptionPrefix.noSummary'}),
		emailIncludeDescription: true,
		documentTitle: props.documentTitle,
		shareType: shareType,
	};

	if (!isEmpty(props.articleId)) {
		buttonParams.articleId = props.articleId;
		buttonParams.articleTitle = props.articleTitle;
		buttonParams.issueName = props.issueName;
		buttonParams.category = props.category;
	}
	if (!isEmpty(props.folio)) {
		buttonParams.folio = props.folio;
		buttonParams.issueName = props.issueName;
		buttonParams.emailIncludeDescription = false;
	}

	const shareButtonsElement = useRef(null);
	const wrapperClassName = addClass("replica-viewer-controls react-share-buttons", createSingleButton ? 'single' : '');
	const shareClassName = addClass("share-buttons", createSingleButton ? 'hidden' : '');

	if (!isEmpty(shareButtons) && shareType.type === 'web') {
		return (
			<div className={wrapperClassName}>
				{createSingleButton ?
					<GenerateSingleButton shareButtonsElement={shareButtonsElement} />
					: null}
				<div className={shareClassName} ref={shareButtonsElement}>
					{shareButtons.map(buttonName => {
						return <GenerateReactShareButton key={buttonName} buttonName={buttonName} {...buttonParams} />;
					})}
				</div>
			</div>
		);
	} else if (shareType.type === 'Navigator' || shareType.type === 'App') {
		return (
			<div className={'single-share-button'}>
				<GenerateNativeShareButton {...buttonParams} />
			</div>
		);
	} else {
		return null;
	}
};


/**
 * Start of jsx code for module
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ReactShareButtonsModule = (props) => {
	if (displayOnDevice(props)) {
		return <GenerateShare props={props} />;
	} else {
		return null;
	}
};


/**
 * Map state (store) data for the shareButtons module; added to module props.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{key: Value}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = props.storageKey ? props.storageKey : ARTICLE_VIEWER;
	const storeState = state[storageKey] ? state[storageKey] : {};
	const globalState = state.globals ? state.globals : {};

	const shareButtonsProps = {
		storageKey: storageKey,
		shareButtons: !isEmpty(props.shareButtons) ? props.shareButtons : [],
		singleButtonDevices: !isEmpty(props.singleButtonDevices) ? props.singleButtonDevices : [],
		isApp: isTrue(state.app, {defaultValue: false}),
		platform: !isEmpty(state.platform) ? state.platform : "web",
		shareUrl: !isEmpty(globalState.share) && !isEmpty(globalState.share.href) ? globalState.share.href : window.location.href.replace(window.location.search,''),  // strip query parameters
		summary: !isEmpty(globalState.share) && !isEmpty(globalState.share.summary) ? globalState.share.summary : '',
		articleId: !isEmpty(globalState.share) && !isEmpty(globalState.share.articleId) ? globalState.share.articleId : '',
		articleTitle: !isEmpty(globalState.share) && !isEmpty(globalState.share.articleTitle) ? globalState.share.articleTitle : '',
		issueName: !isEmpty(globalState.share) && !isEmpty(globalState.share.issueName) ? globalState.share.issueName : '',
		category: !isEmpty(globalState.share) && !isEmpty(globalState.share.category) ? globalState.share.category : [],
		folio: !isEmpty(globalState.share) && !isEmpty(globalState.share.folio) ? globalState.share.folio : [],
		documentTitle: !isEmpty(globalState.documentTitle) ? globalState.documentTitle : document.title
	};
	return shareButtonsProps;
};

export default connect(
	mapStateToProps,
	null
)(ReactShareButtonsModule);
