import React, {useEffect, useState} from 'react';
import {
	ARTICLE_ZOOM_STEP_LEVEL, DEFAULT_ARTICLE_ZOOM_LEVEL,
	ID_NOT_SET,
	MAX_ARTICLE_ZOOM_LEVEL,
	MIN_ARTICLE_ZOOM_LEVEL
} from "../../_MODULE_GLOBALS/constants";
import {getFloatValue, isEmpty} from "../../../utils/generalUtils";
import {manageUpdate} from "../../globals/globalNavigator";
import {addClass} from "../../../utils/generateClassName";
import {Translate} from "../../../locales/locales";
import * as Tracking from "../../../utils/tracking";



/**
 * Handle click on zoom button.
 * Call tracking, then call to update store attributes related to zoom level.
 *
 * @param params
 *     evt: click event
 *     zoomSize: zoom size value from +/- click
 *     moduleProps: props
 */
const updateZoom = (params) => {
	params = Object.assign({
		evt: null,
		zoomSize: 1,
		moduleProps: {}
	}, params);

	const updateParams = {
		navigationKey: !isEmpty(params.moduleProps.navigationKey) ? params.moduleProps.navigationKey : 'article',
		navigationKeys: !isEmpty(params.moduleProps.navigationKeys) ? params.moduleProps.navigationKeys : {},
		attributes: {
			zoomLevel: params.zoomSize
		},
	};

	const storeState = !isEmpty(params.moduleProps.storeState) ? params.moduleProps.storeState : {};
	const trackingProperties = {
		articleId: !isEmpty(storeState.articleId) ? storeState.articleId : ID_NOT_SET,
		label: params.moduleProps.trackingLabel,
		location: "article viewer",
		module: "article zoom",
		type: "click",
		"url": (window ? window.location.href : ''),
	};
	Tracking.libraryTrack("button clicked",trackingProperties);

	manageUpdate(updateParams);
};


/**
 * Article zoom button(s) based on the responsiveButton module.
 *
 * @param props module props passed from responsiveButton module
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleZoom = (props) => {
	const moduleProps = Object.assign({
		GeneratedTag: `button`,
		className: '',
		title: '',
		label: '',
		navigationKey: '',
		navigationKeys: {},
		storeState: {},
		storageKey: 'articleViewer'
	}, props);

	const storeState = !isEmpty(moduleProps.storeState) ? moduleProps.storeState : {};
	// keep track of page zoom level (1 (default); up to 2)
	const [zoomSize, setZoomSize] = useState(DEFAULT_ARTICLE_ZOOM_LEVEL);

	const className = addClass('article-viewer-control article-zoom', moduleProps.className);
	const zoomLevel = getFloatValue(storeState.zoomLevel, zoomSize);

	/**
	 * If zoomLevel size changes, update the state value.
	 * This can happen through click on the button or from initialization
	 * data update from articleViewer
	 *
	 */
	useEffect(() => {
		if (zoomSize !== zoomLevel) {
			setZoomSize(zoomLevel);
		}
	}, [zoomLevel, zoomSize]);

	const zoomConstants = {
		zoomMax: !isEmpty(storeState.zoomMax) ? storeState.zoomMax : MAX_ARTICLE_ZOOM_LEVEL,
		zoomMin: !isEmpty(storeState.zoomMin) ? storeState.zoomMin : MIN_ARTICLE_ZOOM_LEVEL,
		zoomStep: !isEmpty(storeState.zoomStep) ? storeState.zoomStep : ARTICLE_ZOOM_STEP_LEVEL,
		label: Translate.Text({'id': 'actionButton.zoomButton.ariaLabel'}),
		zoomInTitle: Translate.Text({'id': 'actionButton.zoomButton.zoomIn'}),
		zoomOutTitle: Translate.Text({'id': 'actionButton.zoomButton.zoomOut'}),
	};
	const zoomClass = {
		zoomIn: 'zoom-plus',
		zoomOutTitle: zoomConstants.zoomOutTitle,
		zoomOut: 'zoom-minus',
		zoomInTitle: zoomConstants.zoomInTitle
	};
	if (zoomSize <= zoomConstants.zoomMin) {
		zoomClass.zoomOut = 'zoom-minus disabled';
		zoomClass.zoomOutTitle = '';
	}
	if (zoomSize >= zoomConstants.zoomMax) {
		zoomClass.zoomIn = 'zoom-plus disabled';
		zoomClass.zoomInTitle = '';
	}

	return (
		<div className={className}>
			<span className={"fal fa-text-size zoom-text-size-icon"} aria-label={zoomConstants.label} />
			<button
				className={zoomClass.zoomOut}
				title={zoomClass.zoomOutTitle} aria-label={zoomClass.zoomOutTitle}
				onClick={(evt) => {
					const newZoomSize = zoomSize - zoomConstants.zoomStep;
					if (newZoomSize >= zoomConstants.zoomMin) {
						moduleProps.trackingLabel = 'Zoom Out';
						setZoomSize(newZoomSize);
						updateZoom({evt: evt, zoomSize: newZoomSize, moduleProps: moduleProps});
					}
				}}
			>
				<span className={'fal fa-minus-circle'} />
			</button>
			<button
				className={zoomClass.zoomIn}
				title={zoomClass.zoomInTitle} aria-label={zoomClass.zoomInTitle}
				onClick={(evt) => {
					const newZoomSize = zoomSize + zoomConstants.zoomStep;
					if (newZoomSize <= zoomConstants.zoomMax) {
						moduleProps.trackingLabel = 'Zoom In';
						setZoomSize(newZoomSize);
						updateZoom({evt: evt, zoomSize: newZoomSize, moduleProps: moduleProps});
					}
				}}
			>
				<span className={'fas fa-plus-circle'} />
			</button>
		</div>
	);
};
export {ArticleZoom};

