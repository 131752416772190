import $ from 'jquery';
import deepmerge from "deepmerge";
import {clone} from "../../utils/objectUtils";
import {getStoreValue} from "../../utils/storeValue";
import getDeviceType from "../../utils/getDeviceType";
import addDebounceListener from "../../utils/addDebounceListener";
import debounce from "debounce";
import {getContainerName, getScroll} from "./helperFunctions";
import ResizeObserver from 'resize-observer-polyfill';
import {
	getLeafValue,
	getValueByPartialKey,
	isPlainObject,
	objectHasLeaf
} from "../../utils/objectUtils";
import {arrayRemoveDups, arraysConcatNoDup} from "../../utils/arrayUtils";
import {isTrue, isEmpty} from "../../utils/generalUtils";
import {GLOBALS} from "../_MODULE_GLOBALS/constants";


/**
 * globalLibraryData function
 *
 * React to global changes to the Library.
 * Currently, this includes
 *     resize: change in browser
 *     scroll: either document or container
 * This can be extended to include module "registration" to capture changes to
 * a registered module.
 *
 * Global values are used to
 *     add classes to the root
 *     add classes to the structure element
 *     add css variables to the root
 *
 * TODO (potential future enhancements)
 *     use global variables to funnel all tracking calls here
 *     add other event type detection
 */

// globalData object is defined locally and not visible outside this file
let globalData = {};
let resizeObserver = null;


/**
 * Loop through structures/panes/containers and setup the structure
 * for the dataObject so that we don't need to continually check if
 * the dataObject has the format we need.
 *
 * @param dataObject object to add attributes
 *     sizeData
 *     scrollData
 * @param $containers jQuery object for containers to loop through
 */
const updateDataObjectContainerStructure = (dataObject, $containers) => {
	$containers = typeof $containers !== 'undefined' ? $containers : $('.container');
	$containers.each(function(index, container) {
		const $container = $(container);
		if ($container.children().length > 0) {
			let containerName = getContainerName({container: container, removeClass: ['container', 'layout-block']});
			const $structure = $container.parents('.structure');
			if ($structure.length > 0) {
				const structureName = $structure.attr('id');
				if (!dataObject.hasOwnProperty(structureName)) {
					dataObject[structureName] = {};
				}
				const $pane = $container.parents('.pane');
				if ($pane.length > 0) {
					//"normal" containers, those inside a structure/pane
					const paneName = $pane.attr('id');
					if (!dataObject[structureName].hasOwnProperty(paneName)) {
						dataObject[structureName][paneName] = {};
					}
					if (!dataObject[structureName][paneName].hasOwnProperty(containerName)) {
						dataObject[structureName][paneName][containerName] = {};
					}
				} else {
					// containers inside a structure but not in a pane
					if (!dataObject[structureName].hasOwnProperty(containerName)) {
						dataObject[structureName][containerName] = {};
					}
				}
			} else {
				// containers outside structures; "global" containers
				containerName = container.id;
				if (!dataObject.hasOwnProperty('global')) {
					dataObject.global = {};
				}
				if (!dataObject.global.hasOwnProperty(containerName)) {
					dataObject.global[containerName] = {};
				}
			}
		}
	});
};

/**
 * Merge in the new data passed in into the current globalData object.  Do NOT return anything,
 * just update.  We do a minimal check to see that it passes the jQuery object definition, but
 * don not check the format of the newData object.
 *
 * @param newData object containing new data to merge into current globalData
 * @param options override options for update
 */
const updateGlobalData = (newData, options) => {
	options = Object.assign({
		shallow: false,
	}, options);
	if (isPlainObject(newData)) {
		if (isTrue(options.shallow, {defaultValue: false})) {
			globalData = Object.assign(globalData, newData);
		} else {
			globalData = deepmerge(globalData, newData);
		}
	} else {
//		console.log('illegal data');
	}
};

/**
 * Return a clone of the globalObject.  This ensures that the caller cannot inadvertently modify
 * the contents of the global object.
 *
 * @returns {null|*} clone of globalData
 */
const getGlobalDataObject = () => {
	return clone(globalData);
};

/**
 * Get a top-level attribute from the globalObj.  To be safe, get the value from a clone of
 * the globalData object to make sure the caller can't get something it could accidentally
 * overwrite.
 *
 * In many cases, we want to only allow simple values to be returned, no object or array values.
 *     string
 *     number
 *     boolean
 *
 * @param params
 *     attr top-level attribute key
 *     simple: true: check and return only simple types
 *     defaultValue (optional) return value if no data
 */
const getGlobalDataValue = (params) => {
	params = Object.assign({
		attr: '',
		simple: true,
		defaultValue: null,
		clone: false
	}, params);
	const defaultValue = typeof params.defaultValue !== 'undefined' ? params.defaultValue : null;
	// get clone of store global object
	const globalObj = getGlobalDataObject();
	const returnValue = globalObj.hasOwnProperty(params.attr) ? globalObj[params.attr] : defaultValue;
	if (isTrue(params.simple)) {
		return (typeof returnValue === 'string' || typeof returnValue === 'number' || typeof returnValue === 'boolean') ? returnValue : defaultValue;
	} else {
		return !isTrue(params.clone) ? returnValue : clone(returnValue);
	}
};
export {getGlobalDataValue};


/**
 * Call to set a specific store globals value.
 * Optionally, call updateData after setting the store value to trigger
 * updates in modules
 *
 * @param params
 *     attributeKey: value's key in store globals property
 * @param value value to set
 * @param options
 *     callUpdateData: boolean option to call to updateData
 */
const setGlobalDataValue = (params, value, options) => {
	params = Object.assign({
		attributeKey: '',
	}, params);
	options = Object.assign({
		callUpdateData: false,
		shallow: false
	}, options);
	value = typeof value !== 'undefined' ? value : '';

	if (!isEmpty(params.attributeKey)) {
		updateGlobalData({[params.attributeKey]: value}, options);
		if (isTrue(options.callUpdateData)) {
			const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
			updateData({[params.attributeKey]: value}, {type: GLOBALS, storageKey: 'globals'});
		}
	}
};
export {setGlobalDataValue};


/**
 * Provide a consistent way to get the scroll percentage of the browser and all the current containers
 * and return the values in an object similar to the globalData object.  The globalData object is not
 * updated until after this call, and usually by the caller.
 *
 * The values are current at the time of the call, and can be compared to the existing values in
 * the globalData object
 *
 * @param scrollTarget
 *     null: return values for the browser and all containers
 *     document: return the value for the browser only
 *     container: return the value for a single container
 *
 * @returns {{}}
 */
const generateScrollData = (scrollTarget) => {
	let $containers = $();  // empty if not set later
	let scrollData = {};

	if (scrollTarget === null) {
		// generate content for browser; set $container to all containers
		scrollData = {
			browser: {
				scroll: getScroll({target: document, type:'percentage'})
			}
		};
		$containers = $('.container');
	} else {
		// for specific target; generate content for document or set $container to scrollTarget parent container
		if (scrollTarget === document) {
			// generate content specific for the document
			scrollData = {
				browser: {
					scroll: getScroll({target: document, type:'percentage'})
				}
			};
		} else {
			$containers = $(scrollTarget).closest('.container');
		}
	}

	updateDataObjectContainerStructure(scrollData, $containers);
	// loop through container(s) and set scroll value
	$containers.each(function(index, container) {
		const $container = $(container);
		if ($container.children().length > 0) {
			let containerName = getContainerName({container: container, removeClass: ['container', 'layout-block']});
			const $structure = $container.parents('.structure');
			if ($structure.length > 0) {
				const structureName = $structure.attr('id');
				const $pane = $container.parents('.pane');
				if ($pane.length > 0) {
					// capture data for "normal" containers, those inside a structure/pane
					const paneName = $pane.attr('id');
					scrollData[structureName][paneName][containerName].scroll = getScroll({
						target: container,
						type: 'percentage'
					});
				} else {
					// capture data for containers inside a structure but not in a pane
					scrollData[structureName][containerName].scroll = getScroll({
						target: container,
						type: 'percentage'
					});
				}
			} else {
				// capture data for containers outside structures; "global" containers
				containerName = container.id;
				scrollData.global[containerName].scroll = getScroll({target: container, type: 'percentage'});
			}
		}
	});

	return scrollData;
};

/**
 * Setup conditions for reacting to and storing global changes to the Library.
 * These include changes in structure, panes, modules
 *
 * The first thing that is done is to setup and store initial size and scroll information
 * This is likely to be incorrect incorrect for size of most containers, as their size
 * will likely update as they get content.
 * In that case, resize and/or scroll events will be detected and the information for
 * containers will be updated then.
 *
 */
const setInitialGlobalData = () => {
	const scrollData = generateScrollData(null);
	updateGlobalData(scrollData);

	addDebounceListener({
		eventName: "resize",
		target: window,
		timeout: 500,
		fn: function(event) {
			updateResizeData(event);
		}
	});

	addDebounceListener({
		eventName: "scroll",
		target: document,
		timeout: 100,
		capture: true,
		fn: function(event) {
			updateScrollData(event);
		}
	});

	setupContainerResizeCapture();
};
export {setInitialGlobalData};

/**
 * When something happens to the ContentHub, ie. change in navigation, pane, structure,
 * update global values.
 *
 */
const updateOnNavigationChange = (params) => {
	params = Object.assign({
		currentPane: '',
		previousCurrentPane: ''
	}, params);
	const {currentPane, previousCurrentPane} = params;
	const currentStructure = getStoreValue({attributeKey: 'currentStructure'});
	const structures = getStoreValue({attributeKey: 'structures'}) || {};

	// check if the new pane value is different from what we stored previously
	if (previousCurrentPane !== currentPane) {
		const $structure = $('.structure');
		const paneDef = structures[currentStructure].structurePanes.find(paneInfo => paneInfo.paneName === currentPane);
		const dataClassName = paneDef.hasOwnProperty('className') ? paneDef.className : '';
		const paneDisplay = structures[currentStructure].paneDisplay.find(paneDisp => paneDisp.id === currentPane);
		const dataPaneLabel = paneDisplay.hasOwnProperty('label') ? paneDisplay.label : '';
		$structure.attr('data-current-pane', currentPane);
		$structure.attr('data-current-pane-class', dataClassName);
		$structure.attr('data-current-pane-label', dataPaneLabel);
	}

	// store these new values in case we want to detect and respond to a change
	updateGlobalData({
		currentPane: currentPane,
		currentStructure: currentStructure,
		device: getDeviceType()
	});

	setupContainerResizeCapture();
	updateResizeData(null);
	updateScrollData(null);
};
export {updateOnNavigationChange};


/**
 * Setup the ResizeObserver to listen for changes in visible .container elements.
 * If the instance does not exist, create it; otherwise, remove listeners (disconnect)
 * for all current elements and re-add.
 *
 * Use debounce to throttle changes of container size, although it probably isn't
 * required, so set the value relatively low (100ms).
 *
 */
const setupContainerResizeCapture = () => {
	const currentStructure = getStoreValue({attributeKey: 'currentStructure'});
	const currentPane = getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''});

	// throttle/debounce: returns a function; pass function to ResizeObserver instantiation
	const containerResizeDebounce = debounce(
		function(entries) {
			updateResizeData();
		}, 100);

	if (resizeObserver === null) {
		resizeObserver = new ResizeObserver(containerResizeDebounce);
	} else {
		resizeObserver.disconnect();
	}

	$('.container').each(function(index, container) {
		let containerName = getContainerName({container: container, removeClass: ['container', 'layout-block']});
		if (containerName.includes('container-global')) {
			resizeObserver.observe(container);
		} else {
			// check if container is visible
			const $structure = $(container).parents('.structure');
			if ($structure.length > 0) {
				const structureName = $structure.attr('id');
				const paneName = $(container).parents('.pane').length > 0 ? $(container).parents('.pane').attr('id'): null;
				if (structureName === currentStructure) {
					// set cssVar for containers in current pane or outside pane but inside structure
					if (paneName === currentPane || paneName === null) {
						resizeObserver.observe(container);
					}
				}
			}
		}
	});
};
export {setupContainerResizeCapture};


/**
 * Update the global size variables and css on a resize event.
 *
 * Note: This will trigger on startup without an user-initiated browser resize and
 * applies to all containers and browser to set size variables.
 *
 * Note2: We want to remove the old --size data, since the cssVar name is not structure
 * and pane specific.  This likely won't be a problem, but we want to be sure.  To
 * enable this, collect --size variables into an array; after this, remove old --size
 * variables, then add the new ones to the style attribute.
 *
 * Setting values in css variables
 *     browser height/width
 *         --browser-height
 *         --browser-width
 *     "global" containers
 *         --global_<container-name>-height
 *         --global_<container-name>-width
 *     all other containers
 *         --size_<container-name>-height
 *         --size_<container-name>-width
 *
 * @param event resize event
 */
const updateResizeData = (event) => {
	const currentStructure = getStoreValue({attributeKey: 'currentStructure'});
	const currentPane = getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''});

	const $App = $('#App');

	// set var for size of browser
	const browserSizeData = setSizeData({componentName: 'browser'});
	const browserSize = browserSizeData.size;
	$App.css({
		'--browser-height': browserSize.height,
		'--browser-width': browserSize.width
	});

	// collect new size cssVars
	const containerSizeVars = [];
	$('.container').each(function(index, container) {
		let containerName = getContainerName({container: container, removeClass: ['container', 'layout-block']});
		if (containerName.includes('container-global')) {
			// set var for size of "global" containers
			containerName = $(container).attr('id');
			const containerSizeData = setSizeData({structure: 'global', pane: null, componentName: containerName, component: container});
			const containerSize = containerSizeData.size;
			const cssVarName = '--global_' + containerName;
			containerSizeVars.push(cssVarName + '-height:' + containerSize.height);
			containerSizeVars.push(cssVarName + '-width:' + containerSize.width);
		} else {
			// set var for size of structure containers; if not in a pane, set as part of structure
			// note: only container-global should be outside structure, but check anyway
			const $structure = $(container).parents('.structure');
			if ($structure.length > 0) {
				const structureName = $structure.attr('id');
				const paneName = $(container).parents('.pane').length > 0 ? $(container).parents('.pane').attr('id'): null;
				if (structureName === currentStructure) {
					// set cssVar for containers in current pane or outside pane but inside structure
					if (paneName === currentPane || paneName === null) {
						const containerSizeData = setSizeData({structure: structureName, pane: paneName, componentName: containerName, component: container});
						const containerSize = containerSizeData.size;
						const cssVarName = '--size_' + containerName;
						containerSizeVars.push(cssVarName + '-height:' + containerSize.height);
						containerSizeVars.push(cssVarName + '-width:' + containerSize.width);
					}
				}
			}
		}
	});
	$('.layout-block').each(function(index, container) {
		let containerName = getContainerName({container: container, removeClass: ['container', 'layout-block']});
		// set var for size of structure containers; if not in a pane, set as part of structure
		// note: only container-global should be outside structure, but check anyway
		const $structure = $(container).parents('.structure');
		if ($structure.length > 0) {
			const structureName = $structure.attr('id');
			const paneName = $(container).parents('.pane').length > 0 ? $(container).parents('.pane').attr('id'): null;
			if (structureName === currentStructure) {
				// set cssVar for containers in current pane or outside pane but inside structure
				if (paneName === currentPane || paneName === null) {
					const containerSizeData = setSizeData({structure: structureName, pane: paneName, componentName: containerName, component: container});
					const containerSize = containerSizeData.size;
					const cssVarName = '--size_' + containerName;
					containerSizeVars.push(cssVarName + '-height:' + containerSize.height);
					containerSizeVars.push(cssVarName + '-width:' + containerSize.width);
				}
			}
		}
	});

	// remove old "--size_" cssVars from style attributes, add new ones, then update style attribute
	const styleAttributes = !isEmpty($App.attr('style')) ? $App.attr('style').split(';') : [];
	const styleNoSizeAttributes = arrayRemoveDups(styleAttributes.filter(styleAttr => !styleAttr.includes('--size_')), true);
	const finalAttributes = arraysConcatNoDup(styleNoSizeAttributes, containerSizeVars).join(';');
	$App.attr('style', finalAttributes);

	// set var for size of (scrollable) content area; do this last, as it may need other vars set first
	const contentElement = document.getElementById('App');
	const contentSizeData = setSizeData({structure: currentStructure, pane: currentPane, componentName: 'content', component: contentElement});
	const contentSize = contentSizeData.size;
	if (contentSizeData.sizeChanged) {
		$App.css({
			'--content-height': contentSize.height,
			'--content-width': contentSize.width
		});
	}
};

/**
 * Update the global variables and css on a scroll event.
 *
 * If we have an explicit scroll event, then we can get the target for a specific container.
 * If the event is null, then it is an initialization event and we set values for all containers.
 *
 * Note: a scroll of 0 will not set a scroll var for the container or browser
 *
 * Set scrollTop values in css variables
 *     --documen-scroll
 *     --scroll_container
 * Set a class if document or container is scrolled and the scroll direction (up/down)
 *     scroll-up/scroll-down
 *     no class if scrolled to the top
 *
 * @param event scroll event; null is initialization
 */
const updateScrollData = (event) => {
	const currentStructure = getStoreValue({attributeKey: 'currentStructure'});
	const currentPane = getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''});

	const $App = $('#App');

	if (event === null) {
		// remove "--scroll" cssVars before looping through containers, so the list will always be current
		if ($App[0].hasAttribute('style')) {
			const styleAttributes = $App.attr('style')
				.split(' ')
				.filter(styleAttr => !styleAttr.includes('--scroll_'))
				.join(' ');
			$App.attr('style', styleAttributes);
		}

		// set scroll var for all containers
		const browserScroll = setScrollData({componentName: 'browser', component: document, structure: null, pane: null});
		$App.css({'--document-scroll': browserScroll.scrollData.scrollTop});
		if (browserScroll.scrollDirection !== 'no-change') {
			// remove base scroll classes to reset class list
			$App.removeClass('scroll-up scroll-down scroll-top no-change');
			$App.addClass(browserScroll.scrollDirection);
		}
		$('.container').each(function(index, container) {
			let containerName = getContainerName({container: container, removeClass: ['container', 'layout-block']});
			if (containerName.includes('container-global')) {
				// check if the container is a "global" container
				containerName = $(container).attr('id');
				const containerScroll = setScrollData({componentName: containerName, component: container, structure: 'global', pane: null});
				if (containerScroll !== null) {
					const containerScrollDataAttr = '--scroll_' + containerName;
					$App.css(containerScrollDataAttr, containerScroll.scrollData.scrollTop);
				}
			} else {
				// otherwise, the container is contained within a structure; if pane is null, then it is outside a pane
				// note: only container-global should be outside structure, so structureName should never be null
				const $structure = $(container).parents('.structure');
				if ($structure.length > 0) {
					const structureName = $structure.attr('id');
					const paneName = $(container).parents('.pane').length > 0 ? $(container).parents('.pane').attr('id') : null;
					if (structureName === currentStructure) {
						// set cssVar for containers in current pane or outside pane but inside structure
						if (paneName === currentPane || paneName === null) {
							const containerScroll = setScrollData({
								componentName: containerName,
								component: container,
								structure: 'global',
								pane: paneName
							});
							if (containerScroll !== null) {
								const containerScrollDataAttr = '--scroll_' + containerName;
								$App.css(containerScrollDataAttr, containerScroll.scrollData.scrollTop);
							}
						}
					}
				}
			}
		});
	} else {
		// get the scroll data for a specific scroll target element
		const scrollTarget = event.target;
		if (scrollTarget === document) {
			const containerScroll = setScrollData({componentName: 'browser', component: document, structure: null, pane: null});
			$App.css({'--document-scroll': containerScroll.scrollData.scrollTop});
			if (containerScroll.scrollDirection !== 'no-change') {
				// remove base scroll classes to reset class list
				$App.removeClass('scroll-up scroll-down scroll-top no-change');
				$App.addClass(containerScroll.scrollDirection);
			}
		} else {
			let containerName = getContainerName({container: scrollTarget, removeClass: ['container', 'layout-block']});
			let structureName;
			let paneName;
			if (containerName.includes('container-global')) {
				// check if the container is a "global" container
				containerName = $(scrollTarget).attr('id');
				structureName = 'global';
				paneName = null;
			} else {
				// otherwise, the container is contained within a structure; if pane is null, then it is outside a pane
				// note: only container-global should be outside structure, so structureName should never be null
				structureName = $(scrollTarget).parents('.structure').length > 0 ? $(scrollTarget).parents('.structure').attr('id'): null;
				paneName = $(scrollTarget).parents('.pane').length > 0 ? $(scrollTarget).parents('.pane').attr('id'): null;
			}
			const containerScrollDataAttr = '--scroll_' + containerName;
			const containerScroll = setScrollData({componentName: containerName, component: scrollTarget, structure: structureName, pane: paneName});
			if (containerScroll !== null) {
				$App.css(containerScrollDataAttr, containerScroll.scrollData.scrollTop);
				if (containerScroll.scrollDirection !== 'no-change') {
					// remove base scroll classes to reset class list
					$App.removeClass('scroll-up scroll-down scroll-top no-change');
					$App.addClass(containerScroll.scrollDirection);
				}
			} else {
				$App.css(containerScrollDataAttr, "");
			}
		}
	}
};

/**
 * Check the globalData variable to see if it has an object structure matching the parameters.
 * If not, add the attribute.
 *
 * @param params
 *     componentName: usually the container name; "browser" is a special, top-level param
 *         in the "normal" case, under structure/pane
 *     structure: structure name for top-level
 *         note: "global" is a special structure name
 *     pane: pane under structure if it is not null
 *         if null, generate structure and component with no pane
 */
const updateGlobalDataStructure = (params) => {
	params = Object.assign({
		componentName: 'browser',
		structure: getStoreValue({attributeKey: 'currentStructure'}),
		pane: getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''}),
	}, params);

	if (params.componentName === 'browser') {
		// set top-level attribute for browser
		if (!globalData.hasOwnProperty(params.componentName)) {
			globalData[params.componentName] = {};
		}
	} else {
		if (!globalData.hasOwnProperty(params.structure)) {
			globalData[params.structure] = {};
		}
		if (params.pane === null) {
			if (!globalData[params.structure].hasOwnProperty(params.componentName)) {
				globalData[params.structure][params.componentName] = {};
			}
		} else {
			if (!globalData[params.structure].hasOwnProperty(params.pane)) {
				globalData[params.structure][params.pane] = {};
			}
			if (!globalData[params.structure][params.pane].hasOwnProperty(params.componentName)) {
				globalData[params.structure][params.pane][params.componentName] = {};
			}
		}
	}
};


/**
 * Provide a consistent way of getting size data (height and width) from DOM objects,
 * setting them in the stored globalData object, then returning the values.
 *
 * Browser: height and width of the browser window
 * Content: height and width of the content area (usually bigger than the browser window)
 * Container: height and width of a container object.
 *     Containers may be of type
 *         global: outside the structure/pane framework
 *             store as "global" structure; no pane
 *         outside pane: inside a structure, but outside a pane
 *             store under structure; no pane
 *         in structure/pane: "normal" containers in both structure and pane
 *            store under structure/pane
 *
 * Note: ALL content (modules) must be added to a container.  Only browser size is stored
 * outside a container.
 * Note: The "content" attribute within a structure/pane is considered a special "container"
 *
 * @param params
 *     componentName: name of component, usually the container name
 *         special: "browser", "content"
 *     component: the actual DOM component
 *     structure: name, usually passed in, otherwise from global store as currentStructure
 *     pane: name, usually passed in, otherwise from global store as currentPane
 *
 * @returns {{sizeChanged: boolean, size: {width: number, height: number}}|{sizeChanged: boolean, size: {width: number, height: number}}}
 *     size: width and height values for element
 *     sizeChanged: whether the height or width changed
 */
const setSizeData = (params) => {
	params = Object.assign({
		componentName: 'browser',
		component: null,
		structure: getStoreValue({attributeKey: 'currentStructure'}),
		pane: getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''}),
	}, params);

	const zeroSize = {
		height: 0,
		width: 0
	};

	updateGlobalDataStructure(params);
	if (params.componentName === 'browser') {
		const size = {
			height: window.innerHeight,
			width: window.innerWidth
		};
		const sizeData = objectHasLeaf(globalData, [params.componentName, 'size']) ? globalData[params.componentName].size : zeroSize;
		const sizeChanged = (size.height !== sizeData.height || size.width !== sizeData.width);
		globalData[params.componentName].size = size;
		return {size: size, sizeChanged: sizeChanged};
	} else if (params.pane === null) {
		// capture data for containers inside a structure but not in a pane
		if ($(params.component).children().length > 0) {
			const size = {
//				height: params.component.offsetHeight,
//				width: params.component.offsetWidth
				height: $(params.component).outerHeight(true),
				width: $(params.component).outerWidth(true)
			};
			const sizeData = objectHasLeaf(globalData, [params.structure, params.componentName, 'size']) ? globalData[params.structure][params.componentName].size : zeroSize;
			const sizeChanged = (size.height !== sizeData.height || size.width !== sizeData.width);
			globalData[params.structure][params.componentName].size = size;
			return {size: size, sizeChanged: sizeChanged};
		} else {
			return {size: zeroSize, sizeChanged:false};
		}
	} else {
		// capture data for "normal" containers, those inside a structure/pane
		// special case 'content'
		if (params.componentName === 'content' || $(params.component).children().length > 0) {
			const size = {
//				height: params.component.offsetHeight,
//				width: params.component.offsetWidth
				height: $(params.component).outerHeight(true),
				width: $(params.component).outerWidth(true)
			};
			const sizeData = objectHasLeaf(globalData, [params.structure, params.pane, params.componentName, 'size']) ? globalData[params.structure][params.pane][params.componentName].size : zeroSize;
			const sizeChanged = (size.height !== sizeData.height || size.width !== sizeData.width);
			globalData[params.structure][params.pane][params.componentName].size = size;
			return {size: size, sizeChanged: sizeChanged};
		} else {
			return {size: zeroSize, sizeChanged:false};
		}
	}
};

/**
 * Look up and return stored scroll data in the globalDataObject
 *
 * @param params
 *     componentName: for lookup in the globalData object
 *     component: DOM component
 *     structure: may be passed in, otherwise from global store
 *     pane: may be passed in, otherwise from global store
 * @returns {{scroll: {scroll: number, scrollTop: number}}|*}
 */
const getStoredScrollData = (params) => {
	params = Object.assign({
		component: document,
		componentName: 'browser',
		structure: getStoreValue({attributeKey: 'currentStructure'}),
		pane: getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''}),

	}, params);

	const zeroScroll = {
		scroll: 0,
		scrollTop: 0
	};

	if (params.componentName === 'browser') {
		return globalData.hasOwnProperty('browser') && globalData.browser.hasOwnProperty('scroll') ? globalData.browser.scroll : zeroScroll;
	} else if (globalData.hasOwnProperty(params.structure)) {
		const structureData = globalData[params.structure];
		if (params.pane === null) {
			return structureData.hasOwnProperty(params.componentName) && structureData[params.componentName].hasOwnProperty('scroll') ? structureData[params.componentName].scroll : zeroScroll;
		} else {
			if (structureData.hasOwnProperty(params.pane)) {
				const paneData = structureData[params.pane];
				return paneData.hasOwnProperty(params.componentName) && paneData[params.componentName].hasOwnProperty('scroll') ? paneData[params.componentName].scroll : zeroScroll;
			}
		}
	}
	// no match or data; just return empty
	return zeroScroll;

};
export {getStoredScrollData};


/**
 * Provide a consistent way to set and get scroll data (scroll percentage and scrollTop) from the
 * stored globalData object, and to return scroll direction using previous scroll data.
 *
 * When this is called, it finds and stores scroll data, replacing the current scroll data.
 *
 * @param params
 *     componentName: for lookup in the globalData object
 *     component: DOM component
 *     structure: may be passed in, otherwise from global store
 *     pane: may be passed in, otherwise from global store
 * @returns {{scrollData: {scroll: number, scrollTop: number}, scrollDirection: (string)}|{scrollData: {scroll: number, scrollTop: *}, scrollDirection: (string)}|null}
 */
const setScrollData = (params) => {
	params = Object.assign({
		componentName: 'browser',
		component: null,
		structure: getStoreValue({attributeKey: 'currentStructure'}),
		pane: getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''}),
	}, params);

	const zeroScrollData = {
		scrollData: {
			scroll: 0,
			scrollTop: 0
		}
	};

	// look to see if scroll has changed, and if so, in which direction
	const getScrollDirection = (oldScrollData, newScrollData) => {
		if (newScrollData.scrollTop === 0) {
			return 'scroll-top';
		} else if (oldScrollData.scroll === newScrollData.scroll) {
			return 'no-change';
		} else if (oldScrollData.scroll > newScrollData.scroll) {
			return 'scroll-up';
		} else {
			return 'scroll-down';
		}
	};

	updateGlobalDataStructure(params);
	if (params.componentName === 'browser') {
		// generate content for browser
		const scrollData = {
			scroll: getScroll({target: document, type:'percentage'}),
			scrollTop: document.documentElement.scrollTop,
			scrollHeight: document.documentElement.scrollHeight
		};
		const oldScrollData = getStoredScrollData(params);
		const scrollDirection = getScrollDirection(oldScrollData, scrollData);
		globalData[params.componentName].scroll = scrollData;
		return {scrollData: scrollData, scrollDirection: scrollDirection};
	} else if (params.pane === null) {
		// capture data for containers inside a structure but not in a pane
		if ($(params.component).children().length > 0) {
			const scrollData = {
				scroll: getScroll({target: params.component, type:'percentage'}),
				scrollTop: params.component.scrollTop,
				scrollHeight: params.component.scrollHeight
			};
			const oldScrollData = getStoredScrollData(params);
			const scrollDirection = getScrollDirection(oldScrollData, scrollData);
			globalData[params.structure][params.componentName].scroll = scrollData;
			return {scrollData: scrollData, scrollDirection: scrollDirection};
		} else {
			return zeroScrollData;
		}
	} else {
		// capture data for "normal" containers, those inside a structure/pane
		if ($(params.component).children().length > 0) {
			const scrollData = {
				scroll: getScroll({target: params.component, type:'percentage'}),
				scrollTop: params.component.scrollTop,
				scrollHeight: params.component.scrollHeight
			};
			const oldScrollData = getStoredScrollData(params);
			const scrollDirection = getScrollDirection(oldScrollData, scrollData);
			globalData[params.componentName] = scrollData;
			globalData[params.structure][params.pane][params.componentName].scroll = scrollData;
			return {scrollData: scrollData, scrollDirection: scrollDirection};
		} else {
			return zeroScrollData;
		}
	}
};

/**
 * Find the module location from the stored module locations - structure/pane/container from configuration.
 * We use either the instanceId or a key based on moduleName + storageKey to find a module's location
 * in the configured structure.
 *
 * If no location information can be found, just returns empty object
 *
 * Note: instanceId is considered "more" unique, so that is tried first if it is not empty.
 *
 * @param params
 *     module: module name
 *     storageKey: storageKey name
 *     instanceId: module instanceId
 *     useDevice: true: return only results for current device breakpoint
 *     partialKey: false: use full locationKey (module + storageKey)
 *         true: return pane location if locationKey is only partially complete
 *               this can be either an empty string for module or storageKey
 *
 * @returns {*}
 */
const getModuleLocation = (params) => {
	params = Object.assign({
		module: '',
		storageKey: '',
		instanceId: '',
		useDevice: false,
		partialKey: false
	}, params);

	// get moduleInstanceLocations and find attributes based on instanceId
	const paneInstanceLocations = getStoreValue({attributeKey: 'moduleInstanceLocations'});
	const paneInstanceLocation = paneInstanceLocations.hasOwnProperty(params.instanceId) ? paneInstanceLocations[params.instanceId] : {};

	// get moduleLocations and find attributes based on moduleName+storageKey
	const paneLocations = getStoreValue({attributeKey: 'moduleLocations'});
	const locationKey = (params.module + '_' + params.storageKey).toUpperCase();
	const paneLocation = isTrue(params.partialKey, {default: false}) ?
		getValueByPartialKey({obj: paneLocations, partialKey: locationKey, default: {}}) :
		paneLocations.hasOwnProperty(locationKey) ? paneLocations[locationKey] : {};

	// location from instanceId takes precedence over moduleName+storageKey
	if (params.useDevice) {
		const device = getDeviceType();
		return paneInstanceLocation.hasOwnProperty(device) ? paneInstanceLocation[device] : (paneLocation.hasOwnProperty(device) ? paneLocation[device] : {container: '', pane: ''});
	} else {
		return !isEmpty(paneInstanceLocation) ? paneInstanceLocation : paneLocation;
	}
};
export {getModuleLocation};

/**
 * Return the pane and container in which the module is located, depending on the device breakpoint.
 * Generate the style/html selector for the container element, knowing the following:
 *     pane: this is the html id of the pane
 *     container: this is the html class of the container
 *
 * @param params
 *     module: module name (NOT module object)
 *     storageKey: module storage key from caller
 *     instanceId: module instanceId
 *
 * @returns {{container, selector: string, pane}}
 */
const getModuleContainer = (params) => {
	params = Object.assign({
		module: '',
		storageKey: '',
		instanceId: ''
	}, params);
	const moduleLocation = getModuleLocation({
		module: params.module,
		storageKey: params.storageKey,
		instanceId: params.instanceId,
		useDevice: true
	});

	const pane = !isEmpty(moduleLocation.pane) ? '#' + moduleLocation.pane : '';
	const layoutBlock = !isEmpty(moduleLocation.layoutBlock) ? ' .' + moduleLocation.layoutBlock : '';
	const container = !isEmpty(moduleLocation.container) ? ' .' + moduleLocation.container : '';
	return {
		container: moduleLocation.container,
		pane: moduleLocation.pane,
		layoutBlock: moduleLocation.layoutBlock,
		containerSelector: pane + layoutBlock + container,
		layoutSelector: pane + layoutBlock,
	};
};
export {getModuleContainer};


/**
 * Determine if a module's configured pane matches the current pane by checking if the configured
 * container for the module matches the stored currentPane value.
 * A module is uniquely identified by name and storageKey.
 *
 * WARNING:
 *     This function allows "null" to always match as a currentPane if the module is configured
 *     in a special "null" (ie. global) pane.  By default, the attribute allowNullPane will be set
 *     to true.  This normally won't be overridden, but you can set allowNullPane: false to
 *     prevent this default test.
 *
 * @param params
 *     moduleName: the name of the module; configured as "name"
 *     storageKey: the module's configured storageKey
 *     instanceId: the module's configured instanceId
 *     allowNullPane: if true, also match if the module is configures in the "null" pane
 * @returns {{isMyPane: boolean, myPane}} */
const checkIfMyPane = (params) => {
	params = Object.assign({
		moduleName: '',
		storageKey: '',
		instanceId: '',
		allowNullPane: true
	}, params);
	const allowNullPane = isTrue(params.allowNullPane);

	// if the currentPane attribute is set in state globals, capture the value
	const currentPane = getGlobalDataValue({attr: 'currentPane', simple: true, defaultValue: ''});
	const myPane = getModuleContainer({module: params.moduleName, storageKey: params.storageKey, instanceId: params.instanceId}).pane;
	const isMyPane = currentPane === myPane ||
		(allowNullPane ?
			(isEmpty(myPane) || myPane === 'null' || myPane === 'none') :
			false);

	return {myPane: myPane, isMyPane: isMyPane, currentPane: currentPane};
};
export {checkIfMyPane};


/**
 * Generate initial css-variable height/width values for configuraed containers.  All initial values
 * are set to 0.  This allows us to use the css-variable in styling, even if it is not currently used in a
 * particular structure/pane configuration.  Otherwise, if the css-variable isn't present, it causes
 * an error
 *
 * @param configuredContainers from configuration
 * @returns {{}} object of styles and values to add to a React string
 */
const generateInitialVariableStyles = (configuredContainers) => {
	const sizeStyles = {};
	configuredContainers.forEach((containerName) => {
		const sizeName = containerName.indexOf('global_') === 0 ? "--" + containerName : "--size_" + containerName;
		sizeStyles[sizeName + "-height"] = 0;
		sizeStyles[sizeName + "-width"] = 0;
	});
	return sizeStyles;
};
export {generateInitialVariableStyles};


const getModuleInstanceData = (params) => {
	params = Object.assign({
		module: '',
		storageKey: ''
	}, params);
	const moduleContainer = getModuleContainer(params);
	const moduleInstances = getStoreValue({attributeKey: 'moduleInstances'});
	const device = getDeviceType();
	const structures = getStoreValue({attributeKey: 'structures'});
	const deviceStructure = structures[device].structureName;
	if (moduleInstances.hasOwnProperty(deviceStructure) && moduleInstances[deviceStructure].hasOwnProperty(moduleContainer.pane) && moduleInstances[deviceStructure][moduleContainer.pane].hasOwnProperty(moduleContainer.container)) {
		const moduleInstanceData = moduleInstances[deviceStructure][moduleContainer.pane][moduleContainer.container];
		const instanceData = moduleInstanceData.find(element => element.name === params.module);
		return typeof instanceData !== 'undefined' ? instanceData : {};
	} else {
		return {};
	}
};
export {getModuleInstanceData};


/**
 * Given a module name and storage key, find the instanceId for that combination in the
 * current structure.
 *
 * Note: If the instance attribute has not been defined, then the return value will
 * be undefined.  We don't want to try to define anything, since almost any default
 * value might be a legal attribute value.
 *
 * @param params
 *     module: module name
 *     storageKey: module storageKey
 *     attributes: list of attribute names to get data values
 * @returns {string|*} instanceId
 */
const getModuleInstanceAttributes = (params) => {
	params = Object.assign({
		module: '',
		storageKey: '',
		attributes: []
	}, params);
	const attributes = Array.isArray(params.attributes) ? params.attributes : [];
	if (attributes.length === 0) {
		return {};
	} else {
		const instanceData = getModuleInstanceData(params);
		const attributesObject = {};
		attributes.forEach(attribute => {
			attributesObject[attribute] = instanceData[attribute];
		});
		return attributesObject;
	}
};
export {getModuleInstanceAttributes};


/**
 * Given a set of instance attributes and attribute values, update the
 * stored instance data.
 *
 * Note: This would normally be used to add new, derived attributes to
 * the module instance data, so that it can be accessed later from the
 * getModuleinstanceAttributes function.
 *
 * @param params
 *     module: module name
 *     storageKey: module storageKey
 *     attributes: object of attribute names and values to set
 */
const updateModuleInstanceAttributes = (params) => {
	params = Object.assign({
		module: '',
		storageKey: '',
		attributes: {}
	}, params);
	const attributes = params.attributes;
	if (!isEmpty(attributes)) {
		const instanceData = getModuleInstanceData(params);
		Object.keys(attributes).forEach(attrName => {
			instanceData[attrName] = attributes[attrName];
		});
	}
};
export {updateModuleInstanceAttributes};


/**
 * Return a set of configuration attributea for a module given just the module name.
 *
 * This will lookup up module configuration based only on the module name, so it may
 * not find a particular configuration if multiple instances of the same module are
 * configured.
 * For a specific module instance, use getModuleInstanceAttributes
 *
 * @param params
 *     module: name of the module
 *     attributes: list of attributes to get values
 *     default: optional default value to return
 * @returns {{}|*}
 */
const getModuleAttributesByLocation = (params) => {
	params = Object.assign({
		module: '',
		attributes: [],
		default: {},
	}, params);

	const moduleLocation = getModuleLocation({
		module: params.module,
		useDevice: true,
		partialKey: true
	});

	const moduleInstances = getStoreValue({attributeKey: 'moduleInstances'});
	const attributeMap = moduleLocation.structure + '.' +moduleLocation.pane + '.' +moduleLocation.container;
	const containerModules = getLeafValue(moduleInstances, attributeMap);
	if (!isEmpty(containerModules)) {
		const moduleConfiguration = containerModules.find(module => module.name === params.module);
		if (!isEmpty(moduleConfiguration)) {
			const attributesObject = {};
			params.attributes.forEach(attribute => {
				attributesObject[attribute] = !isEmpty(moduleConfiguration[attribute]) ? moduleConfiguration[attribute] : params.default;
			});
			return attributesObject;
		}
	}
	return params.default;

};
export {getModuleAttributesByLocation};


/**
 * Provide a way to store back-end/global data for a particular module instance.
 * Pass in the module type and storageKey to find the instance for the structure/pane.
 *
 * Store the key/value object by the instance id.
 *
 * @param params
 *     module: module name/type
 *     storageKey: module storageKey for instance
 *     attributeKey: store attribute key
 *     attributeValue: store attribute value
 */
const storeGlobalModuleData = (params) => {
	params = Object.assign({
		module: '',
		storageKey: '',
		attributeKey: null,
		attributeValue: ''
	}, params);

	if (params.attributeKey) {
		const moduleInstance = getModuleInstanceAttributes({module: params.module, storageKey: params.storageKey, attributes: ['moduleId','instanceId']});
		const instanceKey = moduleInstance.instanceId;
		const moduleInstanceData = getGlobalDataValue({
			attr: 'moduleInstanceData',
			simple: false,
			defaultValue:  {}
		});
		const instanceData = moduleInstanceData.hasOwnProperty(instanceKey) ? moduleInstanceData[instanceKey] : {};
		const newInstanceData = deepmerge(instanceData, {[params.attributeKey]: params.attributeValue});
		const newModuleInstanceData = deepmerge(moduleInstanceData, {[instanceKey]: newInstanceData});
		updateGlobalData({'moduleInstanceData': newModuleInstanceData});
	}
};
export {storeGlobalModuleData};

/**
 * Get a stored value for a global-stored module instance.  The instance id
 * is found based on the module name, storageKey and current structure/pane.
 *
 * Returns the stored key/value attributes for the instance id key.
 *
 * @param params
 *     module: module name/type
 *     storageKey: module storage key for instance
 * @returns {*}
 */
const getStoredGlobalModuleData = (params) => {
	params = Object.assign({
		module: '',
		storageKey: ''
	}, params);
	const moduleInstance = getModuleInstanceAttributes({module: params.module, storageKey: params.storageKey, attributes: ['moduleId','instanceId']});
	const instanceKey = moduleInstance.instanceId;
	const moduleInstanceData = getGlobalDataValue({
		attr: 'moduleInstanceData',
		simple: false,
		defaultValue:  {}
	});
	return moduleInstanceData.hasOwnProperty(instanceKey) ? moduleInstanceData[instanceKey] : {};
};
export {getStoredGlobalModuleData};
