import {generateClassFromCSV} from "../../../../utils/generateClassName";

/**
 * Special article attributes include the following and must be processed before use:
 *     categories
 *     issueGroups
 *
 * @param params attribute
 * @returns {{value: string}} return as object for caller
 */
const getSpecialAttribute = (params) => {
	const attribute = params.hasOwnProperty('attribute') ? params.attribute : '';
	if (attribute === 'categories') {
		if (params.article.hasOwnProperty('categories') && params.article.categories !== '' && params.article.categories !== null) {
			const categoriesClass = generateClassFromCSV({classCSV: params.article.categories, prefix: 'category_'});
			return {value: categoriesClass};
		}
	}
	return {value: ''};
};
export default getSpecialAttribute;