import React, { useState, useEffect } from 'react';
/**
 * This hook can be used by modules that want to have a chance to finish tracking
 * of their view when the client switches tabs or closes the browsers.
 * It takes 2 functions as arguments. The first defines the tracking call itself
 * and the second allows the hook to reset the module's start-time for a view.
 * When a new tab is opened, this hook starts a timer for tabHiddenTimerDelay seconds.
 * If the client switches back to the module view before the timer expires it is
 * canceled and the viewing continues as if the tab switch had not occured.
 * Once the timer expires the tracking function is called and when the client comes back
 * to the module view the second function is called to reset the module view time so
 * that a new view can start.
 * When the browser is closed the tracking function will be called.
 *
 * @param {*} callBackFct
 * @param {*} startTimeFct
 * @param props
 */
export function useVisibilityChange(callBackFct, startTimeFct, props) {
	const [isVisible, setIsVisible] = useState(getIsDocumentHidden());
	const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());
	const [isTabVisible, setIsTabVisible] = useState(true);
	const [tabHiddenTimer, setTabHiddenTimer] = useState(null);
	const tabHiddenTimerDelay = 120000;// 120s
	const [isTabHiddenTimerRunning, setIsTabHiddenTimerRunning] = useState(false);
	const [browserState, setBrowserState] = useState("open");

	useEffect(() => {
		const visibilityChange = getBrowserVisibilityProp();
		document.addEventListener(visibilityChange, onVisibilityChange, false);
		return () => {
			document.removeEventListener(visibilityChange, onVisibilityChange);
		};
	});

	// runs once at mount time and sets up a listener for window close event
	useEffect(() => {
		window.onbeforeunload = () => {
			setBrowserState("closing");
			//return "show warning"; //this is helpful for testing
		};
	},[]);

	// watching browserState, kick off articleViewed on close
	useEffect(() => {
//		console.log("*** time for action, callBackFtc: " + typeof callBackFct);
		if (callBackFct) {
			if (browserState === "closing") {
				callBackFct({time: new Date().getTime(), message: "Tab closed", status: "closed", props: props});
				setBrowserState("closingTracked");
			}
		}
	},[browserState, callBackFct, props]);

	let status = "no-action";
	const visibiltyChanged = isVisible !== isTabVisible;
	if (visibiltyChanged) {
		setIsTabVisible(isVisible);

		if (isVisible) {
			if (isTabHiddenTimerRunning) {
				clearTimeout(tabHiddenTimer);
				setIsTabHiddenTimerRunning(false);
				status = "timer-canceled";
			} else if (typeof startTimeFct === "function") {
				//timer finished
				startTimeFct(new Date().getTime());
				status = "reset-start-time";
			}
		} else {
			setIsTabHiddenTimerRunning(true);
			setTabHiddenTimer(setTimeout(() => {
//				console.log("*** time for action, callBackFtc: " + typeof callBackFct);
				setIsTabHiddenTimerRunning(false);
				if (callBackFct) {
					callBackFct({time: new Date().getTime(), message: "Tab Hidden Timeout", status: "hidden", props: props});
				}
			}, tabHiddenTimerDelay));
			status = "timer-scheduled-" + tabHiddenTimerDelay;
		}
	}

	//console.log(" useVisibilityChange: returning >>> " + status + " <<<");
	return status;
}

function getBrowserVisibilityProp() {
	if (typeof document.hidden !== "undefined") {
		return "visibilitychange";
	}
}

function getBrowserDocumentHiddenProp() {
	if (typeof document.hidden !== "undefined") {
		return "hidden";
	}
}

function getIsDocumentHidden() {
	return !document[getBrowserDocumentHiddenProp()];
}
