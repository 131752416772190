import {isEmpty, isTrue} from "./generalUtils";
import {objectsEqual, getObjectFromJSON, isPlainObject} from "./objectUtils";
import {generateClassFromProps} from './generateClassName';
import getDeviceType from "./getDeviceType";
import $ from "jquery";
import {getStoreValue} from "./storeValue";
import deepmerge from "deepmerge";
import {checkIfMyPane} from "../modules/globals/globalLibraryData";
import {stringsEqual} from "./stringUtils";
import {generateHashKey} from "./generateHash";


/**
 * Read the displayDevices attribute from module configuration.  Depending on the
 * device, determine if the display property is set for that device and whether
 * or not it is set to true/false.
 *
 * Test if display property is set to "false" return false; otherwise return true (default)
 *
 * In addition to the check for the displayDevices property, check to see if the module props
 * are the ones associated with the current displayed structure.  There seems to be some
 * props cacheing that can happen when structures are changed at device breakpoints, so that
 * previous structure props are also delivered to modules.
 * This check will, at least, prevent the module from rendering with those props.
 *
 * WARNING: This additional check means that all modules should include the displayOnDevice
 * call before render.
 *
 * sample configuration
 *     "displayDevices": "{\"default\": {\"display\": \"true\"}, \"xxl\": {\"display\": \"true\"}, \"xl\": {\"display\": \"true\"}, \"lg\": {\"display\": \"false\"}, \"md\": {\"display\": \"false\"}, \"sm\": {\"display\": \"false\"}, \"xs\": {\"display\": \"false\"}}"
 *
 * @param params props from module or parameters passed in directly
 *     displayDevices: json string with key of device and display attribute for whether to display/render
 *     structureId: (optional) the structure associated with the module's current instantiation
 * @returns {boolean} true: display/render; false: don't display/render
 */
const displayOnDevice = (params) => {
	params = Object.assign({
		displayDevices: null,
		structureId: ''
	}, params);
	const displayDevices = getObjectFromJSON(params.displayDevices, {});
	const currentDevice = getDeviceType();
	const currentStructure = getStoreValue({attributeKey: 'currentStructure'});
	const isCurrentStructure = (!isEmpty(currentStructure) && stringsEqual(currentStructure, params.structureId)) || stringsEqual(params.structureId, 'globalModules');
	const isApp = isTrue(getStoreValue({attributeKey: 'app'}), {defaultValue: false});

	if (!isCurrentStructure) {
		return false;
	} else if (displayDevices.hasOwnProperty('app') && isApp) {
		return isTrue(displayDevices.app.display);
	} else if (displayDevices.hasOwnProperty(currentDevice)) {
		return isTrue(displayDevices[currentDevice].display);
	} else if (displayDevices.hasOwnProperty('default')) {
		return isTrue(displayDevices.default.display);
	} else {
		return true;
	}
};
export {displayOnDevice};


/**
 * Return active/inactive depending on the state of a store property.
 * If the property activeAttribute is not defined, just return empty string.
 *
 * @param params
 *     activeAttribute: json string with key and type for property
 *     storageKey: (optional) storageKey for property
 *     defaultState: 'active'/'inactive'
 * @returns {*|string} 'active' or 'inactive'
 */
const getActiveAttributeState = (params) => {
	if (!params.hasOwnProperty('activeAttribute')) {
		return '';
	}
	const options = Object.assign({
		activeAttribute: '',
		storageKey: ''
	}, params);
	options.activeAttribute = getObjectFromJSON(options.activeAttribute, {});
	options.activeAttribute = isPlainObject(options.activeAttribute) ? options.activeAttribute : {};
	const activeKey = options.activeAttribute.hasOwnProperty('key') ? options.activeAttribute.key : '';
	const activeType = options.activeAttribute.hasOwnProperty('type') ? options.activeAttribute.type : 'string';
	let active = (options.activeAttribute.hasOwnProperty('default') && $.inArray(options.activeAttribute.default, ['active', 'inactive']) !== -1)
		? options.activeAttribute.default : 'active';
	const storageValue = getStoreValue({
		storageKey: options.storageKey,
		attributeKey: activeKey
	});
	if (storageValue !== null) {
		if (activeType === 'string') {
			active = (storageValue === '') ? 'inactive' : 'active';
		}
	}
	return active;
};
export {getActiveAttributeState};


/**
 * Add docType query parameters to the list of query parameters, but only if the query is based
 * on an issue.  First application is for articleLists that use an issueUrl as one of the query
 * parameters, as that generates an issue articleList.
 *
 * Sample configuration: specifies a set of docType attributes attached to Textbook
 *     "cardTemplate": {
 *         "docType": {
 *             "Textbook": {
 *                 "card": "textbook",
 *                 "displayThumbnail": false,
 *                 "displaySummary": false,
 *                 "createSections": true
 *             }
 *         }
 *     }
 *
 * Match with docTypeQueryParams example
 *     docTypeQueryParams = {'createSections': {attribute: 'cardTemplate', property: 'createSections', related: {withContent: true}}
 *
 * For this example, we are looking for a docType specification called createSections within an attribute called cardTemplate.
 * If the query is for an issue-type list, then we gather the parameters and add them to the queryParams object.
 * If there is a related parameter, then we set the value of the related parameter to the related value.
 *
 * Note: In general, these parameters are not whiteListedParams to send in the server api call, but are usually
 * used to provide instructions to the server for how to process the data.
 *
 * @param params
 *     moduleProps: props from module
 *     queryParams: generated query parameters from generateQueryParams function
 *     docTypeQueryParams: special query params linked to docType attributes
 * @returns {*}
 */
const generateDoctypeConfigQueryParams = (params) => {
	params = Object.assign({
		moduleProps: {},
		queryParams: {},
		docTypeQueryParams: {}
	}, params);
	const {moduleProps, queryParams, docTypeQueryParams} = params;

	// only gather docType params if it is an issue-type query
	if (queryParams.hasOwnProperty('issueUrl')) {
		Object.keys(docTypeQueryParams).forEach(paramName => {
			const docTypeAttributes = docTypeQueryParams[paramName];
			const attribute = docTypeAttributes.attribute;
			const currentDocType = !isEmpty(moduleProps.docType) ? moduleProps.docType : 'Default';
			const configuredDocTypes = moduleProps.hasOwnProperty(attribute) && moduleProps[attribute].hasOwnProperty('docType') ? moduleProps[attribute].docType : {};
			const configuredParam = {};
			Object.keys(configuredDocTypes).forEach(docType => {
				configuredParam[docType] = configuredDocTypes[docType].hasOwnProperty(paramName) ? configuredDocTypes[docType][paramName] : false;
			});
			if (!isEmpty(configuredParam)) {
				if (docTypeQueryParams[paramName].hasOwnProperty('related')) {
					Object.keys(docTypeQueryParams[paramName].related).forEach((queryParam) => {
						if (configuredParam[currentDocType]) {
							queryParams[queryParam] = docTypeQueryParams[paramName].related[queryParam];
						}
					});
				}
				queryParams[paramName] = queryParams.hasOwnProperty(paramName) ? deepmerge(queryParams[paramName], configuredParam) : configuredParam;
				queryParams[paramName] = JSON.stringify(queryParams[paramName]);
			}
		});
	}
	return queryParams;
};


/**
 * Gather query params from props configuration and, optionally, additional
 * props that can override the normal props.
 *
 * configQueryParams have two different forms:
 *     key:value (string:string) - the key matches the fetch parameter; the value matches the configuration parameter
 *         in most (all?) cases these are the same
 *     key:value (string:object) - the key matches the fetch parameter; the value is an object
 *         attribute: matches the configuration parameter
 *         default: default value for attribute so it is always set to something
 *
 * docTypeQueryParams
 *     see explanatory comments in function generateDoctypeConfigQueryParams (above)
 *
 * @param params
 *     configQueryParams: object of module's params for query
 *     docTypeQueryParams: special module params targeted towards docType attributes
 *     moduleProps: module's props
 *     additionalProps: (optional) override props
 * @returns {{}}
 */
const generateQueryParams = (params) => {
	params = Object.assign({
		configQueryParams: {},
		docTypeQueryParams: {},
		moduleProps: {},
		additionalProps: {}
	}, params);
	const {configQueryParams, docTypeQueryParams, moduleProps, additionalProps} = params;

	let queryParams = {};
	Object.keys(configQueryParams).forEach((key) => {
		let param = '';
		if (!isEmpty(configQueryParams[key])) {
			if (typeof configQueryParams[key] === 'string') {
				param = configQueryParams[key];
			} else {
				param = configQueryParams[key].attribute;
				queryParams[key] = configQueryParams[key].default;
			}
		}
		if (!isEmpty(additionalProps[param])) {
			queryParams[key] = additionalProps[param];
		} else if (!isEmpty(moduleProps[param])) {
			queryParams[key] = moduleProps[param];
		}
	});
	if (queryParams.hasOwnProperty('maxEntries') && !queryParams.hasOwnProperty('pageSize')) {
		queryParams.pageSize = queryParams.maxEntries;
		delete queryParams.maxEntries;
	}
	if (!isEmpty(docTypeQueryParams)) {
		queryParams = generateDoctypeConfigQueryParams({
			moduleProps: moduleProps,
			queryParams: queryParams,
			docTypeQueryParams: docTypeQueryParams
		});
	}
	// if singleDocumentCollection, always add issueUrl to query params
	const singleDocumentCollection = getStoreValue({attributeKey: 'singleDocumentCollection'});
	const urlNavigationTarget = getStoreValue({attributeKey: 'urlNavigationTarget'});
	if (singleDocumentCollection && urlNavigationTarget.hasOwnProperty('issueUrl') && !queryParams.hasOwnProperty('issueUrl')) {
		queryParams.issueUrl = urlNavigationTarget.issueUrl;
	}

	return queryParams;
};
export {generateQueryParams};


/**
 * Check to see if the query parameters used to fetch the list are the same as the query parameters
 * defined for this module instance.  If they are the same, then set the moduleProps ist attribute to the
 * list from the store.  Otherwise, wait for the list to fetch.
 *
 * @param params
 *     originalProps: props from the module
 *     storeState: redux store state
 *     moduleProps: new props defined for the module
 *     configQueryParams: modules object list of query parameters
 *     listType: type of list to check
 */
const detectListFetchComplete = (params) => {
	params = Object.assign({
		originalProps: {},
		storeState: {},
		moduleProps: {},
		configQueryParams: {},
		docTypeQueryParams: {},
		listType: ''
	}, params);
	const {originalProps, storeState, moduleProps, configQueryParams, docTypeQueryParams, listType} = params;

	const queryParams = generateQueryParams({configQueryParams: configQueryParams, docTypeQueryParams: docTypeQueryParams, moduleProps: originalProps, additionalProps: moduleProps});

	moduleProps.fetchParamsSet = false;
	moduleProps.fetchQueryParams = {};

	if (storeState.hasOwnProperty(listType)) {
		if (storeState.hasOwnProperty('fetchQueryParams') && storeState.fetchQueryParams !== null) {
			moduleProps.fetchQueryParams = storeState.fetchQueryParams;
			if (objectsEqual(storeState.fetchQueryParams, queryParams)) {
				moduleProps[listType] = storeState[listType];
				moduleProps.fetchParamsSet = true;
			} else {
				moduleProps[listType] = [];
				moduleProps.fetchParamsSet = false;
			}
		} else {
			moduleProps[listType] = storeState[listType];
		}
	} else {
		moduleProps[listType] = [];
	}

	return moduleProps;
};
export {detectListFetchComplete};


/**
 * Generate a basic set of module properties that are common to all from
 * parameters specific to the calling module.
 *
 * Properties key name: mops (module options)
 *
 *
 * @param props
 * @param params
 * @returns {{queryParams: {}, titleParams: {}, className: string, storageKey: string}}
 */
const generateMops = (props, params) => {
	const mops = {
		titleParams: {},
		className: '',
		storageKey: '',
		queryParams: {},
		docTypeQueryParams: {}
	};
	// default values
	params = Object.assign({
		defaultKey: '',
		title: '',
		titleTag: 'h2',
		titlePrefix: '',
		displayTitle: false,
		defaultClass: 'default-class',
		configQueryParams: {}
	}, params);

	mops.titleParams = {
		// entering a locale key is preferred for the title, as it is translatable and changeable in the strings file
		i18nId: props.hasOwnProperty('title') ? props.title : params.title,
		displayTitle: props.hasOwnProperty('displayTitle') ? isTrue(props.displayTitle) : params.displayTitle,
		tag: props.hasOwnProperty('titleTag') ? props.titleTag : params.titleTag,
		titlePrefix: props.hasOwnProperty('titlePrefix') ? props.titlePrefix : params.titlePrefix
	};

	const generatedClass = generateClassFromProps(props, params);
	mops.className = generatedClass.className;
	mops.cssClass = generatedClass.cssClass;
	mops.storageKey = props.hasOwnProperty('storageKey') ? props.storageKey : params.defaultKey;
	mops.queryParams = generateQueryParams({configQueryParams: params.configQueryParams, docTypeQueryParams: params.docTypeQueryParams, moduleProps: props});

	return mops;
};
export {generateMops};


/**
 * Decompose grid attribute into three parts depending on the device size.
 *
 * If the current device does not match any configured device, use the default
 * configuration, otherwise, use sensible defalt values.
 *
 * sample definition for grid:
 *
 *    {
 *        "xl": {"columns": 4, "entriesPerPage": 12, "maxDisplay": 200},
 *        "lg": {"columns": 3, "entriesPerPage": 9, "maxDisplay": 120}
 *    }
 *
 * @param grid configured grid paged definition
 * @returns {{}} decomposed object of valus for device
 */
const devicePageGrid = (grid) => {
	const device = getDeviceType();
	const gridObject = {};
	let gridDevice = {};
	if (grid.hasOwnProperty(device)) {
		gridDevice = grid[device];
	} else if (grid.hasOwnProperty('default')) {
		gridDevice = grid.default;
	}
	gridObject.columns = gridDevice.hasOwnProperty('columns') ? gridDevice.columns : 1;
	gridObject.entriesPerPage = gridDevice.hasOwnProperty('entriesPerPage') ? gridDevice.entriesPerPage : 10;
	gridObject.maxDisplay = gridDevice.hasOwnProperty('maxDisplay') ? gridDevice.maxDisplay : 200;

	return gridObject;
};
export {devicePageGrid};


/**
 *  The `navigationAttributes.fetchDataAttributes` object specifies data that should take
 *  precedence over module-defined data.  If it exists, add any found parameters to
 *  attribute data to be added to module props.  Note this may cause data to be re-fetched.
 *
 * @param params module parameters
 *     storeState: store state based on storageKey
 *     originalProps: the "props" attribute from the module
 *     attributeType: key for fetchDataAttributes
 *
 * @returns {*} update moduleProps values
 */
const setNavigationProps = (params) => {
	params = Object.assign({
		moduleProps: {},
		storeState: {},
		navigationAttributeType: ''
	}, params);
	const {moduleProps, storeState, navigationAttributeType} = params;

	const navigationProps = {};

	if (storeState.hasOwnProperty('navigationAttributes')) {
		navigationProps.navigationAttributes = storeState.navigationAttributes;
		navigationProps.fetchNavigationKey = storeState.navigationAttributes.navigationKey;
		const fetchDataAttributes = getObjectFromJSON(storeState.navigationAttributes.fetchDataAttributes, {});
		if (fetchDataAttributes.hasOwnProperty(navigationAttributeType)) {
			const fetchAttributes = getObjectFromJSON(fetchDataAttributes[navigationAttributeType].parameters, {});
			Object.keys(fetchAttributes).forEach(key => {
				navigationProps[key] = fetchAttributes[key];
			});
		}
	} else {
		navigationProps.navigationAttributes = {};
		navigationProps.fetchNavigationKey = moduleProps.hasOwnProperty('navigationKey') ? moduleProps.navigationKey : null;
	}
	return navigationProps;
};
// export {setNavigationProps};

/**
 * Generate module props that *should* apply to almost all DATA modules.
 * As part of this, also call setNavigationProps to populate module data with navigation
 * attributes.
 *
 * @param params
 *     moduleProps: props object created by module
 *     props: module props object
 *     state: redux store state object
 *     storageKey: module storage key
 *     navigationAttributeType: for generating navigation attributes
 * @returns {any} new module props
 */
const dataModuleAttributes = (params) => {
	params = Object.assign({
		moduleProps: {},
		originalProps: {},
		state: {},
		storageKey: '',
		navigationAttributeType: ''
	}, params);
	const {originalProps, state, storageKey} = params;
	// state object specific to the module instance
	const storeState = state[storageKey] ? state[storageKey] : {};

	const myPane = checkIfMyPane({moduleName: originalProps.name, instanceId: originalProps.instanceId, storageKey: storageKey});

	const newModuleProps = Object.assign({}, params.moduleProps, {
		// props: defined in module configuration
		active: getActiveAttributeState(originalProps),
		navigationKeys: getObjectFromJSON(originalProps.navigationKeys, {}),
		useModuleFocus: isTrue(originalProps.useModuleFocus),
		useModuleTitle: isTrue(originalProps.useModuleTitle),
		shareable: isTrue(originalProps.shareable),
		fetchOnInit: originalProps.hasOwnProperty('fetchOnInit') ? isTrue(originalProps.fetchOnInit) : true,

		// storeState: defined by other modules updateData calls
		forceFetch: storeState.hasOwnProperty('forceFetch') ? isTrue(storeState.forceFetch) : false,
		updateHistoryState: isTrue(storeState.updateHistoryState),
		replaceHistoryState: isTrue(storeState.replaceHistoryState),

		// general state attributes
		isApp: isTrue(state.app, {defaultValue: false}),

		// other defined attributes
		currentPane: myPane.currentPane,
		myPane: myPane.myPane,
		isMyPane: myPane.isMyPane
	});

	if (!isEmpty(state.queryParams)) {
		// optional attributes from query params
		const optionalQueryAttributes = ['u1'];
		optionalQueryAttributes.forEach(attribute => {
			if (!isEmpty(state.queryParams[attribute])) {
				newModuleProps[attribute] = state.queryParams[attribute];
			}
		});
	}

	const navigationProps = setNavigationProps({
		storeState: storeState,
		moduleProps: newModuleProps,
		navigationAttributeType: params.navigationAttributeType
	});

	// generate hash key from userAccess object; check if authentication object is populated and use it; else use value from state
	if (!isEmpty(state.authentication) && !isEmpty(state.authentication.userAccess)) {
		newModuleProps.userAccess = state.authentication.userAccess;
		newModuleProps.userAccessHash = generateHashKey({keyValue: state.authentication.userAccess});
	} else if (!isEmpty(state.userAccess)) {
		newModuleProps.userAccess = state.userAccess;
		newModuleProps.userAccessHash = generateHashKey({keyValue: state.userAccess});
	} else {
		newModuleProps.userAccess = {isLoggedIn: false, access: {}};
		newModuleProps.userAccessHash = null;
	}
	newModuleProps.issueHasAccess = isTrue(newModuleProps.userAccess.access[navigationProps.issueUrl], {default: true});

	if (!isEmpty(navigationProps.issueUrl)) {
		newModuleProps.issueGroup = getIssueGroup({documentList: state.documentList, issueUrl: navigationProps.issueUrl});
	}

	return Object.assign(newModuleProps, navigationProps);
};
export {dataModuleAttributes};


/**
 * Find the docType for a particular issueUrl.  If none, default to "Default"
 *
 * @param params
 *     docTypeOverride: docType to use if defined
 *     documentList: documentList from server
 *     issueUrl: current issueUrl
 * @returns {*}
 */
const getDocType = (params) => {
	params = Object.assign({
		docTypeOverride: '',
		documentList: {},
		issueUrl: '',
	}, params);

	const docTypeOverride = !isEmpty(params.docTypeOverride) ? params.docTypeOverride : '';
	const documentList = !isEmpty(params.documentList) ? params.documentList : {};
	const issueUrl = !isEmpty(params.issueUrl) ? params.issueUrl : '';

	let docType = 'Default';
	if (!isEmpty(docTypeOverride)) {
		docType = docTypeOverride;
	} else {
		const issueDocument = documentList.find(doc => doc.issueUrl === issueUrl);
		docType = !isEmpty(issueDocument) ? issueDocument.docType : 'Default';
	}
	return docType;
};
export {getDocType};


/**
 * Return the issueGroup attribute for a particular issueUrl.
 * If there is no issueGroup defined for a document in documentList,
 * then use "default" as the returned value
 *
 * @param params
 *     documentList: list of documents(issues) in the collection
 *     issueUrl: url string identifier for a particular issue
 * @returns {string|*} issueGroup value
 */
const getIssueGroup = (params) => {
	params = Object.assign({
		documentList: [],
		issueUrl: ''
	}, params);

	let issueDocument = params.documentList.find(doc => doc.issueUrl === params.issueUrl);
	issueDocument = !isEmpty(issueDocument) ? issueDocument : {};
	return !isEmpty(issueDocument.issueGroup) ? issueDocument.issueGroup : 'default';
};
export {getIssueGroup};
