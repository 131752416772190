import $ from 'jquery';
import {generateArticleSectionStrings} from "../../../../utils/generateArticleSectionStrings";

/**
 * Sets the value of a `data-section` attribute on article h2 elements
 * using the text content of the header
 *
 * @param article cleaned article object
 * @returns {*} revised article object; updates h2 elements
 */
const setArticleSectionHeaders = (article) => {
	const $articleContent = $(article.content);
	const $elements = $articleContent.find('h2');
	if ($elements.length > 0) {
		$elements.each(function(key, element) {
			const sectionStrings = generateArticleSectionStrings($(element));
			$(element).attr('data-section',sectionStrings.section);
		});
	}
	article.content = $('<div />').append($articleContent).html();
	return article;
};
export {setArticleSectionHeaders};
