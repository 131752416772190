import {useEffect, useState} from 'react';
import $ from "jquery";


/**
 * Given an element id, check for and get jQuery element for that element id.
 * We will be using this to attach a jQuery event listener, including an
 * event namespace (which is why we use jQuery).
 *
 * @param elementId html id to find/return jQuery object
 */
const getEventListenerElement = (elementId) => {
	const captureElementId = typeof elementId !== 'undefined' ? elementId : 'pane-1';
	let $captureElement = $('#' + captureElementId);
	if ($captureElement.length === 0) {
		$captureElement = $('#pane-1');
	}
	return $captureElement;
};
export {getEventListenerElement};


/**
 * Enable or disable keystroke events.  This will setup the event listener for the
 * appropriate keystroke event, including calling the function used to handle the
 * keystroke event and passing the appropriate parameters to that event.
 *
 * Note: Event detection uses the jQuery namespace ability to enable/disable only
 * the event detection for this particular function call.
 *
 * @param params
 *     keyEventHandler: function to handle keystroke events
 *     enableEventParams: parameters used to setup event detection
 *     handleEventParams: parameters used by the keystroke handler
 */
const enableDisableKeyEvent = (params) => {
	params = Object.assign({
		keyEventHandler: null,
		enableEventParams: {},
		handleEventParams: {}
	}, params);
	const moduleProps = params.handleEventParams.props;
	const enableDisableParams = Object.assign({
		supportedKeyEvents: ['keyup','keydown','keypress'],
		keyEvent: 'keydown',
		nameSpace: 'keydown.' + moduleProps.storageKey,
		eventElementId: moduleProps.myPane,
		enable: moduleProps.isMyPane
	}, params.enableEventParams);
	const $captureElement = getEventListenerElement(enableDisableParams.eventElementId);

	if (params.keyEventHandler !== null && enableDisableParams.supportedKeyEvents.includes(enableDisableParams.keyEvent)) {
		if (enableDisableParams.enable) {
			$captureElement.off(enableDisableParams.nameSpace).on(enableDisableParams.nameSpace, (event) => {
				params.keyEventHandler(event, params.handleEventParams);
			});
		} else {
			$captureElement.off(enableDisableParams.nameSpace);
		}
	} else {
		$captureElement.off(enableDisableParams.nameSpace);
	}
};
export {enableDisableKeyEvent};


/**
 * This hook can be used by modules that want to enable keystroke events.
 *
 * This is called with two functions that must be activated in the callback along
 * with the attributes to be passed to those functions.
 *
 * Function 1: A function that will enable/disable the event listener.  A jQuery
 * element object will be generated
 *
 * @param callback calls function that enables keyboard capture and sets up the key handler
 * @param params object
 *     enableDisableHandler: pointer to function that enables/disables keyboard capture
 *     keyEventHandler: pointer to function that handles key capture
 *     enableEventParams: parameters to pass to enableDisableHandler
 *     handleEventParams: parameters to pass to keyEventHandler
 */
const useKeyEvents = (callback, params) => {
	params = Object.assign({
		enableDisableHandler: null,
		keyEventHandler: null,
		enableEventParams: {},
		handleEventParams: {}
	}, params);

	const [storedEnableEventParams, setStoredEnableEventParams] = useState('');
	const [storedHandleEventParams, setStoredHandleEventParams] = useState('');

	const stringEnableEventParams = JSON.stringify(params.enableEventParams);
	const stringHandleEventParams = JSON.stringify(params.handleEventParams);

	if (stringHandleEventParams !== storedHandleEventParams || stringEnableEventParams !== storedEnableEventParams) {
		setStoredHandleEventParams(stringHandleEventParams);
		setStoredEnableEventParams(stringEnableEventParams);
		const callbackParams = {
			keyEventHandler: params.keyEventHandler,
			enableEventParams: params.enableEventParams,
			handleEventParams: params.handleEventParams
		};
		callback(params.enableDisableHandler, callbackParams);
	}
};
export {useKeyEvents};
