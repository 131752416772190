import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import {HTML_FRAGMENT} from "../../_MODULE_GLOBALS/constants";
import {GenerateHtmlMarkup} from "../../../widgets/generateHtmlMarkup";
import embedJavascript, {executeFunction} from "../../../utils/embedJavascript";
import {getObjectFromJSON} from "../../../utils/objectUtils";
import GenerateTitle from "../../../widgets/generateTitle";
import {useMountPostRender} from "../../../hooks/useMount";
import {isEmpty} from "../../../utils/generalUtils";
import { updateReplaceable} from "../../../utils/stringUtils";
import getDeviceType from "../../../utils/getDeviceType";
import {useAttributesChanged} from "../../../hooks/useAttributesChanged";


/*
	HTML Fragment Module

	Generate a DOM element from a string of html and place in the DOM and UI.

	The html is wrapped in a div that can take an optional class name.
	That's it, that's all.

	WARNING: This is a very simple module that doesn't do any checking of the
	html to see if it is dangerous.  Use with care.

	NOTE: i18N Strings:  You can embed i18N strings into the body of the embed
	    1.  Add an attribute called "replacementStrings"
	    2.  Add the replacement key into the embed by adding them as {string.key.name}
	        where string.key.name follows the standard for i18N strings.
	    3.  Since this is usually a custom module, you would normally add these strings
	        to the Custom Strings
	    4.  Replacement will only be done if the replacement key is found in the embed

	    Sample:
	        "replacementStrings": {
	            "message": "fragmentStringName.message"
	        },
            "embed": "<h2>{message}</h2>"

            In Custom Strings
                "fragmentStringName": {
                    "message": "This is the message to display"
                }

 */
/*
	list of potential configuration parameters; other than query params
		className (string): (optional) class name to add to div
		replacementStrings (object): (optional) set of strings to replace in body of embed
		embed {string}: (required) embed from content provider
*/


/**
 * Check if embed has content.
 * If so, check to see if there are any replacement strings in the configuration.
 *     If so, call to replace them from i19nStrings.
 *
 * @param params
 *     embed: original embed string
 *     replacementStrings: from configuration
 *     i18nStrings: i18nStrings object
 * @returns {*|string} updated embed string
 */
const replaceEmbedStrings = (params) => {
	params = Object.assign({
		embed: '',
		replacementStrings: {},
		i18nStrings: {}
	}, params);
	let htmlEmbed = !isEmpty(params.embed) ? params.embed : '';

	if (!isEmpty(params.replacementStrings)) {
		Object.keys(params.replacementStrings).forEach(replaceKey => {
			const i18nKey = params.replacementStrings[replaceKey];
			if (params.i18nStrings.hasOwnProperty(i18nKey)) {
				htmlEmbed = updateReplaceable({
					original: htmlEmbed,
					replaceKey: replaceKey,
					replacement: params.i18nStrings[i18nKey]
				});
			}
		});
	}
	return htmlEmbed;
};



export const HtmlFragmentModule = (props) => {
	// both check if embed exists and replace any i18nStrings in embed
	const htmlEmbed = replaceEmbedStrings({
		embed: props.embed,
		replacementStrings: props.replacementStrings,
		i18nStrings: props.i18nStrings
	});

	// returns titleParams, className, storageKey, queryParams
	// NOTE: This module does NOT display title
	const mops = generateMops(props, {
		defaultKey: HTML_FRAGMENT,
		defaultClass: 'html-fragment',
		title: 'htmlFragment.title',
		titleTag: 'h2',
		configQueryParams: {}
	});

	// run once, since props.src won't change, to call to embed and execute javascript src
	// "src" is DEPRECATED in favor of "body" for configuration
	useMountPostRender(() => {
		if (!isEmpty(props.src)) {
			embedJavascript(props.src);
		}
	});
	useMountPostRender(() => {
		if (!isEmpty(props.body)) {
			embedJavascript(props.body);
		}
	});

	useMountPostRender(() => {
		if (!isEmpty(props.init)) {
			executeFunction(props.init, props.initParams);
		}
	});

	// run on breakpoint change
	const currentBreakpoint = getDeviceType();
	useAttributesChanged(() => {
		if (!isEmpty(props.jsOnChange)) {
			executeFunction(props.jsOnChange, props.jsOnChangeParams);
		}
	}, [currentBreakpoint]);

/*
	// need to figure out a better way to trigger on change, as this gets called too often
	// run on every render
	useEffect(() => {
		if (props.jsOnChange) {
			executeFunction(props.jsOnChange, props.jsOnChangeParams);
		}
	});
*/


	/*
	* Generate jsx for html if displayOnDevice
	* Call GenerateHtmlMarkup function to generate html.
	*/
	if (displayOnDevice(props)) {
		return (
			<div className={mops.className}>
				<GenerateTitle titleParams={mops.titleParams} />
				<GenerateHtmlMarkup htmlMarkup={htmlEmbed} />
			</div>
		);
	} else {
		return null;
	}

};


/**
 * Map state (store) data.
 *
 * storageKey provides a way to map a specific store object to a particular
 * instance of the module.  Add storageKey when configuring the module instance
 * if it needs to listen for a store change.
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {{filters: Array}}
 */
const mapStateToProps = (state, props) => {
	const htmlProps = {
		active: getActiveAttributeState(props),
		replacementStrings: props.hasOwnProperty('replacementStrings') ? props.replacementStrings : {},
		i18nStrings: state.i18nStrings
	};

	if (props.executeJavascriptOnChange) {
		const changeJS = getObjectFromJSON(props.executeJavascriptOnChange, {});
		htmlProps.jsOnChange = changeJS.hasOwnProperty('function') ? changeJS.function : null;
		htmlProps.jsOnChangeParams = changeJS.hasOwnProperty('params') ? changeJS.params : [];
	}
	return htmlProps;
};

/**
 * simpleImage module (currently) makes to calls to fetch of update data.
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HtmlFragmentModule);
