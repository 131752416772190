import {manageUpdateAndNavigation} from "../../../globals/globalNavigator";
import {isEmpty, isTrue} from "../../../../utils/generalUtils";
import {stringsEqual} from "../../../../utils/stringUtils";
import {getObjectFromJSON, clone} from "../../../../utils/objectUtils";
import {dropdownValues as searchTypeValues} from "../filters/searchType";
import {dropdownValues as searchSortValues} from "../filters/searchSort";
import {dropdownValues as searchTargetValues} from "../filters/searchTarget";



// used to generate query parameters for url generation
const reducerToFilter = {
	"searchSortFilter": "sortBy",
	"searchArticleSearchFilter": "articleSearch",
	"searchTypeFilter": "type",
	"searchIssuesFilter": "issueUrl",
	"searchEntryFilter": "entry"
};

/**
 * Call to update data for a specific search filter when a search filter is updated.
 * Then call manageUpdateAndNavigation to navigate to search results and update.
 * Call to update inProgress on change
 *
 * @param params
 *     props module props
 *     navigationKey: caller can set their own navigationKey
 *     searchValue: value to set for the search filter
 *     searchReducer: specific search filter to update in search.js reducer
 *     shareEntryValue: to set a search entry value to populate url; null: pickup from filters
 */
const updateFilterOnChange = (params) => {
	params = Object.assign({
		props: {},
		navigationKey: 'searchFilter',
		searchValue: '',
		searchReducer: '',
	}, params);
	const props = clone(params.props);
	const searchFilters = props.hasOwnProperty('searchFilters') ? props.searchFilters : {};

	const shareQueryParams = {};
	Object.keys(reducerToFilter).forEach(reducerName => {
		const filterName = reducerToFilter[reducerName];
		// if searchReducer matches reducerName, use the passed in value otherwise use the stored value
		shareQueryParams[filterName] = reducerName === params.searchReducer ? params.searchValue : searchFilters[filterName];
	});
	const searchValue = (stringsEqual(params.searchReducer, 'searchEntryFilter') && isEmpty(params.searchValue)) ? '' : params.searchValue;

	manageUpdateAndNavigation({
		navigationKey: params.navigationKey,
		moduleProps: props,
		attributes: {
			[params.searchReducer]: searchValue,
			shareQueryParams: shareQueryParams
		}
	});
};
export {updateFilterOnChange};


/**
 * If the filter value is already stored in session storage, use that value.
 * Otherwise, check to see is a defaultValue has been set in the configuration.
 * Otherwise, set the defaultValue to the first item in the dropdown list.
 *
 * @param params
 *     props: module props
 *     storageFilterName: session storage filter name
 *     defaultSearchValue: default search value from module
 * @returns {*|string} default value
 */
const getDefaultFilterValue = (params) => {
	params = Object.assign({
		props: {},
		defaultSearchValue: '',
		searchFiltersValue: '',
		forceFetch: false
	}, params);

	if (isTrue(params.forceFetch) && !isEmpty(params.searchFiltersValue)) {
		return params.searchFiltersValue;
	} else if (params.props.hasOwnProperty('defaultValue')) {
		return params.props.defaultValue;
	} else {
		return params.defaultSearchValue;
	}
};
export {getDefaultFilterValue};

/**
 * Initialize selection (dropdown) filters
 *
 * @param params
 *     props: module props
 *     storageFilterName: session storage filter name
 *     reducerFilterName: filter name in search.js reducer
 *     filters: filters object from reducer action
 *     defaultDropdownOrder: default order as defined in filter module
 *     dropdownValues: filter module values defined in filter module
 *
 * @returns {{filterValue: (*), defaultValue: *, defaultSelection: *, order}}
 */
const defineSelectionFilter = (params) => {
	params = Object.assign({
		props: {},
		searchFiltersValue: '',
		defaultDropdownOrder: [],
		dropdownValues: {},
		forceFetch: false
	}, params);
	const {props, searchFiltersValue, defaultDropdownOrder, dropdownValues, forceFetch} = params;
	const dropdownOrder = props.hasOwnProperty('dropdownOrder') ? getObjectFromJSON(props.dropdownOrder, defaultDropdownOrder) : defaultDropdownOrder;

	const defaultSelection = dropdownOrder.hasOwnProperty('default') ? dropdownOrder.default : dropdownOrder.order[0];
	const defaultValue = getDefaultFilterValue({
		props: props,
		defaultSearchValue: dropdownValues[defaultSelection].value,
		searchFiltersValue: searchFiltersValue,
		forceFetch: forceFetch
	});
	// set the actual filter value from filters if set, else use defaultValue
	const filterValue = isEmpty(searchFiltersValue) ? defaultValue : searchFiltersValue;

	return {order: dropdownOrder.order, defaultValue: defaultValue, defaultSelection: defaultSelection, filterValue: filterValue};
};
export {defineSelectionFilter};


/**
 * Initialize filters and initialise searchFilters object for the base searchResults module
 *
 * Check state.searchFilters for status first.  By default, state filters are set to null to show that
 * they haven't been initialized.  Once initialized, they will have a value other than null.
 * If null, check to see if there is a matching session variable for the filter.
 * If not, use the default value for the filter.
 *
 * @param params
 *     storeState: store state
 *     props: module props
 *     state: full state object
 *     allSearchFilters: all filters from searchResults module
 *
 * @returns {{filters: {}, searchFilters: string}}
 */
const defineSearchResultFilters = (params) => {
	params = Object.assign({
		storeState: {},
		props: {},
		state: {},
		allSearchFilters: {}
	}, params);
	const {storeState, props, state, allSearchFilters} = params;

	const searchFilters = [];
	const defaults = props.hasOwnProperty('defaults') ? props.defaults : {};

	let newFilters = {};
	if (storeState.hasOwnProperty('searchFilters')) {
		newFilters = clone(storeState.searchFilters);
	} else {
		Object.keys(allSearchFilters).forEach((filterName) => {
			newFilters[filterName] = allSearchFilters[filterName].default;
		});
	}

	// if searchResult Configuration defaults are set, then pick them up and use them
	// Note: these values are only used if the associated filter module is not configured (isEmpty => undefined/null)
	if (!isEmpty(defaults)) {
		if (isEmpty(newFilters.type) && defaults.hasOwnProperty('searchType') && searchTypeValues.hasOwnProperty(defaults.searchType)) {
			newFilters.type = searchTypeValues[defaults.searchType].value;
		}
		if (isEmpty(newFilters.sortBy) && defaults.hasOwnProperty('searchSort') && searchSortValues.hasOwnProperty(defaults.searchSort)) {
			newFilters.sortBy = searchSortValues[defaults.searchSort].value;
		}
		// handle target a little different; we only check is/not matching 'article' for articleSearch
		if (isEmpty(newFilters.articleSearch) && defaults.hasOwnProperty('searchTarget') && searchTargetValues.hasOwnProperty(defaults.searchTarget)) {
			newFilters.articleSearch = searchTargetValues[defaults.searchTarget].value === 'article';
		}
	}
	// if we only use a single Document/issue, then set to a single documentId
	// check to make sure issueUrl matches the document in the (single document) list
	if (state.singleDocumentCollection && !isEmpty(state.documentList)) {
		const navigationIssueUrl = state.urlNavigationTarget.issueUrl;
		const issue = state.documentList.find(issue => issue.issueUrl === navigationIssueUrl);
		newFilters.issueUrl = typeof issue !== 'undefined' ? issue.issueUrl : '';
	}

	// Each state filter is null by default until set by a module (one-time action).  If it is null, use default value
	Object.keys(newFilters).forEach((filterName) => {
		const filterValue = newFilters[filterName];
		if (filterValue === null) {
			const storageTerm = allSearchFilters[filterName].storageTerm;
			const defaultValue = defaults.hasOwnProperty(storageTerm) ? defaults[storageTerm] : '';
			newFilters[filterName] = getDefaultFilterValue({props: props, storageFilterName: storageTerm, defaultValue: defaultValue});
			newFilters[filterName] = filterName === 'articleSearch' ? newFilters[filterName] === 'article' : newFilters[filterName];
		}
		searchFilters.push(newFilters[filterName]);
	});

	return {searchFilters: newFilters, searchFiltersString: JSON.stringify(searchFilters)};
};
export {defineSearchResultFilters};
