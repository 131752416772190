import React from 'react';
import {useMountPostRender} from "../../../../hooks/useMount";
import {connect} from 'react-redux';
import {Translate} from "../../../../locales/locales";
import {SEARCH_FILTERS, SEARCH_PROGRESS, SEARCH_RESULTS} from "../../../_MODULE_GLOBALS/constants";
import { updateData } from '../../../../store/actions';
import GenerateTitle from "../../../../widgets/generateTitle";
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../../utils/moduleSetup";
import {defineSelectionFilter, updateFilterOnChange} from "../searchUtils/filterUpdate";
import {isTrue, isEmpty} from "../../../../utils/generalUtils";


const dropdownValues = {
	'any': {'value':'0', 'display': 'search.type.any'},
	'all': {'value': '1', 'display': 'search.type.all'},
	'exact': {'value': '2', 'display': 'search.type.exact'},
};
export {dropdownValues};  // use: import {dropdownValues as searchTypeDropdownValues} from "../filters/searchType";

const defaultDropdownOrder = {
	default: "any",
	order: ["any", "all", "exact"]
};


export const SearchTypeModule = (props) => {
	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: SEARCH_RESULTS,
		defaultClass: 'search-type',
		title: 'search.type.title',
		titleTag: 'span',
		configQueryParams: {}
	});

	// runs only once on init
	useMountPostRender(() => {
		// update filter value on startup
		props.updateData({searchTypeFilter: props.searchType}, {storageKey: props.storageKey});
	});


	/*
	* Generate jsx for search type dropdown if displayOnDevice
	*/
	if (displayOnDevice(props)) {
		return (
			<div className={mops.className}>
				<GenerateTitle titleParams={mops.titleParams} />
				<select
					aria-label={Translate.Text({id: 'search.type.ariaLabel'})} defaultValue={props.defaultValue}
					onChange={(evt) => {
						const searchValue = evt.currentTarget.value;
						updateFilterOnChange({props: props, searchValue: searchValue, searchReducer: 'searchTypeFilter'});
					}}>
					{
						props.dropdownOrder.map((selection) => {
							return (
								<option key={dropdownValues[selection].value} value={dropdownValues[selection].value}>
									{Translate.Text({id: dropdownValues[selection].display})}
								</option>
							);
						})
					}
				</select>
			</div>
		);
	} else {
		return null;
	}
};

/**
 * Map the store object you are using.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{dropdownOrder, searchType: (*|string), defaultValue: (*|string), active: (*|string), storageKey: (*|string)}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = props.storageKey ? props.storageKey : SEARCH_RESULTS;
	const storageState = state.hasOwnProperty(storageKey) ? state[storageKey] : {};
	// filters set through reducer; module filter is null if not explicitly set
	const searchFilters = storageState.hasOwnProperty('searchFilters') ? storageState.searchFilters : {};
	const searchFiltersValue = !isEmpty(searchFilters.type) ? searchFilters.type : '';

	// set default values from configuration
	const defaultFilterValues = defineSelectionFilter({
		props: props,
		searchFiltersValue: searchFiltersValue,
		defaultDropdownOrder: defaultDropdownOrder,
		dropdownValues: dropdownValues,
		forceFetch: isTrue(storageState.forceFetch)
	});
	const dropdownOrder = defaultFilterValues.order;
	const defaultValue = defaultFilterValues.defaultValue;
	const searchType = defaultFilterValues.filterValue;

	return {
		storageKey: storageKey,
		active: getActiveAttributeState(props),
		searchFilters: searchFilters,
		dropdownOrder: dropdownOrder,
		defaultValue: defaultValue,
		searchType: searchType,
	};
};

/**
 * Actions that can be called by this module.
 *
 * actions:
 *     updateData call to update the store with new properties
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		updateData: (payload, params) => {
			params.type = SEARCH_FILTERS;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchTypeModule);
