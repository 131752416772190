import Cookies from "universal-cookie";
import {isEmpty, isTrue} from "./generalUtils";


const getCookie = (req, key) => {
	const cookies = !isEmpty(req) ? new Cookies(req.headers.cookie) : new Cookies();
	const cookieValue = cookies.get(key);
	if (!isEmpty(cookieValue)) {
		return cookieValue;
	} else {
		return null;
	}

};

export { getCookie };


const setCookie = (req, key, value, options) => {
	options = Object.assign({
		path: "/",
		domain: '',
		secure: false,
		httpOnly: false,
		sameSite: "none",
		expires: null,
		maxAge: (3600 * 24 * 60)  // 60 days
	}, options);

	const cookieOptions = {
		path: options.path,
		secure: isTrue(options.secure, {default: false}),
		httpOnly: isTrue(options.httpOnly, {default: false}),
		sameSite: options.sameSite
	};
	if (!isEmpty(options.expires)) {
		cookieOptions.expires = options.expires;
	} else {
		cookieOptions.maxAge = options.maxAge;
	}
	if (!isEmpty(options.domain)) {
		cookieOptions.domain = options.domain;
	}

	const cookies = !isEmpty(req) ? new Cookies(req.headers.cookie) : new Cookies();
//	cookies.set(key, value, {path: path, secure: secure, httpOnly: httpOnly, sameSite: sameSite, maxAge: maxAge});
	cookies.set(key, value, {...cookieOptions});

//	cookies.set("fla", "five", {path: "/" + collectionUrl, secure: false, httpOnly: false, sameSite: "None", maxAge: (3600 * 24 * 60)});


};

export { setCookie };

