import React from 'react';
import {Translate} from "../../../locales/locales";
import {isTrue} from "../../../utils/generalUtils";
import {addClass} from "../../../utils/generateClassName";


/**
 * Generate jsx for search results found, passed in from the search results module.
 * Call Translate to display text from string based on the results
 *
 * @param params
 *     entry: string value for search
 *     results: array of results
 *     searchInProgress:
 *         true: search has not completed
 *         false: search has completed, display number of results
 * @returns {JSX.Element}
 * @constructor
 */
export const DisplaySearchResultsFound = (params) => {
	params = Object.assign({
		entry: '',
		results: [],
		searchInProgress: true,
		clearFilters: false
	}, params);
	const { entry, results, searchInProgress, clearFilters } = params;

	const baseClass = 'search-display';
	const titleId = clearFilters ? 'search.display.cleared' : 'search.display.results';

	const total = searchInProgress ? 0 : results.length;
	const resultsSearchClasses = [
		isTrue(searchInProgress) ? 'search-display-in-progress' : 'search-display-complete',
		total === 0 ? ' search-display-no-results' : ' search-display-has-results',
		isTrue(clearFilters) ? 'search-display-cleared-search' : ''
	];
	const widgetClassName = addClass(baseClass, resultsSearchClasses);

	return (
		<div className={widgetClassName} tabIndex="-1">
			<Translate.Text id={titleId} replacements={{total, entry}} html={true} />
		</div>
	);
};
