import { takeEvery, call, put, all } from "redux-saga/effects";
import {
	DATA_LOADED,
	DATA_REQUESTED,
	DATA_FETCH_SUCCEEDED,
	DATA_FETCH_FAILED,
	DATA_SAVE_REQUESTED,
	DATA_DELETE_REQUESTED,
	DATA_DELETED,
	DATA_SAVED,
	API_ERRORED,
	
} from "../../modules/_MODULE_GLOBALS/constants";

const sagas = [
	takeEvery(DATA_REQUESTED, fetchDataSaga),
//	takeEvery(DATA_FETCH_REQUESTED, dataFetchSaga),
	takeEvery(DATA_SAVE_REQUESTED, saveDataSaga),
	takeEvery(DATA_DELETE_REQUESTED, deleteDataSaga)
];

export default function* watcherSaga() {
	yield all(sagas);
}

function* fetchDataSaga(args) {
	try {
		// TODO: args and args.params feels a little weird; fix if possible
		const payload = yield call(fetchData, args.params);
		yield put({ type: DATA_LOADED, payload, params: args.params });
	} catch (e) {
		console.log(e);
		yield put({ type: API_ERRORED, payload: e });
	}
}

function fetchData(params) {
	return fetch(params.url).then(response =>
		response.json()
	);
}


/**
 * Used for post and put apis
 *
 * @param params
 *     args: arguments for saving the data
 *     add details
 */
function* saveDataSaga(args) {
	try {
		// TODO: args and args.params feels a little weird; fix if possible
		const payload = yield call(saveData, args.params);
		yield put({ type: DATA_SAVED, payload, params: args.params });
	} catch (e) {
		console.log(e);
		yield put({ type: API_ERRORED, payload: e });
	}
}

/**
 * Used for post and put apis
 *
 * @param params
 *     params: arguments for saving the data
 *     required arguments should be url, method and body
 *     method : PUT or POST
 *     body : a JSON object of the data that will be stored
 * returns response, which will have the saved object and ids of the saved object
 */
function saveData(params) {
	return fetch ( params.url, { "method": params.method,
		"body": params.body,  
		"headers": {"Content-type": "application/json; charset=UTF-8"}})
		.then(response => response.json()
		);
}


function* deleteDataSaga(args) {
	try {
		const payload = yield call(deleteData, args.params);
		yield put({ type: DATA_DELETED, payload, params: args.params });
	} catch (e) {
		console.log(e);
		yield put({ type: API_ERRORED, payload: e });
	}
}

function deleteData(params) {
	return fetch(params.url, {"method":"DELETE", 
		"headers": {"Content-type": "application/json; charset=UTF-8"}}).then(response =>
		response.json()
	);
}
// TEST ONLY FOR MULTIPLE SAGAS - NOT CURRENTLY USED
const apiFetch = "https://jsonplaceholder.typicode.com/posts";
// worker Saga: will be fired on DATA_FETCH_REQUESTED actions
function* dataFetchSaga(action) {
	try {
		const user = yield call(apiFetch, action.payload.userId);
		yield put({type: DATA_FETCH_SUCCEEDED, user: user});
	} catch (e) {
		yield put({type: DATA_FETCH_FAILED, message: e.message});
	}
}

