import React from 'react';
import { Translate } from "../../locales/locales";
import * as Tracking from "../../utils/tracking";
import {GLOBALS, FOOTNOTE} from "../../modules/_MODULE_GLOBALS/constants";
import {getStoreValue} from "../../utils/storeValue";


export const FootnoteWidget = (props) => {
	
	const href = getStoreValue({type: GLOBALS, storageKey: FOOTNOTE, attributeKey:"footnoteHref"});
	const footnotes = getStoreValue({type: GLOBALS, storageKey: FOOTNOTE, attributeKey:"footnotes"});
	const articleTitle = getStoreValue({type: GLOBALS, storageKey: FOOTNOTE, attributeKey:"articleTitle"});
	const issueName = getStoreValue({type: GLOBALS, storageKey: FOOTNOTE, attributeKey:"issueName"});
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
	const currentFootnoteRefs = typeof href === "undefined" ? [] : href.split(",");
	let currentFootnotesTitle = Translate.Text({id:'article.footnoteLightbox.title'}) + " ";
	let currentFootnotes = "";
	let currentFootnoteIds = [];
	currentFootnoteRefs.forEach( (ref, idx) => {
		const footnoteId = ref.replace("#footnote","");
		if (idx > 0) {
			currentFootnotes += "<br/>";
		}
		currentFootnotesTitle += "[";
		currentFootnotesTitle += footnoteId;
		currentFootnotesTitle += "]";
		currentFootnotes += footnotes[ref];
		currentFootnoteIds.push(footnoteId);
	});

	Tracking.libraryTrack("content viewed", {
		"type": "footnote view",
		"footnote id": currentFootnoteIds,
		"article title": articleTitle,
		"issue name": "_" + issueName
	});
	
	updateData({"modalTitle": currentFootnotesTitle}, {type: GLOBALS, storageKey: FOOTNOTE});
	updateData({"modalMarkup": currentFootnotes}, {type: GLOBALS, storageKey: FOOTNOTE});
	
	return true;
};
