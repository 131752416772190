import React, {useState, useEffect} from 'react';
import $ from 'jquery';
import {useAttributesChanged} from "./useAttributesChanged";
import {isEmpty, isTrue} from "../utils/generalUtils";



/**
 * Hook that waits for the referenced image element to be loaded
 * then calls the callback function.
 *
 * Note: This uses jQuery to listen for load event in order to take advantage
 * of jQuery namespace ability.
 * The "load" event will not trigger if the image is already loaded, so
 * we don't try to listen again if we have already stored the element.
 *
 * NoteL This is slightly simpler that useImageLoadedMultiple, as you only
 * need to specify a single image and attributes, and don't need to pass
 * anything back, since you know all the attributes you have specified at
 * the time the hook call is made.
 *
 * @param callback function callback when conditions are satisfied
 * @param params
 *     imgElement: element to detect if loaded
 *     imgIdentifier: identifier for image; also used for namespace for removal of listener
 *     additionalAttributes: other properties to test if change trigger
 */
const useImageLoaded = (callback, params) => {
	params = Object.assign({
		imgElement: null,
		imgIdentifier: '',
		additionalTestAttributes: [],
	}, params);
	const [storedImgElement, setStoredImgElement] = useState(null);

	const imgElement = !isEmpty(params.imgElement) ? params.imgElement : null;
	const imgIdentifier = !isEmpty(params.imgIdentifier) ? params.imgIdentifier : '';
	const additionalTestAttributes = Array.isArray(params.additionalTestAttributes) ? params.additionalTestAttributes : [];
	const attributes = [imgElement, imgIdentifier].concat(additionalTestAttributes);

	useAttributesChanged(() => {
		if (!isEmpty(imgElement)) {
			const isLoaded = isTrue(imgElement.complete, {defaultValue: false}) && imgElement.naturalHeight > 0;
			if (storedImgElement !== imgElement && !isLoaded) {
				setStoredImgElement(imgElement);
				const loadNamespaced = !isEmpty(imgIdentifier) ? 'load.' + imgIdentifier : 'load';
				$(params.imgElement).off(loadNamespaced).on(loadNamespaced, (evt) => {
					callback(evt);
				});
			} else {  // image loaded, or some other attribute changed
				callback(null);
			}
		}
	}, attributes);
};
export {useImageLoaded};


/**
 * Hook that can take multiple images to wait for each image to load.  Each image
 * is processed separately and will call the callback when it is loaded, or
 * immediately if it is already loaded.  This means, that if there are five
 * images passed in, the callback will be called for each image as it is
 * loaded.
 *
 * Note: This uses jQuery to listen for load event in order to take advantage
 * of jQuery namespace ability.
 * The "load" event will not trigger if the image is already loaded, so
 * we don't try to listen again if we have already stored the element.
 *
 * @param callback function callback when conditions are satisfied
 * @param images array of images with attributes for the images.  Each image has the following attributes:
 *     imgElement: image DOM element
 *     imgIdentifier: identifier for image; also used for namespace for removal of listener
 *     additionalAttributes: other properties to test if change trigger
 *     returnAttributes: additional attributes that you want to be passed back
 */
const useImageLoadedMultiple = (callback, images) => {
	images = Array.isArray(images) ? images : [];

	const [storedImgElements, setStoredImgElements] = useState([]);

	// gather all the passed in image attributes to check for change
	let attributes = [];
	if (!isEmpty(images)) {
		images.forEach(imgAttributes => {
			attributes = attributes.concat([imgAttributes.imgElement, imgAttributes.imgIdentifier], imgAttributes.additionalTestAttributes);
		});
	}
	const imgElements = images.map(imgAttributes => {
		return imgAttributes.imgElement;
	});

	useAttributesChanged(() => {
		images.forEach(imgAttributes => {
			const imgElement = !isEmpty(imgAttributes.imgElement) ? imgAttributes.imgElement : null;
			const imgIdentifier = !isEmpty(imgAttributes.imgIdentifier) ? imgAttributes.imgIdentifier : '';
			const returnAttributes = !isEmpty(imgAttributes.returnAttributes) ? imgAttributes.returnAttributes : {};
			if (!isEmpty(imgElement)) {
				const isLoaded = isTrue(imgElement.complete, {defaultValue: false}) && imgElement.naturalHeight > 0;
				if (!isLoaded && !storedImgElements.includes(imgElement)) {
					const loadNamespaced = !isEmpty(imgIdentifier) ? 'load.' + imgIdentifier : 'load';
					$(imgElement).off(loadNamespaced).on(loadNamespaced, (evt) => {
						callback(evt, {imgElement: imgElement, imgIdentifier: imgIdentifier, returnAttributes: returnAttributes});
					});
				} else {  // image loaded, or some other attribute changed
					callback(null, {imgElement: imgElement, imgIdentifier: imgIdentifier, returnAttributes: returnAttributes});
				}
			}
		});
		setStoredImgElements(imgElements);

	}, attributes);
};
export {useImageLoadedMultiple};
