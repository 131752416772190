import React, { useState } from 'react';
import {connect} from 'react-redux';
import { ISSUE_LIST, ID_NOT_SET} from "../../_MODULE_GLOBALS/constants";
import {fetchData} from '../../../store/actions';
import GeneratePages, {GenerateInProgressPage} from "../../../widgets/generatePages";
import GenerateTitle from "../../../widgets/generateTitle";
import {
	generateMops,
	getActiveAttributeState,
	displayOnDevice,
	devicePageGrid,
	getIssueGroup
} from "../../../utils/moduleSetup";
import * as Tracking from '../../../utils/tracking';
import {Translate} from "../../../locales/locales";
import {manageUpdateAndNavigation} from "../../globals/globalNavigator";
import {getObjectFromJSON, clone} from "../../../utils/objectUtils";
import getDeviceType from "../../../utils/getDeviceType";
import {getDestinations} from "../../../utils/getDestinations";
import { populateFetchDataAttributes } from "../../../utils/populateFetchDataAttributes";
import {getIntValue, isEmpty, isTrue} from "../../../utils/generalUtils";
import {ExternalUrl} from "../../../environment";
import {getStoreValue} from "../../../utils/storeValue";
import {addParamsToUrl} from "../../../utils/urlParamUtils";
import {useFetchAttributesChange, useFetchComplete} from "../../../hooks/useFetchChange";
import {getIssueList} from "../../../utils/issueList/getIssueList";
import {checkIfMyPane} from "../../globals/globalLibraryData";


// list of possible query params sent with api call
// key is props name; value is fetch parameter
const configQueryParams = {
//	'maxEntries':'maxEntries',  // maxEntries has been removed
	'pageSize':'pageSize',  // maxEntries was an alias for pageSize
	'sortBy':'sortBy',
	'publicationIds':'publicationIds',
//	'tagsFilter':'tagsFilter',   // sent as "tags" for fetch
	'tags':'tags',
	'startPubdate':'startPubdate',
	'endPubdate':'endPubdate',
	'excludeIssueUrls': 'excludeIssueUrls',
	'u1': 'u1',
};

/*
	list of potential configuration parameters; other than query params
		className: optional class name to add to div
		displayTitle: {true/false} - display the title; default false if not set
		maxDisplay: number of entries per page
		i18nId: {string} - key to string for title in strings (locale) file
		gridColumns: {object} - number of columns at different pure grid breakpoints
			sm: {number}
			md: {number}
			lg: {number}
 */


/**
 * Check navigationKeys for links to other modules.  Determine if
 * the destination is internal or external (default).
 *
 * @param params
 *     navigationKeys: configured navigation keys
 *     navigationKey: key to use for navigation lookup
 * @returns {string|*|string}
 */
const getDestination = (params) => {
	params = Object.assign({
		navigationKeys: null,
		navigationKey: 'issue',
	}, params);
	const navigationKeys = getObjectFromJSON(params.navigationKeys, {});
	const navigationKey = !isEmpty(params.navigationKey) ? params.navigationKey : 'issue';
	const device = getDeviceType();
	const destinations = getDestinations({navigationKeys: navigationKeys});
	const destination = destinations.hasOwnProperty(device) ? destinations[device] : {};
	if (navigationKeys.hasOwnProperty(navigationKey)) {
		return destination.hasOwnProperty(navigationKey) ? destination[navigationKey] : 'internal';
	} else {
		return 'external';
	}
};


/**
 * Generate a single page of issues for paging.
 * For the page marked entryPage, generate a 'src' attribute
 * with the image.  For other pages, generate a 'data-src' attribute
 * to delay loading images until the page is loaded.
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GeneratePage = (params) => {
	params = Object.assign({
		entries: [],
		fullList: [],
		entryPage: 0,
		page: 0,
		mops: {},
		props: {},
		updateHistoryState: false
	}, params);
	const props = clone(params.props);
	const isEntryPage = params.page === params.entryPage;
	const issues = params.entries || [];
	const srcAttr = isEntryPage ? 'src' : 'data-src';
	const className = 'issue-page';
	const issueClasses = 'issue-entry';
	const issueStyles = {
		width: (100 / props.pageGridAttributes.columns) + '%'
	};
	const queryParams = getStoreValue({attributeKey: 'queryParams'});

	return (
		<div className={'display-page ' + (isEntryPage ? 'selected' : '')} data-page={params.page}>
			<ul className={className}>
				{
					issues.map((issue) => {
						const imgSrc = {[srcAttr]: issue.coverImage};
						const issueName = issue.issueName;
						const urlTitle = issueName.replace(/[\s:?#]/g,"_");
						const navigationKey = isTrue(issue.replicaOnly) && !isEmpty(props.replicaOnlyNavigationKey) ? props.replicaOnlyNavigationKey : (!isEmpty(props.navigationKey) ? props.navigationKey : 'issue');
						const destination = getDestination({navigationKeys: props.navigationKeys, navigationKey: navigationKey});
						const hostname = destination === 'internal' ? Translate.Text({id: "hoverText.issueLink"}) : issue.documentLink.replace(issue.url, '');
						const url = destination === 'internal' ? hostname + urlTitle : addParamsToUrl(queryParams,issue.documentLink,false);
						const issueGroup = getIssueGroup({documentList: props.documentList, issueUrl: issue.issueUrl});

						// setup onClick to pass event plus parameters
						const issueParams = {
							props: props,
							mops: params.mops,
							issue: issue,
							url: url,
							hostname: hostname
						};
						const onClickIssue = (event) => issueClicked(event, issueParams);

						return (
							<li key={issue.groupId} className={issueClasses} style={issueStyles} data-issue-group={issueGroup}>
								<div className={'issue-entry-contents'}>
									<a className={'thumbnail'} href={url} data-issuename={issueName} data-groupid={issue.groupId} onClick={onClickIssue}>
										{issue.coverImage !== ''
											? <img alt={'Cover of ' + issueName + ' issue of ' + issue.publicationDisplayName} className={'thumbnail-image'}
												   {...imgSrc} />
											: <img alt={'Cover thumbnail placeholder'} className={'thumbnail-image'}
												   src={ExternalUrl.PLACEHOLDER.GTXCEL_LIBRARY} />
										}
									</a>
									<h3 className={'issue-name'}><a href={url} data-issuename={issueName} onClick={onClickIssue}>{issueName}</a></h3>
								</div>
							</li>
						);
					})
				}
			</ul>
		</div>
	);
};


/**
 * Generate jsx for a page to display if no results found.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateNoResultsPage = () => {
	return (
		<div className="no-results">
			<h3>{Translate.Text({id: 'issueList.noResults'})}</h3>
			<p>{Translate.Text({id: 'issueList.noResultsParagraph'})}</p>
		</div>
	);
};

/**
 * Handle issue link clicked event.
 * Track event click
 *
 * Note: the url and hostname are fake, since we handle navigation internally
 *
 * @param event click event
 * @param params issue parameters
 *     props: module props
 *     issue: issue object
 *     url: url for link
 *     hostname: host name for link
 */
const issueClicked = (event, params) => {
	params = Object.assign({
		props: {},
		mops: {},
		issue: {},
		url: '',
		hostname: ''
	}, params);
	const props = !isEmpty(params.props) ? params.props : {};
	const currentIssue = !isEmpty(params.issue) ? params.issue : {};

	event.stopPropagation();

	const trackingProperties = {
		"type": "issue link",
		"module": "issue list",
		"url": params.url,
		"domain": params.hostname,
		"label": currentIssue.issueName,
		"destination type": "issue"
	};
	Tracking.libraryTrack("button clicked", trackingProperties);

	const navigationKey = isTrue(currentIssue.replicaOnly) && !isEmpty(props.replicaOnlyNavigationKey) ? props.replicaOnlyNavigationKey : (!isEmpty(props.navigationKey) ? props.navigationKey : 'issue');
	const destination = getDestination({navigationKeys: props.navigationKeys, navigationKey: navigationKey});

	if (destination === 'internal') {
		event.preventDefault();

		// parse any dynamic fetch attributes; note: the function makes a clone of props
		// add issueNames to currentIssue for fetch/navigation
		const moduleProps = populateFetchDataAttributes({
			props: props,
			data: currentIssue
		});

		manageUpdateAndNavigation({
			navigationKey: navigationKey,
			moduleProps: moduleProps,
			attributes: {
				articleId: ID_NOT_SET,
				folio: '',
				pageNumber: ID_NOT_SET,
				issueUrl: currentIssue.issueUrl,
				pdfDownloadUrl: currentIssue.pdfDownloadUrl,
				fetchQueryParams: params.mops.queryParams
			}
		});
	}
};



/**
 * Start of jsx code for module
 *
 * @param props
 * @returns {string|JSX.Element}
 * @constructor
 */
export const IssueListModule = (props) => {
	// add selectedTagId to store so the issue list will refresh on tag change
//	const [selectedTagId, setSelectedTagId] = useState(ID_NOT_SET);
	const [issueList, setIssueList] = useState([]);
	const [fetchInProgress, setFetchInProgress] = useState(false);

	/**
	 * Call to generate module-specific props, but that are generally common to all library
	 * modules.
	 * Generally, returns titleParams, className, storageKey, queryParams
	 *
	 * Note: this should be called very early so that subsequent functions can use these.
	 *
	 * @type {{queryParams: {}, titleParams: {}, className: string, storageKey: string}}
	 */
	const mops = generateMops(props, {
		defaultKey: ISSUE_LIST,
		defaultClass: 'issue-list',
		title: 'issueList.title',
		titleTag: 'h2',
		configQueryParams: configQueryParams
	});


	/**
	 * Called to manage whether the module fetches data.  If the hook calls the
	 * callback, then call getReplicaList which will determine whether to
	 * fetch a new list or get an existing list from stored data.
	 *
	 * Note: this use mops, so that must come first.
	 * Note: props is used internally in the hook.
	 */
	useFetchAttributesChange(() => {
		const storedListData = getIssueList({storageKey: mops.storageKey, queryParams: mops.queryParams});
		if (!isEmpty(storedListData.issueList)) {
			setIssueList(storedListData.issueList);
		}
		setFetchInProgress(true);
	}, {props: props, queryParams: mops.queryParams});

	/**
	 * Call hook to check to see if the fetchInProgress has been set/changed in the reducer.
	 * fetchInProgress is set true when fetch is started (DATA_REQUESTED)
	 * and set to false when fetch is done (DATA_LOADED)
	 */
	useFetchComplete((isInProgress) => {
		isInProgress = isTrue(isInProgress, {defaultValue: false});
		setFetchInProgress((isInProgress));
		if (!isInProgress) {
			const storedListData = getIssueList({storageKey: mops.storageKey, queryParams: mops.queryParams, returnStoredDataOnly: true});
			setIssueList(storedListData.issueList);
		}
	}, {requestInProgress: props.fetchInProgress, fetchInProgress: fetchInProgress});


	const moduleClassName = mops.className;
	const issuePage = 0;

	/*
	 * Generate jsx for issue list if displayOnDevice.
	 * Call to GeneratePages since, as a list,
	 * it could require multiple pages and pagination block.
	 * start a page 0; update this if we can start at another page
	 */
	if (displayOnDevice(props)) {
		return (
			<div className={moduleClassName}>
				{isEmpty(props.selectedTagLabel)?
					<GenerateTitle titleParams={mops.titleParams} /> :
					<GenerateTitle titleParams={{i18nId: props.selectedTagLabel, displayTitle: true}} />
				}
				{fetchInProgress ?
					<GenerateInProgressPage className={"in-progress"} displaySpinner={true} /> : issueList.length === 0 ?
						<GenerateNoResultsPage /> :
						<GeneratePages props={props} mops={mops} fullList={issueList} entries={issueList} className={'issues-pages'} cssClass={mops.cssClass} GeneratePage={GeneratePage} entryPage={issuePage} />
				}
			</div>
		);
	} else {
		return null;
	}
};


/**
 * Map state (store) data for the issueList module; added to module props.
 *
 * global params
 *     collection
 *     collectionUrl
 * issueList (array of issue objects)
 *     coverImage (fully-qualified link to cover image)
 *     description
 *     documentLink (full-qualified url to issue)
 *     groupId (issue id)
 *     issueName
 *     publication (publication short name)
 *     publicationDisplayName (publication display name)
 *     publicationId
 *     publishDate
 *     ticketId
 *     userHasAccess
 *     accessLevel
 *     replicaOnly
 *     url (issue short url)
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : ISSUE_LIST;
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};

	const grid = getObjectFromJSON(props.grid, {});
	const pageGridAttributes = devicePageGrid(grid);
	const selectedFilterTag = !isEmpty(storeState.selectedTag) ? storeState.selectedTag : {};
	const selectedTagId = getIntValue(selectedFilterTag.tagId, ID_NOT_SET);
	const myPane = checkIfMyPane({moduleName: 'issueList', instanceId: props.instanceId, storageKey: storageKey});
	const pageSize = getIntValue(props.pageSize, 200, {zeroEmpty: true});

	const issueProps = {
		storageKey: storageKey,
		collectionName: !isEmpty(storeState.collection) ? storeState.collection : [],
		collectionUrl: !isEmpty(storeState.collectionUrl) ? storeState.collectionUrl : [],
		issueGroups: !isEmpty(state.publicationIssueGroups) ? clone(state.publicationIssueGroups) : [],
		selectedTagId: selectedTagId,
		destinations: getDestinations(props),
		excludeIssueUrls: !isEmpty(props.excludeIssueUrls) ? props.excludeIssueUrls : [],
		active: getActiveAttributeState(props),
		pageGridAttributes: pageGridAttributes,
		fetchInProgress: isTrue(storeState.fetchInProgress, {defaultValue: false}),
		isMyPane: myPane.isMyPane,
		myPane: myPane.myPane,
		navigationKey: !isEmpty(props.navigationKey) ? props.navigationKey : 'issue',
		replicaOnlyNavigationKey: !isEmpty(props.replicaOnlyNavigationKey) ? props.replicaOnlyNavigationKey : '',
		documentList: state.documentList,  // used to generate issueGroup data attribute for UI
	};

	// tagsFilter configuration attribute takes precedence in determining fetch of issue list
	// after that, consider if there is an issuesFilter module feeding into this
	if (!isEmpty(props.tagsFilter)) {
		issueProps.selectedTagLabel = '';
		issueProps.tags = props.tagsFilter;
	} else {
		// selected tag is -1 indicates ALL issues
		const issueGroup = (selectedTagId === -1)
			? {
				name: Translate.Text({id: 'issuesFilter.allTag'}),
				tags: '*'
			}
			: issueProps.issueGroups.find(issueGroup => issueGroup.tagId === selectedTagId);
		if (!isEmpty(issueGroup)) {
			issueProps.selectedTagLabel = !isEmpty(selectedFilterTag.tagLabel) ? selectedFilterTag.tagLabel : issueGroup.name;
			issueProps.tags = !isEmpty(issueGroup.tags) ? issueGroup.tags : issueGroup.name;
			issueProps.pageSize = getIntValue(selectedFilterTag.pageSize, pageSize, {zeroEmpty: true});

		} else {
			const initialTag = !isEmpty(props.initialTag) ? props.initialTag : {};
			issueProps.selectedTagLabel = !isEmpty(initialTag.label) ? initialTag.label : '';
			issueProps.tags = !isEmpty(initialTag.group) ? initialTag.group : '*';
			issueProps.pageSize = getIntValue(initialTag.pageSize, pageSize, {zeroEmpty: true});
		}
	}

	return issueProps;
};

/**
 * Actions that can be called by this module.  Each action is added to props so
 * that it can be called in the module, but defined in a single place with
 * appropriate parameters for the action call by this module.
 *
 * Potential query parameters (set in configuration)
 *     pageSize: (default: 200) maximum number of entries to return
 *         property: number
 *     sortBy: (default: "publishedDate-desc") sort the results
 *         properties: "publishedDate" + "-" + "asc"|"desc"
 *         includes sort order
 *          comma separated list - whatever is first is the top level sort, the second one is within that sort by the next
 *     publicationIds: (default: current publication for collection url) for multiple collections in future
 *         properties: comma separated list of publication ids
 *             specify in the order you want the publications to appear
 *     startPubdate: (default: none) to select a set of publications by date
 *         property: publication date
 *         format example : YYYY-MM-DD ( 2016-06-02)
 *     endPubdate: (default: none) to select a set of publications by date
 *         property: publication date
 *         format example : YYYY-MM-DD ( 2016-06-02)
 *
 * actions:
 *     fetchData will make a call to the server to get data
 *
 * @param dispatch call action
 * @returns {{fetchData: fetchData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		fetchData: (params) => {
			params.type = ISSUE_LIST;
			dispatch(fetchData(params));
		},
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IssueListModule);
