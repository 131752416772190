import {isEmpty} from "./generalUtils";
import {mergeNoDups} from "./objectUtils";


/**
 * Generate the value for a particular module property based on the docType,
 * if there is a defined docType.  Currently only used for articles, so
 * only applicable to articleViewer and articleList.
 *
 * Potentially, used to generate values for the following:
 *     returns STRING
 *         className: class name
 *         listType (articleList): type of list (ie. combo, grid)
 *         documentTemplate (articleViewer): article template (name) for generating article
 *     returns OBJECT
 *         cardTemplate (articleList): set of attributes used to generate a single articleList card
 *
 * Note1: the attributes parameter MUST be an object of potential values related to docType.
 *     If it is a string, then there is something wrong with the instance/abstract configuration.
 * Note2: return values correspond to the types listed above.  If not, then there is
 *     something wrong with the instance/abstract configuration.
 *
 * For more detailed discussion of configuring articleList and articleViewer for docType,
 * see the following Confluence document:
 *     https://texterity.atlassian.net/wiki/spaces/GE/pages/2730000389/Document+Type+docType+configuration
 * related Confluence documents:
 *     https://texterity.atlassian.net/wiki/spaces/GE/pages/687833290/articleList+Module+Configuration
 *     https://texterity.atlassian.net/wiki/spaces/GE/pages/687833290/articleList+Module+Configuration
 *
 *
 * @param docType type of document; from issue/document setting
 * @param attributes to generate the final value
 *     base (string): optional string; currently used only in class name
 *     default: value to use if no docType defined
 *         string or object: matching return value defined above; default to string
 *     docType (object): defined attributes matching docType from configuration
 * @returns {*}
 */
const getDocTypeAttribute = (docType, attributes) => {
	docType = typeof docType === 'string' ? docType : 'Default';
	attributes = Object.assign({
		base: '',
		default: '',
		docType: {}
	}, attributes);

	const docTypeAttributes = (!isEmpty(attributes.docType) && attributes.docType.hasOwnProperty(docType)) ? attributes.docType[docType] : attributes.default;
	if (typeof docTypeAttributes === 'string') { // return string
		return mergeNoDups(attributes.base, docTypeAttributes);
	} else { // return object; if default not defined in configuration, use empty object
		const defaultAttributes = typeof attributes.default !== 'string' ? attributes.default : {};
		return mergeNoDups(defaultAttributes, docTypeAttributes);
	}
};
export {getDocTypeAttribute};
