import React from 'react';
import {getPreviousNextPages, previousNextPageClicked} from "../utils/previousNextUtils";
import {ID_NOT_SET} from "../modules/_MODULE_GLOBALS/constants";
import {clone, cloneWithoutReplicaList} from '../utils/objectUtils';


/**
 * Generate a Previous / Next button element for replicaViewer
 *
 * @param params element params
 * @returns {JSX.Element} button element for display
 * @constructor
 */
const PreviousNextReplicaButton = (params) => {
 	params = Object.assign({
 		props: {},
 		mops: {},
 		direction: '',
		folio: '',
		pageNumber: ID_NOT_SET,
		pagesFolios: [],
 		replicaList: [],
 		documentTitle: '',
		pageSpread: '',
		zoomLevel: ''
		// clickAction: {}
 	}, params);
 	//const props = clone(params.props);
	const props = cloneWithoutReplicaList(params.props);

 	const previousNextPages = getPreviousNextPages({pagesFolios: params.pagesFolios, replicaList: params.replicaList});

 	let label;
 	let buttonPageIndex;
 	let direction;
 	if (params.direction === 'previous') {
 		label = 'Go to Previous Page';
		buttonPageIndex = previousNextPages.previousPageIndex;
 		direction = 'Previous Page';
 	} else {
 		label = 'Go to Next Page';
		buttonPageIndex = previousNextPages.nextPageIndex;
 		direction = 'Next Page';
 	}

	const buttonFolio = buttonPageIndex < 0 ? '' : params.replicaList[buttonPageIndex].folio;
	const buttonPageNumber = buttonPageIndex < 0 ? -1 : params.replicaList[buttonPageIndex].pageNumber;
	const buttonTitle = buttonPageIndex < 0 ? '' : params.documentTitle;

	// reset replaceHistoryState attribute
 	props.replaceHistoryState = false;

 	const pageClickParams = {
 		pageNumber: buttonPageNumber,
		folio: buttonFolio,
 		props: props,
 		mops: params.mops,
 		eventType: 'click',
 		direction: direction,
		documentTitle: params.documentTitle,
		pageSpread: params.pageSpread,
		zoomLevel: params.zoomLevel,
		clickAction: params.clickAction
 	};

 	return (
 		<button
 			className={params.direction + '-button' + (buttonPageIndex < 0 ? ' inactive' : '')}
 			data-folio={buttonFolio}
 			data-issuename={buttonTitle}
 			onClick={(evt) => previousNextPageClicked( {event: evt, pageClickParams: pageClickParams})}>
 			<div className={'direction-icon'} />
 			<span className={'screen-reader'}>{label}</span>
 		</button>
 	);
};

export {PreviousNextReplicaButton};
