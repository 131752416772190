import React from 'react';
import ReactDOM from 'react-dom';
import {GLOBALS} from "../../modules/_MODULE_GLOBALS/constants";
import {getStoreValue} from "../../utils/storeValue";
import {Translate} from "../../locales/locales";
import {GenerateHtmlMarkup} from "../../widgets/generateHtmlMarkup";

const Modal = (params) => {
	const storageKey = params.storageKey;
	const callback = params.callback;
	const showModal = params.showModal;
	const isShowing = getStoreValue({type: GLOBALS, storageKey: storageKey, attributeKey:"isShowing"});
	
	if (!showModal || !isShowing) {
		return null;
	}
	
	const modalTitle = getStoreValue({type: GLOBALS, storageKey: storageKey, attributeKey:"modalTitle"});
	const modalMarkup = getStoreValue({type: GLOBALS, storageKey: storageKey, attributeKey:"modalMarkup"});
	if (typeof modalMarkup === "undefined" || modalMarkup === null) {
		callback();
	}
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
	const closeButtonAction = (event) => {
		updateData({"modalMarkup": undefined}, {type: GLOBALS, storageKey: storageKey});
		updateData({"modalTitle": undefined}, {type: GLOBALS, storageKey: storageKey});
		updateData({"isShowing": false}, {type: GLOBALS, storageKey: storageKey});
	};
	
	return ReactDOM.createPortal(
		<React.Fragment>
			<div id="modalLightbox" tabIndex="-1" aria-modal="true" role="dialog" onClick={closeButtonAction}>
				<div className="lightboxbg" aria-label="modal Lightbox" onClick={closeButtonAction}>
					<div className="lightbox-modal">
						<h4>{modalTitle}</h4>
						<button className="close-modal" onClick={closeButtonAction}>
							<i className="fal fa-times"></i>
							<span className="screen-reader">{Translate.Text({id: "article.modalLightbox.closeButton.text"})}</span>
						</button>
						<div className="wrapper-modal">
							<GenerateHtmlMarkup tag={'p'} htmlMarkup={modalMarkup} />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>, document.body);
};

export {Modal};

