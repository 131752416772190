import $ from "jquery";
import { Translate } from "../../locales/locales";

/**
 *
 * The functions below handle front-end display for hover and click events
 * on `textbook` articleList type card buttons and links.
 *
 * Used in src/modules/articleList/pages/generateTextbookPage.jsx
 *
 */


/**
 * Top level menu title button hover events
 *
 * @param event
 */
const handleTitleHover = (event) => {
	const $li = $(event.target).closest('li');
	if (event.type === 'mouseenter') {
		$li.addClass('hover').next().addClass('hover-sibling');
	} else if (event.type === 'mouseleave') {
		$li.removeClass('hover').next().removeClass('hover-sibling');
	}
};


/**
 * Show/hide the list item hover state when mouse
 * is positioned over one of its subMenu links
 *
 * @param event
 */
const handleSubMenuHover = (event) => {
	const $li = $(event.target).parents('.textbook-entry');
	if (event.type === 'mouseenter') {
		$li.addClass('hover').next().addClass('hover-sibling');
	} else if (event.type === 'mouseleave') {
		$li.removeClass('hover').next().removeClass('hover-sibling');
	}
};


/**
 * click on [+] or [-] icons to
 * expand/collapse subMenu preview state
 *
 * @param event
 */
const handleIconClick = (event) => {
	const $li = $(event.target).closest('li');
	const $icon = $li.find('.fas');
	const $iconText = $icon.next('.screen-reader');
	const $submenu = $li.find('ul');
	const closeText = Translate.Text({id:'articleList.textbookCard.subMenuIconTextClose'});
	const expandText = Translate.Text({id:'articleList.textbookCard.subMenuIconTextExpand'});
	if ($li.hasClass('article-current') && !$li.hasClass('article-current-closed')) {
		$li.addClass('article-current-closed');
		$icon.addClass('fa-plus-square').removeClass('fa-minus-square');
		$iconText.html(expandText);
		$submenu.slideUp('fast');
	} else if ($li.hasClass('article-current-closed')) {
		$li.removeClass('article-current-closed');
		$icon.addClass('fa-minus-square').removeClass('fa-plus-square');
		$iconText.html(closeText);
		$submenu.slideDown('fast');
	} else if ($li.hasClass('preview-open')) {
		$li.removeClass('preview-open');
		$icon.addClass('fa-plus-square').removeClass('fa-minus-square');
		$iconText.html(expandText);
		$submenu.slideUp('fast');
	} else {
		$li.addClass('preview-open');
		$icon.addClass('fa-minus-square').removeClass('fa-plus-square');
		$iconText.html(closeText);
		$submenu.slideDown('fast');
	}
};


/**
 * Export event handlers within an object
 */
const textbookCardEvents = {
	'handleTitleHover': handleTitleHover,
	'handleSubMenuHover': handleSubMenuHover,
	'handleIconClick': handleIconClick
};
export {textbookCardEvents};
