/**
 * Given an array of paged entries and an entry id along with the number of
 * entries per page in a single page, return the page index that entry
 * would be found on.  Return -1 if not found.
 *
 * @param params entry and page attributes
 *     entryList: list of entries
 *     entryId: entry id
 *     idAttr: the attribute of the entry id
 *     entriesPerPage: the number of entries per single page
 * @returns {number} value or 0 if not found, since that will always be the default page number
 */
const getEntryPageNumber = (params) => {
	params = Object.assign({
		entryList: [],
		entryId: 0,
		idAttr: 'id',
		entriesPerPage: 1
	}, params);
	const entryIndex = params.entryList.findIndex(entry => entry[params.idAttr] === params.entryId);
	return entryIndex === -1 ? 0 : parseInt((entryIndex / params.entriesPerPage), 10);
};
export {getEntryPageNumber};
