import React from "react";
import {Translate} from "../../../../locales/locales";
import {isEmpty} from "../../../../utils/generalUtils";
import {navigateToTarget} from "../../../globals/globalNavigator";
import {addClass} from "../../../../utils/generateClassName";
import {ID_NOT_SET} from "../../../_MODULE_GLOBALS/constants";
import {findArticle} from "../../../../utils/articleList/articleActions";
import {findReplicaPage} from "../../../../utils/replicaList/replicaActions";
import * as Tracking from "../../../../utils/tracking";

const trackAndNavigate = (targetParams) => {

	const trackingProperties = {
		"article id": targetParams.attributes.articleId,
		"article title": targetParams.attributes.articleTitle,
		"destination folio": [ targetParams.attributes.folio ],
		"destination type": "replica",
		"label": "Go to Page View",
		"location": "article viewer",
		"module": "responsive button",
		"type": "click",
		"url": (window ? window.location.href : ''),
	};
	Tracking.libraryTrack("button clicked",trackingProperties);
	
	navigateToTarget(targetParams);
};

/**
 * Display and handle the click on a "navigation" button type.  This button is designed
 * to go to go to a module another pane.
 *
 * @param props props passed from the responsive button
 * @returns {*} the generated (clickable) tag to display
 * @constructor
 */
const PageViewButton = (props) => {
	props = Object.assign({
		GeneratedTag: `button`,
		className: '',
		title: '',
		label: '',
		replacementValues: {},
		navigationKey: '',
		navigationKeys: {},
		storeState: {},
		issueUrl: ''
	}, props);
	const {GeneratedTag, storeState} = props;

	const className = addClass('article-viewer-control page-view-button', props.className);
	const label = !isEmpty(props.label) ? Translate.Text({id: props.label}) : Translate.Text({id: 'actionButton.pageViewButton.label'});
	const title = !isEmpty(props.title) ? Translate.Text({id: props.title}) : Translate.Text({id: 'actionButton.pageViewButton.title'});

	const navigationKey = 'replica';
	const navKey = props.navigationKeys.hasOwnProperty(navigationKey) ? props.navigationKeys[navigationKey] : {};
	const navKeyAttributes = navKey.attributes ? navKey.attributes : {};
	const targetKey = isEmpty(navKey.navigationTarget) ? 'replica' : navKey.navigationTarget;

	const pageAttributes = {
		pageNumber: storeState.hasOwnProperty('pageNumber') ? storeState.pageNumber : 0,
		folio:  storeState.hasOwnProperty('folio') ? storeState.folio : '',
		replicaList: storeState.hasOwnProperty('replicaList') ? storeState.replicaList : [],
	};
	const articleViewData = storeState.hasOwnProperty('articleViewData') ? storeState.articleViewData : {};
	pageAttributes.articleId = !isEmpty(articleViewData.articleId)
		? articleViewData.articleId
		: storeState.hasOwnProperty('articleId') ? storeState.articleId : ID_NOT_SET;
	pageAttributes.articleList = !isEmpty(articleViewData.articleList)
		? articleViewData.articleList
		: storeState.hasOwnProperty('articleList') ? storeState.articleList : [];
	pageAttributes.issueUrl = !isEmpty(articleViewData.issueUrl)
		? articleViewData.issueUrl
		: !isEmpty(props.issueUrl) ? props.issueUrl : '';


	/**
	 * Get the folio value to pass to the replicaViewer when transitioning from
	 * article view to replica view.  Since this starts at article view, use
	 * articleId and article to find folio, if possible.
	 *
	 * Try to get the folio from the replica page matching the pageNumber from the matching article
	 * if no replica page, get the folio list in the article
	 * If neither of these are available, use the default from storeState
	 */
	const article = findArticle({
		articleList: pageAttributes.articleList,
		articleId: pageAttributes.articleId
	});
	const replicaPage = findReplicaPage({
		replicaList: pageAttributes.replicaList,
		pageNumber: !isEmpty(article) ? article.pages.split(',')[0] : pageAttributes.pageNumber
	});
	if (!isEmpty(replicaPage)) {
		pageAttributes.folio = replicaPage.folio;
	} else if (!isEmpty(article)) {
		pageAttributes.folio = !isEmpty(article.folios) ? article.folios[0] : '';
	}

	if (!isEmpty(pageAttributes.folio)) {
		pageAttributes.articleTitle = !isEmpty(article) ? article.title : '';
		const attributes = Object.assign(navKeyAttributes, pageAttributes, {
			updateHistoryState: true,
			replaceHistoryState: false,
			forceFetch: false
		});

		const targetParams = {
			targetKey: targetKey,
			attributes: attributes,
			replacementValues: props.replacementValues
		};

		return (
			<GeneratedTag className={className} title={title} onClick={(evt) => trackAndNavigate(targetParams)}><span className={'button-text'}>{label}</span></GeneratedTag>
		);
	} else {
		return (
			<div className={className} title={title} aria-hidden={true}><span className={'button-text'}>{label}</span></div>
		);
	}
};
export {PageViewButton};
