import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {ARTICLE_LIST, DISPLAY_ARTICLE_DATA, ID_NOT_SET} from "../../_MODULE_GLOBALS/constants";
import {fetchData, updateData} from '../../../store/actions';
import GenerateTitle from "../../../widgets/generateTitle";
import {isTrue, isEmpty, getIntValue, getFloatValue, valuesEqual} from "../../../utils/generalUtils";
import {generateMops, displayOnDevice, devicePageGrid, dataModuleAttributes, getDocType} from "../../../utils/moduleSetup";
import GeneratePages, {GenerateInProgressPage, manageDeviceResize} from "../../../widgets/generatePages";
import {GenerateArticleIssueCover, removeCoverArticle} from "../../../widgets/generateSummary";
import {getObjectFromJSON, clone} from "../../../utils/objectUtils";
import {getDestinations} from "../../../utils/getDestinations";
import {getEntryPageNumber} from "../../../utils/articleList/getEntryPageNumber";
import {useFetchAttributesChange, useFetchComplete} from "../../../hooks/useFetchChange";
import {getCurrentArticleId} from "../../../utils/articleList/getCurrentArticleId";
import {removeExcludeCover} from "../../../utils/articleList/removeExcludeCover";
import {generateTitleAttribute} from "../../../utils/articleList/generateTitleAttribute";
import {GenerateGridListPage} from "./pages/generateGridListPage";
import {GenerateComboList} from "./pages/generateComboList";
import {GenerateTextbookPage} from "./pages/generateTextbookPage";
import {addClass} from "../../../utils/generateClassName";
import {GenerateDefaultPage} from "./pages/generateDefaultPage";
import {generateArticleDisplayParams} from "../../../utils/articleList/generateArticleDisplayParams";
import {finalCleanupArticleList} from "../../../utils/articleList/finalCleanupArticleList";
import {getArticleList} from "../../../utils/articleList/getArticleList";
import {setDOMProperties} from "../../globals/setDOMProperties";
import {getDocTypeAttribute} from "../../../utils/getDocTypeAttribute";
import {useMountPostRender} from "../../../hooks/useMount";
import {useAttributesChanged} from "../../../hooks/useAttributesChanged";



// list of possible query params sent with api call
// key is props name; value is fetch parameter
const configQueryParams = {
//	'maxEntries':'maxEntries',  // maxEntries has been removed
	'pageSize':'pageSize',  // maxEntries was an alias for pageSize
	'categories':'categories',
	'excludeContentTypes':'excludeContentTypes',
	'issueUrl': 'issueUrl',
	'authorNames':'authorNames',
	'sortBy':'sortBy',
	'publicationIds':'publicationIds',
	'recentIssues':'recentIssues',
	'pageNumber':'pageNumber',
	'isLoggedIn': 'isLoggedIn',
	'userAccessHash': 'userAccessHash',
	'withContent': {attribute: "withContent", default: false},
	'withTocAds': {attribute: "withTocAds", default: false},
	'u1': 'u1',
};



// list of possible query params that are tied to the docType but are only valid for issue articleLists, ie. has issueUrl as a query parameter
const docTypeQueryParams = {
	'createSections': {attribute: 'cardTemplate', property: 'createSections', related: {withContent: true}}  // set docType(s) for createSections if the displaySections configuration attribute is true
};


/*
	list of potential configuration parameters; other than query params.  (docType) means that it has sub-attributes matched to docType
		className: (docType) optional class name to add to div
		displayTitle: {true/false} - display the title; default false if not set
		titlePrefix: {string} - a string to place before the list title
		title: {string} - i18n key for title in strings (locale) file or text title string
		useTitleAttribute: (boolean) - override any automated way to generate title and use the configuration title string
		listType: (docType) type of list to create
		cardTemplate: (docType) attributes for styling an article list "card" (summary block); plus sub-attributes linked to docType
			displayThumbnail: {true/false} - show/hide article thumbnail
			displayThumbnailOnLeft: (true/false) - show thumbnail on left or right (default)
			displayIssue: {true/false} - show/hide issue name
			displaySummary: {true/false} - show/hide article summary
			displayCategory: {true/false} - show/hide category name
			displaySections: (true/false) - show section sub-list for document TOC
			useSummaryEllipsis: {true/false} - add ellipsis to end of the summary, or not
			maxSummaryCharacters: (value) - maximum number of characters to display in summary
		maxDisplay: {value} - number of entries to display for paging
		gridColumns: {object} - number of columns at different pure grid breakpoints
			sm: {number}
			md: {number}
			lg: {number}
 */



/**
 * Generate the article list jsx for display of the list.
 * Call to GeneratePages since, as a list,
 * it could require multiple pages and pagination block.
 *
 * @param params
 *     props: module props
 *     mops: module mops
 *     articleList: stored article list
 *     moduleDOMElement: DOM element for the articleList element
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateArticleList = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		articleList: [],
		articleListIdentifier: '',
		callOnUIChange: null,
		moduleDOMElement: {}
	}, params);
	const {props, mops, articleList, articleListIdentifier, callOnUIChange, moduleDOMElement} = params;

	// generate full className for module; default is to display active indicator
	let moduleClassName = articleList.length > 0 ? mops.className : addClass(mops.className, 'empty');
	moduleClassName = (props.navigationAttributes.fromModule === props.moduleId && props.navigationAttributes.fromInstance === props.instanceId) ? addClass(moduleClassName, 'called-from-self') : moduleClassName;
	moduleClassName = props.displayActiveIndicator ? addClass(moduleClassName, 'display-active') : moduleClassName;

	// generate attributes to apply to the list element
	const dataAttributes = {
		'data-columns': props.pageGridAttributes.columns,
		'data-called-from-module': props.navigationAttributes.hasOwnProperty('fromModule') ? props.navigationAttributes.fromModule : null,
		'data-called-from-instance': props.navigationAttributes.hasOwnProperty('fromInstance') ? props.navigationAttributes.fromInstance : null,
		'data-storagekey': props.storageKey
	};
	// only set if it is a single-issue articleList where issueGroup is set
	if (!isEmpty(props.issueGroup)) {
		dataAttributes['data-issue-group'] = props.issueGroup;
	}

	const articlePage = getEntryPageNumber({
		entryList: articleList,
		entryId: props.articleId,
		idAttr: 'id',
		entriesPerPage: props.pageGridAttributes.entriesPerPage
	});

	const articleAttributes = {
		props: props,
		mops: mops,
		articleListIdentifier: articleListIdentifier,
		callOnUIChange: callOnUIChange,
		moduleDOMElement: moduleDOMElement,
		fullList: articleList,
		updateHistoryState: props.hasOwnProperty('navigationKey'),
	};

	// callback for single page generation
	let GenerateArticleListPage = (pageParams) => GenerateDefaultPage(pageParams);
	let displayList = articleList;
	// setup properties for different listTypes
	if (props.listType === 'grid') {  // displays multiple articles in a single block based on categories; may have multiple blocks/pages
		articleAttributes.pageCount = articleList.length > 0 ? (Math.ceil(props.categoriesList.length / props.pageGridAttributes.entriesPerPage)) : 0;
		displayList = props.categoriesList;
		GenerateArticleListPage = (pageParams) => GenerateGridListPage(pageParams);
	} else if (props.listType === 'combo') {  // displays multiple articles in a single block; only one block/page
		articleAttributes.pageCount = articleList.length > 0 ? 1 : 0;
		GenerateArticleListPage = (pageParams) => GenerateComboList(pageParams);
	} else if (props.listType === 'textbook') { // displays menu as a collapsible list with sub-items
		GenerateArticleListPage = (pageParams) => GenerateTextbookPage(pageParams);
	} else {
		// if issue articleList, we need the cover for display at top, but want to remove it for the list
		displayList = removeCoverArticle({props: props, articleList: articleList});
	}

	return (
		<div className={'articleListContainer'} tabIndex={'-1'} {...dataAttributes}>
			<GenerateArticleIssueCover {...articleAttributes} />
			<div className={moduleClassName}>
				<GenerateTitle titleParams={mops.titleParams} />
				<GeneratePages {...articleAttributes} entries={displayList} className={'articles-pages'} cssClass={mops.cssClass} entryPage={articlePage} GeneratePage={GenerateArticleListPage} />
			</div>
		</div>
	);
};

/**
 * Start of jsx code for module
 *
 * @param props
 * @returns {string|JSX.Element}
 * @constructor
 */
export const ArticleListModule = (props) => {
	// updating value triggers re-render; for pagination
	const [paginationWidth, setPaginationWidth] = useState(window.innerWidth);
	// store serialized data to keep the last "valid" list
	const [articleList, setArticleList] = useState([]);
	const [fetchInProgress, setFetchInProgress] = useState(false);
	const [adTrackedForShareable, setAdTrackedForShareable] = useState("");

	const callOnUIChange = (fct, args)=>{
		if (adTrackedForShareable !== props.currentShareable) {
			if (typeof fct !== "function") {
				console.log("expected function", fct);
				return;
			}

			if (typeof args === "undefined") {
				fct();
			} else if (args.length === 1) {
				fct(args[0]);
			} else if (args.length === 2) {
				fct(args[0],args[1]);
			}
		}
	};

	useEffect(() => {
		if (adTrackedForShareable !== props.currentShareable) {
			setAdTrackedForShareable(props.currentShareable);
		}
	}, [adTrackedForShareable, props.currentShareable]);

	// Callback only once when module is setup.
	useMountPostRender(() => {
		// setup to trigger change if the device size value changes
		manageDeviceResize(setPaginationWidth);
	});

	// clone props, since props.title will be overwritten by generateTitleAttribute
	const moduleProps = clone(props);
	// generate before mops, so that mops can use the results
	moduleProps.title = generateTitleAttribute({moduleProps: moduleProps, articleList: articleList});
	/**
	 * Call to generate module-specific props, but that are generally common to all library
	 * modules.
	 * Generally, returns titleParams, className, storageKey, queryParams
	 *
	 * Note: this should be called very early so that subsequent functions can use these.
	 *
	 * @type {{queryParams: {}, titleParams: {}, className: string, storageKey: string}}
	 */
	const mops = generateMops(moduleProps, {
		defaultKey: ARTICLE_LIST,
		defaultClass: addClass('article-list', [moduleProps.listType, moduleProps.className]),
		title: 'articleList.base.title',
		titleTag: 'h2',
		configQueryParams: configQueryParams,
		docTypeQueryParams: docTypeQueryParams
	});

	const articleListIdentifier = !isEmpty(moduleProps.issueUrl) ? moduleProps.issueUrl : (!isEmpty(moduleProps.categories) ? moduleProps.categories : '');

	// setup ref to the article parent element for focus
	const moduleDOMElement = useRef(null);
	const DOMElement = !isEmpty(moduleDOMElement.current) ? moduleDOMElement.current : null;


	const DOMElementReady = moduleProps.isMyPane &&
		articleList.length > 0 &&
		!isEmpty(articleListIdentifier) &&
		!isEmpty(moduleDOMElement) &&
		!isEmpty(moduleDOMElement.current) &&
		valuesEqual(moduleDOMElement.current.dataset.articleListIdentifier, articleListIdentifier);

	useAttributesChanged(() => {
		if (DOMElementReady) {
			setDOMProperties({
				module: 'articleList',
				storageKey: moduleProps.storageKey,
				moduleDOMElement: DOMElement,
				DOMElement: DOMElement,
				useModuleTitle: moduleProps.useModuleTitle,
				useModuleFocus: moduleProps.useModuleFocus,
				titleValue: articleListIdentifier
			});
		}
	}, [DOMElementReady, articleListIdentifier, moduleProps.storageKey, moduleProps.useModuleFocus, moduleProps.useModuleTitle]);


	/**
	 * Called to manage whether the module fetches data.  If the hook calls the
	 * callback, then call getArticleList which will determine whether to
	 * fetch a new list or get an existing list from stored data.
	 *
	 * Note: this uses mops, so that must be defined first.
	 * Note: moduleProps is used internally in the hook.
	 */
	useFetchAttributesChange(() => {
		const storedListData = getArticleList({storageKey: mops.storageKey, queryParams: mops.queryParams});
		if (!isEmpty(storedListData.articleList)) {
			setArticleList(storedListData.articleList);
		}
		setFetchInProgress(isTrue(storedListData.fetchInProgress));
	}, {type: ARTICLE_LIST, props: moduleProps, queryParams: mops.queryParams});

	/**
	 * Call hook to check to see if the fetchInProgress has been set/changed in the reducer.
	 * fetchInProgress is set true when fetch is started (DATA_REQUESTED)
	 * and set to false when fetch is done (DATA_LOADED)
	 */
	useFetchComplete((isInProgress) => {
		isInProgress = isTrue(isInProgress, {defaultValue: false});
		setFetchInProgress((isInProgress));
		if (!isInProgress) {
			const storedListData = getArticleList({storageKey: mops.storageKey, queryParams: mops.queryParams, returnStoredDataOnly: true});
			setArticleList(storedListData.articleList);
		}
	}, {requestInProgress: props.fetchInProgress, fetchInProgress: fetchInProgress});


	// GenerateArticleIssueCover can return empty string if not displayed
	if (displayOnDevice(props)) {
		return (
			fetchInProgress ?
				moduleProps.displayInProgressPage ? <GenerateInProgressPage displaySpinner={true} /> : null
				:
				<GenerateArticleList callOnUIChange={callOnUIChange} props={moduleProps} mops={mops} articleList={articleList} articleListIdentifier={articleListIdentifier} moduleDOMElement={moduleDOMElement} />
		);
	} else {
		return null;
	}
};


/**
 * Map state (store) data for the articleList module; added to module props.
 *
 * articleList: array of articles objects where each object can contain:
 *     id (article id in UPP) - not currently available
 *     articleId (article id in WDS)
 *     publicationId
 *     publication (publication name)
 *     issueName (Short title?)
 *     issueId
 *     articleUrl (The webreader link to the article)
 *     title
 *     subtitle
 *     byline (Comma separated list of authors)
 *     thumbnail (article image thumbnail src)
 *     sortOrder (The order in which the article are displayed in the issue. Articles should be returned in sort order.
 *     summary (This is a short summary of the article)
 *     categories - comma separated string of categories
 *     authorNames - array of author names
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {{articleList: Array}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : ARTICLE_LIST;
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};
	const globalState = !isEmpty(state.globals) ? state.globals : {};
	const lastShareHistory = state.globals.currentShareable;

	// capture some values for other attributes use
	const grid = getObjectFromJSON(props.grid, {});

	let listProps = {
		storageKey: storageKey,
		grid: grid,
		currentShareable: !isEmpty(lastShareHistory) ? lastShareHistory.shareHistoryKey : '',
		pageGridAttributes: devicePageGrid(grid),
		destinations: getDestinations(props),
		articleId: getIntValue(storeState.articleId, ID_NOT_SET),
		coverImageUrl: !isEmpty(storeState.coverImageUrl) ? storeState.coverImageUrl : '',
		displayActiveIndicator: isTrue(props.highlightSelected, {defaultValue: true}),
		excludeContentTypes: props.hasOwnProperty('excludeContentTypes') ? props.excludeContentTypes : '',  // required for issue articleList
		canDisplayCoverImage: isTrue(props.canDisplayCoverImage, {defaultValue: true}),
		canDownloadPDF: isTrue(props.canDownloadPDF, {defaultValue: true}),
		scrollToSelection: isTrue(props.scrollToSelection, {defaultValue: true}),
		section: !isEmpty(storeState.section) ? storeState.section : '',
		isLoggedIn: globalState.isLoggedIn ? globalState.isLoggedIn : false,
		app: isTrue(state.app, {defaultValue: false}),
		articleList: storeState.articleList,
		fetchInProgress: isTrue(storeState.fetchInProgress, {defaultValue: false}),
		displayInProgressPage: isTrue(props.displayInProgressPage, {defaultValue: true}),
		issueOverrides: !isEmpty(props.issueOverrides) ? props.issueOverrides : {},
		zoomLevel: !isEmpty(storeState.zoomLevel) ? getFloatValue(storeState.zoomLevel) : '',
	};

	// add module properties that are common to data modules
	listProps = dataModuleAttributes({
		moduleProps: listProps,
		originalProps: props,
		state: state,
		storageKey: storageKey,
		navigationAttributeType: 'articles'
	});

	// check if this is an issue list to fetch cover for link
	listProps = removeExcludeCover(listProps);

	// Set the articleId for the selected article; articleList needs to know which article to mark as current
	listProps = getCurrentArticleId({
		moduleProps: listProps,
	});

	listProps.docType = getDocType({documentList: state.documentList, issueUrl: storeState.issueUrl, docTypeOverride: storeState.docType});
	// generate values for the list based on docType
	listProps.className = getDocTypeAttribute(listProps.docType, props.className);
	listProps.listType = getDocTypeAttribute(listProps.docType, props.listType);

	// setup all the articleDisplayParams for creating a list card; generated from cardTemplate configuration
	listProps = generateArticleDisplayParams({
		originalProps: props,
		moduleProps: listProps
	});

	// cleanup authorNames, categories props; trim and create arrays from string lists
	// update articleDisplayParams for filter/hide categories from categories/categoriesList
	listProps = finalCleanupArticleList({
		storeState: storeState,
		originalProps: props,
		moduleProps: listProps
	});

	return listProps;
};

/**
 * Actions that can be called by this module.  Each action is added to props so
 * that it can be called in the module, but defined in a single place with
 * appropriate parameters for the action call by this module.
 *
 * Potential query parameters (set in configuration)
 *     pageSize: (default: 200) maximum number of entries to return
 *         property: number
 *     categories: (default: all categories) comma separated list of categories to filter
 *         categories are set in article editor for article
 *         properties: comma separated list of category names
 *     excludeContentTypes: (default: none, ie. show all) comma separated list of article content types to filter out
 *         matching articles.  These types are (generally) generated based on the article template.  Article content
 *         types include:
 *             cover - generated based on cover template
 *             advertisement - generated based on template with advertisement in the name (full-page-advertisement)
 *             article - all other articles
 *     issueUrl: (default: all issues) Issue URL value as set in Specs
 *         the issue specified is the Issue URL value not the id
 *         ex. "issueURL": "April_2000"
 *     recentIssues: (default: all issues) number of most recent issues to return
 *         property: number
 *         ex. "recentIssues": 5
 *     authorNames: (default: all authors) pipe ("|") separated list of authors to filter
 *         name must be specified in the same format it is entered in the author-name span in the article
 *         Note: name is NOT case-sensitive; "LASTNAME" will be handles the same as "lastname"
 *         ex. "authorNames": "LastName1, FirstName1|FirstName2 LastName2"
 *         Note: this is an OR query; returns articles containing any of the author names
 *     pageNumber: (default: 0) starting page number of full results
 *     sortBy: (default: "publishedDate-desc") sort the results
 *         properties: "sortOrder"|"issueName"|"publishedDate"|"category" + "-" + "asc"|"desc"
 *         includes sort order
 *         comma separated list - whatever is first is the top level sort, the second one is within that sort by the next
 *         default sort orders
 *             "sortOrder-asc"
 *             "issueName-asc"
 *             "publishedDate-desc"
 *             "category-asc"
 *     'publicationIds': (default: current publication for collection url) for multiple collections in future
 *         properties: comma separated list of publication ids
 *             specify in the order you want the publications to appear
 *
 * actions:
 *     fetchData will make a call to the server to get data
 *     updateData will store data in the redux store
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		fetchData: (params) => {
			params.type = ARTICLE_LIST;
			dispatch(fetchData(params));
		},
		updateData: (payload, params) => {
			params.type = params.hasOwnProperty('type') ? params.type : DISPLAY_ARTICLE_DATA;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ArticleListModule);
