import {getModuleContainer, getModuleInstanceAttributes} from "./globalLibraryData";
import {isServer, isTrue} from "../../utils/generalUtils";
import {getStoreValue} from "../../utils/storeValue";
import {Translate} from "../../locales/locales";
import {GLOBALS} from "../_MODULE_GLOBALS/constants";


/**
 * Called by the paneObserver MutationObserver when there is a tracked DOM element
 * change.  Note, we are currently only interested in attributes, specifically "style".
 *
 * If this is called because of an attribute change and the style does not contain
 * "display: none" then call to set the focus and then disconnect the observer.
 * The observer will be reconnected on the next setPaneFocus call for the appropriate
 * pane DOM element to listen for changes.
 *
 * @param mutationsList
 * @param observer
 */
const handlePaneChange = (mutationsList, observer) => {
	const noFocus = mutationsList.some((record) => {
		const attrValue = record.target.attributes.getNamedItem(record.attributeName).value;
		return record.attributeName === 'style' && attrValue.includes('display: none');
	});
	if (!isTrue(noFocus)) {
		const moduleDOMElement = mutationsList[0].target;
		moduleDOMElement.focus();
		observer.disconnect();
	}
};

/**
 * Get the pane that is the target of an action when we plan to set the focus
 * to the top of the pane.  The pane becomes the focus element and is used
 * to trigger the paneObserver MutationObserver to listen for changes in the
 * pane DOM element attributes.
 *
 * The paneObserver should only be instantiated once when the UI is built and
 * setDOMProperties.js is included in the UI functions.
 *
 * Note: This is only triggered when going to a new pane, as revisiting (some)
 * panes usually requires the focus to be set to an element within the body
 * of the pane.
 *
 * @param params used for pane lookup depending on the module
 *     module: module name
 *     storageKey: module storageKey
 */
const paneObserver = !isServer ? new MutationObserver(handlePaneChange) : null;
const setPaneFocus = (params) => {
	params = Object.assign({
		module: '',
		storageKey: ''
	}, params);
	const moduleContainer = getModuleContainer(params);
	if (paneObserver && moduleContainer.pane !== '') {
		const moduleDOMElement = document.getElementById(moduleContainer.pane);
		if (moduleDOMElement !== null) {
			paneObserver.observe(moduleDOMElement, {attributes: true});
		} else {
			paneObserver.disconnect();
		}
	}
};


/**
 * Called from manageShareable to set the focus from a manageUpdateAndNavigation call,
 * or from specified modules, where focus is set to module content and that need to wait
 * for the content to be instantiated in the DOM.
 *
 * The useModuleFocus attribute determines whether the module element is given the focus
 * or whether a more generic pane focus is used.
 *
 * TODO: Figure out how to go back to last known position
 *
 * @param params
 *     moduleDOMElement: the element to set focus
 *     useModuleFocus: set focus to moduleDOMElement vs pane
 */
const setFocusForModule = (params) => {
	params = Object.assign({
		moduleDOMElement: null,
		useModuleFocus: false,
	}, params);
	if (!isTrue(params.useModuleFocus)) {
		setPaneFocus(params);
	} else if (params.moduleDOMElement !== null) {
		params.moduleDOMElement.focus();
	}
};

/**
 * Call to change the DOM document title.
 * If useModuleTitle is set in configuration, then a module will need to pass in
 * a specified value to replace the titleValue part of the i18n string.  Otherwise,
 * the generic library title will be used.
 * 
 * Note: If the titleValue for a module has not yet been populated, this function
 * will just keep the previous document title until it gets something.
 * 
 * @param params
 *     titleValue: explicit value for titleValue replacement
 *     useModuleTitle: use the titleValue supplied by the module
 */
const setDocumentTitle = (params) => {
	params = Object.assign({
		useModuleTitle: false
//		titleValue: undefined  // we check undefined by default, so don't want to define
	}, params);
	const collectionTitle = getStoreValue({attributeKey: 'collectionTitle'});
	if (params.useModuleTitle) {
		if (typeof params.titleValue !== 'undefined') {
			document.title = Translate.string({id: 'document.title', replacements: {"collectionTitle": collectionTitle, "titleValue": params.titleValue}});
		}
	} else {
		const titleValue = Translate.string({id: 'document.titleBase'});
		document.title = Translate.string({id: 'document.title', replacements: {"collectionTitle": collectionTitle, "titleValue": titleValue}});
	}
	// update store to trigger any listeners
	const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
	updateData({documentTitle: document.title}, {type: GLOBALS, storageKey: 'globals'});
};


/**
 * Master call to set different DOM properties.  Currently used for
 *     focus: set the focus when going to a new pane
 *     title: set the document title when going to a new pane
 *
 * @param params
 *     module: module name
 *     storageKey: module storage key
 *     moduleDOMElement: explicit element to use for focus
 *     useModuleFocus: use module vs pane focus
 *     useModuleTitle: use module value vs generic title value
 *     titleValue: value from module
 */
const setDOMProperties = (params) => {
	params = Object.assign({
		module: '',
		storageKey: '',
		moduleDOMElement: null,
		useModuleFocus: false,
		useModuleTitle: false,
		setDOMFocus: true
//		titleValue: undefined  // we check undefined by default, so don't want to define
	}, params);

	const moduleAttributes = getModuleInstanceAttributes({module: params.module, storageKey: params.storageKey, attributes: ['useModuleFocus', 'useModuleTitle']});
	if (params.setDOMFocus) {
		params.useModuleFocus = typeof moduleAttributes.useModuleFocus !== 'undefined' ? moduleAttributes.useModuleFocus : params.useModuleFocus;
		setFocusForModule(params);
	}
	params.useModuleTitle = typeof moduleAttributes.useModuleTitle !== 'undefined' ? moduleAttributes.useModuleTitle : params.useModuleTitle;
	setDocumentTitle(params);
};
export {setDOMProperties};