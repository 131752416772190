import React from "react";
import $ from "jquery";

/**
 * Given a list of values, join them together into a string, using separator
 * parameter, then return the string as a React jsx string.
 *
 * @param params parameters
 * @returns {string} if tag: html string; else simple string
 * @constructor
 */
const GenerateStringFromList = (params) => {
	params = $.extend({
		list: [],
		type: 'list',
		separator: ' ',  // space is default separator
		className: '',
		tag: null
	}, params);
	const GeneratedTag = params.tag !== null ? params.tag : false;

	if (params.list) {
		if (typeof params.list === 'string') {
			params.list = params.list.split(',');
		} else if (Array.isArray(params.list) === false) {
			params.list = [];
		}
	} else {
		params.list = [];
	}

	if (params.list.length > 0) {
		const generatedString = params.list.join(params.separator);
		return GeneratedTag ? <GeneratedTag className={params.className}>{generatedString}</GeneratedTag> : generatedString;
	} else {
		return '';
	}
};
export default GenerateStringFromList;
