import {triggerOnArticleListUI} from "../../../../triggers/triggerOnArticleList";
import {GenerateSummary} from "../../../../widgets/generateSummary";
import React, {useEffect} from "react";
import {populateArticleActions} from "../../../../utils/articleList/articleActions";
import {addClass} from "../../../../utils/generateClassName";
import {isTrue} from "../../../../utils/generalUtils";
import {generateHashKey} from "../../../../utils/generateHash";

/**
 * Generate a single page of articles for paging.
 *
 * @param pageParams params from generatePages
 * @returns {*}
 * @constructor
 */
const GenerateDefaultPage = (pageParams) => {
	pageParams = Object.assign({
		props: {},
		mops: {},
		entries: [],
		fullList: [],
		page: 0,
		entryPage: 0,
		updateHistoryState: false,
		articleListIdentifier: '',
		callOnUIChange: null,
		moduleDOMElement: null
	}, pageParams);
	const props = pageParams.props;

	const articleDisplayParams = props.hasOwnProperty('articleDisplayParams') ? props.articleDisplayParams : {};
	const pageList = pageParams.entries;
	const articleList = pageParams.fullList;

	const displayClassName = 'display-page' + (pageParams.page === pageParams.entryPage ? ' selected' : '');
	const className = 'article-page';
	const articleClassBase = 'article-entry';
	const articleStyles = {
		width: articleDisplayParams.cardTemplate === 'carousel' ? 'auto' : (100 / props.pageGridAttributes.columns) + '%'
	};

	const articleActions = populateArticleActions({
		props: props,
		mops: pageParams.mops,
		articleList: articleList,
		callOnUIChange: pageParams.callOnUIChange,
		updateHistoryState: isTrue(pageParams.updateHistoryState)
	});

	/**
	 * These triggers require that the articleList be built in the UI before activation, so place in a
	 * useEffect that activates at the end of the jsx
	 *
	 * Note: listType ('default'/'carousel' will make a different scroll call in the trigger.
	 */
	const storageKey = props.storageKey;
	const articleId = props.articleId;
	const listType = props.hasOwnProperty('listType') ? props.listType : 'default';
	const articleListKey = generateHashKey({keyValue: pageParams.mops.queryParams, keyType: 'articles'});

	useEffect(() => {
		const triggerParams = {
			module: 'articleList',
			storageKey: storageKey,
			listType: listType,
			articleId: articleId,
			articleListKey: pageParams.mops.queryParams,
			scrollToSelection: isTrue(props.scrollToSelection),
			scrollToLastPosition: isTrue(props.scrollToLastPosition)
		};
		triggerOnArticleListUI(triggerParams);
	});

	return (
		<div className={displayClassName} data-page={pageParams.page} data-article-list-identifier={pageParams.articleListIdentifier} ref={pageParams.moduleDOMElement}>
			<ul className={className}>
				{
					pageList.map((article) => {
						const categories = Array.isArray(article.categories) ? article.categories : [];
						const categoriesClasses = categories.map(category => category.trim() !== '' ? 'category-' + category.trim() : '');
						const articleClassList = categoriesClasses.concat([
							'card-' + articleDisplayParams.cardTemplate,
							'article-' + article.articleId,
							'issue-' + article.issueId,
							(props.articleId && props.articleId === article.articleId) ? 'article-current' : ''
						]);
						const articleClass = addClass( articleClassBase, articleClassList);
						return (
							<li key={article.id} data-articleid={article.id} data-issueid={article.issueId} className={articleClass} style={articleStyles}>
								<div className={'article-entry-contents'}>
									<GenerateSummary entry={article} actions={articleActions} template={articleDisplayParams.cardTemplate} displayParams={articleDisplayParams} />
								</div>
							</li>
						);
					})
				}
			</ul>
		</div>
	);
};
export {GenerateDefaultPage};
