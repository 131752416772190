import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {fetchData, updateData} from '../../../store/actions';
import GenerateTitle from "../../../widgets/generateTitle";
import GenerateImage from "../../../widgets/generateImage";
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import {SIMPLE_IMAGE} from "../../_MODULE_GLOBALS/constants";


/**
 * SimpleImageModule
 *
 * This module is designed to allow for the display of an image given an image src.
 *
 * All parameters are passed in through configuration options, so it will not have
 * any interaction with the store; so not fetch or update store data.
 *
 */

/*
	list of potential configuration parameters; other than query params
		className: optional class name to add to div
		displayTitle: {true/false} - display the title; default false if not set
		i18nId: {string} - key to string for title in strings (locale) file
		src: img src url
		alt: img alt text
		link: href link for image
		imgHeight: inline style; height of image (better to set className)
		imgWidth: inline style; width of image (better to set className)
 */

export const SimpleImageModule = (props) => {
	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: SIMPLE_IMAGE,
		defaultClass: 'simple-image',
		title: 'simpleImage.title',
		titleTag: 'h2',
		configQueryParams: {}
	});


	// check and set image properties
	const imageProperties = {
		alt: props.alt ? props.alt : '',
		src: props.src ? props.src : '#',
		link: props.link ? props.link : false,
		styles: {
			height: props.imgHeight ? props.imgHeight : '',
			width: props.imgWidth ? props.imgWidth : ''
		}
	};


	/*
	* Generate jsx for action button if displayOnDevice
	* Call GenerateImage function to generate the image
	*/
	if (displayOnDevice(props)) {
		return (
			<div className={mops.className}>
				<GenerateTitle titleParams={mops.titleParams} />
				<GenerateImage imageProperties={imageProperties} />
			</div>
		);
	} else {
		return null;
	}
};


/**
 * Map state (store) data.
 *
 * storageKey provides a way to map a specific store object to a particular
 * instance of the module.  Add storageKey when configuring the module instance
 * if it needs to listen for a store change.
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
	return {
		active: getActiveAttributeState(props)
	};
};

/**
 * simpleImage module (currently) makes to calls to fetch of update data.
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SimpleImageModule);

