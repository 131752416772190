import {getObjectFromJSON} from "./objectUtils";

const embedJavascript = (url) => {
	if (typeof url !== "undefined" && url !== '') {
		const expression = /(http|\/\/)[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
		const regex = new RegExp(expression);

		if (!url.match(regex)) {
			console.error("Invalid URL " + url);
			return false;
		}

		// determine if script is already in DOM, remove if so to avoid duplicates
		const scripts = document.getElementsByTagName("script");
		for (let i = 0; i < scripts.length; ++i) {
			if (scripts[i].getAttribute('src') === url ) {
				scripts[i].parentNode.removeChild(scripts[i]);
			}
		}

		const script = document.createElement('script');
		script.src = url;
		script.async = true;
		document.body.appendChild(script);
		return true;
	} else {
		return false;
	}
};
export default embedJavascript;


/**
 * Call to execute an external function.
 * This could be a straight function that is already loaded, but is
 * more likely to be a function that is identified by string name that
 * we need to find after we have loaded a module.
 *
 * Note: For strings, this does not execute an arbitrary function from
 * a string (ie. eval), instead it will search on the window object for
 * an existing function, and only execute it if found.
 *
 * @param func function or string name of function
 * @param funcParams additional function parameters
 */
const executeFunction = (func, funcParams) => {
	funcParams = typeof funcParams === 'string' ? getObjectFromJSON(funcParams, []) : (Array.isArray(funcParams) ? funcParams : []);
	if (typeof func === 'string') {
		try {
			const functionParts = func.split('.');
			let functionCall = window[functionParts[0]];
			if (typeof functionCall !== 'undefined') {
				if (functionParts.length > 1) {
					for (let i = 1; i < functionParts.length; i++) {
						functionCall = functionCall[functionParts[i]];
					}
				}
				if (typeof functionCall === 'function') {
					if (funcParams.length > 0) {
						functionCall.apply(null, funcParams);
					} else {
						functionCall();
					}
				}
			}
		} catch (e) {
			console.log('not currently configured');
		}
	} else {
		try {
			if (funcParams.length > 0) {
				func.apply(null, funcParams);
			} else {
				func();
			}
		} catch (e) {
			console.log('not currently configured');
		}
	}

};
export {executeFunction};
