/* CDNSTANTS, for anything that SHOULDN'T change */


/*
	Generic constants
*/

// VALUE Constants
export const ID_NOT_SET = -99999;
export const DEFAULT_REPLICA_PAGE_SPREAD = 1;
export const DEFAULT_REPLICA_ZOOM_LEVEL = 1;
export const REPLICA_ZOOM_STEP_LEVEL = 0.25;
export const MIN_REPLICA_ZOOM_LEVEL = 1;
export const MAX_REPLICA_ZOOM_LEVEL = 2;
export const DEFAULT_ARTICLE_ZOOM_LEVEL = 1;
export const ARTICLE_ZOOM_STEP_LEVEL = 0.25;
export const MIN_ARTICLE_ZOOM_LEVEL = 1;
export const MAX_ARTICLE_ZOOM_LEVEL = 2;


export const ABOVE = "ABOVE";
export const BELOW = "BELOW";
export const MIDDLE = "MIDDLE";


// Map action names to constants.
export const ADD_ARTICLES = "ADD_ARTICLES";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const ADD_ARTICLE_LIST = "ADD_ARTICLE_LIST";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const VIEWER_ARTICLE_LIST = "VIEWER_ARTICLE_LIST";
export const DATA_REQUESTED = "DATA_REQUESTED";
export const DATA_LOADED = "DATA_LOADED";
export const DATA_FETCH_REQUESTED = "DATA_FETCH_REQUESTED";
export const DATA_FETCH_SUCCEEDED = "DATA_FETCH_SUCCEEDED";
export const DATA_FETCH_FAILED = "DATA_FETCH_FAILED";
export const API_ERRORED = "API_ERRORED";
export const UPDATE_DATA = "UPDATE_DATA";
export const DATA_UPDATED = "DATA_UPDATED";
//Add/update value in the database and return the data
export const DATA_SAVE_REQUESTED = "DATA_SAVE_REQUESTED";
export const DATA_DELETE_REQUESTED = "DATA_DELETE_REQUESTED";
export const DATA_DELETED = "DATA_DELETED";
export const DATA_SAVED = "DATA_SAVED";


// Map module types and constants to constants.
export const NAVIGATOR = "NAVIGATOR";
export const GLOBALS = "GLOBALS";

export const ADD_SCRIPT = "ADD_SCRIPT";
export const ARTICLE_LIST = "ARTICLE_LIST";
export const ARTICLE_LIST_FILTER = "ARTICLE_LIST_FILTER";
export const ARTICLES = "ARTICLES";
export const AUTHENTICATE = "AUTHENTICATE";
export const ISSUE_LIST = "ISSUE_LIST";
export const SEARCH_RESULTS = "SEARCH_RESULTS";
export const SEARCH_ISSUES = "SEARCH_ISSUES";
export const SEARCH_FILTERS = "SEARCH_FILTERS";
export const SEARCH_PROGRESS = "SEARCH_PROGRESS";
export const SEARCH_ENTRY_FILTER = "SEARCH_ENTRY_FILTER";
export const SEARCH_FILTERS_ARTICLE = "SEARCH_FILTERS_ARTICLE";
export const SIMPLE_IMAGE = "SIMPLE_IMAGE";
export const TAGGED_TEXT = "TAGGED_TEXT";
export const TAGS_LOOKUP = "TAGS_LOOKUP";
export const TAG_LIST = "TAG_LIST";
export const SELECTED_TAG = "SELECTED_TAG";
export const VIDEO = "VIDEO";
export const HTML_FRAGMENT = "HTML_FRAGMENT";
export const LOGO = "LOGO";
export const STATIC_AD = "STATIC_AD";
export const GOOGLE_AD = "GOOGLE_AD";
export const EMBED_JAVASCRIPT = "EMBED_JAVASCRIPT";
export const ARTICLE_VIEWER = "ARTICLE_VIEWER";
export const ARTICLE_VIEWER_PROGRESS = "ARTICLE_VIEWER_PROGRESS";
export const ARTICLE_ARTICLEVIEW = "ARTICLE_ARTICLEVIEW";
export const REPLICA_LIST = "REPLICA_LIST";
export const REPLICA_VIEWER = "REPLICA_VIEWER";
export const LIBRARY_TARGET = "LIBRARY_TARGET";
export const SHARE_BUTTONS = "SHARE_BUTTONS";
export const RESPONSIVE_BUTTON = "RESPONSIVE_BUTTON";
export const STATIC_BUTTON = "STATIC_BUTTON";
export const MODAL_MENU = "MODAL_MENU";
export const HIGHLIGHTS = "HIGHLIGHTS";
export const AUTHENTICATION = "AUTHENTICATION";
export const EASTEREGG = "EASTEREGG";
export const FOOTNOTE = "FOOTNOTE";
export const STARTER = "STARTER";

// Authentication/login constants
export const LOGIN = "LOGIN";
export const NO_LOGIN = "NO_LOGIN";
export const REFERRER_LOGIN = "REFERRER_LOGIN";
export const JWT_LOGIN = "JWT_LOGIN";
export const ECOMMERCE_INAPP_LOGIN = "ECOMMERCE_INAPP_LOGIN";
export const SUBSCRIBER_LOGIN = "SUBSCRIBER_LOGIN";


//	Map action attributes to a constant.
export const ARTICLELIST_ARTICLE = "ARTICLELIST_ARTICLE";
export const ARTICLELIST_ARTICLELIST = "ARTICLELIST_ARTICLELIST";
export const ARTICLELIST_STOREDLIST = "ARTICLELIST_STOREDLIST";
export const ARTICLELIST_ISSUE = "ARTICLELIST_ISSUE";
export const DISPLAY_ARTICLE_DATA = "displayArticle";
export const ISSUE_ARTICLE_DATA = "issueArticle";
export const SUBSCRIBER = "SUBSCRIBER";
export const PURCHASE = "PURCHASE";
export const REPLICALIST_PAGE = "REPLICALIST_PAGE";
export const REPLICALIST_REPLICALIST = "REPLICALIST_REPLICALIST";
export const REPLICALIST_STOREDLIST = "REPLICALIST_STOREDLIST";
export const REPLICALIST_REPLICA = "REPLICALIST_REPLICA";
export const DISPLAY_REPLICA_DATA = "DISPLAY_REPLICA_DATA";
export const REPLICA_LAYOUT_DATA = "REPLICA_LAYOUT_DATA";
export const ISSUELIST_ISSUELIST = "ISSUELIST_ISSUELIST";
export const ISSUELIST_STOREDLIST = "ISSUELIST_STOREDLIST";
export const ISSUE_FILTERS = "ISSUE_FILTERS";
export const SEARCHRESULTS_STOREDRESULTS = "SEARCHRESULTS_STOREDRESULTS";
export const TRACKER = "TRACKER";


// StorageKeys
export const GLOBALS_KEY = "globals";
export const AUTHENTICATION_KEY = "authentication";
export const EASTEREGG_KEY = "easterEgg";
export const HIGHLIGHTS_KEY = "highlights";
export const GLOBAL_ARTICLELIST_KEY = "GlobalArticleListStore";
export const FOOTNOTE_KEY = "FOOTNOTE";



//export const ARTICLE_DISPLAY_DATA = "ARTICLE_DISPLAY_DATA";
//export const ISSUE_ARTICLE_DISPLAY_DATA = "ISSUE_ARTICLE_DISPLAY_DATA";

const getStorageKeyAttributeMap = (attributeMapName) => {
	const attributeMap = {
		DISPLAY_ARTICLE_DATA: [
			"articleId",
			"articleList",
			"articleSummaries"
		],
		ISSUE_ARTICLE_DATA: [
			"articleId",
			"issueId",
			"issueList"
		],
		DISPLAY_REPLICA_DATA: [
			"folio",
			"replicaList"
		]
	};
	return (typeof attributeMapName !== 'undefined' && attributeMap.hasOwnProperty(attributeMapName)) ? attributeMap[attributeMapName] : {};
};
export {getStorageKeyAttributeMap};


