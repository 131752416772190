import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import store from '../store';
import {Provider, connect} from 'react-redux';
import { gatheredLibraryModules } from '../modules/_MODULE_GLOBALS/gatheredModules';
import * as Tracking from '../utils/tracking';
import {storeInCache} from "../utils/manageStoreCacheData";
import {GLOBAL_ARTICLELIST_KEY} from "../modules/_MODULE_GLOBALS/constants";


/**
 * Loop through the containerNames from the globalModules object.  For each container, loop through
 * the defined array of modules, find out if it is a defined module, then add the set of modules
 * for each global container to the DOM.
 *
 * Each new global container is added after (and outside) the structures container so it will
 * persist even when a structure is changed.
 *
 * It is up to styles to decide exactly where to place each container, but it is expected that
 * it will be fixed somewhere in the UI.
 *
 * Add the module to a list, which is then used to populate the global container.
 *
 * @param globalModules
 */
const renderGlobalModules = (globalModules) => {
	globalModules = typeof globalModules !== 'undefined' ? globalModules : {};
	const allModules = gatheredLibraryModules();
	let keyNum = 0;  // key for each module; incremented by 1 for each module
	const globalContainers = Object.keys(globalModules);
	const $app = $('#App');

	globalContainers.forEach((containerName) => {
		const components = [];
		globalModules[containerName].forEach((module) => {
			const moduleInstance = {...module};
			if (moduleInstance.name && (moduleInstance.name in allModules)) {
				module.key = ++keyNum;
				const Module = allModules[moduleInstance.name];
				components.push(<Module {...moduleInstance} key={module.key}/>);
			}
		});
		if (components.length > 0) {
			// check to see if the global container was already added; if so, return existing DOM element, otherwise add and return
			const $globalContainer = $app.find('#'+containerName);
			const containerDiv = $globalContainer.length > 0 ? $globalContainer.get(0) : $('<div id="'+containerName+'" class="container container-global"></div>').appendTo($app).get(0);
			// render array of components into specified container
			ReactDOM.render(
				<Provider store={store}>
					{components}
				</Provider>,
				containerDiv
			);
		}
	});
};

/**
 * Given a configuration and a set of layouts, populate the appropriate layout
 * with configured modules.
 *
 * Note: This runs a listener for browser resize to determine the device type for
 * appropriate layout as specified by the displays property.
 *
 * @param props passed in props
 * @returns {*}
 * @constructor
 */
const PopulateGlobalModules = (props) => {
	useEffect(() => {
		// call to render global modules
		renderGlobalModules(props.globalModules);
	}, [props.globalModules]);

	/**
	 * Global modules will populate into containers that are created on-the-fly in the App div, so this method
	 * doesn't need to create anything.
	 *
	 * @type {function(): string}
	 */
	const GenerateGlobalModules = (() => {
		return "";
	});
	return GenerateGlobalModules();

};


/**
 * Get store state, add appropriate property to props to pass to component.
 *
 * @param state store state
 * @returns {{layout: *, configuration: *}} props object
 */
const mapStateToProps = state => {
	const stateProps = {
		globalModules: state.hasOwnProperty('globalModules') ? state.globalModules : {}
	};
	// handle store of articleList from fetch
	if (state.hasOwnProperty(GLOBAL_ARTICLELIST_KEY)) {
		const articleList = state[GLOBAL_ARTICLELIST_KEY].articleList;
		const fetchQueryParams = state[GLOBAL_ARTICLELIST_KEY].fetchQueryParams;
		storeInCache({dataKey: fetchQueryParams, data: articleList});
	}

	return stateProps;
};

/**
 * Provide connection to store
 */
export default connect(
	mapStateToProps,
	null
)(PopulateGlobalModules);
