import $ from "jquery";



/**
 * For a <sup class="footnoteReference"> in the article markup, find the <a> tag
 *  and wrap it in a <span> tag.  This is a work-around for a bug in parseHtmlToReact that
 * triggers when we try to process the <a> tag within the footnoteReference, to add
 * an onClick handler.
 *
 * parseHtmlToReact doesn't properly handle any node between the </a> and its parent
 * end tag.
 *
 * Note that it doesn't matter if the node is a text node or an html tag,
 * anything in that position will trigger an error
 *
 * For example: the "testing" string will cause an error
 *
 *     <sup class="footnoteReference"><a href="#footnote1">one</a>testing</sup>
 *
 * Wrapping the <a> tag within a new <span> ensures that there is nothing between
 * the bounds of the parent (span) and the child (a)
 *
 *     <sup class="footnoteReference"><span><a href="#footnote1">one</a></span>testing</sup>
 *
 * @param params
 *     articleMarkup: html markup for an article
 *
 * @returns {*} the modified html markup
 */
const updateFootnoteReference = (params) => {
	params = Object.assign({
		articleMarkup: ''
	}, params);
	const $wrapper = $('<div />').append(params.articleMarkup);
	$wrapper.find('sup.footnoteReference').find('a').wrap('<span></span>');

	// return the full article markup html as a string
	return $wrapper.html();
};

export { updateFootnoteReference };
