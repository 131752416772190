import {isEmpty} from "../../../utils/generalUtils";
import deepmerge from "deepmerge";
import {isPlainObject, objectSize} from "../../../utils/objectUtils";
import {arrayRemoveDups} from "../../../utils/arrayUtils";


/**
 * Given the original redux store state object, create a new state object (returnState).
 *
 * Pass in a set of default values for specific module attributes as attached to
 * a specific storageKey and well as a list of values to reset each time the
 * reducer moduleAction is triggered.
 * Deep merge (creates new object) these attributes with the current store state, in order
 *     default values (initialStorageState)
 *     current state
 *     reset values (resetStorageState)
 * Default values will be overridden by current state values which will be overridden
 * byt reset values.
 * This new state object (returnState) is then the starting point to merge in the new
 * attributes passed into the reducer through action.params and action.payload.
 *
 * Add attributes to the storageKey returnState for any attribute that may not be set
 * every time.  This allows the attributes to keep their current, redux state value
 * unless they are actually changed.
 *
 * WARNING:
 *     initialStorageState and resetStorageState MUST be created as an object with
 *     the storageKey as the only key which creates an inner object with the attributes
 *     to use as defaults or for reset.
 *     This ensures that the default/reset attributes only applies to the specific
 *     module storageKey.
 *     Example call passed to initialStorageState property:
 *
 *     const createInitialState = ((storageKey) => {
 *         return {
 *             [storageKey]: {
 *                 "pageNumber": '',
 *                 "folio": '',
 *                 "issueUrl": '',
 *                 "articleList": [],
 *                 "articleId": ID_NOT_SET
 *             }
 *         };
 *     });
 *
 *     This example returns an object with storageKey as the key to the object
 *     containing five default attributes.
 *
 *
 */
const noUpdate = [
	"type",
	"assign"
];
/**
 * @param params
 *     storageKey: storageKey for particular update/fetch call matching calling module
 *     state: original store state
 *     action: reducer action property
 *     initialStorageState: default state values for attributes matching the storageKey
 *     resetStorageStateValues: state values that we always want to reset to their default before any overrides
 * @returns {unknown}
 */
const createNewStoreState = (params) => {
	const startTime = Date.now();
	params = Object.assign({
		storageKey: '',
		state: {},
		action: {},
		initialStorageState: {},
		resetStorageStateValues: {}
	}, params);
	const storageKey = !isEmpty(params.storageKey) ? params.storageKey : '';
	const actionParams = !isEmpty(params.action.params) ? params.action.params : {};
	const actionPayload = !isEmpty(params.action.payload) ? params.action.payload : {};

	/* this merge is too simplistic as it ignores initial and reset states
	* const returnState = { 
	* 	...params.state,
	* 	[storageKey]: {
	* 		...params.state[storageKey] , ...actionPayload
	* 	}
	* };
	*/
	// merge current store state into defaults; then merge overrides into that and create a new state object
	// note: deepmerge.all takes an array of objects to merge for more than two
	const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
	const returnState = deepmerge.all([
		params.initialStorageState,
		params.state,
		params.resetStorageStateValues
	],{ arrayMerge: overwriteMerge });

	// if assign is "merge", deepmerge the attribute; if assign is "add" treat like array and add; else just replace (default)
	const assign = !isEmpty(actionParams.assign) ? actionParams.assign : "replace";
	const attributes = deepmerge(actionParams, actionPayload);
	Object.keys(attributes).forEach(key => {
		if (!noUpdate.includes(key)) {
			if (assign === "merge") {
				if (isPlainObject(returnState[storageKey][key]) && isPlainObject(attributes[key])) {
					returnState[storageKey][key] = deepmerge(returnState[storageKey][key], attributes[key]);
				} else {
					// fallback is replace
					returnState[storageKey][key] = attributes[key];
				}
			} else if (assign === "add") {
				if (Array.isArray(returnState[storageKey][key])) {
					returnState[storageKey][key].push(attributes[key]);
					returnState[storageKey][key] = arrayRemoveDups(returnState[storageKey][key]);
				} else {
					// fallback is replace
					returnState[storageKey][key] = attributes[key];
				}
			} else {
				returnState[storageKey][key] = attributes[key];
			}
		}
	});
	//const size = objectSize(returnState);
	const timeElapsed = Date.now - startTime;
	if (timeElapsed > 10) {
		console.log("createNewStoreState", timeElapsed);//, size);
	}
	return returnState;
};
export {createNewStoreState};
