

/**
 * If required, remove the "cover" attribute from excludeContentTypes.  This is usually used by
 * the issue articleList when the list is fetched by issueUrl.
 * We want to make a special call to get the cover article, even if the configuration excludes it.
 *
 * @param moduleProps clone of the module props
 * @returns {{excludeContentTypes}|*} the modified moduleProps
 */
const removeExcludeCover = (moduleProps) => {
	// handle the issue list based on issueUrl to always include cover, even if not configured for it
	if (moduleProps.hasOwnProperty('issueUrl')) {
		moduleProps.coverExcluded = false;
		if (moduleProps.hasOwnProperty('excludeContentTypes')) {
			let excludeContentTypes = moduleProps.excludeContentTypes.split(',');
			if (excludeContentTypes.includes('cover')) {
				excludeContentTypes = excludeContentTypes.filter(entry => entry !== 'cover');
				excludeContentTypes = excludeContentTypes.join(',');
				moduleProps.excludeContentTypes = excludeContentTypes;
				moduleProps.coverExcluded = true;
			}
		}
	}
	return moduleProps;
};
export {removeExcludeCover};
