import $ from "jquery";
import {Translate} from "../../../../locales/locales";



/**
 * Wrap images in <a> tags in the article markup.
 *
 * Figure images will open in a lightbox if clicked, so we need to add
 * <a> tags for WCAG compliance.
 *
 * Additional
 *     check if the figure is part of the floated-images template or is floated
 *     in those cases, handle the images to them within the article content boundaries
 *     unless given special instructions
 *
 * Also remove non-standard ref attributes.
 *
 * @param params
 *     articleMarkup: html markup for an article
 *     template: article template
 *
 * @returns {*} the modified html markup
 */
const updateArticleImages = (params) => {
	params = Object.assign({
		articleMarkup: '',
		template: 'default'
	}, params);
	const $wrapper = $('<div />').append(params.articleMarkup);
	const $figures = $wrapper.find('figure.picture, figure.graphic');

	// do not add lightbox when existing link 'figure a img'
	const $images = $figures.find('> img');

	// remove the nonstandard 'ref' attribute
	// wrap img in a tag for WCAG compliance
	$images.removeAttr('ref').wrap('<a class="lightboxPictureWrap" href="//openLightbox"></a>').after('<span class="screen-reader">' + Translate.string({id: "article.lightboxImage.picture"}) + '</span>');
	$images.parents('figure').addClass('lightboxPictureFigure');

	// check figures explicitly marked "constrain-to-article" OR figures that will be floated left/right
	//     wrap in div to keep same width as content
	// exceptions: marked "do-not-constrain" or "align-center"
	$figures.each(function(index, figure) {
		const $figure = $(figure);
		if ($figure.hasClass("constrain-to-article")) {
			$figure.wrap('<div class="constrain-to-article" />');
		} else if (!$figure.hasClass("do-not-constrain")) {
			if (
				(params.template === 'floated-images' && !$figure.hasClass("align-center")) ||
				($figure.hasClass("align-left") || $figure.hasClass("align-right"))
			) {
				$figure.wrap('<div class="constrain-to-article" />');
			}
		}
	});


	const $advertisements = $wrapper.find('advertisement');

	// insert lightbox icon at top of advertisement
	$advertisements.prepend('<a class="fullscreenIcon" href="//openLightbox"><span class="fas fa-expand"></span><span class="screen-reader">' + Translate.string({id: "article.lightboxImage.advertisement"}) + '</span></a>');

	// return the full article markup html as a string
	return $wrapper.html();
};

export { updateArticleImages };
