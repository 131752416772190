import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {BackButton} from "./buttonWidgets/backButton";
import {HomeButton} from "./buttonWidgets/homeButton";
import {SupportButton} from "./buttonWidgets/supportButton";
import {ToggleLayoutCollapseButton} from "./buttonWidgets/toggleLayoutCollapseButton";
import {getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import {STATIC_BUTTON} from "../../_MODULE_GLOBALS/constants.js";
import {updateData} from "../../../store/actions";
import {isEmpty, isTrue} from "../../../utils/generalUtils";
import getDeviceType from "../../../utils/getDeviceType";



const staticButtons = {
	'backButton': {
		button: BackButton,
		device: {
			default: true
		},
		useHideNavButtonParameter: true
	},
	'homeButton': {
		button: HomeButton,
		device: {
			default: true
		},
		useHideNavButtonParameter: true
	},
	'toggleLayoutCollapseButton': {
		button: ToggleLayoutCollapseButton,
		device: {
			default: false,
			lg: true,
			xl: true,
			xxl: true
		},
		useHideNavButtonParameter: false
	},

	// TODO: DEPRECATED - REMOVE
	'supportButton': {
		button: SupportButton,
		device: {
			default: true
		},
		useHideNavButtonParameter: false
	}
};


/**
 * Module to generate different "static" buttons.
 * A static button is one in which there are no values passed to it from other modules.
 *
 * Current specifications allows only a few Configuration options, but future specification
 * may allow for override of the staticButtons attributes defined above.
 *
 * Each button type has its own widget specification and jsx code generation.
 *
 * @param props module props
 * @returns {JSX.Element|null}
 * @constructor
 */
export const StaticButtonModule = (props) => {
	const buttonDef = staticButtons[props.widget];
	const StaticButton = !isEmpty(buttonDef) && !isEmpty(buttonDef.button) ? buttonDef.button : null;

	const device = getDeviceType();
	const renderOnDevice = buttonDef.device.hasOwnProperty(device) ? isTrue(buttonDef.device[device]) : isTrue(buttonDef.device.default);

	const useHideNavButtonParameter = isTrue(buttonDef.useHideNavButtonParameter, {default: false});
	const hideNavButtons = props.state.queryParams.hasOwnProperty('hideNavButtons') ? isTrue(props.state.queryParams.hideNavButtons, {default: false}) : false;
	const renderOnPlatform = !(useHideNavButtonParameter && hideNavButtons);

	/*
	* Generate jsx for action button if displayOnDevice
	* Use ActionButton as tag
	*/
	if (displayOnDevice(props) && renderOnDevice && renderOnPlatform) {
		return <StaticButton {...props} />;
	} else {
		return null;
	}
};


/**
 * Map the store object you are using.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{articleList: Array}}
 */
const mapStateToProps = (state, props) => {
	return {
		state: state,
		active: getActiveAttributeState(props),
		tag: !isEmpty(props.tag) ? props.tag : 'button',
		label: !isEmpty(props.label) ? props.label : '',
		title: !isEmpty(props.title) ? props.title : '',
		className: !isEmpty(props.className) ? props.className : ''
	};
};

/**
 * UIWidgetModule module (currently) makes to calls to fetch of update data.
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		updateData: (payload, params) => {
			params.type = STATIC_BUTTON;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StaticButtonModule);
