import React from "react";
import $ from "jquery";
import {addClass} from "../generateClassName";
import {GenerateReplicaIssueCover} from "../../widgets/generateSummary";
import {GenerateHtmlMarkup} from "../../widgets/generateHtmlMarkup";
import {clone, cloneWithoutReplicaList} from "../objectUtils";
import {getElementAttributes} from "../htmlUtils";
import {getIntValue, isEmpty} from "../generalUtils";
import {pageClicked} from "./replicaActions";
import GenerateTitle from "../../widgets/generateTitle";
import {Translate} from "../../locales/locales";



/**
 * Parse the toc markup from issue editor, updating tag attributes, getting the
 * folio value and the list level to generate list.
 *
 * We use jQuery to parse the html as it is clean and efficient, but only to
 * generate the toc attributes from the provided markup.
 *
 * @param markup html markup for toc
 * @returns {*[]}
 */
const generateTocAttributes = (markup) => {
	markup = !isEmpty(markup) ? markup : '';
	const tocAttributes = [];
	const $tocMarkup = $(markup);
	if ($tocMarkup.length > 0) {
		const $aTags = $tocMarkup.find('a');
		const aTagsList = $.makeArray($aTags);

		$tocMarkup.find('a').each(function(index, el) {
			const $aTag = $(el);

			const allAttributes = getElementAttributes(el);
			const folio = !isEmpty(allAttributes['data-page-number']) ? allAttributes['data-page-number'] : '';
			const contents = $aTag.html().replace(/\n/g, '');
			const pageStart = contents.match(/page_start\s+(.*?)\s/);
			const level = !isEmpty(pageStart) ? getIntValue(pageStart[1], 1) : 1;

			// update some attributes based on folio
			allAttributes.className = addClass(allAttributes.className, ['folio-'+folio]);
			allAttributes.href = Translate.Text({ id: 'replicaList.open.page' }) + folio;  // just want href for screen reader and hover

			const liBlock = {
				linkEntry: index,
				attributes: allAttributes,
				folio: folio,
				level: level,
				contents: contents,
				listIndex: index
			};

			// Required for proper styling and navigation
			// update attributes of the <a> tag
			$aTag.addClass(!isEmpty(folio) ? 'folio-'+folio : '');
			$aTag.attr("href", allAttributes.href);
			$aTag.attr("data-link-entry", index);
			// update special attributes of the <li> parent tag
			if ($aTag.hasClass('cover_link') || $aTag.hasClass('toc_anchor')) {
				$aTag.parents('li').addClass($aTag.attr('class'));  // copy class to <li> parent
			}

			tocAttributes.push(liBlock);
		});
	}
	return {tocAttributes: tocAttributes, markup: $tocMarkup.html()};
};


/**
 * Handle a click on the list-style toc as a whole.
 * Since the toc is generated from pre-made markup from the Issue Editor,
 * we capture a click event that bubbles up to the top of the toc and
 * determine if the original click was on an <a> tag.
 *
 * If so, we process the attributes related to the folio associated
 * with the toc line and call the replicaList "pageClicked" function
 * to handle tracking and navigation to the correct replica page.
 *
 * @param evt click event
 * @param params
 *     replicaListAttributes: parameters related to the replica list
 *     tocAttributes: parameters related to the toc; excluding markup
 */
const handleTocPageClick = (evt, params) => {
	params = Object.assign({
		replicaListAttributes: {},
		tocAttributes: {}
	}, params);

	// prevent the <a> tag original action and stop event propagation
	evt.stopPropagation();
	evt.preventDefault();

	// just use jQuery to identify if the click event happened on an <a> tag
	const $aTagTarget = $(evt.target).closest('a');
	if ($aTagTarget.length > 0 && !isEmpty(params.tocAttributes)) {
		const replicaList = !isEmpty(params.replicaListAttributes.fullList) ? params.replicaListAttributes.fullList : {};
		const linkEntry = $aTagTarget[0].getAttribute('data-link-entry');
		const linkAttributes = params.tocAttributes[linkEntry];
		const folio = linkAttributes.folio;
		let replicaPage = replicaList.find(page => page.folio === folio);
		replicaPage = !isEmpty(replicaPage) ? replicaPage : {};

		const pageParams = {
			moduleProps: params.replicaListAttributes.props,
			mops: params.replicaListAttributes.mops,
			replicaList: replicaList,
			folio: folio,
			issueUrl: replicaPage.issueUrl,
			updateHistoryState: false,
			trackingParams: {
				title: linkAttributes.attributes.title,
				link: linkAttributes.attributes.href,
				domain: replicaPage.domain,
			}
		};
		// call general pageClicked function to handle tracking/navigation
		pageClicked(evt, pageParams);
	}
};


/**
 * Generate the actual markup for the toc using the provided markup from the
 * Issue Editor markup.
 * Wrap the list tags in an <ol> tag and provide an onClick handler on the
 * <ol> tag while passing appropriate replica list and toc parameters to
 * the onClick handler.
 *
 * @param params
 *     replicaListAttributes: parameters related to the replica list
 *     tocListAttributes: parameters related to the toc
 * @returns {JSX.Element|null}
 * @constructor
 */
const GenerateTocMarkup = (params) => {
	params = Object.assign({
		replicaListAttributes: {},
		tocListAttributes: {}
	}, params);
	const {replicaListAttributes, tocListAttributes} = params;
	if (!isEmpty(tocListAttributes)) {
		const tocAttributes = tocListAttributes.tocAttributes;
		const tocMarkup = tocListAttributes.markup;

		return (
			<ol onClick={(evt) => {
				handleTocPageClick(evt, {
					replicaListAttributes: replicaListAttributes,
					tocAttributes: tocAttributes
				});
			}}>
				<GenerateHtmlMarkup htmlMarkup={tocMarkup} />
			</ol>
		);
	} else {
		return null;
	}
};


/**
 * Call to generate the Turnstyle list-style toc.
 * This reads html markup, generated from the Issue Editor.
 *
 * First it parses the html to gather attributes related to each
 * line of the generated toc (ordered list).
 * Then, it generates the html from the provided markup, while passing
 * the gathered attributes along for tracking and click action.
 *
 * @param params
 *     moduleProps: module props
 *     mops: module mops
 *     replicaList: list of replica pages for the issue
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateListStyleReplicaList = (params) => {
	params = Object.assign({
		moduleProps: {},
		mops: {},
		replicaList: {},
	}, params);
	const {mops, replicaList} = params;
	//const props = clone(params.moduleProps);
	const props = cloneWithoutReplicaList(params.moduleProps);

	const tocListAttributes = generateTocAttributes(props.listStyleToc);

	const replicaListAttributes = {
		props: props,
		mops: mops,
		fullList: replicaList,
	};

	const dataAttributes = {
		'data-called-from-module': props.navigationAttributes.hasOwnProperty('fromModule') ? props.navigationAttributes.fromModule : null,
		'data-called-from-instance': props.navigationAttributes.hasOwnProperty('fromInstance') ? props.navigationAttributes.fromInstance : null,
		'data-storagekey': props.storageKey,
		'data-issue-group': props.issueGroup,
	};


	return (
		<div className={"replicaListContainer"} tabIndex={'-1'} {...dataAttributes}>
			<GenerateReplicaIssueCover {...replicaListAttributes} />
			<div className="replicaList replica-pages-list-style">
				<GenerateTitle titleParams={mops.titleParams} />
				<div className={"replica-pages"}>
					<GenerateTocMarkup tocListAttributes={tocListAttributes} replicaListAttributes={replicaListAttributes} />
				</div>
			</div>
		</div>
	);
};
export {GenerateListStyleReplicaList};
