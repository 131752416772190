import React, {useEffect} from "react";
import {triggerOnReplicaListUI} from "../../../triggers/triggerOnReplicaList";
import {findReplicaPage, populateReplicaActions} from "../../../utils/replicaList/replicaActions";
import {GenerateReplicaSummary} from "../../../widgets/generateSummary";
import {addClass} from "../../../utils/generateClassName";
import {isEmpty, isTrue} from "../../../utils/generalUtils";


/**
 * Generate a single page of replica pages for paging.
 *
 * @param pageParams params from generatePages
 * @returns {*}
 * @constructor
 */
const GenerateReplicaPage = (pageParams) => {
	pageParams = Object.assign({
		props: {},
		mops: {},
		entries: [],
		fullList: [],
		page: 0,
		entryPage: 0,
		updateHistoryState: false,
		moduleDOMElement: null,
		moduleSpecific: {}
	}, pageParams);
	const props = pageParams.props;

	const replicaDisplayParams = props.hasOwnProperty('replicaDisplayParams') ? props.replicaDisplayParams : {};
	const pageList = pageParams.entries;
	const replicaList = pageParams.fullList;
	const currentReplicaPage = findReplicaPage({replicaList: replicaList, folio: props.folio, pageNumber: props.pageNumber});
	const pageSpread = isEmpty(pageParams.moduleSpecific.pageSpread) ? 1 : pageParams.moduleSpecific.pageSpread;
	const spreadPageNumbers = isEmpty(currentReplicaPage) ? [] : (pageSpread === 1 ? [currentReplicaPage.pageNumber] : currentReplicaPage.spread);

	const displayClassName = 'display-page' + (pageParams.page === pageParams.entryPage ? ' selected' : '');
	const className = 'replica-page';
	const replicaClassBase = 'replica-entry';

	const replicaActions = populateReplicaActions({
		props: props,
		mops: pageParams.mops,
		replicaList: replicaList,
		updateHistoryState: isTrue(pageParams.updateHistoryState)
	});

	/**
	 * These triggers require that the replicaList be built in the UI before activation, so place in a
	 * useEffect that activates at the end of the jsx
	 */
	useEffect(() => {
		const triggerParams = {
			module: 'replicaList',
			storageKey: props.storageKey,
			folio: props.folio,
			pageSpread: pageSpread,
			replicaListKey: pageParams.mops.queryParams,
			scrollToSelection: isTrue(props.scrollToSelection),
			scrollToLastPosition: isTrue(props.scrollToLastPosition)
		};
		triggerOnReplicaListUI(triggerParams);
	});


	return (
		<div className={displayClassName} data-page={pageParams.page} ref={pageParams.moduleDOMElement}>
			<ul className={className}>
				{
					pageList.map((page) => {
						const replicaClassList = [].concat([
							'card-' + replicaDisplayParams.cardTemplate,
							'folio-' + page.folio,
							isTrue(spreadPageNumbers.includes(page.pageNumber)) ? 'folio-current' : ''
						]);
						const replicaClass = addClass(replicaClassBase, replicaClassList);
						return (
							<li key={page.folio} data-folio={page.folio} className={replicaClass}>
								<div className={'replica-entry-contents'}>
									<GenerateReplicaSummary entry={page} actions={replicaActions} template={replicaDisplayParams.cardTemplate} displayParams={replicaDisplayParams} moduleProps={props} />
								</div>
							</li>
						);
					})
				}
			</ul>
		</div>
	);
};
export {GenerateReplicaPage};
