import React from 'react';
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	TumblrShareButton,
	WhatsappShareButton,
	PinterestShareButton,
	RedditShareButton,
	TelegramShareButton,
	FacebookMessengerShareButton,
	ViberShareButton,
	WeiboShareButton,
	EmailShareButton,
} from "react-share";
import {isEmpty, isTrue} from "../../../utils/generalUtils";
import * as Tracking from "../../../utils/tracking";
import {openInAppBrowser} from "../../../utils/nativeAppInterface";
import deepmerge from "deepmerge";
import {translatedString} from "../../../locales/locales";


/**
 * Handle click on share button.
 *
 * @param event click event
 */
/**
 * Handle click on share button for different cases.  If desktop
 * @param params
 */
const shareButtonClicked = (params) => {
	params = Object.assign({
		buttonName: '',
		shareUrl: '',
		documentTitle: '',
		shareType: {},
	}, params);
	const {buttonName, shareUrl, documentTitle, shareType, articleId, articleTitle, issueName, category, folio} = params;

	// Prepare tracking properties for the share click
	// trackingParams are passed through from manageShareable
	const trackingProperties = {
		"destination type": "share",
		"document title": document ? document.title : '',
		"label": "Share",
		"location": "modular library",
		"share type": buttonName,
		"url": shareUrl,
	};

	if (!isEmpty(articleId)) {
		trackingProperties["article title"] = articleTitle;
		trackingProperties["document name"] = issueName;
		trackingProperties.category = category;
	}

	if (!isEmpty(folio)) {
		trackingProperties["page folio"] = folio;
		trackingProperties["document name"] = issueName;
	}

	// Handle share and tracking by type
	if (shareType.type === 'web') {
		Tracking.libraryTrack("shared", trackingProperties);
	} else if (shareType.type === 'Navigator') {
		const shareData = {
			title: documentTitle,
			url: shareUrl,
		};
		navigator.share(shareData)
			.then(() => {
				Tracking.libraryTrack("shared", trackingProperties);
			})
			.catch(console.error);
	} else if (shareType.type === 'App') {
		// track platform = ios or android
		trackingProperties.platform = shareType.platform;
		if (shareType.platform === 'ios') {
			// @TODO: handle iOS native app share if navigator.share is not supported
		} else if (shareType.platform === 'android') {
			Tracking.libraryTrack("shared", trackingProperties);
			openInAppBrowser({type: 'share', url: shareUrl});
		}
	}

};


/**
 * Email body gets a different string depending on whether we share a replica page
 * or something else (ex. article).  Since a replica page doesn't have a real
 * summary value, we want to use the string that does not include description.
 *
 * @param specialParams
 *     instructions: for handling with/without email description
 *     shareParam: the share param to return string (body)
 *     replaceableStrings: the replaceable strings object for string replacement
 * @returns {*}
 */
const generateEmailBody = (specialParams) => {
	specialParams = Object.assign({
		instructions: {},
		shareParam: '',
		replaceableStrings: {},
	}, specialParams);
	const {instructions, shareParam, replaceableStrings} = specialParams;

	const stringId = isTrue(instructions.emailIncludeDescription) ? shareParam.withDescription : shareParam.withoutDescription;
	return translatedString({id: stringId, replacements: replaceableStrings});
};


/**
 * Get the i18n value for each of shareParams for a share button.
 * If the returned, translated string is empty, then don't add it to the final set
 * of attributes.
 *
 * @param params
 *     buttonName: button name for special handling
 *     shareParams: share params to use for strings
 *     replaceableStrings: set of replacements
 *     specialHandling: instructions for special handling of generated strings
 * @returns {{}|*}
 */
const generateTranslatedParams = (params) => {
	params = Object.assign({
		buttonName: '',
		shareParams: {},
		replaceableStrings: {},
		custom: {},
	}, params);
	const {buttonName, shareParams, replaceableStrings, custom} = params;

	if (isEmpty(shareParams) || isEmpty(replaceableStrings)) {
		return shareParams;
	}
	const translatedParams = {};
	Object.keys(shareParams).forEach(param => {
		let translatedAttribute;
		if (custom.hasOwnProperty(buttonName) && param === 'body') {
			translatedAttribute = generateEmailBody({instructions: custom[buttonName], shareParam: shareParams[param], replaceableStrings: replaceableStrings});
		} else {
			translatedAttribute = translatedString({id: shareParams[param], replacements: replaceableStrings});
		}
		// only exclude if empty string
		if (!isEmpty(translatedAttribute, {trimString: false})) {
			translatedParams[param] = translatedAttribute;
		}
	});
	return translatedParams;
};


/**
 * Generate a button to share via navigator.share() or App code
 *
 * @param params
 *     clickAction: function to run on button click
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateNativeShareButton = (params) => {
	params = Object.assign({
		shareUrl: '',
		documentTitle: '',
		shareType: {},
		trackingParams: {},
	}, params);
	return (
		<div className={'share-button-wrapper'}>
			<button className={'share-button'} onClick={() => shareButtonClicked(params)}>
				<span className={'fas fa-share-alt'} />
				<span className={'screen-reader'}>Share</span>
			</button>
		</div>);
};
export {GenerateNativeShareButton};


/**
 * Generate our own Print button as it is not one of react-share buttons.
 *
 * @params
 *     key: key value for generated button as it is generated from an array of values
 *     children: React child element from GeneratedTag
 *
 * @type {function(*=)}
 */
const PrintButton = (params => {
	params = Object.assign({
		key: '',
		buttonName: '',
		shareUrl: '',
		documentTitle: '',
		description: '',
		shareType: {},
		children: '',
		beforeOnClick: null
	}, params);
	const buttonName = !isEmpty(params.buttonName) ? params.buttonName : 'print';
	const buttonClassName = buttonName + "-share-button-icon";

	return (
		<button
			key={params.key}
			aria-label={buttonName}
			className={buttonClassName}
			onClick={() => {
				params.beforeOnClick();
				window.print();
			}}
		>
			{params.children}
		</button>
	);
});


/**
 * Map button name to react-share function
 *
 */
const reactShareButtons = {
	"facebook": {
		"button": FacebookShareButton,
		"shareParams": {
			"quote": "share.facebook.quote"
		}
	},
	"twitter": {
		"button":  TwitterShareButton,
		"shareParams": {
			"title": "share.twitter.title"
		}
	},
	"linkedin": {
		"button": LinkedinShareButton,
		"shareParams": {
			"title": "share.linkedin.title"
		}
	},
	"tumblr": {
		"button": TumblrShareButton,
		"shareParams": {
			"title": "share.tumblr.title"
		}
	},
	"whatsapp": {
		"button": WhatsappShareButton,
		"shareParams": {
			"title": "share.whatsapp.title"
		}
	},
	"pinterest": {
		"button": PinterestShareButton,
		"shareParams": {
			"description": "share.pinterest.description"
		}
	},
	"reddit": {
		"button": RedditShareButton,
		"shareParams": {
			"title": "share.reddit.title"
		}
	},
	"telegram": {
		"button": TelegramShareButton,
		"shareParams": {
			"title": "share.telegram.title"
		}
	},
	"facebookMessenger": {
		"button": FacebookMessengerShareButton,
		"shareParams": {}
	},
	"viber": {
		"button": ViberShareButton,
		"shareParams": {
			"title": "share.viber.title"
		}
	},
	"weibo": {
		"button": WeiboShareButton,
		"shareParams": {
			"title": "share.weibo.title"
		}
	},
	"email": {
		"button": EmailShareButton,
		"shareParams": {
			"subject": "share.email.subject",
			"body": {
				"withDescription": "share.email.body.withDescription",
				"withoutDescription": "share.email.body.withoutDescription"
			},
			"separator": "share.email.separator",
			"openShareDialogOnClick": true
		}
	}
};
const additionalShareButtons = {
	"print": {
		"button": PrintButton,
		"shareParams": {}
	}
};


/**
 * Generate a Share Button based on the buttons available in the react-share package.
 *
 * @params
 *     buttonName: button name to match to share type
 *     shareUrl: share url parameter to pass to button
 *
 * @type {(function(*=): (null|*))|*}
 */
const GenerateReactShareButton = (params => {
	params = Object.assign({
		buttonName: '',
		shareUrl: '',
		description: '',
		emailIncludeDescription: true,
		documentTitle: '',
		shareType: {},
	}, params);
	const {buttonName, shareUrl, description, documentTitle} = params;
	const shareButtons = deepmerge(reactShareButtons, additionalShareButtons);
	const supportedButtons = Object.keys(shareButtons);
	const additionalButtons = Object.keys(additionalShareButtons);

	const replaceableStrings = {
		"buttonName": buttonName,
		"shareUrl": shareUrl,
		"documentTitle": documentTitle,
		"summary": description
	};

	if (isEmpty(buttonName) || !supportedButtons.includes(buttonName)) {
		return null;
	} else {
		const shareButton = shareButtons[buttonName];
		const GeneratedTag = shareButton.button;
		const buttonI18nId = "share." + buttonName + ".buttonTitle";
		const buttonTitle = translatedString({id: buttonI18nId});

		const imageParams = {
			"title": buttonTitle !== buttonI18nId ? buttonTitle : buttonName,
			"className": "font-awesome-icons " + buttonName + "-button-icon",
			"aria-hidden": "true"
		};
		let buttonParams;
		if (additionalButtons.includes(buttonName)) {
			buttonParams = params;
		} else {
			buttonParams = Object.assign({},
				{
					url: shareUrl,
					title: documentTitle,
					description: description,
					resetButtonStyle: false
				},
				generateTranslatedParams({
					buttonName: buttonName,
					shareParams: shareButton.shareParams,
					replaceableStrings: replaceableStrings,
					custom: {
						email: {
							emailIncludeDescription: isTrue(params.emailIncludeDescription),
						}
					}
				})
			);
		}

		return (
			<GeneratedTag {...buttonParams} beforeOnClick={() => shareButtonClicked(params)}>
				<span {...imageParams}/>
			</GeneratedTag>
		);
	}
});
export {GenerateReactShareButton};

