import React from "react";
import {manageUpdateAndNavigation} from "../modules/globals/globalNavigator";
import { populateFetchDataAttributes } from "../utils/populateFetchDataAttributes";
import {findArticle} from "../utils/articleList/articleActions";
import * as Tracking from "../utils/tracking";
import {clone} from "../utils/objectUtils";


/**
 * Generate category buttons (multiple) based on the list of categories that
 * are passed in.
 *
 * @param params
 *     moduleProps: props from module
 *     articleId: selected article id
 *     articleTitle: title of selected article
 *     categories: list of categories
 * @returns {JSX.Element|string}
 * @constructor
 */
const GenerateCategoryButtons = (params) => {
	params = Object.assign({
		moduleProps: {},
		articleId: 0,
		articleTitle: '',
		categories: []
	}, params);

	/**
	 * Handle click on category button.  Call navigation to go to correct module.
	 *
	 * @param event click event
	 * @param categoryName category name from button
	 */
	const categoryButtonAction = (event, categoryName) => {
		let categoryProps = clone(params.moduleProps);
		// check the category clicked and reset the fetchQueryParams if present
		if (categoryProps.hasOwnProperty('fetchQueryParams')) {
			categoryProps.fetchQueryParams.categories = categoryName;
		} else {
			categoryProps.fetchQueryParams = {};
		}
		categoryProps.categories = categoryName;
		categoryProps = populateFetchDataAttributes({
			props: categoryProps,
			data: {categories: categoryName}
		});
		const trackingProperties = {
			"label": categoryName,
			"type": "category button",
			"module": "article viewer",
			"article title": params.articleTitle,
			"destination type": "category",
			"location": "modular library"
		};
		Tracking.libraryTrack("button clicked", trackingProperties);
		const destinationArticle = findArticle({articleList: categoryProps.articleList, articleId: params.articleId});
		const issueUrl = destinationArticle.hasOwnProperty('issueUrl') ? destinationArticle.issueUrl : '';
		manageUpdateAndNavigation({
			navigationKey: 'category',
			moduleProps: categoryProps,
			attributes: {
				articleId: params.articleId,
				issueUrl: issueUrl,
				categories: categoryName,
				fetchQueryParams: categoryProps.fetchQueryParams,
				articleList: categoryProps.hasOwnProperty('articleList') ? categoryProps.articleList : [],
				updateHistoryState: true			}
		});
	};


	if (params.categories === null) {
		return '';
	}

	const excludeCategories = params.moduleProps.hasOwnProperty('excludeCategories') && Array.isArray(params.moduleProps.excludeCategories) ? params.moduleProps.excludeCategories: [];
	const displayCategories = params.categories.filter(cat => !excludeCategories.includes(cat));

	let fetchNavigationValue = '';
	if (params.moduleProps.hasOwnProperty('fetchNavigationKey') && params.moduleProps.fetchNavigationKey === 'category') {
		fetchNavigationValue = params.moduleProps.hasOwnProperty('fetchQueryParams') && params.moduleProps.fetchQueryParams.hasOwnProperty('categories') ? params.moduleProps.fetchQueryParams.categories : '';
	}

	return (
		<div className={'category-buttons-wrapper'}>
			{
				displayCategories.map((categoryName, index) => {
					if (categoryName !== "") {
						const keyValue = params.moduleProps.storageKey + categoryName + index.toString();
						const buttonClassName = categoryName === fetchNavigationValue ? 'category-button fetched-category' : 'category-button';
						return <button className={buttonClassName}
									   key={keyValue}
									   onClick={(event) => categoryButtonAction(event, categoryName)}
									   data-name="category"
									   data-articleid={params.articleId}>{categoryName}</button>;
					} else {
						return '';
					}
				})
			}
		</div>
	);
};
export default GenerateCategoryButtons;
