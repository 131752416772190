import 'isomorphic-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
//import {createStore} from './store';
import store from './store';
import {isServer} from "./utils/generalUtils";


if (!isServer) {
	ReactDOM.hydrate(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById('root')
	);

	// TODO: Remove these debugging statements after live testing
	document.addEventListener("gtxlogin", function(e) {
		console.log("intercepted gtxlogin event of type " + e.type);
		console.log(e.detail);
	});
	document.addEventListener("gtxdatalayer", function(e) {
		console.log("intercepted gtxdatalayer event of type " + e.type);
		console.log(e.detail);
	});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//if (!isServer){
	serviceWorker.unregister();
//}
}
