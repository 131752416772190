import {clone} from "./objectUtils";

/**
 * Given a key for a specific type of module defined in the navigationKeys attribute,
 * move that module definition up to the top of the list.
 *
 * @param params
 *     modules: from navigationKeys attribute for modules
 *     key: the specific definition that should be moved to top of list
 */
const reorderNavigationKeysModules = (params) => {
	params = Object.assign({
		modules: {},
		key: ''
	}, params);
	const modules = clone(params.modules);
	const itemIndex = modules.findIndex(module => module.module === params.key);
	// skip if key is not found (-1) or is already first (0)
	if (itemIndex > 0) {
		const item = modules.splice(itemIndex, 1)[0];
		modules.splice(0, 0, item);
	}
	return modules;
};
export {reorderNavigationKeysModules};
