import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {displayOnDevice, getActiveAttributeState} from "../../../utils/moduleSetup";
import embedJavascript, {executeFunction} from "../../../utils/embedJavascript";
import {getObjectFromJSON} from "../../../utils/objectUtils";
import {useMountPostRender} from "../../../hooks/useMount";
import {isEmpty} from "../../../utils/generalUtils";
import getDeviceType from "../../../utils/getDeviceType";
import {useAttributesChanged} from "../../../hooks/useAttributesChanged";


/**
 * This is a simple module to embed and execute a 3rd party javascript source.
 * It relies on the embedJavascript function to actually embed the javascript in the DOM.
 *
 * props
 *     javascript src
 *
 * @param props
 * @returns {string} empty string (module must return something)
 * @constructor
 */
export const EmbedJavascriptModule = (props) => {

	// run once, since props.src won't change, to call to embed and execute javascript src
	// "src" is DEPRECATED in favor of "body" for configuration
	useMountPostRender(() => {
		if (!isEmpty(props.src)) {
			embedJavascript(props.src);
		}
	});
	useMountPostRender(() => {
		if (!isEmpty(props.body)) {
			embedJavascript(props.body);
		}
	});

	useMountPostRender(() => {
		if (!isEmpty(props.init)) {
			executeFunction(props.init, props.initParams);
		}
	});

	// run on breakpoint change
	const currentBreakpoint = getDeviceType();
	useAttributesChanged(() => {
		if (!isEmpty(props.jsOnChange)) {
			executeFunction(props.jsOnChange, props.jsOnChangeParams);
		}
	}, [currentBreakpoint]);


/*
	// need to figure out a better way to trigger on change, as this gets called too often
	useEffect(() => {
		if (!isEmpty(props.jsOnChange)) {
			executeFunction(props.jsOnChange, props.jsOnChangeParams);
		}
	}, [currentBreakpoint, props.jsOnChange, props.jsOnChangeParams]);
*/

	return null;

};

/**
 * Set modle properties from props and store state.
 *
 * NOTE: Javascript added to the "head" configuration is embedded from the node server
 * in a call to "gatherGlobalModulesAndAttributes" in the "generateAdditionalConfigObjects.js" file.
 *
 * Note: although activeAttribute is supported, it may not behave as expected;
 * use at your own risk...
 *
 *
 * @param state redux store state
 * @param props from configuration
 * @returns {{active: (*|string)}}
 */
const mapStateToProps = (state, props) => {
	const embedProps = {
		active: getActiveAttributeState(props),
	};
	if (!isEmpty(props.executeJavascriptOnChange)) {
		const changeJS = getObjectFromJSON(props.executeJavascriptOnChange, {});
		embedProps.jsOnChange = changeJS.hasOwnProperty('function') ? changeJS.function : null;
		embedProps.jsOnChangeParams = changeJS.hasOwnProperty('params') ? changeJS.params : [];
	}

	return embedProps;
};


export default connect(
	mapStateToProps,
	null
)(EmbedJavascriptModule);
