import React from 'react';
import {connect} from 'react-redux';
import LogTimestamp from 'log-timestamp';  // don't remove; auto-timestamp
import PopulateStructure from './structures/populateStructure';
import PopulateGlobalModules from "./structures/PopulateGlobalModules";
import {contentHubAction} from "./utils/nativeAppInterface";
import {isEmpty, isServer, isTrue} from "./utils/generalUtils";
import {generateInitialVariableStyles} from "./modules/globals/globalLibraryData";
import {Modal} from "./widgets/modal/modal";
import {FootnoteWidget} from "./widgets/footnote/index";
import {GLOBALS, FOOTNOTE} from "./modules/_MODULE_GLOBALS/constants";
import {getStoreValue} from "./utils/storeValue";
import {addClass} from "./utils/generateClassName";


/**
 * Generte the overall className from props attributes
 * @param props
 * @returns {*}
 */
const generateLibraryClassName = (props) => {
	const classNameArray = [];
	classNameArray.push(!isEmpty(props.platform) ? props.platform : '');
	classNameArray.push((props.structures.hasOwnProperty('THEME') && props.structures.THEME.hasOwnProperty('className')) ? props.structures.THEME.className : '');
	classNameArray.push(isTrue(props.app) ? 'app' : 'web');
	classNameArray.push(isTrue(props.preview) ? 'preview-library' : '');
	classNameArray.push(isTrue(props.darkmode) ? 'darkmode' : '');
	classNameArray.push(isTrue(props.notchHeight) ? 'has-notch' : '');
	classNameArray.push(!isEmpty(props.collectionUrl) ? props.collectionUrl : '');
	return addClass(props.baseConfigurationName, classNameArray);
};


/**
 * App Component is the highest level real component in the application, it is the parent of the routes and
 * an ancestors of all other components.
 * BaseLayout will be generated using the store "layout" passed in from
 * configuration data.
 * Note: classname for app is added to enclosing div in PopulateLayout
 */
export const App = (props) => {
	const libraryClassName = generateLibraryClassName(props);
	const initialVariableStyles = generateInitialVariableStyles(props.configuredContainers);

	const showFootnotes = getStoreValue({type: GLOBALS, storageKey: "FOOTNOTE", attributeKey:"showFootnotes"});
	// place classes or styles in the app-wrapper that wrap the App
	// note: these will we below any root classes added on the server
	return (
		<div className={'app-wrapper'} style={initialVariableStyles}>
			<div id={"App"} className={libraryClassName}>
				<div id={"structure-block"}>
					<PopulateStructure app={"library"} />
				</div>
				<PopulateGlobalModules />
				<Modal storageKey={FOOTNOTE} showModal={showFootnotes} callback={FootnoteWidget}/>
			</div>
		</div>
	);
};


// export default App;
// note: we are using combineReducers which uses key:value pairs for each set of reducers
export default connect(
	(state) => {
		return {
			structures: state.structures,
			collectionUrl: state.collectionUrl,
			baseConfigurationName: state.baseConfigurationName,
			customStrings: state.customStrings,
			configuredContainers: state.configuredContainers,
			app: state.app,
			platform: state.platform,
			darkmode: state.darkmode,
			preview: state.preview,
			notchHeight: state.notchHeight
		};
	},
	null
)( App);

if (!isServer) {
	window.contentHubAction = contentHubAction;
}
