import React from "react";
import GenerateTitle from "../generateTitle";
import {populateArticleData} from "../generateSummary";
import { Translate } from "../../locales/locales";
import {clone} from '../../utils/objectUtils';
import deepmerge from "deepmerge";


/**
 * Generates subMenu markup from html content
 *
 * @param params
 * @returns {JSX.Element}
 */
const GenerateSubMenu = (params) => {
	params = Object.assign({
//		actions: {},
		items: {},
		cardEvents: {}
	}, params);
	const cardEvents = params.cardEvents;
	return params.items.length < 1 ? '' : (
		<ul className={"textbook-submenu"}
			onMouseEnter={cardEvents.handleSubMenuHover}
			onMouseLeave={cardEvents.handleSubMenuHover}>
			<li>
				{
					params.items.map((item) => {
						// key must be added to outermost tag, so we use full React.Fragment tag instead of shortcut (<>)
						return (
							<React.Fragment key={item.section}>
								<a
									href={"//scroll_to/" + item.section}
									className={"textbook-submenu-link"}
									onClick={item.clickAction}>
									<span className={"section-number"}>{item.sectionNumber}</span>
									<span className={"section-title"}>{item.sectionTitle}</span>
								</a>
							</React.Fragment>
						);
					})
				}
			</li>
		</ul>
	);
};


/**
 * Generate html for Textbook articleList listType card
 *
 * @param params
 *     entry: article object
 *     actions: article actions to use for populate article data
 *     displayParams: article display params
 * @returns {JSX.Element}
 * @constructor
 */
const GenerateCardSections = (params) => {
	params = Object.assign({
		entry: {},
		actions: {},
		displayParams: {}
	}, params);
	const entry = clone(params.entry);
	// set article properties for template
	const articleData = populateArticleData(entry, params.actions);

	// create an array of sectionMenuItem objects that are passed to `GenerateSubMenu`
	// add section data to click action attributes for section menu items
	const sectionMenuItems = entry.sectionMenuItems.map((sectionData) => {
		entry.section = sectionData.section;
		const sectionArticleData = populateArticleData(entry, params.actions);
		return deepmerge(sectionData, {clickAction: sectionArticleData.clickAction});
	});

	// generate class if has section menu items or not
	const entryClass = sectionMenuItems.length > 0 ? 'textbook-entry-buttons' : 'textbook-entry-buttons no-submenu';

	// set titleParams
	const titleParams = {
		tag: 'span',
		className: 'title',
		displayTitle: true,
	};

	// set front end event handling
	const cardEvents = params.actions.hasOwnProperty('cardEvents') ? params.actions.cardEvents : {};
	// icon class name if current article or not
	const iconClassName = articleData.isCurrentArticle ? 'fas fa-minus-square' : 'fas fa-plus-square';
	// icon screen reader text
	const iconText = articleData.isCurrentArticle ? Translate.Text({id:'articleList.textbookCard.subMenuIconTextClose'}) : Translate.Text({id:'articleList.textbookCard.subMenuIconTextExpand'});

	return (
		<div className={"textbook-entry-wrapper"}>
			<div className={entryClass}>
				<button
					className={"textbook-entry-title"}
					onMouseEnter={cardEvents.handleTitleHover}
					onMouseLeave={cardEvents.handleTitleHover}
					onClick={articleData.clickAction}>
					<GenerateTitle titleParams={titleParams}>{entry.title}</GenerateTitle></button>
				{ sectionMenuItems.length < 1 ? '' : <button className={"toggle-submenu"} onClick={cardEvents.handleIconClick}><i className={iconClassName} /><span className={'screen-reader'}>{iconText}</span></button> }
			</div>
			{ sectionMenuItems.length < 1 ? '' : <GenerateSubMenu items={sectionMenuItems} cardEvents={cardEvents} /> }
		</div>
	);
};
export default GenerateCardSections;
