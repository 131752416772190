import {
	DATA_LOADED,
	DATA_UPDATED,
	PURCHASE,
	SUBSCRIBER,
	GLOBALS_KEY,
	AUTHENTICATION_KEY,
	EASTEREGG_KEY,
	DATA_REQUESTED,
	EASTEREGG,
	AUTHENTICATION,
	GLOBALS,
	ID_NOT_SET
} from "../../_MODULE_GLOBALS/constants";
import deepmerge from "deepmerge";
import {createNewStoreState} from "./generalReducerActions";

/*
   This reducer updates global attributes for use by any module
   Currently detected attributes:
       currentPane
       isLoggedIn
       products
       share: {
           currentShareKey,  // hash key for full shareable object
           url,  // full url, including query parameters
           href  // url with no query parameters
       }
 */

/**
 * Required store properties for globals
 *
 * This ensures that there is a placeholder in the store for
 * the values returned for the module.
 * Each action-type then initializes the state for it's store state.
 */
const createInitialGlobalState = ((storageKey) => {
	return {
		[storageKey]: {
			"share": {},
			"currentPane": "",
			"currentArticleId": ID_NOT_SET,
			"currentArticleIsOpen": false,
			"currentArticleCategory": "",
			"currentFolio": ID_NOT_SET,
			"currentIssueUrl": "",
		}
	};
});
const createInitialAuthenticationState = ((storageKey) => {
	return {
		[storageKey]: {
		}
	};
});
const createInitialEasterEggState = ((storageKey) => {
	return {
		[storageKey]: {
		}
	};
});

/**
 * List of attributes that should be re-initialized each time a reducer gets called.
 *
 * @type {function(*): {}}
 */
const resetRequestStoreGlobalState = ((storageKey) => {
	return {
		[storageKey]: {
		}
	};
});
const resetStoreGlobalState = ((storageKey) => {
	return {
		[storageKey]: {
			clearErrorMsg: false
		}
	};
});

const resetRequestStoreAuthenticationState = ((storageKey) => {
	return {
		[storageKey]: {
		}
	};
});
const resetStoreAuthenticationState = ((storageKey) => {
	return {
		[storageKey]: {
			clearErrorMsg: false
		}
	};
});
const resetStoreEasterEggState = ((storageKey) => {
	return {
		[storageKey]: {
			egg: ''
		}
	};
});



function globalsReducers(state, action) {
	// check module action call; if not present, set default to empty string so it doesn't unnecessarily trigger
	const moduleAction = (action.params && action.params.type) ? action.params.type : '';

	/* GENERIC GLOBAL REDUCERS - START */
	if (moduleAction === GLOBALS && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : GLOBALS_KEY;

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialGlobalState(storageKey),
			resetStorageStateValues: resetStoreGlobalState(storageKey)
		});

		return returnState;
	}
	/* GENERIC GLOBAL REDUCERS - END */


	/* AUTHENTICATION REDUCERS - START */
	/*     GLOBAL TO BE ACCESSIBLE TO MULTIPLE MODULES */

	if (moduleAction === AUTHENTICATION && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : AUTHENTICATION_KEY;

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialAuthenticationState(storageKey),
			resetStorageStateValues: resetStoreAuthenticationState(storageKey)
		});

		return returnState;
	}
	if (moduleAction === AUTHENTICATION && action.type === DATA_REQUESTED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : AUTHENTICATION_KEY;

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialAuthenticationState(storageKey),
			resetStorageStateValues: resetRequestStoreAuthenticationState(storageKey)
		});

		returnState[storageKey].authenticationInProgress = true;

		return returnState;
	}
	if (moduleAction === AUTHENTICATION && action.type === DATA_LOADED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : AUTHENTICATION_KEY;

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialAuthenticationState(storageKey),
			resetStorageStateValues: resetStoreAuthenticationState(storageKey)
		});

		// mismatch in isLoggedIn indicates that we're still waiting
		// for the real response
		if (action.payload.userAccess.isLoggedIn === state.globals.isLoggedIn) {
			returnState[storageKey].authenticationInProgress = false;
		}

		return returnState;
	}
	if (moduleAction === SUBSCRIBER && action.type === DATA_REQUESTED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : GLOBALS_KEY;

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialAuthenticationState(storageKey),
			resetStorageStateValues: resetRequestStoreGlobalState(storageKey)
		});

		returnState[storageKey].subscriberInProgress = true;

		return returnState;
	}
	if (moduleAction === SUBSCRIBER && action.type === DATA_LOADED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : GLOBALS_KEY;

		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialAuthenticationState(storageKey),
			resetStorageStateValues: resetStoreGlobalState(storageKey)
		});

		returnState[storageKey].subscriberInProgress = false;

		return returnState;
	}
	if (moduleAction === PURCHASE && ( action.type === DATA_LOADED || action.type === DATA_UPDATED)) {
		//  && ( action.type === DATA_LOADED || action.type === DATA_UPDATED)
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : GLOBALS_KEY;
		const returnState = deepmerge(
			createInitialGlobalState(storageKey),
			state
		);
		//The SUBSCRIBER action uses "globals" storageKey, so update the global State
		if (storageKey === GLOBALS_KEY) {
			console.log(action.payload);
			/*{ status: 'success',
  				PurchaseMetadata: '{"status":"success","subscriberId":"CSOUdQRvlOJET","foundExistingSubscriber":false,
				"verifiedPurchases":[{"purchaseState":"PURCHASED","notificationId":"88",
				"productId":"com.texterity.hybridecommerce_qa7.20201201124815","orderId":"GPA.3395-5301-7208-39321",
				"purchaseTime":1616433839460,"purchaseToken":"GPA.3395-5301-7208-39321","developerPayload":"GPA.3395-5301-7208-39321"}]}' }*/
			//const purchaseMetadata = JSON.parse(action.payload.PurchaseMetadata);
			const purchaseMetadata = action.payload.PurchaseMetadata || {};
			const verifiedPurchases = purchaseMetadata.verifiedPurchases;
			console.log(verifiedPurchases);
			if (verifiedPurchases && verifiedPurchases.length > 0) {
				console.log("successful purchase of " + verifiedPurchases[0].productId);
				returnState[storageKey].purchaseProductId = verifiedPurchases[0].productId;
				returnState[storageKey].isPurchaseSuccessful = true;
				returnState[storageKey].subscriberId = purchaseMetadata.subscriberId;
			}
			returnState[storageKey] = Object.assign(returnState[storageKey], action.payload);
		}
		console.log(returnState);
		return returnState;
	}
	/* AUTHENTICATION REDUCERS - END */



	/* EASTEREGG REDUCERS - START */
	if (moduleAction === EASTEREGG && action.type === DATA_UPDATED) {
		const storageKey = (action.params && action.params.storageKey) ? action.params.storageKey : EASTEREGG_KEY;

		// create new store state and update only changed attributes
		const returnState = createNewStoreState({
			storageKey: storageKey,
			state: state,
			action: action,
			initialStorageState: createInitialEasterEggState(storageKey),
			resetStorageStateValues: resetStoreEasterEggState(storageKey)
		});

		return returnState;
	}
	/* EASTEREGG REDUCERS - END */



	return state;
}
export default globalsReducers;

