import React from "react";
import GenerateStringFromList from "../generateStringFromlist";
import GenerateExcerpt from "../generateExcerpt";
import GenerateTitle from "../generateTitle";
import {populateArticleData, populateIssueData, GenerateSummaryIssue} from "../generateSummary";
import {truncateString} from "../../utils/stringUtils";

/**
 * Generate card with the large image size.
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GenerateCardLarge = (params) => {
	const entry = params.entry ? params.entry : {};
	const actions = params.actions ? params.actions : {};
	let displayParams = params.displayParams ? params.displayParams : {};

	displayParams = Object.assign({
		displayThumbnail: false,
		displayThumbnailOnLeft: false,
		displayIssue: false,
		displayCategory: false,
		displaySummary: true,
	}, displayParams);

	const titleParams = {
		tag: 'h3',
		className: 'title',
		displayTitle: true,
	};

	// set article and issue properties for template
	const articleData = populateArticleData(entry, actions);
	const issue = populateIssueData(entry, actions);
	// truncate summary if needed
	const summary = typeof entry.summary === 'string' && entry.summary !== '' ? truncateString(entry.summary, displayParams.maxSummaryCharacters, displayParams.useSummaryEllipsis) : '';

	// fallback to title for ALT tag if not available
	const imgAlt = typeof entry.imageAlt === 'string' && entry.imageAlt !== '' ? entry.imageAlt : entry.title + ", article thumbnail";

	/**
	 * Generate an a-tag element with different parts depending on whether an associated variable is set
	 *     entry.image !== '': generate a card image
	 *     displayParams.displayIssue: generate an issue summary
	 *     displayParams.displayCategory: generate a category list
	 *     displayParams.displaySummary: generate a summary excerpt
	 *
	 * Note: a description element and title is always generated, so the a-tag will not be empty
	 */
	return (
		<a href={articleData.url} className={'item-link'} data-articletitle={articleData.title} onClick={articleData.clickAction}>
			{
				entry.image !== '' &&
				<div className={'card-image'}>
					<img alt={imgAlt} className={'cropped-image'} src={entry.image} />
				</div>
			}
			<div className={'description'}>
				<GenerateTitle titleParams={titleParams}>{entry.title}</GenerateTitle>
				{
					displayParams.displayIssue &&
					<GenerateSummaryIssue issue={issue} />
				}
				{
					displayParams.displayCategory &&
					<div className={'article-categories'}>
						<GenerateStringFromList list={entry.categories} separator={', '} tag={'span'} />
					</div>
				}
				{
					displayParams.displaySummary &&
					<GenerateExcerpt className={'summary'} summary={summary} />
				}
			</div>
		</a>
	);
};
export default GenerateCardLarge;
