import React from "react";
import {Translate} from "../../../../locales/locales";
import {isEmpty, isTrue} from "../../../../utils/generalUtils";
import {navigateToTarget} from "../../../globals/globalNavigator";
import {addClass} from "../../../../utils/generateClassName";
import {findReplicaPage} from "../../../../utils/replicaList/replicaActions";
import {ID_NOT_SET} from "../../../_MODULE_GLOBALS/constants";
import {getReplicaPages} from "../../../../utils/replicaList/replicaActions";
import * as Tracking from "../../../../utils/tracking";
import {findArticle} from "../../../../utils/articleList/articleActions";

const trackAndNavigate = (targetParams) => {

	const pageAttributes = {
		replicaList: targetParams.attributes.replicaList,
		pageSpread: targetParams.attributes.pageSpread,
	};
	const pagesData = getReplicaPages({folio: targetParams.attributes.folio, pageAttributes: pageAttributes});
	const pagesFolios = [];
	pagesData.pages.forEach(page => {
		pagesFolios.push(page.folio);
	});

	const trackingProperties = {
		"destination article id": targetParams.attributes.articleId,
		"destination type": "article",
		"folio": pagesFolios,
		"label": "Go to Reading View",
		"location": "replica viewer",
		"module": "responsive button",
		"type": "click",
		"url": (window ? window.location.href : ''),
	};
	Tracking.libraryTrack("button clicked",trackingProperties);
	
	navigateToTarget(targetParams);
};


/**
 * Display and handle the click on a "navigation" button type.  This button is designed
 * to go to go to a module another pane.
 *
 * @param props props passed from the responsive button
 * @returns {*} the generated (clickable) tag to display
 * @constructor
 */
const ReadingViewButton = (props) => {
	props = Object.assign({
		GeneratedTag: `button`,
		className: '',
		title: '',
		label: '',
		replacementValues: {},
		navigationKey: '',
		navigationKeys: {},
		storeState: {},
		issueUrl: ''
	}, props);
	const {GeneratedTag, storeState} = props;

	const className = addClass('replica-viewer-control reading-view-button', props.className);
	const label = !isEmpty(props.label) ? Translate.Text({id: props.label}) : Translate.Text({id: 'actionButton.readingViewButton.label'});
	const title = !isEmpty(props.title) ? Translate.Text({id: props.title}) : Translate.Text({id: 'actionButton.readingViewButton.title'});

	const navigationKey = 'article';
	const navKey = props.navigationKeys.hasOwnProperty(navigationKey) ? props.navigationKeys[navigationKey] : {};
	const navKeyAttributes = navKey.attributes ? navKey.attributes : {};
	const targetKey = isEmpty(navKey.navigationTarget) ? 'article' : navKey.navigationTarget;

	const articleAttributes = {
		articleId: storeState.hasOwnProperty('articleId') ? parseInt(storeState.articleId, 10) : ID_NOT_SET,
		articleList: storeState.hasOwnProperty('articleList') ? storeState.articleList : [],
		folio: storeState.hasOwnProperty('folio') ? storeState.folio : '',
		pageNumber: storeState.hasOwnProperty('pageNumber') ? storeState.pageNumber : ID_NOT_SET,
		replicaList: storeState.hasOwnProperty('replicaList') ? storeState.replicaList : [],
		pageSpread: storeState.hasOwnProperty('pageSpread') ? storeState.pageSpread : 1,
		issueUrl: props.issueUrl
	};

	/**
	 * Get the articleId to pass to the articleViewer when transitioning from
	 * replica view to article view.  Since this starts at replica view, use
	 * folio and replica page to find articleId, if possible
	 *
	 * Try to get the articleId from the article matching the folio value
	 * if no article, get the articleId from the replicaPage matching the folio value
	 * If neither of these are available, use the default from storeState
	 */
	const article = findArticle({
		articleList: articleAttributes.articleList,
		folio: articleAttributes.folio,
		pageNumber: articleAttributes.pageNumber
	});
	const replicaPage = findReplicaPage({
		replicaList: articleAttributes.replicaList,
		folio: articleAttributes.folio,
		pageNumber: articleAttributes.pageNumber
	});
	if (!isEmpty(article)) {
		articleAttributes.articleId = article.articleId;
	} else if (!isEmpty(replicaPage)) {
		// find the replica page matching the folio/pageNumber then the associated articleId
		// if spread is more than one page, find the first page of the spread with an itemId (articleId)
		if (articleAttributes.pageSpread > 1) {
			const articlePage = articleAttributes.replicaList.find(listPage => {
				return !isEmpty(replicaPage.spread) && replicaPage.spread.includes(listPage.pageNumber) && !isEmpty(listPage.itemId);
			});
			articleAttributes.articleId = !isEmpty(articlePage) ? parseInt(articlePage.itemId, 10) : ID_NOT_SET;
		} else {
			// itemId is the upp article id; which should be an int, but make sure
			articleAttributes.articleId = !isEmpty(replicaPage) ? parseInt(replicaPage.itemId, 10) : ID_NOT_SET;
		}
	}

	if (!isTrue(replicaPage.issueHasArticles)) {
		return null;
	} else if (!isNaN(articleAttributes.articleId) && articleAttributes.articleId !== ID_NOT_SET) {
		const attributes = Object.assign(navKeyAttributes, articleAttributes, {
			updateHistoryState: true,
			replaceHistoryState: true,
			forceFetch: true
		});
		const targetParams = {
			targetKey: targetKey,
			attributes: attributes,
			replacementValues: props.replacementValues
		};

		return (
			<GeneratedTag className={className} title={title} onClick={(evt) => trackAndNavigate(targetParams)}><span className={'button-text'}>{label}</span></GeneratedTag>
		);
	} else {
		return (
			<div className={className} title={title} aria-hidden={true}><span className={'button-text'}>{label}</span></div>
		);
	}
};
export {ReadingViewButton};
