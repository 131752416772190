import React, { useState, useEffect } from 'react';
import {REFERRER_LOGIN} from "../../../_MODULE_GLOBALS/constants";
import {Translate} from "../../../../locales/locales";
import {openInAppBrowser, appCaller} from "../../../../utils/nativeAppInterface";
import * as Tracking from "../../../../utils/tracking";
import {trackButtonClick} from "../index";


const appSubscribeButton = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		action: "subscribe link",
	}));

	// for debug - short circuit around app
	//console.log("Calling window.contentHubAction");
	//window.contentHubAction("{\"type\":\"login\",\"platform\":\"android\",\"response\":{\"success\":\"SUCCESS\"}}");
	//options.subscribeUrl = "http://staging4.texterity.com/data/udo/testMobileRefurlAndroid.html";
	//options.successUrl = "http://staging4.texterity.com/data/udo/testMobileRefurlAndroidSuccess1.html";
//		openInAppBrowser({type: 'login', url: params.subscribeUrl, successUrl: params.successUrl, expirationDays: params.expirationDays });

	openInAppBrowser({type: 'login', ...params});
};


/**
 * Widget for the "app" dialog
 *
 * @param params props + additionalProps passed in from the authentication parent module
 * @returns {JSX.Element}
 * @constructor
 */
const AuthenticateApp = (params) => {
	const isAppRefUrl = (params.app && params.authenticationType === REFERRER_LOGIN);

	const displayText = {
		loginLabel: Translate.Text({ id: 'authenticate.prompt.loginLink' }),
		loginButtonLabel: Translate.Text({id:'authenticate.prompt.button'}),
	};

	const authParams = {
		url: params.subscribeUrl,
		successUrl: params.successUrl,
		expirationDays: params.expirationDays,
		// setup base attributes for tracking for this widget
		baseTrackingProperties: {
			"destination type": params.popupType,
			"issue name": "_" + params.issueName,
			"issue url": params.issueUrl,
		}
	};


	return (
		<div className={"authentication authenticate-app"}>
			<div className="authenticate-screen" />
			<div className="authenticate">
				<div className={params.popupType}>
					<h2 className="title">{Translate.Text({id:'authenticate.prompt.title'})}</h2>
					{
						isAppRefUrl ? <p className="copy">{Translate.Text({id:'authenticate.prompt.only'})}</p> :
							<>
								<p className="copy">{Translate.Text({id:'authenticate.prompt.copy'})}</p>
								<button id={'subscribe-button'} className={'button'} onClick={() => appSubscribeButton({...authParams, label: displayText.loginLabel})}>{displayText.loginButtonLabel}</button>
							</>
					}
					<div className="login-prompt">
						<span>{Translate.Text({ id: 'authenticate.prompt.loginPrompt' })}</span>
						<button id={'login-link'} className={'button login-link'} onClick={() => appSubscribeButton({...authParams, label: displayText.loginLabel})}>{displayText.loginLabel}</button>
					</div>
				</div>
			</div>
		</div>
	);

};
export {AuthenticateApp};
