/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";


/**
 * Put a wrapper around the useEffect hook for a single run at mount.
 * This could be done on a file-by-file basis, but then we would have
 * to suppress the eslint warning each time we want it to be an
 * initialization function.
 *
 * This just abstracts the single-time run and makes it clear with
 * the name that this to to be run only once on initialization.
 *
 * @param func function to execute
 */
const useMountPostRender = (func) => {
	useEffect(func, []);
};
export {useMountPostRender};


const useMountPreRender = (func) => {
	const [initialized, setInitialized] = useState(false);
	if (!initialized) {
		func();
		setInitialized(true);
	}
};
export {useMountPreRender};
