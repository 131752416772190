import $ from 'jquery';
import {isEmpty} from "../../utils/generalUtils";

/*
 * Helper functions to provide a consistent way for callers to deal
 * with containers and for global data calculations.
 */


/**
 * Provide consistent method for getting container name
 *
 * @param params
 *     container: to get name from class
 *     separator: character to use to join when generating name; default '-'
 *     removeClass: class name to remove from element classList
 * @returns {string}
 */
const getContainerName = (params) => {
	params = Object.assign({
		container: null,
		separator: '-',
		removeClass: ['container']
	}, params);
	if (isEmpty(params.container)) {
		return params.container;
	} else {
		const separator = typeof params.separator === 'string' ? params.separator : '-';
		const removeClass = Array.isArray(params.removeClass) ? params.removeClass : ['container'];
		let classNames = params.container.className.split(' ');
		removeClass.forEach(className => {
			classNames = classNames.filter(name => name !== className);
		});
		return classNames.join(separator);
	}
};
export {getContainerName};

/**
 * Calculate the scroll percentage from target's scrollTop and scroll height.
 *
 * @param target element
 * @returns {number}
 */
const calculateScrollPercentage = (target) => {
	let scrollPct = 0;
	if (target === document) {
		scrollPct = window.scrollY / document.documentElement.scrollHeight;
	} else {
		const scrollHeight = target.scrollHeight - target.clientHeight;
		scrollPct = target.scrollTop > 0 && scrollHeight !== 0 ? target.scrollTop / scrollHeight : 0;
	}
	return scrollPct;
};
export {calculateScrollPercentage};

/**
 * Calculate the scrollTop value from the target's scroll percentage and height.
 *
 * @param target element
 * @param scrollPct scroll percentage; calculated earlier and stored in globals
 * @returns {number}
 */
const calculateScrollTop = (target, scrollPct) => {
	let scrollTop = 0;
	if (target === document) {
		const browserHeight = $(window).height();
		const documentHeight = $(document).height();
		scrollTop = scrollPct > 0 && documentHeight !== browserHeight ? Math.round(scrollPct * (documentHeight - browserHeight)) : 0;
	} else {
		const scrollHeight = target.scrollHeight - target.clientHeight;
		scrollTop = Math.round(scrollHeight * scrollPct);
	}
	return scrollTop;
};
export {calculateScrollTop};

/**
 * Calculate either the scroll percentage or the scrollTop for an element or the document.
 *
 * @param params input parameters
 *     target: target element or document
 *     type: percentage or top
 *     scrollPct: stored scroll percentage
 * @returns {number}
 */
const getScroll = (params) => {
	params = Object.assign({
		target: document,
		type: 'percentage',
		scrollPct: 0
	}, params);
	if (params.type === 'percentage') {
		return calculateScrollPercentage(params.target);
	} else {
		return calculateScrollTop(params.target, params.scrollPct);
	}
};
export {getScroll};

/**
 * Collapse or expand layout column by removing or adding a class to the pane
 *
 * @param params input parameters
 *     paneId: target pane
 *     isCollapsed: whether or not the colummn is already collapsed (boolean) - default: false
 */
const toggleLayoutCollapse = (params) => {
	params = Object.assign({
		paneId: '',
		isCollapsed: false
	}, params);
	if (params.paneId === '') {
		return;
	}
	const $pane = $('#' + params.paneId);
	if ($pane.length > 0) {
		if (params.isCollapsed) {
			$pane.removeClass('layout-collapsed');
		} else {
			$pane.addClass('layout-collapsed');
		}
	}
};
export {toggleLayoutCollapse};
