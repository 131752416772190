import React from 'react';
import {connect} from 'react-redux';
import {updateData} from '../../../store/actions';
import GenerateImage from "../../../widgets/generateImage";
import {generateMops, getActiveAttributeState, displayOnDevice} from "../../../utils/moduleSetup";
import {LOGO} from "../../_MODULE_GLOBALS/constants";
import {getStoreValue} from "../../../utils/storeValue";


/**
 * SimpleImageModule
 *
 * This module is designed to allow for the display of an image given an image src.
 *
 * All parameters are passed in through configuration options, so it will not have
 * any interaction with the store; so not fetch or update store data.
 *
 */

/*
	list of potential configuration parameters; other than query params
		className: optional class name to add to div
		src: img src url
		alt: img alt text
		link: href link for image
		imgHeight: inline style; height of image (better to set className)
		imgWidth: inline style; width of image (better to set className)
 */

export const LogoModule = (props) => {
	// returns titleParams, className, storageKey, queryParams
	const mops = generateMops(props, {
		defaultKey: LOGO,
		defaultClass: 'logo',
		configQueryParams: {}
	});

	// check and set image properties
	const imageProperties = {
		alt: props.alt ? props.alt : '',
		src: props.src ? props.src : '#',
		link: props.link ? props.link : false,
		styles: {
			height: props.imgHeight ? props.imgHeight : '',
			width: props.imgWidth ? props.imgWidth : ''
		}
	};


	/*
	* Generate jsx for logo if displayOnDevice
	* Call GenerateImage function to generate image for logo
	*/
	if (displayOnDevice(props)) {
		return (
			<div className={mops.className}>
				<GenerateImage imageProperties={imageProperties} />
			</div>
		);
	} else {
		return null;
	}
};


/**
 * Map state (store) data.
 *
 * storageKey provides a way to map a specific store object to a particular
 * instance of the module.  Add storageKey when configuring the module instance
 * if it needs to listen for a store change.
 *
 * Note: logo image path is hard-coded and added to collectionUrl
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
	const src = props.src ? props.src : (state.fullLogoImageUrl ? state.fullLogoImageUrl : '');
	const link = props.link ? props.link : (state.logoLinkUrl && state.logoLinkUrl !== '' ? state.logoLinkUrl : false);
	const alt = props.alt ? props.alt : getStoreValue({'attributeKey':'collectionTitle'}) + " logo - links to publisher's website";

	return {
		active: getActiveAttributeState(props),
		src: src,
		link: link,
		alt: alt
	};
};

/**
 * simpleImage module (currently) makes to calls to fetch of update data.
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LogoModule);

