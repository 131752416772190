import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {LIBRARY_TARGET} from "../../_MODULE_GLOBALS/constants";
import {isEmpty} from "../../../utils/generalUtils";


/**
 * This module serves as a placeholder target for the home button.  It can be placed
 * in any container, but it is recommended that it be placed in a custom container
 * inside the layout-header of the desired home target pane.
 *
 * It doesn't display anything and the classes for home-target and the custom
 * home container are set to display: none, so that it doesn't interfered in the
 * flow.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const LibraryTargetModule = (props) => {
	const className = "library-target";

	/*
	* Empty jsx for home target; just a placeholder for the home button to target
	* to navigate to the home pane.
	*
	* Note: This needs some placeholder html in order to render, hence the inclusion
	* of a <div> element.  Class "home-target" is set to display: none;
	*/
	return <div className={className} />;
};


/**
 * Map the store object you are using.
 * This doesn't really get any data, so can actually return nothing.
 *
 * Note: A storageKey must be configured in the homeTarget module configuration
 * in order to trigger navigation for the home button.  We just include it
 * here for completeness.
 *
 * @param state pointer to the store state
 * @param props module props, passed through action to store and back
 * @returns {{articleList: Array}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : LIBRARY_TARGET;

	return {
		storageKey: storageKey
	};
};

/**
 * Completely empty dispatch (null), since we don't fetch or update any data.
 * Note: This required as this module must be a data module in order
 * to trigger navigation.
 *
 * @param dispatch
 * @returns {{updateData: updateData}}
 */
//function mapDispatchToProps(dispatch) {
//	return {};
//}


export default connect(
	mapStateToProps,
	null
)(LibraryTargetModule);

