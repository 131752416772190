import React from "react";
import GenerateTitle from "../generateTitle";
import GenerateImage from "../generateImage";
import {sanitizeInline} from "../../utils/htmlUtils";
import {populateArticleData} from "../generateSummary";
import {truncateString} from "../../utils/stringUtils";
import {GenerateHtmlMarkup} from "../generateHtmlMarkup";
import {ExternalUrl} from "../../environment";


/**
 * Generate html for Stacked card layout
 *
 * @param params
 * @returns {*}
 * @constructor
 */
const GenerateCardStacked = (params) => {
	const entries = params.entries ? params.entries : {};
	const actions = params.actions ? params.actions : {};
	const displayParams = params.displayParams ? params.displayParams : {};
	const category = params.category? params.category : '';

	const articles = {
		firstArticle: {},
		links: []
	};
	let count = 0;

	for (let i = 0; i < entries.length; i++) {

		// set article properties
		const entry = entries[i];
		const articleData = populateArticleData(entry, actions);

		if (count < 1) {
			const firstArticle = {};
			firstArticle.title = entry.title;
			firstArticle.imgSrc = entry.bigImage !== '' ? entry.bigImage : ExternalUrl.PLACEHOLDER.LANDSCAPE_THUMBNAIL_MEDIUM;
			// fallback to article title for ALT tag if not available
			firstArticle.imgAlt = typeof entry.bigImageAlt === 'string' && entry.bigImageAlt !== '' ? entry.bigImageAlt : firstArticle.title;
			firstArticle.summary = typeof entry.summary === 'string' && entry.summary !== '' ? truncateString(sanitizeInline(entry.summary), displayParams.maxSummaryCharacters, displayParams.useSummaryEllipsis) : '';

			firstArticle.url = articleData.url;
			firstArticle.linkTitle = articleData.linkTitle;
			firstArticle.clickAction = articleData.clickAction;

			articles.firstArticle = firstArticle;
			count = 1;
		} else {
			const link = {};
			link.title = entry.title;
			link.url = articleData.url;
			link.linkTitle = articleData.linkTitle;
			link.clickAction = articleData.clickAction;
			link.key = articleData.articleId;

			articles.links.push(link);
		}

	}

	// set title params
	const titleParams = {
		tag: 'h3',
		className: 'title',
		displayTitle: true,
	};

	// set first article image
	const coverImageSrc = typeof articles.firstArticle.imgSrc !== 'undefined' ? articles.firstArticle.imgSrc : ExternalUrl.PLACEHOLDER.LANDSCAPE_THUMBNAIL_MEDIUM;
	const coverImageAlt = typeof articles.firstArticle.imgAlt !== 'undefined' ? articles.firstArticle.imgAlt : 'Placeholder';

	// check and set image properties
	const imageProperties = {
		alt: coverImageAlt,
		src: coverImageSrc,
		link: false,
	};

	return (
		<div className={'article-list-grid-element'}>
			<h2 className={'article-list-grid-title-stacked'}><i className={'icon-star'}><span>Star Icon</span>
				<svg viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 0L15.9187 8.98278H25.3637L17.7225 14.5344L20.6412 23.5172L13 17.9656L5.35879 23.5172L8.27747 14.5344L0.636266 8.98278H10.0813L13 0Z" /></svg></i>
			{category}
			</h2>
			<div className={'article-list-grid-card'}>
				<div className={'article-list-grid-card-stacked'}>
					<a href={articles.firstArticle.url} className={'item-link'} data-articletitle={articles.firstArticle.title} data-category={category} onClick={articles.firstArticle.clickAction}>
						<GenerateImage imageProperties={imageProperties} />
						{articles.firstArticle.title && <GenerateTitle titleParams={titleParams}>{articles.firstArticle.title}</GenerateTitle>}
						<GenerateHtmlMarkup tag={'p'} className={'summary'} htmlMarkup={articles.firstArticle.summary} />
					</a>
					<div className={'links'}>
						{articles.links.map((link) => {
							return (
								<a href={link.url} key={link.key} className={'item-link'} title={link.linkTitle} data-articletitle={link.title} data-category={category} onClick={link.clickAction}>{link.title}</a>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};
export default GenerateCardStacked;
