import React, {useState} from "react";
import getDeviceType from "../../../../utils/getDeviceType";
import {toggleLayoutCollapse} from "../../../globals/helperFunctions";
import {Translate} from "../../../../locales/locales";
import * as Tracking from "../../../../utils/tracking";
import {isEmpty} from "../../../../utils/generalUtils";
import {addClass} from "../../../../utils/generateClassName";


/**
 * Handle click on Toggle Layout Collapse button
 *
 * @param params
 *     paneId: target pane where the button resides
 *     isCollapsed: collapse state, stored in useState value in button definition
 */
const layoutCollapseAction = (params) => {
	params = Object.assign({
		paneId: '',
		isCollapsed: false
	}, params);
	const trackingProperties = {
		"type": "toggleLayoutCollapse",
	};
	Tracking.libraryTrack("button clicked", trackingProperties);
	toggleLayoutCollapse({
		paneId: params.paneId,
		isCollapsed: params.isCollapsed
	});
};


/**
 * Generate the Toggle Layout Collapse button.
 *
 * @param params
 *     paneId: target pane where the button resides
 *     tag: the tag for the generated element
 *     className: additional class string
 * @returns {JSX.Element}
 * @constructor
 */
const ToggleLayoutCollapseButton = (params) => {
	params = Object.assign({
		paneId: '',
		tag: 'button',
		className: ''
	}, params);

	const className = addClass('toggle-layout-collapse', params.className);
	const iconClassName = 'toggle-layout-collapse-icon';

	// Keep track of the collapsed state of the columns
	const [isCollapsed, setIsCollapsed] = useState(false);

	const toggleIcon = (isCollapsed) ? 'far fa-chevron-right' : 'far fa-chevron-left';
	const toggleText = (isCollapsed) ? Translate.Text({id: 'toolTip.showMenu'}) : Translate.Text({id: 'toolTip.hideMenu'});
	const toggleParams = {
		paneId: params.paneId,
		isCollapsed: isCollapsed
	};
	const clickToggleLayoutCollapse = (params) => {
		layoutCollapseAction(params);
		setIsCollapsed(!isCollapsed);
	};
	const GeneratedTag = !isEmpty(params.tag) ? `${params.tag}` : `button`;

	return (
		<GeneratedTag
			className={className}
			data-tooltip={toggleText}
			aria-label={toggleText}
			aria-expanded={!isCollapsed}
			onClick={() => clickToggleLayoutCollapse(toggleParams)}
		>
			<div className={iconClassName}>
				<span className={toggleIcon} />
				<span className={'screen-reader'}>{toggleText}</span>
			</div>
		</GeneratedTag>
	);

};

export {ToggleLayoutCollapseButton};
