import {getObjectFromJSON} from "./objectUtils";
import LibraryConfiguration from "../environment";


/**
 * Return an object of destinations with device as key from the 'destination' property
 * to determine what to do with a click on one of the areas in the UI.
 * 'destination' is currently either "external" or "internal" or "disable"
 *
 * If navigationKeys is not defined, just return empty object
 *
 * @param params props from module
 *     destination: json string with key and type for a particular destination
 * @returns {*} object of destinations with device as key
 */
const getDestinations = (params) => {
	const options = Object.assign({
		navigationKeys: null
	}, params);

	const destinations = {};
	if (options.navigationKeys !== null) {
		const navigationKeys = getObjectFromJSON(options.navigationKeys, {});
		const allDevices = Object.keys(LibraryConfiguration.DEFAULTS.DEVICESIZEBREAKPOINTS);

		const filters = Object.keys(navigationKeys);
		filters.forEach((filter) => {
			const filterDestinations = {};
			const destination = getObjectFromJSON(navigationKeys[filter].destination, {});
			Object.keys(destination).forEach(devices => {
				const deviceKeys = devices.split(',');
				deviceKeys.forEach((deviceKey) => {
					deviceKey = deviceKey.trim();
					filterDestinations[deviceKey] = destination[devices];
				});
			});
			const defaultValue = filterDestinations.hasOwnProperty('default') ? filterDestinations.default : 'external';
			allDevices.forEach((deviceKey) => {
				deviceKey = deviceKey.trim();
				destinations[deviceKey] = destinations.hasOwnProperty(deviceKey) ? destinations[deviceKey] : {};
				destinations[deviceKey][filter] = filterDestinations.hasOwnProperty(deviceKey) ? filterDestinations[deviceKey] : defaultValue;
			});
		});
	}
	return destinations;
};
export {getDestinations};
