import React, { useState, useEffect } from 'react';
import {generateStringifiedValue} from "../utils/generalUtils";

/**
 * Detect a change to a set of params that are passed in.  In general, this
 * should be passed in as an array of params.
 *
 * This will simply check if the params have changed and activate the callback.
 * It allows for a callback that doesn't require all the parameters to
 * be included in the change list as does useEffect.
 * In addition, we convert the params to a JSON string, so that we can
 * pass in objects whose signature has changed but whose contents remain
 * unchanged.
 *
 * Note: useEffect surrounds the parameter check to ensure that we are not
 * triggering the following error:
 *     "Cannot update a component (<name>) while rendering a different component"
 * This happens when React is rendering some jsx and we attempt to either
 * setState or perform another render.
 * useEffect ensures that the first render completes before doing anything else.
 *
 * Note: We use generateStringifiedValue to handle different types of values that
 * are being stringified using JSON.  In particular, DOM elements cannot be directly
 * stringified and must be converted to something else first
 *
 * @param callback base callback function
 * @param params set of generic params to store/check
 *     NOTE: must be an array!
 */
const useAttributesChanged = (callback, params) => {
	params = Array.isArray(params) ? params : [];

	const [paramsString, setParamsString] = useState('');

	let stringifiedParams = '';
	params.forEach(param => {
		stringifiedParams += generateStringifiedValue(param);
	});

	useEffect(() => {
		if (paramsString !== stringifiedParams) {
			setParamsString(stringifiedParams);
			callback();
		}
	}, [callback, paramsString, stringifiedParams]);
};
export {useAttributesChanged};
