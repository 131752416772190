import $ from 'jquery';
import Configuration from '../environment';
import store from '../store';
import {isEmpty, isTrue} from "./generalUtils";
import {ARTICLE_LIST, ISSUE_LIST, REPLICA_LIST} from "../modules/_MODULE_GLOBALS/constants";


/**
 * Generate the correct url for a webservice call, including any
 * query parameters that are passed in.
 *
 */


/**
 * List of api service calls.
 *
 * @type {{
 * 		ISSUE_LIST: {pubIdType: string, addCollectionUrl: boolean, url: string},
 * 		ARTICLE_LIST: {pubIdType: string, addCollectionUrl: boolean, url: string},
 * 		ARTICLES: {pubIdType: string, addCollectionUrl: boolean, url: string},
 * 		SEARCH_RESULTS: {pubIdType: string, addCollectionUrl: boolean, url: string},
 * 		SEARCH_ISSUES: {pubIdType: string, addCollectionUrl: boolean, url: string}}}
 */
const SERVICES = {
	/*
		ARTICLES_LIST: {
			url: Configuration.WEBSERVICES.ARTICLE_LIST,
			pubIdType: 'publicationId'
		},
		ARTICLES: {
			url: Configuration.SERVER.ARTICLES,
			addCollectionUrl: true,
			pubIdType: 'publicationIds'
		},
		ARTICLE_DETAILS: {
			url: Configuration.WEBSERVICES.CONTENT.ARTICLE_DETAILS,
			pubIdType: 'publicationId'
		},
		ARTICLE_GROUPS: {
			url: Configuration.WEBSERVICES.CONTENT.ARTICLE_GROUPS,
			pubIdType: 'publicationId'
		},
		ARTICLE_CATEGORIES: {
			url: Configuration.WEBSERVICES.CONTENT.ARTICLE_CATEGORIES,
			pubIdType: 'publicationId'
		},
		GROUPS: {
			url: Configuration.WEBSERVICES.CONTENT.GROUPS,
			pubIdType: 'publicationId'
		},
		PUBLISHERS: {
			url: Configuration.WEBSERVICES.CONTENT.PUBLISHERS,
			pubIdType: 'publicationId'
		},
		PUBLICATIONS: {
			url: Configuration.WEBSERVICES.CONTENT.PUBLICATIONS,
			pubIdType: 'publicationId'
		},
	*/
	SEARCH_RESULTS: {
		url: Configuration.SERVER.SEARCH,
		addCollectionUrl: true,
		pubIdType: 'publicationId'
	},
	SEARCH_ISSUES: {
		url: Configuration.SERVER.ISSUE_NAMES,
		addCollectionUrl: true,
		pubIdType: 'publicationIds'
	},
	ARTICLE_LIST: {
		url: Configuration.SERVER.ARTICLE_LIST,
		addCollectionUrl: true,
		pubIdType: 'publicationIds'
	},
	AUTHENTICATE: {
		url: Configuration.SERVER.PUBLISHER,
		addCollectionUrl: true,
		pubIdType: 'publicationIds'
	},
	ISSUE_LIST: {
		url: Configuration.SERVER.ISSUE_LIST,
		addCollectionUrl: true,
		pubIdType: 'publicationIds'
	},
	SUBSCRIBER: {
		url: Configuration.SERVER.SUBSCRIBER,
		addCollectionUrl: true,
		pubIdType: 'publicationIds'
	},
	PURCHASE: {
		url: Configuration.SERVER.PURCHASE,
		addCollectionUrl: true,
		pubIdType: 'publicationIds'
	},
	TAGS_LOOKUP: {
		url: Configuration.SERVER.ISSUE_TAGS,
		addCollectionUrl: true,
		pubIdType: 'publicationIds'
	},
	IN_APP_PRODUCTS: {
		url: Configuration.SERVER.IN_APP_PRODUCTS,
		addCollectionUrl: true,
		pubIdType: 'publicationIds'
	},
	VIEWER_ARTICLE_LIST: {
		url: Configuration.SERVER.ARTICLE_LIST,
		addCollectionUrl: true,
		pubIdType: 'publicationIds'
	},
	REPLICA_LIST: {
		url: Configuration.SERVER.REPLICA_LIST,
		addCollectionUrl: true,
		pubIdType: 'publicationIds'
	},
	AUTHENTICATION: {
		url: Configuration.SERVER.ACCESS,
		addCollectionUrl: true,
	},
	HIGHLIGHTS: {
		url: Configuration.SERVER.HIGHLIGHTS,
		addCollectionUrl: true,
		pubIdType: 'publicationIds'
	}
};


/**
 * Generate the correct url for a webservice call, including any
 * query parameters that are passed in .
 *
 * @param type webservice
 * @param queryParams (optional) additional query params
 * @returns {string} full API url for call
 */
const getApiUrl = (type, queryParams) => {
	if (SERVICES.hasOwnProperty(type)) {
		const state = store.getState();
		const globalState = state.globals ? state.globals : {};
		const publicationId = state.publicationId;
		const collectionUrl = state.collectionUrl;
		const phoneId = state.phoneId;
		const app = state.app;
		const platform = state.platform;
		const version = state.version;
		const isLoggedIn = globalState.isLoggedIn;
		const service = SERVICES[type];

		let webService = service.url;
		const defaultQueryParams = {};
		if (app) {
			defaultQueryParams.app = app;
		}
		if (phoneId && phoneId !== "") {
			defaultQueryParams.phoneId = phoneId;
		}
		if (platform && platform !== "") {
			defaultQueryParams.platform = platform;
		}
		if (version && version !== "") {
			defaultQueryParams.version = version;
		}
		if (isLoggedIn && isLoggedIn === true) {
			defaultQueryParams.isLoggedIn = isLoggedIn;
		}
		if (queryParams) {
			queryParams = Object.assign(defaultQueryParams, queryParams) ;
		}
		if (service.hasOwnProperty('addCollectionUrl') && service.addCollectionUrl === true) {
			webService = '/' + collectionUrl + webService;
		}
		if (queryParams) {
			if (queryParams.hasOwnProperty('maxEntries') && !queryParams.hasOwnProperty('pageSize')) {
				queryParams.pageSize = queryParams.maxEntries;
				delete queryParams.maxEntries;
			}
			const allQueryParams = !isEmpty(service.pubIdType) ? Object.assign({}, queryParams, {[service.pubIdType]: publicationId}) : queryParams;
			$.each(allQueryParams, function(param, value) {
				const separator = webService.includes('?') ? '&' : '?';
				// ensure we aren't double-encoding a value by decoding first
				const encodedValue = encodeURIComponent(decodeURIComponent(value));
				webService += separator + param + '=' + encodedValue;
			});
		}
		return webService;
	}
};
export default getApiUrl;



/**
 * List of required fetch attributes for client fetch or node queries to decide if the fetch
 * query is valid.
 *
 * ALLREQUIRED: all of the attributes are required for a valid query
 * ONEREQUIRED: at least one of the attributes is required for a valid query
 *
 * @type {{REPLICA_LIST: {ALLREQUIRED: string[], ONEREQUIRED: *[]}, ARTICLE_LIST: {ONEREQUIRED: string[], ALLRQUIRED: *[]}}}
 */
const REQUIRED_FETCH_ATTRIBUTES = {
	[ARTICLE_LIST]: {
		ALLREQUIRED: [],
		ONEREQUIRED: [
			"categories",
			"issueUrl",
			"recentIssues"
		]
	},
	[REPLICA_LIST]: {
		ALLREQUIRED: [
			"issueUrl"
		],
		ONEREQUIRED: []
	},
	[ISSUE_LIST]: {
		ALLREQUIRED: [],
		ONEREQUIRED: []
	}
};
export {REQUIRED_FETCH_ATTRIBUTES};

/**
 * Check if the fetch query params meets the minimum to form a valid query. Required params
 * are of two types
 *     oneRequired: at least one of the params must be present in the query string
 *     allRequired: all of the params must be present in the query string
 * This function checks for oneRequired first, then allRequired, as allRequired
 * is the more stringent check.
 *
 * If there are no required params for the given type, then the query will validate
 * as true, ie. nothing required.
 *
 * @param params
 *     type: query type
 *     queryParams: fetch query params
 * @returns {boolean|boolean}
 */
const checkRequiredParams = (params) => {
	params = Object.assign({
		type: '',
		queryParams: {}
	}, params);
	const {type, queryParams} = params;

	if (isEmpty(type) || isEmpty(REQUIRED_FETCH_ATTRIBUTES[type])) {
		return true;
	}
	const allRequired = REQUIRED_FETCH_ATTRIBUTES[type].ALLREQUIRED;
	const oneRequired = REQUIRED_FETCH_ATTRIBUTES[type].ONEREQUIRED;

	let validQuery = false;
	// at least one of these is required, otherwise we won't send the query
	oneRequired.forEach(param => {
		if (queryParams.hasOwnProperty(param)) {
			validQuery = true;
		}
	});
	// all of these are required, otherwise we won't send the query
	validQuery = !isEmpty(oneRequired) ? validQuery : true;
	allRequired.forEach(param => {
		if (!queryParams.hasOwnProperty(param)) {
			validQuery = false;
		}
	});

	return validQuery;
};
export {checkRequiredParams};
